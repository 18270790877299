import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navmain from "../../Components/Navbar";
import MaterialTable from "material-table";
import DrmSidebar from "../../Components/DrmSidebar";
import Paper from "@mui/material/Paper";
import Footer from "../../Components/Footer";
import RequestTable from "../../Components/RequestTable";
import RequestCard from "../../Components/RequestCard";
import ChartComponent1 from "../../Components/ChartComponent";
import ComposedChart from "../../Components/ComposedChart";
import AreaChart from "../../Components/areaChart";
import ActivitiesCard from "../../Components/ActivitiesCard";
import StatsCard from "../../Components/statsCard";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {
  createPositions,
  formlist,
  addpositions,
} from "../../actions/createPositionActions";
import { listusers } from "../../actions/userActions";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { centerlist } from "../../actions/centerActions";
import { APIURL } from "../../CommomMethods/Fetch";
function DrmDashboard({ reqTableData, history }) {
  toast.configure();
  const [newVis, setNewVis] = useState("hiddenDiv");
  const [ongVis, setOngVis] = useState("hiddenDiv");
  const [compVis, setCompVis] = useState("hiddenDiv");
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [show, setShow] = useState(false);

  const [dashBoardCount, setDashBoardCount] = useState([]);
  const [centerwiseData, setCenterwiseData] = useState([]);
  const [count, setCount] = useState([]);

  useEffect(() => {
    // axios
    //   .get("onboard/onboardCountRecords")
    //   .then((res) => {
    //     setCount(res.data);
    //   });
    axios
      .get(APIURL() + "onboard/onboardedRecords")
      .then((res) => {
        setCount(res.data);
      });
  }, []);
  useEffect(() => {
    axios.get(APIURL()+"vacancies/centerwise").then((res) => {
      setCenterwiseData(res.data);
    });
    axios.get(APIURL()+"vacancies/allvacancy").then((res) => {
      setDashBoardCount(res.data);
    });
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const uploadDoc = useSelector((state) => state.uploadDoc);
  const { upload } = uploadDoc;

  const formList = useSelector((state) => state.formList);
  const { form, success } = formList;
  const [datastamp, setDatastamp] = useState({});

  // const confirm = () => {
  //     axios
  //       .put(
  //         "onboard/updateDetail/" + doctorId,
  //         datastamp
  //       )
  //       .then((res) => {});
  //   };
  const [value, setValue] = React.useState("New Position");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const cardStyle = {
    background: "transparent",
    border: "0px",
  };

  const cardStyle1 = {
    background: "transparent",
    border: "0px",
    marginTop: "5px",
  };
  const infoStyle = {
    border: "2px solid #858585",
  };
  const subTitle = {
    fontSize: "13px",
  };

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(formlist());
  }, [dispatch]);

  const handleCreatePosition = () => {
    let k = {
      positionType: value,
      postedBy: users && users[0] && users[0].id,
      ...data,
    };

    dispatch(createPositions(k));
    toast.success("Success");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  const info = "Clearing Exceptions";

  function tabChange(stat) {
    if (stat == "new") {
      if (newVis == "hiddenDiv") {
        setNewVis("visibleDiv");
        setOngVis("hiddenDiv");
        setCompVis("hiddenDiv");
      } else {
        setNewVis("hiddenDiv");
      }
    }
  }
  var testdata = newdata;
  var newdata = [];

  function selectedValue(e) {
    newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);

    //  var selectedcenter= document.getElementById('centerlist').value;
    //  var selecteddept = document.getElementById('departmentlist').value;
    //  var selecteddesignation = document.getElementById('designationlist').value;
    //  var selectedtype = document.getElementById('typelist').value;
  }

  return (
    <>
      <DrmSidebar />

      <div className="main-content side-content pt-0">
        <div className="container-fluid">
          <div className="inner-body">
            <Navmain />

            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Welcome {users && users[0] && users[0].name}{" "}
                </h2>
                <br />
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Dashboard
                  </li>
                </ol>
              </div>
            </div>
            {/* new request */}
            <div className="justify-content-center">
              <div className="row">
                <div className="col-md-4 requestCol">
                  <a className="requestCard" onClick={() => tabChange("new")}>
                    <RequestCard
                      onClick={() => tabChange("new")}
                      reqType="new"
                      num={5}
                    />
                  </a>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12 requestCol">
                  <StatsCard />
                </div>
              </div>
            </div>

            {/* hidden new requests table */}
            <div className={`${newVis}`} id="newReqTable">
              <RequestTable
                reqTableData={reqTableData}
                reqType="new"
                history={history}
              />
            </div>

            <div className={`${ongVis}`} id="ongReqTable">
              <RequestTable reqTableData={reqTableData} reqType="ongoing" />
            </div>

            <div className={`${compVis}`} id="compReqTable">
              <RequestTable reqTableData={reqTableData} reqType="completed" />
            </div>

            <div className="row">
              <div className="col-xl-4 col-md-4" style={{ height: "280px" }}>
                <ComposedChart datalist={count} />
              </div>
              <div className="col-xl-4 col-md-4" style={{ height: "280px" }}>
                <ChartComponent1 datalist={dashBoardCount} />
              </div>
              <div className="col-xl-4 col-md-4">
              </div>
            </div>

            <br />
            {/* <Card style={cardStyle1}>
              <Card.Body>
                <div className="row row-sm ">
                  <div className="col-sm-6 col-md-6 col-xl-3">
                    <Link to="#" onClick={handleShow}>
                      <ActivitiesCard
                        text="Create Position"
                        icon="fe fe-user"
                      />
                    </Link>
                  </div>
                  <div className="col-sm-6 col-md-6 col-xl-3">
                    <Link to="/bh-agreement">
                      <ActivitiesCard
                        text="View All Doctor Agreements"
                        icon="fe fe-user"
                      />
                    </Link>
                  </div>
                  <div className="col-sm-6 col-md-6 col-xl-3">
                    <Link to="/doctorList">
                      <ActivitiesCard
                        text="View All Doctors"
                        icon="fe fe-user"
                      />
                    </Link>
                  </div>
                </div>
              </Card.Body>
            </Card> */}
            <br />
          </div>
        </div>
        <Footer style={{}} />
      </div>
    
    </>
  );
}

export default DrmDashboard;
