import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import XLSX from "xlsx";
import moment from "moment";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
//ui components
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import axios from "axios";
import { APIURL } from "../CommomMethods/Fetch";

const styleSheet = makeStyles((theme) => ({
  iconButtonStyle: {
    float: "right",
  },
  marginforMaster: {
    marginTop: "30px",
  },
}));

export default function BillingLineitem() {
  const classes = styleSheet();
  const [preview, setPreview] = useState(true);
  const [intVal, setIntVal] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [progress, setProgress] = useState(false);
  const [rows, setRows] = useState([]);
  const [reload, setReload] = useState(false);
 


  useEffect(() => {
    axios
      .get(APIURL() + "billings/findAllBillingDet")
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows, "AllExcelData");
        setRows(rows.data);
      });
  }, [reload]);


  return (
    <>
      <Sidebar />
      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.marginforMaster}
              >
                <MaterialTable
                    columns={[
                        { title: "SlNo", field: "SlNo" },
                        { title: "MPI", field: "MPI" },
                        { title: "Patient Name", field: "PatientName" },
                        { title: "Bill No", field: "BillNo" },
                        { title: "Bill Time", field: "BillTime" },
                        {
                          title: "Bill No",
                          field: "BillNo",
                          filtering: false,
                          render: (row) => (
                            <Link
                              to={{
                                // pathname: "/DoctorProfileBH",
                                pathname:"/user-profile",
                                state: { id: row.id },
                              }}
                            >
                              {" "}
                              <i className="fas fa-eye" color="black"></i>{" "}
                            </Link>
                          ),
                        },
                        { title: "Bill Date", field: "BillDate" },
                        { title: "Admin Fee", field: "AdminFee" },
                        { title: "Item Group Name", field: "ItemGroupName" },
                        { title: "Item Name", field: "ItemName" },
                        { title: "Gross", field: "Gross" },
                        { title: "Tax", field: "Tax" },
                        { title: "Discount", field: "Discount" },
                        { title: "Net", field: "Net" },
                        { title: "Return Amount", field: "ReturnAmount" },
                        { title: "Ordering Physician", field: "OrderingPhysician" },
                        { title: "Specialisation", field: "Specialisation" },
                        { title: "ConductedBy", field: "ConductedBy" },
                        { title: "User", field: "User" },
                        { title: "Payable NonPayable", field: "PayableNonPayable" },
                        { title: "Conducted By Final", field: "ConductedByFinal" },
                        { title: "Location", field: "Location" },
                       
                    ]}
                    data={rows}

                    options={{
                      // filtering: true,
                      sorting: true,
                      exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    title="Billing Details"
                  />
              </Grid>
              </Grid>
          
                 
            
          </div>
        </div>
      </div>
    </>
  );
}
