import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navmain from "../Components/Navbar";
import GraphsDashBoard from "../Components/GraphsDashBoard";
import AppSidebar from "../Components/AppSidebar";
import { APIURL } from "../CommomMethods/Fetch";
import { Box, makeStyles } from "@material-ui/core";
import "react-circular-progressbar/dist/styles.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import { AppContext } from "../actions/contextApi";
import Backdrop from "@material-ui/core/Backdrop";
import c9gif from '../SampleFile/cloudnine.gif';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function Template() {
  const [backDrop, setBackDrop] = useState(false);
  
  
  const backClasses = backDropStyle();

  return (
    <>
      <AppSidebar />
      <div className="main-content side-content pt-0">
        <div className="container-fluid">
          <div className="inner-body" style={{ zindex: '9999' }}>
            <Navmain />

            <GraphsDashBoard />
          </div>
        </div>
      </div >
      <Backdrop className={backClasses.backdrop} open={backDrop}>
        <img src={c9gif} alt="loading..." />
      </Backdrop>
    </>
  );
}
