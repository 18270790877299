import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navmain from "../Components/Navbar";
import GraphsDashBoard from "../Components/GraphsDashBoard";

import Footer from "../Components/Footer";
import { APIURL } from "../CommomMethods/Fetch";

import {
  createPositions,
  formlist,
  addpositions,
} from "../actions/createPositionActions";
import { listusers } from "../actions/userActions";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
// import { APIURL } from "../../CommomMethods/Fetch";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function CFODashBoard({ reqTableData, history }) {
  toast.configure();
  const [newVis, setNewVis] = useState("hiddenDiv");
  const [ongVis, setOngVis] = useState("hiddenDiv");
  const [compVis, setCompVis] = useState("hiddenDiv");
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [centerData, setCenterData] = useState({});
  const [show, setShow] = useState(false);
  const [showUpdatePosition, setShowUpdatePosition] = useState(false);

  const [dashBoardCount, setDashBoardCount] = useState([]);
  const [centerwiseData, setCenterwiseData] = useState([]);
  const [filterDatas, setfilterDatas] = useState([]);
  const [openpos, setOpenPos] = useState([]);
  const [count, setCount] = useState([]);
  const [statcount, setStatCount] = useState([]);
  const [selectedMin, setselectedMin] = useState([]);
  const [nofreq, setNofReq] = useState(1);
  const [selectedMax, setselectedMax] = useState([]);
  const [fgchecked, setfgchecked] = useState(false);
  const [posModelValue, setPosModelValue] = useState({});
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  useEffect(() => {

    axios
      .get(APIURL() + "onboard/onboardStatus")
      .then((res) => {
        setCount(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  useEffect(() => {
    axios
      .get(APIURL() + "vacancies/centerwise")
      .then((res) => {
        setCenterwiseData(res.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
    axios
      .get(APIURL() + "vacancies/allvacancy")
      .then((res) => {
        setDashBoardCount(res.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);
  // setfgchecked;
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log(count, "countcountcountcount");
  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const uploadDoc = useSelector((state) => state.uploadDoc);
  const { upload } = uploadDoc;

  const formList = useSelector((state) => state.formList);
  const { form, success } = formList;
  const handleChangeFg = (e) => {
    setfgchecked(e.target.checked);
  };
  useEffect(() => {
    axios.get(APIURL() + "designations/").then((res) => {
      setfilterDatas(res.data.data);
    });
  }, []);
  console.log(filterDatas, "filterDatas");
  const [datastamp, setDatastamp] = useState({});
  const [centerposition, setCenterposition] = useState([]);
  const userloginid = users && users[0] && users[0].id;
  console.log(userloginid, "user  login   id");

  console.log(centerposition, "centerposition");
  const getposition = () => {
    axios
      .get(
        APIURL() + `centerpositionmaps/findAllCenterPositionByID/${userloginid}`
      )
      .then((res) => {
        console.log(res.data.data, "center map data");
        const fetchdata = res.data.data.filter(
          (z) => z.centername == centerData
        );
        setCenterposition(fetchdata);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  console.log(centerposition, "centerpostionnn");
  useEffect(() => {
    getposition();
  }, [users, data.centerlist, centerData]);

  const [value, setValue] = React.useState("New Position");
  const [openNoReq, setOpenNoReq] = React.useState(false);
  const [salary, setSalary] = React.useState("Monthly");
  const [radioSelect, setRaddioSelect] = React.useState(0);
  const [select, setSelect] = React.useState(0);
  const [selectPositionData, setSelectPOsitiondata] = React.useState(0);

  const handleChange = (event) => {
    setValue(event.target.value);
    setData({});
  };
  const closeDialog = () => {
    setOpenNoReq(false);
    setShow(true);
  };
  const handleChnageSalary = (event) => {
    setSalary(event.target.value);
  };
  const handleChangeSelect = (e, data) => {
    // alert(data.id);
    console.log(data, "TTTTTTTT");
    setRaddioSelect(data.id);
    setSelect(e.target.value);
    setSelectPOsitiondata(data);
    // setRaddioSelect(event.target.value);
  };

  const cardStyle = {
    background: "transparent",
    border: "0px",
  };

  const cardStyle1 = {
    background: "transparent",
    border: "0px",
    marginTop: "5px",
  };
  const infoStyle = {
    border: "2px solid #858585",
  };
  const subTitle = {
    fontSize: "13px",
  };

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(formlist());
  }, [dispatch]);
  const handleCreatePosition = () => {
    console.log(
      selectPositionData,
      "selectPositionDataselectPositionData000000"
    );
    if (selectPositionData != 0) {
      data.salaryType = salary;
      data.nofreq = nofreq;
      data.centercode = selectPositionData.centerid;
      data.departmentlist = selectPositionData.departmentname;
      data.minsalary = selectPositionData.fg_from;
      data.category = selectPositionData.category;
      // data.category = data.category;
      data.maxsalary = selectPositionData.fg_to;
      data.financialGrid = "Yes";
      let k = {
        positionType: value,
        postedBy: users && users[0] && users[0].id,
        ...data,
      };
      console.log(k, "data2");
      // console.log(k, "passing data to vacancy");
      dispatch(createPositions(k));
      getposition();
      toast.success("Success");
      // }
    } else {
      // console.log(data, "data3");
      if (selectPositionData == 0) {
        // alert("Please select the vacancy position");
        // return;
      }
      data.nofreq = nofreq;
      data.financialGrid = "Yes";
      data.category = data.typelist;
      data.salaryType = salary;
      data.centercode = users[0] && users[0].centers && users[0].centers.find(elem => elem.name == data.centerlist).id
      let k = {
        positionType: value,
        postedBy: users && users[0] && users[0].id,
        request_type: "Onboarding",
        ...data,
      };
      dispatch(createPositions(k));
      getposition();
      toast.success("Success");
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleUpdatePosition = () => {
    // console.log(data, "data3");
    data.minsalary = selectedMin;
    data.maxsalary = selectedMax;
    data.financialGrid = fgchecked ? "No" : "Yes";
    let k = {
      positionType: value,
      rdApproval: fgchecked ? "R" : "NR",
      postedBy: users && users[0] && users[0].id,
      ...data,
    };
    // console.log(k, "passing data to vacancy");
    axios.put(APIURL() + "vacancies/" + posModelValue.id, k).then((res) => { });
    getposition();
    toast.success("Success");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  const info = "Clearing Exceptions";

  function tabChange(stat) {
    if (stat == "new") {
      if (newVis == "hiddenDiv") {
        setNewVis("visibleDiv col-xl-12 col-md-12");
        setOngVis("hiddenDiv");
        setCompVis("hiddenDiv");
      } else {
        setNewVis("hiddenDiv");
      }
    }
  }
  var testdata = newdata;
  var newdata = [];
  var departmentArray = [];

  function selectedValue(e) {
    console.log(e, "all select");
    var selecteddept = document.getElementById("departmentlist");

    console.log(e, "check e");
    newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
    setCenterData(newdata.centerlist);
  }

  console.log(data, "department list");

  var newcenterdata = [];
  function centerOnchange(e) {
    //setCenterData
    console.log(e.target.value, "e.target.value");
    newcenterdata = { ...centerData };
    newcenterdata[e.target.id] = e.target.value;
    setCenterData(newcenterdata);
  }

  const [financialData, setFinancilaData] = useState({});

  var newadddata = [];
  function onchangeFinancialGrid(e) {
    newadddata = { ...financialData };
    newadddata[e.target.id] = e.target.value;
    setFinancilaData(newadddata);
  }
  // console.log(data, "comma indicator");

  const openPosModalParent = (value) => {
    console.log("openPosModalParent", value);
    setPosModelValue(value);
    setShowUpdatePosition(true);
    setValue(value.positionType)
    if (value.financialGrid == "No") {
      setfgchecked(true);
    } else {
      setfgchecked(false);
    }
    newdata = { ...data };
    newdata["designationlist"] = value.designationLabel;
    newdata["typelist"] = value.category;
    setData(newdata);
    setselectedMax(value.maxsalary)
    setselectedMin(value.minsalary)
  }

  const dataCompoToggle = () => {
    tabChange("new");
  }

  const dataCompoToggle2 = (dataTable) => {
    setNewVis("hiddenDiv");
    console.log('dataTable check value', dataTable);
    // tabChange("new"); 
  }

  const handleReq = (e) => {
    setNofReq(e.target.value);
  };

  useEffect(() => {
    var k = centerposition.map((z) => setOpenPos(z.openposition));
  }, [centerposition]);
  console.log(openpos, "openposopenpos");
  return (
    <>
      <div className="main-content pr-5 pl-5 pt-0">
        <div className="container-fluid">
          <div className="inner-body">
            <Navmain />

            <div className="page-header" >
              <div>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Dashboard
                  </li>
                </ol>
              </div>
            </div>
            {/* new request */}
            <GraphsDashBoard />
          </div>
        </div>
        <Footer style={{}} />
      </div>
    </>
  );
}

export default CFODashBoard;
