import React, { useEffect, useState } from "react";
import { Card, Table, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import { Modal } from "react-bootstrap";
//import ui components
import FinanceSidebar from "../../Components/FinanceSidebar";
import { agreementState, onboardState } from "../../CommomMethods/States";
//import material-ui component
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
//import axios
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { listusers } from "../../actions/userActions";
import MaterialTable from "material-table";
import Sidebar from "../../Components/Sidebar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import FixedfullTime from "../Payout/NEW/FixedfullTime";
import FixedMG from "../Payout/NEW/fixedMG";
import VcChennai from "../Payout/NEW/VcChennai";
import VisitingCon from "../Payout/NEW/VisitingCon";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CircularProgress from "@material-ui/core/CircularProgress";

import { APIURL } from "../../CommomMethods/Fetch";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#810e36",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  buttonProgress: {
    // backgroundColor: "red",
    color: "white",
    position: "absolute",
    top: "50%",
    left: "20%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
export default function FinanceDashboard() {
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState(false);
  const [rows, setRows] = useState([]);
  const [orow, setOrow] = useState({});
  const [openr, setOpenr] = useState(false);
  const [remark, setRemark] = useState("");
  const [row, setRow] = useState({});
  const [categoty, setCategory] = useState("");
  const [docData, setDocData] = useState([]);
  const [viewAgreement, setViewAgreement] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const [openReject, setOpenRejectmodal] = React.useState(false);
  const [openApprove, setOpenApprovemodal] = React.useState(false);
  const [review, setReview] = useState("");
  const [approve, setapprove] = useState([]);
  const [rej, setRej] = useState([]);

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/currentAgreementStatusforCeo/")
      .then((res) => {
        console.log(res.data, "ppp");
        setRows(res.data.data);
      })
      .catch((error) => { });
  }, [status]);

  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  const handleClose = () => setShow(false);
  const handleShow = (doctorid) => {
    setShow(true);
  };
  const handleApproveDialog = (row) => {
    //setDocid(row.doctorId);
    setOpenApprovemodal(true);
    setapprove(row);
  };
  console.log(rows, "ROWSS");
  const handleComments = (e) => {
    setReview(e.target.value);
  };
  const handleApprove = (row) => {
    if (review == '') {
      toast.error("Kindly provide some comments");
      return false;
    }
    setStatus(false);
    const obj = {
      doctorId: approve.doctorId,
      name: approve.name,
      currentAction: "FINANCE_APPROVED",
      currentActionBy: users[0].id,
      nextAction: agreementState.completed,
      remark: review,
      status: 1,
    };

    axios.post(APIURL() + "onboard/agreementAction/", obj).then((res) => { });

    axios.post(APIURL() + "onboard/action/", obj).then((res) => {
      setStatus(true);
      toast.success("Successfully Confirmed");
      window.location.reload();
    });
  };

  const handlerClickOpen = (row) => {
    setOrow(row);
    setOpenr(true);
  };
  const Remark = (e) => {
    setRemark(e.target.value);
  };

  const handlerClose = () => {
    setOpenr(false);
  };
  const handleSubmitReject = () => {
    const obj = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: agreementState.hr_accepted.state,
      currentActionBy: users[0].id,
      nextAction: agreementState.dr_accepted.state,
      remark: remark,
      status: 1,
    };

    axios.post(APIURL() + "onboard/agreementAction/", obj).then((res) => {
      toast.success("Rejection successfully submitted");
    });
    window.location.reload();
  };

  const closeAgreement = () => {
    setViewAgreement(false);
  };

  const viewAgreementDoc = (row) => {
    setRow(row);
    axios
      .get(APIURL() + "onboard/agreementView/" + row.doctorId)
      .then((res) => {
        if (res.data != null) {
          setCategory(res.data.data.vacancyData[0].category);
          setDocData(res.data.data);
        }
      });
    setViewAgreement(true);
  };

  return (
    <>
      <FinanceSidebar />

      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />
            <form>
              <br />
              <Card style={formStyle}>
                <Card.Body style={bodyStyle}>
                  <MaterialTable
                    columns={[
                      { title: "Doctor Name", field: "name" },
                      { title: "Comments", field: "remarks" },

                      {
                        title: "Current Action",
                        field: "Action",
                        filtering: false,
                        render: (row) => (
                          <div style={{ width: "140px" }}>
                            <>{agreementState.dr_accepted.message}</>
                          </div>
                        ),
                      },
                      //   {
                      //     title: "View Details",
                      //     field: "Action",
                      //     filtering: false,
                      //     render: (row) => (
                      //       <Link
                      //         to={{
                      //           pathname: "/UserProfileDetail",
                      //           state: { id: row.doctorId },
                      //         }}
                      //       >
                      //         {" "}
                      //         <i className="fas fa-eye" color="black"></i>{" "}
                      //       </Link>
                      //     ),
                      //   },
                      {
                        title: " View Agreements",
                        field: "Action",
                        filtering: false,
                        render: (row) => (
                          <AssignmentIcon
                            style={{
                              backgroundColor: "#810e36",
                              color: "white",
                            }}
                            onClick={() => {
                              viewAgreementDoc(row);
                            }}
                          ></AssignmentIcon>
                        ),
                      },
                      {
                        title: "Approve",
                        field: "Action",
                        filtering: false,
                        render: (row) =>
                          row.agreementnextAction === agreementState.finance_approval.state ? (
                            <div style={{ whiteSpace: "nowrap" }}>
                              <Button
                                size="small"
                                style={{
                                  backgroundColor: "#810e36",
                                  color: "white",
                                }}
                                // color="#810e36"
                                variant="contained"
                                onClick={() => {
                                  handleApproveDialog(row);
                                }}
                              >
                                Approve
                              </Button>
                              {!status && (
                                <CircularProgress
                                  size={24}
                                  className={classes.buttonProgress}
                                />
                              )}
                            </div>
                          ) : row.agreementnextAction === agreementState.completed ? (
                            <span className="badge badge-success">
                              Approved
                            </span>
                          ) :
                            row.agreementnextAction === agreementState.dr_accepted.state ? (
                              <span className="badge" style={{
                                color: "white",
                                backgroundColor: "grey",
                              }}>
                                {agreementState.ag_init.message}
                              </span>
                            ) : (
                              <div style={{ whiteSpace: "nowrap" }}>
                                <Button
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  disabled
                                >
                                  No Action Required
                                </Button>
                              </div>
                            ),
                      },
                    ]}
                    data={rows}
                    // data={
                    //   rows &&
                    //   rows

                    //     .filter(
                    //       (z) =>
                    //         z.agreementnextAction === "COMPLETE" &&
                    //         z.onboardnextAction === "CH_DOC_OK"
                    //     )
                    // }
                    options={{
                      // filtering: true,
                      sorting: true,
                      exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    title="Agreement Details"
                  />
                </Card.Body>
              </Card>
            </form>
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Review
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Remark</b>
          </label>
          <textarea
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitReject}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={viewAgreement}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="end"
              style={{ marginLeft: "99%" }}
              color="inherit"
              onClick={closeAgreement}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          {categoty === "Full Time Consultant" || categoty === 'Retainer' ? (
            <FixedfullTime
              history={row}
              recData={docData}
              catagory={categoty}
            />
          ) : categoty === "Minimum Guarentee(MG)" ? (
            <FixedMG history={row} recData={docData} catagory={categoty} />
          ) : categoty === "Visiting Consultant" ? (
            <VisitingCon history={row} recData={docData} catagory={categoty} />
          ) : categoty === "Visiting Consultant Chennai" ? (
            <VcChennai history={row} recData={docData} catagory={categoty} />
          ) : (
            "hi"
          )}
        </List>
      </Dialog>

      {/* APPROVE MODAL */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenApprovemodal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openApprove}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenApprovemodal(false);
          }}
          style={{ textAlign: "center" }}
        >
          Approve
        </DialogTitle>
        <DialogContent dividers>
          <label>Comments </label>
          <textarea
            placeholder="Comments"
            type="text"
            onChange={handleComments}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleApprove}
            color="secondary"
            variant="contained"
            size="small"
          >
            Approve
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setOpenApprovemodal(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
