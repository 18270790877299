import React, { useState, useEffect } from "react";
//ui components
import AppSidebar from "../../Components/PTSidebar";
import Navbar from "../../Components/Navbar";
//mui components
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Button, Paper, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import Skeleton from "@material-ui/lab/Skeleton";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
////
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import { dateFormatter } from "../../actions/createPositionActions";

export default function DiscardPayout() {
  const [rows, setRows] = useState([]);
  const [ipRows, setIpRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState("1");

  const handleChangetab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setLoading(false);
    axios
      .get(APIURL() + "billings/getAllDIscardData")
      .then((res) => res.data)
      .then((rows) => {
        setRows(rows.data);
        setLoading(true);
      });

    axios
      .get(APIURL() + "billings/getAllIpDIscardData")
      .then((res) => res.data)
      .then((rows) => {
        setIpRows(rows.data);
        setLoading(true);
      });
  }, []);

  return (
    <>
      <AppSidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />

        <TabContext value={value}>
          <AppBar
            position="static"
            style={{ marginTop: "10px", backgroundColor: "#810e36" }}
          >
            <TabList
              onChange={handleChangetab}
              aria-label="simple tabs example"
            >
              <Tab label="Op Payout List" value="1" />
              <Tab label="Ip Payout List" value="2" />
            </TabList>
          </AppBar>
          <TabPanel value="1">
            <Typography variant="subtitle1">Discard Op Payout List</Typography>
            <Paper>
              <MaterialTable
                columns={[
                  { title: "MPI", field: "MPI" },
                  { title: "Bill No", field: "BillNo" },
                  { title: "Bill Date", field: "BillDate",  render: (row) => (<div>{dateFormatter(row.BillDate)}</div>) },
                  { title: "Bill Time", field: "BillTime" },
                  { title: "Patient Name", field: "PatientName" },
                  { title: "ItemGroup Name", field: "ItemGroupName" },
                  { title: "Item Name", field: "ItemName" },
                  { title: "Conducted By", field: "ConductedBy" },
                ]}
                data={rows}
                options={{
                  // filtering: true,
                  //   selection: true,
                  sorting: true,
                  exportButton: true,
                  exportAllData: true,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 50, 100, 200, 500],
                  search: true,
                }}
                title="Discard Payout"
              />
            </Paper>
          </TabPanel>
          <TabPanel value="2">
            <Typography variant="subtitle1">Discard Ip Payout List</Typography>
            <Paper>
              <MaterialTable
                columns={[
                  { title: "MPI", field: "MPI" },
                  { title: "Bill Date", field: "BillNo",  render: (row) => (<div>{dateFormatter(row.BillNo)}</div>) },
                  { title: "Bill No", field: "BillDate" },
                  { title: "Bill Time", field: "BillTime" },
                  { title: "Patient Name", field: "PatientName" },
                  { title: "ItemGroup Name", field: "Specialisation" },
                  { title: "Item Name", field: "AdmitGroup" },
                  { title: "Conducted By", field: "AdmittingPhysician" },
                  { title: "Admission Purpose", field: "AdmissionPurpose" },
                ]}
                data={ipRows}
                options={{
                  // filtering: true,
                  //   selection: true,
                  sorting: true,
                  exportButton: true,
                  exportAllData: true,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 50, 100, 200, 500],
                  search: true,
                }}
                title="Discard Ip-Payout"
              />
            </Paper>
          </TabPanel>
        </TabContext>
      </Container>
    </>
  );
}
