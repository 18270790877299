import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
//ui
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import PTSidebar from "../../Components/PTSidebar";
import Navbar from "../../Components/Navbar";
//mui
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

export default function ManageIpvisit() {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    axios
      .get(APIURL() + "billings/getipvisit")
      .then((res) => res.data)
      .then((rows) => {
        setRows(rows.data);
      });
  }, []);
  return (
    <>
      <PTSidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
        <Paper>
          <MaterialTable
            columns={[
              { title: "MPI", field: "IPNo" },
              { title: "Bill NO", field: "Bill Date" },
              //   { title: "Bill Date", field: "Bill Date" },
              { title: "Bill Time", field: "Bill Time" },
              { title: "Patient Name", field: "Age" },
              { title: "Age", field: "Gender" },
              { title: "Gender", field: "Admitting Physician" },
              { title: "Admitting Physician", field: "Specialisation" },
              { title: "Specialisation", field: "Admit Group" },
              { title: "Admit Group", field: "Admission Purpose" },
            //   { title: "Admission Purpose", field: "Admission Purpose" },
            ]}
            data={rows}
            options={{
              // filtering: true,
              sorting: true,
              exportButton: true,
              pageSize: 5,
              pageSizeOptions: [5, 10, 50, 100, 200, 500],
              search: true,
            }}
            title="Ip Visit"
          />
        </Paper>
      </Container>
    </>
  );
}
