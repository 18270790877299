function ipAgreementCalc(ipAgreement) {
    var gipsa = ipAgreement.Gipsa && ipAgreement.Gipsa === 'Yes' ? " For Gipsa" : ""
    if (ipAgreement.ipdeductaionvalue === "0" && ipAgreement.ipdiscountType === "Yes" && ipAgreement.ipfixedamount === "0") {
        if (ipAgreement.ipsurgeonfeeperc === "0" && ipAgreement.ippackageamountperc === "0" && ipAgreement.ipnetbillpercentage !== "0") {
            return ipAgreement.ipnetbillpercentage + "% of the Net Amount" + gipsa
        }
        else if (ipAgreement.ipsurgeonfeeperc === "0" && ipAgreement.ippackageamountperc === "0" && ipAgreement.ipnetbillpercentage === "0") {
            return ipAgreement.ipgrossbillpercentage + "% of the Net Amount" + gipsa
        }
        else if (ipAgreement.ipsurgeonfeeperc === "0" && ipAgreement.ipgrossbillpercentage === "0" && ipAgreement.ipnetbillpercentage === "0") {
            return ipAgreement.ippackageamountperc + "% of the Net Amount" + gipsa
        }
        else {
            return ipAgreement.ipsurgeonfeeperc + "% of the Net Amount" + gipsa
        }
    }

    if (ipAgreement.ipdeductaionvalue !== "0" && ipAgreement.ipdiscountType === "No" && ipAgreement.ipfixedamount === "0") {
        if (ipAgreement.ipsurgeonfeeperc === "0" && ipAgreement.ippackageamountperc === "0" && ipAgreement.ipnetbillpercentage !== "0") {
            return ipAgreement.ipnetbillpercentage + "% of the Gross Bill excluding Deductions" + gipsa
        }
        else if (ipAgreement.ipsurgeonfeeperc === "0" && ipAgreement.ippackageamountperc === "0" && ipAgreement.ipnetbillpercentage === "0") {
            return ipAgreement.ipgrossbillpercentage + "% of the Gross Bill excluding Deductions" + gipsa
        }
        else if (ipAgreement.ipsurgeonfeeperc === "0" && ipAgreement.ipgrossbillpercentage === "0" && ipAgreement.ipnetbillpercentage === "0") {
            return ipAgreement.ippackageamountperc + "% of the Gross Bill excluding Deductions" + gipsa
        }
        else {
            return ipAgreement.ipsurgeonfeeperc + "% of the Gross Bill excluding Deductions" + gipsa
        }
    }

    if (ipAgreement.ipdeductaionvalue !== "0" && ipAgreement.ipdiscountType === "Yes" && ipAgreement.ipfixedamount === "0") {
        if (ipAgreement.ipsurgeonfeeperc === "0" && ipAgreement.ippackageamountperc === "0" && ipAgreement.ipnetbillpercentage !== "0") {
            return ipAgreement.ipnetbillpercentage + "% of the Net Bill excluding Deductions" + gipsa
        }
        else if (ipAgreement.ipsurgeonfeeperc === "0" && ipAgreement.ippackageamountperc === "0" && ipAgreement.ipnetbillpercentage === "0") {
            return ipAgreement.ipgrossbillpercentage + "% of the Net Bill excluding Deductions" + gipsa
        }
        else if (ipAgreement.ipsurgeonfeeperc === "0" && ipAgreement.ipgrossbillpercentage === "0" && ipAgreement.ipnetbillpercentage === "0") {
            return ipAgreement.ippackageamountperc + "% of the Net Bill excluding Deductions" + gipsa
        }
        else {
            return ipAgreement.ipsurgeonfeeperc + "% of the Net Bill excluding Deductions" + gipsa
        }
    }

    if (ipAgreement.ippackageamountperc !== "0" && ipAgreement.ipdeductaionvalue === "0" && ipAgreement.ipdiscountType === "No" && ipAgreement.ipfixedamount === "0") {
        return ipAgreement.ippackageamountperc + "% of the Package Amount" + gipsa
    }
    if (ipAgreement.ipsurgeonfeeperc !== "0" && ipAgreement.ipdeductaionvalue === "0" && ipAgreement.ipdiscountType === "No" && ipAgreement.ipfixedamount === "0") {
        return ipAgreement.ipsurgeonfeeperc + "% of the  Surgeon Fee" + gipsa
    }
    if (ipAgreement.ipnetbillpercentage !== "0" && ipAgreement.ipdeductaionvalue === "0" && ipAgreement.ipdiscountType === "No" && ipAgreement.ipfixedamount === "0") {
        return ipAgreement.ipnetbillpercentage + "% of the Net Bill Amount" + gipsa
    }
    if (ipAgreement.ipgrossbillpercentage !== "0" && ipAgreement.ipdeductaionvalue === "0" && ipAgreement.ipdiscountType === "No" && ipAgreement.ipfixedamount === "0") {
        return ipAgreement.ipgrossbillpercentage + "% of the Gross Bill Amount" + gipsa
    }
    if (ipAgreement.ipfixedamount !== 0) {
        return " Fixed Amount of " + ipAgreement.ipfixedamount + "/- Rs per case " + gipsa
    }
    else {
        return
    }
}

function ipProcAgreementCalc(ipAgreementProc) {
    var gipsa = ipAgreementProc.Gipsa && ipAgreementProc.Gipsa === 'Yes' ? " For Gipsa" : ""
    var result = ''
    if (ipAgreementProc.ipdeductaionvalue === "0" && ipAgreementProc.ipdiscountvalue !== "0" && ipAgreementProc.ipfixedamount === "0") {
        if (ipAgreementProc.ipsurgeonfeeperc === "0" && ipAgreementProc.ippackageamountperc === "0" && ipAgreementProc.ipnetbillpercentage !== "0") {
            result = ipAgreementProc.ipnetbillpercentage + "% of the Net Amount" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
        else if (ipAgreementProc.ipsurgeonfeeperc === "0" && ipAgreementProc.ippackageamountperc === "0" && ipAgreementProc.ipnetbillpercentage === "0") {
            result = ipAgreementProc.ipgrossbillpercentage + "% of the Net Amount" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
        else if (ipAgreementProc.ipsurgeonfeeperc === "0" && ipAgreementProc.ipgrossbillpercentage === "0" && ipAgreementProc.ipnetbillpercentage === "0") {
            result = ipAgreementProc.ippackageamountperc + "% of the Net Amount" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
        else {
            result = ipAgreementProc.ipsurgeonfeeperc + "% of the Net Amount" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
    }
    if (ipAgreementProc.ipdeductaionvalue !== "0" && ipAgreementProc.ipdiscountvalue === "0" && ipAgreementProc.ipfixedamount === "0") {
        if (ipAgreementProc.ipsurgeonfeeperc === "0" && ipAgreementProc.ippackageamountperc === "0" && ipAgreementProc.ipnetbillpercentage !== "0") {
            result = ipAgreementProc.ipnetbillpercentage + "% of the Gross Bill excluding Deductions" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
        else if (ipAgreementProc.ipsurgeonfeeperc === "0" && ipAgreementProc.ippackageamountperc === "0" && ipAgreementProc.ipnetbillpercentage === "0") {
            result = ipAgreementProc.ipgrossbillpercentage + "% of the Gross Bill excluding Deductions" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
        else if (ipAgreementProc.ipsurgeonfeeperc === "0" && ipAgreementProc.ipgrossbillpercentage === "0" && ipAgreementProc.ipnetbillpercentage === "0") {
            result = ipAgreementProc.ippackageamountperc + "% of the Gross Bill excluding Deductions" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
        else {
            result = ipAgreementProc.ipsurgeonfeeperc + "% of the Gross Bill excluding Deductions" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
    }
    if (ipAgreementProc.ipdeductaionvalue !== "0" && ipAgreementProc.ipdiscountvalue !== "0" && ipAgreementProc.ipfixedamount === "0") {
        if (ipAgreementProc.ipsurgeonfeeperc === "0" && ipAgreementProc.ippackageamountperc === "0" && ipAgreementProc.ipnetbillpercentage !== "0") {
            result = ipAgreementProc.ipnetbillpercentage + "% of the Net Bill excluding Deductions" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
        else if (ipAgreementProc.ipsurgeonfeeperc === "0" && ipAgreementProc.ippackageamountperc === "0" && ipAgreementProc.ipnetbillpercentage === "0") {
            result = ipAgreementProc.ipgrossbillpercentage + "% of the Net Bill excluding Deductions" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
        else if (ipAgreementProc.ipsurgeonfeeperc === "0" && ipAgreementProc.ipgrossbillpercentage === "0" && ipAgreementProc.ipnetbillpercentage === "0") {
            result = ipAgreementProc.ippackageamountperc + "% of the Net Bill excluding Deductions" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
        else {
            result = ipAgreementProc.ipsurgeonfeeperc + "% of the Net Bill excluding Deductions" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
    }

    if (ipAgreementProc.ippackageamountperc !== "0" && ipAgreementProc.ipdeductaionvalue === "0" && ipAgreementProc.ipdiscountvalue === "0") {
        if (ipAgreementProc.ipfixedamount !== "0") {
            result = "Rs." + ipAgreementProc.ipfixedamount + "/- per case" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
        else {
            result = ipAgreementProc.ippackageamountperc + "% of the Package Amount" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
    }

    if (ipAgreementProc.ipsurgeonfeeperc !== "0" && ipAgreementProc.ipdeductaionvalue === "0" && ipAgreementProc.ipdiscountvalue === "0") {
        if (ipAgreementProc.ipfixedamount !== "0") {
            result = "Rs." + ipAgreementProc.ipfixedamount + "/- per case" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
        else {
            result = ipAgreementProc.ipsurgeonfeeperc + "% of the Surgeon Fee" + gipsa
            if (ipAgreementProc.itemName && (ipAgreementProc.itemName).includes('Consultation')) {
                result = ipAgreementProc.ipsurgeonfeeperc + "% of the Consultation Fee" + gipsa
            }
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
    }

    if (ipAgreementProc.ipnetbillpercentage !== "0" && ipAgreementProc.ipdeductaionvalue === "0" && ipAgreementProc.ipdiscountvalue === "0") {
        if (ipAgreementProc.ipfixedamount !== "0") {
            result = "Rs." + ipAgreementProc.ipfixedamount + "/- per case" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
        else {
            result = ipAgreementProc.ipnetbillpercentage + "% of the Net Bill Amount" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
    }

    if (ipAgreementProc.ipgrossbillpercentage !== "0" && ipAgreementProc.ipdeductaionvalue === "0" && ipAgreementProc.ipdiscountvalue === "0") {
        if (ipAgreementProc.ipfixedamount !== "0") {
            result = "Rs." + ipAgreementProc.ipfixedamount + "/- per case" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
        else {
            result = ipAgreementProc.ipgrossbillpercentage + "% of the Gross Bill Amount" + gipsa
            if (ipAgreementProc.slab === 1) {
                result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
            }
            return result
        }
    }
    if (ipAgreementProc.ipfixedamount !== "0") {
        result = "Rs." + ipAgreementProc.ipfixedamount + "/- per case" + gipsa
        if (ipAgreementProc.slab === 1) {
            result = result + ' for ' + ipAgreementProc.count + ' and more than ' + ipAgreementProc.count + ' procedures'
        }
        return result
    } else {
        return
    }
}

// function ipMgAgreementCalc(opMgAgreement) {

 
// }


exports.calculateIpAgreement = {
    ipAgreementCalculations: ipAgreementCalc,
    ipProcAgreementCalculation: ipProcAgreementCalc,
    // ipMgAgreementCalculation: ipMgAgreementCalc,
};