import React, { useRef, useState } from "react";
// @material-ui/core components
import "date-fns";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Backdrop, CircularProgress, Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const styleSheet = makeStyles((theme) => ({
    paperStyle: { marginTop: "10px", height: "50%" },
    textFieldstyle: {
        marginBottom: "20px",
        marginTop: "20px",
    },
    btnStyle: {
        marginRight: "80px",
        marginLeft: "auto",
        display: "block",
        marginBottom: "20px",
    },
}));
const backDropStyle = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
export default function AddPools({ addPools }) {
    const style = styleSheet();
    const initialFormState = {
        firmName: '',
        IFSC: '',
        BankAccNo: '',
        BranchName: '',
        BankName: '',
    };
    const [backDropOpen, setBackDropOpen] = useState(false);
    const backClasses = backDropStyle();
    const [department, setDepartment] = useState(initialFormState);
    const [state, setState] = useState({
        open: false,
        vertical: "top",
        horizontal: "center",
        message: "",
        color: "",
        icon: "",
    });
    //variables for snack bar
    const { vertical, horizontal, open } = state;
    const handleClose = () => {
        setState({ ...state, open: false });
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDepartment({ ...department, [name]: value });
        if (name === 'IFSC') {
            var IFSCTest = /^[A-Z]{4}0[A-Z0-9]{6}$/;
            if (value.length > 0) {
                if (IFSCTest.test(value)) {
                    if (value.length === 11) {
                        setBackDropOpen(true);
                        axios
                            .get("https://ifsc.razorpay.com/" + value)
                            .then((res) => {
                                setDepartment({ ...department, [name]: value,["BankName"]: res.data.BANK, ["BranchName"]: res.data.BRANCH });
                                setBackDropOpen(false);
                            })
                            .catch((err) => {
                                setBackDropOpen(false);
                                setState({
                                    open: true,
                                    vertical: "top",
                                    horizontal: "center",
                                    message: "Invalid IFSC Code",
                                    color: "error",
                                });
                            });
                    }
                }
            } else {
                setBackDropOpen(false);
                setState({
                    open: true,
                    vertical: "top",
                    horizontal: "center",
                    message: "Invalid IFSC Code",
                    color: "error",
                });
            }
        }
    };

    return (
        <>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    addPools(department);
                }}
            >
                <Paper className={style.paperStyle}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                                className={style.textFieldstyle}
                                variant="outlined"
                                name="firmName"
                                label="Name"
                                value={department.firmName}
                                onChange={handleInputChange}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                                className={style.textFieldstyle}
                                variant="outlined"
                                name="IFSC"
                                label="IFSC"
                                value={department.IFSC}
                                onChange={handleInputChange}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                                className={style.textFieldstyle}
                                variant="outlined"
                                name="BankName"
                                label="Bank Name"
                                value={department.BankName}
                                // onChange={handleInputChange}
                                disabled
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                                className={style.textFieldstyle}
                                variant="outlined"
                                name="BranchName"
                                label="Branch Name"
                                value={department.BranchName}
                                // onChange={handleInputChange}
                                disabled
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <TextField
                                className={style.textFieldstyle}
                                variant="outlined"
                                name="BankAccNo"
                                label="Account Number"
                                value={department.BankAccNo}
                                onChange={handleInputChange}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Button type="submit" color="primary" variant="contained" className={style.btnStyle} style={{ marginTop: '28px', marginLeft: 'inherit' }}>Add</Button>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                autoHideDuration={5000}
                open={open}
                onClose={handleClose}
                key={vertical + horizontal}
            >
                <Alert severity={state.color}>{state.message}</Alert>
            </Snackbar>
            <Backdrop
                className={backClasses.backdrop}
                open={backDropOpen}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}
