import React, {useEffect} from "react";
import { useSelector } from "react-redux";
//import from mui
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
//import component
import Navbar from "../Components/Navbar";
import AppSidebar from "../Components/DrmAppSidebar";
import { Typography } from "@material-ui/core";

export default function DrmTemplate(props) {
  const userdata = useSelector(state => state.user);
  console.log(userdata,"new sidebar");
  return (
    <>
      <CssBaseline />

      <div style={{ marginLeft: "250px" }}>
        <div>
          <Grid container>
            <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
              <AppSidebar />
              <Navbar />
              <Typography
                variant="h3"
                style={{ padding: "20%", fontWeight: "20px", color: "#7f0c34" }}
              >
                WELCOME DRM
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
