import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import {numbersManager} from '../actions/userActions'
var createReactClass = require('create-react-class');
const data = [
  {
    name: 'Jun22',
    Revenue: 90,
    Payout: 40,
  },
  {
    name: 'Jul22',
    Revenue: 30,
    Payout: 10,
  },
  {
    name: 'Aug22',
    Revenue: 80,
    Payout: 20,
  },
  {
    name: 'Sep22',
    Revenue: 65,
    Payout: 27,
  },
  {
    name: 'Oct22',
    Revenue: 60,
    Payout: 18,
  },
  {
    name: 'Nov22',
    Revenue: 120,
    Payout: 23,
  }
];
const colors = ['green', '#FFC658', '#FF847C', '#DE3959', '#E84A5F', '#ffc658', '#FECEAB', '#99B898', '#f77bef', '#003366', '#88eedd', '#a474dc', '#93831d', '#9ab762', '#391a21', '#20b2aa', '#95989d', '#ace1af', '#172e1e', '#b00e18', '#40ff00', '#ff8000', '#ff00bf']
export default function DualBarGraph(props) {
  const CustomizedLabel = createReactClass({
    render: function () {
      const { x, y, fill, value } = this.props;
      console.log(value, "HDJDJDJDJJDJDJDJ")
      return (<text
        x={x}
        y={y}
        dy={-4}
        fontSize='10px'
        fontWeight='bold'
        fill={fill}
        textAnchor="start">{numbersManager(value)}</text>)
    }
  })
  const CustomTooltip = ({ active, payload, label }) => {
    console.log(payload,"arr--------")
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{maxHeight: '250px', overflow:'scroll', pointerEvents: 'auto'}}>
          <p className="label">{label}:</p>
          {Object.keys(payload[0].payload).filter(elem => elem !== 'MonthName').map(item =>
            <p className="label" style={{color:'inherit', display:'flex', allignItems:'left'}}>{`${item} : ${numbersManager(payload[0].payload[item])}`}</p>
          )}
        </div>
      );
    }
    return null;
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={props.RevenueData}
        margin={{
          top: 20,
          right: 15,
          left: 5,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="MonthName" fontSize="9px"/>
        <Tooltip content={<CustomTooltip/>}/>
        <Legend iconSize={9} iconType='circle' />
        {props.CenterList.map((elem, Index) =>
          <Bar dataKey={elem.LegendName} fill={colors[Index]} barSize={41}
            label={<CustomizedLabel />} />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}