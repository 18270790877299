import React, { useState } from "react";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  FormControlLabel,
  Paper,
  RadioGroup,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const styleSheet = makeStyles((theme) => ({
  paperStyle: { marginTop: "10px" },
  textFieldstyle: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  btnStyle: {
    marginRight: "80px",
    marginLeft: "auto",
    display: "block",
    marginBottom: "20px",
  },
}));

var randomColor = Math.floor(Math.random()*16777215).toString(16);
var Color = "#"+randomColor;

export default function AddCenter({ addDepartment, regionRows }) {
  const style = styleSheet();
  const initialFormState = {
    id: null,
    region_id: "",
    name: "",
    code: "",
    ColorCode: "",
    description: "",
    status: "",
  };
  const [department, setDepartment] = useState(initialFormState);
  const [value, setValue] = React.useState("1");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartment({ ...department, [name]: value });
  };
  
  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          department.status = value;
          department.ColorCode = Color;
          addDepartment(department);
        }}
      >
        <Paper className={style.paperStyle}>
          <Grid container>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "40px", marginBottom: "10px" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Region
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="region_id"
                  value={department.region_id}
                  onChange={handleInputChange}
                  label="Select Region"
                >
                  {regionRows &&
                    regionRows.map((opt) => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="name"
                label="Name"
                value={department.name}
                onChange={handleInputChange}
                required
                fullWidth
              />
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="code"
                label="Code"
                value={department.code}
                onChange={handleInputChange}
                required
                fullWidth
              />
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="description"
                label="Description"
                value={department.description}
                onChange={handleInputChange}
                required
                fullWidth
                multiline
              />
              <FormControl component="fieldset"
               style={{ width: "100%", marginTop: "20px", marginBottom: "10px" }}>
                <FormLabel component="legend">Status</FormLabel>
                <RadioGroup
                  row
                  name="status"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="#00963f" />}
                    label="Active"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="#00963f" />}
                    label="Inactive"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={style.btnStyle}
            >
              Add
            </Button>
          </Grid>
        </Paper>
      </form>
    </>
  );
}