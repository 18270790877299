import "./App.css";
import Sidebar from "../Components/Sidebar";
import Chat from "../Components/Chat";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import packageJson from "../../package.json";
import withClearCache from "../ClearCache";
import moment from "moment";

import NotAuthorized from "../Pages/NotAuthorized";
import CeoDashboard from "../Pages/Ceo/CeoDashboard";
import BHDashboard from "../Pages/BusinessHead/Dashboard";
import Dashboard_Doctor from "../Pages/Doctor/Dashboard_Doctor";
import DrmDashboard from "../Pages/DRM/DrmDashboard";
import BHDashboardNew from "../Pages/BusinessHead/BhDashboard";
import DoctorWisePayoutBH from "../Pages/BusinessHead/DoctorWisePayout";
import CenterWisePayoutBH from "../Pages/BusinessHead/CenterWisePayout";
import OverallPayout from "../Pages/BusinessHead/OverallPayout";
import CenterWisePayoutDetailsRD from "../Pages/RegionalDirector/CenterWisePayoutDetailsRD";
import CenterWisePayoutDetailsCh from "../Pages/BusinessHead/CenterWisePayoutDetails";
import DoctorWisePayoutDetailsCh from "../Pages/BusinessHead/DoctorWisePayoutDetails";
import CenterWiseBillingReports from "../Pages/BusinessHead/CenterWiseBillingReports";
import DoctorwiseUnprocessed from "../Pages/BusinessHead/DoctorwiseUnprocessed";
import DoctorDashboard from "../Pages/Doctor/DoctorDashboard";
import CenterWisePayoutDoctor from "../Pages/Doctor/CenterWisePayout";
import DoctorWisePayoutDoctor from "../Pages/Doctor/DoctorWisePayout";
import CenterWisePayoutDetailsDr from "../Pages/Doctor/CenterWisePayoutDetails";
import DoctorWisePayoutDetailsDr from "../Pages/Doctor/DoctorWisePayoutDetails";
import RdDashboard from "../Pages/RegionalDirector/RdDashboard";
import Count from "../Pages/RegionalDirector/Count";
import HRDashboard from "../Pages/HumanResource/HrDashboard";
import DoctorProfileHR from "../Pages/HumanResource/DoctorProfileHR";
import DoctorProfileRD from "../Pages/RegionalDirector/DoctorProfileRD";
import History from "../Pages/BusinessHead/History";
import SignUp from "../Pages/Login/SignUp";
import ForgotPassword from "../Pages/Login/ForgotPassword";
import LoginPage from "../Pages/Login/LoginPage";
import DoctorForm from "../Pages/BusinessHead/DoctorForm";
import OnboardForm from "../Pages/BusinessHead/OnboardForm";
import RdStatus from "../Pages/RegionalDirector/status";
import SignedAgreement from "../Pages/RegionalDirector/SignedAgreement";
import VacancyApproval from "../Pages/RegionalDirector/VacancyApproval";
import UploadDocuments from "../Pages/Doctor/UploadDocuments";
import UploadedDocument from "../Pages/Doctor/UploadedDocument";
import DoctorProfileStatus from "../Pages/Doctor/DoctorProfileStatus";
import CeoProfile from "../Pages/Ceo/Ceo-Profile";
import BHProfile from "../Pages/BusinessHead/BH-profile";
import HRProfile from "../Pages/HumanResource/HR-profile";
import RDProfile from "../Pages/RegionalDirector/RD-profile";
import Rddiscontinue from "../Pages/RegionalDirector/Rddiscontinue";
import DoctorProfileBH from "../Pages/BusinessHead/DoctorProfileBH";
import BHBillingDetails from "../Pages/BusinessHead/BHBillingDetails";
import UnProcessedPayout from "../Pages/BusinessHead/UnProcessedPayout";
import Userprofile from "../Pages/Setting/UserProfile";
import UserProfileDetail from "../Pages/Setting/UserProfileDetail";
// import FixedMG from "../Pages/fixed";
import Agreements from "../Pages/HumanResource/Agreements";
import DoctorOpPayout from "../Pages/Doctor/DoctorOpPayout";
import ViewDoctors from "../Pages/BusinessHead/ViewDoctors";
import DoctorAgreements from "../Pages/BusinessHead/DoctorAgreements";
import ManageBranch from "../Pages/Branch/ManageBranch";
import DoctorInfo from "../Components/DoctorInfo";
import PayoutPage from "../Pages/Payout/PayoutPage";
import PayoutReport from "../Pages/Payout/PayoutReport";
import GenerateOpPayout from "../Pages/Payout/GenerateOpPayout";
import PayableItem from "../Pages/Payout/PayableItem";
import ManageIpItemMaster from "../Pages/Payout/IPItemMaster/ManageIpItemMaster";
import ManageDelivery from "../Pages/Payout/ManageDelivery";
import ManageGynec from "../Pages/Payout/ManageGynec";
import ManageIvfv from "../Pages/Payout/ManageIvfv";
import ManageBabyReceiving from "../Pages/Payout/ManageBabyReceiving";
import ManageAnesthetist from "../Pages/Payout/ManageAnesthetist";
import ManageIpvisit from "../Pages/Payout/ManageIpvisit";
import PayoutDashboard from "../Pages/Payout/PayoutDashboard";
import PayoutDashboardNew from "../Pages/Payout/PayoutDashboardNew";
import UploadIpFiles from "../Pages/Payout/UploadIpFiles";
import OpPayoutProcess from "../Pages/Payout/OpPayoutProcess";
import IpPayoutProcess from "../Pages/Payout/IpPayoutProcess";
import ChEditDiscount from "../Pages/BusinessHead/ChEditDiscount";
import MiscOpCh from "../Pages/BusinessHead/MiscOpCh";
import IpBillItemDetail from "../Pages/Payout/IpBillItemDetail";
import IpLos from "../Pages/Payout/IpLos";
import DoctorOpParameter from "../Pages/Payout/DoctorOpParameter";
import ManageCommercials from "../Pages/Payout/DoctorCommercialMaster/ManageCommercials";
// import ManageIpCommercials from "../Pages/Payout/DoctorIpCommercialMaster/ManageIpCommercials";
import ManageDoctor from "../Pages/Payout/DoctorMaster/ManageDoctor";
import ManagePayableNonPayable from "../Pages/Payout/PayableNonPayableMaster/ManagePayableNonPayable";
import ManageIpPayableNonPayable from "../Pages/Payout/IpPayableNonPayableMaster/ManageIpPayableNonPayable";
import DIscardPayout from "../Pages/Payout/DIscardPayout";
// import PayoutDashboardNew from "../Pages/Payout/NEW/PayoutDashboardNew";
import DoctorProfile from "../Pages/Doctor/DoctorProfile";
import Doctortest from "../Pages/Doctor/Doctortest";
import ManageDesignation from "../Pages/Designation/ManageDesignation";
import ManageCenterPositionMap from "../Pages/CenterPositionMap/ManageCenterPositionMap";
import ManageResources from "../Pages/Resources/ManageResources";
import ManagePermissions from "../Pages/Permissions/ManagePermissions";
import ManageDepartment from "../Pages/Department/ManageDepartment";
import ManageRoles from "../Pages/Roles/RoleList";
import ManageAgreementAnnexure from "../Pages/AgreementAnnexure/ManageAgreementAnnexure";
import CountReport from "../Pages/Reports/CountReport";
import ManageUserMapCenter from "../Pages/UserMapCenter/ManageUserMapCenter";
import ManageFirmMapping from "../Pages/FirmMapping/ManageFirmMapping";
import ManagePoolMapping from "../Pages/PoolMapping/ManagePoolMapping";
import ManageUserRegionMap from "../Pages/UserRegionMap/ManageUserRegionMap";
// import ManageUserMapping from "../Pages/UserMapping/ManageUserMapping";
//import ManagePayoutMemberMap from "../Pages/PayoutMemberMapping/ManagePayoutMemberMap";
//import ManageDrmMapping from "../Pages/DrmMapping/ManageDrmMapping";
import ManageUser from "../Pages/CreateUser/ManageUser";
import ManageDoctors from '../Admin/Doctor/ManageDoctors'
import ManageCenter from "../Pages/Center/ManageCenter";
import ManageRegion from "../Pages/Region/ManageRegion";
import RejectedRequests from "../Pages/BusinessHead/RejectedRequests";
import ManageProcedure from "../Pages/Procedure/ManageProcedure";
import ManageServiceCatagory from "../Pages/ServiceCatagory/ManageServiceCatagory";
import ManageProcedureCatagory from "../Pages/procedureCatagory/ManageProcedureCatagory";
import ManageParameter from "../Pages/Parameter/ManageParameter";
import ManageCommercialCatagory from "../Pages/CommercialCategory/ManageCommercialCatagory";
import ManageDomainCatagory from "../Pages/DomainCatagory/ManageDomainCatagory";
import MangeExcelFile from "../Pages/UploadExcelFile/MangeExcelFile";
import MangeIpExcelFile from "../Pages/UploadipExcelFile/MangeExcelFile";
//MangeIpExcelFile
import CalculationBH from "../Pages/BusinessHead/CalculationBH";
import DoctorFinalOnboard from "../Pages/HumanResource/DoctorFinalOnboard";
import AddQualification from "../Pages/Doctor/addQualification";
import ipHead from "../Pages/IpHead/ipHeadDashboard";
import AddEmployer from "../Pages/Doctor/addEmployer";
import ProfileDetails from "../Pages/Doctor/ProfileDetails";

// for temporary testing of code
import Temp from "../Pages/Payout/Temp";
import TestDashboard from "../Pages/BusinessHead/TestDashboard";
import ViewStatus from "../Pages/RegionalDirector/ViewStatus";
import CenterWisePayout from "../Pages/RegionalDirector/CenterWisePayout";
import DoctorWisePayout from "../Pages/RegionalDirector/DoctorWisePayout";
import DoctorWisePayoutDetailsRD from "../Pages/RegionalDirector/DoctorWisePayoutDetailsRD";
import CenterWIsePayoutDetails from "../Pages/Payout/CenterWIsePayoutDetails";
import DoctorWisePayoutDetails from "../Pages/Payout/DoctorWisePayoutDetails";
import BStatus from "../Pages/BusinessHead/ViewStatus";

import Test from "../Pages/Payout/test";
import FixedConsultant from "../Pages/Payout/FixedConsultant";
import MGConsultant from "../Pages/Payout/MGConsultant";
import VisitingConsultant from "../Pages/Payout/VisitingConsultant";
import CheckAgreement from "../Pages/Doctor/CheckAgreement";
import AgreementStatus from "../Pages/HumanResource/CheckAgreement";
import BHCheckAgreement from "../Pages/BusinessHead/CheckAgreement";
import RDCheckAgreement from "../Pages/RegionalDirector/CheckAgreement";
import CeoAgreement from "../Pages/Ceo/Ceo-Agreement";
import DoctorProfileFinal from "../Pages/HumanResource/DoctorProfileFinal";
import DoctorList from "../Pages/DoctorList";
import DoctorListHR from "../Pages/HumanResource/DoctorListHR";
import FinanceDashboard from "../Pages/FinanceTeam/FinanceDashboard";
import FinanceProfile from "../Pages/FinanceTeam/FinanceProfile";
import DoctorListRd from "../Pages/RegionalDirector/DoctorListRd";
import UploadedDocHR from "../Pages/HumanResource/uploadedDocHR";
import CalculationData from "../Pages/RegionalDirector/CalculationData";
import RenewalProcess from "../Pages/BusinessHead/RenewalProcess";
import SignedAgreement1 from "../Pages/BusinessHead/SignedAgreement";
import IndeminityProcess from "../Pages/BusinessHead/IndeminityProcess";
import McrcProcess from "../Pages/BusinessHead/McrcProcess";
import ChangePassword from "../Pages/BusinessHead/ChangePassword";
import ResetPassword from "../Pages/Setting/ResetPassword";
import Estimation from "../Pages/Doctor/Estimation";
import Template from "../Pages/template";
import CFODashBoard from "../Pages/CFODashBoard";
import DrmTemplate from "../Pages/Drrmtemplate";
import LOSPayoutProcess from "../Pages/Payout/LOSPayoutProcess";

import BillingDetails from "../Pages/BillingDetails";
import PayoutDetails from "../Pages/PayoutDetails";
import BillingLineitem from "../Pages/BillingLineitem";
import UploadCommercials from "../Pages/Payout/UploadCommercials";
// Agreements
import FixedfullTime from "../Pages/Payout/NEW/FixedfullTime";
import FixedMG from "../Pages/Payout/NEW/fixedMG";
import VcChennai from "../Pages/Payout/NEW/VcChennai";
import VisitingCon from "../Pages/Payout/NEW/VisitingCon";

// Draft Agreement
import DraftMG from "../Pages/Payout/NEW/draftMG";
import DraftFixedfullTime from "../Pages/Payout/NEW/DraftFixedfullTime";
import DraftVisitingCon from "../Pages/Payout/NEW/DraftVisitingCon";

import UploadJoining from "../Pages/DRM/UploadJoining";
import ViewDocument from "../Pages/DRM/ViewDocument";
import ViewProfile from "../Pages/DRM/ViewProfile";
import ActivityAssignment from "../Pages/ActivityMapping/ActivityAssignment";
import ManageAgreementTemplate from "../Pages/AgreementTemplate/ManageAgreementTemplate";
import ManagePools from "../Pages/FirmOrPools/ManagePools";
import { AppContext } from '../actions/contextApi';
// import LOSManagement from "../user/LOSManagement/LOSManagement";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ManageRequisitions from "../Admin/Requisition/ManageRequisitions";

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  const [centerNames, setCenterNames] = useState([])
  const [Months, setMonths] = useState([])
  const [Years, setYears] = useState([])
  const [itemGroupNames, setitemGroupNames] = useState([])
  const [DoctorNames, setDoctorNames] = useState([])
  const [regionNames, setRegionNames] = useState([])
  const [LOSName, setLOSName] = useState([])
  const [IPOPName, setIPOPName] = useState([])
  const [graphFilter, setGraphFilter] = useState('')
  const [graphFilterByDept, setGraphFilterByDept] = useState('')
  const [graphId, setGraphId] = useState()
  const [graphFilterByGroup, setGraphFilterByGroup] = useState('')
  const [graphFilterByYear, setGraphFilterByYear] = useState('')
  const [graphFilterByLegend, setGraphFilterByLegend] = useState('')
  const [tableHeaderName, setTableHeaderName] = useState('');
  const [tableShow, setTableShow] = useState(false)
  const ref = useRef(null);
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;
  toast.configure();
  const reqTableData = [
    {
      requestId: "N1234",
      requestType: "Onboarding",
      requestRaisedBy: "Business Head",
      date: "07/12/2018",
      subject: "Onboarding",
      currentStatus: "ongoing",
    },
    {
      requestId: "N1234",
      requestType: "Payout",
      requestRaisedBy: "Regional Director",
      date: "07/12/2018",
      subject: "Onboarding",
      currentStatus: "ongoing",
    },
    {
      requestId: "N1234",
      requestType: "Renewal",
      requestRaisedBy: "Doctor",
      date: "07/12/2018",
      subject: "Onboarding",
      currentStatus: "ongoing",
    },
    {
      requestId: "N1234",
      requestType: "Postion",
      requestRaisedBy: "Business Head",
      date: "07/12/2018",
      subject: "Onboarding",
      currentStatus: "ongoing",
    },
    {
      requestId: "N1234",
      requestType: "Onboarding",
      requestRaisedBy: "BH",
      date: "07/12/2018",
      subject: "Onboarding",
      currentStatus: "completed",
    },
    {
      requestId: "N1234",
      requestType: "Position",
      requestRaisedBy: "BH",
      date: "07/12/2018",
      subject: "Onboarding",
      currentStatus: "ongoing",
    },
  ];

  const payoutExceptionData = [
    {
      exceptionId: "P1234",
      doctorName: "Dr Ajay Arora",
      doctorBranch: "Jayanagar",
      date: "24/06/2021",
      errorMessage: "?",
      currentStatus: "BH Approval Pending",
    },
    {
      exceptionId: "P1354",
      doctorName: "Dr Sharan Kumar",
      doctorBranch: "Bellandur",
      date: "26/06/2021",
      errorMessage: "?",
      currentStatus: "Payout Intervention Required",
    },
    {
      exceptionId: "P1211",
      doctorName: "Dr Rahul Kumar",
      doctorBranch: "Jayanagar",
      date: "23/06/2021",
      errorMessage: "?",
      currentStatus: "BH Approval passed",
    },
    {
      exceptionId: "P1320",
      doctorName: "Dr ABC",
      doctorBranch: "Chennai",
      date: "25/06/2021",
      errorMessage: "?",
      currentStatus: "Payout Intervention Required",
    },
    {
      exceptionId: "P1120",
      doctorName: "Dr DEF",
      doctorBranch: "Jayanagar",
      date: "22/06/2021",
      errorMessage: "?",
      currentStatus: "COO Approval Pending",
    },
    {
      exceptionId: "P1222",
      doctorName: "Dr GHI",
      doctorBranch: "Malleshwaram",
      date: "24/06/2021",
      errorMessage: "?",
      currentStatus: "Doctor Confirmation Pending",
    },
  ];

  const TopTenPayouts = [
    {
      srNo: 0,
      doctorName: "Dr Ajay Arora",
      doctorBranch: "Jayanagar",
      Payout: "50lpa",
    },
    {
      srNo: 1,
      doctorName: "Dr Kalpa Sharma",
      doctorBranch: "Kanakpura Road",
      Payout: "50lpa",
    },
    {
      srNo: 2,
      doctorName: "Dr Satish",
      doctorBranch: "Jayanagar",
      Payout: "48lpa",
    },
    {
      srNo: 3,
      doctorName: "Dr Jacob Arora",
      doctorBranch: "Jayanagar",
      Payout: "45lpa",
    },
    {
      srNo: 4,
      doctorName: "Dr Nidhi Lathi",
      doctorBranch: "Chennai",
      Payout: "43lpa",
    },
    {
      srNo: 5,
      doctorName: "Dr Sanjeev",
      doctorBranch: "Jayanagar",
      Payout: "40lpa",
    },
    {
      srNo: 6,
      doctorName: "Dr Soham",
      doctorBranch: "Jayanagar",
      Payout: "40lpa",
    },
    {
      srNo: 7,
      doctorName: "Dr Karthik",
      doctorBranch: "Jayanagar",
      Payout: "35lpa",
    },
    {
      srNo: 8,
      doctorName: "Dr Pushpa",
      doctorBranch: "Jayanagar",
      Payout: "34lpa",
    },
    {
      srNo: 9,
      doctorName: "Dr Krishna",
      doctorBranch: "Jayanagar",
      Payout: "30lpa",
    },
  ];
  return (
    <div className="App" dataBuildDate={getBuildDate(packageJson.buildDate)}>
      {/*<Dashboard/>
      <DoctorForm/>*/}
      <AppContext.Provider value={{ centerNames, setCenterNames, Months, setMonths, Years, setYears, itemGroupNames, setitemGroupNames, DoctorNames, setDoctorNames, regionNames, setRegionNames, graphFilter, setGraphFilter, graphFilterByGroup, setGraphFilterByGroup, graphFilterByYear, setGraphFilterByYear, graphFilterByLegend, setGraphFilterByLegend, tableShow, setTableShow, tableHeaderName, setTableHeaderName, ref, graphFilterByDept, setGraphFilterByDept, LOSName, setLOSName, IPOPName, setIPOPName, graphId, setGraphId }}>
        <Router>
          {/* <Route exact path="/sidebar"> */}
          {/* <Sidebar userType={"RD"} /> */}
          {/* </Route>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/SignUp" component={SignUp} />
        <Route exact path="/ForgotPassword" component={ForgotPassword} />
        <Route exact path="/Doctor-Profile" component={DoctorProfile} />
        <Route exact path="/Doctortest" component={Doctortest} />
        <Route exact path="/DoctorProfileStatus" component={DoctorProfileStatus} />
        {/* <Route exact pathe="/view-status" component={ViewStatus} /> */}
          {/* <Route exact path="/bh-dashboard" component={BHDashboard} /> */}
          {/* <BHDashboard reqTableData={reqTableData} /> */}
          {/* </Route> */}
          {/* <Route exact path="/rd-dashboard">
          <RdDashboard reqTableData={reqTableData} />
        </Route>
        <Route exact path="/DoctorDashboard">
          <DoctorDashboard reqTableData={reqTableData} />
        </Route>
        <Route exact path="/payout-dashboard">
          <PayoutDashboard reqTableData={payoutExceptionData} />
        </Route>  */}
          <Switch>
            <Route exact path="/sidebar">
              {/* <Sidebar userType={"RD"} /> */}
            </Route>
            <Route exact path="/" component={LoginPage} />
            <Route exact path="/SignUp" component={SignUp} />
            <Route exact path="/ForgotPassword" component={ForgotPassword} />
            <Route exact path="/Doctor-Profile" component={DoctorProfile} />
            <Route exact path="/Doctortest" component={Doctortest} />
            <Route exact path="/ResetPassword" component={ResetPassword} />
            <Route exact path="/ipHead" component={ipHead} />
            <Route exact path="/payout-dashboard">
              <PayoutDashboard reqTableData={payoutExceptionData} />
            </Route>
            <Route exact path="/BillingLineitem" component={BillingLineitem} />
            <Route exact path="/BillingDetails" component={BillingDetails} />
            <Route exact path="/PayoutDetails" component={PayoutDetails} />
            <Route exact path="/chat" component={Chat} />
            <Route exact path="/CeoProfile" component={CeoProfile} />
            <Route exact path="/BHProfile" component={BHProfile} />
            <Route exact path="/HRProfile" component={HRProfile} />
            <Route exact path="/RDProfile" component={RDProfile} />

            <Route exact path="/hr-agreements" component={Agreements} />
            <Route exact path="/create-doctor" component={DoctorForm} />
            <Route exact path="/Template" component={Template} />
            <Route exact path="/CFODashBoard" component={CFODashBoard} />
            <Route exact path="/manage-agreement-template" component={ManageAgreementTemplate} />
            <Route exact path="/manage-firms-or-pools" component={ManagePools} />
            <Route exact path="/DrmTemplate" component={DrmTemplate} />

            <Route exact path="/onboard-doctor" component={OnboardForm} />
            <Route exact path="/RejectedRequests" component={RejectedRequests} />
            <Route exact path="/upload-documents" component={UploadDocuments} />
            <Route exact path="/UploadedDocument" component={UploadedDocument} />
            <Route exact path="/view-doctors" component={ViewDoctors} />
            <Route exact path="/doctor-agreements" component={DoctorAgreements} />
            {/* <Route exact path="/UploadCommercials" component={UploadCommercials} /> */}
            <Route exact path="/doctor-info" component={DoctorInfo} />

            <Route exact path="/payout-page" component={PayoutPage} />
            <Route exact path="/GenerateOpPayout" component={GenerateOpPayout} />

            <Route exact path="/TestDashboard" component={TestDashboard} />
            <Route exact path="/DrmDashboard" component={DrmDashboard} />
            <Route exact path="/retainer-payout" component={LOSPayoutProcess} />
            <Route
              exact
              path="/manage-designation"
              component={ManageDesignation}
            />
            <Route
              exact
              path="/manage-position"
              component={ManageCenterPositionMap}
            />
            <Route exact path="/manage-resource" component={ManageResources} />
            <Route
              exact
              path="/manage-permissions"
              component={ManagePermissions}
            />
            <Route exact path="/DoctorDashboard">
              <DoctorDashboard reqTableData={reqTableData} />
            </Route>

            <Route
              exact
              path="/manage-usermapcenter"
              component={ManageUserMapCenter}
            />
            {/* <Route
              exact
              path="/manage-UserMapping"
              component={ManageUserMapping}
            /> */}

            <Route
              exact
              path="/manage-firm-mapping"
              component={ManageFirmMapping}
            />

            <Route exact path="/manage-pool-mapping" component={ManagePoolMapping} />

            {/* <Route exact path="/manage-drmmapping" component={ManageDrmMapping} /> */}
            {/* <Route
              exact
              path="/manage-userregionmap"
              component={ManageUserRegionMap}
            /> */}

            {/* <Route
              exact
              path="/payout-member-map"
              component={ManagePayoutMemberMap}
            /> */}
            <Route exact path="/count-report" component={CountReport} />
            <Route exact path="/manage-department" component={ManageDepartment} />
            <Route exact path="/manage-roles" component={ManageRoles} />
            <Route
              exact
              path="/manage-agreement-annexure"
              component={ManageAgreementAnnexure}
            />

            <Route exact path="/manage-region" component={ManageRegion} />
            <Route exact path="/manage-user" component={ManageUser} />
            <Route exact path="/manage-doctors" component={ManageDoctors} />
            <Route exact path="/manage-requisitions" component={ManageRequisitions} />
            <Route exact path="/manage-center" component={ManageCenter} />
            <Route exact path="/activity-assignment" component={ActivityAssignment} />

            <Route exact path="/ManageBranch" component={ManageBranch} />
            {/* <Route exact path="/FixedMG" component={FixedMG} /> */}
            <Route exact path="/manage-procedure" component={ManageProcedure} />
            <Route exact path="/fixedagmt" component={FixedfullTime} />
            <Route exact path="/mgagmt" component={FixedMG} />
            <Route exact path="/vcchennai" component={VcChennai} />
            <Route exact path="/visitcon" component={VisitingCon} />
            <Route exact path="/draftMG" component={DraftMG} />
            <Route
              exact
              path="/draftFixedfullTime"
              component={DraftFixedfullTime}
            />
            <Route exact path="/draftVisitingCon" component={DraftVisitingCon} />
            <Route
              exact
              path="/service-category"
              component={ManageServiceCatagory}
            />
            <Route
              exact
              path="/procedure-category"
              component={ManageProcedureCatagory}
            />
            <Route exact path="/manage-parameter" component={ManageParameter} />

            <Route
              exact
              path="/manage-commercial"
              component={ManageCommercialCatagory}
            />
            <Route exact path="/manage-domain" component={ManageDomainCatagory} />
            <Route exact path="/manage-file" component={MangeExcelFile} />
            <Route
              exact
              path="/manage-ipbill-file"
              component={MangeIpExcelFile}
            />

            <Route exact path="/fixed-consultant" component={FixedConsultant} />

            <Route
              exact
              path="/visiting-consultant"
              component={VisitingConsultant}
            />

            <Route
              exact
              path="/DoctorFinalOnboard"
              component={DoctorFinalOnboard}
            />

            <Route exact path="/mg-consultant" component={MGConsultant} />
            <Route exact path="/user-profile" component={Userprofile} />

            {/* <Route exact path="/bh-agreement" component={BHCheckAgreement} /> */}
            <Route exact path="/DoctorProfileHR" component={DoctorProfileHR} />
            <Route exact path="/DoctorProfileRD" component={DoctorProfileRD} />
            <Route
              exact
              path="/DoctorProfileFinal"
              component={DoctorProfileFinal}
            />

            <Route exact path="/CeoDashboard" component={CeoDashboard} />

            {/* shreyansh payout */}
            <Route exact path="/Test">
              <Test reqTableData={payoutExceptionData} />
            </Route>

            {/* Arya Payout */}
            <Route exact path="/temp">
              <Temp
                PayoutExceptionData={payoutExceptionData}
                TopTenPayouts={TopTenPayouts}
              />
            </Route>

            <Route exact path="/hr-dashboard" component={HRDashboard} />
            <Route exact path="/UploadedDocHR" component={UploadedDocHR} />
            <Route exact path="/hr-agreement" component={AgreementStatus} />
            <Route exact path="/DoctorListHR" component={DoctorListHR} />

            <Route exact path="/bh-dashboard">
              <BHDashboard reqTableData={reqTableData} />
            </Route>

            <Route exact path="/doctor-dashboard">
              <Dashboard_Doctor reqTableData={reqTableData} />
            </Route>

            <Route
              exact
              path="/ippayout-process"
              component={IpPayoutProcess}
            />
            <Route
              exact
              path="/DoctorWisePayout"
              component={DoctorWisePayoutBH}
            />
            <Route
              exact
              path="/History"
              component={History}
            />
            <Route
              exact
              path="/BHBillingDetails"
              component={BHBillingDetails}
            />
            <Route
              exact
              path="/DoctorwiseUnprocessed"
              component={DoctorwiseUnprocessed}
            />
            <Route
              exact
              path="/CenterWiseBillingReports"
              component={CenterWiseBillingReports}
            />
            <Route
              exact
              path="/CenterWisePayout"
              component={CenterWisePayoutBH}
            />
            <Route
              exact
              path="/CenterWIsePayoutDetails"
              component={CenterWisePayoutDetailsCh}
            />
            <Route exact path="/OverallPayout" component={OverallPayout} />
            <Route
              exact
              path="/DoctorWIsePayoutDetails"
              component={DoctorWisePayoutDetailsCh}
            />
            <Route exact path="/bhdashboard" component={BHDashboardNew} />
            <Route exact path="/ChangePassword" component={ChangePassword} />
            <Route exact path="/CalculationBH" component={CalculationBH} />
            <Route exact path="/view-status" component={BStatus} />
            <Route exact path="/bh-agreement" component={BHCheckAgreement} />
            <Route exact path="/DoctorList" component={DoctorList} />
            <Route exact path="/mcrc-process" component={McrcProcess} />
            <Route exact path="/MiscOpCh" component={MiscOpCh} />
            <Route exact path="/ChEditDiscount" component={ChEditDiscount} />
            <Route
              exact
              path="/DoctorProfileBH"
              component={DoctorProfileBH}
            />
            <Route
              exact
              path="/UnProcessedPayout"
              component={UnProcessedPayout}
            />
            <Route exact path="/renewal-process" component={RenewalProcess} />
            <Route
              exact
              path="/signed-agreement"
              component={SignedAgreement1}
            />
            <Route
              exact
              path="/indeminity-process"
              component={IndeminityProcess}
            />
            <Route exact path="/ChangePassword" component={ChangePassword} />
            <Route exact path="/rd-dashboard">
              <RdDashboard reqTableData={reqTableData} />
            </Route>
            <Route exact path="/Rddiscontinue">
              <Rddiscontinue reqTableData={reqTableData} />
            </Route>
            <Route exact path="/count-reports" component={Count} />

            <Route exact path="/rdStatus" component={RdStatus} />
            <Route
              exact
              path="/signed-agreement"
              component={SignedAgreement}
            />

            <Route
              exact
              path="/ippayout-process"
              component={IpPayoutProcess}
            />

            <Route
              exact
              path="/vacancy-approval"
              component={VacancyApproval}
            />

            <Route
              exact
              path="/rd-approvalcalc"
              component={CalculationData}
            />
            <Route exact path="/rd-agreement" component={RDCheckAgreement} />
            <Route exact path="/DoctorListRd" component={DoctorListRd} />
            <Route
              exact
              path="/CenterWisePayoutDetailsRD"
              component={CenterWisePayoutDetailsRD}
            />
            <Route
              exact
              path="/DoctorWisePayout"
              component={DoctorWisePayout}
            />
            <Route
              exact
              path="/CenterWisePayout"
              component={CenterWisePayout}
            />
            <Route
              exact
              path="/CenterWIsePayoutDetails"
              component={CenterWIsePayoutDetails}
            />
            <Route
              exact
              path="/DoctorWisePayoutDetailsRD"
              component={DoctorWisePayoutDetailsRD}
            />
            <Route exact path="/UploadJoining" component={UploadJoining} />
            <Route exact path="/ViewDocument" component={ViewDocument} />
            <Route exact path="/ViewProfile" component={ViewProfile} />
            <Route exact path="/ChangePassword" component={ChangePassword} />
            <Route
              exact
              path="/CenterWisePayout"
              component={CenterWisePayoutDoctor}
            />
            <Route
              exact
              path="/DoctorWisePayout"
              component={DoctorWisePayoutDoctor}
            />

            <Route
              exact
              path="/CenterWisePayoutDetails"
              component={CenterWisePayoutDetailsDr}
            />
            <Route
              exact
              path="/DoctorWisePayoutDetails"
              component={DoctorWisePayoutDetailsDr}
            />
            <Route exact path="/DoctorDashboard">
              <DoctorDashboard reqTableData={reqTableData} />
            </Route>
            <Route
              exact
              path="/DoctorProfileStatus"
              component={DoctorProfileStatus}
            />
            <Route exact path="/check-agreement" component={CheckAgreement} />
            <Route
              exact
              path="/AddQualification"
              component={AddQualification}
            />
            <Route exact path="/DoctorOpPayout" component={DoctorOpPayout} />
            <Route exact path="/ProfileDetails" component={ProfileDetails} />
            <Route exact path="/Estimation" component={Estimation} />
            <Route exact path="/AddEmployer" component={AddEmployer} />
            <Route exact path="/ChangePassword" component={ChangePassword} />
            <Route
              exact
              path="/UserProfileDetail"
              component={UserProfileDetail}
            />
            <Route exact path="/CeoAgreement" component={CeoAgreement} />
            <Route
              exact
              path="/FinanceDashboard"
              component={FinanceDashboard}
            />
            <Route exact path="/FinanceProfile" component={FinanceProfile} />
            <Route
              exact
              path="/payout-dasboard"
              component={PayoutDashboardNew}
            />
            <Route exact path="/UploadIpFiles" component={UploadIpFiles} />
            <Route
              exact
              path="/UploadCommercials"
              component={UploadCommercials}
            />
            <Route
              exact
              path="/oppayout-process"
              component={OpPayoutProcess}
            />
            <Route
              exact
              path="/ippayout-process"
              component={IpPayoutProcess}
            />
            <Route
              exact
              path="/ip-billitem-details"
              component={IpBillItemDetail}
            />
            <Route exact path="/ip-los" component={IpLos} />

            <Route
              exact
              path="/manage-doctor-commercial"
              component={DoctorOpParameter}
            />

            <Route
              exact
              path="/ManageCommercials"
              component={ManageCommercials}
            />

            <Route exact path="/Manage-doctor" component={ManageDoctor} />
            <Route
              exact
              path="/manage-payable-nonpayable"
              component={ManagePayableNonPayable}
            />
            <Route
              exact
              path="/manage-ip-payable-nonpayable"
              component={ManageIpPayableNonPayable}
            />

            <Route
              exact
              path="/manage-discard-payout"
              component={DIscardPayout}
            />
            <Route exact path="/payout-report" component={PayoutReport} />
            <Route exact path="/manage-payable" component={PayableItem} />
            <Route exact path="/manage-delivery" component={ManageDelivery} />
            <Route exact path="/manage-gynec" component={ManageGynec} />
            <Route exact path="/manage-ivfv" component={ManageIvfv} />
            <Route
              exact
              path="/manage-babyreceiving"
              component={ManageBabyReceiving}
            />
            <Route
              exact
              path="/manage-anesthetist"
              component={ManageAnesthetist}
            />
            <Route exact path="/manage-ipvisit" component={ManageIpvisit} />

            <Route
              exact
              path="/manage-ipitem-master"
              component={ManageIpItemMaster}
            />
            {/* <Route exact path="/*" component={NotAuthorized} /> */}
          </Switch>
        </Router>
      </AppContext.Provider>
    </div>
  );
}

export default App;
