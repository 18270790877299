function opAgreementCalc(opAgreement) {

    if (opAgreement.opdiscounttype === "Yes" &&
        opAgreement.opadhoc !== "0") {
        if (opAgreement.fixedpercentage !== "0") {
            return opAgreement.fixedpercentage +
                "% of the Net Bill excluding Deductions "
        }
        else {
            return "Rs "+ opAgreement.fixedamount + " /- Per case "
        }
    }
    if (opAgreement.opdiscounttype === "No" &&
        opAgreement.opadhoc === "0") {
        if (opAgreement.fixedpercentage !== "0") {
            return opAgreement.fixedpercentage +
                " % of the Gross Amount "
        }
        else {
            return "Rs "+opAgreement.fixedamount + " /- Per case "
        }
    }


    if (opAgreement.opdiscounttype === "Yes" &&
        opAgreement.opadhoc === "0") {
        if (opAgreement.fixedpercentage !== "0") {
            return opAgreement.fixedpercentage +
                " % of the Net Amount "
        }
        else {
            return "Rs "+opAgreement.fixedamount + " /- Per case "
        }
    }


    if (opAgreement.opdiscounttype === "No" &&
        opAgreement.opadhoc !== "0") {
        if (opAgreement.fixedpercentage !== "0") {
            return opAgreement.fixedpercentage +
                "% of the Gross Bill excluding Deductions "
        }
        else {
            return "Rs "+opAgreement.fixedamount + " /- Per case "
        }
    }
    else {
        return ""
    }
}

function opMgAgreementCalc(opMgAgreement) {

    if (opMgAgreement.opadhoc === "0" && opMgAgreement.opdiscounttype === "Yes") {
        if (opMgAgreement.fixedpercentage !== "0") {
            return opMgAgreement.fixedpercentage + "% of the fee"
        }
        else {
            return "Rs" + opMgAgreement.fixedamount + "/- per case"
        }
    }
    if (opMgAgreement.opadhoc === "0" && opMgAgreement.opdiscounttype === "No") {
        if (opMgAgreement.fixedpercentage !== "0") {
            return opMgAgreement.fixedpercentage + "% of the fee"
        }
        else {
            return "Rs "+opMgAgreement.fixedamount + " /- Per case "
        }
    }
    if (opMgAgreement.opadhoc !== "0" && opMgAgreement.opdiscounttype === "No") {
        if (opMgAgreement.fixedpercentage !== "0") {
            return opMgAgreement.fixedpercentage + "% of the fee"
        }
        else {
            return "Rs "+opMgAgreement.fixedamount + " /- Per case "
        }
    }
    if (opMgAgreement.opadhoc !== "0" && opMgAgreement.opdiscounttype === "Yes") {
        if (opMgAgreement.fixedpercentage !== "0") {
            return opMgAgreement.fixedpercentage + "% of the fee"
        }
        else {
            return "Rs "+opMgAgreement.fixedamount + " /- Per case "
        }
    }
    if (opMgAgreement.totalRevenue !== "0") {
        return "Rs" + opMgAgreement.totalRevenue + "/- per case"
    }
    if (opMgAgreement.fixedpercentage !== "0") {
        return opMgAgreement.fixedpercentage + "% of the fee"
    }
    if (opMgAgreement.fixedpercentage === "0") {
        if (opMgAgreement.fixedpercentage !== "0") {
            return opMgAgreement.fixedpercentage +
                "% of the Gross Bill excluding Deductions "
        }
        else {
            return "Rs" + opMgAgreement.fixedamount + " /- Per case "
        }
    }
    else {
        return ""
    }
}
exports.calculateOpAgreement = {
    opAgreementCalculation: opAgreementCalc,
    opMgAgreementCalculation: opMgAgreementCalc,
};