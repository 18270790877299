import React, { useState, useRef, useEffect } from "react";
import ReactDOMServer from 'react-dom/server'
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { agreementState } from "../../../CommomMethods/States";
import { listusers } from "../../../actions/userActions";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import html2PDF from 'jspdf-html2canvas';
import moment from 'moment'
import { FaArrowCircleDown } from 'react-icons/fa';
import AgreementComponent from '../../../Components/AgreementComponent1'
import AgreementMgip from '../../../Components/AgreementMGip'
import { Helmet } from "react-helmet";
import { APIURL } from "../../../CommomMethods/Fetch";
import './../../../index.css';
import { dateFormatter } from '../../../actions/createPositionActions';
import { numberWithCommas } from "../../../actions/createPositionActions";
import parse from 'html-react-parser';
function Agreement(props) {
    const [lists, setList] = useState([]);
    const [annexure, setAnnexure] = useState([]);
    const [agreementView, setAgreementView] = useState("");
    const [agreementData, setAgreementData] = useState(false);
    const dispatch = useDispatch();
    const userList = useSelector((state) => state.userList);
    const { users } = userList;
    const userLogin = useSelector((state) => state.userLogin);
    const { user } = userLogin;
    useEffect(() => {
        dispatch(listusers());
    }, [dispatch]);

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const ordinal_suffix_of = (i) => {
        var val = "th"
        var j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            val = "st";
        }
        if (j == 2 && k != 12) {
            val = "nd";
        }
        if (j == 3 && k != 13) {
            val = "rd";
        }
        return val;
    }

    const getAgreementHtml = async () => {
        if (props && props.recData && props.recData.agreementTemplate && props.recData.agreementTemplate.length > 0) {
            var recData = props.recData
            var templateData = props.recData.agreementTemplate[0].templateData
            var DoctorFullName = "___________"
            var StampDate = "___________"
            var CenterName = "___________"
            var CenterAddress = "___________"
            var NoticePeriod = "___________"
            var LockinPeriod = "___________"
            var MedicalDegree = "___________"
            var PGDegree = "___________"
            var Designation = "___________"
            var FatherName = "___________"
            var SpouseName = "___________"
            var Pan = "___________"
            var MedicalCouncilCertificateNumber = "___________"
            var MedicalCouncilCertificateValidity = "___________"
            var Aadhaar = "___________"
            var Address = "___________"
            var State = "___________"
            var PinCode = "___________"
            var PermanentAddress = "___________"
            var PermanentState = "___________"
            var PermanentPinCode = "___________"
            var Mobile = "___________"
            var DoctorEmail = "___________"
            var CommencementDate = "___________"
            var Term = "___________"
            var ExpiryDate = "___________"
            var ProfessionalIndemnityAmount = "___________"
            var IndemnityExpiryDate = "___________"
            var Department = "___________"
            var DoctorBankName = "___________"
            var DoctorBankAccNo = "___________"
            var DoctorBankIFSCCode = "___________"
            var SettlementTerm = "___________"
            var FullTimeAmount = "___________"
            var Amount = "___________"
            var Period = "___________"
            var MgTerm = "___________"
            var SettlementPeriod = "___________"
            var SettlementPaymentPeriod = "___________"
            var OPEstimations = "___________"
            var IPEstimations = "___________"
            var EstimationRemarks = ""
            var AgreementAnnexures = ""
            if (recData.doctorCommercial && recData.doctorCommercial.length > 0) {
                if (recData.doctorCommercial[0].fullname) {
                    DoctorFullName = recData.doctorCommercial[0].fullname
                }
                if (recData.doctorCommercial[0].stampdate && recData.doctorCommercial[0].stampdate !== '0000-00-00') {
                    StampDate = recData.doctorCommercial[0].stampdate
                    StampDate = (new Date(StampDate)).getDate() + '<sup>' + ordinal_suffix_of((new Date(StampDate)).getDate()) + '</sup> day of ' + months[(new Date(StampDate)).getMonth()] + ', ' + (new Date(StampDate)).getFullYear()
                }
                if (recData.doctorCommercial[0].noticeperiod) {
                    NoticePeriod = recData.doctorCommercial[0].noticeperiod
                }
                if (recData.doctorCommercial[0].lockinperiod) {
                    LockinPeriod = recData.doctorCommercial[0].lockinperiod
                }
                if (recData.doctorCommercial[0].medicaldegree) {
                    MedicalDegree = recData.doctorCommercial[0].medicaldegree
                }
                if (recData.doctorCommercial[0].pgdegree) {
                    PGDegree = recData.doctorCommercial[0].pgdegree
                }
                if (recData.doctorCommercial[0].fathername) {
                    FatherName = recData.doctorCommercial[0].fathername
                }
                if (recData.doctorCommercial[0].husbandname) {
                    SpouseName = recData.doctorCommercial[0].husbandname
                }
                if (recData.doctorCommercial[0].panno) {
                    Pan = recData.doctorCommercial[0].panno
                }
                if (recData.doctorCommercial[0].mcrcval) {
                    MedicalCouncilCertificateNumber = recData.doctorCommercial[0].mcrstate ? (recData.doctorCommercial[0].mcrstate.substring(0, 3) + "- " + recData.doctorCommercial[0].mcrcval) : recData.doctorCommercial[0].mcrcval
                }
                if (recData.doctorCommercial[0].mcrdate && recData.doctorCommercial[0].mcrdate !== '0000-00-00') {
                    MedicalCouncilCertificateValidity = moment(recData.doctorCommercial[0].mcrdate, "YYYY-MM-DD").format("DD-MM-YYYY")
                }
                if (recData.doctorCommercial[0].adhaarno) {
                    Aadhaar = recData.doctorCommercial[0].adhaarno
                }
                if (recData.doctorCommercial[0].Address) {
                    Address = recData.doctorCommercial[0].Address
                }
                if (recData.doctorCommercial[0].State) {
                    State = recData.doctorCommercial[0].State
                }
                if (recData.doctorCommercial[0].PinCode) {
                    PinCode = recData.doctorCommercial[0].PinCode
                }
                if (recData.doctorCommercial[0].PermanentAddress) {
                    PermanentAddress = recData.doctorCommercial[0].PermanentAddress
                }
                if (recData.doctorCommercial[0].PermanentState) {
                    PermanentState = recData.doctorCommercial[0].PermanentState
                }
                if (recData.doctorCommercial[0].PermanentPinCode) {
                    PermanentPinCode = recData.doctorCommercial[0].PermanentPinCode
                }
                if (recData.doctorCommercial[0].mobile) {
                    Mobile = recData.doctorCommercial[0].mobile
                }
                if (recData.doctorCommercial[0].email) {
                    DoctorEmail = recData.doctorCommercial[0].email
                    if ((DoctorEmail).includes('@cloudnine')) {
                        DoctorEmail = recData.user.AdditionalEmailId
                    }
                }
                if (recData.doctorCommercial[0].contractlength) {
                    Term = recData.doctorCommercial[0].contractlength
                }
                if (recData.doctorCommercial[0].doj && recData.doctorCommercial[0].doj !== '0000-00-00') {
                    CommencementDate = moment(recData.doctorCommercial[0].doj, "YYYY-MM-DD").format("DD-MM-YYYY")
                    ExpiryDate = moment(moment(recData.doctorCommercial[0].doj.substring(0, 10)).add(Term, 'M').calendar()).format("DD-MM-YYYY")
                    console.log(recData.doctorCommercial[0].doj.substring(0, 10), moment(recData.doctorCommercial[0].doj.substring(0, 10)).add(Term, 'M').calendar(), ExpiryDate, 'recData.doctorCommercial[0].doj.substring(0, 10)')
                }
                if (recData.doctorCommercial[0].indemnityvalue) {
                    ProfessionalIndemnityAmount = "Rs " + recData.doctorCommercial[0].indemnityvalue + "/-"
                }
                if (recData.doctorCommercial[0].indemnityexpiry && recData.doctorCommercial[0].indemnityexpiry !== '0000-00-00') {
                    IndemnityExpiryDate = moment(recData.doctorCommercial[0].indemnityexpiry, "YYYY-MM-DD").format("DD-MM-YYYY")
                }
                if (recData.doctorCommercial[0].bankname) {
                    DoctorBankName = recData.doctorCommercial[0].bankname
                }
                if (recData.doctorCommercial[0].bankaccno) {
                    DoctorBankAccNo = recData.doctorCommercial[0].bankaccno
                }
                if (recData.doctorCommercial[0].bankifsc) {
                    DoctorBankIFSCCode = recData.doctorCommercial[0].bankifsc
                }
                if (recData.doctorCommercial[0].estimation_remarks) {
                    EstimationRemarks = "Remarks: " + recData.doctorCommercial[0].estimation_remarks
                }
            }
            if (recData.vacancyData && recData.vacancyData.length > 0) {
                if (recData.vacancyData[0].centerNames) {
                    CenterName = recData.vacancyData[0].centerNames
                }
                if (recData.vacancyData[0].designationLabel) {
                    Designation = recData.vacancyData[0].designationLabel
                }
                if (recData.vacancyData[0].CenterAddress) {
                    CenterAddress = recData.vacancyData[0].CenterAddress
                }
                if (recData.vacancyData[0].departmentLabel) {
                    Department = recData.vacancyData[0].departmentLabel
                }
            }
            var mgData = ''
            var fulltimeData = ''
            if (templateData.search('{{MG#}}') != -1 && templateData.search('{{/MG#}}') != -1) {
                mgData = await (templateData.split('{{MG#}}'))[1]
                mgData = await (mgData.split('{{/MG#}}'))[0]
                console.log(mgData, 'mgData')
            }
            if (templateData.search('{{FULLTIME#}}') != -1 && templateData.search('{{/FULLTIME#}}') != -1) {
                fulltimeData = await (templateData.split('{{FULLTIME#}}'))[1]
                fulltimeData = await (fulltimeData.split('{{/FULLTIME#}}'))[0]
                console.log(fulltimeData, 'fulltimeData')
            }
            if (recData.fixedMg && recData.fixedMg.length > 0) {
                var mgDataitem = ""
                for (let i = 0; i < recData.fixedMg.length; i++) {
                    var itemMG = await mgData
                    const fixedMGElement = recData.fixedMg[i];
                    if (fixedMGElement.settlementterm) {
                        SettlementTerm = fixedMGElement.settlementterm
                        Amount = inWords(parseInt(fixedMGElement.settlementterm))
                    }
                    if (fixedMGElement.mgperiod) {
                        Period = fixedMGElement.mgperiod
                        MgTerm = yearinWords(parseInt(fixedMGElement.mgperiod))
                    }
                    if (fixedMGElement.settlementperiod) {
                        SettlementPeriod = fixedMGElement.settlementperiod
                    }
                    if (fixedMGElement.settlementpaymentperiod) {
                        SettlementPaymentPeriod = fixedMGElement.settlementpaymentperiod
                        SettlementPaymentPeriod = SettlementPaymentPeriod == 'Yearly' ? 'the year' : SettlementPaymentPeriod == 'Half Yearly' ? 'half of the year' : SettlementPaymentPeriod == 'Quaterly' ? 'the quarter' : SettlementPaymentPeriod == 'Monthly' ? 'the month' : SettlementPaymentPeriod
                    }
                    itemMG = await itemMG.replace(/{{SettlementTerm}}/g, SettlementTerm)
                    itemMG = await itemMG.replace(/{{Amount}}/g, Amount)
                    itemMG = await itemMG.replace(/{{Period}}/g, Period)
                    itemMG = await itemMG.replace(/{{MgTerm}}/g, MgTerm)
                    itemMG = await itemMG.replace(/{{SettlementPeriod}}/g, SettlementPeriod)
                    mgDataitem += await '<p class="secsubtitleAgmt"><strong>' + fixedMGElement.Center + '</strong></p>' + itemMG
                }
                templateData = await templateData.replace(mgData, mgDataitem)
            } else {
                templateData = await templateData.replace(mgData, "")
            }
            if (recData.VisitingParamter && recData.VisitingParamter.length > 0) {
                var fulltimeDataitem = ""
                for (let j = 0; j < recData.VisitingParamter.length; j++) {
                    var itemVP = await fulltimeData
                    const VPElement = recData.VisitingParamter[j];
                    if (VPElement.professionalfeepermonth) {
                        FullTimeAmount = VPElement.professionalfeepermonth
                        Amount = inWords(parseInt(VPElement.professionalfeepermonth))
                    }
                    itemVP = await itemVP.replace('Consultation Fee', 'Consultation Fee for ' + VPElement.Center)
                    itemVP = await itemVP.replace(/{{FullTimeAmount}}/g, FullTimeAmount)
                    itemVP = await itemVP.replace(/{{Amount}}/g, Amount)
                    fulltimeDataitem += await itemVP
                }
                templateData = await templateData.replace(fulltimeData, fulltimeDataitem)
            } else {
                templateData = await templateData.replace(fulltimeData, "")
            }
            if (recData.agreementAnnexures && recData.agreementAnnexures.length > 0) {
                AgreementAnnexures += '<div className="sch3">'
                    + '<p className="subtitleAgmt">'
                    + '<strong>ANNEXURE-Platinum</strong>'
                    + '</p>'
                    + '<div className="agreement_annexure" style="display: \'flex\', justify-content: \'center\', align-items: \'center\'" >'
                    + '<table border="1" cellspacing="0" cellpadding="0">'
                    + '<tbody>'
                    + '<tr>'
                    + '<td valign="top">'
                    + '<p>'
                    + '<strong>Gynecology Procedures</strong>'
                    + '</p>'
                    + '</td>'
                    + '<td valign="top">'
                    + '<p>'
                    + '<strong>Platinum</strong>'
                    + '</p>'
                    + '</td>'
                    + '<td valign="top">'
                    + '<p>'
                    + '<strong>Pay Out</strong>'
                    + '</p>'
                    + '</td>'
                    + '</tr>'
                for (let a = 0; a < recData.agreementAnnexures.length; a++) {
                    var annexure = recData.agreementAnnexures[a];
                    AgreementAnnexures += '<tr>'
                        + '<td nowrap="" valign="top">'
                        + '<p>' + annexure.procedurename + '</p>'
                        + '</td>'
                        + '<td nowrap="" valign="top">'
                        + '<p>' + annexure.platinum + '</p>'
                        + '</td>'
                        + '<td nowrap="" valign="top">'
                        + '<p>' + annexure.payout + '</p>'
                        + '</td>'
                        + '</tr>'
                }
                AgreementAnnexures += '</tbody>'
                    + '</table>'
                    + '</div>'
                    + '</div>'
            }
            var estimationsData = '<div className="listAgmt" id="agreementEstimations">'
            // if (recData.doctorOPParameter && recData.doctorOPParameter.length > 0 && recData.doctorIPParameter && recData.doctorIPParameter.length > 0) {
            //     OPEstimations = await (ReactDOMServer.renderToStaticMarkup(<AgreementComponent opcomp={recData.doctorOPParameter} />)).toString()
            //     // console.log(estimationsData.toString(), OPEstimations, 'estimationsData')
            //     IPEstimations = await (ReactDOMServer.renderToStaticMarkup(<AgreementMgip ipcomp={recData.doctorIPParameter} />)).toString()
            //     estimationsData = estimationsData + (OPEstimations) + (IPEstimations)
            // }
            estimationsData = estimationsData + '</div>'
            let mgCommercial = ''
            console.log(props.catagory, 'props.catagory')
            if (props.catagory === "Full Time Consultant" || props.catagory === 'Retainer') {
                templateData = await templateData.replace(mgData, '')
                // estimationsData = ''
            } else if (props.catagory === "Visiting Consultant") {
                templateData = await templateData.replace(mgData, '')
                // templateData = await templateData.replace(fulltimeData, '')
            } else if (props.catagory === "Minimum Guarentee(MG)") {
                templateData = await templateData.replace(fulltimeData, '')
            }
            templateData = await templateData.replace(/{{MG#}}/g, '')
            templateData = await templateData.replace(/{{\/MG#}}/g, '')
            templateData = await templateData.replace(/{{FULLTIME#}}/g, '')
            templateData = await templateData.replace(/{{\/FULLTIME#}}/g, '')
            templateData = await templateData.replace(/{{StampDate}}/g, StampDate)
            templateData = await templateData.replace(/{{DoctorFullName}}/g, DoctorFullName)
            templateData = await templateData.replace(/{{CenterName}}/g, CenterName)
            templateData = await templateData.replace(/{{CenterAddress}}/g, CenterAddress)
            templateData = await templateData.replace(/{{NoticePeriod}}/g, NoticePeriod)
            templateData = await templateData.replace(/{{LockinPeriod}}/g, LockinPeriod)
            templateData = await templateData.replace(/{{MedicalDegree}}/g, MedicalDegree)
            templateData = await templateData.replace(/{{PGDegree}}/g, PGDegree)
            templateData = await templateData.replace(/{{Designation}}/g, Designation)
            templateData = await templateData.replace(/{{FatherName}}/g, FatherName)
            templateData = await templateData.replace(/{{SpouseName}}/g, SpouseName)
            templateData = await templateData.replace(/{{Pan}}/g, Pan)
            templateData = await templateData.replace(/{{MedicalCouncilCertificateNumber}}/g, MedicalCouncilCertificateNumber)
            templateData = await templateData.replace(/{{MedicalCouncilCertificateValidity}}/g, MedicalCouncilCertificateValidity)
            templateData = await templateData.replace(/{{Aadhaar}}/g, Aadhaar)
            templateData = await templateData.replace(/{{Address}}/g, Address)
            templateData = await templateData.replace(/{{State}}/g, State)
            templateData = await templateData.replace(/{{PinCode}}/g, PinCode)
            templateData = await templateData.replace(/{{PermanentAddress}}/g, PermanentAddress)
            templateData = await templateData.replace(/{{PermanentState}}/g, PermanentState)
            templateData = await templateData.replace(/{{PermanentPinCode}}/g, PermanentPinCode)
            templateData = await templateData.replace(/{{Mobile}}/g, Mobile)
            templateData = await templateData.replace(/{{DoctorEmail}}/g, DoctorEmail)
            templateData = await templateData.replace(/{{CommencementDate}}/g, CommencementDate)
            templateData = await templateData.replace(/{{Term}}/g, Term)
            templateData = await templateData.replace(/{{ExpiryDate}}/g, ExpiryDate)
            templateData = await templateData.replace(/{{ProfessionalIndemnityAmount}}/g, ProfessionalIndemnityAmount)
            templateData = await templateData.replace(/{{IndemnityExpiryDate}}/g, IndemnityExpiryDate)
            templateData = await templateData.replace(/{{Department}}/g, Department)
            templateData = await templateData.replace(/{{DoctorBankName}}/g, DoctorBankName)
            templateData = await templateData.replace(/{{DoctorBankAccNo}}/g, DoctorBankAccNo)
            templateData = await templateData.replace(/{{DoctorBankIFSCCode}}/g, DoctorBankIFSCCode)
            templateData = await templateData.replace(/{{SettlementTerm}}/g, SettlementTerm)
            templateData = await templateData.replace(/{{Amount}}/g, Amount)
            templateData = await templateData.replace(/{{Period}}/g, Period)
            templateData = await templateData.replace(/{{MgTerm}}/g, MgTerm)
            templateData = await templateData.replace(/{{SettlementPeriod}}/g, SettlementPeriod)
            templateData = await templateData.replace(/{{SettlementPaymentPeriod}}/g, SettlementPaymentPeriod)
            templateData = await templateData.replace(/{{FullTimeAmount}}/g, FullTimeAmount)
            templateData = await templateData.replace(/{{OPEstimations}}/g, estimationsData)
            templateData = await templateData.replace(/{{IPEstimations}}/g, "")
            templateData = await templateData.replace(/{{EstimationRemarks}}/g, EstimationRemarks)
            templateData = await templateData.replace(/{{AgreementAnnexures}}/g, AgreementAnnexures)
            await setAgreementData(templateData)
            await setAgreementView(true)
        }
    }

    const getAgreementComponent = (opcomp) => ReactDOMServer.renderToStaticMarkup(<AgreementComponent opcomp={opcomp} />)

    const getAgreementMgip = (ipcomp) => ReactDOMServer.renderToStaticMarkup(<AgreementMgip ipcomp={ipcomp} />)

    useEffect(() => {
        axios
            .get(APIURL() + "agreementannexures/getdata/getprocedures")
            .then((res) => {
                console.log(res.data.data, "res-ponse");
                setAnnexure(res.data.data);
            });
        getAgreementHtml()
    }, []);
    const formStyle = {
        // borderRadius: "5px",
        // border: "1px solid black",
        border: "white",
        marginTop: "50px",
    };
    const headerStyle = {
        backgroundColor: "#94248E",
        margins: "0px",
        padding: "10px",
        color: "white",
        borderRadius: "5px",
        borderBottom: "3px black solid",
    };
    const bodyStyle = {
        borderRadius: "5px",
    };

    console.log(props, "$$$$$$$$$$$$$$");
    const confirmAgreement = (id) => {
        const obj = {
            doctorId: props.recData.doctorCommercial[0].doctorId,
            name: props.recData.doctorCommercial[0].fullname,
            currentAction: agreementState.hr_revision.state,
            currentActionBy: users[0].id,
            nextAction: agreementState.dr_accepted.state,
            status: 1,
        };

        axios.post(APIURL() + "onboard/agreementAction/", obj).then((res) => {
            toast.success("Successfully Confirmed");
            window.location.reload();
        });
    };

    var k =
        props &&
        props.recData &&
        props.recData.doctorDetails &&
        props.recData.doctorDetails.id;
    useEffect(() => {
        axios
            .get(APIURL() + "documents/list/" + k)
            .then((res) => res.data)
            .then((rows) => {
                setList(rows);
            });
    }, [k]);

    var a = [
        "",
        "one ",
        "two ",
        "three ",
        "four ",
        "five ",
        "six ",
        "seven ",
        "eight ",
        "nine ",
        "ten ",
        "eleven ",
        "twelve ",
        "thirteen ",
        "fourteen ",
        "fifteen ",
        "sixteen ",
        "seventeen ",
        "eighteen ",
        "nineteen ",
    ];
    var b = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
    ];
    var n;
    function inWords(num) {
        if ((num = num.toString()).length > 9) return "overflow";
        n = ("000000000" + num)
            .substr(-9)
            .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return;
        var str = "";
        str +=
            n[1] != 0
                ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
                : "";
        str +=
            n[2] != 0
                ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
                : "";
        str +=
            n[3] != 0
                ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
                : "";
        str +=
            n[4] != 0
                ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
                : "";
        str +=
            n[5] != 0
                ? (str != "" ? "and " : "") +
                (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
                "only "
                : "";
        return str;
    }
    function yearinWords(num) {
        if ((num = num.toString()).length > 9) return "overflow";
        n = ("000000000" + num)
            .substr(-9)
            .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
        if (!n) return;
        var str = "";
        str +=
            n[1] != 0
                ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
                : "";
        str +=
            n[2] != 0
                ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
                : "";
        str +=
            n[3] != 0
                ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
                : "";
        str +=
            n[4] != 0
                ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
                : "";
        str +=
            n[5] != 0
                ? (str != "" ? "and " : "") +
                (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
                ""
                : "";
        return str;
    }
    var amount = parseInt(
        props &&
        props.recData &&
        props.recData.fixedMg &&
        props.recData.fixedMg[0] &&
        props.recData.fixedMg[0].settlementterm
    );
    var Mgterm = parseInt(
        props &&
        props.recData &&
        props.recData.fixedMg &&
        props.recData.fixedMg[0] &&
        props.recData.fixedMg[0].mgperiod
    );

    const pages = document.getElementsByClassName("page");
    let page = document.getElementById("page");
    const generatePdf = async () => {
        var recData = props.recData
        await axios.get(APIURL() + "onboard/download-draft-agreement/" + recData.vacancyData[0].doctorId, {
            responseType: 'blob',
            headers: {
                Accept: 'application/pdf',
            },
        }).then((res) => {
            console.log(res)
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.target = "_blank";
            link.download = recData.user.name + "_Draft_Agreement.pdf"
            link.href = url;
            // link.setAttribute('download', 'Doctor Agreement.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        // html2PDF(page, {
        //     jsPDF: {
        //         format: "a4",
        //     },
        //     imageType: "image/jpeg",
        //     output: "./pdf/Agreement.pdf",
        // });
    };

    const printAll = () => {
        // const printableElements = document.getElementById("page").innerHTML;
        // window.print();
        var container = document.getElementById("containerscrollbtn").innerHTML = "";
        var divContents = document.getElementById("page").innerHTML;
        var a = window.open("", "", "height=500, width=500");
        a.document.write("<html><body>");
        // a.document.write("<style> @page{size: A4; margin: 7rem 4rem; } p{ page-break-inside: avoid;}</style>");
        // style={{ position: "fixed", right: "0px", color: "#d6d6d4", }}
        // body{counter-reset: pageNo;} footer{counter-increment: pageNo; content:counter(pageNo); position:fixed; bottom: 5px; right: 0px;}
        a.document.write(
            "<style> @page{size: A4; margin: 5rem;} p{page-break-inside: avoid;} header{color:#d6d6d4;marginBottom:5rem;display:block }@media print {@page{counter-reset: pageNo;} footer::after{counter-increment: pageNo; content:'Page | 'counter(pageNo); position:fixed; bottom: 5px; right: 0px;}} </style>"
        );
        a.document.write(divContents);
        a.document.write("</body></html>");
        a.document.close();
        a.print();
    };

    // date as string
    console.log(props);
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    const myDate = today;
    // const myDate = props && props.recData && props.recData.doctorCommercial && props.recData.doctorCommercial[0].stampdate && props.recData.doctorCommercial[0].stampdate.substring(0, 10);
    const date = new Date(myDate + " 0:0:0");

    const expirydate = moment(
        props &&
        props.recData &&
        props.recData.doctorCommercial &&
        props.recData.doctorCommercial.length &&
        props.recData.doctorCommercial[0].indemnityexpiry,
        "YYYY-MM-DD"
    ).format("DD-MM-YYYY");
    var indemnityExpiryDate = moment(
        props &&
        props.recData &&
        props.recData.doctorCommercial &&
        props.recData.doctorCommercial[0] &&
        props.recData.doctorCommercial[0].indemnityexpiry,
        "YYYY-MM-DD"
    ).format("DD-MM-YYYY");
    var MCRCDate = moment(
        props &&
        props.recData &&
        props.recData.doctorCommercial &&
        props.recData.doctorCommercial[0] &&
        props.recData.doctorCommercial[0].mcrdate,
        "YYYY-MM-DD"
    ).format("DD-MM-YYYY");
    var commencementDate = moment(
        props &&
        props.recData &&
        props.recData.doctorCommercial &&
        props.recData.doctorCommercial[0] &&
        props.recData.doctorCommercial[0].doj &&
        props.recData.doctorCommercial[0].doj,
        "YYYY-MM-DD"
    ).format("DD-MM-YYYY");
    var dates = moment(
        props &&
        props.recData &&
        props.recData.doctorCommercial &&
        props.recData.doctorCommercial[0].createdAt &&
        props.recData.doctorCommercial[0].createdAt.substring(0, 10),
        "YYYY-MM-DD"
    ).format("DD-MM-YYYY");

    // var ipArray=[];
    var dt =
        props &&
        props.recData &&
        props.recData.doctorCommercial &&
        props.recData.doctorCommercial[0].doj &&
        props.recData.doctorCommercial[0].doj.substring(0, 10);
    var term =
        props &&
            props.recData &&
            props.recData.doctorCommercial && props.recData.doctorCommercial[0].contractlength ?
            (props.recData.doctorCommercial[0].contractlength.split(' ')[0]) : "";

    console.log(dt, term, "1props && props.recData")
    var futureMonth = moment(moment(dt).add(term, 'M').calendar()).format("DD-MM-YYYY");
    console.log(props && props.recData, "props && props.recData")
    return (
        agreementView ?
            <>
                {parse(agreementData, {
                    replace: domNode => {
                        if (domNode.attribs && domNode.attribs.id === 'agreementEstimations') {
                            return <div className="listAgmt">
                                <AgreementComponent
                                    opcomp={
                                        props &&
                                        props.recData &&
                                        props.recData.doctorOPParameter
                                    }
                                />
                                <AgreementMgip
                                    ipcomp={
                                        props &&
                                        props.recData &&
                                        props.recData.doctorIPParameter &&
                                        props.recData.doctorIPParameter
                                    }
                                />
                            </div>;
                        }
                    }
                })}
                {
                    user.user.roles == 2 ?
                        <Row style={{ margin: "1rem 2rem" }} id="section2">
                            <Col md={2} >
                                <Button color="secondary" startIcon={<SimCardDownloadIcon />} variant="contained" id="btn" onClick={() => generatePdf()}>
                                    Generate PDF
                                </Button>
                            </Col>
                        </Row>
                        : ""
                }
            </>
            :
            ""
    );
}

export default Agreement;
