import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";
//import image
import profileImg from "./../../images/profile.jpg";
import Navbar from "../../Components/Navbar";
import ConfirmDialog from "../../Components/ConfirmDialog";
import { documentlist } from "../../actions/documentActions";
import Sidebar from "../../Components/Sidebar";
import Timeline from "../../Components/Timeline";
import axios from "axios";
import { toast } from "react-toastify";
import { agreementviewById } from "../../actions/agreementActions";
import { agreementState, onboardState } from "../../CommomMethods/States";
// import Navbar from "../../Components/Navbar";
import CeoSidebar from "../../Components/CeoSidebar";
import { APIURL } from "../../CommomMethods/Fetch";
//import from mui
import Grid from "@mui/material/Grid";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import Dialog from "@material-ui/core/Dialog";
import Iframe from "react-iframe";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { onboardById } from "../../actions/onboardActions";
import { useLocation } from "react-router-dom";
import { dateFormatter, numberWithCommas, numberToEnglish } from "../../actions/createPositionActions";
import c9gif from '../../SampleFile/cloudnine.gif';
import { Backdrop } from "@material-ui/core";
//material table
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));
const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function DoctorProfileFinal({ history }) {
  const tables = myTableStyle();
  const location = useLocation();
  console.log(location, "location")
  const dispatch = useDispatch();
  const backClasses = backDropStyle();
  const [backDropOpen, setBackdropOpen] = useState(false);
  const [LtID, setLtId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [urlData, setUrlData] = useState("");
  const [OfficialEmailId, setOfficialEmailId] = useState("");
  const [file, setFile] = useState();
  const [docidcheck, setdocidcheck] = useState();
  const [filename, setFilename] = useState("");
  const [vals, setVals] = useState("false");
  const [filePath, setfilePath] = useState();
  const [rowData, setRowData] = useState({});
  const [doctorDetail, setDoctorDetail] = React.useState([]);
  const [doctorOpDetail, setDoctorOpDetail] = React.useState([]);
  const [doctorIpDetail, setDoctorIpDetail] = React.useState([]);
  const [doctorOpCommercial, setDoctorOpCommercial] = React.useState([]);
  const [doctorIpCommercial, setDoctorIpCommercial] = React.useState([]);
  const [doctorMgDetails, setDoctorMgDetails] = React.useState([]);
  const [doctorFixedDetails, setDoctorFixedDetails] = React.useState([]);
  const [CenterArray, setCenterArray] = React.useState([]);
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const listDoc = useSelector((state) => state.listDoc);
  const { list } = listDoc;
  // const location = useLocation();
  useEffect(() => {
    dispatch(
      onboardById(location && location.state && location.state.id)
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      documentlist(location && location.state && location.state.id)
    );
  }, [dispatch]);
  const onboardId = useSelector((state) => state.onboardId);
  const { oboard } = onboardId;

  console.log(list,  "!!!oboardoboardoboardoboard");
  let docid = oboard && oboard.doctorDetails && oboard.doctorDetails.id;

  const ConfirmLtID = () => {
    if (OfficialEmailId == "") {
      toast.warning("Please enter official email and try again!");
      return false;
    }
    if (oboard.vacancyData[0].manualAgreement == 1 && list.data && !list.data.find((z) => z.docTitle === "Agreement")) {
      toast.warning("Please upload the signed agreement and try again!");
      return false;
    }
    // if (LtID == "") {
    //   toast.warning("Please enter LT Id and try again!");
    //   return false;
    // }
    setBackdropOpen(true)
    const obj = {
      category: LtID,
    };
    const inputData = {
      name: oboard.doctorDetails && oboard.doctorDetails && oboard.doctorDetails.name,
      role: user.user.roles,
      doctor_name: oboard.doctorDetails.name,
      request_id: oboard.vacancyData[0].id,
      id: oboard.doctorDetails && oboard.doctorDetails && oboard.doctorDetails.id,
      user_id: user.user.id,
      OfficalEmailId: OfficialEmailId,
      LTId: LtID,
    };
    axios
      .post(APIURL() + "users/confirmEmployment/", inputData)
      .then((res) => {
        setOfficialEmailId("");
        setLtId("");
      }).then(() => {
        if (oboard.vacancyData[0].manualAgreement == 1) {
          toast.success("Manual Signed Agreement uploaded")
        } else {
          toast.success("Onboarding Completed!")
        }
        setBackdropOpen(false)
        history.push("/bh-dashboard");
      })
      .catch((err) => {
        // toast.error("Please try after sometime!");
      });
    // axios
    //   .put(
    //     APIURL() + "users/" + location && location.state && location.state.id,
    //     obj
    //   )
    // .then((res) => { });
  };
  const onChangeFIle = (element) => {
    setfilePath(element.target.value);
    setFile(element.target.files[0]);
    setFilename(element.target.files[0].name);
  };

  const uploadAgeeementFile = async () => {
    console.log(
      oboard && oboard.doctorDetails && oboard.doctorDetails.id,
      "DOC ID"
    );
    // setLoading(false);
    // setCirculatLoader(true);
    var fileupld = document.getElementById("fileUpload").innerHTML;
    const formData = await new FormData();
    await formData.append("name", filename);
    // formData.append("doctorId", user.user.id);
    await formData.append("docFile", file);
    await formData.append("docTitle", "Agreement");
    await formData.append("url", filePath);
    await formData.append(
      "doctorId",
      oboard && oboard.doctorDetails && oboard.doctorDetails.id
    );
    console.log(formData, filename, file, filePath, "formData");
    // return;
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        // setLoading(true);
        // alert(response.data.message);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        console.log(response.data, "respon-se");
      });
    // setFile({});
    // setFilename("");
  };

  useEffect(() => {
    console.log(history.location.state.id, "history-1");
    const doctorId = history.location.state.id;
    getData(doctorId);
  }, [history]);

  const getData = (id) => {
    console.log(id, "doctor-id");
    axios
      .get(APIURL() + `users/checkdocid/${id}`)
      .then((res) => {
        console.log(res.data.data, "res-ponse");
        setRowData(res.data.data);
      })
      .catch((err) => {
        // toast.error("Please try after sometime!");
      });
    axios
      .get(APIURL() + "onboard/previewOne/" + id)
      .then((res) => {
        let CenterArray = []
        setDoctorDetail(res.data);
        setDoctorIpDetail(res.data.doctorIPParameter);
        setDoctorOpDetail(res.data.doctorOPParameter);
        setDoctorOpCommercial(res.data.doctorOpCommercial);
        setDoctorIpCommercial(res.data.doctorIpCommercial);
        setDoctorMgDetails(res.data.doctorMgDetails);
        setDoctorFixedDetails(res.data.doctorFixedDetails);
        setOfficialEmailId(res.data.doctorDetails.email)
        if (res.data.doctorMgDetails && res.data.doctorMgDetails.length > 0) {
          res.data.doctorMgDetails.map(item => {
            CenterArray.push(item.Center)
          })
        } else if (res.data.doctorFixedDetails && res.data.doctorFixedDetails.length > 0) {
          res.data.doctorFixedDetails.map(item => {
            CenterArray.push(item.Center)
          })
        } else if (res.data.doctorIPParameter && res.data.doctorIPParameter.length > 0) {
          res.data.doctorIPParameter.map(item => {
            CenterArray.push(item.Center)
          })
        } else if (res.data.doctorOPParameter && res.data.doctorOPParameter.length > 0) {
          res.data.doctorOPParameter.map(item => {
            CenterArray.push(item.Center)
          })
        }
        if (CenterArray && CenterArray.length == 0) {
          if (res.data.doctorCommercial && res.data.doctorCommercial[0] && res.data.doctorCommercial[0].businessunit) {
            CenterArray.push(res.data.doctorCommercial[0].businessunit)
          }
        }
        setCenterArray(CenterArray.filter((value, index, array) => array.indexOf(value) === index && value != (oboard.doctorCommercial && oboard.doctorCommercial[0] && oboard.doctorCommercial[0].businessunit)))
      })
      .catch((error) => { });
  };

  const handleDownloadFile = (url) => {
    fetch(APIURL() + "billings/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = "Agreement";
        a.click();
      });

      //window.location.href = response.url;
    });
  };

  const handleRemoveAgreement = (row) => {
    var k = window.confirm("Are you sure want to remove this agreement ?");
    if (k) {
      console.log(row);
      axios
        .post(APIURL() + "users/removeAgreement", row)
        .then((res) => {
          console.log(res, "res-ponse");
          // alert(res.data.message);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((err) => {
          // toast.error("Please try after sometime!");
        });
    }
  };

  const viewDocFile = (url, filename) => {
    console.log(url, filename, "url-file");
    setOpenDialog(true);

    fetch(APIURL() + "billings/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        setUrlData(url);
      });
    });
  };
  const viewSignedAgreementDoc = async () => {
    console.log(rowData, "view-agreement-ch");
    if (list.data && list.data.find((z) => z.docTitle === "Agreement")) {
      await fetch(APIURL() + "documents/download/" + (list.data && list.data.find((z) => z.docTitle === "Agreement").url), {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      }).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = rowData.name + "_Agreement.pdf";
          a.click();
        });
      });
    } else {
      // setAgreementRow(row);
      await axios.get(APIURL() + "onboard/download-agreement/" + rowData.digio_doc_id, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      }).then((res) => {
        console.log(res)
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.target = "_blank";
        link.download = rowData.name + "_Agreement.pdf"
        link.href = url;
        // link.setAttribute('download', 'Doctor Agreement.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
    }
  };
  const TableTitle = ({ text }) => (
    <Typography
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "black",
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "15px"
      }}
    >
      {text}
    </Typography>
  );

  const fileHandler11 = (e) => {
    if (
      e.target.files[0].type != "image/jpeg" &&
      e.target.files[0].type != "image/jpg" &&
      e.target.files[0].type != "image/png" &&
      e.target.files[0].type != "application/pdf"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload valid file",
      });
      return;
    }
    if (e.target.files[0]) {
      setfilePath(e.target.value);
      setFile(e.target.files[0]);
      setFilename(e.target.files[0].name);
    }
  };
  return (
    <>
      <Sidebar />
      <div className="main-content side-content pt-0">
        <div
          className="container-fluid"
          style={{ margin: "0 auto", backgroundColor: "white" }}
        >
          <div className="inner-body">
            <Navbar />

            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  style={{
                    color: "#810e36",
                    paddingTop: "20px",
                    borderBottom: "1px dashed rgb(0 150 63 / 52%)",
                    paddingBottom: "20px",
                  }}
                >
                  {"Profile Information"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {
                  oboard && oboard.onBoardData && oboard.onBoardData.length && oboard.onBoardData[oboard.onBoardData.length - 1] && oboard.onBoardData[oboard.onBoardData.length - 1].nextAction && oboard.onBoardData[oboard.onBoardData.length - 1].nextAction === onboardState.completed ?
                    <Row style={{ margin: "1rem", float: "right" }}>
                      {
                        (list.data && list.data.find((z) => z.docTitle === "Agreement")) || (oboard.doctorDetails && oboard.doctorDetails.digio_doc_id) ?
                          <button className="btn btn-primary" onClick={viewSignedAgreementDoc}> Download Agreement </button>
                          : ""
                      }
                    </Row>
                    : ''
                }
                {(list.data && list.data.find((z) => z.docTitle === "Agreement")) || (oboard.doctorDetails && oboard.doctorDetails.digio_doc_id) ? "" :
                  <>
                    <input
                      type="file"
                      id={"fileUpload"}
                      accept=".pdf,.jpeg,.jpg,.png"
                      onChange={fileHandler11}
                      name="Agreement"
                      className="form-control"
                    />
                    {file && filename ? (
                      <Button color="secondary" onClick={uploadAgeeementFile}>
                        Upload
                      </Button>
                    ) : (
                      ""
                    )}
                  </>
                }
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Paper elevation={24} style={{ padding: "20px", border: "1px solid", height: "100%" }}>
                  {list.data &&
                    list.data
                      .filter((z) => z.docTitle === "Passport Size Photograph")
                      .map((ele) => (
                        <img
                          class="img-responsive"
                          src={APIURL() + "documents/download/" + ele.url}
                          style={{
                            borderRadius: "50%",
                            marginRight: "1rem",
                            width: "500px",
                            height: "58%"
                          }}
                        />
                      ))}
                  <div style={{ height: '178px', maxHeight: '178px', overflow: 'scroll', marginTop: '5%' }}>
                    <Typography variant="h6" style={{ textAlign: "center", marginTop: "15%" }}>
                      Dr.{" "}
                      {oboard.doctorCommercial &&
                        oboard.doctorCommercial[0] && oboard.doctorCommercial[0].fullname}
                    </Typography>
                    <Typography variant="subtitle2" style={{ textAlign: "center", marginTop: "4px" }}>
                      Designation: {oboard.doctorCommercial &&
                        oboard.doctorCommercial[0] && oboard.doctorCommercial[0].designationlist}
                    </Typography>
                    <Typography variant="subtitle2" style={{ textAlign: "center", marginTop: "4px" }}>
                      Department: {oboard.doctorCommercial &&
                        oboard.doctorCommercial[0] && oboard.doctorCommercial[0].departmentlist}
                    </Typography>
                    <Typography variant="subtitle2" style={{ textAlign: "center", marginTop: "4px" }}>
                      Branch: {oboard.doctorCommercial && oboard.doctorCommercial[0] && oboard.doctorCommercial[0].businessunit}
                      {/* Branch: {CenterArray && CenterArray.length > 0 ? CenterArray.join(', ') : '---'} */}
                    </Typography>
                    <Typography variant="subtitle2" style={{ textAlign: "center", marginTop: "4px" }}>
                      Term: {oboard.doctorCommercial &&
                        oboard.doctorCommercial[0] && oboard.doctorCommercial[0].contractlength} Months
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid", maxHeight: "260px", overflow: "scroll" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        CONTACT
                      </h5>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Email :{" "}
                        {oboard.doctorDetails && oboard.doctorDetails.email}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Phone :{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].mobile}
                      </Typography>{" "}
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Permanent Addr :{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].PermanentAddress}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Present Addr :{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].Address}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        pin code :{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].PinCode}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid", maxHeight: "260px", overflow: "scroll" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        PERSONAL DETAIL
                      </h5>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Aadhaar :{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].adhaarno}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Pan :{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].panno}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        DOB :{" "}{console.log(oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].birthday, "KLKLKLKKKKK")}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].birthday ? dateFormatter(oboard.doctorCommercial[0].birthday) : "---"}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        DOJ :{" "}
                        {oboard && oboard.doctorCommercial && oboard.doctorCommercial[0] && oboard.doctorCommercial[0].doj ? dateFormatter(oboard.doctorCommercial[0].doj) : "---"}
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid", maxHeight: "260px", overflow: "scroll" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        BANK DETAIL
                      </h5>
                      <Typography
                        variant="subtitle2"
                        style={{ textAlign: "center" }}
                      ></Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        BANk name :
                        {oboard && oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].bankname}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        ifsc :
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].bankifsc}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        account no :
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].bankaccno}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid", maxHeight: "260px", overflow: "scroll" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        OTHER DETAILS
                      </h5>
                      <Typography variant="overline" display="block" style={{ textAlign: "center" }}>About: {oboard.doctorCommercial && oboard.doctorCommercial[0] && oboard.doctorCommercial[0].aboutdoctor}</Typography>
                      <Typography variant="overline" display="block" style={{ textAlign: "center" }}
                      >
                        UG Degree: {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].medicaldegree}
                      </Typography>
                      <Typography variant="overline" display="block" style={{ textAlign: "center" }}
                      >
                        PG Degree: {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].pgdegree}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Indemnity Exp :
                        {oboard && oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].indemnityexpiry ? dateFormatter(oboard.doctorCommercial[0].indemnityexpiry) : "---"}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Additional Branch: {CenterArray && CenterArray.length > 0 ? CenterArray.join(', ') : '---'}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div className="col-xl-12 col-md-12 pt-4">
              <Row>
                <Col>
                  <Card.Body>
                    {oboard.doctorDetails &&
                      (oboard.doctorDetails.isConfirmed == 0 ||
                        oboard.doctorDetails.isConfirmed == null) ? (
                      user && user.user && user.user.activityAssigments.filter((elem) => elem.activityId === 8).find(item => item.RoleId === user.user.roles) ? (
                        <>
                          <Row style={{ marginRight: "30rem" }}>
                            <Col md={6}>
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Enter Doctor ID:
                              </label>
                              <input
                                type="number"
                                className="form-control "
                                value={LtID}
                                onChange={(e) => setLtId(e.target.value)}
                                style={{ border: "1px solid grey" }}
                              ></input>
                            </Col>
                            <Col md={6}>
                              <label style={{ fontWeight: "bold" }}>
                                {" "}
                                Communication Email:
                              </label>
                              <input
                                type="text"
                                className="form-control "
                                value={OfficialEmailId}
                                onChange={(e) =>
                                  setOfficialEmailId(e.target.value)
                                }
                                style={{ border: "1px solid grey" }}
                              ></input>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        ""
                      )
                    ) : null}
                  </Card.Body>
                </Col>
              </Row>
              <br />
              <br />
            </div>


            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  style={{
                    color: "#810e36",
                    paddingTop: "20px",
                    borderBottom: "1px dashed rgb(0 150 63 / 52%)",
                    paddingBottom: "20px",
                  }}
                >
                  {"Commercials Summary"}
                </Typography>
              </Grid>
            </Grid>
            <DialogContent>
              <Paper className={tables.root} style={{ border: "1px solid" }}>
                {/* <h5 className="p-2" style={{ fontWeight: "bold", borderBottom: "1px solid" }}>Commercials Summary</h5> */}

                <MaterialTable
                  columns={[
                    { title: "Center Name", field: "center", render: (row) => (<div>{row.center ? row.center : "---"}</div>) },
                    { title: "Item Group", field: "itemgroup", render: (row) => (<div>{row.itemgroup ? row.itemgroup : "---"}</div>) },
                    { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
                    { title: "Commercial Term", field: "commercialterm", render: (row) => (<div>{row.commercialterm ? row.commercialterm : "---"}</div>) },
                  ]}
                  data={doctorOpCommercial}
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text="Out-Patient Commercial" />}
                />
                <MaterialTable
                  columns={[
                    { title: "Center Name", field: "Center", render: (row) => (<div>{row.Center ? row.Center : "---"}</div>) },
                    { title: "Group", field: "group", render: (row) => (<div>{row.group ? row.group : "---"}</div>) },
                    { title: "Item Group", field: "itemGroup", render: (row) => (<div>{row.itemGroup ? row.itemGroup : "---"}</div>) },
                    { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
                    { title: "Room Category", field: "roomCategory", render: (row) => (<div>{row.roomCategory ? row.roomCategory : "---"}</div>) },
                    { title: "MOP", field: "ipmodeofpayment", render: (row) => (<div>{row.ipmodeofpayment ? row.ipmodeofpayment : "---"}</div>) },
                    { title: "Commercial Term", field: "commercialTerm", render: (row) => (<div>{row.commercialTerm ? row.commercialTerm : "---"}</div>) },
                  ]}
                  // data={doctorDetail.doctorIPParameter}
                  data={doctorIpDetail}
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text="In-Patient Commercials" />}
                />
                {doctorMgDetails && doctorMgDetails.length > 0 ?
                  doctorMgDetails.map((item) =>
                    <MaterialTable
                      columns={[
                        { title: "Minimum Guarantee Payment: The Company agrees to pay the Consultant an amount of Rs. " + numberWithCommas(item.minguranteepermonth) + " (Rs. " + numberToEnglish((item.minguranteepermonth).replaceAll(',', '')) + ") per month (“Minimum Guarantee Amount”) or the “Calculated Earnings” whichever is higher subject to statutory deductions. ", field: "mgCommercial" },
                      ]}
                      // data={doctorDetail.doctorIPParameter}
                      data={
                        [
                          {
                            mgCommercial: 'The Minimum Guarantee Amount will be paid for ' + item.mgperiod + ' months'
                          },
                          {
                            mgCommercial: 'The earnings shall be calculated on ' + item.settlementperiod + ' basis and difference amount (Calculated Earnings minus Minimum Guarantee), if any, shall be paid at the end of ' + item.settlementpaymentperiod + '.'
                          },
                        ]
                      }
                      options={{
                        // filtering: true,
                        sorting: true,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                          fontWeight: 400
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text={"Minimum Guarentee (MG) Commercial for " + item.Center} />}
                    />
                  )
                  : ''}
                {doctorFixedDetails && doctorFixedDetails.length > 0 ?
                  doctorFixedDetails.map((item) =>
                    <MaterialTable
                      columns={[
                        { title: "Monthly Fixed Fee: The Company will pay an all-inclusive amount of Rs. " + numberWithCommas((item.professionalfeepermonth).replaceAll(',', '')) + " (Rs. " + numberToEnglish((item.professionalfeepermonth).replaceAll(',', '')) + ") per month. ", field: "fixedCommercial", width: "100%" },
                      ]}
                      // data={doctorDetail.doctorIPParameter}
                      data={
                        [
                          {
                            // fixedCommercial: item.additionalfeevalue != '0' ? "Additional Fee for " + item.additionalfeename + ": The Company will pay an additional amount of Rs. " + numberWithCommas(((item.additionalfeevalue).toString()).replaceAll(',', '')) + " (Rs. " + numberToEnglish(((item.additionalfeevalue).toString()).replaceAll(',', '')) + ") per month. " : ""
                            // fixedCommercial: item.additionalfeevalue != '0' ? "Additional Fee for " + item.additionalfeename + ": The Company will pay an additional amount of Rs. " + numberWithCommas(((item.additionalfeevalue)).replaceAll(',', '')) + " (Rs. " + numberToEnglish(((item.additionalfeevalue)).replaceAll(',', '')) + ") per month. " : ""
                          },
                        ]
                      }
                      options={{
                        // filtering: true,
                        sorting: false,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                          fontWeight: 400
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text={"Full Time Consultant Commercial for " + item.Center} />}
                    />
                  )
                  : ''}
              </Paper>
              <br />
            </DialogContent>
            <div className="col-xl-12 col-md-12 pt-4">
              <Row>
                <Col>
                  <Card.Body>
                    {oboard.doctorDetails &&
                      (oboard.doctorDetails.isConfirmed == 0 ||
                        oboard.doctorDetails.isConfirmed == null) ? (
                      user && user.user && user.user.activityAssigments.filter((elem) => elem.activityId === 8).find(item => item.RoleId === user.user.roles) ? (
                        <>
                          <Row style={{ margin: "1rem", float: "right" }}>
                            <button
                              className="btn btn-primary"
                              onClick={ConfirmLtID}
                            >
                              Confirm
                            </button>
                          </Row>
                        </>
                      ) : (
                        ""
                      )
                    ) : null}
                  </Card.Body>
                </Col>
              </Row>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        disableBackdropClick
      >
        <DialogTitle
          id="customized-dialog-title"
          // onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers></DialogContent>
        <Iframe
          url={urlData}
          width="900px"
          height="800px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
        />

        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <Backdrop
        className={backClasses.backdrop}
        open={backDropOpen}
      >
        <img src={c9gif} alt="loading..." />
      </Backdrop>
    </>
  );
}
