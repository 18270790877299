import React, { useEffect, useState } from "react";
import AppSidebar from "../../Components/AppSidebar";
import Sidebar from "../../Components/Sidebar";
import DoctorSidebar from "../../Components/DoctorSidebar";
import axios from "axios";
import { Card, Table, Toast } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { agreementState } from "../../CommomMethods/States";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Navmain from "../../Components/Navbar";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialTable from "material-table";
import { listusers } from "../../actions/userActions";
import { agreementlist } from "../../actions/agreementActions";
import { Backdrop, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { APIURL } from "../../CommomMethods/Fetch";
import { numberWithCommas, numberToEnglish } from "../../actions/createPositionActions"
import c9gif from '../../SampleFile/cloudnine.gif';
const { onboardState } = require("../../CommomMethods/States");
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  buttonProgress: {
    // backgroundColor: "red",
    color: "white",
    position: "absolute",
    top: "50%",
    left: "20%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
}));

export default function CalculationData(props) {
  console.log(props, "props------------------->")
  const tables = myTableStyle();
  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  const initialFormtState = {
    consultation: "",
    totalRevenue: "",
    opfixedpay: "",
    oppercpay: "",
    opadhoc: "",
    opcount: "",
    opmonth: "",
    fixedamount: "",
    fixedpercentage: "",
    opperunitcharge: "",
    netamount: "",
    opamount: "",
    oprevenue: "",
    opgross: "",
    opnet: "",
    oppayout: "",
    opdiscounttype: "",
    opdiscountvalue: "",
  };
  const [row, setOpservice] = useState({
    initialFormtState,
  });
  const initialFormtStateipservice = {
    itemGroup: "",
    ipconsultaion: "",
    iproom: "",
    ipmodeofpayment: "",
    ipfixedamount: "",
    ipsurgeonfeeperc: "",
    ippackageamountperc: "",
    ipsurgeonfeeamount: "",
    ipdiscountvalue: "",
    ipdiscountType: "",
    ipgrossperc: "",
    ipnetperc: "",
    ipmonth: "",
    netperc: "",
    amount: "",
    count: "",
    revenue: "",
    payout: "",
    ipgrossbill: "",
    ipgrossbillpercentage: "",
    ipdeductaionvalue: "",
    deductionCat: "",
    ipnetbillpercentage: "",
    iptotalamount: "",
    ipnetbillamount: "",
    ipTotalAmount: "",
    ipTotalNetBillAmount: "",
    ipgrossbillperunit: "",
    ipdeductionperunit: "",
  };
  const [iprow, setIpservice] = useState([
    {
      initialFormtStateipservice,
    },
  ]);
  const [totaldeduction, setTotaldeduction] = useState(0);
  const [adhoc, setAdhoc] = useState("");
  const [opRevenue, setopRevenue] = useState(0);
  const [opNetPayout, setOpNetPayout] = useState(0);
  const [gross, setGross] = useState("");
  const [opNetAmount, setOpNetAmount] = useState(0);
  const [onboardData, setOnboardData] = useState([]);
  const [openr, setOpenr] = React.useState(false);
  const [openOp, setOpenOp] = React.useState(false);
  const [openIp, setOpenIp] = React.useState(false);
  const [openCommercials, setOpenCommercials] = React.useState(false);
  const [openReject, setOpenRejectmodal] = React.useState(false);
  const [openApprove, setOpenApprovemodal] = React.useState(false);
  const [review, setReview] = useState("");
  const [approve, setapprove] = useState([]);
  const [rej, setRej] = useState([]);

  const [docid, setDocid] = useState("");
  const [doctorDetail, setDoctorDetail] = useState([]);
  const [doctorOpDetail, setDoctorOpDetail] = useState([]);
  const [doctorOpDetailEdit, setDoctorOpDetailEdit] = useState([]);

  const [doctorIpDetail, setDoctorIpDetail] = useState([]);
  const [doctorOpCommercial, setDoctorOpCommercial] = useState([]);
  const [doctorIpCommercial, setDoctorIpCommercial] = useState([]);
  const [doctorMgDetails, setDoctorMgDetails] = useState([]);
  const [doctorFixedDetails, setDoctorFixedDetails] = useState([]);
  const [remark, setRemark] = useState("");
  const [status, setStatus] = useState(false);
  const [ipgrossbillTot, setipgrossbillTot] = useState("");
  const [ipdeductiontot, setipdeductiontot] = useState(0);
  const [netIp, setnetIp] = useState("");
  const [ipAmountTot, setIpAmountTot] = useState(0);
  const [ipPayoutTotal, setIpPayoutTotal] = useState(0);
  const [grossIp, setGrossIp] = useState("");
  const [ipdiscount, setIpDiscount] = useState("Not applicable");
  const [ipNetBillAmountTot, setipNetBillAmountTot] = useState(0);
  const [TotalPayOutValue, setTotalPayOutValue] = useState([]);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const agreementList = useSelector((state) => state.agreementList);
  const { agreements } = agreementList;

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const backClasses = backDropStyle();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(agreementlist());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    setBackDropOpen(true);
    axios
      .get(APIURL() + "onboard/getCentersbyRd/" + user.user.id)
      .then((res) => {
        setOnboardData(res.data);
        setBackDropOpen(false);
      })
      .catch((error) => { });
  }, [user]);

  const handleComments = (e) => {
    setReview(e.target.value)
  };
  const handleRejectDialog = (row) => {
    setDocid(row.doctorId);
    setOpenRejectmodal(true);
    setRej(row);
  };

  const handleApproveDialog = (row) => {
    setDocid(row.doctorId);
    setOpenApprovemodal(true)
    setapprove(row);
  };
  function getDocdetail(id) {
    axios
      .get(APIURL() + "onboard/previewOne/" + id)
      .then((res) => {
        setDoctorDetail(res.data);
        setDoctorIpDetail(res.data.doctorIPParameter);
        setDoctorOpDetail(res.data.doctorOPParameter);
        setDoctorOpDetailEdit(res.data.doctorOPParameter);
        setDoctorOpCommercial(res.data.doctorOpCommercial);
        setDoctorIpCommercial(res.data.doctorIpCommercial);
        setDoctorMgDetails(res.data.doctorMgDetails);
        setDoctorFixedDetails(res.data.doctorFixedDetails);
        let TotalRev = 0
        let TotalPayOutOP = 0
        let TotalPayOutIP = 0
        let TotalMGorFixedValue = 0
        let TotalPayout = 0
        if (res.data.doctorIPParameter && res.data.doctorIPParameter.length > 0) {
          res.data.doctorIPParameter.map((elem) => {
            TotalRev = TotalRev + parseFloat(elem.ipTotalAmount)
            TotalPayOutIP = TotalPayOutIP + parseFloat(elem.payout)
          })
        }
        if (res.data.doctorOPParameter && res.data.doctorOPParameter.length > 0) {
          res.data.doctorOPParameter.map((elem) => {
            TotalRev = TotalRev + parseFloat(elem.oprevenue)
            TotalPayOutOP = TotalPayOutOP + parseFloat(elem.oppayout)
          })
        }
        if (res.data.doctorMgDetails && res.data.doctorMgDetails.length > 0) {
          res.data.doctorMgDetails.map((elem) => {
            TotalMGorFixedValue = TotalMGorFixedValue + parseFloat(elem.minguranteepermonth)
          })
          if (TotalMGorFixedValue > (TotalPayOutIP + TotalPayOutOP)) {
            TotalPayout = TotalMGorFixedValue
          } else {
            TotalPayout = (TotalPayOutIP + TotalPayOutOP)
          }
        }
        if (res.data.doctorFixedDetails && res.data.doctorFixedDetails.length > 0) {
          res.data.doctorFixedDetails.map((elem) => {
            TotalMGorFixedValue = TotalMGorFixedValue + parseFloat(((elem.professionalfeepermonth).toString()).replaceAll(',', '')) + parseFloat(((elem.additionalfeevalue).toString()).replaceAll(',', ''))
          })
          TotalPayout = TotalMGorFixedValue + (TotalPayOutIP + TotalPayOutOP)
        }
        setTotalPayOutValue([{
          "TotalRevenue": TotalRev,
          "TotalIP": TotalPayOutIP,
          "TotalOP": TotalPayOutOP,
          "TotalMGorFixedValue": TotalMGorFixedValue,
          "Payout": TotalPayout
        }])
      })
      .catch((error) => { });
  }
  // console.log(TotalPayOutValue && TotalPayOutValue.length > 0 && numberToEnglish(TotalPayOutValue[0].TotalMGorFixedValue), "kjkjkj====>")
  console.log(doctorIpDetail, doctorOpDetail, doctorMgDetails, doctorFixedDetails, "kjkjkj====>")

  const handlerClickOpen = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenr(true);
  };
  const handlerClose = () => {
    setOpenr(false);
  };
  const handlerClickOpenOp = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenOp(true);
  };
  const handlerCloseOp = () => {
    setOpenOp(false);
  };

  const handlerClickOpenIp = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenIp(true);
  };

  const handlerClickOpenCommercials = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }
    setOpenCommercials(true);
  };

  const handlerClickcloseCommercials = () => {
    setOpenCommercials(false);
  };

  const handlerCloseIp = () => {
    setOpenIp(false);
  };
  const handleApprove = async (ele) => {
    console.log(approve, ele, 'approveapproveapprove')
    ele.currentTarget.disabled = true;
    // alert("The Agreement is APPROVED")
    if ((review).trim() == '') {
      toast.error("Kindly provide some comments");
      ele.currentTarget.disabled = false;
      return false;
    }
    await setBackDropOpen(true)
    await setStatus(true);
    const obj = {
      doctorId: approve.doctorId,
      name: approve.name,
      currentAction: agreementState.init.state,
      currentActionBy: users[0].id,
      nextAction: agreementState.hr_revision.state,
      remark: review,
      status: 1,
    };
    const obj1 = {
      doctorId: approve.doctorId,
      requested_id: approve.requestId,
      name: approve.name,
      currentAction: onboardState.rd.state,
      currentActionBy: user.user.id,
      role: user.user.roles,
      nextAction: onboardState.dr.state,
      remark: review,
      status: 1,
    };
    await axios.post(APIURL() + "onboard/action/", obj1).then(async (res) => {
      axios.post(APIURL() + "onboard/create-lt-profile", obj1)
      axios.post(APIURL() + "onboard/create-d365-vendor", obj1)
      await axios.post(APIURL() + "onboard/generate-agreement/", obj1).then((res) => {
        setStatus(false)
        setBackDropOpen(false)
        setOpenApprovemodal(false);
        window.location.reload();
      });
    });
    //axios.post(APIURL() + "onboard/agreementAction/", obj).then((res) => {});
  };
  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  var n;
  function inWords(num) {
    if ((num = num.toString()).length > 9) return "overflow";
    n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "only "
        : "";
    return str;
  }

  var amount = parseInt(
    doctorDetail.doctorCommercial &&
    doctorDetail.doctorCommercial[0] &&
    doctorDetail.doctorCommercial[0].ctcmonth
  );

  var addamount = parseInt(
    doctorDetail.doctorCommercial &&
    doctorDetail.doctorCommercial[0] &&
    doctorDetail.doctorCommercial[0].additionalfee
  );

  const Remark = (e) => {
    setRemark(e.target.value);
  };
  const updateRecord = () => {
    doctorOpDetail.forEach((element) => {
      element.chstatus = 0;
    });
    doctorIpDetail.forEach((element) => {
      element.chstatus = 0;
    });
    doctorOpCommercial.forEach((element) => {
      element.chstatus = 0;
    });
    doctorIpCommercial.forEach((element) => {
      element.chstatus = 0;
    });
    const obj = {
      doctorOpDetail,
      doctorIpDetail,
      doctorOpCommercial,
      doctorIpCommercial,
    };
    axios
      .put(APIURL() + "onboard/updateFourtables/" + docid, obj)
      .then((res) => { });
    if (remark != "") {
      const obj1 = {
        doctorId: doctorDetail.doctorDetails.id,
        name: doctorDetail.doctorDetails.name,
        currentAction: onboardState.rd.state,
        currentActionBy: 1,
        nextAction: onboardState.upload.state,
        remark: remark,
        status: 1,
      };
      axios.post(APIURL() + "onboard/action/", obj1).then((res) => {
        // toast.success("Rectification successfully submitted");
      });
      window.location.reload();
    }
  };
  const TableTitle = ({ text }) => (
    <Typography
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "black",
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "15px"
      }}
    >
      {text}
    </Typography>
  );
  const handleInputChangeIp = (row, event) => {
    const { name, value } = event.target;
    console.log(name, "ip-name");
    setDoctorIpDetail(row);
    if (name == "ipTotalAmount") {
      doctorIpDetail.ipTotalAmount = value;
    }
    if (name == "count") {
      doctorIpDetail.count = value;
    }
    if (name == "ipsurgeonfeeperc") {
      doctorIpDetail.ipsurgeonfeeperc = value;
    }
    if (name == "ippackageamountperc") {
      doctorIpDetail.ippackageamountperc = value;
    }

    if (name == "ipgrossbillpercentage") {
      doctorIpDetail.ipgrossbillpercentage = value;
    }
    if (name == "ipfixedamount") {
      doctorIpDetail.ipfixedamount = value;
    }
    if (name == "fixedamount") {
      doctorIpDetail.fixedamount = value;
    }
    if (name == "ratio") {
      doctorIpDetail.ratio = value;
    }

    if (name == "ipdeductaionvalue") {
      doctorIpDetail.ipdeductaionvalue = value;
    }

    setIpservice({ ...iprow, [name]: value });

  }


  const handleInputChange = (row, event) => {
    const { name, value } = event.target;
    setDoctorOpDetail(row);
    if (name == "fixedamount") {
      doctorOpDetail.fixedamount = value;
    }
    if (name == "fixedpercentage") {
      doctorOpDetail.fixedpercentage = value;
    }
    if (name == "opcount") {
      doctorOpDetail.opcount = value;
    }
    if (name == "opperunitcharge") {
      doctorOpDetail.opperunitcharge = value;
    }
    if (name == "netamount") {
      doctorOpDetail.netamount = value;
    }
    if (name == "oprevenue") {
      doctorOpDetail.oprevenue = value;
    }
    if (name == "opgross") {
      doctorOpDetail.opgross = value;
    }
    if (name == "opnet") {
      doctorOpDetail.opnet = value;
    }
    if (name == "totalRevenue") {
      doctorOpDetail.totalRevenue = value;
    }
    if (name == "opadhoc") {
      doctorOpDetail.opadhoc = value;
    }
    console.log(name, "%%%%55");
    setOpservice({ ...row, [name]: value });
  };

  console.log(doctorOpDetailEdit, "OOOOOO");

  const opCalculation = (row) => {
    //deduction calculation
    console.log(row, "LLLL");
    let deduct = parseInt(row.opadhoc);
    if (isNaN(deduct)) {
      deduct = 0;
    } else {
    }

    setAdhoc(deduct);

    let unit = parseInt(row.opperunitcharge);
    let count = parseInt(row.opcount);
    //unit value checking
    if (isNaN(unit)) {
      unit = 0;
    } else {
    }
    //count value checking
    if (isNaN(count)) {
      count = 0;
    } else {
    }
    let gs = unit * count;
    let discountval = parseInt(row.opdiscountvalue) * count;
    //discount value checking
    if (isNaN(discountval) || row.opdiscountvalue.length === 0) {
      discountval = 0;
    } else {
    }

    let netVal = gs - discountval;
    let deduction = parseInt(row.opadhoc) * count;
    // let totalRevenue = parseInt(row.totalRevenue) * count;
    let totalRevenue = parseInt(row.totalRevenue);

    if (isNaN(deduction)) {
      deduction = 0;
    } else {
    }

    if (isNaN(totalRevenue)) {
      totalRevenue = 0;
    } else {
    }
    //net value checking
    deduction = totalRevenue ? deduct : deduction
    discountval = totalRevenue ? parseInt(row.opdiscountvalue) : discountval
    gs = totalRevenue ? totalRevenue : gs
    netVal = totalRevenue ? totalRevenue - deduction : netVal

    setTotaldeduction(deduction);
    if (isNaN(deduction)) {
      deduction = 0;
    } else {
    }
    let opRevenueval = netVal - deduction;
    let percnt = parseInt(row.fixedpercentage);
    let fixedamt = parseInt(row.fixedamount);

    console.log(percnt, fixedamt, "UUUUU");
    //payout value checking

    if (isNaN(percnt)) {
      percnt = 0;
    } else {
    }
    if (isNaN(fixedamt)) {
      fixedamt = 0;
    } else {
    }

    let fixedDeduction = totalRevenue
      ? totalRevenue * (percnt / 100)
      : fixedamt
        ? fixedamt * (count > 0 ? count : 1)
        : opRevenueval * (percnt / 100);
    fixedDeduction = fixedDeduction.toFixed(2);
    //set value
    setGross(gs);
    // setOpNetAmount(gs - discountval);
    setOpNetAmount(gs - deduction);

    if (row.totalRevenue) {
      // row.totalRevenue=opRevenueval
      setopRevenue(opRevenueval);
    } else {
      // row.totalRevenue=(netVal - deduction)
      setopRevenue(netVal - deduction);
    }
    console.log(opRevenueval, 'opRevenuevalopRevenueval')

    setOpNetPayout(fixedDeduction);
  };

  const ipCalculation = (row, event) => {
    setIpDiscount(row.ipdiscountType);
    console.log(row, "UUU");
    let packageamount = 5000;
    let surgeonfee = parseInt(row.ipsurgeonfeeamount);
    var fixedAmount = parseFloat(row.fixedamount);
    var perunitcharge = parseFloat(row.ipgrossbillperunit);
    var ratio = parseFloat(row.ratio);
    let count = parseInt(row.count);
    let applicableOn = row.applicableOn;
    let disperu = parseInt(row.ipdeductionperunit);

    if (isNaN(count)) {
      count = 0;
    } else {
    }
    if (isNaN(perunitcharge)) {
      perunitcharge = 0;
    } else {
    }
    if (isNaN(ratio)) {
      ratio = 0;
    } else {
    }
    if (isNaN(disperu)) {
      disperu = 0;
    } else {
    }

    let deductiontot = disperu * count;
    var grossbilltot = parseFloat(perunitcharge) * count;
    var finalNetbillcount = parseFloat(perunitcharge) * count;
    if (finalNetbillcount && finalNetbillcount > 0) {
      finalNetbillcount = finalNetbillcount - deductiontot
    }
    var ipPayouttot = 0
    if (applicableOn && applicableOn.includes('Fixed')) {
      ipPayouttot = fixedAmount * count
    } else {
      ipPayouttot = grossbilltot * ratio
    }
    var amountip = grossbilltot
    setIpAmountTot(amountip);
    setipgrossbillTot(grossbilltot);
    setipdeductiontot(deductiontot);
    setipNetBillAmountTot(finalNetbillcount);
    setIpPayoutTotal(ipPayouttot);
  };

  const handleReject = () => {
    if (review == '') {
      toast.error("Kindly provide some comments");
      return false;
    }
    const obj1 = {
      doctorId: rej.doctorId,
      requested_id: rej.requestId,
      name: rej.name,
      currentAction: onboardState.rd_revision.state,
      currentActionBy: user.user.id,
      role: user.user.roles,
      nextAction: onboardState.init.state,
      remark: review,
      status: 2,
    };
    axios.post(APIURL() + "onboard/action/", obj1).then((res) => {
      setStatus(false)
      window.location.reload();
    });
  }
  console.log(doctorOpDetail, "PPPPPPPPPPP");
  return (
    <>
      {
        user.user.roles == 4 ? <DoctorSidebar />
          :
          user.user.roles !== 0 ? <Sidebar />
            : <AppSidebar />
      }

      <div className="main-content side-content pt-0">
        <Navmain />
        {user.user.activityAssigments.filter((elem) => elem.activityId === 3 || elem.activityId === 14).find(item => item.RoleId === user.user.roles) ?
          <Card.Body
            // style={bodyStyle}
            style={{ width: "100%", marginTop: "4rem" }}
          >
            <MaterialTable
              columns={[
                { title: "Doctor Name", field: "name" },
                { title: "Doctor Type", field: "category" },
                { title: "Comments", field: "remark" },
                {
                  title: "Estimation Details",
                  field: "Action",
                  filtering: false,
                  render: (row) => (
                    <div style={{ width: "140px" }}>
                      <i
                        className="fas fa-address-card"
                        style={{ fontSize: "20px", color: "#810e36" }}
                        onClick={() => handlerClickOpenOp(row)}
                      />
                    </div>
                  ),
                },
                {
                  title: "Action",
                  field: "Action",
                  filtering: false,
                  render: (row) =>
                    row.nextAction === onboardState.rd.state ? (
                      <div className={tables.wrapper} style={{ margin: '0px' }}>
                        {console.log(row, "row=====>")}
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          // style={{ backgroundColor: "#810e36", color: "white" }}
                          // className={buttonClassname}
                          disabled={status}
                          onClick={() => {
                            handleApproveDialog(row);
                          }}
                        >
                          Approve
                        </Button>

                        <Button
                          style={{ marginLeft: '10px', backgroundColor: '#810e36', color: '#fff' }}
                          color="error"
                          size="small"
                          variant="contained"
                          onClick={() => {
                            handleRejectDialog(row);
                          }}
                        >
                          Review
                        </Button>
                        {status && (
                          <CircularProgress
                            size={24}
                            className={tables.buttonProgress}
                          />
                        )}
                      </div>
                    )
                      : row.nextAction == onboardState.dr_proposed_commercials_accepted.state ?
                        <span className="badge badge-secondary" style={{ backgroundColor: "gray" }}>Awaiting Doctor Acceptance</span>
                        : row.currentAction == onboardState.rd_revision.state && row.nextAction === onboardState.init.state ? (
                          <span className="badge badge-danger">Sent for revision</span>
                        )
                          : (
                            <span className="badge badge-success">Approved</span>
                          ),
                },
              ]}
              data={onboardData.data}
              // data={
              //   onboardData &&
              //   onboardData.data &&
              //   onboardData.data.filter(
              //     (z) => z.nextAction === onboardState.rd.state
              //   )
              // }
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
                searchText: props && props.location && props.location.state && props.location.state.data && props.location.state.data.doctorName ? props.location.state.data.doctorName : ''
              }}
              title="Estimation Approval"
            />
          </Card.Body>
          : <div>
            <h2 style={{ marginTop: "200px", display: 'flex', justifyContent: 'center', color: "#d11d53" }}>You Are Not Authorized To See This Page</h2>
          </div>
        }

      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Doctor Detail
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Name:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].fullname}
          </Typography>
          <Typography gutterBottom>
            Email-Id:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].email}
          </Typography>
          <Typography gutterBottom>
            Mobile:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].mobile}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="xl"
        onClose={handlerCloseOp}
        aria-labelledby="customized-dialog-title"
        open={openOp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseOp}
          style={{ textAlign: "center" }}
        >
          <h5
            style={{
              margin: ".4rem",
              textAlign: "center",
              padding: "7px",
              backgroundColor: "#810e36",
              color: "white",
            }}
          >
            <b>Estimations and Commercials Summary</b>
          </h5>
        </DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root} style={{ border: "1px solid" }}>
            <h5 className="p-2" style={{ fontWeight: "bold", borderBottom: "1px solid" }}>Estimations Summary</h5>
            <MaterialTable
              style={{
                padding: 0,
                margin: 0,
                border: "none"
              }}
              columns={[
                {
                  title: "Center Name",
                  field: "center",
                },
                {
                  title: "Item Group Name",
                  field: "itemGroup",
                },
                {
                  title: "Item  Name",
                  field: "itemName",
                  render: (row) => (
                    <div>{row.itemName ? row.itemName : "---"}</div>
                  ),
                },
                {
                  title: "Discount Applicable",
                  field: "opdiscounttype",
                  render: (row) => (
                    <div>
                      {row.opdiscounttype ? row.opdiscounttype : "---"}
                    </div>
                  ),
                },
                {
                  title: "Deduction Value",
                  field: "opadhoc",
                  render: (row) => (
                    <div>
                      {/* {row.opadhoc && row.opadhoc !== "0"
                        ? "Rs. " + numberWithCommas(row.opadhoc)
                        : "---"} */}
                      <TextField
                        id="opadhoc"
                        // label="opadhoc"
                        // variant="outlined"
                        multiline
                        variant="filled"
                        fullWidth
                        name="opadhoc"
                        value={row.opadhoc ? row.opadhoc : "---"}
                        onChange={(event) => handleInputChange(row, event)}
                        onBlur={() => opCalculation(row)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                      />
                    </div>
                  ),
                },
                {
                  title: "Fixed Amount",
                  field: "fixedamount",
                  render: (row) => (
                    <div>
                      {/* {row.fixedamount && row.fixedamount !== "0"
                        ? "Rs. " + numberWithCommas(row.fixedamount)
                        : "---"} */}
                      <TextField
                        id="fixedamount"
                        // label="Fixed Amount"
                        // variant="outlined"
                        multiline
                        variant="filled"
                        fullWidth
                        name="fixedamount"
                        value={row.fixedamount && row.fixedamount > 0 ? row.fixedamount : "0"}
                        onChange={(event) => handleInputChange(row, event)}
                        onBlur={() => opCalculation(row)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                      />
                    </div>
                  ),
                },
                {
                  title: "Fixed Percentage",
                  field: "fixedpercentage",
                  render: (row) => (
                    <div>
                      {/* {row.fixedpercentage ? row.fixedpercentage + "%" : "---"} */}
                      <TextField
                        id="Payout"
                        // label="Fixed Percentage"
                        // variant="outlined"
                        multiline
                        variant="filled"
                        fullWidth
                        name="fixedpercentage"
                        value={row.fixedpercentage ? row.fixedpercentage : "---"}
                        onChange={(event) => handleInputChange(row, event)}
                        onBlur={() => opCalculation(row)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                      />
                    </div>
                  ),
                },
                {
                  title: "Total Revenue",
                  field: "totalRevenue",
                  render: (row) => (
                    <div>
                      {/* {row.totalRevenue
                        ? "Rs. " + numberWithCommas(row.totalRevenue)
                        : "---"} */}
                      <TextField
                        id="Total Revenue"
                        // label="Total Revenue"
                        // variant="outlined"
                        multiline
                        variant="filled"
                        fullWidth
                        name="totalRevenue"
                        value={row.totalRevenue ? row.totalRevenue : row.totalRevenue}
                        onChange={(event) => handleInputChange(row, event)}
                        onBlur={() => opCalculation(row)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                      />
                    </div>
                  ),
                },
                {
                  title: "No. of Procedures",
                  field: "opcount",
                  render: (row) => (
                    <div>
                      {/* {row.opcount ? row.opcount : "---"} */}
                      <TextField
                        id="Payout"
                        multiline
                        variant="filled"
                        // label="No. of Procedures"
                        // variant="outlined"
                        fullWidth
                        name="opcount"
                        value={row.opcount ? row.opcount : row.opcount}
                        onChange={(event) => handleInputChange(row, event)}
                        onBlur={() => opCalculation(row)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                      />
                    </div>
                  ),
                },
                // {
                //   title: "No. of Months",
                //   field: "opmonth",
                //   render: (row) => (
                //     <div>{row.opmonth ? row.opmonth : "---"}</div>
                //   ),
                // },
                {
                  title: "Per Unit Charge",
                  field: "opperunitcharge",
                  render: (row) => (
                    <div>
                      {/* {row.opperunitcharge
                        ? "Rs. " + numberWithCommas(row.opperunitcharge)
                        : "---"} */}
                      <TextField
                        id="Per Unit Charge"
                        // label="Per Unit Charge"
                        // variant="outlined"
                        multiline
                        variant="filled"
                        fullWidth
                        name="opperunitcharge"
                        value={row.opperunitcharge ? row.opperunitcharge : row.opperunitcharge}
                        onChange={(event) => handleInputChange(row, event)}
                        onBlur={() => opCalculation(row)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                      />
                    </div>
                  ),
                },
                {
                  title: "Gross",
                  field: "opgross",
                  render: (row) => (
                    <div>
                      {/* {row.opgross
                        ? "Rs. " + numberWithCommas(row.opgross)
                        : "---"} */}
                      <TextField
                        id="Payout"
                        // label="Gross"
                        // variant="outlined"
                        multiline
                        variant="filled"
                        fullWidth
                        name="opgross"
                        value={row.opgross ? row.opgross : row.opgross}
                        onChange={(event) => handleInputChange(row, event)}
                        onBlur={() => opCalculation(row)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                      />
                    </div>
                  ),
                },
                {
                  title: "Net",
                  field: "opnet",
                  render: (row) => (
                    <div>
                      {/* {row.opnet
                        ? "Rs. " + numberWithCommas(row.opnet)
                        : "---"} */}
                      <TextField
                        id="Net"
                        // label="Net"
                        multiline
                        variant="filled"
                        fullWidth
                        name="opnet"
                        value={row.opnet ? row.opnet : row.opnet}
                        onChange={(event) => handleInputChange(row, event)}
                        onBlur={() => opCalculation(row)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                      />
                    </div>
                  ),
                },
                {
                  title: "Revenue",
                  field: "oprevenue",
                  render: (row) => (
                    <div>
                      {opRevenue > 0
                        ? "Rs. " + numberWithCommas(opRevenue) : "Rs. " + numberWithCommas(row.oprevenue)
                      }
                    </div>
                  ),
                },
                {
                  title: "Payout",
                  field: "oppayout",
                  render: (row) => (
                    <div>
                      {opNetPayout
                        ? "Rs. " + numberWithCommas(opNetPayout) : "Rs. " + numberWithCommas(row.oppayout)
                      }
                    </div>
                  ),
                },
              ]}
              data={doctorOpDetail}
              options={{
                sorting: true,
                search: false,
                paging: false,
                rowStyle: {
                  fontSize: 12,
                  padding: 0
                },
                cellStyle: {
                  border: "1px solid rgba(224, 224, 224, 1)",
                  padding: "3px 10px",
                  minWidth: 150,
                  maxWidth: 150
                },
                headerStyle: {
                  border: "1px solid rgba(224, 224, 224, 1)",
                  padding: "3px 10px",
                  fontSize: 12,
                },
              }}
              title={<TableTitle text="Out-Patient Service Estimation" />}
            />
            {/* <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Item Name", field: "itemName" },
                { title: "Revenue", field: "oprevenue", render: (row) => row.oprevenue ? "Rs. " + numberWithCommas(parseFloat(row.oprevenue).toFixed()) : "" },
                { title: "Pay Out", field: "oppayout", render: (row) => row.oppayout ? "Rs. " + numberWithCommas(parseFloat(row.oppayout).toFixed()) : "" },
                { title: "Number of Months", field: "opmonth" },
                // { title: "Net Amount", field: "netamount" },
              ]}
              // data={doctorDetail.doctorOPParameter} setDoctorOpDetail
              data={doctorOpDetail}

              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.chstatus === true ? "orangered" : "#FFF",
                }),
              }}
              title="OP Details"
            /> */}
            <MaterialTable
              columns={[
                {
                  title: "Center Name",
                  field: "Center",
                },
                {
                  title: "Group",
                  field: "group",
                },
                {
                  title: "Item Group Name",
                  field: "itemGroup",
                },
                {
                  title: "Procedure Name",
                  field: "itemName",
                  render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>)
                },
                {
                  title: "Room Type",
                  field: "roomCategory",
                  render: (row) => (<div>{row.roomCategory ? row.roomCategory : "---"}</div>)
                },

                {
                  title: "Mode of Payment",
                  field: "ipmodeofpayment",
                  render: (row) => (<div>{row.ipmodeofpayment ? row.ipmodeofpayment : "---"}</div>)
                },
                {
                  title: "Slab",
                  field: "slab",
                  render: (row) => row.slab ? 'Yes' : 'No'
                },
                {
                  title: "Discount Applicable",
                  field: "ipdiscountType",
                },
                {
                  title: "Apllicable On",
                  field: "applicableOn",
                },
                {
                  title: "Fixed Amount",
                  field: "fixedamount",
                  // render: (row) => (<div>{row.ipfixedamount && row.ipfixedamount !== "0" ? "Rs. " + numberWithCommas(row.ipfixedamount) : "---"}</div>)
                  render: (row) => (
                    <div>
                      <TextField
                        id="fixedamount"
                        // label="ipfixedamount"
                        // variant="outlined"
                        multiline
                        variant="filled"
                        fullWidth
                        name="fixedamount"
                        value={row.fixedamount ? row.fixedamount : "---"}
                        onChange={(event) => handleInputChangeIp(row, event)}
                        onBlur={() => ipCalculation(row)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                      />
                    </div>)
                },
                {
                  title: "Deduction Value",
                  field: "ipdeductaionvalue",
                  render: (row) => (<div>
                    {/* {row.ipdeductaionvalue && row.ipdeductaionvalue !== "0" ? "Rs. " + numberWithCommas(row.ipdeductaionvalue) : "---"
                  } */}
                    <TextField
                      id="ipdeductaionvalue"
                      // label="Deduction Value"
                      // variant="outlined"
                      multiline
                      variant="filled"
                      fullWidth
                      name="ipdeductaionvalue"
                      value={row.ipdeductaionvalue && row.ipdeductaionvalue !== "0" ? row.ipdeductaionvalue : row.ipdeductaionvalue
                      }
                      onChange={(event) => handleInputChangeIp(row, event)}
                      onBlur={() => ipCalculation(row)}
                      style={{ marginBottom: "20px" }}
                      autoComplete="off"
                    />
                  </div>)
                },
                {
                  title: "Fixed Percentage",
                  field: "ratio",
                  // render: (row) => (<div>{row.ipsurgeonfeeperc && row.ipsurgeonfeeperc !== "0" ? row.ipsurgeonfeeperc + '%' : "---"}</div>)
                  render: (row) => (
                    <div>
                      <TextField
                        id="ratio"
                        // label="ipsurgeonfeeperc"
                        // variant="outlined"
                        multiline
                        variant="filled"
                        fullWidth
                        name="ratio"
                        value={row.ratio ? row.ratio : "---"}
                        onChange={(event) => handleInputChangeIp(row, event)}
                        onBlur={(event) => ipCalculation(row, event)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                      />
                    </div>
                  ),
                },
                {
                  title: "Net Bill Amount",
                  field: "ipTotalNetBillAmount",
                  render: (row) => (<div>
                    {/* {row.ipTotalNetBillAmount && row.ipTotalNetBillAmount !== "0" ? "Rs. " + numberWithCommas(row.ipTotalNetBillAmount) : "---"} */}
                    {
                      ipdiscount === "No"
                        ? 0
                        : ipNetBillAmountTot
                          ? numberWithCommas(ipNetBillAmountTot)
                          : numberWithCommas(row.ipTotalNetBillAmount)
                    }
                  </div>)
                },
                {
                  title: "Amount",
                  field: "ipTotalAmount",
                  // render: (row) => (<div>{row.ipTotalAmount ? "Rs. " + numberWithCommas(row.ipTotalAmount) : "---"}</div>)
                  render: (row) => (
                    <div>
                      <TextField
                        id="ipTotalAmount"
                        // label="ipTotalAmount"
                        // variant="outlined"
                        multiline
                        variant="filled"
                        fullWidth
                        name="ipTotalAmount"
                        value={row.ipTotalAmount ? row.ipTotalAmount : "---"}
                        onChange={(event) => handleInputChangeIp(row, event)}
                        // onBlur={() => opCalculation(row)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                      />
                    </div>
                  ),
                },
                {
                  title: "Count",
                  field: "count",
                  // render: (row) => (<div>{row.count ? row.count : "---"}</div>)
                  render: (row) => (
                    <div>
                      <TextField
                        id="count"
                        // label="count"
                        // variant="outlined"
                        multiline
                        variant="filled"
                        fullWidth
                        name="count"
                        value={row.count ? row.count : "---"}
                        onChange={(event) => handleInputChangeIp(row, event)}
                        onBlur={(event) => ipCalculation(row, event)}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                      />
                    </div>
                  ),
                },
                // { title: "Revenue", field: "revenue" },
                {
                  title: "Payout", field: "payout", render: (row) => (<div>
                    {ipPayoutTotal ? "Rs. " + numberWithCommas(ipPayoutTotal) : "Rs. " + numberWithCommas(row.payout)}
                    {/* {row.payout ? "Rs. " + numberWithCommas(row.payout) : "---"} */}
                  </div>)
                },
              ]}
              data={doctorIpDetail}
              options={{
                sorting: true,
                search: false,
                paging: false,
                rowStyle: {
                  fontSize: 12,
                },
                headerStyle: {
                  border: "1px solid rgba(224, 224, 224, 1)",
                  padding: "3px 10px",
                  fontSize: 12,
                },
                cellStyle: {
                  border: "1px solid rgba(224, 224, 224, 1)",
                  padding: "3px 10px",
                  minWidth: 150,
                  maxWidth: 150
                },
              }}
              title={<TableTitle text="In-Patient Service Estimation" />}
            />
            {doctorMgDetails && doctorMgDetails.length > 0 ?
              <MaterialTable
                columns={[
                  {
                    title: "Center Name",
                    field: "Center",
                    render: (row) => (<div>{row.Center ? row.Center : "---"}</div>)
                  },
                  {
                    title: "Minimum Guarentee /Month",
                    field: "minguranteepermonth",
                    render: (row) => (<div>{row.minguranteepermonth && row.minguranteepermonth !== "0" ? "Rs. " + numberWithCommas(row.minguranteepermonth) : "---"}</div>)
                  },
                  {
                    title: "MG Period In Years",
                    field: "mgperiod",
                    render: (row) => row.mgperiod && row.mgperiod !== "0" ? row.mgperiod + " years" : "---"
                  },
                  {
                    title: "Settlement Calculation Period",
                    field: "settlementperiod",
                    render: (row) => row.settlementperiod ? row.settlementperiod : "---"
                  },
                  {
                    title: "Settlement Payment Period",
                    field: "settlementpaymentperiod",
                    render: (row) => row.settlementpaymentperiod ? row.settlementpaymentperiod : "---"
                  },
                  {
                    title: "Settlement Term",
                    field: "settlementterm",
                    render: (row) => row.settlementterm && row.settlementterm !== "0" ? "Rs. " + numberWithCommas(row.settlementterm) : "---"
                  },
                ]}
                // data={doctorDetail.doctorIPParameter}
                data={doctorMgDetails}
                options={{
                  // filtering: true,
                  sorting: true,
                  paging: false,
                  search: false,
                  rowStyle: {
                    fontSize: 12,
                  },
                  headerStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px",
                    fontSize: 12,
                  },
                  cellStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px"
                  },
                }}
                title={<TableTitle text="Minimum Guarentee (MG) Estimation" />}
              />
              : ""}
            {TotalPayOutValue && TotalPayOutValue.length > 0 ?
              <MaterialTable
                columns={[
                  {
                    title: "Total Estimated Revenue",
                    field: "TotalRevenue",
                    render: (row) => (<div>{row.TotalRevenue && row.TotalRevenue !== "0" ? "Rs. " + numberWithCommas(row.TotalRevenue) : "---"}</div>)
                  },
                  {
                    title: "Total OP",
                    field: "TotalOP",
                    render: (row) => (<div>{row.TotalOP && row.TotalOP !== "0" ? "Rs. " + numberWithCommas(row.TotalOP) : "---"}</div>)
                  },
                  {
                    title: "Total OP %",
                    field: "TotalOP",
                    render: (row) => (<div>{row.TotalOP && row.TotalOP !== "0" ? ((row.TotalOP / row.Payout) * 100).toFixed(2) + '%' : "---"}</div>)
                  },
                  {
                    title: "Total IP",
                    field: "TotalIP",
                    render: (row) => (<div>{row.TotalIP && row.TotalIP !== "0" ? "Rs. " + numberWithCommas(row.TotalIP) : "---"}</div>)
                  },
                  {
                    title: "Total IP %",
                    field: "TotalIP",
                    render: (row) => (<div>{row.TotalIP && row.TotalIP !== "0" ? ((row.TotalIP / row.Payout) * 100).toFixed(2) + '%' : "---"}</div>)
                  },
                  {
                    title: doctorFixedDetails && doctorFixedDetails.length > 0 ? "Fixed Amount /Month" : "Minimum Guarentee /Month",
                    field: "TotalMGorFixedValue",
                    render: (row) => row.TotalMGorFixedValue && row.TotalMGorFixedValue !== "0" ? "Rs. " + numberWithCommas(((row.TotalMGorFixedValue).toString()).replaceAll(',', '')) : "---"
                  },
                  {
                    title: "Payout",
                    field: "Payout",
                    render: (row) => row.Payout && row.Payout !== "0" ? "Rs. " + numberWithCommas(row.Payout) : "---"
                  },
                  {
                    title: "Payout %",
                    field: "Payout",
                    render: (row) => row.Payout && row.Payout !== "0" ? ((row.Payout / row.TotalRevenue) * 100).toFixed(2) + '%' : "---"
                  }
                ]}
                // data={doctorDetail.doctorIPParameter}
                data={TotalPayOutValue}
                options={{
                  // filtering: true,
                  sorting: true,
                  paging: false,
                  search: false,
                  rowStyle: {
                    fontSize: 12,
                  },
                  headerStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px",
                    fontSize: 12,
                  },
                  cellStyle: {
                    border: "1px solid rgba(224, 224, 224, 1)",
                    padding: "3px 10px"
                  },
                }}
                title={<TableTitle text="Total Estimated Payout" />}
              />
              : ""}
          </Paper>
          <Paper className={tables.root} style={{ border: "1px solid" }}>
            <h5 className="p-2" style={{ fontWeight: "bold", borderBottom: "1px solid" }}>Commercials Summary</h5>

            <MaterialTable
              columns={[
                { title: "Center Name", field: "center", render: (row) => (<div>{row.center ? row.center : "---"}</div>) },
                { title: "Item Group", field: "itemgroup", render: (row) => (<div>{row.itemgroup ? row.itemgroup : "---"}</div>) },
                { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
                { title: "Commercial Term", field: "commercialterm", render: (row) => (<div>{row.commercialterm ? row.commercialterm : "---"}</div>) },
              ]}
              data={doctorOpCommercial}
              options={{
                // filtering: true,
                sorting: true,
                paging: false,
                search: false,
                rowStyle: {
                  fontSize: 12,
                },
                headerStyle: {
                  border: "1px solid rgba(224, 224, 224, 1)",
                  padding: "3px 10px",
                  fontSize: 12,
                },
                cellStyle: {
                  border: "1px solid rgba(224, 224, 224, 1)",
                  padding: "3px 10px"
                },
              }}
              title={<TableTitle text="Out-Patient Commercial" />}
            />
            <MaterialTable
              columns={[
                { title: "Center Name", field: "Center", render: (row) => (<div>{row.Center ? row.Center : "---"}</div>) },
                { title: "Group", field: "group", render: (row) => (<div>{row.group ? row.group : "---"}</div>) },
                { title: "Item Group", field: "itemGroup", render: (row) => (<div>{row.itemGroup ? row.itemGroup : "---"}</div>) },
                { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
                { title: "Room Category", field: "roomCategory", render: (row) => (<div>{row.roomCategory ? row.roomCategory : "---"}</div>) },
                { title: "MOP", field: "ipmodeofpayment", render: (row) => (<div>{row.ipmodeofpayment ? row.ipmodeofpayment : "---"}</div>) },
                { title: "Commercial Term", field: "commercialTerm", render: (row) => (<div>{row.commercialTerm ? row.commercialTerm : "---"}</div>) },
              ]}
              // data={doctorDetail.doctorIPParameter}
              data={doctorIpDetail}
              options={{
                // filtering: true,
                sorting: true,
                paging: false,
                search: false,
                rowStyle: {
                  fontSize: 12,
                },
                headerStyle: {
                  border: "1px solid rgba(224, 224, 224, 1)",
                  padding: "3px 10px",
                  fontSize: 12,
                },
                cellStyle: {
                  border: "1px solid rgba(224, 224, 224, 1)",
                  padding: "3px 10px"
                },
              }}
              title={<TableTitle text="In-Patient Commercials" />}
            />
            {doctorMgDetails && doctorMgDetails.length > 0 ?
              doctorMgDetails.map((item) =>
                <MaterialTable
                  columns={[
                    { title: "Minimum Guarantee Payment: The Company agrees to pay the Consultant an amount of Rs. " + numberWithCommas(item.minguranteepermonth) + " (Rs. " + numberToEnglish((item.minguranteepermonth).replaceAll(',', '')) + ") per month (“Minimum Guarantee Amount”) or the “Calculated Earnings” whichever is higher subject to statutory deductions. ", field: "mgCommercial" },
                  ]}
                  // data={doctorDetail.doctorIPParameter}
                  data={
                    [
                      {
                        mgCommercial: 'The Minimum Guarantee Amount will be paid for ' + item.mgperiod + ' months'
                      },
                      {
                        mgCommercial: 'The earnings shall be calculated on ' + item.settlementperiod + ' basis and difference amount (Calculated Earnings minus Minimum Guarantee), if any, shall be paid at the end of ' + item.settlementpaymentperiod + '.'
                      },
                    ]
                  }
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontSize: 12,
                      fontWeight: 400
                    },
                    rowStyle: {
                      fontSize: 12,
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text={"Minimum Guarentee (MG) Commercial for " + item.Center} />}
                />
              )
              : ''}
            {doctorFixedDetails && doctorFixedDetails.length > 0 ?
              doctorFixedDetails.map((item) =>
                <MaterialTable
                  columns={[
                    { title: "Monthly Fixed Fee: The Company will pay an all-inclusive amount of Rs. " + numberWithCommas((item.professionalfeepermonth).replaceAll(',', '')) + " (Rs. " + numberToEnglish((item.professionalfeepermonth).replaceAll(',', '')) + ") per month. ", field: "fixedCommercial", width: "100%" },
                  ]}
                  // data={doctorDetail.doctorIPParameter}
                  data={
                    [
                      {
                        fixedCommercial: item.additionalfeevalue != '0' ? "Additional Fee for " + item.additionalfeename + ": The Company will pay an additional amount of Rs. " + numberWithCommas(((item.additionalfeevalue).toString()).replaceAll(',', '')) + " (Rs. " + numberToEnglish(((item.additionalfeevalue).toString()).replaceAll(',', '')) + ") per month. " : ""
                      },
                    ]
                  }
                  options={{
                    // filtering: true,
                    sorting: false,
                    paging: false,
                    search: false,
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontSize: 12,
                      fontWeight: 400
                    },
                    rowStyle: {
                      fontSize: 12,
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text={"Full Time Consultant Commercial for " + item.Center} />}
                />
              )
              : ''}
          </Paper>
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          {/* <Button
            autoFocus
            onClick={updateRecord}
            color="primary"
            variant="contained"
            size="small"
          >
            Update Data
          </Button> */}
          <Button
            autoFocus
            onClick={handlerCloseOp}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerCloseIp}
        aria-labelledby="customized-dialog-title"
        open={openIp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseIp}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Revenue", field: "revenue", render: (row) => row.revenue ? "Rs. " + numberWithCommas(parseFloat(row.revenue).toFixed()) : "" },
                { title: "Pay Out", field: "payout", render: (row) => row.payout ? "Rs. " + numberWithCommas(parseFloat(row.payout).toFixed()) : "" },
                // { title: "Net Amount", field: "ipTotalAmount" },
              ]}
              data={doctorDetail.doctorIPParameter}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
              }}
              title="Ip Details"
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerCloseIp}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClickcloseCommercials}
        aria-labelledby="customized-dialog-title"
        open={openCommercials}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group/Procedures", field: "itemGroup" },
                { title: "Commercial Term", field: "CommercialTerm" },
              ]}
              data={doctorDetail.doctorIPParameter}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
              }}
              title="Commercial Details"
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerClickcloseCommercials}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* APPROVE MODAL */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenApprovemodal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openApprove}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenApprovemodal(false);
          }}
          style={{ textAlign: "center" }}
        >Approve</DialogTitle>
        <DialogContent dividers>
          <label>
            Comments{" "}
          </label>
          <textarea
            placeholder="Comments"
            type="text"
            onChange={handleComments}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={(e) => handleApprove(e)}
            color="secondary"
            variant="contained"
            size="small"
          >

            Approve
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setOpenApprovemodal(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* REJECT MODAL */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenRejectmodal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openReject}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenRejectmodal(false);
          }}
          style={{ textAlign: "center" }}
        >Review</DialogTitle>
        <DialogContent dividers>
          <label>
            Comments{" "}
          </label>
          <textarea
            placeholder="Comments"
            type="text"
            onChange={handleComments}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleReject}
            color="secondary"
            variant="contained"
            size="small"
          >
            Review
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setOpenRejectmodal(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        className={backClasses.backdrop}
        open={backDropOpen}
      ><img src={c9gif} alt="loading..." />
      </Backdrop>

    </>
  );
}
