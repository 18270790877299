import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo2 from "../images/Logos-04.png";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { listusers } from "../actions/userActions";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

function PTSidebar({ userType }) {
  const [dash, setDash] = useState("bh-dashboard");
  const [item4, setItem4] = useState("visibleDiv");
  const [item5, setItem5] = useState("visibleDiv");
  const [item6, setItem6] = useState("visibleDiv");
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  // bringing things from the reducer
  const { loading, error, user } = userLogin;
  function CheckUser() {
    if (userType === "BH") {
      setDash("bh-dashboard");
    } else if (userType === "HR") {
      setDash("hr-dashboard");
      setItem5("hiddenDiv");
    } else if (userType === "COO") {
      setDash("rd-dashboard");
      setItem4("hiddenDiv");
      setItem6("hiddenDiv");
    }
    useEffect(() => {
      dispatch(listusers());
    }, [dispatch]);
  }
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <div class="sidebar main-sidebar main-sidebar-sticky side-menu ps ps--active-y">
      <div className="main-sidebar-header header-img">
        <img src={logo2} className="img-responsive" alt="logo" />
      </div>
      <div className="main-sidebar-body">
        <ul className="nav">
        <div style={{ display: "block", width: "100%", marginTop: "-1.1rem", marginLeft: "2px"}}>
            <Accordion 
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent"}}
             >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent", position: "sticky", top: '0' }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white"}}
                  >
                    <span className="shape1"></span>
                    <span className="shape2"></span>
                    <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                    <span className="sidemenu-label" style={{ fontSize: "16px", marginLeft: "10px" }} >{'   '}Payouts</span>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link to="/payout-dasboard" id="nav-link" className="nav-link"
                        style={{ marginTop: "-1rem" }}>
                        {/* <span className="shape1"></span>
                <span className="shape2"></span> */}
                        <i className="fas fa-file-contract sidemenu-icon mr-0"></i>
                        {/* <span
                  // className="sidemenu-label"
                  // style={{ fontSize: "15px", marginTop: "-19px" }}
                  className="new-nav-link"
                  style={{
                    backgroundColor: "transparent",
                    height: ".8rem",
                    border: "none",
                  }}
                >
                  Dashboard
                </span> */}
                        <span className="sidemenu-label" style={{ fontSize: "14px" }}>
                          Payout-Dashboard
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/UploadIpFiles"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-0"></i>
                        <span className="sidemenu-label" style={{ fontSize: "14px" }}>
                          Upload Ip Files
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/UploadCommercials"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-0"></i>
                        <span className="sidemenu-label" style={{ fontSize: "14px" }}>
                          Upload Commercials
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/ip-los"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-0"></i>
                        <span className="sidemenu-label" style={{ fontSize: "14px" }}>
                          IP LOS
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/manage-doctor-commercial"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-0"></i>
                        <span className="sidemenu-label" style={{ fontSize: "14px" }}>
                          Doctor Commercial
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        // to="/manage-doctor-commercial"
                        to="/ManageCommercials"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-0"></i>
                        <span className="sidemenu-label" style={{ fontSize: "13px" }}>
                          Op Commercial Master
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/Manage-doctor"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-0"></i>
                        <span className="sidemenu-label" style={{ fontSize: "14px" }}>
                          Doctor master
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/manage-payable"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-0"></i>
                        <span className="sidemenu-label" style={{ fontSize: "14px" }}>
                          Payable Item
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/manage-ipitem-master"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-0"></i>
                        <span className="sidemenu-label" style={{ fontSize: "14px" }}>
                          Ip Packages
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/manage-discard-payout"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-0"></i>
                        <span className="sidemenu-label" style={{ fontSize: "14px" }}>
                          Discard Payout
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/GenerateOpPayout"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-0"></i>
                        <span className="sidemenu-label" style={{ fontSize: "14px" }}>
                          Payout Report
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </ul>

        <div
          style={{
            display: "block",
            width: "100%",
            marginTop: "-.5rem",
            marginLeft: "13px",
          }}
        ></div>
      </div>
    </div>
  );
}

export default PTSidebar;
