import React, { PureComponent, useContext } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { AppContext } from '../actions/contextApi';
var createReactClass = require('create-react-class');
const data = [
  {
      "MonthName": "Mar-22",
      "C9NOIDA": 130000000,
      "SEC 14": 97927344,
      "NIT": 69442337,
      "PCK": 99423776,
      "CDG": 15155390,
      "C9-MFG": 18077885,
      "PNB": 14856990,
      "EDH": 70856852
  },
  {
      "MonthName": "Apr-22",
      "C9NOIDA": 10904834,
      "SEC 14": 5238510,
      "NIT": 2105225,
      "PCK": 2889489,
      "CDG": 6881455,
      "C9-MFG": 20324027,
      "PNB": 1990118,
      "EDH": 3869923
  },
  {
      "MonthName": "May-22",
      "C9NOIDA": 73533170,
      "SEC 14": 22571642,
      "NIT": 64132338,
      "PCK": 77317907,
      "CDG": 72307998,
      "C9-MFG": 35239602,
      "PNB": 95540556,
      "EDH": 32305651
  },
  {
      "MonthName": "Jun-22",
      "C9NOIDA": 11340864,
      "SEC 14": 6963172,
      "NIT": 61604788,
      "PCK": 62584130,
      "CDG": 90963369,
      "C9-MFG": 49401886,
      "PNB": 20321571,
      "EDH": 44157563
  },
  {
      "MonthName": "Jul-22",
      "C9NOIDA": 37572146,
      "SEC 14": 77419164,
      "NIT": 23186988,
      "PCK": 6045199,
      "CDG": 85038738,
      "C9-MFG": 88335699,
      "PNB": 7316453,
      "EDH": 17164219
  },
  {
      "MonthName": "Aug-22",
      "C9NOIDA": 44546661,
      "SEC 14": 36751237,
      "NIT": 27395432,
      "PCK": 73122633,
      "CDG": 21591649,
      "C9-MFG": 28778249,
      "PNB": 18568220,
      "EDH": 85424554
  }
]

const colors = ['green', '#FFC658', '#FF847C', '#DE3959', '#E84A5F', '#ffc658', '#FECEAB', '#99B898', '#f77bef', '#003366', '#88eedd', '#a474dc', '#93831d', '#9ab762', '#391a21', '#20b2aa', '#95989d', '#ace1af', '#172e1e', '#b00e18', '#40ff00', '#ff8000', '#ff00bf']

const LineChartPayout = (props) => {
  const {graphFilter,setGraphFilter} = useContext(AppContext)
  const {tableHeaderName, setTableHeaderName} = useContext(AppContext);
  const {tableShow, setTableShow} = useContext(AppContext);
  const ref = useContext(AppContext);
  const numbersManager = (number) => {
    let nf = new Intl.NumberFormat('en-IN');
    if (!isNaN(number)) {
      return nf.format(Math.abs(number / 100000).toFixed(0))
    }
    else{
      return nf.format(number.toFixed(0))
    }
  }
  const CustomizedLabel = createReactClass({
    render: function () {
      const { x, y, fill, value } = this.props;
      console.log(value, "HDJDJDJDJJDJDJDJ")
      return (<text
        x={x}
        y={y}
        dy={-4}
        fontSize='10px'
        fontWeight='bold'
        fill={fill}
        textAnchor="start">{numbersManager(value)}</text>)
    }
  })
  const CustomTooltip = ({ active, payload, label }) => {
    console.log(payload,"arr--------")
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{maxHeight: '250px', overflow:'scroll', pointerEvents: 'auto'}}>
          <p style={{color:'Black', fontSize: "12px", fontWeight: "bolder"}}>{label}:</p>
          {Object.keys(payload[0].payload).filter(elem => elem !== 'MonthName').map(item =>
            <p className="label" style={{ display:'flex', allignItems:'left', fontSize: "12px"}}>{`${item} : ${numbersManager(payload[0].payload[item])}`}</p>
          )}
        </div>
      );
    }
    return null;
  };
  const graphOnClick = (e) => {
    ref.current?.scrollIntoView({behavior: 'smooth'})
    setGraphFilter(e.activeLabel)
    setTableHeaderName('Payout Table')
    setTableShow(true)
    console.log('abc----------->>>>>>>>123',e.activeLabel, tableShow);
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={props.RevenueData}
        // data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        cursor='pointer'
        onClick={(e) => graphOnClick(e)}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="MonthName" fontSize="9px"/>
        <YAxis fontSize="9px" tickFormatter={(label) => `${(label/100000).toLocaleString('en-IN')}`}/>
        <Tooltip content={<CustomTooltip/>}/>
        <Legend wrapperStyle={{fontSize: "11px"}} iconSize={8} iconType='circle' />
        {props.CenterList.map((elem, Index) =>
          <Line type="monotone" fontSize="1px" dataKey={elem['LegendName']}  stroke={elem.ColorCode? elem.ColorCode: 'green'} dot={{ stroke: elem.ColorCode? elem.ColorCode: 'green', strokeWidth: 2, r: 4 ,strokeDasharray:''}} strokeWidth={props.strokeWidth ? (props.strokeWidth)+3 : 3} activeDot={{ r: 8 }}/>
        )}
      </LineChart>
    </ResponsiveContainer>
  );
}
export default LineChartPayout