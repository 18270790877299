import React, {useState} from 'react'
import { Paper } from '@material-ui/core'
import MaterialTable from 'material-table'
import { Visibility } from '@mui/icons-material'
import { APIURL } from '../../../CommomMethods/Fetch'
import axios from 'axios';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";


function ListDoctor({ rows, editRow, viewRow, downloadExcel, viewDoc, downloadAgreement, filterCenters, filterDatas, editLTID }) {

   
    return (
        <>
            <Paper>
                <MaterialTable
                    columns={[
                        { title: "Requisition", field: "requisition_id", render: (row) => row.requisition_id ? ("REQ-" + row.requisition_id) : "", customFilterAndSearch: (term, rowData) => ("REQ-" + rowData.requisition_id).includes(term) },
                        { title: "Name", field: "name" },
                        { title: "LT Email ID", field: "lt_email_id", cellStyle:{cursor:"pointer", color:"#810e36"}, render: (row) => <div onClick={() => editLTID(row)}>{row.lt_email_id?row.lt_email_id:"---"}</div>},
                        { title: "Personal Email Id", field: "AdditionalEmailId", render: (row) => row.AdditionalEmailId ? row.AdditionalEmailId : row.email, customFilterAndSearch: (term, rowData) => rowData.AdditionalEmailId ? rowData.AdditionalEmailId.includes(term) : rowData.email ? rowData.email.includes(term) : "" },
                        // { title: "Official Email-Id", field: "email", render: (row) => row.AdditionalEmailId ? row.email : "---", customFilterAndSearch: (term, rowData) => rowData.AdditionalEmailId ? rowData.email.includes(term) : ("---").includes(term) },
                        {
                            title: "Center", field: "centerName",
                            lookup: filterCenters
                        },
                        {
                            title: "Association Type", field: "category",
                            lookup: {
                                "Minimum Guarentee(MG)": "Minimum Guarentee(MG)",
                                "Visiting Consultant": "Visiting Consultant",
                                "Full Time Consultant": "Full Time Consultant",
                                "Retainer": "Retainer",
                            }
                        },
                        {
                            title: "Designation", field: "designationLabel",
                            lookup: filterDatas
                        },
                        { title: "Mobile", field: "mobile" },
                        {
                            title: "User Status", field: "userStatus",
                            lookup: {
                                "Active": "Active",
                                "In Active": "In Active",
                                "Pending": "Pending",
                            }
                        },

                        {
                            title: "Onboarding Status",
                            field: "currentStatus",
                            lookup: {
                                "Initiated": "Initiated",
                                "Saved as Draft": "Saved as Draft",
                                "To be review the commercials": "To be review the commercials",
                                "Send back to edit Doctor's Personal Information": "Send back to edit Doctor's Personal Information",
                                "Doctor info to be filled": "Doctor info to be filled",
                                "Documents to be uploaded": "Documents to be uploaded",
                                "To be verified by Corporate HR": "To be verified by Corporate HR",
                                "To be verified by COO": "To be verified by COO",
                                "Sent back to review the commercials and re-submit": "Sent back to review the commercials and re-submit",
                                "To be verified by BH": "To be verified by BH",
                                "Send back to resubmit doctors document": "Send back to resubmit doctors document",
                                "Renewal Initiated": "Renewal Initiated",
                                "Agreement to be signed by Doctor": "Agreement to be signed by Doctor",
                                "Agreement to be signed by CEO": "Agreement to be signed by CEO",
                                "LT Id need to link": "LT Id need to link",
                                "Manual Signed Agreement to be upload": "Manual Signed Agreement to be upload",
                                "Onboarded": "Onboarded",
                                "Sent for Discontinue": "Sent for Discontinue",
                                "Deactivated": "Deactivated",
                                "PENDING": "PENDING",
                            }
                        },
                    ]}
                    actions={[
                        {
                            icon: "edit",
                            tooltip: "Edit",
                            iconProps: { style: { fontSize: "24px", color: "#810e36" } },
                            onClick: (event, row) => editRow(row),
                        },
                        {
                            icon: 'visibility',
                            tooltip: "View Agreement",
                            iconProps: { style: { fontSize: "24px", color: "#810e36" } },
                            onClick: (event, row) => viewRow(row),
                        },
                        rowData =>
                        ({
                            icon: 'list',
                            tooltip: "Download Agreement",
                            // iconProps: { style: { fontSize: "24px", color: rowData.OnextAction !== 'COMPLETED' ? "#d5d1d1" : "#5f5fe7" } },
                            iconProps: { style: { fontSize: "24px", color: "#810e36" } },
                            onClick: (event, rowData) => downloadAgreement(rowData),
                            // disabled: rowData.OnextAction !== 'COMPLETED' && !rowData.digio_doc_id,
                        }),
                        {
                            icon: 'description',
                            tooltip: "View Documents",
                            iconProps: { style: { fontSize: "24px", color: "#810e36" } },
                            onClick: (event, row) => viewDoc(row),
                        },
                        {
                            icon: () => <button type="button">Export</button>,// you can pass icon too
                            tooltip: "Export to Excel",
                            onClick: () => downloadExcel(),
                            isFreeAction: true
                        },
                    ]}
                    data={rows.filter((z) => z.users_role_id === 4)}
                    // data={query =>
                    //     new Promise((resolve, reject) => {
                    //         let url = APIURL() + "admin/doctors?"
                    //         url += `per_page=${query.pageSize}`
                    //         url += `&page=${(query.page + 1)}`
                    //         url += `&search=${query.search}`
                    //         if (query.filters.length) {
                    //             query.filters.forEach(item => {
                    //                 url += `&${item.column.field}=${item.value}`
                    //             })
                    //         }
                    //         if (query.orderBy) {
                    //             url += `&orderByField=${query.orderBy.field}`
                    //             url += `&orderBySort=${query.orderDirection}`
                    //         }
                    //         // console.log(query, tableRef, "activitylogs")
                    //         axios.get(url)
                    //             .then(response => response.data)
                    //             .then(result => {
                    //                 resolve({
                    //                     data: result.data,
                    //                     page: result.page - 1,
                    //                     totalCount: result.total,
                    //                 })
                    //             })
                    //     })
                    // }
                    options={{
                        filtering: true,
                        sorting: true,
                        // exportButton: true,
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 50, 100, 200, 500],
                        search: true,
                    }}
                    icons={{
                        Filter: "filter",
                    }}
                    title="Doctors"
                />
            </Paper>
        </>
    )
}

export default ListDoctor