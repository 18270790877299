import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import {
  Paper,
  Container,
  CssBaseline,
  DialogTitle,
  Button,
  TextField,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//ui component
import PTSidebar from "../../Components/PTSidebar";
import Navbar from "../../Components/Navbar";

export default function IpBillItemDetail() {
  let location = useHistory();
  console.log(location, "location of ipbillitemdetail");
  const [ipItemDetail, setIpItemDetail] = useState([]);
  const [changeRowValue, setChangeRowValue] = useState([]);
  const [openr, setOpenr] = React.useState(false);

  useEffect(() => {
    setIpItemDetail(location.location.state.detail);
  }, []);

  const handlerClose = () => {
    setOpenr(false);
  };
  return (
    <>
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
        <PTSidebar />
        <Paper>
          <MaterialTable
            style={{ whiteSpace: "nowrap" }}
            columns={[
              { title: "MPI", field: "MPI" },
              { title: "IP No", field: "IP No" },

              { title: "Bill No", field: "Bill No" },
              { title: "Bill Date", field: "Bill Date" },
              { title: "Bill Time", field: "Bill Time" },
              { title: "PatientName", field: "Patient Name" },
              { title: "Admitting Physician", field: "Admitting Physician" },
              { title: "Specialisation", field: "Specialisation" },
              { title: "AdmitGroup", field: "Admit Group" },
              { title: "Item Group", field: "Item Group" },
              { title: "Item Name", field: "Item Name" },
              { title: "Item Added Date", field: "Item Added Date" },
              { title: "Item Added Time", field: "Item Added Time" },
              { title: "Item Discount", field: "Item Discount" },
              { title: "Oraganisation Name", field: "Oraganisation Name" },

              { title: "Bed Category", field: "Bed Category" },
              { title: "Gross", field: "Gross" },
              { title: "TPA", field: "TPA Name" },
              { title: "Tax", field: "Tax" },
              { title: "Insurance Name", field: "Insurance Name" },
              { title: "Quantity", field: "Qty" },
              { title: "User", field: "User" },
            ]}
            data={ipItemDetail}
            options={{
              selection: true,
              sorting: true,
              exportButton: true,
              pageSize: 5,
              pageSizeOptions: [5, 10, 50, 100, 200, 500],
              search: true,
            }}
            actions={[
              {
                tooltip: "Edit Records",
                icon: "edit",
                iconProps: { style: { color: "#810e36" } },
                onClick: (evt, data) => setOpenr(true),
              },
            ]}
            onSelectionChange={(rows) => {
              //   setRowSelection(true);
              setChangeRowValue(rows);
            }}
            title="ip-billing item detail"
          />
        </Paper>
      </Container>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Edit Doctor Details
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Ratio</b>
          </label>
          <TextField
            id="Payout"
            label="Ratio"
            variant="outlined"
            fullWidth
            name="ratio"
            // value={rowDetail.ratio}
            // onChange={handleInputChange}
            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />

          <label>
            <b> Percentage</b>
          </label>
          <TextField
            id="Payout"
            label="Percentage"
            variant="outlined"
            fullWidth
            name="percentage"
            // value={rowDetail.percentage}
            // onChange={handleInputChange}
            //   onChange={(e) => setPayout(e.target.value)}

            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            // onClick={handlePayoutEdit}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
