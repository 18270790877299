import React, { useEffect, useState } from "react";
// @material-ui/core components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
// import { SnackbarContent } from "@material-ui/core";
import SnackbarContent from "@material-ui/core/SnackbarContent";
// axios
import axios from "axios";
//bootstrap

//ui components
import AddAgreementTemplate from "./forms/AddAgreementTemplate";
import EditAgreementTemplate from "./forms/EditAgreementTemplate";
import AgreementTemplateList from "./tables/AgreementTemplateList";
import Navbar from "../../Components/Navbar";
import AppSidebar from "../../Components/AppSidebar";
import { APIURL } from "../../CommomMethods/Fetch";

const styleSheet = makeStyles((theme) => ({
  iconButtonStyle: {
    float: "right",
  },
  marginforMaster: {
    marginTop: "30px",
  },
}));

export default function ManageAgreementTemplate() {
  const classes = styleSheet();
  //useStae for Snackbar
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    color: "",
    icon: "",
  });
  //variables for snack bar
  const { vertical, horizontal, open } = state;
  //function for snackbar
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const initialFormState = {
    id: null,
    procedurename: "",
    platinum: "",
    payout: "",
    fromdate: "",
    todate: "",
  };
  const [department, setDepartment] = useState(initialFormState);
  const [preview, setPreview] = useState(true);
  const [editing, setEditing] = useState(false);
  const [rows, setRows] = useState([]);
  const [defaultBody, setDefaultBody] = useState('');
  const [data, setData] = useState(false);

  // get all records from department
  useEffect(() => {
    axios
      .get(APIURL() + "template/getAllTemplate")
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows.data, "rows.data")
        setRows(rows.data.filter(item => item.templateName !== 'Default'));
        setDefaultBody(rows.data.find(item => item.templateName == 'Default')['templateData'])
      });
  }, [data]);

  //populate single record in the Edit component
  const editRow = (department) => {
    setPreview(false);
    setEditing(true);
    setDepartment({
      "id": department.id,
      "templateName": department.templateName,
      "templateData": department.templateData,
      "status": department.status,
      "createdAt": department.createdAt,
      "updatedAt": department.updatedAt,
    });
  };

  //update department
  const updateAgreementTemplate = (department) => {
    setData(false);
    axios.post(APIURL() + "template/updateTemplate", department).then((res) => {
      if (res.status === 201) {
        setEditing(false);
        setPreview(true);
        setData(true);
        setState({
          open: true,
          vertical: "top",
          horizontal: "center",
          message: res.data.message,
          color: "success",
        });
      }
    });
  };

  //add designation
  const addAgreementTemplate = (department) => {
    console.log(department, "dept-mnt");
    setData(false);
    axios
      .post(APIURL() + "template/createTemplate", department)
      .then((res) => {
        // console.log(res, "res-ponse");
        setPreview(true);
        setData(true);
        if (res.status === 201) {
          setState({
            open: true,
            vertical: "top",
            horizontal: "center",
            message: res.data.message,
            color: "success",
          });
        }
      });
  };

  //deletion of designation
  const deleteDepartment = (row) => {
    setData(false);
    var k = window.confirm("Are you sure want to delete this record ?");
    if (k) {
      axios.post(APIURL() + "template/deleteTemplate",row).then((res) => {
        setData(true);
        if (res.status === 201) {
          setState({
            open: true,
            vertical: "top",
            horizontal: "center",
            message: res.data.message,
            color: "danger",
          });
        }
      });
    }
  };

  return (
    <>
      <AppSidebar />
      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                className={classes.marginforMaster}
              >
                <Typography variant="h6">Manage Agreement-Template</Typography>
                {preview ? (
                  <Typography variant="body2">
                    Masters / Manage Agreement-Template / Agreement-Template
                    List
                  </Typography>
                ) : editing ? (
                  <Typography variant="body2">
                    Masters / Manage Agreement-Template / Edit
                    Agreement-Template
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    Masters / Manage Agreement-Template / Add Agreement-Template
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.marginforMaster}
              >
                <div className={classes.iconButtonStyle}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setPreview(true);
                      setEditing(false);
                    }}
                  >
                    <ListIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setPreview(false);
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {preview ? (
                  <AgreementTemplateList
                    rows={rows}
                    editRow={editRow}
                    deleteDepartment={deleteDepartment}
                  />
                ) : editing ? (
                  <EditAgreementTemplate
                    editing={editing}
                    setEditing={setEditing}
                    setPreview={setPreview}
                    currentDepartment={department}
                    updateAgreementTemplate={updateAgreementTemplate}
                  />
                ) : (
                  <AddAgreementTemplate
                    addAgreementTemplate={addAgreementTemplate}
                    defaultBody={defaultBody}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={5000}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <SnackbarContent color={state.color} message={state.message} />
      </Snackbar>
    </>
  );
}
