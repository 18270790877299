import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
//ui components
import PTSidebar from "../../Components/PTSidebar";
import Navbar from "../../Components/Navbar";
import ConfirmDialog from "../../Components/ConfirmDialog";

//mui components
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Button, Typography, Paper } from "@material-ui/core";
import { MenuProps, useStylesmulti } from "../CommonFIle/util";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MaterialTable from "material-table";
import Skeleton from "@material-ui/lab/Skeleton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import VisibilityIcon from "@material-ui/icons/Visibility";
//import component
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { onboardById } from "../../actions/onboardActions";
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import { payoutState } from "../../CommomMethods/States";
import { payoutCalculation } from "../../Pages/Calculations/payoutCalc";
import { dateFormatter } from "../../actions/createPositionActions";
import Sidebar from "../../Components/Sidebar";
import SidebarRD from "../../Components/Rd-sidebar";
import moment from 'moment';
import { toast } from "react-toastify";
import XLSX from 'xlsx';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const styleSheet = makeStyles((theme) => ({
  iconButtonStyle: {
    float: "right",
  },
  marginforMaster: {
    marginTop: "30px",
  },
}));

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function IpPayoutProcess() {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const tableRef = useRef();
  const location = useLocation();
  let history = useHistory();
  console.log(location, "location from anothere state");
  const classes = styleSheet();
  const dispatch = useDispatch();
  const [backDropOpen, setBackDropOpen] = useState(false);
  const backClasses = backDropStyle();
  const [rows, setRows] = useState([]);
  const [name, setName] = useState([]);
  const [centerName, setCenterName] = useState([]);
  const [center, setCenter] = useState("Select Center");
  const [month, setMonth] = useState("Select Month");
  const [year, setYear] = useState("Select Year");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [opCenter, setOpCenter] = useState("Select Center");
  const [opMonth, setOpMonth] = useState("Select Month");
  const [opYear, setOpYear] = useState("Select Year");
  const [loading, setLoading] = useState(true);
  const [changeRowValue, setChangeRowValue] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const [openr, setOpenr] = React.useState(false);
  const [editItemModal, setEditItemModal] = React.useState(false);
  const [reload, setReload] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [opTotPayout, setOpTotPayout] = useState([]);
  const [initCalOP, setInitCalOP] = useState([]);
  const [data, setData] = useState("Select the value");
  const [splitDialog, setSplitDialog] = useState(false);
  const [spitRecord, setSplitRecord] = useState({});
  const [splitNewRec, setSplitNewRec] = useState([]);
  const [oboard, setOboard] = useState([]);
  const [oboardVal, setOboardVal] = useState([]);
  const [OPoboardVal, setOPOboardVal] = useState([]);
  const [dataVal, setDataVal] = useState([]);
  const [ipbillItem, setIpbillItem] = useState([]);
  const [radiologyChk, setRadiologyChk] = useState(false);
  const [ipitemgrouplist, setIpitemgrouplist] = useState([]);
  const [ipitemnamegrouplist, setIpitemgroupnamelist] = useState([]);
  const [options, setOptions] = useState([]);
  const [allData, setAllData] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [selected, setSelected] = useState([]);

  const [updateState, setUpdateState] = useState(0);

  const [allDataitemname, setAllDataitemname] = useState([]);
  const [selecteditemname, setSelecteditemname] = useState([]);
  const [payoutDataStatus, setPayoutDataStatus] = useState([]);
  const [OPPayoutDataStatus, setOPPayoutDataStatus] = useState([]);
  const [payoutReq, setPayoutReq] = useState([]);
  // const [ipitenamemgrouplist, setIpitemnamegrouplist] = useState([]);
  const [checkedRadiogy, setCheckedRadiogy] = React.useState(false);
  const [chkState, setChkState] = React.useState({
    checkedR: false,
    checkedP: false,
    checkedL: false,
    unseditem: false,
  });

  const [circularLoader, setCirculatLoader] = React.useState(false);

  // item group name start
  const isAllSelecteditemname =
    ipitemnamegrouplist.length > 0 &&
    selecteditemname.length === ipitemnamegrouplist.length;

  const handleChangeName = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      let centernames = [];
      ipitemnamegrouplist.forEach((element) => {
        centernames.push(element.ItemName);
      });

      setSelecteditemname(
        selecteditemname.length === ipitemnamegrouplist.length
          ? []
          : centernames
      );
      return;
    }
    setSelecteditemname(value);
  };
  // item group name end

  const handleChange = (event) => {
    setChkState({ ...chkState, [event.target.name]: event.target.checked });
  };

  const handleChangeRadiology = (event) => {
    setCheckedRadiogy(event.target.checked);
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  const getPayoutStatus = async (patientType) => {
    var data = {
      center: patientType == 'IP' ? center : opCenter,
      startDate: startDate,
      endDate: endDate,
      patientType: patientType,
    }
    await axios.post(APIURL() + 'billings/payout-status', data)
      .then(async (result) => {
        if (patientType == 'IP') {
          setPayoutDataStatus(result.data.data);
        } else {
          setPayoutDataStatus(result.data.data);
        }
        console.log(payoutDataStatus, OPPayoutDataStatus, result, 'payoutDataStatus')
      })
  }


  const LoadData = async (patientType = "IP", type = "normal") => {
    var data = {
      Center: center,
      // Year: year,
      // Month: month,
      startDate: moment(new Date(startDate)).format('YYYY-MM-DD'),
      endDate: moment(new Date(endDate)).format('YYYY-MM-DD'),
    };
    await axios
      .post(
        APIURL() + "billings/ipBillingDetailProcessDump",
        data
      )
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows, "@!!45");
      });

    await getRecord(patientType, type);
  }

  const getRecord = async (patientType = "IP", type = "normal") => {
    console.log("getting records");
    setLoading(false);
    if (user.user.activityAssigments.filter((elem) => elem.activityId === 9 || elem.activityId === 10).find(item => item.RoleId === user.user.roles)) {
      await axios
        .get(APIURL() + `billings/loadipbills/${center}/${year}/${month}/${type}/${startDate}/${endDate}`)
        .then((res) => res.data)
        .then((rows) => {
          setOboardVal(rows);
          console.log("\n\n\n\n\n----------@!!45-------\n");
          setLoading(true);
        }).catch((err) => {
          console.log(err, "\n\n\n\n\n----------@!!45-------\n");
          setLoading(true);
        });
    } else {
      if (patientType == 'IP') {
        await axios
          .get(APIURL() + `billings/get-payout-details/${center}/${year}/${month}/${patientType}`)
          .then((res) => res.data)
          .then((rows) => {
            setOboardVal(rows);
            console.log("\n\n\n\n\n----------@!!45-------\n");
            setLoading(true);
          });
      } else {
        await axios
          .get(APIURL() + `billings/get-payout-details/${opCenter}/${opYear}/${opMonth}/${patientType}`)
          .then((res) => res.data)
          .then((rows) => {
            setOPOboardVal(rows);
            console.log("\n\n\n\n\n----------@!!45-------\n");
            setLoading(true);
          });
      }
    }
    await getPayoutStatus(patientType)
  };

  console.log(user, "west login pt");
  const repalceFunction = (array) => {
    let convname = selecteditemname;
    array.forEach((z, i) => {
      if (z.includes("/")) {
        console.log(i);
        convname[i] = z.replace(/\//g, "0Z");
      }
    });
    return convname;
  };

  const calculateIPPayout = async (type) => {
    console.log("ip payout clicked");
    setBackDropOpen(true);
    // console.log(selecteditemname, "selecteditemname new");

    let totalItemnameconv = repalceFunction(selecteditemname);
    axios
      .post(
        APIURL() +
        `billings/calculateIPPayout/${center}/${year}/${month}/${selected}`,
        totalItemnameconv
      )
      .then((res) => res.data)
      .then((rows) => {
        console.log("loaded closed", rows);
        getRecord(type);
        setBackDropOpen(false);
      });
  };

  //calculateOtherDeduction
  const calculateOtherDecut = async (type) => {
    console.log("ip payout clicked");
    setBackDropOpen(true);
    // console.log(selecteditemname, "selecteditemname new");

    let totalItemnameconv = repalceFunction(selecteditemname);
    axios
      .post(
        APIURL() +
        `billings/calculateOtherDeduction/${center}/${year}/${month}/${selected}`,
        totalItemnameconv
      )
      .then((res) => res.data)
      .then((rows) => {
        console.log("loaded closed", rows);
        getRecord(type);
        setBackDropOpen(false);
      });
  };

  //calculateIPPayout("otherdeduction")

  const [rowDetail, setRowDetail] = useState({
    referalFee: "",
  });
  // const onboardId = useSelector((state) => state.onboardId);
  // const { oboard } = onboardId;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRowDetail({ ...rowDetail, [name]: value });
  };
  const handleReferalFeeEdit = () => {
    setReload(true);
    const obj = { rowDetail: rowDetail, changeRowValue: changeRowValue };
    console.log(obj, "referal fee");
    axios.put(APIURL() + "billings/updateReferalFee", obj).then((res) => {
      setReload(false);
      setOpenr(false);
      getRecord();
    });
  };

  useEffect(() => {
    axios
      .post(APIURL() + `billings/get-payout-requests`, { userId: user.user.id })
      .then((res) => res.data)
      .then((rows) => {
        setPayoutReq(rows.data);
      });
    // axios
    //   .get(
    //     APIURL() +
    //     `billings/getitenNameList/${center}/${year}/${month}/${selected}`
    //   )
    //   .then((res) => res.data)
    //   .then((rows) => {
    //     console.log(rows.data, "item name list");
    //     setIpitemgroupnamelist(rows.data);
    //   });
  }, [center, year, month, selected, ipitemgrouplist]);
  // alert(selected);

  // useEffect(() => {
  //   axios
  //     .get(APIURL() + `billings/getitemGroupList/${center}/${year}/${month}`)
  //     .then((res) => res.data)
  //     .then((rows) => {
  //       console.log(rows.data, "");
  //       setIpitemgrouplist(rows.data);
  //     });

  //   // if (ipitemgrouplist.length == 0) setIpitemnamegrouplist([]);
  // }, [center, year, month]);
  console.log(ipitemgrouplist, "ipitemgrouplist");

  // useEffect(async () => {
  //   if (location.state) {
  //     location.state.data.uploadedBy = user.user.username;
  //     location.state.data.uploadedId = user.user.id;
  //     location.state.data.role = user.user.roles;
  //     await axios
  //       .post(
  //         APIURL() + "billings/ipBillingDetailProcessDump",
  //         location.state.data
  //       )
  //       .then((res) => res.data)
  //       .then((rows) => {
  //         console.log(rows, "@!!45");
  //       });

  //     await getRecord();
  //   }
  // }, location.state);

  // useEffect(() => {
  //   setLoading(false);
  //   axios
  //     .get(APIURL() + "billings/findAllIpBillingDetProcess")
  //     .then((res) => res.data)
  //     .then((rows) => {
  //       setRows(rows.data);
  //       setLoading(true);
  //     });
  // }, []);

  // useEffect(() => {
  //   setLoading(false);
  //   axios
  //     .get(APIURL() + "onboard/name")
  //     .then((res) => res.data)
  //     .then((rows) => {
  //       setName(rows);
  //       setLoading(true);
  //     });
  // }, []);
  var array = [];
  var k =
    name &&
    name.data &&
    name.data.map((ele) => {
      array.push(ele.doctorName);
    });
  console.log(array, "0012");
  // useEffect(() => {
  //   setLoading(false);
  //   axios
  //     .get(APIURL() + "onboard/name/" + array)
  //     .then((res) => res.data)
  //     .then((rows) => {
  //       setOboard(rows);
  //       setLoading(true);
  //     });
  // }, []);

  console.log(oboard, "*!!!");
  var doctorList = [
    { id: 0, name: "Dr. Preety Aggarwal" },
    { id: 1, name: "Dr. Shweta Bansal Wazir" },
    { id: 2, name: "Dr. Manish Balde" },
    { id: 3, name: "Dr. Witty Raina" },
    { id: 4, name: "Dr. Binal Shah" },
    { id: 5, name: "Dr. Beena Muktesh" },
    { id: 6, name: "Dr. Shalu Gupta" },
    { id: 7, name: "Dr. Bhavna Dewan" },
    { id: 8, name: "Dr. Radhika Sheth" },
    { id: 9, name: "Dr. Hena Bhagde" },
  ];
  function selectedDropdownvalue(e) {
    const { name, value } = e.target;
    setData(value);
    setInitCalOP([]);
  }
  function selectedMonth(e, patientType = 'IP') {
    const { name, value } = e.target;
    if (patientType == 'IP') {
      setMonth(value);
    } else {
      setOpMonth(value);
    }
  }

  function selectedYear(e, patientType = 'IP') {
    const { name, value } = e.target;
    if (patientType == 'IP') {
      setYear(value);
    } else {
      setOpYear(value);
    }
  }

  const selectedCenter = (e, patientType = 'IP') => {
    console.log(patientType, "selectedCenter")
    const { name, value } = e.target;
    if (patientType == 'IP') {
      setCenter(value);
    } else {
      setOpCenter(value);
    }
  }

  var currentdate = new Date().toJSON().slice(0, 10);
  const handleChangeDate = (e) => {
    const { name, value } = e.target;
    if (name == "StartDate") {
      setStartDate(value)
      setEndDate(value);
    }
    if (name == "EndDate") {
      setEndDate(value)
    }
  }
  // useEffect(() => {
  //   setInitCalOP([]);
  //   setOpTotPayout([]);
  // }, [data]);

  // useEffect(() => {
  //   dispatch(onboardById(364));
  // }, [reload]);
  const [valRec, setValRec] = useState([]);
  const [loadVal, setLoadVal] = useState(false);
  var sumArray = [];
  var Val = [];
  var sum = 0;
  // function calculatePayoutAll() {
  //   // setLoadVal(false);
  //   // var filterdata = rows.filter((z) => data.includes(z.ItemGroupName));
  //   // Val = payoutCalculation.initiateCalOpCalc(filterdata, oboard, data);
  //   // // console.log(Val, "netval");
  //   // console.log(oboard, filterdata, "netval");
  //   axios
  //     .get(
  //       APIURL() +
  //         `billings/loadipbills/${center}/${year}/${month}/calcPayByMonth`
  //     )
  //     .then((res) => res.data)
  //     .then((rows) => {
  //       console.log(rows.data, "222222222222222222222222");
  //       setDataVal(rows.data);
  //       // setLoading(true);
  //     });
  // }

  //loadIpBillsbyLos
  const getIpbillDataonLos = () => {
    axios
      .get(APIURL() + `billings/loadIpBillsbyLos/${center}/${year}/${month}`)
      .then((res) => res.data)
      .then((rows) => {
        // console.log(rows);
      });
  };

  console.log(oboardVal, "get gata from ip processing");
  // useEffect(() => {
  //   axios
  //     .get(APIURL() + `billings/loadbills/MFG/2021/september/calcPayByMonth`)
  //     .then((res) => res.data)
  //     .then((rows) => {
  //       console.log(rows.data, "2777777777")
  //       // setOboardVal(rows);
  //       // setLoading(true);
  //     });
  // }, []);

  console.log(rows, "%%%%");
  // useEffect(() => {
  //   setInitCalOP(valRec);
  // }, [loadVal, valRec]);
  const handlerClose = () => {
    setOpenr(false);
  };
  const onSplitRow = (row) => {
    setSplitRecord(row);
  };
  const addRowData = (row) => {
    let rec = {};
    spitRecord.ConductedBy = rowDetail.doctorname;
    spitRecord.Share = rowDetail.ratio;
    rec = {
      ...spitRecord,
      ConductedBy: rowDetail.doctorname,
      Share: rowDetail.ratio,
      Ratio: rowDetail.ratio,
    };
    let newData = [...splitNewRec];
    newData.push(rec);
    newData.forEach((i, index) => {
      console.log(i, index, "record 21");
    });
    rec = {};
    setSplitNewRec(newData);
    // setRowDetail({ doctorname: "", ratio: "", percentage: "" });
    setRowDetail({ referalFee: "" });
  };

  const changeSplitRecord = () => {
    axios
      .post(APIURL() + "billings/createipSplidRecord", splitNewRec)
      .then(function (response) {
        setSplitDialog(false);
        setSplitRecord({})
        setSplitNewRec([])
        getRecord(splitNewRec[0].patientType, "normal")
      });
    //createSplidRecord
  };

  const getDetail = (data) => {
    axios.get(APIURL() + "billings/getIpItemData/" + data).then((res) => {
      // console.log(res.data.data, "getIpItemData getIpItemData getIpItemData");
      if (res.data.data.length > 0) {
        setIpbillItem(res.data.data);
        history.push({
          pathname: "/ip-billitem-details",

          state: { detail: res.data.data },
        });
        // history.push("/ip-billitem-details");
      }
    });
  };
  console.log(dataVal.length, "dataVal");

  const deleteRow = (data) => {
    axios
      .post(APIURL() + "billings/discardIpRecord", data)
      .then(function (response) { });
  };

  const deleteMultipleRows = () => {
    axios
      .post(APIURL() + "billings/discardIpRecords", changeRowValue)
      .then(function (response) { });
  };

  const populateSurgeon = () => {
    axios
      .get(APIURL() + `billings/populateSurgeons/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };
  //calculateDiscount
  const calculateDiscount = () => {
    axios
      .get(APIURL() + `billings/calculateDiscount/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const calculateRLP = () => {
    axios
      .get(APIURL() + `billings/getRLP/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  //getRLPWest

  const calculateRLPWest = () => {
    axios
      .get(APIURL() + `billings/getRLPWest/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const calculateLOS = () => {
    axios
      .get(APIURL() + `billings/getLos/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  //getWestLos
  const calculateLosWestData = () => {
    axios
      .get(APIURL() + `billings/getWestLos/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  //get inclusion
  const calculateInclusion = () => {
    axios
      .get(APIURL() + `billings/getInclusion/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };
  const calculateOtSurgeonCharges = () => {
    axios
      .get(APIURL() + `billings/getOtSurgeonCharges/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const calculateEpiduralCharges = () => {
    axios
      .get(APIURL() + `billings/getEpiduralCharges/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const calculatePpeKit = () => {
    axios
      .get(APIURL() + `billings/getPPeKit/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const babyreceiving = () => {
    axios
      .get(APIURL() + `billings/getbabyReceiving/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };
  const calculateSafetyConsumable = () => {
    axios
      .get(APIURL() + `billings/getSafetyConsumable/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const calculatetotalDedcution = () => {
    axios
      .get(APIURL() + `billings/totalDeduction/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };


  const calculateOtherDedcution = () => {
    axios
      .get(APIURL() + `billings/otherDeduction/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const [initiateCalc, setInitiateCalc] = useState([]);
  const initiateCalculation = async (input) => {
    await setCirculatLoader(true);
    await axios
      .post(APIURL() + `billings/initiateCalculation/${center}/${year}/${month}/${startDate}/${endDate}`, {
        role: user.user.roles,
        user_id: user.user.id,
        // request_id: payoutDataStatus.request_id,
        request_id: 1,
        nextAction: input && input.nextActionVal ? input.nextActionVal : 'Initiate',
        "payoutData": input && input.payoutData ? input.payoutData : [],
        "doctorDataResult": input && input.doctorDataResult ? input.doctorDataResult : [],
        "pnpData": input && input.pnpData ? input.pnpData : [],
      })
      .then(async (response) => {
        console.log(response, "initiatecalc");
        if (response.data.nextAction !== 'Completed') {
          initiateCalculation({ "nextActionVal": response.data.nextAction, "payoutData": response.data.data, "doctorDataResult": response.data.doctorDataResult, "pnpData": response.data.pnpData })
        } else {
          await setInitiateCalc(response.data.data);
          await setCirculatLoader(false);
          await getPayoutStatus("IP")
        }
        // getRecord();
      });
  };
  console.log(initiateCalc, "initiatecalc")
  const sendToCH = async (patientType) => {
    setCirculatLoader(true);
    var data = {
      center: patientType == 'IP' ? center : opCenter,
      startDate: patientType == 'IP' ? startDate : startDate,
      endDate: patientType == 'IP' ? endDate : endDate,
      year: patientType == 'IP' ? year : opYear,
      month: patientType == 'IP' ? month : opMonth,
      currentAction: payoutState.init.state,
      currentActionBy: user.user.id,
      role: user.user.roles,
      request_id: payoutDataStatus ? payoutDataStatus.request_id : "",
      nextAction: payoutState.ch_approval.state,
      status: 1,
      patientType: patientType,
    }
    await axios.post(APIURL() + 'billings/send-for-approval', data)
      .then(async () => {
        toast.success(payoutState.ch_approval.message);
        await setCirculatLoader(false);
        await getPayoutStatus(patientType)
      })
  }

  const sendToRD = async (patientType) => {
    setCirculatLoader(true);
    var data = {
      center: patientType == 'IP' ? center : center,
      startDate: patientType == 'IP' ? startDate : startDate,
      endDate: patientType == 'IP' ? endDate : endDate,
      year: patientType == 'IP' ? year : opYear,
      month: patientType == 'IP' ? month : opMonth,
      currentAction: payoutState.ch_approved.state,
      currentActionBy: user.user.id,
      role: user.user.roles,
      request_id: payoutDataStatus ? payoutDataStatus.request_id : "",
      nextAction: payoutState.rd_approved.state,
      status: 1,
      patientType: patientType,
    }
    await axios.post(APIURL() + 'billings/send-for-approval', data)
      .then(async () => {
        toast.success(payoutState.ch_approved.currmessage);
        setCirculatLoader(false);
      })

    await getPayoutStatus(patientType)
  }

  const approvePayouts = async (patientType) => {
    setCirculatLoader(true);
    var data = {
      center: patientType == 'IP' ? center : center,
      startDate: patientType == 'IP' ? startDate : startDate,
      endDate: patientType == 'IP' ? endDate : endDate,
      year: patientType == 'IP' ? year : opYear,
      month: patientType == 'IP' ? month : opMonth,
      currentAction: payoutState.rd_approved.state,
      currentActionBy: user.user.id,
      role: user.user.roles,
      request_id: payoutDataStatus ? payoutDataStatus.request_id : "",
      nextAction: payoutState.completed,
      status: 1,
      patientType: patientType,
    }
    await axios.post(APIURL() + 'billings/send-for-approval', data)
      .then(async () => {
        toast.success(payoutState.rd_approved.currmessage);
        setCirculatLoader(false);
      })

    await getPayoutStatus(patientType)
  }

  const openEditItemModal = async (e, data) => {
    setItemData(data[0])
    setEditItemModal(true)
    console.log(e, data, 'openEditItemModal')
  }

  const handlerEditItemModalClose = () => {
    setEditItemModal(false);
    setItemData([]);
  };

  const updateItemDetail = async () => {

  }

  const calculateDelivery = () => {
    axios
      .get(APIURL() + `billings/getDelivery/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const calculateGynec = () => {
    axios
      .get(APIURL() + `billings/getGynec/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const calculateBabyReceiving = () => {
    axios
      .get(APIURL() + `billings/getBabyRcving/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const calculateFertility = () => {
    axios
      .get(APIURL() + `billings/getFertility/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const calculateAnnesthesist = () => {
    axios
      .get(APIURL() + `billings/getAnnesthesist/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const calculateIpvisit = () => {
    axios
      .get(APIURL() + `billings/getipVisitFee/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const calculateNicu = () => {
    axios
      .get(APIURL() + `billings/getNicuFee/${center}/${year}/${month}`)
      .then(function (response) {
        console.log(response, "nicu doc fee");
        getRecord();
      });
  };

  const calculateMisc = () => {
    axios.get(APIURL() + `billings/getMisc/${center}/${year}/${month}`)
      .then(function (response) {
        console.log(response, "Misc");
        getRecord();
      });
  };

  //calculateRadiology
  const calculateRadiology = () => {
    axios
      .get(APIURL() + `billings/getRadiologyFee/${center}/${year}/${month}`)
      .then(function (response) {
        console.log(response, "nicu doc fee");
        getRecord();
      });
  };

  const ipCalculationNorth = () => {
    axios
      .get(APIURL() + `billings/ipCalculationNorth/${center}/${year}/${month}`)
      .then(function (response) {
        getRecord();
      });
  };

  const updateBillItemData = async (newData, oldData) => {
    console.log(newData, oldData, 'function calls')
    var data = {
      payout_detail_id: oldData.id,
      newData: newData
    }
    await axios.post(APIURL() + 'billings/update-bill-item', data).catch((error) => {
      console.log(error)
    }).then((res) => {
      console.log(res, 'Updated bill item')
      toast.success("Bill item updated successfully");
    })
  }

  const addBillItemData = async (newData) => {
    console.log(newData, 'function calls')
    var data = {
      newData: newData
    }
    await axios.post(APIURL() + 'billings/add-bill-item', data).catch((error) => {
      console.log(error)
    }).then((res) => {
      console.log(res, 'Add bill item')
      toast.success("Bill item added successfully");
    })
  }

  const deleteBillItemData = async (oldData) => {
    console.log(oldData, 'function calls')
    var data = {
      payout_detail_id: oldData.id,
    }
    await axios.post(APIURL() + 'billings/delete-bill-item', data).catch((error) => {
      console.log(error)
    }).then((res) => {
      console.log(res, 'Delete bill item')
      toast.success("Bill item deleted successfully");
    })
  }

  const isAllSelected =
    ipitemgrouplist.length > 0 && selected.length === ipitemgrouplist.length;

  const parentItemChange = (event) => {
    if (ipitemgrouplist.length == 0) {
      setIpitemgroupnamelist([]);
    }
    const value = event.target.value;

    if (value[value.length - 1] === "all") {
      let centernames = [];
      let centerid = [];

      ipitemgrouplist.forEach((element) => {
        centerid.push(element.ItemGroup);
        centernames.push(element.ItemGroup);
      });
      setSelected(
        selected.length === ipitemgrouplist.length ? [] : centernames
      );
      setAllData(centerid);
      return;
    } else {
      let indexPosition = -1;
      const centerSet = new Set();
      ipitemgrouplist.forEach((element, index) => {
        if (element.centername == value[0]) {
          indexPosition = index;
          centerSet.add(element.centerId);
          console.log(element.centerId, "for single click of else");
        } else {
        }
      });

      const centerids = Array.from(centerSet);
      setAllData(centerids);

      console.log(allData, "center id  array");
      setSelected(value);
    }
  };

  console.log(selected, "selected item group");
  useEffect(() => {
    axios.get(APIURL() + "usermapcenters/centermaps/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows, "2323");
        setCenterName(rows.data);
      });
  }, []);
  const downloadCsv = (data, fileName) => {
    const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    a.setAttribute("download", finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  //parentItemChangeItemName

  const isAllSelecteditemName =
    ipitemnamegrouplist.length > 0 &&
    selecteditemname.length === ipitemnamegrouplist.length;

  const parentItemChangeItemName = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === "allitemname") {
      let centernames = [];
      let centerid = [];

      ipitemnamegrouplist.forEach((element) => {
        centerid.push(element.ItemGroup);
        centernames.push(element.ItemGroup);
      });
      setSelecteditemname(
        selecteditemname.length === ipitemnamegrouplist.length
          ? []
          : centernames
      );
      setAllDataitemname(centerid);
      return;
    } else {
      let indexPosition = -1;
      const centerSet = new Set();
      ipitemnamegrouplist.forEach((element, index) => {
        if (element.centername == value[0]) {
          indexPosition = index;
          centerSet.add(element.centerId);
          console.log(element.centerId, "for single click of else");
        } else {
        }
      });

      const centerids = Array.from(centerSet);
      setAllDataitemname(centerids);

      console.log(allData, "center id  array");
      setSelecteditemname(value);
    }
  };

  const getPayoutReports = async (row) => {
    await setCirculatLoader(true);
    await setCenter(row.center)
    await setStartDate(row.startDate)
    await setEndDate(row.endDate)
    var data = {
      center: row.center,
      startDate: row.startDate,
      endDate: row.endDate,
      patientType: row.patientType,
    }
    await axios.post(APIURL() + 'billings/payout-status', data)
      .then(async (result) => {
        if (row.patientType == 'IP') {
          await setPayoutDataStatus(result.data.data);
        } else {
          await setPayoutDataStatus(result.data.data);
        }
        console.log(payoutDataStatus, OPPayoutDataStatus, result, 'payoutDataStatus')
      })
    console.log(row, startDate, endDate, center, "getPayoutReports")
    if (row.patientType == 'IP') {
      await axios
        .get(APIURL() + `billings/get-payout-details/${row.center}/${row.startDate}/${row.endDate}/${row.patientType}`)
        .then((res) => res.data)
        .then(async (rows) => {
          await setOboardVal(rows);
          // await getPayoutStatus(row.patientType)
          console.log("\n\n\n\n\n----------@!!45-------\n");
          await setLoading(true);
          await setCirculatLoader(false);
        });
    } else {
      await axios
        .get(APIURL() + `billings/get-payout-details/${row.center}/${row.startDate}/${row.endDate}/${row.patientType}`)
        .then((res) => res.data)
        .then(async (rows) => {
          await setOboardVal(rows);
          // await getPayoutStatus(row.patientType)
          console.log("\n\n\n\n\n----------@!!45-------\n");
          await setLoading(true);
          await setCirculatLoader(false);
        });
    }
  }

  const downloadPayoutBillingDetails = () => {
    let StartDate = moment(new Date(startDate)).format('YYYY-MM-DD')
    let EndDate = moment(new Date(endDate)).format('YYYY-MM-DD')
    var payoutData = [...oboardVal]
    const newData = payoutData.map(row => {
      // delete row.tableData
      let obj = {
        // "SlNo": row.SlNo,
        "MPI": row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", ""),
        "IPNo": row.IPNo,
        "Bill No": row.BillNo,
        "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
        "Bill Time": row.BillTime ? row.BillTime : "Invalid Time",
        "Patient Name": row.PatientName,
        "Age": row.Age,
        "Gender": row.Gender,
        "Admitting Physician": row.AdmittingPhysician,
        "Specialisation": row.Specialisation,
        "Admit Group": row.AdmitGroup,
        "Admission Purpose": row.AdmissionPurpose,
        "Admission Date": row.AdmissionDate,
        "Admission Time": row.AdmissionTime,
        "Discharge Date": row.DischargeDate,
        "Discharge Time": row.DischargeTime,
        "LOS(InDays)": row.LOSInDays,
        "Ward": row.Ward,
        "Room": row.Room,
        "Bed": row.Bed,
        "Bed Category": row.BedCategory,
        "Bill Type": row.BillType,
        "Pharmacy Total": row.PharmacyTotal,
        "Total": row.Total,
        "Pharmacy Return": row.PharmacyReturn,
        "Gross": row.Gross,
        "Rounding": row.Rounding,
        "Net": row.Net,
        "Advance Adjusted": row.AdvanceAdjusted,
        "NetAfter Advance": row.NetAfterAdvance,
        "Cash": row.Cash,
        "Card": row.Card,
        "CHQ": row.CHQ,
        "Amount Due": row.AmountDue,
        "Package Name": row.PackageName,
        "Package Amount": row.PackageAmount,
        "Category": row.Category,
        "TPA": row.TPA,
        "Insurance": row.Insurance,
        "Corporate Name": row.CorporateName,
        "Auth Code": row.AuthCode,
        "Approved Amount": row.ApprovedAmount,
        "User": row.User,
        "Discount": row.Discount,
        "ipGroup": row.ipGroup,
        "ipSubGroup": row.ipSubGroup,
        "ERP LOS": row.erpLos,
      }
      return obj
    })
    // console.log(newData,"newData--------------------")
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
    //Download
    XLSX.writeFile(workBook, "Ip-Payout-Billing-Details_" + center + "_" + StartDate + "_to_" + EndDate + ".xlsx")
    return true;
  }

  const downloadExcel = () => {
    let StartDate = moment(new Date(startDate)).format('YYYY-MM-DD')
    let EndDate = moment(new Date(endDate)).format('YYYY-MM-DD')
    var payoutData = [...initiateCalc]
    const newData = payoutData.map(row => {
      // delete row.tableData
      let obj = {
        // "SlNo": row.SlNo,
        "MPI": row.MPI.includes("'") ? (row.MPI).replace("`", "") : "'" + (row.MPI).replace("`", ""),
        "IPNo": row.IPNo,
        "Bill No": row.BillNo,
        "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
        "Bill Time": row.BillTime ? row.BillTime : "Invalid Time",
        "Patient Name": row.PatientName,
        "Age": row.Age,
        "Gender": row.Gender,
        "Admitting Physician": row.AdmittingPhysician,
        "Specialisation": row.Specialisation,
        "Admit Group": row.AdmitGroup,
        "Admission Purpose": row.AdmissionPurpose,
        "Admission Date": row.AdmissionDate,
        "Discharge Date": row.DischargeDate,
        "LOS(InDays)": row.LOSInDays,
        "Item Group": row.itemGroupName,
        "Item Name": row.itemName,
        "Bed Category": row.BedCategory,
        "Bill Type": row.BillType,
        "Package Name": row.PackageName,
        "Package Amount": row.PackageAmount,
        "Category": row.Category,
        "TPA": row.TPA,
        "Insurance": row.Insurance,
        "Corporate Name": row.CorporateName,
        "User": row.User,
        "Month": row.Month,
        "ipGroup": row.ipGroup,
        "Sub Category": row.ipSubGroup,
        "ERP LOS": row.erpLos,
        "Pharmacy": row.PharmacyTotal,
        "Discount": row.Discount,
        "Lab": row.Lab,
        "Radiology": row.Radiology,
        "Other Deduction": row.otherDeduction,
        "Epidural Charges": row.epiduralCharges,
        "Safety Consumable": row.safetyConsumable,
        // "Net Bill (Bill Report)": row.Gross ? numberWithCommas(parseFloat(row.Gross).toFixed()) : 0,
        // "Net Bill (Calculated)": row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0,
        // "Item Gross": row.itemGross ? numberWithCommas(parseFloat(row.itemGross).toFixed()) : 0,
        // "Item Net": row.itemNet ? numberWithCommas(parseFloat(row.itemNet).toFixed()) : 0,
        "Net Bill (Bill Report)": row.Gross ? parseFloat(row.Gross).toFixed() : 0,
        "Net Bill (Calculated)": row.Net ? parseFloat(row.Net).toFixed() : 0,
        "Item Gross": row.itemGross ? parseFloat(row.itemGross).toFixed() : 0,
        "Item Net": row.itemNet ? parseFloat(row.itemNet).toFixed() : 0,
        "Payable/Non Payable": row.PNP == 1 ? 'Payable' : 'Non Payable',
        "Package Inclusion": row.packageInclusion,
        "Doctor Name": row.conductingDoctor,
        "Doctor LT ID": row.doctorLTId,
        "Nature": row.conductingNature,
        "Commercial Term": row.commercialTerm,
        "Qty": row.Qty,
        "Amount": row.amount ? parseFloat(row.amount).toFixed() : 0,
        "Ratio": row.ratio,
        "Dr Fee": row.doctorFee ? parseFloat(row.doctorFee).toFixed() : 0,
        "Exception": row.exceptionIssue,
      }
      return obj
    })
    // console.log(newData,"newData--------------------")
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
    //Download
    XLSX.writeFile(workBook, "IP-Payout_" + center + "_" + StartDate + "_to_" + EndDate + ".xlsx")
    return true;
  }

  console.log(user.user.roles, payoutDataStatus, "user.user.roles")
  return (
    <>
      {" "}
      {/* {user.user.activityAssigments.filter((elem) => elem.activityId === 9 || elem.activityId === 10 || elem.activityId === 11).find(item => item.RoleId === user.user.roles) ?
        // <PTSidebar />
        <Sidebar />
        : <Sidebar />
      } */}
      <Sidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
        {!loading ? (
          <Skeleton animation="wave" variant="rect" width={950} height={400} />
        ) : (
          <div style={{ whiteSpace: "nowrap" }}>
            {user.user.activityAssigments.filter((elem) => elem.activityId === 12 || elem.activityId === 13 || elem.activityId === 14).find(item => item.RoleId === user.user.roles) ?
              <MaterialTable
                columns={[
                  { title: "Start Date", field: "startDate" },
                  { title: "End Date", field: "endDate" },
                  { title: "Center", field: "center" },
                  { title: "Req. Type", field: "patientType" },
                  {
                    title: "Action", field: "patientType", filtering: false, render: (row) =>
                      <IconButton style={{ color: "#810e36" }} aria-label="close" onClick={() => getPayoutReports(row)}>
                        <VisibilityIcon />
                      </IconButton>
                  },
                ]}
                data={payoutReq}
                options={{
                  sorting: true,
                  exportButton: true,
                  exportAllData: true,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 50, 100, 200, 500],
                  search: true,
                  filtering: true,
                }}
                icons={{
                  Filter: "filter",
                }}
                title="Generated Payouts"
              />
              : ''}
            {user.user.activityAssigments.filter((elem) => elem.activityId === 9 || elem.activityId === 10 || elem.activityId === 11).find(item => item.RoleId === user.user.roles) ?
              <Paper
                style={{ marginBottom: "10px", border: "1px solid #810e36" }}
              >
                <div style={{ padding: "8px" }}>

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={center}
                          onChange={(e) => selectedCenter(e, 'IP')}
                          label=" Filter By Center"
                          fullWidth
                        >
                          <MenuItem value="Select the Center">
                            Filter By Center
                          </MenuItem>
                          {centerName.length > 0 && centerName.map((ele) => {
                            return (<MenuItem value={ele.code}>{ele.name}</MenuItem>)
                          })}
                          {/* <MenuItem value="NORTH">NORTH</MenuItem> */}
                          {/* <MenuItem value="JNR">JNR</MenuItem> */}
                          {/* <MenuItem value="SOUTH">SOUTH</MenuItem>
                        <MenuItem value="WEST">WEST</MenuItem> */}

                        </Select>
                      </FormControl>
                      {/* <FormControl
                      variant="outlined"
                      // className={classes.formControl}

                      style={{ width: "100%" }}
                    >
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={center}
                        onChange={selectedCenter}
                        label=" Filter By Center"
                        fullWidth
                      >
                        <MenuItem value="Select the Center">
                          Filter By Center
                        </MenuItem>
                        <MenuItem value="NORTH">NORTH</MenuItem>
                        <MenuItem value="SOUTH">SOUTH</MenuItem>
                        <MenuItem value="WEST">WEST</MenuItem>
                        <MenuItem value="Mumbai">Mumbai</MenuItem>
                        <MenuItem value="Jayanagar">Jayanagar</MenuItem>
                      </Select>
                    </FormControl> */}
                    </Grid>
                    {/* <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={year}
                          onChange={(e) => selectedYear(e, 'IP')}
                          label=" Filter By Year"
                          fullWidth
                        >
                          <MenuItem value="Select the Year">
                            Filter By Year
                          </MenuItem>
                          <MenuItem value="2021">2021</MenuItem>
                          <MenuItem value="2022">2022</MenuItem>
                          <MenuItem value="2023">2023</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={month}
                          onChange={(e) => selectedMonth(e, 'IP')}
                          label=" Filter By Month"
                          fullWidth
                        >
                          <MenuItem value="Select the Month">
                            Filter By Month
                          </MenuItem>
                          <MenuItem value="JANUARY">JANUARY</MenuItem>
                          <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                          <MenuItem value="MARCH">MARCH</MenuItem>
                          <MenuItem value="APRIL">APRIL</MenuItem>
                          <MenuItem value="MAY">MAY</MenuItem>
                          <MenuItem value="JUNE">JUNE</MenuItem>
                          <MenuItem value="JULY">JULY</MenuItem>
                          <MenuItem value="AUGUST">AUGUST</MenuItem>
                          <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                          <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                          <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                          <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid> */}
                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <input
                          type="date"
                          name="StartDate"
                          value={startDate}
                          max={currentdate}
                          onChange={(e) => handleChangeDate(e)}
                          style={{ color: "#000000", height: "55.98px", fontWeight: "500", fontSize: "15px" }}
                          className="form-control"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                      <FormControl variant="outlined" style={{ width: "100%" }}>
                        <input
                          type="date"
                          label="From"
                          name="EndDate"
                          min={startDate}
                          max={currentdate}
                          value={endDate}
                          onChange={(e) => handleChangeDate(e)}
                          style={{ color: "#000000", height: "55.98px", fontWeight: "500", fontSize: "15px" }}
                          className="form-control"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                      {" "}
                      <Button
                        variant="contained"
                        onClick={() => LoadData("IP", "normal")}
                        style={{
                          height: "100%",
                          backgroundColor: "#810e36",
                          color: "white",
                        }}
                        fullWidth
                      >
                        Load Data{" "}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
              : ''}
            {oboardVal && oboardVal.length > 0 ?
              user.user.activityAssigments.filter((elem) => elem.activityId === 9 || elem.activityId === 10 || elem.activityId === 11).find(item => item.RoleId === user.user.roles) ?
                <>
                  <MaterialTable
                    columns={[
                      // {
                      //   title: "Split",
                      //   field: "Action",
                      //   filtering: false,
                      //   render: (row) => (
                      //     <Tooltip title="Split Record">
                      //       <IconButton>
                      //         <CallSplitIcon
                      //           style={{
                      //             transform: "rotate(90deg)",
                      //             color: "#810e36",
                      //           }}
                      //           onClick={() => {
                      //             setSplitDialog(true);
                      //             onSplitRow(row);
                      //           }}
                      //         />
                      //       </IconButton>
                      //     </Tooltip>
                      //   ),
                      // },
                      // {
                      //   title: "Discard",
                      //   field: "Action",
                      //   filtering: false,
                      //   render: (row) => (
                      //     <Tooltip title="Discard Record">
                      //       <IconButton>
                      //         <RemoveCircleOutlineIcon
                      //           style={{
                      //             color: "#810e36",
                      //           }}
                      //           onClick={() => {
                      //             setConfirmDialog({
                      //               isOpen: true,
                      //               title:
                      //                 "Deleting record may result in some features unresponsive ?",
                      //               subtitle:
                      //                 "Are you sure you want to delete this record ?",
                      //               onConfirm: () => {
                      //                 deleteRow(row);
                      //                 setConfirmDialog({ isOpen: false });
                      //               },
                      //             });
                      //           }}
                      //         />
                      //       </IconButton>
                      //     </Tooltip>
                      //   ),
                      // },
                      { title: "MPI", field: "MPI" },
                      {
                        title: "IP No",
                        field: "IPNo",
                      },
                      { title: "Bill No", field: "BillNo" },
                      { title: "Bill Date", field: "BillDate" },
                      { title: "Bill Time", field: "BillTime" },
                      { title: "PatientName", field: "PatientName" },
                      { title: "Age", field: "Age" },
                      { title: "Gender", field: "Gender" },
                      { title: "Admitting Physician", field: "AdmittingPhysician" },
                      { title: "Specialisation", field: "Specialisation" },
                      { title: "AdmitGroup", field: "AdmitGroup" },
                      { title: "Admission Purpose", field: "AdmissionPurpose" },
                      { title: "Admission Date", field: "AdmissionDate" },
                      { title: "Admission Time", field: "AdmissionTime" },
                      { title: "Discharge Date", field: "DischargeDate" },
                      { title: "Discharge Time", field: "DischargeTime" },
                      { title: "LOSInDays", field: "LOSInDays" },
                      { title: "Ward", field: "Ward" },
                      { title: "Room", field: "Room" },
                      { title: "Bed", field: "Bed" },
                      { title: "Bed Category", field: "BedCategory" },
                      { title: "BillType", field: "BillType" },
                      { title: "Pharmacy Total", field: "PharmacyTotal" },
                      { title: "Total", field: "Total" },
                      { title: "Pharmacy Return", field: "PharmacyReturn" },
                      { title: "Gross", field: "Gross" },
                      { title: "Luxury Tax", field: "LuxuryTax" },
                      { title: "Other Tax", field: "OtherTax" },
                      { title: "Total Tax", field: "TotalTax" },
                      { title: "Rounding", field: "Rounding" },
                      { title: "Net", field: "Net" },
                      { title: "Advance Adjusted", field: "AdvanceAdjusted" },
                      { title: "NetAfter Advance", field: "NetAfterAdvance" },
                      { title: "Cash", field: "Cash" },
                      { title: "Card", field: "Card" },
                      { title: "CHQ", field: "CHQ" },
                      { title: "Amount Due", field: "AmountDue" },
                      { title: "Package Name", field: "PackageName" },
                      { title: "Package Amount", field: "PackageAmount" },
                      { title: "Category", field: "Category" },
                      { title: "TPA", field: "TPA" },
                      { title: "Insurance", field: "Insurance" },
                      { title: "Corporate Name", field: "CorporateName" },
                      { title: "Auth Code", field: "AuthCode" },
                      { title: "Approved Amount", field: "ApprovedAmount" },
                      { title: "User", field: "User" },
                      { title: "Discount", field: "Discount" },
                      {
                        title: "Radiology",
                        field: "Radiology",
                      },
                      { title: "Lab", field: "Lab" },
                      {
                        title: "Pharmacy",
                        field: "Pharmacy",
                      },
                      { title: "Other Deduction", field: "otherDeduction" },
                      {
                        title: "ipGroup", field: "ipGroup",
                        lookup: {
                          "Gynec": "Gynec",
                          "Delivery": "Delivery",
                          "Pead": "Pead",
                          "Fertility": "Fertility",
                        },
                      },
                      { title: "ipSubGroup", field: "ipSubGroup" },
                      {
                        title: "erpLos", field: "erpLos",
                        lookup: {
                          "Gynec": "Gynec",
                          "Delivery": "Delivery",
                          "Pead": "Pead",
                          "Fertility": "Fertility",
                        },
                      },
                      { title: "oTSurgeonCharges", field: "oTSurgeonCharges" },
                      { title: "epiduralCharges", field: "epiduralCharges" },
                      { title: "babyReceivingFee", field: "babyReceivingFee" },
                      { title: "safetyConsumable", field: "safetyConsumable" },
                      { title: "Referal Fee", field: "referalFee" },
                      { title: "Total Deduction", field: "totalDeduction" },
                      // { title: "PPE KIT", field: "ppeKit" },
                      { title: "Conducting Doctor", field: "conductingDoctor" },
                      { title: "Conducting Nature", field: "conductingNature" },
                      { title: "Conducting Term", field: "conductingTerm" },
                      { title: "Amount", field: "amount", render: (row) => row.amount ? numberWithCommas(parseFloat(row.amount).toFixed()) : 0 },
                      { title: "Ratio", field: "ratio" },
                      { title: "Doctor Fee", field: "doctorFee", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                      {
                        title: "Annesthesist Doctor Fee",
                        field: "annesthesistDoctorFee",
                      },
                      { title: "Ip Visist Doctor Fee", field: "ipVisistDoctorFee" },
                      { title: "Nicu Doctor Fee", field: "nicuDoctorFee" },
                      { title: "Misc Doctor Fee", field: "miscellaneousDoctorFee" },
                      { title: "Radiology Doctor Fee", field: "radiologyDoctorFee" },
                    ]}
                    data={oboardVal}
                    // actions={[
                    //   {
                    //     tooltip: "Edit Records",
                    //     icon: "edit",
                    //     iconProps: { style: { color: "#810e36" } },
                    //     onClick: (evt, data) => setOpenr(true),
                    //   },
                    //   {
                    //     tooltip: "Discard Records",
                    //     icon: () => (
                    //       <RemoveCircleOutlineIcon style={{ color: "#810e36" }} />
                    //     ),
                    //     onClick: (evt, row) =>
                    //       setConfirmDialog({
                    //         isOpen: true,
                    //         title:
                    //           "Discarding record may result in some features unresponsive ?",
                    //         subtitle:
                    //           "Are you sure you want to discard this record ?",
                    //         onConfirm: () => {
                    //           deleteMultipleRows(row);
                    //           setConfirmDialog({ isOpen: false });
                    //         },
                    //       }),
                    //   },
                    // ]}
                    actions={[
                      {
                        icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                        tooltip: "Export to Excel",
                        onClick: () => downloadPayoutBillingDetails(),
                        isFreeAction: true
                      }
                    ]}
                    options={{
                      fixedColumns: {
                        top: 1,
                        right: 0
                      },
                      maxBodyHeight: '500px',
                      filtering: true,
                      sorting: true,
                      // exportButton: true,
                      exportAllData: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    icons={{
                      Filter: "filter",
                    }}
                    onSelectionChange={(rows) => {
                      setRowSelection(true);
                      setChangeRowValue(rows);
                    }}
                    title="Ip-Payout Billing Details"
                  />
                  <div style={{ marginTop: "10px" }}>
                    {user.user.id == 402 ? (
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={calculateRLPWest}
                        style={{ display: "none" }}
                      >
                        R-L-P
                      </Button>
                    ) : (
                      <>
                        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                          <Button
                            style={{ marginLeft: "10px" }}
                            size="small"
                            color="secondary"
                            variant="contained"
                            onClick={initiateCalculation}
                          >
                            Initiate Calculation
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </>
                :
                <>
                  <MaterialTable
                    columns={[
                      {
                        title: "Split",
                        field: "Action",
                        filtering: false,
                        hidden: (payoutDataStatus && payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approval.state) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.nextAction == payoutState.init.state))) ? false : true,
                        render: (row) => (
                          <Tooltip title="Split Record">
                            <IconButton>
                              <CallSplitIcon
                                style={{
                                  transform: "rotate(90deg)",
                                  color: "#810e36",
                                }}
                                onClick={() => {
                                  setSplitDialog(true);
                                  onSplitRow(row);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        ),
                      },
                      {
                        title: "Discard",
                        field: "Action",
                        filtering: false,
                        hidden: (payoutDataStatus && payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approved.state) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.nextAction == payoutState.init.state))) ? true : true,
                        render: (row) => (
                          <Tooltip title="Discard Record">
                            <IconButton>
                              <RemoveCircleOutlineIcon
                                style={{
                                  color: "#810e36",
                                }}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Deleting record may result in some features unresponsive ?",
                                    subtitle:
                                      "Are you sure you want to delete this record ?",
                                    onConfirm: () => {
                                      deleteRow(row);
                                      setConfirmDialog({ isOpen: false });
                                    },
                                  });
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        ),
                      },
                      { title: "MPI", field: "MPI", editable: 'never' },
                      {
                        title: "IP No",
                        field: "ip",
                        editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      { title: "Bill No", field: "BillNo", editable: 'never' },
                      { title: "Bill Date", field: "BillDate", editable: 'never' },
                      { title: "Bill Time", field: "BillTime", editable: 'never' },
                      { title: "Patient Name", field: "PatientName", editable: 'never' },
                      {
                        title: "Age", field: "age", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Gender", field: "gender", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      { title: "Admitting Physician", field: "OrderingPhysician", editable: 'never' },
                      { title: "Specialisation", field: "Specialisation", editable: 'never' },
                      {
                        title: "Admit Group", field: "admitGroup", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Admission Purpose", field: "admissionPurpose", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Admission Date", field: "AdmissionDate", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Discharge Date", field: "DischargeDate", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "LOS(InDays)", field: "LOS", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Bed Category", field: "bedCategory", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Bill Type", field: "billType", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Package Name", field: "packageName", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Package Amount", field: "PackageAmount", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false, render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0
                      },
                      {
                        title: "Category", field: "insuranceCategory", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "TPA", field: "tpaName", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Insurance", field: "insurance", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Corporate Name", field: "organisation", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      { title: "User", field: "User", editable: 'never' },
                      { title: "Month", field: "PaymentMonth", editable: 'never' },
                      {
                        title: "ERP LOS", field: "erpLos", editable: 'never',
                        lookup: {
                          "Gynec": "Gynec",
                          "Delivery": "Delivery",
                          "Pead": "Pead",
                          "Fertility": "Fertility",
                        },
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Pharmacy", field: "PharmacyTotal", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                        render: (row) => row.PharmacyTotal ? numberWithCommas(parseFloat(row.PharmacyTotal).toFixed()) : 0
                      },
                      { title: "Discount", field: "Discount", editable: 'never' },
                      {
                        title: "Lab", field: "Lab", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                        render: (row) => row.Lab ? numberWithCommas(parseFloat(row.Lab).toFixed()) : 0
                      },
                      {
                        title: "Radiology", field: "Radiology", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                        render: (row) => row.Radiology ? numberWithCommas(parseFloat(row.Radiology).toFixed()) : 0
                      },
                      {
                        title: "Other Deduction", field: "otherDeduction", editable: 'never',
                        hidden: (row) => row.patientType == "OP" ? true : false,
                        render: (row) => row.otherDeduction ? numberWithCommas(parseFloat(row.otherDeduction).toFixed()) : 0
                      },
                      { title: "Net Bill", field: "Net", editable: 'never', render: (row) => row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0 },
                      { title: "Doctor Name", field: "ConductedBy", validate: rowData => rowData.ConductedBy !== '' && rowData.ConductedBy !== null ? "" : "Doctor Name cannot be empty" },
                      {
                        title: "Nature", field: "NatureofDoctors",
                        lookup: {
                          "Surgeon Fee": "Surgeon Fee",
                          "Anesthesia Services": "Anesthesia Services",
                          "IP Visit Fee": "IP Visit Fee",
                          "Radiology Fee": "Radiology Fee",
                          "NICU": "NICU",
                          "Additional Procedure": "Additional Procedure",
                        },
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Terms", field: "commercialTerm",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      { title: "Qty", field: "Count" },
                      { title: "Amount", field: "doctorAmount" },
                      { title: "Ratio", field: "Ratio" },
                      { title: "Dr Fee ", field: "doctorFee", type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                      { title: "CH Remarks ", field: "GLCoe", validate: rowData => rowData.GLCoe !== '' && rowData.GLCoe !== null ? "" : "Remarks cannot be empty" },
                      // { title: "RD Remarks ", field: "Concatenate" },
                    ]}
                    data={oboardVal}
                    // actions={[
                    //   {
                    //     tooltip: "Edit Records",
                    //     icon: "edit",
                    //     iconProps: { style: { color: "#810e36" } },
                    //     onClick: (evt, data) => openEditItemModal(evt, data),
                    //   },
                    //   {
                    //     tooltip: "Discard Records",
                    //     icon: () => (
                    //       <RemoveCircleOutlineIcon style={{ color: "#810e36" }} />
                    //     ),
                    //     onClick: (evt, row) =>
                    //       setConfirmDialog({
                    //         isOpen: true,
                    //         title:
                    //           "Discarding record may result in some features unresponsive ?",
                    //         subtitle:
                    //           "Are you sure you want to discard this record ?",
                    //         onConfirm: () => {
                    //           deleteMultipleRows(row);
                    //           setConfirmDialog({ isOpen: false });
                    //         },
                    //       }),
                    //   },
                    // ]}
                    editable={user.user.activityAssigments.filter((elem) => elem.activityId === 12).find(item => item.RoleId === user.user.roles) ?
                      (payoutDataStatus && payoutDataStatus.nextAction && ((payoutDataStatus.nextAction == payoutState.ch_approval.state && user.user.activityAssigments.filter((elem) => elem.activityId === 12).find(item => item.RoleId === user.user.roles))) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.nextAction == payoutState.init.state)))) ? {
                        // onRowAdd: newData =>
                        //   new Promise((resolve, reject) => {
                        //     setTimeout(() => {
                        //       addBillItemData(newData)
                        //       setOboardVal([...oboardVal, newData]);
                        //       getRecord("IP", "normal")
                        //       resolve();
                        //     }, 1000)
                        //   }),
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              const dataUpdate = [...oboardVal];
                              const index = oldData.tableData.id;
                              dataUpdate[index] = newData;
                              console.log(oldData, newData, 'exception item update')
                              if (newData.GLCoe == '') {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Alert",
                                  subtitle: "Please Add Remarks",
                                });
                                reject()
                                return;
                              } else {
                                updateBillItemData(newData, oldData)
                                setOboardVal([...dataUpdate]);
                                resolve();
                              }

                            }, 1000)
                          }),
                        // onRowDelete: oldData =>
                        //   new Promise((resolve, reject) => {
                        //     setTimeout(() => {
                        //       const dataDelete = [...oboardVal];
                        //       const index = oldData.tableData.id;
                        //       dataDelete.splice(index, 1);
                        //       deleteBillItemData(oldData);
                        //       setOboardVal([...dataDelete]);

                        //       resolve();
                        //     }, 1000)
                        //   }),
                      } : {} : {}}
                    options={{
                      // filtering: true,

                      // selection: payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approved.state ? true : false,
                      sorting: true,
                      exportButton: true,
                      exportAllData: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    onSelectionChange={(rows) => {
                      setRowSelection(true);
                      setChangeRowValue(rows);
                    }}
                    title="Payout Details"
                  />
                  <MaterialTable
                    columns={[
                      {
                        title: "Split",
                        field: "Action",
                        filtering: false,
                        hidden: payoutDataStatus && payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approval.state ? true : true,
                        render: (row) => (
                          <Tooltip title="Split Record">
                            <IconButton>
                              <CallSplitIcon
                                style={{
                                  transform: "rotate(90deg)",
                                  color: "#810e36",
                                }}
                                onClick={() => {
                                  setSplitDialog(true);
                                  onSplitRow(row);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        ),
                      },
                      {
                        title: "Discard",
                        field: "Action",
                        filtering: false,
                        hidden: payoutDataStatus && payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approved.state ? true : true,
                        render: (row) => (
                          <Tooltip title="Discard Record">
                            <IconButton>
                              <RemoveCircleOutlineIcon
                                style={{
                                  color: "#810e36",
                                }}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Deleting record may result in some features unresponsive ?",
                                    subtitle:
                                      "Are you sure you want to delete this record ?",
                                    onConfirm: () => {
                                      deleteRow(row);
                                      setConfirmDialog({ isOpen: false });
                                    },
                                  });
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        ),
                      },
                      { title: "MPI", field: "MPI" },
                      {
                        title: "IP No",
                        field: "ip",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      { title: "Bill No", field: "BillNo" },
                      { title: "Bill Date", field: "BillDate" },
                      { title: "Bill Time", field: "BillTime" },
                      { title: "Patient Name", field: "PatientName" },
                      {
                        title: "Age", field: "age",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Gender", field: "gender",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      { title: "Admitting Physician", field: "OrderingPhysician" },
                      { title: "Specialisation", field: "Specialisation" },
                      {
                        title: "Admit Group", field: "admitGroup",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Admission Purpose", field: "admissionPurpose",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Admission Date", field: "AdmissionDate",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Discharge Date", field: "DischargeDate",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "LOS(InDays)", field: "LOS",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Bed Category", field: "bedCategory",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Bill Type", field: "billType",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Package Name", field: "packageName",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Package Amount", field: "PackageAmount",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                        render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0
                      },
                      {
                        title: "Category", field: "insuranceCategory",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "TPA", field: "tpaName",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Insurance", field: "insurance",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Corporate Name", field: "organisation",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      { title: "User", field: "User" },
                      { title: "Month", field: "PaymentMonth" },
                      {
                        title: "ERP LOS", field: "erpLos",
                        lookup: {
                          "Gynec": "Gynec",
                          "Delivery": "Delivery",
                          "Pead": "Pead",
                          "Fertility": "Fertility",
                        },
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Pharmacy", field: "PharmacyTotal",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                        render: (row) => row.PharmacyTotal ? numberWithCommas(parseFloat(row.PharmacyTotal).toFixed()) : 0
                      },
                      { title: "Discount", field: "Discount" },
                      {
                        title: "Lab", field: "Lab",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                        render: (row) => row.Lab ? numberWithCommas(parseFloat(row.Lab).toFixed()) : 0
                      },
                      {
                        title: "Radiology", field: "Radiology",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                        render: (row) => row.Radiology ? numberWithCommas(parseFloat(row.Radiology).toFixed()) : 0
                      },
                      {
                        title: "Other Deduction", field: "otherDeduction",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                        render: (row) => row.otherDeduction ? numberWithCommas(parseFloat(row.otherDeduction).toFixed()) : 0
                      },
                      { title: "Net Bill", field: "Net", render: (row) => row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0 },
                      { title: "Doctor Name", field: "ConductedBy" },
                      {
                        title: "Nature", field: "NatureofDoctors",
                        lookup: {
                          "Surgeon Fee": "Surgeon Fee",
                          "Anesthesia Services": "Anesthesia Services",
                          "IP Visit Fee": "IP Visit Fee",
                          "Radiology Fee": "Radiology Fee",
                          "NICU": "NICU",
                          "Additional Procedure": "Additional Procedure",
                        },
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      {
                        title: "Terms", field: "commercialTerm",
                        hidden: (row) => row.patientType == "OP" ? true : false,
                      },
                      { title: "Qty", field: "Count" },
                      { title: "Amount", field: "doctorAmount" },
                      { title: "Ratio", field: "Ratio" },
                      { title: "Dr Fee ", field: "doctorFee", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                      { title: "Exceptional Issue", field: "vlookup" },
                      { title: "CH Remarks ", field: "GLCoe" },
                      // { title: "RD Remarks ", field: "Concatenate" },
                    ]}
                    data={oboardVal.filter(elem => elem.vlookup != '')}
                    // editable={user.user.activityAssigments.filter((elem) => elem.activityId === 12).find(item => item.RoleId === user.user.roles) ?
                    //   payoutDataStatus && payoutDataStatus.nextAction && ((payoutDataStatus.nextAction == payoutState.ch_approved.state && user.user.activityAssigments.filter((elem) => elem.activityId === 12).find(item => item.RoleId === user.user.roles))) ? {
                    //     onRowAdd: newData =>
                    //       new Promise((resolve, reject) => {
                    //         setTimeout(() => {
                    //           setOboardVal([...oboardVal, newData]);
                    //           resolve();
                    //         }, 1000)
                    //       }),
                    //     onRowUpdate: (newData, oldData) =>
                    //       new Promise((resolve, reject) => {
                    //         setTimeout(() => {
                    //           const dataUpdate = [...oboardVal];
                    //           const index = oldData.tableData.id;
                    //           dataUpdate[index] = newData;
                    //           console.log(oldData, newData, 'exception item update')
                    //           updateBillItemData(newData, oldData)
                    //           setOboardVal([...dataUpdate]);

                    //           resolve();
                    //         }, 1000)
                    //       }),
                    //     onRowDelete: oldData =>
                    //       new Promise((resolve, reject) => {
                    //         setTimeout(() => {
                    //           const dataDelete = [...oboardVal];
                    //           const index = oldData.tableData.id;
                    //           dataDelete.splice(index, 1);
                    //           setOboardVal([...dataDelete]);

                    //           resolve();
                    //         }, 1000)
                    //       }),
                    //   } : {} : {}}
                    options={{
                      // filtering: true,

                      // selection: payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approved.state ? true : false,
                      sorting: true,
                      exportButton: true,
                      exportAllData: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    onSelectionChange={(rows) => {
                      setRowSelection(true);
                      setChangeRowValue(rows);
                    }}
                    title="Payout Details Exceptional Cases"
                  />
                  {user.user.activityAssigments.filter((elem) => elem.activityId === 13).find(item => item.RoleId === user.user.roles) ?
                    payoutDataStatus && payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approval.state ?
                      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                        {/* <Button
                          style={{ marginLeft: "10px" }}
                          size="small"
                          color="secondary"
                          variant="contained"
                          onClick={() => sendToRD(payoutDataStatus.patientType)}
                        >
                          Send to COO
                        </Button> */}
                      </div>
                      : ""
                    : user.user.activityAssigments.filter((elem) => elem.activityId === 14).find(item => item.RoleId === user.user.roles) && payoutDataStatus && payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.rd_approved.state ?
                      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                        {/* <Button
                          style={{ marginLeft: "10px" }}
                          size="small"
                          color="secondary"
                          variant="contained"
                          onClick={() => approvePayouts(payoutDataStatus.patientType)}
                        >
                          Approve
                        </Button> */}
                      </div>
                      : ""}
                </>
              :
              ""
            }
            {dataVal.length > 0 ? (
              <MaterialTable
                columns={[
                  { title: "SlNo", field: "SlNo" },
                  { title: "MPI", field: "MPI" },
                  { title: "Patient Name", field: "PatientName" },
                  { title: "Bill Time", field: "BillTime" },
                  {
                    title: "Bill No",
                    field: "BillNo",
                    render: (row) => (
                      <div
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => alert(row.BillNo)}
                      >
                        {row.BillNo}
                      </div>
                    ),
                  },
                  { title: "Bill Date", field: "BillDate", render: (row) => (<div>{dateFormatter(row.BillDate)}</div>) },
                  { title: "Admin Fee", field: "AdminFee" },
                  { title: "Item Group Name", field: "ItemGroupName" },
                  { title: "Item Name", field: "ItemName" },
                  { title: "Gross", field: "Gross" },
                  { title: "Tax", field: "Tax" },
                  { title: "Discount", field: "Discount" },
                  { title: "Net", field: "Net" },
                  {
                    title: "Calculated Value",
                    field: "calculatedvalue",
                    render: (row) =>
                      row.calculatedvalue != 0 ? (
                        <div style={{ cursor: "pointer", color: "green" }}>
                          {row.calculatedvalue}
                        </div>
                      ) : (
                        <div style={{ cursor: "pointer", color: "red" }}>
                          {row.calculatedvalue}
                        </div>
                      ),
                  },
                  { title: "Share", field: "Share" },
                  {
                    title: "Ordering Physician",
                    field: "OrderingPhysician",
                  },
                  { title: "Specialisation", field: "Specialisation" },
                  { title: "ConductedBy", field: "ConductedBy" },
                  { title: "User", field: "User" },
                  {
                    title: "Payable",
                    field: "PayableNonPayable",
                    lookup: {
                      1: "Yes",
                      0: "No",
                    },
                  },
                  {
                    title: "Conducted By Final",
                    field: "ConductedByFinal",
                  },
                  { title: "Location", field: "Location" },
                ]}
                data={dataVal}
                options={{
                  sorting: true,
                  exportButton: true,
                  exportAllData: true,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 50, 100, 200, 500],
                  search: true,
                }}
                onSelectionChange={(rows) => {
                  setRowSelection(true);
                  setChangeRowValue(rows);
                }}
                title="OP-Payout Calculation Details"
              />
            ) : (
              ""
            )}
          </div>
        )}

        {(initiateCalc.length > 0) ? (
          <>
            <MaterialTable
              columns={[
                {
                  title: "Split",
                  field: "Action",
                  editable: 'never',
                  filtering: false,
                  hidden: (payoutDataStatus && payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approval.state) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.nextAction == payoutState.init.state))) ? false : true,
                  render: (row) => (
                    <Tooltip title="Split Record">
                      <IconButton>
                        <CallSplitIcon
                          style={{
                            transform: "rotate(90deg)",
                            color: "#810e36",
                          }}
                          onClick={() => {
                            setSplitDialog(true);
                            onSplitRow(row);
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  ),
                },
                // { title: "SlNo", field: "SlNo", editable: 'never' },
                { title: "MPI", field: "MPI", editable: 'never' },
                { title: "IPNo", field: "ip", editable: 'never' },
                { title: "Bill No", field: "BillNo", editable: 'never' },
                { title: "Bill Date", field: "BillDate", editable: 'never' },
                { title: "Patient Name", field: "PatientName", editable: 'never' },
                { title: "Age", field: "age", editable: 'never' },
                { title: "Gender", field: "gender", editable: 'never' },
                { title: "Admitting Physician", field: "OrderingPhysician", editable: 'never' },
                { title: "Specialisation", field: "Specialisation", editable: 'never' },
                { title: "Admit Group", field: "admitGroup", editable: 'never' },
                { title: "Admission Purpose", field: "admissionPurpose", editable: 'never' },
                // { title: "Admission Date", field: "AdmissionDate", editable: 'never' },
                // { title: "Discharge Date", field: "DischargeDate", editable: 'never' },
                { title: "LOS(InDays)", field: "LOS", editable: 'never' },
                {
                  title: "Item Group", field: "ItemGroupName", editable: 'never', lookup: {
                    "IP Physician Consulting": "IP Physician Consulting",
                    "OT Surgeon Charges": "OT Surgeon Charges",
                    "Radiology": "Radiology",
                    "Non OT Procedures": "Non OT Procedures",
                    "Anesthesia Services": "Anesthesia Services",
                    "Other Charges": "Other Charges",
                    "Pharmacy": "Pharmacy",
                    "Lab": "Lab",
                    "Registration Charge": "Registration Charge",
                    "Care Team": "Care Team",
                    "Procedure Charge": "Procedure Charge",
                    "Bed Charges": "Bed Charges",
                  }
                },
                { title: "Item Name", field: "ItemName", editable: 'never' },
                { title: "Bed Category", field: "bedCategory", editable: 'never' },
                { title: "Bill Type", field: "billType", editable: 'never' },
                { title: "Package Name", field: "packageName", editable: 'never' },
                { title: "Package Amount", field: "PackageAmount", editable: 'never', render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0 },
                { title: "Category", field: "Category", editable: 'never' },
                { title: "TPA", field: "tpaName", editable: 'never' },
                { title: "Insurance", field: "insurance", editable: 'never' },
                { title: "Corporate Name", field: "organisation", editable: 'never' },
                { title: "User", field: "User", editable: 'never' },
                // { title: "Month", field: "month", editable: 'never' },
                { title: "Category", field: "ipGroup", editable: 'never' },
                { title: "Sub Category", field: "ipSubGroup", editable: 'never' },
                // { title: "Room Category", field: "", editable: 'never' },
                {
                  title: "ERP LOS", field: "erpLos", editable: 'never',
                  lookup: {
                    "Gynec": "Gynec",
                    "Delivery": "Delivery",
                    "Pead": "Pead",
                    "Fertility": "Fertility",
                  },
                },
                // { title: "Package Amount", field: "PackageAmount", editable: 'never', render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0 },
                { title: "Pharmacy", field: "PharmacyTotal", editable: 'never', render: (row) => row.PharmacyTotal ? numberWithCommas(parseFloat(row.PharmacyTotal).toFixed()) : 0 },
                { title: "Discount", field: "Discount", editable: 'never' },
                { title: "Lab", field: "Lab", editable: 'never', render: (row) => row.Lab ? numberWithCommas(parseFloat(row.Lab).toFixed()) : 0 },
                { title: "Radiology", field: "Radiology", editable: 'never', render: (row) => row.Radiology ? numberWithCommas(parseFloat(row.Radiology).toFixed()) : 0 },
                { title: "Other Deduction", field: "otherDeduction", editable: 'never', render: (row) => row.otherDeduction ? numberWithCommas(parseFloat(row.otherDeduction).toFixed()) : 0 },
                { title: "Epidural Charges", field: "epiduralCharges", editable: 'never', render: (row) => row.epiduralCharges ? numberWithCommas(parseFloat(row.epiduralCharges).toFixed()) : 0 },
                { title: "Safety Consumable", field: "safetyConsumable", editable: 'never', render: (row) => row.safetyConsumable ? numberWithCommas(parseFloat(row.safetyConsumable).toFixed()) : 0 },
                { title: "Net Bill (Bill Report)", field: "Gross", editable: 'never', render: (row) => row.Gross ? numberWithCommas(parseFloat(row.Gross).toFixed()) : 0 },
                { title: "Net Bill (Calculated)", field: "Net", editable: 'never', render: (row) => row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0 },
                { title: "Payable/Non Payable", field: "PNP", editable: 'never', render: (row) => row.PNP == 1 ? 'Payable' : 'Non Payable' },
                { title: "Doctor Name", field: "ConductedBy", validate: rowData => rowData.ConductedBy !== '' && rowData.ConductedBy !== null ? "" : "Doctor Name cannot be empty" },
                { title: "Doctor LTID", field: "CONDUCTED_BY_LOGIN_ID", validate: rowData => rowData.CONDUCTED_BY_LOGIN_ID !== '' && rowData.CONDUCTED_BY_LOGIN_ID !== null ? "" : "Doctor LTID cannot be empty" },
                {
                  title: "Nature", field: "NatureofDoctors",
                  lookup: {
                    "Surgeon Fee": "Surgeon Fee",
                    "Anesthesia Services": "Anesthesia Services",
                    "IP Visit Fee": "IP Visit Fee",
                    "Radiology Fee": "Radiology Fee",
                    "NICU": "NICU",
                    "Additional Procedure": "Additional Procedure",
                  },
                },
                { title: "Terms", field: "commercialTerm" },
                { title: "Qty", field: "Count" },
                { title: "Amount", field: "doctorAmount" },
                // { title: "DoctorFeeinChargePerunit", field: "" },
                { title: "Ratio", field: "Ratio" },
                { title: "Dr Fee ", field: "doctorFee", type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                { title: "DP Team Remarks ", field: "Concatenate", validate: rowData => rowData.Concatenate !== '' && rowData.Concatenate !== null ? "" : "Remarks cannot be empty" },
                { title: "Exceptional Issue", field: "vlookup", editable: 'never' },
              ]}
              data={initiateCalc}
              actions={[
                {
                  icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                  tooltip: "Export to Excel",
                  onClick: () => downloadExcel(),
                  isFreeAction: true
                }
              ]}
              editable={
                (payoutDataStatus && payoutDataStatus.nextAction && ((payoutDataStatus.nextAction == payoutState.ch_approval.state && user.user.activityAssigments.filter((elem) => elem.activityId === 12).find(item => item.RoleId === user.user.roles))) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.nextAction == payoutState.init.state)))) ? {
                  // onRowAdd: newData =>
                  //   new Promise((resolve, reject) => {
                  //     setTimeout(() => {
                  //       addBillItemData(newData)
                  //       setOboardVal([...oboardVal, newData]);
                  //       getRecord("IP", "normal")
                  //       resolve();
                  //     }, 1000)
                  //   }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataUpdate = [...oboardVal];
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;
                        console.log(oldData, newData, 'exception item update')
                        if (newData.Concatenate == '') {
                          setConfirmDialog({
                            isOpen: true,
                            title: "Alert",
                            subtitle: "Please Add Remarks",
                          });
                          reject()
                          return;
                        } else {
                          updateBillItemData(newData, oldData)
                          setOboardVal([...dataUpdate]);
                          resolve();
                        }

                      }, 1000)
                    }),
                  // onRowDelete: oldData =>
                  //   new Promise((resolve, reject) => {
                  //     setTimeout(() => {
                  //       const dataDelete = [...oboardVal];
                  //       const index = oldData.tableData.id;
                  //       dataDelete.splice(index, 1);
                  //       deleteBillItemData(oldData);
                  //       setOboardVal([...dataDelete]);

                  //       resolve();
                  //     }, 1000)
                  //   }),
                } : {}}
              options={{
                sorting: true,
                fixedColumns: {
                  top: 1,
                  right: 0
                },
                maxBodyHeight: '500px',
                filtering: true,
                exportButton: false,
                // exportAllData: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              icons={{
                Filter: "filter",
              }}
              title="IP-Payout"
            />
            {/* {user.user.activityAssigments.filter((elem) => elem.activityId === 11).find(item => item.RoleId === user.user.roles) ?
              payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.nextAction == payoutState.init.state) ?
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <Button
                    style={{ marginLeft: "10px" }}
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => sendToCH('IP')}
                  >
                    Send to center head
                  </Button>
                </div>
                : ""
              : ""} */}
            <MaterialTable
              columns={[
                { title: "SlNo", field: "SlNo" },
                { title: "MPI", field: "MPI" },
                { title: "IPNo", field: "IPNo" },
                { title: "Bill No", field: "BillNo" },
                { title: "Bill Date", field: "BillDate" },
                { title: "Patient Name", field: "PatientName" },
                { title: "Age", field: "Age" },
                { title: "Gender", field: "Gender" },
                { title: "Admitting Physician", field: "AdmittingPhysician" },
                { title: "Specialisation", field: "Specialisation" },
                { title: "Admit Group", field: "AdmitGroup" },
                { title: "Admission Purpose", field: "AdmissionPurpose" },
                { title: "Admission Date", field: "AdmissionDate" },
                { title: "Discharge Date", field: "DischargeDate" },
                { title: "LOS(InDays)", field: "LOSInDays" },
                { title: "Bed Category", field: "BedCategory" },
                { title: "Bill Type", field: "BillType" },
                { title: "Package Name", field: "PackageName" },
                { title: "Package Amount", field: "PackageAmount", render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0 },
                { title: "Category", field: "Category" },
                { title: "TPA", field: "TPA" },
                { title: "Insurance", field: "Insurance" },
                { title: "Corporate Name", field: "CorporateName" },
                { title: "User", field: "User" },
                { title: "Month", field: "month" },
                { title: "Category", field: "ipGroup" },
                { title: "Sub Category", field: "ipSubGroup" },
                { title: "Room Category", field: "" },
                {
                  title: "ERP LOS", field: "erpLos",
                  lookup: {
                    "Gynec": "Gynec",
                    "Delivery": "Delivery",
                    "Pead": "Pead",
                    "Fertility": "Fertility",
                  },
                },
                { title: "Package Amount", field: "PackageAmount", render: (row) => row.PackageAmount ? numberWithCommas(parseFloat(row.PackageAmount).toFixed()) : 0 },
                { title: "Pharmacy", field: "PharmacyTotal", render: (row) => row.PharmacyTotal ? numberWithCommas(parseFloat(row.PharmacyTotal).toFixed()) : 0 },
                { title: "Discount", field: "Discount" },
                { title: "Lab", field: "Lab", render: (row) => row.Lab ? numberWithCommas(parseFloat(row.Lab).toFixed()) : 0 },
                { title: "Radiology", field: "Radiology", render: (row) => row.Radiology ? numberWithCommas(parseFloat(row.Radiology).toFixed()) : 0 },
                { title: "Other Deduction", field: "otherDeduction", render: (row) => row.otherDeduction ? numberWithCommas(parseFloat(row.otherDeduction).toFixed()) : 0 },
                { title: "Net Bill", field: "Net", render: (row) => row.Net ? numberWithCommas(parseFloat(row.Net).toFixed()) : 0 },
                { title: "Doctor Name", field: "conductingDoctor" },
                { title: "Nature", field: "conductingNature" },
                { title: "Terms", field: "conductingTerm" },
                { title: "Qty", field: "Qty" },
                { title: "Amount", field: "amount" },
                { title: "DoctorFeeinChargePerunit", field: "" },
                { title: "Ratio", field: "ratio" },
                { title: "Dr Fee ", field: "doctorFee", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0 },
                { title: "Exceptional Issue", field: "exceptionIssue" },
              ]}
              data={initiateCalc.filter(elem => elem.exceptionIssue != '')}
              options={{
                sorting: true,
                fixedColumns: {
                  top: 1,
                  right: 0
                },
                maxBodyHeight: '500px',
                filtering: true,
                exportButton: false,
                // exportAllData: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              icons={{
                Filter: "filter",
              }}
              title="IP-Payout Exceptional Cases"
            />
          </>
        ) : (
          ""
        )}
      </Container>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Edit Doctor Details
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Referal Fee</b>
          </label>
          <TextField
            id="Payout"
            label="Referal Fee"
            variant="outlined"
            fullWidth
            name="referalFee"
            value={rowDetail.referalFee}
            onChange={handleInputChange}
            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleReferalFeeEdit}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setSplitDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={splitDialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          {spitRecord.ConductedBy}
        </DialogTitle>
        <Grid container>
          <Grid item xs={12} xl={6} md={6} lg={6} sm={12}>
            <DialogContent dividers>
              <Typography>MPI: {spitRecord.MPI}</Typography>
              <Typography>BillDate: {spitRecord.BillDate}</Typography>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Doctor Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="doctorname"
                  value={rowDetail.doctorname}
                  onChange={handleInputChange}
                  label="  Select Doctor Name"
                >
                  {doctorList &&
                    doctorList.map((opt) => (
                      <MenuItem key={opt.id} value={opt.name}>
                        {opt.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <label>
                <b>Ratio</b>
              </label>
              <TextField
                id="Payout"
                label="Ratio"
                variant="outlined"
                fullWidth
                name="ratio"
                value={rowDetail.ratio}
                onChange={handleInputChange}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
              />
            </DialogContent>
          </Grid>
          <Grid
            item
            xs={12}
            xl={6}
            md={6}
            lg={6}
            sm={12}
            style={{ height: "300px", overflowY: "auto" }}
          >
            <MaterialTable
              columns={[
                { title: "SlNo", field: "SlNo" },
                { title: "MPI", field: "MPI" },
                { title: "Bill No", field: "BillNo" },
                { title: "Patient Name", field: "PatientName" },
                { title: "ConductedBy", field: "ConductedBy" },
                { title: "Share", field: "Share" },
                { title: "Item Group Name", field: "ItemGroupName" },
                { title: "Item Name", field: "ItemName" },
                { title: "Gross", field: "Gross" },
                { title: "Net", field: "Net" },
              ]}
              data={splitNewRec}
              title="Split Rows"
            />
          </Grid>
        </Grid>

        <Button
          color="secondary"
          onClick={() => {
            addRowData(spitRecord);
          }}
        >
          Add
        </Button>
        <DialogActions>
          {splitNewRec && splitNewRec.length > 0 ? (
            <Button
              autoFocus
              onClick={() => {
                changeSplitRecord();
              }}
              color="secondary"
              variant="contained"
            >
              Submit
            </Button>
          ) : (
            ""
          )}

          <Button
            autoFocus
            onClick={() => {
              setSplitDialog(false);
            }}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerEditItemModalClose}
        aria-labelledby="customized-dialog-title"
        open={editItemModal}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerEditItemModalClose}
          style={{ textAlign: "center" }}
        >
          Edit Item Details
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={4} sm={6} xl={4} style={{ position: "relative" }}>
              <TextField label="Bill No" value={itemData.BillNo} style={{ marginBottom: "20px" }} fullWidth name="BillNo" variant="outlined" />
            </Grid>
            <Grid item xs={12} md={12} lg={4} sm={6} xl={4} style={{ position: "relative" }}>
              <TextField label="MPI" value={itemData.MPI} style={{ marginBottom: "20px" }} fullWidth name="MPI" variant="outlined" />
            </Grid>
            <Grid item xs={12} md={12} lg={4} sm={6} xl={4} style={{ position: "relative" }}>
              <TextField label="IP No" value={itemData.ip} style={{ marginBottom: "20px" }} fullWidth name="ip" variant="outlined" />
            </Grid>
            <Grid item xs={12} md={12} lg={4} sm={6} xl={4} style={{ position: "relative" }}>
              <TextField label="Gross" value={itemData.Gross} style={{ marginBottom: "20px" }} fullWidth name="Gross" variant="outlined" />
            </Grid>
            <Grid item xs={12} md={12} lg={4} sm={6} xl={4} style={{ position: "relative" }}>
              <TextField label="Net" value={itemData.Net} style={{ marginBottom: "20px" }} fullWidth name="Net" variant="outlined" />
            </Grid>
            <Grid item xs={12} md={12} lg={4} sm={6} xl={4} style={{ position: "relative" }}>
              <TextField label="Admitting Physician" value={itemData.OrderingPhysician} style={{ marginBottom: "20px" }} fullWidth name="OrderingPhysician" variant="outlined" />
            </Grid>
            <Grid item xs={12} md={12} lg={4} sm={6} xl={4} style={{ position: "relative" }}>
              <TextField label="Doctor" value={itemData.ConductedBy} style={{ marginBottom: "20px" }} fullWidth name="ConductedBy" variant="outlined" />
            </Grid>
            <Grid item xs={12} md={12} lg={4} sm={6} xl={4} style={{ position: "relative" }}>
              <TextField label="Ratio" value={itemData.Ratio} style={{ marginBottom: "20px" }} fullWidth name="Ratio" variant="outlined" />
            </Grid>
            <Grid item xs={12} md={12} lg={4} sm={6} xl={4} style={{ position: "relative" }}>
              <TextField label="Doctor Fee" value={itemData.doctorFee} style={{ marginBottom: "20px" }} fullWidth name="doctorFee" variant="outlined" />
            </Grid>
          </Grid>
          <label>
            <b>Referal Fee</b>
          </label>
          <TextField
            id="Payout"
            label="Referal Fee"
            variant="outlined"
            fullWidth
            name="referalFee"
            value={rowDetail.referalFee}
            onChange={handleInputChange}
            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={updateItemDetail}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
          <Button
            autoFocus
            onClick={handlerEditItemModalClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        className={backClasses.backdrop}
        open={backDropOpen}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop className={backClasses.backdrop} open={circularLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
