
import React, { useState ,useEffect} from 'react'
import { Modal, Button, Container, Row, Col, Card } from 'react-bootstrap'
import BillingPieSummary from '../../Components/BillingPieSummary'
import userImg from './../../images/user.png'
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Navbar from '../../Components/Navbar'
import FinanceSidebar from "../../Components/FinanceSidebar";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { listusers } from "../../actions/userActions";
import Profiles from "../../Components/Profile" 
import { APIURL } from "../../CommomMethods/Fetch";
function FinanceProfile({ reqTableData }) {
    const [newVis, setNewVis] = useState("hiddenDiv")
    const [ongVis, setOngVis] = useState("hiddenDiv")
    const [compVis, setCompVis] = useState("hiddenDiv")
    const [photoval, setphotoValue] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalShow, setModalShow] = React.useState(false);
    const labelStyle = { paddingBottom: "5px", textDecoration: "underline", color: "black" };
    const infoStyle={padding:"5px", border:"2px solid rgb(133, 133, 133, 0.4)",fontWeight:"lighter", borderRadius:"5px"};
    // const percentage = 66;
    // const info = "Clearing Exceptions";
    const dispatch = useDispatch();
    const [filename, setFilename] = useState("");
    const [photo, setphoto] = useState();
    const [filePath, setfilePath] = useState();
    const [refresh, setRefresh] = useState(false);
    const [status, setStatus] = useState(false);

    const userLogin = useSelector((state) => state.userLogin);
    const { loading, error, user } = userLogin;

    const userList = useSelector((state) => state.userList);
    const { users } = userList;
    useEffect(() => {
        dispatch(listusers());
      }, [dispatch]);

    const cardStyle = {
        background: 'transparent',
        border: '0px',
    }
    // const infoStyle = {
    //     border: '2px solid #858585',
    // }
    const containerStyle = {
        margin: '0px',
    }
    const subTitle = {
        fontSize: "13px"
    }
    const percentage = 66;
    const info = "Clearing Exceptions";

    function tabChange(stat) {
        if (stat == "new") {
            if (newVis == "hiddenDiv") {
                setNewVis("visibleDiv")
            }
            else {
                setNewVis("hiddenDiv")
            }

        }
    }

    const [datatable, setDatatable] = React.useState({
        columns: [
            {
                label: 'RequestId',
                field: 'requestId',
                width: 100,
            },
            {
                label: 'Request Type',
                field: 'requestType',
                width: 100,
            },
            {
                label: 'Request Raised By',
                field: 'requestRaisedBy',
                sort: 'asc',
                width: 100,
            },
            {
                label: 'Date',
                field: 'date',
                sort: 'disabled',
                width: 100,
            },
            {
                label: 'Subject',
                field: 'subject',
                sort: 'disabled',
                width: 100,
            },
        ],

        rows: reqTableData

    });
    const PhotoUpload = (e) => {
        setphoto(e.target.files[0]);
        setfilePath(e.target.value);
        setFilename(e.target.files[0].name);
      };

      const handlePhoto = (e) => {
        e.preventDefault();
        setStatus(false);
        // var Photo = document.getElementById("photo").innerHTML;
        const formData = new FormData();
        // formData.append("docTitle", Photo);
        formData.append("docValue", photoval);
        formData.append("doctorId", user.user.id);
        formData.append("docFile", photo);
        formData.append("name", filename);
        formData.append("url", filePath);
        setRefresh(false);
        
        axios
      .post(APIURL() + "users/uploadPhoto", formData)
          .then(function (response) {
            setRefresh(true);
            if (response.data.message == "Invalid File") {
              toast.error(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (response.data.message == "No File uploaded") {
              toast.warning(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else if (response.data.message == "Successfully Uploaded") {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            setStatus(true);
            toast.success("Success");
            
          });
      };

    return (
        <div className="profilewrapper" style={{ height:"100vh"}}>

            <FinanceSidebar />
            <div className="main-content side-content pt-0">
                <div className="container-fluid">
                    <div className="inner-body">

                        <Navbar />
                        <br/>
                        <br/>
            <Profiles />
                        {/* <div className="col-xl-12 col-md-12 pt-4 profilestyles" >
                            <Row >
                                <Col xs={3} md={3}>
                                <img class="img-responsive" src={"https://thumbs.dreamstime.com/b/doctor-holding-digital-tablet-meeting-room-portrait-beautiful-mature-woman-looking-camera-confident-female-using-164999229.jpg"} style={{ borderRadius: "5px", margin: "1rem", width: "600px" }} />
                                    <input
                                        type="file"
                                        accept="jpeg,jpg,png"
                                        onChange={PhotoUpload}
                                        id={10}
                                        name="photo"
                                        className="form-control"
                                    /><span><i className="fas fa-upload" onClick={handlePhoto} /></span>
                                </Col>
                                
                                <Col xs={8} md={{ span: 8, offset: 1 }}>
                                    <Row>
                                        <Col xs={6} md={6} style={{ padding: "10px" }}>
                                            <h6 >Name</h6>
                                            <h6 style={infoStyle}>{users&& users[0] && users[0].name}</h6>
                                        </Col>
                                        <Col xs={6} md={6} style={{ padding: "10px" }}>
                                            <h6 >ID</h6>
                                            <h6 style={infoStyle}>{users&& users[0] && users[0].id}</h6>
                                        </Col>
                                        <Col xs={6} md={6} style={{ padding: "10px" }}>
                                            <h6 >DOB</h6>
                                            <h6 style={infoStyle}>{users&& users[0] && users[0].dob}</h6>
                                        </Col>
                                        <Col xs={6} md={6} style={{ padding: "10px" }}>
                                            <h6 >Official Email</h6>
                                            <h6 style={infoStyle}>{users&& users[0] && users[0].email}</h6>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
            
                      

                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinanceProfile
