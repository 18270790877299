import React, { useState, useEffect } from "react";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  FormControlLabel,
  Paper,
  RadioGroup,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import axios from "axios";
import { APIURL } from "../../../../CommomMethods/Fetch";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
const styleSheet = makeStyles((theme) => ({
  paperStyle: { marginTop: "10px" },
  textFieldstyle: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  btnStyle: {
    marginRight: "80px",
    marginLeft: "auto",
    display: "block",
    marginBottom: "20px",
  },
}));
const stylesmodal = makeStyles({
  root: {
    marginTop: "18px",
  },
});
export default function AddCommercials({
  addDepartment,
  regionRows,
  currentDepartment,
}) {
  const style = styleSheet();
  const styleClasses = stylesmodal();
  const [department, setDepartment] = useState(currentDepartment);
  const [value, setValue] = React.useState("1");
  const [doclist, setDoclist] = React.useState("");
  const [doctorvalue, setDoctorValue] = React.useState("Yes");
  const [doctor, setDoctor] = React.useState("Select Doctor");
  const [centers, setCenter] = useState("Select Center");
  const [selectid, setSelectId] = useState(0);
  const [from, setFrom] = React.useState(new Date());
  const [to, setTo] = React.useState(new Date());
  console.log(doctorvalue, "doctorvalue");

  function selectedCenter(e) {
    const { name, value } = e.target;
    setCenter(value);
    department.region = value;
  }

  function selectedDoctor(e, key) {
    const { name, value } = e.target;
    setDoctor({ ...doctor, [name]: value.doctorName });
    // setDoctor(e.target.value);
    setSelectId(e.target.value[1]);
    department.doctorName = e.target.value.doctorName;
    department.doctorId = e.target.value.doctorId;
  }
  console.log(selectid, "selectid");

  const handleDoctorChange = (event) => {
    var Val = "";
    setDoctorValue(event.target.value);
    if (event.target.value === "Yes") {
      Val = "Yes";
    } else if (event.target.value === "No") {
      Val = "No";
    }
    department.chstatus = Val;
  };

  const handleFromDate = (a) => {
    setFrom(a);
    department.from = a;
  };
  const handleToDate = (a) => {
    setTo(a);
    department.to = a;
  };

  useEffect(() => {
    console.log(doctorvalue, "department.chstatus");
    if (doctorvalue == "No") {
      axios
        .get(APIURL() + "opparameters/finddoclist", { params: { center: centers } })
        .then((res) => res.data)
        .then((rows) => {
          console.log(rows, "setDoclist");
          setDoclist(rows.data);
        });
    }

  }, [centers, doctorvalue]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartment({ ...department, [name]: value });
  };

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          department.status = value;
          department.chstatus = doctorvalue;
          addDepartment(department);
        }}
      >
        <Paper className={style.paperStyle}>
          <Grid container spacing={1}>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl variant="outlined" style={{ width: "100%", marginTop: "20px" }}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={centers}
                  onChange={selectedCenter}
                  label=" Filter By Center"
                  fullWidth
                >
                  <MenuItem value="Select the Center">
                    Filter By Center
                        </MenuItem>
                  <MenuItem value="NORTH">NORTH</MenuItem>
                  <MenuItem value="SOUTH">SOUTH</MenuItem>
                  <MenuItem value="WEST">WEST</MenuItem>

                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {doctorvalue == "No" ?
                <FormControl variant="outlined" style={{ width: "100%", marginTop: "25px" }}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="doctorName"
                    // value={selectid}
                    onChange={(e, key) => { selectedDoctor(e, key) }}
                    label="Doctor Names"
                    fullWidth
                  >
                    <MenuItem value="Select the Center">
                      Doctor Names
                  </MenuItem>
                    {doclist.length > 0 && doclist.map((ele) => {
                      return (<MenuItem key={ele.doctorId} eventKey={[ele.doctorId, ele.doctorName]} value={ele}>{ele.doctorName}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
                :
                <TextField
                  className={style.textFieldstyle}
                  variant="outlined"
                  name="doctorName"
                  label="Doctor Name"
                  value={department.doctorName}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              }
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="natureofdoc"
                label="Doctor Type"
                value={department.natureofdoc}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="itemGroup"
                label="Item Group"
                value={department.itemGroup}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="itemName"
                label="Item Name"
                value={department.itemName}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="fixedamount"
                label="Fixed Amount"
                value={department.fixedamount}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="applicable"
                label="Applicable On"
                value={department.applicable}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="fixedpercentage"
                label="Fixed %"
                value={department.fixedpercentage}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  autoOk
                  label="From"
                  value={from}
                  className={styleClasses.root}
                  onChange={handleFromDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>


            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-dialog"
                  autoOk
                  label="To"
                  // disablePast
                  value={to}
                  className={styleClasses.root}
                  onChange={handleToDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
              {/* <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="Center"
                label="Center Name"
                value={department.Center}
                onChange={handleInputChange}
                required
                fullWidth
              /> */}

            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {centers !== "Select Center" ?
                <FormControl component="fieldset">
                  <FormLabel component="legend">New Doctor?</FormLabel>
                  <RadioGroup
                    row
                    aria-label="Doctor"
                    name="chstatus"
                    value={doctorvalue}
                    onChange={handleDoctorChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio color="#fd7e14" />}
                      label="Yes"
                      checked={doctorvalue == "Yes" ? true : false}
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio color="#fd7e14" />}
                      label="No"
                      checked={doctorvalue == "No" ? true : false}
                    />
                  </RadioGroup>
                </FormControl>
                : ""}
            </Grid>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={style.btnStyle}
            >
              Add
            </Button>
          </Grid>
        </Paper>
      </form>
    </>
  );
}
