import React from "react";
// material-table component
import MaterialTable from "material-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { numberWithCommas } from "../../../../actions/createPositionActions";

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

export default function ListIpItemMaster({ rows, editRow, deleteDepartment }) {
  const tables = myTableStyle();
  return (
    <>
      <Paper className={tables.root}>
        <MaterialTable
          columns={[
            { title: "Package Name", field: "name" },
            { title: "los", field: "los" },
            { title: "Room Type", field: "roomType" },
            { title: "Doctor Type", field: "actorType" },
            { title: "Amount", field: "actorAmount",render: (row)=> row.actorAmount ? "Rs. "+numberWithCommas(row.actorAmount) : ""  },
            { title: "Total Amount", field: "totalAmount",render: (row)=> row.totalAmount ? "Rs. "+numberWithCommas(row.totalAmount) : ""  },
            { title: "Center", field: "center" },
            { title: "region", field: "region" },
          ]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit",
              iconProps: { style: { fontSize: "24px", color: "#5f5fe7" } },
              onClick: (event, row) => editRow(row),
            },
            {
              icon: "delete",
              tooltip: "Delete",
              iconProps: { style: { fontSize: "24px", color: "#f55151" } },
              onClick: (event, row) => {
                deleteDepartment(row);
              },
            },
          ]}
          data={rows}
          options={{
            // filtering: true,
            sorting: true,
            exportButton: true,
            exportAllData: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 50, 100, 200, 500],
            search: true,
          }}
          title="Ip Package"
        />
      </Paper>
    </>
  );
}
