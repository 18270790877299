import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";
//import image
import profileImg from "./../../images/profile.jpg";
import Navbar from "../../Components/Navbar";
import { documentlist } from "../../actions/documentActions";
import DoctorSidebar from "../../Components/DoctorSidebar";
import Timeline from "../../Components/Timeline";

import { agreementviewById } from "../../actions/agreementActions";
import { agreementState, onboardState } from "../../CommomMethods/States";
// import Navbar from "../../Components/Navbar";
import CeoSidebar from "../../Components/CeoSidebar";

//import from mui
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { onboardById } from "../../actions/onboardActions";
import { useLocation } from "react-router-dom";
import { dateFormatter } from "../../actions/createPositionActions";
//material table
const myTableStyle = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "20%",
        marginTop: theme.spacing(3),
        "& td": {
            fontFamili: "Roboto !important",
        },
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflow: "auto",
        marginBottom: "50px",
    },
}));

export default function UserProfileDetail(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const { loading, error, user } = userLogin;
    // const location = useLocation();
    useEffect(() => {
        dispatch(onboardById(location && location.state && location.state.id));
    }, [dispatch]);

    useEffect(() => {
        dispatch(documentlist(location && location.state && location.state.id));
    }, [dispatch]);

    const onboardId = useSelector((state) => state.onboardId);
    const { oboard } = onboardId;

    return (
        <>
            <CeoSidebar />
            <div className="main-content side-content pt-0">
                <div
                    className="container-fluid"
                    style={{ margin: "0 auto", backgroundColor: "white" }}
                >
                    <div className="inner-body">
                        <Navbar />

                        <Grid container spacing={2} style={{ marginTop: "5px" }}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Paper elevation={24} style={{ padding: "20px" }}>
                                    <img
                                        src={profileImg}
                                        style={{
                                            width: "100%",
                                            objectFit: "contain",
                                            margin: "0 auto",
                                            display: "block",
                                        }}
                                    />
                                    <Typography style={{ textAlign: "center", marginTop: "4px" }}>
                                        {oboard.doctorCommercial &&
                                            oboard.doctorCommercial[0].designationlist}
                                    </Typography>
                                    <Typography variant="h6" style={{ textAlign: "center" }}>
                                        Dr.{" "}
                                        {oboard.doctorCommercial &&
                                            oboard.doctorCommercial[0].fullname}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ textAlign: "center" }}
                                    >
                                        Post Doctoral Fellow - Graduate Institute, Geneva,
                                        Switzerland
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ textAlign: "center" }}
                                    >
                                        M.Phil &amp; Ph.D. - Tata Institute of Social Sciences,
                                        Mumbai
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Paper
                                            elevation={24}
                                            style={{ height: "260px", padding: "20px" }}
                                        >
                                            <Typography variant="h5" style={{ textAlign: "center" }}>
                                                CONTACT
                                            </Typography>
                                            <Typography
                                                variant="overline"
                                                display="block"
                                                style={{ textAlign: "center" }}
                                            >
                                                Email :{" "}
                                                {oboard.doctorCommercial &&
                                                    oboard.doctorCommercial[0].email}
                                            </Typography>
                                            <Typography
                                                variant="overline"
                                                display="block"
                                                style={{ textAlign: "center" }}
                                            >
                                                Phone :{" "}
                                                {oboard.doctorCommercial &&
                                                    oboard.doctorCommercial[0].mobile}
                                            </Typography>{" "}
                                            <Typography
                                                variant="overline"
                                                display="block"
                                                style={{ textAlign: "center" }}
                                            >
                                                Permanent Addr :{" "}
                                                {oboard.doctorCommercial &&
                                                    oboard.doctorCommercial[0].PermanentAddress}
                                            </Typography>
                                            <Typography
                                                variant="overline"
                                                display="block"
                                                style={{ textAlign: "center" }}
                                            >
                                                Present Addr :{" "}
                                                {oboard.doctorCommercial &&
                                                    oboard.doctorCommercial[0].Address}
                                            </Typography>
                                            <Typography
                                                variant="overline"
                                                display="block"
                                                style={{ textAlign: "center" }}
                                            >
                                                pin code :{" "}
                                                {oboard.doctorCommercial &&
                                                    oboard.doctorCommercial[0].PinCode}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Paper
                                            elevation={24}
                                            style={{ height: "260px", padding: "20px" }}
                                        >
                                            <Typography variant="h5" style={{ textAlign: "center" }}>
                                                PERSONAL DETAIL
                                            </Typography>
                                            <Typography
                                                variant="overline"
                                                display="block"
                                                style={{ textAlign: "center" }}
                                            >
                                                Aadhaar :{" "}
                                                {oboard.doctorCommercial &&
                                                    oboard.doctorCommercial[0].aadhaarno}
                                            </Typography>
                                            <Typography
                                                variant="overline"
                                                display="block"
                                                style={{ textAlign: "center" }}
                                            >
                                                Pan Card :{" "}
                                                {oboard.doctorCommercial &&
                                                    oboard.doctorCommercial[0].pancardno}
                                            </Typography>
                                            <Typography
                                                variant="overline"
                                                display="block"
                                                style={{ textAlign: "center" }}
                                            >
                                                DOB :{" "}
                                                {oboard.doctorCommercial &&
                                                    oboard.doctorCommercial[0].birthday}
                                            </Typography>
                                            <Typography
                                                variant="overline"
                                                display="block"
                                                style={{ textAlign: "center" }}
                                            >
                                                DOJ :{" "}
                                                {oboard.doctorCommercial &&
                                                    oboard.doctorCommercial[0].doj}
                                            </Typography>
                                        </Paper>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Paper
                                            elevation={24}
                                            style={{ height: "260px", padding: "20px" }}
                                        >
                                            <Typography variant="h5" style={{ textAlign: "center" }}>
                                                BANK DETAIL
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                style={{ textAlign: "center" }}
                                            ></Typography>
                                            <Typography
                                                variant="overline"
                                                display="block"
                                                style={{ textAlign: "center" }}
                                            >
                                                BANk name :
                                                {oboard.doctorCommercial &&
                                                    oboard.doctorCommercial[0].BankName}
                                            </Typography>
                                            <Typography
                                                variant="overline"
                                                display="block"
                                                style={{ textAlign: "center" }}
                                            >
                                                ifsc :
                                                {oboard.doctorCommercial &&
                                                    oboard.doctorCommercial[0].ifsccode}
                                            </Typography>
                                            <Typography
                                                variant="overline"
                                                display="block"
                                                style={{ textAlign: "center" }}
                                            >
                                                account no :
                                                {oboard.doctorCommercial &&
                                                    oboard.doctorCommercial[0].bankaccountno}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Paper
                                            elevation={24}
                                            style={{ height: "260px", padding: "10px" }}
                                        >
                                            <Typography variant="h5" style={{ textAlign: "center" }}>
                                                OTHER DETAILS
                                            </Typography>

                                            <Typography
                                                variant="overline"
                                                display="block"
                                                style={{ textAlign: "center" }}
                                            >
                                                Indemnity Exp :
                                                {oboard.doctorCommercial &&
                                                    oboard.doctorCommercial[0].indemnityexpiry}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography
                                    variant="h4"
                                    gutterBottom
                                    align="center"
                                    style={{
                                        color: "#00963f",
                                        paddingTop: "20px",
                                        borderBottom: "1px dashed rgb(0 150 63 / 52%)",
                                        paddingBottom: "20px",
                                    }}
                                >
                                    {"Onboard Information"}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: "5px" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Paper
                                    elevation={24}
                                    style={{ height: "520px", padding: "10px" }}
                                >
                                    {/* <Typography variant="h5" style={{ textAlign: "center" }}>
                DOCUMENT APPROVAL STATUS
              </Typography> */}

                                    <MaterialTable
                                        columns={[
                                            {
                                                title: "Action",
                                                field: "currentAction",
                                                filtering: false,
                                                render: (row) => (
                                                    <div style={{ width: "140px" }}>
                                                        {row.currentAction === onboardState.init.state ? (
                                                            <span style={{ color: "red" }}>
                                                                {" "}
                                                                {onboardState.init.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            onboardState.upload.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.upload.message}
                                                            </span>
                                                        ) : row.currentAction === onboardState.hr.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.hr.message}
                                                            </span>
                                                        ) : row.currentAction === onboardState.ch.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.ch.message}
                                                            </span>
                                                        ) : row.currentAction === onboardState.rd.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.rd.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            onboardState.done.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.done.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            onboardState.ch_doc.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.ch_doc.message}
                                                            </span>
                                                        ) : row.currentAction === onboardState.completed ? (
                                                            <span style={{ color: "blue" }}>"COMPLETED"</span>
                                                        ) : row.currentAction ===
                                                            onboardState.ch_renew.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.ch_renew.message}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                ),
                                            },
                                            { title: "Date", field: "createdAt", render: (row) => (<div>{dateFormatter(row.createdAt)}</div>) },
                                            {
                                                title: "Next Action",
                                                field: "nextAction",
                                                filtering: false,
                                                render: (row) => (
                                                    <div style={{ width: "140px" }}>
                                                        {row.nextAction === onboardState.init.state ? (
                                                            <span style={{ color: "red" }}>
                                                                {" "}
                                                                {onboardState.init.message}
                                                            </span>
                                                        ) : row.nextAction === onboardState.upload.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.upload.message}
                                                            </span>
                                                        ) : row.nextAction === onboardState.hr.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.hr.message}
                                                            </span>
                                                        ) : row.nextAction === onboardState.ch.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.ch.message}
                                                            </span>
                                                        ) : row.nextAction === onboardState.rd.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.rd.message}
                                                            </span>
                                                        ) : row.nextAction === onboardState.done.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.done.message}
                                                            </span>
                                                        ) : row.nextAction === onboardState.ch_doc.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.ch_doc.message}
                                                            </span>
                                                        ) : row.nextAction === onboardState.completed ? (
                                                            <span style={{ color: "blue" }}>"COMPLETED"</span>
                                                        ) : row.nextAction ===
                                                            onboardState.ch_renew.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.ch_renew.message}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        data={oboard.onBoardData}
                                        options={{
                                            sorting: true,
                                            exportButton: false,
                                            pageSize: 5,
                                            pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                            search: false,
                                        }}
                                        title="DOCUMENT APPROVAL STATUS"
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Paper
                                    elevation={24}
                                    style={{ height: "520px", padding: "10px" }}
                                >
                                    {/* <Typography variant="h5" style={{ textAlign: "center" }}>
                AGREEMENT APPROVAL STATUS
              </Typography> */}
                                    <MaterialTable
                                        columns={[
                                            {
                                                title: "Action",
                                                field: "currentAction",
                                                filtering: false,
                                                render: (row) => (
                                                    <div style={{ width: "140px" }}>
                                                        {row.currentAction === agreementState.init.state ? (
                                                            <span style={{ color: "red" }}>
                                                                {" "}
                                                                {agreementState.init.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            agreementState.rd_revision.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.rd_revision.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            agreementState.hr_revision.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.hr_revision.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            agreementState.dr_accepted.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.dr_accepted.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            agreementState.hr_accepted.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.hr_accepted.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            agreementState.ch_approved.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.ch_approved.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            agreementState.ceo_approved.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.ceo_approved.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            agreementState.rd_approved.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.rd_approved.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            agreementState.dr_decline.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.dr_decline.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            agreementState.ch_decline.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.ch_decline.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            agreementState.rd_decline.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.rd_decline.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            agreementState.hr_decline.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.hr_decline.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            agreementState.completed ? (
                                                            <span style={{ color: "blue" }}>"COMPLETED"</span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                ),
                                            },

                                            { title: "Date", field: "createdAt", render: (row) => (<div>{dateFormatter(row.createdAt)}</div>) },
                                            {
                                                title: "Next Action",
                                                field: "nextAction",
                                                filtering: false,
                                                render: (row) => (
                                                    <div style={{ width: "140px" }}>
                                                        {row.nextAction === agreementState.init.state ? (
                                                            <span style={{ color: "red" }}>
                                                                {" "}
                                                                {agreementState.init.message}
                                                            </span>
                                                        ) : row.nextAction ===
                                                            agreementState.rd_revision.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.rd_revision.message}
                                                            </span>
                                                        ) : row.nextAction ===
                                                            agreementState.hr_revision.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.hr_revision.message}
                                                            </span>
                                                        ) : row.nextAction ===
                                                            agreementState.dr_accepted.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.dr_accepted.message}
                                                            </span>
                                                        ) : row.nextAction ===
                                                            agreementState.hr_accepted.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.hr_accepted.message}
                                                            </span>
                                                        ) : row.nextAction ===
                                                            agreementState.ch_approved.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.ch_approved.message}
                                                            </span>
                                                        ) : row.nextAction ===
                                                            agreementState.ceo_approved.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.ceo_approved.message}
                                                            </span>
                                                        ) : row.nextAction ===
                                                            agreementState.rd_approved.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.rd_approved.message}
                                                            </span>
                                                        ) : row.nextAction ===
                                                            agreementState.dr_decline.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.dr_decline.message}
                                                            </span>
                                                        ) : row.nextAction ===
                                                            agreementState.ch_decline.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.ch_decline.message}
                                                            </span>
                                                        ) : row.nextAction ===
                                                            agreementState.rd_decline.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.rd_decline.message}
                                                            </span>
                                                        ) : row.nextAction ===
                                                            agreementState.hr_decline.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {agreementState.hr_decline.message}
                                                            </span>
                                                        ) : row.nextAction === agreementState.completed ? (
                                                            <span style={{ color: "blue" }}>"COMPLETED"</span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        data={oboard.doctorAgreement}
                                        options={{
                                            sorting: true,
                                            exportButton: false,
                                            pageSize: 5,
                                            pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                            search: false,
                                        }}
                                        title=" AGREEMENT APPROVAL STATUS"
                                    />
                                </Paper>
                            </Grid>
                        </Grid>


                        <Grid container spacing={2} style={{ marginTop: "5px" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper
                                    elevation={24}
                                    style={{ padding: "10px", width: "100%" }}
                                >
                                    {/* <Typography variant="h5" style={{ textAlign: "center" }}>
                DOCUMENT APPROVAL STATUS
              </Typography> */}

                                    <MaterialTable
                                        columns={[

                                            { title: "Item Group", field: "itemgroup" },
                                            { title: "Commercial Term", field: "commercialterm" },

                                        ]}
                                        data={oboard.doctorOpCommercial}
                                        options={{
                                            sorting: true,
                                            exportButton: false,
                                            pageSize: 5,
                                            pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                            search: false,
                                        }}
                                        title="OP COMMERCIAL"
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper
                                    elevation={24}
                                    style={{ padding: "10px", width: "100%" }}
                                >
                                    {/* <Typography variant="h5" style={{ textAlign: "center" }}>
                AGREEMENT APPROVAL STATUS
              </Typography> */}
                                    <MaterialTable
                                        columns={[


                                            { title: "Item Group", field: "itemgroup" },

                                            { title: "Room Category", field: "iproom" },
                                            { title: "MOP", field: "ipmodeofpayment" },
                                            { title: "Commercial Term", field: "commercialterm" },

                                        ]}
                                        data={oboard.doctorIpCommercial}
                                        options={{
                                            sorting: true,
                                            exportButton: false,
                                            pageSize: 5,
                                            pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                            search: false,
                                        }}
                                        title=" IP COMMERCIAL"
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        <div className="col-xl-12 col-md-12 pt-4">
                            <Row>
                                <Col>
                                    <Card.Body>
                                        {user && user.user && user.user.activityAssigments.filter((elem) => elem.activityId === 8).find(item => item.RoleId === user.user.roles) ? (
                                            <>
                                                <Row style={{ margin: "1rem" }}>
                                                    <Col md={8}>
                                                        <label style={{ fontWeight: "bold" }}>
                                                            {" "}
                                                            Enter LT ID:
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            style={{ border: "1px solid grey" }}
                                                        ></input>
                                                    </Col>
                                                </Row>

                                                <Row style={{ margin: "1rem", float: "right" }}>
                                                    <button className="btn btn-primary">Confirm</button>
                                                </Row>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </Card.Body>
                                </Col>
                            </Row>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
