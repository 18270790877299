import React from "react";
import Typography from "@mui/material/Typography";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useCourseStyles = makeStyles((theme) => ({
  headcircle1: {
    cursor: "pointer",
    display: "block",
    width: "100%",
    height: "100px",
    backgroundColor: "#b50945c4",
    borderRadius: "5px",
    border: "2px solid #fff",
    marginTop: "10px",
    marginBottom: "10px",
    // float: "right",
    // marginLeft: "50px",
    boxShadow: "1px 2px 4px 0px rgba(0,0,0,0.30)",
  },

  headcircleiconwrap: {
    color: "#fff",
    opacity: "0.4",
    width: "20%",
    display: "block",
    float: "right",
    height: "100%",
  },

  headcircleicon: {
    fontSize: "80px",
    display: "block",
    margin: "5px auto",
  },

  headcircleText: {
    color: "white",
    textAlign: "center",
    marginTop: "15px",
    fontWeight: "600",
    lineHeight: "1",
    display: "block",
    float: "left",
    width: "60%",
    textTransform: "uppercase",
    letterSpacing: "2px",
    wordSpacing: "3px",
    // zIndex: "2"
  },
}));

export default function PayoutSummaryCard(props) {
  console.log(props, "card data");
  const courseClasses = useCourseStyles();
  return (
    <>
      {props.detail === "rdcenter" ? (
        <Link
          to={{
            pathname: "/CenterWisePayoutDetailsRD",
            state: { data: props.data.center },
          }}
          className="standardButton acceptButton pt-2"
          style={{ textAlign: "center" }}
        >
          <div className={courseClasses.headcircle1} id="step2">
            <div className={courseClasses.headcircleiconwrap}>
              <MenuBookIcon className={courseClasses.headcircleicon} />
            </div>
            <div className={courseClasses.headcircleText}>
              <Typography variant="h4">{props.data.DoctorCount}</Typography>
              <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
                {props.data.center}
              </Typography>
            </div>
          </div>
        </Link>
      ) : props.detail === "rddoctor" ? (
        <Link
          to={{
            pathname: "/DoctorWisePayoutDetailsRD",
            state: { data: props.data.ConductedBy },
          }}
          className="standardButton acceptButton pt-2"
          style={{ textAlign: "center" }}
        >
          <div className={courseClasses.headcircle1} id="step2">
            <div className={courseClasses.headcircleiconwrap}>
              <MenuBookIcon className={courseClasses.headcircleicon} />
            </div>
            <div className={courseClasses.headcircleText}>
              <Typography variant="h4">{props.data.DoctorCount}</Typography>
              <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
                {props.data.ConductedBy}
              </Typography>
            </div>
          </div>
        </Link>
      ) : props.detail === "doctorcenter" ? (
        <Link
          to={{
            pathname: "/CenterWisePayoutDetails",
            state: { data: props.data.center },
          }}
          className="standardButton acceptButton pt-2"
          style={{ textAlign: "center" }}
        >
          <div className={courseClasses.headcircle1} id="step2">
            <div className={courseClasses.headcircleiconwrap}>
              <MenuBookIcon className={courseClasses.headcircleicon} />
            </div>
            <div className={courseClasses.headcircleText}>
              <Typography variant="h4">{props.data.DoctorCount}</Typography>
              <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
                {props.data.center}
              </Typography>
            </div>
          </div>
        </Link>
      ) : props.detail === "doctor" ? (
        <Link
          to={{
            pathname: "/DoctorWisePayoutDetails",
            state: { data: props.data.ConductedBy },
          }}
          className="standardButton acceptButton pt-2"
          style={{ textAlign: "center" }}
        >
          <div className={courseClasses.headcircle1} id="step2">
            <div className={courseClasses.headcircleiconwrap}>
              <MenuBookIcon className={courseClasses.headcircleicon} />
            </div>
            <div className={courseClasses.headcircleText}>
              <Typography variant="h4">{props.data.DoctorCount}</Typography>
              <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
                {props.data.ConductedBy}
              </Typography>
            </div>
          </div>
        </Link>
      ) : props.detail === "chbillings" ? (
        <Link
          to={{
            pathname: "/CenterWisePayout",
            // state: { data: findpos },
          }}
          className="standardButton acceptButton pt-2"
          style={{ textAlign: "center" }}
        >
          <div className={courseClasses.headcircle1} id="step2">
            <div className={courseClasses.headcircleiconwrap}>
              <MenuBookIcon className={courseClasses.headcircleicon} />
            </div>
            <div className={courseClasses.headcircleText}>
              <Typography variant="h4">{props.data.count}</Typography>
              <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
                {props.data.name}
              </Typography>
            </div>
          </div>
        </Link>
      ) : props.detail === "chcenter" ? (
        <Link
          to={{
            pathname: "/CenterWIsePayoutDetails",
            state: { data: props.data.center },
          }}
          className="standardButton acceptButton pt-2"
          style={{ textAlign: "center" }}
        >
          <div className={courseClasses.headcircle1} id="step2">
            <div className={courseClasses.headcircleiconwrap}>
              <MenuBookIcon className={courseClasses.headcircleicon} />
            </div>
            <div className={courseClasses.headcircleText}>
              <Typography variant="h4">{props.data.DoctorCount}</Typography>
              <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
                {props.data.center }
              </Typography>
            </div>
          </div>
        </Link>
      ) : props.detail === "chdoctor" ? (
        <Link
          to={{
            pathname: "/DoctorWisePayoutDetails",
            state: { data: props.data.ConductedBy },
          }}
          className="standardButton acceptButton pt-2"
          style={{ textAlign: "center" }}
        >
          <div className={courseClasses.headcircle1} id="step2">
            <div className={courseClasses.headcircleiconwrap}>
              <MenuBookIcon className={courseClasses.headcircleicon} />
            </div>
            <div className={courseClasses.headcircleText}>
              <Typography variant="h4">{props.data.DoctorCount}</Typography>
              <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
                {props.data.ConductedBy}
              </Typography>
            </div>
          </div>
        </Link>
      ) :props.detail === "chunprocessed" ? (
        <Link
          to={{
            pathname: "/UnProcessedPayout",
            state: { data: props.data.ConductedBy },
          }}
          className="standardButton acceptButton pt-2"
          style={{ textAlign: "center" }}
        >
          <div className={courseClasses.headcircle1} id="step2">
            <div className={courseClasses.headcircleiconwrap}>
              <MenuBookIcon className={courseClasses.headcircleicon} />
            </div>
            <div className={courseClasses.headcircleText}>
              <Typography variant="h4">{props.data.DoctorCount}</Typography>
              <Typography variant="subtitle1" style={{ lineHeight: 1 }}>
                {props.data.ConductedBy}
              </Typography>
            </div>
          </div>
        </Link>
      ): (
        ""
      )}
    </>
  );
}