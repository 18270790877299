import React from "react";
// material-table component
import MaterialTable from "material-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

export default function TableFile({ rows, deleteBillingReport }) {
  const tables = myTableStyle();
  return (
    <>
      {" "}
      <Paper className={tables.root}>
        <MaterialTable
          columns={[
            { title: "MPI", field: "MPI" },
            { title: "IP", field: "IP" },
            { title: "Bill", field: "Bill" },
            { title: "Bill Date", field: "Bill_Date" },
            { title: "Bill Time", field: "Bill_Time" },
            { title: "Patient Name", field: "Patient_Name" },
            { title: "Age", field: "Age" },
            { title: "Gender", field: "Gender" },
            { title: "Admitting Physician", field: "Admitting_Physician" },
            { title: "Specialisation", field: "Specialisation" },
            { title: "Admit Group", field: "Admit_Group" },
            { title: "Admission Purpose", field: "Admission_Purpose" },
          ]}
          // ]}
          // columns={[]}
          // columns={[]}
          // columns={[]}
          // actions={[
          //   {
          //     icon: "delete",
          //     tooltip: "Delete",
          //     iconProps: { style: { fontSize: "24px", color: "#f55151" } },
          //     onClick: (event, row) => {
          //       deleteBillingReport(row);
          //     },
          //   },
          // ]}
          data={rows}
          options={{
            // filtering: true,
            sorting: true,
            exportButton: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 50, 100, 200, 500],
            search: true,
          }}
          title="Ip Billing Report"
        />
      </Paper>
    </>
  );
}
