import { Box, Grid, Typography } from '@material-ui/core';
import React, { PureComponent, useState } from 'react';
import { PieChart, Pie, Sector, Legend, Tooltip, Line, ResponsiveContainer, Cell } from 'recharts';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import { shadows } from '@mui/system';

const data = [
  { name: 'CH', value: 71386 },
  { name: 'DOCTOR', value: 53456 },
  { name: 'DRM', value: 5676},
  { name: 'HR', value: 75667 },
  { name: 'COO', value: 3351 }
];
const numbersManager = (number) => {
  let nf = new Intl.NumberFormat('en-IN');
  if (!isNaN(number)) {
    return nf.format(Math.abs(number).toFixed(0))
  }
  else{
    return nf.format(number.toFixed(0))
  }
}
const fill = "#8884d8"
const colors = ['#99B898', '#FF847C', '#DE3959', '#c36b6c', '#FECEAB', '#99B898']
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  console.log(payload, "payloadpayloadpayload")
  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill='black' fontSize={12} fontWeight='bold'>
        {payload.name}
      </text>
      <text x={cx} y={cy + 20} textAnchor="middle" fill="black" fontSize={12} fontWeight='bold'>{`${numbersManager(percent*100)}%`}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 6}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />

    </g>
  );
};
const PieChartLabel = (props) => {
  console.log(props.graphData, "LegendList")
  const sum = props.graphData.reduce((accumulator, object) => {
    return accumulator + object.value;
  }, 0);
  const [activeIndex, setActiveIndex] = useState(null)
  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  };
  const onPieExit = (_, index) => {
    setActiveIndex(null)
  };
  return (
    <div>

      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <PieChart width={250} height={250}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            // data={data}
            data={props.graphData}
            cx="50%"
            cy="50%"
            innerRadius={75}
            outerRadius={95}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieExit}
            colorRendering
          >
            {props.graphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          
        </PieChart>
        <Box style={{ maxHeight: '200px', width: '30%', paddingLeft: '5px', overflow: "scroll", backgroundColor: "#F9F9F9", boxShadow: "0px 10px 8px #DADADA" , borderRadius: "5%"}}>
          {props.graphData && props.graphData.length > 0 && props.graphData.map((item, index) =>
            <Box onClick={()=>{setActiveIndex(index)}} className={activeIndex == index ? 'pieLabelSelected' : 'pieLabel'} style={{ display: "flex", margin: "3px", justifyContent: "flex-start", cursor:'pointer'}} >{activeIndex == index ? <FiberManualRecordIcon fontSize={'small'} style={{ color: `${colors[index % colors.length]}`, paddingTop: '10px',marginTop:'-3px' }} /> : <FiberManualRecordOutlinedIcon fontSize={'small'} style={{ paddingTop: '10px', marginTop:'-3px' }} />}<Typography variant="subtitle2"  style={{color: `${colors[index % colors.length]}`, display: 'inline-block', fontSize: '10px', paddingTop: '5px'}} >{item.name ? item.name  : ''} &nbsp;&nbsp;&nbsp;&nbsp; {item.value  ? numbersManager(item.value) : 0} </Typography></Box>
          )}
        </Box>
      </Box>
    </div>
  );
}
export default PieChartLabel