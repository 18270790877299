import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { dateFormatter } from "../../actions/createPositionActions";
//ui component
import SidebarRD from "../../Components/Rd-sidebar";

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  customizedButton: {
    position: "absolute",
    left: "95%",
    top: "-9%",
    backgroundColor: "lightgray",
    color: "gray",
  },
}));

export default function DoctorWisePayoutDetails() {
  const tables = myTableStyle();
  const location = useLocation();
  console.log(location, "location");
  return (
    <>
      <SidebarRD />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Typography variant="subtitle1">Doctor Wise PayoutDetails</Typography>
        <Grid container spacing={1} style={{ marginBottom: "40px" }}>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Doctor Name", field: "category" },
                { title: "Category", field: "label" },
                { title: "Revenue", field: "label" },
                { title: "Payout", field: "label" },
                { title: "Created Date", field: "createdAt", render: (row) => (<div>{dateFormatter(row.createdAt)}</div>) },
                { title: "Detail", field: "designationLabel" },
              ]}
              // data={alldata}
              options={{
                sorting: true, 
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
              }}
              title="Payout-Detail"
            />
          </Paper>
        </Grid>
      </Container>
    </>
  );
}