import React, { useState, useEffect } from "react";
// import {  MSelect } from 'mdbreact';
// material ui components
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import RefreshIcon from "@material-ui/icons/Refresh";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { APIURL } from "../../CommomMethods/Fetch";
import MaterialTable from "material-table";
import { Paper } from "@material-ui/core";
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import { postRecord, putRecord } from "../../CommomMethods/Save";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from "react-router-dom";

import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import { toast } from "react-toastify";

import axios from "axios";

import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { createOnboarding, addonboard } from "../../actions/onboardActions";
import { listusers } from "../../actions/userActions";
import { centerlist } from "../../actions/centerActions";
import { IconButton } from "@material-ui/core";
import { parse } from "date-fns";
import { Label } from "recharts";
import { numberWithCommas } from "../../actions/createPositionActions";

// import Multiselect from "multiselect-react-dropdown";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const APIONBOARD = APIURL() + "onboard";
const APIVACANCY = APIURL() + "vacancies";
const APISINGLEDOC = APIURL() + "documents/uploadOne";
const APIMANYDOC = APIURL() + "documents/uploadMany";
const multiSelectStyle = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    whiteSpace: "nowrap",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
      whiteSpace: "nowrap",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  root1: {
    "& label + .MuiInput-formControl": {
      marginTop: "1vw",
    },
  },
}));

const useStyles1 = makeStyles({
  paper: {
    border: "5px solid black",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function DoctorForm({ history }) {
  const [selected, setSelected] = useState([]);
  const [certIssueDate, setCertIssueDate] = useState();
  const multiStyle = multiSelectStyle();
  const location = useLocation();

  // validation
  const [mobile, setmobile] = useState("");
  const [isError, setIsError] = useState(false);

  const backClasses = backDropStyle();
  const [backDropOpen, setBackDropOpen] = useState(false);
  const classes1 = useStyles1();
  const tables = myTableStyle();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [pan, setpan] = useState();
  const [adhaar, setadhaar] = useState();
  const [cc, setcc] = useState();
  const [address, setaddress] = useState();
  const [bank, setbank] = useState();
  const [mcrcValue, setMcrcValue] = useState();
  const [mcrcReasonValue, setMcrcReasonValue] = useState();
  const [filename, setFilename] = useState("");
  const [remark, setRemark] = useState();
  const [selectedmcrc, setSelectedmcrc] = useState();
  const [aadNo, setAadNo] = useState();
  const [panNo, setpanNo] = useState();
  const [stampdate, setstampdate] = useState();
  const [bankname, setBankNo] = useState();
  const [accountno, setAccountNo] = useState();
  const [bankifsc, setBankifscNo] = useState();
  const [ccBank, setccBank] = useState();
  const [selectedOpvalue, setselectedOpvalue] = useState([]);
  const [ccAccountNo, setccAccountNo] = useState();
  const [mcrcReasonValueDate, setmcrcReasonValueDate] = useState();
  const [ifsc, setifsc] = useState();
  const [addressProof, setAddressProof] = useState();
  const [aboutdoctor, setAboutdoctor] = useState();
  const [visible, setVisible] = useState(false);
  const [totaldeduction, setTotaldeduction] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [selecteddata, setSelectedData] = useState({});
  const [show, setShow] = useState(false);
  const [Consultation, setConsultation] = useState("");
  const [Discount, setDiscount] = useState("No");
  const [Package, setPackage] = useState("");
  const [IpRoom, setIpRoom] = useState("");
  const [IpMop, setIpMop] = useState("");
  const [ipNetBillAmountTot, setipNetBillAmountTot] = useState(0);
  const [ipfixedamount, setIpfixedamount] = useState(0);
  const [ipAmountTot, setIpAmountTot] = useState(0);
  const [ipPayoutTotal, setIpPayoutTotal] = useState(0);
  const [fixed, setFixed] = useState(false);
  const [maritalStat, setmaritalStat] = useState(false);
  const [ipdiscount, setIpDiscount] = useState("Not applicable");

  const [ctcpermonth, setCtcpermonth] = useState("");
  const [permanentPinCode, setPermanentPinCode] = useState("");
  const [permanentCity, setPermanentCity] = useState("");
  const [permanentState, setPermanentState] = useState("");
  const [mgpermonth, setmgpermonth] = useState("");
  const [mgterm, setmgterm] = useState("");
  const [mgsettlementterm, setmgsettlementterm] = useState("");
  const [mgsettlementperiod, setmgsettlementperiod] = useState("");
  const [indemnityvalue, setindemnityvalue] = useState("");
  const [indemnityexpiry, setindemnityexpiry] = useState("");
  const initialFormtState = {
    consultation: "",
    totalRevenue: "",
    opfixedpay: "",
    oppercpay: "",
    opadhoc: "",
    opcount: "",
    fixedamount: "",
    fixedpercentage: "",
    opperunitcharge: "",
    netamount: "",
    opamount: "",
    oprevenue: "",
    opgross: "",
    opnet: "",
    oppayout: "",
    opdiscounttype: "",
    opdiscountvalue: "",
  };
  const [opservice, setOpservice] = useState({
    initialFormtState,
  });
  const initialFormtStateipservice = {
    ipconsultaion: "",
    iproom: "",
    ipmodeofpayment: "",
    ipfixedamount: "",
    ipsurgeonfeeperc: "",
    ippackageamountperc: "",
    ipsurgeonfeeamount: "",
    ipdiscountvalue: "",
    ipdiscountType: "",
    ipgrossperc: "",
    netperc: "",
    amount: "",
    count: "",
    revenue: "",
    payout: "",
    ipgrossbill: "",
    ipgrossbillpercentage: "",
    ipdeductaionvalue: "",
    ipnetbillpercentage: "",
    iptotalamount: "",
    ipnetbillamount: "",
    ipTotalAmount: "",
    ipTotalNetBillAmount: "",
    ipgrossbillperunit: "",
    ipdeductionperunit: "",
  };
  const [ipservice, setIpservice] = useState([
    {
      initialFormtStateipservice,
    },
  ]);
  const [radiologyChild, setRadiologyChild] = useState([]);
  const [filePath, setfilePath] = useState();
  const [ipgrossbillTot, setipgrossbillTot] = useState("");
  const [ipdeductiontot, setipdeductiontot] = useState(0);
  const [noticeperiod, setnoticeperiod] = useState("");
  const [lockinperiod, setlockinperiod] = useState("");
  const [additionalfeename, setadditionalfeename] = useState("");
  const [additionalfee, setadditionalfee] = useState("");
  const [procedure, setProcedure] = useState([]);
  const [rows, setRows] = useState([]);
  const [calculateData, setCalculateData] = useState(0);
  const [procName, setProcName] = useState("");
  const [table, setTable] = useState([]);
  const [iptable, setIptable] = useState([]);
  const [unit, setUnit] = useState("");
  const [percentage, setPercentage] = useState("");
  const [count, setCount] = useState("");
  const [fixedPay, setFixedPay] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [onboardData, setOnoardData] = useState(0);
  const [disable, setDisable] = useState(false);
  const [checked, setChecked] = React.useState(true);
  const [chkitem, setChkitem] = useState([location.state.data.centerNames]);
  const [chkitemId, setChkitemId] = useState([location.state.data.id]);
  const [regionid, setRegionId] = useState(0);
  const [amount, setAmount] = useState("");
  const [net, setNet] = useState("");
  const [payout, setpayout] = useState("");
  const [adhoc, setAdhoc] = useState("");
  const [totalnetAmount, setTotalnetAmount] = useState([]);
  const [totalpayout, setTotalpayout] = useState([]);
  const [totalippayout, setTotalIppayout] = useState([]);
  const [totalipRevenue, setTotalIpRevenue] = useState([]);

  const [age, setAge] = React.useState("");
  const [sfee, setSfee] = useState(0);
  const [pap, setPap] = useState(0);
  const [gross, setGross] = useState("");
  const [grossIp, setGrossIp] = useState("");
  const [gender, setGender] = useState(false);
  const [permanentAdd, setPermanentAdd] = useState("");
  const [adddata, setAddData] = useState("");

  const [netC, setNetC] = useState("");
  const [finalpayout, setFinalpayout] = useState("");
  const [mcrc, setmcrc] = useState();
  const [mcrnValue, setMcrnValue] = useState(false);
  const [visibleFee, setVisibleFee] = useState(false);
  const [disableipsurgeonfeeperc, setDisableipsurgeonfeeperc] = useState(false);
  const [disableippackageamountperc, setDisableippackageamountperc] =
    useState(false);

  const [opNetAmount, setOpNetAmount] = useState(0);
  const [opNetPayout, setOpNetPayout] = useState(0);
  const [opRevenue, setopRevenue] = useState(0);
  const [opfixedamountDisable, setOpfixedamountDisable] = useState(false);
  const [opfixedpercDisable, setOpfixedpercDisable] = useState(false);
  const [chkopList, setChkOpList] = useState([]);
  const [commercialOpTable, setCommercialOpTable] = useState([]);
  const [commercialIpTable, setCommercialIpTable] = useState([]);
  const [ipChild, setIpChild] = useState([]);
  const [selectAllval, setSelectAllval] = useState(false);
  const [selectAllvalChild, setSelectAllvalChild] = useState(false);
  const [selectAllvalIp, setSelectAllvalIp] = useState(false);
  const [selectAllDelivery, setSelectAllDelivery] = useState(false);

  const handleChangeRoom = (event) => {
    setAge(event.target.value);
  };
  const fileHandler1 = (e) => {
    setmcrc(e.target.files[0]);
    setfilePath(e.target.value);
    setFilename(e.target.files[0].name);
  };
  const makeVisible = () => {
    setVisible(true);
    setVisibleFee(true);
  };
  const makeVisiblefalse = () => {
    setadditionalfee("");
    setadditionalfeename("");
    setVisible(false);
    setVisibleFee(false);
  };
  const handleChangeChkbox = (list) => {
    if (chkitem.includes(list.name)) {
      var k = [];
      var l = [];
      l = chkitemId.filter((z) => z != list.id);
      k = chkitem.filter((z) => z != list.name);
      setChkitemId(l);
      setChkitem(k);
    } else {
      setChkitem((prevItems) => [...prevItems, list.name]);
      setChkitemId((prevItems) => [...prevItems, list.id]);
    }
  };

  const handleChangeChkboxforOP = (name) => {
    if (chkopList.includes(name)) {
      if (chkopList.length > 1) {
        setRadiologyChild([]);
        var k = [];
        k = chkopList.filter((z) => z != name);
        setChkOpList(k);
      } else {
        var k = [];
        k = chkopList.filter((z) => z != name);
        setChkOpList(k);
      }
    } else {
      if (chkopList.length > 1) {
        setRadiologyChild([]);
        setChkOpList((prevItems) => [...prevItems, name]);
      } else {
        setChkOpList((prevItems) => [...prevItems, name]);
      }
    }
  };

  const handleChnageforSelectAllOp = () => {
    if (selectAllval === false) {
      setChkOpList([]);
      setSelectAllval(true);
      opListData.map((z) => {
        setChkOpList((prev) => [...prev, z.display]);
      });
    } else {
      setSelectAllval(false);
      setChkOpList([]);
    }
  };

  const [selectRadiology, setSelectRadiology] = useState(false);
  const handleChnageforSelectRadiology = () => {
    if (selectRadiology === false) {
      setRadiologyChild([]);
      setSelectRadiology(true);
      opListData[3].child.map((z) => {
        setRadiologyChild((prev) => [...prev, z.display]);
      });
    } else {
      setSelectRadiology(false);
      setRadiologyChild([]);
    }
  };

  const [selectNonOt, setSelectNonOt] = useState(false);
  const handleChnageforSelectNonotProcedure = () => {
    if (selectNonOt === false) {
      setRadiologyChild([]);
      setSelectNonOt(true);
      opListData[6].child.map((z) => {
        setRadiologyChild((prev) => [...prev, z.display]);
      });
    } else {
      setSelectNonOt(false);
      setRadiologyChild([]);
    }
  };

  const [selectOtherProcedure, setSelectOtherProcedure] = useState(false);
  const handleChnageforSelectOtherProcedure = () => {
    if (selectOtherProcedure === false) {
      setRadiologyChild([]);
      setSelectOtherProcedure(true);
      opListData[7].child.map((z) => {
        setRadiologyChild((prev) => [...prev, z.display]);
      });
    } else {
      setSelectOtherProcedure(false);
      setRadiologyChild([]);
    }
  };

  const handleChnageforSelectAllIP = () => {
    if (selectAllvalIp === false) {
      setChkIpList([]);
      setSelectAllvalIp(true);
      ipListData.map((z) => {
        setChkIpList((prev) => [...prev, z.display]);
      });
    } else {
      setSelectAllvalIp(false);
      setChkIpList([]);
    }
  };
  const handleChnageforSelectDelivery = () => {
    if (selectAllDelivery === false) {
      setIpChild([]);
      setSelectAllDelivery(true);
      ipListData[0].child.map((z) => {
        setIpChild((prev) => [...prev, z.display]);
      });
    } else {
      setSelectAllDelivery(false);
      setIpChild([]);
    }
  };
  const [opRoomType, setOpRoomType] = useState([]);
  const [selectRoomType, setSelectRoomType] = useState(false);
  const handleChangeForRoomTypeAll = () => {
    if (selectRoomType === false) {
      setOpRoomType([]);
      setSelectRoomType(true);
      roomType.map((z) => {
        setOpRoomType((prev) => [...prev, z.name]);
      });
    } else {
      setSelectRoomType(false);
      setOpRoomType([]);
    }
  };
  const [ipMop, setIpMopayment] = useState([]);
  const [selectipMop, setSelectIpMop] = useState(false);
  const handleChangeForMOPTypeAll = () => {
    if (selectipMop === false) {
      setIpMopayment([]);
      setSelectIpMop(true);
      paymentMode.map((z) => {
        setIpMopayment((prev) => [...prev, z.name]);
      });
    } else {
      setSelectIpMop(false);
      setIpMopayment([]);
    }
  };

  const handleChangeForRoomType = (name) => {
    if (opRoomType.includes(name)) {
      var k = [];
      k = opRoomType.filter((z) => z != name);
      setOpRoomType(k);
    } else {
      setOpRoomType((prevItems) => [...prevItems, name]);
    }
  };

  const handleChangeForMop = (name) => {
    if (ipMop.includes(name)) {
      var k = [];
      k = ipMop.filter((z) => z != name);
      setIpMopayment(k);
    } else {
      setIpMopayment((prevItems) => [...prevItems, name]);
    }
  };

  const [selectGynic, setSelectGynic] = useState(false);
  const handleChnageforSelectGynic = () => {
    if (selectGynic === false) {
      setIpChild([]);
      setSelectGynic(true);
      ipListData[1].child.map((z) => {
        setIpChild((prev) => [...prev, z.display]);
      });
    } else {
      setSelectGynic(false);
      setIpChild([]);
    }
  };

  const [selectFertility, setSelectFertility] = useState(false);
  const handleChnageforSelectFertility = () => {
    if (selectFertility === false) {
      setIpChild([]);
      setSelectFertility(true);
      ipListData[2].child.map((z) => {
        setIpChild((prev) => [...prev, z.display]);
      });
    } else {
      setSelectFertility(false);
      setIpChild([]);
    }
  };

  const [selectNicu, setSelectNicu] = useState(false);
  const handleChnageforSelectNicu = () => {
    if (selectNicu === false) {
      setIpChild([]);
      setSelectNicu(true);
      ipListData[4].child.map((z) => {
        setIpChild((prev) => [...prev, z.display]);
      });
    } else {
      setSelectNicu(false);
      setIpChild([]);
    }
  };

  const [selectOpenbilling, setSelectOpenBilling] = useState(false);
  const handleChnageforSelectOpenbilling = () => {
    if (selectOpenbilling === false) {
      setIpChild([]);
      setSelectOpenBilling(true);
      ipListData[5].child.map((z) => {
        setIpChild((prev) => [...prev, z.display]);
      });
    } else {
      setSelectOpenBilling(false);
      setIpChild([]);
    }
  };

  const handleChangeChkboxRadiologyChild = (name) => {
    if (radiologyChild.includes(name)) {
      var k = [];
      k = radiologyChild.filter((z) => z != name);
      setRadiologyChild(k);
    } else {
      setRadiologyChild((prevItems) => [...prevItems, name]);
    }
  };

  const handleChangeChkboxIpChild = (name) => {
    if (ipChild.includes(name)) {
      var k = [];
      k = ipChild.filter((z) => z != name);
      setIpChild(k);
    } else {
      setIpChild((prevItems) => [...prevItems, name]);
    }
  };

  const [nonOtChild, setNonOtChild] = useState([]);
  const handleChangeChkboxNonOtChild = (name) => {
    if (nonOtChild.includes(name)) {
      var k = [];
      k = nonOtChild.filter((z) => z != name);
      setChkOpList(k);
    } else {
      setNonOtChild((prevItems) => [...prevItems, name]);
    }
  };
  const handleChangeMCRN = (e) => {
    setMcrnValue(e.target.checked);
  };

  const handleChangemaritalStat = (e) => {
    setmaritalStat(e.target.checked);
  };
  const handleChangePermanentAdd = (e) => {
    if (e.target.checked === true) {
      setPermanentAdd(data.Address);
      setPermanentState(data.State);
      setPermanentCity(data.City);
      setPermanentPinCode(data.PinCode);
    } else {
      setPermanentAdd("");
      setPermanentState("");
      setPermanentCity("");
      setPermanentPinCode("");
    }
  };

  const CheckFixed = (e) => {
    setFixed(e.target.checked);
  };
  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  const centerList = useSelector((state) => state.centerList);
  const { centers } = centerList;
  var bcenter = location.state.data.centerNames;
  var centerArray = [];

  {
    users &&
      users[0] &&
      users[0].centers.map((ele) => {
        centerArray.push(ele);
      });
  }

  var k = centerArray.filter((z) => z.name === bcenter);
  var l = centerArray.filter((z) => z.region_id === k[0].region_id);

  // setRegionId(k.region_id);
  const getOnboard = useSelector((state) => state.getOnboard);
  const { getonboard } = getOnboard;

  useEffect(() => {
    dispatch(addonboard());
  }, [dispatch]);

  useEffect(() => {
    dispatch(centerlist());
  }, [dispatch]);

  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  // const handleOnboarding = async (event) => {
  //   createCommercialOp();
  //   createCommercialIp();

  //   event.preventDefault();
  //   const formData = new FormData();
  //   if (mcrnValue === false) {
  //     var MCRCTitle = document.getElementById("MCRC").innerHTML;
  //   }
  //   data.designationlist = location.state.data.designationLabel;
  //   data.departmentlist = location.state.data.departmentLabel;
  //   data.businessunit = location.state.data.centerNames;
  //   data.ctcmonth = ctcpermonth;
  //   data.indemnityexpiry = indemnityexpiry;
  //   data.indemnityvalue = indemnityvalue;
  //   data.ctcmonth = ctcpermonth;
  //   data.mgpermonth = mgpermonth;
  //   data.mgterm = mgterm;
  //   data.mgsettlementperiod = mgsettlementperiod;
  //   data.mgsettlementterm = mgsettlementterm;
  //   data.additionalfeename = additionalfeename;
  //   data.additionalfee = additionalfee;
  //   data.PermanentAddress = permanentAdd;
  //   data.PermanentState = permanentState;
  //   data.PermanentCity = permanentCity;
  //   data.PermanentPinCode = permanentPinCode;
  //   data.centers = chkitem;
  //   const postData = {
  //     data: data,
  //     opservice: table,
  //     ipservice: iptable,
  //     opcommercial: commercialOpTable,
  //     ipcommercial: commercialIpTable,
  //   };
  //   setBackDropOpen(true);
  //   await axios
  //     .post("onboard", postData)
  //     .then((res) => {
  //       if (res !== null) {
  //         setOnoardData(res.data.doctorData.id);
  //       }

  //       setBackDropOpen(false);

  //       const obj = {
  //         doctorId: res.data.doctorData.id,
  //       };
  //       axios
  //         .put(
  //           "vacancies/" + location.state.data.id,
  //           obj
  //         )
  //         .then((res) => { });
  //       setTimeout(() => {
  //         toast.success("Successly Submitted!");
  //         window.location.reload();
  //       }, 1000);
  //       history.push("/bh-dashboard");
  //     })
  //     .catch((err) => { });
  //   formData.append("docValue", mcrcValue);
  //   formData.append("docTitle", "Medical Council Registration Certificate No");
  //   // formData.append("doctorId", onboardData);
  //   formData.append("docFile", mcrc);
  //   formData.append("name", filename);
  //   formData.append("url", filePath);

  //   axios
  //     .post("documents/uploadOne", formData)
  //     .then(function (response) {
  //       // setRefresh(true);
  //       if (response.data.message == "Invalid File") {
  //         toast.error(response.data.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       } else if (response.data.message == "No File uploaded") {
  //         toast.warning(response.data.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       } else if (response.data.message == "Successfully Uploaded") {
  //         toast.success(response.data.message, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     });
  // };

  const info = "Clearing Exceptions";

  var testdata = newdata;
  var newdata = [];
  function selectedValue(e) {
    if (e.target.value.length > 10) {
      setIsError(true);
    }
    newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  var newadddata = [];
  function selectedAddValue(e) {
    newadddata = { ...adddata };
    newadddata[e.target.id] = e.target.value;
    setAddData(newadddata);
  }
  var newConsultation = [];
  function selectedConsulataion(e) {
    newConsultation = { ...Consultation };
    newConsultation[e.target.id] = e.target.value;
    setConsultation(newConsultation);
  }

  var optype = [];
  var testData = {};

  const selectedOPValue = (index) => (e) => {
    let newArr = [...opservice]; // copying the old datas array
    newArr[index] = { ...newArr[index], [e.target.name]: e.target.value }; // replace e.target.value with whatever you want to change it to
    setOpservice(newArr);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setOpservice({ ...opservice, [name]: value });
  };

  const onChnageDisable = (event) => {
    setOpfixedamountDisable(false);
    const { name, value } = event.target;
    if (value.length > 0) {
      setOpfixedamountDisable(true);
    } else {
      setOpfixedpercDisable(false);
    }
  };
  const onChnageDisable1 = (event) => {
    setOpfixedpercDisable(false);
    const { name, value } = event.target;
    if (value.length > 0) {
      setOpfixedpercDisable(true);
    } else {
      setOpfixedamountDisable(false);
    }
  };

  const handleInputChangeForCtc = (event) => {
    const { name, value } = event.target;
    // if (value.length > 0) {
    //   setDisable(true);
    // } else {
    //   setDisable(false);
    // }
    setCtcpermonth(value);
  };

  const handlemgpermonth = (event) => {
    const { name, value } = event.target;
    // if (value.length > 0) {
    //   setDisable(true);
    // } else {
    //   setDisable(false);
    // }
    setmgpermonth(value);
  };

  const handlemgterm = (event) => {
    const { name, value } = event.target;
    // if (value.length > 0) {
    //   setDisable(true);
    // } else {
    //   setDisable(false);
    // }
    setmgterm(value);
  };

  const handlemgsettlementperiod = (event) => {
    const { name, value } = event.target;
    // if (value.length > 0) {
    //   setDisable(true);
    // } else {
    //   setDisable(false);
    // }
    setmgsettlementperiod(value);
  };

  const handlemgsettlementterm = (event) => {
    const { name, value } = event.target;
    // if (value.length > 0) {
    //   setDisable(true);
    // } else {
    //   setDisable(false);
    // }
    setmgsettlementterm(value);
  };

  const handleindemnityvalue = (event) => {
    const { name, value } = event.target;

    setindemnityvalue(value);
  };
  const handleindemnityexpiry = (event) => {
    const { name, value } = event.target;

    setindemnityexpiry(value);
  };
  const handleInputChangeForAddName = (event) => {
    const { name, value } = event.target;
    setadditionalfeename(value);
  };
  const handleInputChangeFoAddVal = (event) => {
    const { name, value } = event.target;
    setadditionalfee(value);
  };

  const unitCalculation = () => {
    setUnit(opservice.opperunitcharge);
  };
  const percentageCalculation = () => {
    setPercentage(unit * (opservice.oppercpay / 100));
  };
  const fixedPercentageOrAmount = (e) => {
    if (e.target.name === "fixedpercentage") {
      setOpservice({ ...opservice, fixedamount: 0 });
    }
    if (e.target.name === "fixedamount") {
      setOpservice({ ...opservice, fixedpercentage: 0 });
    }
  };

  //OP CALCULATION FORMULAE
  const opCalculation = () => {
    //deduction calculation
    let deduct = parseInt(opservice.opadhoc);
    if (isNaN(deduct)) {
      deduct = 0;
    } else {
    }

    setAdhoc(deduct);

    let unit = parseInt(opservice.opperunitcharge);
    let count = parseInt(opservice.opcount);
    //unit value checking
    if (isNaN(unit)) {
      unit = 0;
    } else {
    }
    //count value checking
    if (isNaN(count)) {
      count = 0;
    } else {
    }
    let gs = unit * count;
    let discountval = parseInt(opservice.opdiscountvalue) * count;
    //discount value checking
    if (isNaN(discountval) || opservice.opdiscountvalue.length === 0) {
      discountval = 0;
    } else {
    }

    let netVal = gs - discountval;
    let deduction = parseInt(opservice.opadhoc) * count;
    // let totalRevenue = parseInt(opservice.totalRevenue) * count;
    let totalRevenue = parseInt(opservice.totalRevenue);

    if (isNaN(deduction)) {
      deduction = 0;
    } else {
    }

    if (isNaN(totalRevenue)) {
      totalRevenue = 0;
    } else {
    }
    //net value checking

    setTotaldeduction(deduction);
    if (isNaN(deduction)) {
      deduction = 0;
    } else {
    }
    let opRevenueval = netVal - deduction;
    let percnt = parseInt(opservice.fixedpercentage);
    let fixedamt = parseInt(opservice.fixedamount);

    //payout value checking

    if (isNaN(percnt)) {
      percnt = 0;
    } else {
    }
    if (isNaN(fixedamt)) {
      fixedamt = 0;
    } else {
    }

    let fixedDeduction = totalRevenue
      ? totalRevenue * (percnt / 100)
      : opRevenueval
      ? opRevenueval * (percnt / 100)
      : fixedamt * count;

    //set value
    setGross(unit * count);
    setOpNetAmount(gs - discountval);
    setopRevenue(netVal - deduction);
    setOpNetPayout(fixedDeduction);
  };

  //IP CALCULATION FORMULAE
  const ipCalculation = (event) => {
    let packageamount = 5000;
    let surgeonfee = parseInt(ipservice.ipsurgeonfeeamount);
    let count = parseInt(ipservice.count);
    let disperu = parseInt(ipservice.ipdeductionperunit);

    if (isNaN(disperu)) {
      disperu = 0;
    } else {
    }
    if (isNaN(packageamount)) {
      packageamount = 0;
    } else {
    }
    //count value checking
    if (isNaN(surgeonfee)) {
      surgeonfee = 0;
    } else {
    }
    if (isNaN(count)) {
      count = 0;
    } else {
    }

    let GrossPercentage = packageamount
      ? packageamount * count
      : surgeonfee * count;
    setGrossIp(GrossPercentage);

    let grossbilip = parseInt(ipservice.ipgrossbillperunit);
    let deductaionvalip = parseInt(ipservice.ipdeductaionvalue) * count;
    let discountvalip = parseInt(ipservice.ipdiscountvalue) * count;
    let grossbilltot = parseInt(ipservice.ipgrossbillperunit) * count;

    if (isNaN(grossbilltot)) {
      grossbilltot = 0;
    } else {
    }

    setipgrossbillTot(grossbilltot);

    let deductiontot = disperu * count;

    setipdeductiontot(deductiontot);

    if (isNaN(grossbilip)) {
      grossbilip = 0;
    } else {
    }
    if (isNaN(deductaionvalip)) {
      deductaionvalip = 0;
    } else {
    }
    if (isNaN(discountvalip)) {
      discountvalip = 0;
    } else {
    }

    let finalNetbillcount =
      discountvalip === 0 && deductiontot === 0
        ? 0
        : discountvalip === 0
        ? grossbilltot - deductiontot
        : deductiontot === 0
        ? grossbilltot - discountvalip
        : grossbilltot - discountvalip - deductiontot;

    setipNetBillAmountTot(finalNetbillcount);

    // let grossipAmount = grossbilip * count;
    let surgoenipAmount = surgeonfee * count;
    let netAmountip = finalNetbillcount * count;
    let amountval = 0;
    if (event.target.name === "ipfixedamount") {
      amountval = parseInt(event.target.value);
    }

    if (isNaN(amountval)) {
      amountval = 0;
    } else {
    }

    let fixedAmount = amountval * count;


    let amountip = finalNetbillcount
      ? finalNetbillcount * count
      : grossbilip
      ? grossbilltot
      : surgeonfee
      ? surgoenipAmount
      : ipNetBillAmountTot === 0 && packageamount === 0
      ? surgeonfee * count
      : ipNetBillAmountTot === 0 && surgeonfee === 0
      ? 5000 * count
      : 0;
    setIpAmountTot(amountip);

    let surgeopnfeeperc = parseInt(ipservice.ipsurgeonfeeperc);

    let packageamountpercnt = parseInt(ipservice.ippackageamountperc);
    let netbillpercent = parseInt(ipservice.ipnetbillpercentage);
    if (isNaN(surgeopnfeeperc)) {
      surgeopnfeeperc = 0;
    } else {
    }
    if (isNaN(packageamountpercnt)) {
      packageamountpercnt = 0;
    } else {
    }
    if (isNaN(netbillpercent)) {
      netbillpercent = 0;
    } else {
    }
    let grossbillperc = parseInt(ipservice.ipgrossbillpercentage);
    let surgeopnfeeperccalculate = (surgoenipAmount * surgeopnfeeperc) / 100;
    if (isNaN(grossbillperc)) {
      grossbillperc = 0;
    } else {
    }
    // let surgeonpayoutip = surgeopnfeeperccalculate * amountip;
    let packageamountpercntcalculate =
      packageamount * count * (packageamountpercnt / 100);
    let grossbillcal = grossbilltot * (grossbillperc / 100);
    let netbillpercntage = grossbilltot * (netbillpercent / 100);
    // let netbillpercntage = netAmountip * (netbillpercent / 100);

    //netAmountip

    let ipPayouttot = fixedAmount
      ? fixedAmount
      : netbillpercntage
      ? netbillpercntage
      : surgeopnfeeperccalculate
      ? surgeopnfeeperccalculate
      : surgeopnfeeperc === 0 && packageamountpercnt === 0
      ? grossbillcal
      : grossbillperc === 0 && packageamountpercnt === 0
      ? netbillpercntage * amountip
      : surgeopnfeeperc === 0 && finalNetbillcount === 0
      ? packageamountpercntcalculate
      : packageamountpercnt === 0 && finalNetbillcount === 0;

    setIpPayoutTotal(ipPayouttot);
  };

  const adhocCalculation = () => {
    // let n= parseInt(opservice.opadhoc)
    let m = amount - parseInt(opservice.opadhoc);
    setAdhoc(m);
  };
  const countCalculation = () => {
    let c = parseInt(opservice.opcount);
    let k = parseInt(opservice.opperunitcharge);
    c *= k;
    setAmount(c);
  };

  const payoutCalculation = () => {
    let n = parseInt(opservice.fixedamount);
    let i = parseInt(opservice.fixedpercentage);
    let k = i / 100;
    let m = k * adhoc + n;
    setpayout(m);
  };

  const fixedpayCalculation = () => {
    let i = opservice.opfixedpay;
    let f = parseInt(count);
    let total = Number(i) + Number(f);
    setFixedPay(total);
  };
  const getProcedureValue = (e) => {
    // alert(e.target.value);
    setConsultation(e.target.value);
  };

  const getDiscount = (e) => {
    // alert(e.target.value);
    setDiscount(e.target.value);
  };

  const getIPDiscount = (e) => {
    if (e.target.value === "Not applicable") {
      setIpDiscount("No");
    } else {
      setIpDiscount(e.target.value);
    }
  };


  const getPackageValue = (e) => {
    setPackage(e.target.value);
  };

  const getIpRoomValue = (e) => {
    setIpRoom(e.target.value);
  };

  const getIpMopValue = (e) => {
    setIpMop(e.target.value);
  };

  const handleIpserviceInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "ipsurgeonfeeperc") {
      setDisableippackageamountperc(true);
    }
    if (name === "ippackageamountperc") {
      setDisableipsurgeonfeeperc(true);
    }
    setIpservice({ ...ipservice, [name]: value });
  };

  const SHPC = () => {
    var k = parseInt(ipservice.ipsurgeonfeeperc) / 100;
    var sf = k * 2000;
    setSfee(sf);
  };

  const PAP = () => {
    var i = parseInt(ipservice.ippackageamountperc) / 100;
    var pf = i * 5000;
    setPap(pf);
  };

  const grossC = () => {
    let sf = 0,
      pf = 0,
      g = 0;
    if (ipservice.ipsurgeonfeeperc !== "") {
      var k = parseInt(ipservice.ipsurgeonfeeperc) / 100;
      if (isNaN(k)) {
        k = 0;
      }
      sf = k * 2000;
    }
    if (ipservice.ippackageamountperc !== "") {
      var i = parseInt(ipservice.ippackageamountperc) / 100;
      if (isNaN(i)) {
        i = 0;
      }
      pf = i * 5000;
    }
    //  if(sf === "NaN" && pf === "NaN"){
    //    g = 0;
    //  }else if(sf==="NaN"){
    //   g = (pf) * parseInt(ipservice.count);
    //  }else if(pf==="NaN"){
    //   g = (sf) * parseInt(ipservice.count);
    //  }else{
    //    g = 0;
    //  }
    g = (pf + sf) * parseInt(ipservice.count);
    setGross(g);
  };

  const NetC = () => {
    var i = parseInt(ipservice.amount);
    var NB = gross - i;
    setNetC(NB);
  };

  useEffect(() => {
    var a = sfee === 0 ? 0 : sfee;
    var b = pap === 0 ? 0 : pap;
    var c = gross === 0 ? 0 : gross;
    var p;

    var s = parseInt(ipservice.ipfixedamount);
    var k = parseInt(a + b + c);
    var add;
    if (s != "NaN") {
      add = parseInt(k + s);
    } else {
      add = k;
    }

    setFinalpayout(add);
  }, [sfee, pap, gross, ipservice.ipfixedamount]);

  const addIpTableData = () => {
    if (chkipList.length <= 0) {
      return alert("Please select Catagory");
    }
    ipservice.consultation = Consultation;
    // ipservice.iproom = IpRoom;
    ipservice.iproom = opRoomType.join();
    ipservice.ipmodeofpayment = ipMop.join();
    ipservice.netperc = netC;
    ipservice.ipgrossperc = grossIp;
    ipservice.payout = ipPayoutTotal;
    ipservice.revenue = grossIp;
    ipservice.ipTotalAmount = ipAmountTot;
    ipservice.ipdiscountType =
      ipdiscount === "Not applicable" ? "No" : ipdiscount;
    ipservice.ipTotalNetBillAmount = ipNetBillAmountTot;
    ipservice.ipfixedamount = ipservice.ipfixedamount
      ? ipservice.ipfixedamount
      : 0;
    ipservice.ipdiscountvalue = ipservice.ipdiscountvalue
      ? ipservice.ipdiscountvalue
      : 0;
    ipservice.ipdeductaionvalue = ipdeductiontot;
    ipservice.ipdeductionperunit = ipservice.ipdeductionperunit
      ? ipservice.ipdeductionperunit
      : 0;
    ipservice.ipsurgeonfeeperc = ipservice.ipsurgeonfeeperc
      ? ipservice.ipsurgeonfeeperc
      : 0;
    ipservice.ipgrossbillpercentage = ipservice.ipgrossbillpercentage
      ? ipservice.ipgrossbillpercentage
      : 0;
    ipservice.ippackageamountperc = ipservice.ippackageamountperc
      ? ipservice.ippackageamountperc
      : 0;
    ipservice.ipnetbillpercentage = ipservice.ipnetbillpercentage
      ? ipservice.ipnetbillpercentage
      : 0;
    let ipserviceDataArray = [];
    let opRevenueLocal = 0;
    let opPayoutLocal = 0;
    if (ipChild.length > 0 && chkipList.length === 1) {
      for (let k = 0; k < ipChild.length; k++) {
        for (let m = 0; m < iptable.length; m++) {
          if (
            ipChild[k] + "," + opRoomType.join() ===
            iptable[m].itemGroup + "," + iptable[m].iproom
          ) {
            return alert(
              iptable[m].itemGroup +
                " and " +
                iptable[m].iproom +
                " already exist."
            );
          }
        }
        let ipserviceData = { ...ipservice, itemGroup: ipChild[k] };
        ipserviceDataArray.push(ipserviceData);
      }
    } else {
      for (let i = 0; i < chkipList.length; i++) {
        for (let m = 0; m < iptable.length; m++) {
          if (
            chkipList[i] + "," + opRoomType.join() ===
            iptable[m].itemGroup + "," + iptable[m].iproom
          ) {
            return alert(
              iptable[m].itemGroup +
                " and " +
                iptable[m].iproom +
                " already exist."
            );
          }
        }
        let ipserviceData = { ...ipservice, itemGroup: chkipList[i] };
        ipserviceDataArray.push(ipserviceData);
      }
    }
    createCommercialIp(ipserviceDataArray);
    setChkIpList([]);
    setIpChild([]);
    setIptable((prevItems) => [...prevItems, ...ipserviceDataArray]);
    setIpservice(initialFormtStateipservice);
    setSelectIpMop(false);
    setSelectRoomType(false);
    setIpMopayment([]);
    // setSelectIpMop([]);
    setOpRoomType([]);
    setPackage("");
    setNetC("");
    setGross("");
    setIpRoom("");
    setIpMop("");
    setFixedPay("");
    setpayout("");
    setAdhoc("");
    setAmount("");
  };
  const RefreshIpRecord = () => {
    setChkIpList([]);
    setIpMopayment([]);
    setOpRoomType([]);
    setIpChild([]);
    setIpAmountTot(0);
    setIpPayoutTotal(0);
    setipNetBillAmountTot(0);
    var dropDown1 = document.getElementById("MonthCount");
    dropDown1.selectedIndex = 0;
    // var dropDown = document.getElementById("Discount");
    // dropDown.selectedIndex = "Not applicable";
    setIpDiscount("Not applicable");
    setIpservice(initialFormtStateipservice);
    setPackage("");
    setIpRoom("room");
    setIpMop("");
    setGross("");
    setFinalpayout("");
    setNetC("");
    setipgrossbillTot("");
    setipdeductiontot("");
    setopRevenue("");
    setOpNetPayout("");
    setOpNetAmount("");
  };
  var commercialData = [];
  const addTableData = () => {
    if (chkopList.length <= 0) {
      return alert("Please select item group.");
    }
    opservice.consultation = Consultation;
    opservice.opdiscounttype = Discount;
    // opservice.opamount = opNetAmount;
    opservice.oprevenue = opRevenue;
    opservice.oppayout = opNetPayout;
    opservice.netamount = opNetAmount;
    opservice.opgross = gross;
    opservice.opnet = opNetAmount;
    opservice.opadhoc = adhoc ? adhoc : 0;
    opservice.opdiscountvalue = opservice.opdiscountvalue
      ? opservice.opdiscountvalue
      : 0;
    opservice.totalRevenue = opservice.totalRevenue
      ? opservice.totalRevenue
      : 0;
    let opserviceDataArray = [];
    let opRevenueLocal = 0;
    let opPayoutLocal = 0;
    if (editRow === true) {
      table.splice(chkopList, 1);
      setTable((prevItems) => [...prevItems, opservice]);
    } else {
      if (radiologyChild.length > 0 && chkopList.length === 1) {
        for (let k = 0; k < radiologyChild.length; k++) {
          for (let m = 0; m < table.length; m++) {
            if (radiologyChild[k] === table[m].itemGroup) {
              return alert(table[m].itemGroup + " already exist.");
            }
          }
          let opserviceData = { ...opservice, itemGroup: radiologyChild[k] };
          opserviceDataArray.push(opserviceData);
          setTotalnetAmount((j) => [...(j + opserviceData.oprevenue)]);
          // setTotalpayout((j) => [...j +   opservice.oppayout]);
          // opRevenueLocal = opRevenueLocal + opserviceData.oprevenue;
          // opPayoutLocal = opPayoutLocal + opserviceData.oppayout;
        }
      } else {
        for (let i = 0; i < chkopList.length; i++) {
          for (let m = 0; m < table.length; m++) {
            if (chkopList[i] === table[m].itemGroup) {
              return alert(table[m].itemGroup + " already exist.");
            }
          }

          let opserviceData = { ...opservice, itemGroup: chkopList[i] };
          opserviceDataArray.push(opserviceData);
          setTotalnetAmount((j) => [...(j + opserviceData.oprevenue)]);

          // opRevenueLocal = opRevenueLocal + opserviceData.oprevenue;
          // opPayoutLocal = opPayoutLocal + opserviceData.oppayout;
        }
      }
    }

    setChkOpList([]);
    setTotalpayout((j) => [...(j + opservice.oppayout)]);
    setRadiologyChild([]);
    setEditRow(false);
    // setTotalnetAmount((j) => [...j, j + opservice.oprevenue]);
    // setTotalpayout((j) => [...j, j + opservice.oppayout]);

    // setTotalnetAmount((j) => j + opRevenueLocal);
    // setTotalpayout((j) => j + opPayoutLocal);
    setTable((prevItems) => [...prevItems, ...opserviceDataArray]);
    // commercialData.push(opserviceDataArray);

    // setCommercialOpTable((prevItems) => [...prevItems, ...commercialData]);

    createCommercialOp(opserviceDataArray);

    setOpservice(initialFormtState);
    setFixedPay("");
    setpayout("");
    setAdhoc("");
    setAmount("");
    setOpNetAmount("");
    setOpNetPayout("");
    setopRevenue("");
    setGross("");
    var dropDown = document.getElementById("Discount");
    dropDown.selectedIndex = 0;
    setCalculateData(0);
  };

  const createCommercialOp = (datalist) => {
    datalist.map((ele) => {
      let ob = {};
      let itemgroup = ele.itemGroup;

      var commercialterm = "";
      commercialterm =
        ele.opdiscounttype === "Yes" && ele.opadhoc !== 0
          ? ele.fixedpercentage !== 0
            ? ele.fixedpercentage + "% of the Net Bill excluding Deductions"
            : ele.fixedamount + " /- Per case"
          : commercialterm;

      commercialterm =
        ele.opdiscounttype === "No" && ele.opadhoc === 0
          ? ele.fixedpercentage !== 0
            ? ele.fixedpercentage + " % of the Gross Amount"
            : ele.fixedamount + " /- Per case"
          : commercialterm;

      commercialterm =
        ele.opdiscounttype === "Yes" && ele.opadhoc === 0
          ? ele.fixedpercentage !== 0
            ? ele.fixedpercentage + " % of the Net Amount"
            : ele.fixedamount + " /- Per case"
          : commercialterm;

      commercialterm =
        ele.opdiscounttype === "No" && ele.opadhoc !== 0
          ? ele.fixedpercentage !== 0
            ? ele.fixedpercentage + "% of the Gross Bill excluding Deductions"
            : ele.fixedamount + " /- Per case"
          : commercialterm;

      if (commercialterm != "NA") {
        ob = { ...ob, itemgroup, commercialterm };
        commercialOpTable.push(ob);
      }

    });
  };

  const createCommercialIp = (datalist) => {
    datalist.map((ele) => {
      let ob = {};
      let itemgroup = ele.itemGroup;
      let ipmodeofpayment = ele.ipmodeofpayment;
      let iproom = ele.iproom;

      var commercialterm = "";
      commercialterm =
        ele.ipdeductaionvalue !== 0 &&
        ele.ipfixedamount === 0 &&
        ele.ipdiscountType === "No"
          ? ele.ipsurgeonfeeperc === 0 &&
            ele.ippackageamountperc === 0 &&
            ele.ipnetbillpercentage !== 0
            ? ele.ipnetbillpercentage +
              "% of the Gross Bill excluding Deductions"
            : ele.ipsurgeonfeeperc === 0 &&
              ele.ippackageamountperc === 0 &&
              ele.ipnetbillpercentage === 0
            ? ele.ipgrossbillpercentage +
              "% of the Gross Bill excluding Deductions"
            : ele.ipsurgeonfeeperc === 0 &&
              ele.ipgrossbillpercentage === 0 &&
              ele.ipnetbillpercentage === 0
            ? ele.ippackageamountperc +
              "% of the Gross Bill excluding Deductions"
            : ele.ipsurgeonfeeperc + "% of the Gross Bill excluding Deductions"
          : commercialterm;

      commercialterm =
        ele.ipdeductaionvalue === 0 && ele.ipdiscountType === "Yes"
          ? ele.ipsurgeonfeeperc === 0 &&
            ele.ippackageamountperc === 0 &&
            ele.ipnetbillpercentage !== 0
            ? ele.ipnetbillpercentage + " % of the Net Amount"
            : ele.ipsurgeonfeeperc === 0 &&
              ele.ippackageamountperc === 0 &&
              ele.ipnetbillpercentage === 0
            ? ele.ipgrossbillpercentage + " % of the Net Amount"
            : ele.ipsurgeonfeeperc === 0 &&
              ele.ipgrossbillpercentage === 0 &&
              ele.ipnetbillpercentage === 0
            ? ele.ippackageamountperc + " % of the Net Amount"
            : ele.ipsurgeonfeeperc + " % of the Net Amount"
          : commercialterm;

      commercialterm =
        ele.ipdeductaionvalue !== 0 &&
        ele.ipfixedamount === 0 &&
        ele.ipdiscountType === "Yes"
          ? ele.ipsurgeonfeeperc === 0 &&
            ele.ippackageamountperc === 0 &&
            ele.ipnetbillpercentage !== 0
            ? ele.ipnetbillpercentage + "% of the Net Bill excluding Deduction"
            : ele.ipsurgeonfeeperc === 0 &&
              ele.ippackageamountperc === 0 &&
              ele.ipnetbillpercentage === 0
            ? ele.ipgrossbillpercentage +
              "% of the Net Bill excluding Deduction"
            : ele.ipsurgeonfeeperc === 0 &&
              ele.ipgrossbillpercentage === 0 &&
              ele.ipnetbillpercentage === 0
            ? ele.ippackageamountperc
            : ele.ipsurgeonfeeperc +
              "% of the Net Bill excluding Deduction" +
              "% of the Net Bill excluding Deduction"
          : commercialterm;

      commercialterm =
        ele.ippackageamountperc !== 0 &&
        ele.ipdeductaionvalue === 0 &&
        ele.ipdiscountType === "No"
          ? ele.ippackageamountperc + "% of the Package Amount "
          : commercialterm;

      commercialterm =
        ele.ipsurgeonfeeperc !== 0 &&
        ele.ipdeductaionvalue === 0 &&
        ele.ipdiscountType === "No"
          ? ele.ipsurgeonfeeperc + "% of the Surgeon Fee "
          : commercialterm;

      commercialterm =
        ele.ipnetbillpercentage !== 0 &&
        ele.ipdeductaionvalue === 0 &&
        ele.ipdiscountType === "No"
          ? ele.ipnetbillpercentage + "% of the Net Bill Amount "
          : commercialterm;

      commercialterm =
        ele.ipgrossbillpercentage !== 0 &&
        ele.ipdeductaionvalue === 0 &&
        ele.ipdiscountType === "No"
          ? ele.ipgrossbillpercentage + "% of the Gross Bill Amount"
          : commercialterm;

      commercialterm =
        ele.ipfixedamount !== 0
          ? "Fixed Amount of Rs" + ele.ipfixedamount + "/- per case "
          : commercialterm;

      if (commercialterm != "NA") {
        ob = { ...ob, itemgroup, ipmodeofpayment, iproom, commercialterm };
        commercialIpTable.push(ob);
      }

    });
  };
  function getData1() {
    createCommercialIp();
  }
  const refreshopRecord = () => {
    setChkOpList([]);
    setRadiologyChild([]);
    var dropDown1 = document.getElementById("MonthCount");
    dropDown1.selectedIndex = 0;
    var dropDown = document.getElementById("Discount");
    dropDown.selectedIndex = 0;
    setOpservice(initialFormtState);
    setFixedPay("");
    setpayout("");
    setAdhoc("");
    setAmount("");
    setOpNetAmount("");
    setOpNetPayout("");
    setopRevenue("");
    setGross("");
    setTotaldeduction("");
  };

  var array = [];
  array = chkopList.concat(table);

  const selectedIPValue = (index) => (e) => {
    let ipArr = [...ipservice]; // copying the old datas array
    ipArr[index] = { ...ipArr[index], [e.target.id]: e.target.value }; // replace e.target.value with whatever you want to change it to
    setIpservice(ipArr);
  };

  function selectedTabValue(e) {
    let testop = document.getElementsByClassName("optype");
    let tabKey = e && e.target && e.target.id;
    let tempData = e && e.target && e.target.value;
    if (tabKey == "optype") {
      testData[tabKey] = tempData;
      optype.push(testData);
    }
  }
  const formList = useSelector((state) => state.formList);
  const { form, success } = formList;
  useEffect(() => {
    axios
      .get(APIURL() + "procedures/")
      .then((res) => res.data)
      .then((rows) => {
        setProcedure(rows);
      });
  }, [data]);

  const deleteRow = (row) => {
    var k = window.confirm("Are you sure want to delete this record ?");
    if (k) {
      const index = totalnetAmount.indexOf(row.netamount);
      if (index > -1) {
        totalnetAmount.splice(index, 1);
      }
      const index1 = totalpayout.indexOf(row.oppayout);
      if (index1 > -1) {
        totalpayout.splice(index1, 1);
      }
      var data = table.filter((z) => z.itemGroup != row.itemGroup);
      var data1 = commercialOpTable.filter((z) => z.itemgroup != row.itemGroup);
      setTable(data);
      setCommercialOpTable(data1);
    }
  };

  const [editRow, setEditRow] = useState(false);

  const editOpRow = (row) => {
    setEditRow(true);
    setOpservice({
      opperunitcharge: row.opperunitcharge,
      fixedAmount: row.fixedamount,
      fixedpercentage: row.fixedpercentage,
      opgross: row.opgross,
      opnet: row.opnet,
      opcount: row.opcount,
    });
    setGross(row.opgross);
    setOpNetAmount(row.opnet);
    setopRevenue(row.netamount);
    setOpNetPayout(row.oppayout);
    setChkOpList(row.itemGroup);
  };

  const deleteIptableRow = (row) => {
    var k = window.confirm("Are you sure want to delete this record ?");
    if (k) {
      const index = totalipRevenue.indexOf(row.revenue);
      if (index > -1) {
        totalipRevenue.splice(index, 1);
      }
      const index1 = totalippayout.indexOf(row.payout);
      if (index1 > -1) {
        totalippayout.splice(index1, 1);
      }
      var data = iptable.filter((z) => z.itemGroup != row.itemGroup);
      setIptable(data);
      var data1 = commercialIpTable.filter((z) => z.itemgroup != row.itemGroup);
      setCommercialIpTable(data1);
    }
  };

  const handleMCRC = (e) => {
    e.preventDefault();
    var MCRC = document.getElementById("MCRC").innerHTML;
    var dob = document.getElementById("dob").value;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("doctorId", user.user.id);
    formData.append("url", filePath);
    formData.append("docTitle", MCRC);
    formData.append("status", 1);
    formData.append("docValue", mcrcValue);
    formData.append("verificationRemark", remark);
    formData.append("docFile", mcrc);
    formData.append("registeration", selectedmcrc);
    formData.append("dob", dob);
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        toast("Success");
        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 3000);
      });
  };
  const handlePan = (e) => {
    e.preventDefault();
    var panTitle = document.getElementById("pan").innerHTML;
    const formData = new FormData();
    formData.append("docFile", pan);
    formData.append("doctorId", user.user.id);
    formData.append("name", filename);
    formData.append("docValue", panNo);
    formData.append("docTitle", panTitle);
    formData.append("url", filePath);
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        toast("Success");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };
  const handleAdhaar = (e) => {
    e.preventDefault();
    var Adhaar = document.getElementById("Adhaar").innerHTML;
    const formData = new FormData();
    formData.append("docFile", adhaar);
    formData.append("doctorId", user.user.id);
    formData.append("docTitle", Adhaar);
    formData.append("name", filename);
    formData.append("url", filePath);
    formData.append("docValue", aadNo);
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        toast("Success");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };
  function selectedDropdownvalue(e) {
    newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  const handleNotice = (e) => {
    setnoticeperiod(e.target.value);
  };

  const handlelockin = (e) => {
    setlockinperiod(e.target.value);
  };
  const handleCC = (e) => {
    e.preventDefault();
    var CC = document.getElementById("CC").innerHTML;
    const formData = new FormData();
    formData.append("docFile", cc);
    formData.append("doctorId", user.user.id);
    formData.append("docTitle", CC);
    formData.append("name", filename);
    formData.append("url", filePath);
    formData.append("Bank", ccBank);
    formData.append("docValue", ccAccountNo);
    formData.append("Ifsc", ifsc);
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };
  const handleAdress = (e) => {
    e.preventDefault();
    var AddressProof = document.getElementById("AddressProof").innerHTML;
    const formData = new FormData();
    formData.append("docFiles", address);
    formData.append("doctorId", user.user.id);
    formData.append("docTitle", AddressProof);
    formData.append("name", filename);
    formData.append("docValue", addressProof);
    formData.append("url", filePath);
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        toast("Success");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };

  const handleBank = (e) => {
    e.preventDefault();
    var BankStatement = document.getElementById("BankStatement").innerHTML;
    const formData = new FormData();
    for (var x = 0; x < bank.length; x++) {
      formData.append("docFiles", bank[x]);
    }
    formData.append("doctorId", user.user.id);
    formData.append("docTitle", BankStatement);
    formData.append("name", filename);
    formData.append("url", filePath);
    axios
      .post(APIURL() + "documents/uploadMany", formData)
      .then(function (response) {
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
  };
  const Confirm = (e) => {
    e.preventDefault();
    history.push("/Doctor-Profile");
  };
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;
  const [opListData, setOpListData] = useState([]);
  const [opListChildData, setOpListChildData] = useState([]);

  useEffect(() => {
    setOpListData([
      { id: 1, display: "Consultation", value: "Consultation" },
      { id: 2, display: "Pharmacy", value: "Pharmacy" },
      { id: 3, display: "Lab", value: "Lab" },
      {
        id: 4,
        display: "Radiology",
        value: "Radiology",
        child: [
          { display: "Radiology-Radiology1", value: "Radiology1" },
          { display: "Radiology-Radiology2", value: "Radiology2" },
          { display: "Radiology-Radiology3", value: "Radiology3" },
        ],
      },
      { id: 5, display: "Anesthesia Services", value: "Anesthesia Services" },
      { id: 6, display: "Physical Therapy", value: "Physical Therapy" },
      {
        id: 7,
        display: "Non OT Procedures/OP Procedures",
        value: "Non OT Procedures/OP Procedures",
        child: [
          { display: "Non OT Procedures/OP Procedures-MTP", value: "MTP" },
          { display: "Non OT Procedures/OP Procedures-IUI", value: "IUI" },
          { display: "Non OT Procedures/OP Procedures-IUCD", value: "IUCD" },
        ],
      },
      {
        id: 8,
        display: "Other Charges",
        value: "Other Charges",
        child: [
          { display: "Other Charges-DENTAL", value: "DENTAL" },
          {
            display: "Other Charges-EMERENCY RADIOLOGY CHARGE",
            value: "EMERENCY RADIOLOGY CHARGE",
          },
          {
            display: "Other Charges-SUTURING / WOUND DRESSING",
            value: "SUTURING / WOUND DRESSING",
          },
        ],
      },
    ]);
  }, []);

  const isAllSelected =
    opListData.length > 0 && selected.length === opListData.length;

  const handleChangeMultivalue = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === opListData.length ? [] : opListData);
      return;
    }
    setSelected(value);
  };
  const [roomType, setRoomType] = useState([]);
  useEffect(() => {
    setRoomType([
      { id: 1, name: "Sharing ", display: "Sharing " },
      { id: 2, name: "Luxury ", display: "Luxury " },
      { id: 3, name: "Suite ", display: "Suite " },
    ]);
  }, []);

  const [paymentMode, setPaymentMode] = useState([]);
  useEffect(() => {
    setPaymentMode([
      { id: 1, name: "Credit ", display: "Credit " },
      { id: 2, name: "Cash ", display: "Cash " },
    ]);
  }, []);

  const [ipListData, setIpListData] = useState([]);
  useEffect(() => {
    setIpListData([
      {
        id: 1,
        display: "Delivery",
        value: "Delivery",
        child: [
          { display: "Delivery-LSCS", value: "LSCS" },
          { display: "Delivery-Normal", value: "Normal" },
          { display: "Delivery-Forceps", value: "Forceps" },
        ],
      },
      {
        id: 2,
        display: "Gynec",
        value: "Gynec",
        child: [
          {
            display: "Gynec-Cerivical encirclage",
            value: "Cerivical encirclage",
          },
          {
            display: "Gynec-Mid Trimister Abortion",
            value: "Mid Trimister Abortion",
          },
        ],
      },
      {
        id: 3,
        display: "Fertility",
        value: "Fertility",
        child: [
          { display: "Fertility-ICSI", value: "ICSI" },
          {
            display: "Fertility-IVF",
            value: "IVF",
          },
          {
            display: "Fertility-FET",
            value: "FET",
          },
        ],
      },
      { id: 4, display: "Consultations", value: "Consultations" },
      {
        id: 5,
        display: "NICU",
        value: "NICU",
        child: [
          {
            display: "NICU-NICU",
            value: "NICU",
          },
          {
            display: "NICU-NICU with ventilator",
            value: "NICU with ventilator",
          },
        ],
      },
      {
        id: 6,
        display: "Open billing",
        value: "Open billing",
        child: [
          { display: "Open billing-LSCS", value: "LSCS" },
          { display: "Open billing-Normal", value: "Normal" },
          { display: "Open billing-Forceps", value: "Forceps" },
          {
            display: "Open billing-Cerivical encirclage",
            value: "Cerivical encirclage",
          },
          {
            display: "Open billing-Mid Trimister Abortion",
            value: "Mid Trimister Abortion",
          },
          { display: "Open billing-ICSI", value: "ICSI" },
          {
            display: "Open billing-IVF",
            value: "IVF",
          },
          {
            display: "Open billing-FET",
            value: "FET",
          },
          {
            display: "Open billing-NICU",
            value: "NICU",
          },
          {
            display: "Open billing-NICU with ventilator",
            value: "NICU with ventilator",
          },
          {
            display: "Open billing-Cerivical encirclage",
            value: "Cerivical encirclage",
          },
          {
            display: "Open billing-Mid Trimister Abortion",
            value: "Mid Trimister Abortion",
          },
        ],
      },
    ]);
  }, []);

  const [chkipList, setChkIpList] = useState([]);
  const handleChangeChkboxforIP = (name) => {
    //  else
    if (chkipList.includes(name)) {
      if (chkipList.length > 1) {
        setIpChild([]);
        var k = [];
        k = chkipList.filter((z) => z != name);
        setChkIpList(k);
      } else {
        var k = [];
        k = chkipList.filter((z) => z != name);
        setChkIpList(k);
      }
    } else {
      if (chkipList.length > 1) {
        setIpChild([]);
        setChkIpList((prevItems) => [...prevItems, name]);
      } else {
        setChkIpList((prevItems) => [...prevItems, name]);
      }
    }
  };

  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };

  const IFscValidation = (e) => {
    const value = e.target.value;
    var IFSC = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (IFSC.test(value)) {
    } else {
      alert("Enter Correct IFSC Code!");
    }
  };

  const validatePan = (e) => {
    //if()
    const value = e.target.value;
    var Pan = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;

    if (Pan.test(value)) {
    } else {
      alert("Enter Correct PAN number");
    }
    // e.target.value = e.target.value.replace(/([A-Z]){5}([0-9]){4}([A-Z]){1}$/);
  };

  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];
  const [personName, setPersonName] = React.useState([]);

  const handleChangeforMultiple = (event) => {
    setPersonName(event.target.value);
  };

  const handleOnboarding = async (event) => {
    // createCommercialOp();
    // createCommercialIp();

    event.preventDefault();
    const formData = new FormData();
    if (mcrnValue === false) {
      var MCRCTitle = document.getElementById("MCRC").innerHTML;
    }
    data.designationlist = location.state.data.designationLabel;
    data.departmentlist = location.state.data.departmentLabel;
    data.businessunit = location.state.data.centerNames;
    data.ctcmonth = ctcpermonth;
    data.indemnityexpiry = indemnityexpiry;
    data.indemnityvalue = indemnityvalue;
    data.ctcmonth = ctcpermonth;
    data.mgpermonth = mgpermonth;
    data.mgterm = mgterm;
    data.mgsettlementperiod = mgsettlementperiod;
    data.mgsettlementterm = mgsettlementterm;
    data.additionalfeename = additionalfeename;
    data.additionalfee = additionalfee;
    data.PermanentAddress = permanentAdd;
    data.PermanentState = permanentState;
    data.PermanentCity = permanentCity;
    data.PermanentPinCode = permanentPinCode;
    var k = moment(
      moment(data.doj).add(data.contractlength, "M").calendar()
    ).format("DD-MM-YYYY");
    data.agreementexpirydate = k;

    data.centers = chkitem;
    const postData = {
      data: data,
      opservice: table,
      ipservice: iptable,
      opcommercial: commercialOpTable,
      ipcommercial: commercialIpTable,
    };
    let doctorID = "";
    setBackDropOpen(true);

    await postRecord(APIONBOARD, postData)
      .then((res) => {
        if (res !== null) {
          setOnoardData(res.data.doctorData.id);
        }

        setBackDropOpen(false);
        if (res.data.doctorData.id !== null) {
          doctorID = res.data.doctorData.id;
        }
        const obj = {
          doctorId: res.data.doctorData.id,
        };
        axios
          .put(
            "vacancies/" + location.state.data.id,
            obj
          )
          .then((res) => {});
        setTimeout(() => {
          toast.success("Successly Submitted!");
          window.location.reload();
        }, 1000);
        history.push("/bh-dashboard");
      })
      //   putRecord(APIVACANCY, location.state.data.id, obj).then((res) => {});
      //   setTimeout(() => {
      //     toast.success("Successly Submitted!");
      //     window.location.reload();
      //   }, 1000);
      //   history.push("/bh-dashboard");
      // })
      .catch((err) => {});
    formData.append("certIssueDate", certIssueDate);
    formData.append("doctorId", doctorID);
    formData.append("docValue", mcrcValue);
    formData.append("docTitle", "Medical Council Registration Certificate No");
    // formData.append("doctorId", onboardData);
    formData.append("docFile", mcrc);
    formData.append("name", filename);
    formData.append("url", filePath);
    var p = moment(moment(certIssueDate).add(60, "M").calendar()).format(
      "DD-MM-YYYY"
    );
    formData.append("certExpiryDate", p);
    postRecord(APISINGLEDOC, formData).then(function (response) {
      // setRefresh(true);
      if (response.data.message == "Invalid File") {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (response.data.message == "No File uploaded") {
        toast.warning(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (response.data.message == "Successfully Uploaded") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  };
  const handlePaddChange = (event) => {
    const { name, value } = event.target;
    setPermanentAdd(value);
  };

  const handlePstateChange = (event) => {
    const { name, value } = event.target;
    setPermanentState(value);
  };

  const handlePcityChange = (event) => {
    const { name, value } = event.target;
    setPermanentCity(value);
  };
  const handlePpinChange = (event) => {
    const { name, value } = event.target;
    setPermanentPinCode(value);
  };
  return (
    <>
      <Sidebar />

      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />

            <form encType="multipart/form-data" onSubmit={handleOnboarding}>
              <br />
              <Card style={formStyle}>
                <Card.Header style={headerStyle}>
                  <h4> Doctor's Onboarding</h4>
                  <h6 style={{ fontWeight: "lighter" }}>
                    Raised by - Business Head
                  </h6>
                </Card.Header>
                <Card.Body style={bodyStyle}>
                  <Row>
                    <Col md={12}>
                      <h5>Personal Information</h5>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <label for="doctor-name">
                        <h6>
                          Name in Full
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <div className="form-group">
                        <span>Dr.</span>
                        <input
                          className="form-control form-doctor"
                          type="text"
                          placeholder="Doctor's Full Name"
                          id="fullname"
                          style={{ color: "#000000" }}
                          onChange={selectedValue}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <label for="birthday">
                        <h6>
                          Date of Birth
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>

                      <input
                        type="date"
                        id="birthday"
                        style={{ color: "#000000" }}
                        name="birthday"
                        className="form-control"
                        onChange={selectedValue}
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label for="doctor-name">
                        <h6>
                          Father's Name
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <div className="form-group">
                        <span>Mr.</span>
                        <input
                          className="form-control form-doctor"
                          type="text"
                          style={{ color: "#000000" }}
                          placeholder="Father's Name"
                          id="fathername"
                          onChange={selectedValue}
                          required
                        />
                      </div>
                    </Col>

                    <Col>
                      <FormControl component="fieldset">
                        <FormLabel
                          component="legend"
                          style={{ color: "black", fontSize: "13px" }}
                        ></FormLabel>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="top"
                            control={
                              <Checkbox
                                color="primary"
                                checked={maritalStat}
                                onChange={handleChangemaritalStat}
                              />
                            }
                            label="Marital Status(Yes)?"
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </FormControl>{" "}
                    </Col>
                    {maritalStat ? (
                      <Col md={6}>
                        <label for="doctor-name">
                          <h6>
                            {" "}
                            Spouse Name{" "}
                            <i
                              class="fa fa-asterisk"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "6px",
                              }}
                              aria-hidden="true"
                            ></i>
                          </h6>
                        </label>
                        <div className="form-group">
                          <span>Mr/Mrs</span>
                          <input
                            className="form-control form-doctor"
                            type="text"
                            style={{ color: "#000000" }}
                            placeholder="Spouse Name"
                            id="husbandname"
                            onChange={selectedValue}
                          />
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label for="doctor-contact">
                        <h6>
                          Contact Number
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <div className="form-group">
                        <span>+91</span>
                        <input
                          className="form-control form-doctor"
                          type="text"
                          // type="tel"
                          error={isError}
                          // value={mobile}
                          // onChange={(e) => {
                          //   setmobile(e.target.value);
                          //   if (e.target.value.length > 10) {
                          //     setIsError(true);
                          //   }
                          // }}
                          style={{ color: "#000000" }}
                          placeholder="Contact Number"
                          id="mobile"
                          name="doctor-contact"
                          onChange={selectedValue}
                          maxLength="10"
                          onInput={(e) => onlyNumbers(e)}
                          // pattern="^\d{10}$"
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <label for="email-id">
                        <h6>
                          Email Address
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <input
                        type="text"
                        style={{ color: "#000000" }}
                        placeholder="Email Address"
                        id="email"
                        className="form-control"
                        onChange={selectedValue}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <label for="address">
                        <h6>
                          Present Address
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <textarea
                        type="text"
                        placeholder="Present Address"
                        style={{ color: "#000000" }}
                        id="Address"
                        name="Address"
                        className="form-control"
                        onChange={selectedValue}
                        required
                      />
                    </Col>

                    <Col md={6}>
                      <label for="Gender">
                        <h6>
                          Gender
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <br />
                      <input
                        type="radio"
                        id="Gender"
                        name="fav_language"
                        onChange={selectedValue}
                        value="Male"
                      />
                      <label for="Male" style={{ margin: "6px" }}>
                        Male
                      </label>
                      <input
                        type="radio"
                        id="Gender"
                        name="fav_language"
                        onChange={selectedValue}
                        value="Female"
                      />
                      <label for="Female" style={{ margin: "6px" }}>
                        Female
                      </label>
                      <input
                        type="radio"
                        id="Gender"
                        name="fav_language"
                        onChange={selectedValue}
                        value="Other"
                      />
                      <label for="Other" style={{ margin: "6px" }}>
                        Other
                      </label>
                    </Col>
                    <Col md={3}>
                      <label for="state" style={{ margin: "6px" }}>
                        <h6>
                          State
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <input
                        type="text"
                        placeholder="State"
                        style={{ color: "#000000" }}
                        id="State"
                        name="State"
                        className="form-control"
                        onChange={selectedValue}
                        required
                      />
                    </Col>
                    <Col md={3}>
                      <label for="city" style={{ margin: "6px" }}>
                        <h6>
                          City
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <input
                        type="text"
                        placeholder="City"
                        style={{ color: "#000000" }}
                        id="City"
                        name="City"
                        className="form-control"
                        onChange={selectedValue}
                        required
                      />
                    </Col>
                    <Col md={3}>
                      <label for="pin code" style={{ margin: "6px" }}>
                        <h6>
                          Pin Code
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <input
                        type="text"
                        placeholder="Pin Code"
                        style={{ color: "#000000" }}
                        id="PinCode"
                        name="PinCode"
                        className="form-control"
                        onChange={selectedValue}
                        maxLength="6"
                        onInput={(e) => onlyNumbers(e)}
                        required
                      />
                    </Col>
                  </Row>
                  {/* Permanent */}
                  <Row>
                    <Col md={6}>
                      <FormControl component="fieldset">
                        <FormLabel
                          component="legend"
                          style={{ color: "black", fontSize: "13px" }}
                        ></FormLabel>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="top"
                            control={
                              <Checkbox
                                color="primary"
                                // checked={permanentAdd}

                                onChange={handleChangePermanentAdd}
                              />
                            }
                            label="Same as Present Address?"
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </FormControl>{" "}
                    </Col>
                    <Col md={6} style={{ margin: "6px" }}>
                      <label for="address">
                        <h6>
                          Permanent Address
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <textarea
                        type="text"
                        placeholder="Permanent Address"
                        style={{ color: "#000000" }}
                        id="PermanentAddress"
                        name="permanentAdd"
                        className="form-control"
                        value={permanentAdd}
                        onChange={handlePaddChange}
                        // onChange={selectedAddValue}
                        required
                      />
                    </Col>
                    <Col md={4}>
                      <h6 style={{ margin: "10px" }}>
                        About Doctor{" "}
                        <i
                          class="fa fa-asterisk"
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginLeft: "6px",
                          }}
                          aria-hidden="true"
                        ></i>
                      </h6>
                      <textarea
                        type="text"
                        placeholder="About Doctor"
                        style={{ color: "#000000" }}
                        id="aboutdoctor"
                        className="form-control"
                        name="aboutdoctor"
                        onChange={selectedValue}
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <label for="state" style={{ margin: "6px" }}>
                        <h6>
                          State
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <input
                        type="text"
                        placeholder="State"
                        style={{ color: "#000000" }}
                        id="PermanentState"
                        className="form-control"
                        value={permanentState}
                        onChange={handlePstateChange}
                        required
                      />
                    </Col>
                    <Col md={3}>
                      <label for="city" style={{ margin: "6px" }}>
                        <h6>
                          City
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <input
                        type="text"
                        placeholder="City"
                        style={{ color: "#000000" }}
                        id="PermanentCity"
                        className="form-control"
                        value={permanentCity}
                        onChange={handlePcityChange}
                        required
                      />
                    </Col>
                    <Col md={3}>
                      <label for="pin code" style={{ margin: "6px" }}>
                        <h6>
                          Pin Code
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <input
                        type="text"
                        placeholder="Pin Code"
                        style={{ color: "#000000" }}
                        id="PermanentPinCode"
                        className="form-control"
                        value={permanentPinCode}
                        onChange={handlePpinChange}
                        maxLength="6"
                        onInput={(e) => onlyNumbers(e)}
                        required
                      />
                    </Col>
                  </Row>

                  <hr style={{ backgroundColor: "black", height: "0.5px" }} />
                  <Row>
                    <Col md={12}>
                      <h5>
                        Joining Details
                        <i
                          class="fa fa-asterisk"
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginLeft: "6px",
                          }}
                          aria-hidden="true"
                        ></i>
                      </h5>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <label for="desg-name">
                        <h6 style={{ color: "#000000" }}>Designation</h6>
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          id="designationlist"
                          className="form-control"
                          value={location.state.data.designationLabel}
                          readOnly
                          style={{ color: "#000000" }}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <label for="dept-name">
                        <h6>Department</h6>
                      </label>
                      <input
                        type="text"
                        id="departmentlist"
                        className="form-control"
                        value={location.state.data.departmentLabel}
                        readOnly
                        style={{ color: "#000000" }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label for="business-unit" style={{ display: "none" }}>
                        <h6>Business Unit(s)</h6>
                      </label>
                      <div>
                        <input
                          type="text"
                          id="businessunit"
                          name="center-name"
                          className="form-control"
                          value={location.state.data.centerNames}
                          readOnly
                          style={{ color: "#000000", display: "none" }}
                        />

                        <br />
                        <>
                          <FormControl
                            component="fieldset"
                            style={{
                              marginBottom: ".5rem",
                              marginTop: "-.6rem",
                            }}
                          >
                            <h6
                              component="legend"
                              style={{ color: "black", fontSize: "15px" }}
                            >
                              Business Unit(s)
                            </h6>
                            <FormGroup aria-label="position" row>
                              {l.map((item) => (
                                <FormControlLabel
                                  value={item.id}
                                  control={<Checkbox color="primary" />}
                                  label={item.name}
                                  labelPlacement="start"
                                  checked={chkitem.includes(item.name)}
                                  onChange={() => {
                                    handleChangeChkbox(item);
                                  }}
                                />
                              ))}
                            </FormGroup>
                          </FormControl>
                        </>
                      </div>
                    </Col>
                    <Col md={6}></Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label for="contract-term">
                        <h6>Specific Period of Contract</h6>
                      </label>
                      <div className="form-group">
                        <span>In Months</span>
                        <input
                          className="form-control form-doctor"
                          type="number"
                          style={{ color: "#000000" }}
                          placeholder="Contract Term"
                          id="contractlength"
                          name="contract-term"
                          onChange={selectedValue}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <label for="doj">
                        <h6>Proposed Joining Date</h6>
                      </label>
                      <input
                        type="date"
                        id="doj"
                        style={{ color: "#000000" }}
                        name="doj"
                        className="form-control"
                        onChange={selectedValue}
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {" "}
                      <label for="pg-degree">
                        <h6>Highest PG Degree</h6>
                      </label>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Highest PG Degree"
                          id="pgdegree"
                          style={{ color: "#000000" }}
                          onChange={selectedValue}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <label for="pg-totalexp">
                        <h6>Post PG Degree Total Experience</h6>
                      </label>
                      <div className="form-group">
                        <span>In Months</span>
                        <input
                          className="form-control form-doctor"
                          type="number"
                          placeholder="Post PG Degree Total Experience"
                          id="pgtotalexp"
                          style={{ color: "#000000" }}
                          onChange={selectedValue}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      {" "}
                      <label for="pg-relexp">
                        <h6>Post PG Degree Relevant Experience</h6>
                      </label>
                      <div className="form-group">
                        <span>In Months</span>
                        <input
                          className="form-control form-doctor"
                          type="number"
                          style={{ color: "#000000" }}
                          placeholder="Post PG Degree Relevant Experience"
                          id="pgrelevantexp"
                          onChange={selectedValue}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      {" "}
                      <label for="pg-relexp">
                        <h6>Medical Degree</h6>
                      </label>
                      <div className="form-group">
                        <input
                          className="form-control form-doctor"
                          type="text"
                          style={{ color: "#000000" }}
                          placeholder="Medical Degree"
                          id="medicaldegree"
                          onChange={selectedValue}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {" "}
                      <label for="pg-degree">
                        <h6>Notice Period</h6>
                      </label>
                      <div className="form-group">
                        <select
                          className="form-control"
                          style={{ color: "black" }}
                          id="noticeperiod"
                          // value={noticeperiod}
                          onChange={selectedDropdownvalue}
                        >
                          <option>Select Notice Period</option>

                          <option value="15 days">15 days</option>
                          <option value="30 days">30 days</option>
                          <option value="45 days">45 days</option>
                          <option value="60 days">60 days</option>
                          <option value="75 days">75 days</option>
                          <option value="90 days">90 days</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={6}>
                      <label for="pg-totalexp">
                        <h6>Lock in Period</h6>
                      </label>
                      <div className="form-group">
                        <select
                          className="form-control"
                          style={{ color: "black" }}
                          id="lockinperiod"
                          // value={lockinperiod}
                          onChange={selectedDropdownvalue}
                        >
                          <option>Select Lock in Period</option>

                          <option value="NA">NA</option>
                          <option value="1 Year">1 Year</option>
                          <option value="2 Year">2 Year</option>
                          <option value="3 Year">3 Year</option>
                          <option value="4 Year">4 Year</option>
                          <option value="5 Year">5 Year</option>
                        </select>
                      </div>
                    </Col>
                    <Col md={6}>
                      {" "}
                      <label for="pg-relexp">
                        <h6>Existing Indemnity Insurance Value</h6>
                      </label>
                      <div className="form-group">
                        <span>Rs.</span>
                        <input
                          className="form-control form-doctor"
                          type="number"
                          placeholder="Existing Indemnity Insurance Value"
                          id="indemnityvalue"
                          style={{ color: "#000000" }}
                          value={indemnityvalue}
                          name="indemnityvalue"
                          onChange={handleindemnityvalue}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      {" "}
                      <label for="pg-relexp">
                        <h6> Indemnity insurance - Expiry </h6>
                      </label>
                      <div className="form-group">
                        <input
                          className="form-control form-doctor"
                          type="date"
                          placeholder="Post PG Degree Relevant Experience"
                          id="indemnityexpiry"
                          style={{ color: "#000000" }}
                          value={indemnityexpiry}
                          name="indemnityexpiry"
                          onChange={handleindemnityexpiry}
                        />
                      </div>
                    </Col>
                  </Row>

                  <hr style={{ backgroundColor: "black", height: "1px" }} />
                  <Row>
                    <Col md={12}>
                      <h5>Other Information</h5>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={6}>
                      <label for="pg-degree">
                        <h6>
                          {" "}
                          State of Registration{" "}
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <select
                        name="state"
                        id="selectedmcrc"
                        className="form-control"
                        style={{ color: "#000000" }}
                        onChange={selectedDropdownvalue}
                        // value={selectedmcrc}
                        // onChange={(e) => setSelectedmcrc(e.target.value)}
                      >
                        <option value="" disabled selected hidden>
                          Select the state of registration
                        </option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Andaman and Nicobar Islands">
                          Andaman and Nicobar Islands
                        </option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadar and Nagar Haveli">
                          Dadar and Nagar Haveli
                        </option>
                        <option value="Daman and Diu">Daman and Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">
                          Himachal Pradesh
                        </option>
                        <option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                      </select>
                    </Col>
                    <Col md={6}></Col>
                    <Col md={6}>
                      <FormControl component="fieldset">
                        <FormLabel
                          component="legend"
                          style={{ color: "black", fontSize: "13px" }}
                        ></FormLabel>
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="top"
                            control={
                              <Checkbox
                                color="primary"
                                checked={mcrnValue}
                                onChange={handleChangeMCRN}
                              />
                            }
                            label="Submit Medical Council Registration Certificate No Later?"
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </FormControl>{" "}
                    </Col>
                    <Col md={6}>
                      <div>
                        {!mcrnValue ? (
                          <div>
                            <label for="pg-degree">
                              <h6 id="MCRC">
                                {" "}
                                Medical Council Registration Certificate No
                              </h6>
                            </label>
                            <div className="form-group">
                              <input
                                type="text"
                                value={mcrcValue}
                                id="medicalcertno"
                                onChange={(e) => setMcrcValue(e.target.value)}
                                // onChange={selectedValue}
                                style={{ color: "#000000" }}
                                className="form-control"
                                placeholder="Medical Council Registration Certificate No"
                              />
                              <input
                                type="date"
                                value={certIssueDate}
                                id="certIssueDate"
                                onChange={(e) =>
                                  setCertIssueDate(e.target.value)
                                }
                                // onChange={selectedValue}
                                style={{ color: "#000000" }}
                                className="form-control"
                              />
                              <input
                                type="file"
                                id={2}
                                name="mcrc"
                                onChange={fileHandler1}
                                className="form-control"
                              />
                            </div>
                          </div>
                        ) : (
                          <div>
                            <label for="pg-degree">
                              <h6> Reason and Date for Later Submission </h6>
                            </label>
                            <div className="form-group">
                              <textarea
                                value={mcrcReasonValue}
                                id="medicalcertnoreason"
                                onChange={selectedValue}
                                style={{ color: "#000000" }}
                                className="form-control"
                                placeholder="Reason for Later Submission"
                              />
                              <select
                                className="form-control"
                                style={{ color: "black", margin: ".5rem" }}
                                id="medicalcertlimit"
                                name="medicalcertlimit"
                                onChange={selectedDropdownvalue}
                              >
                                <option value="0">Late Duration</option>
                                <option value="30 days">30 days</option>
                                <option value="45 days">45 days</option>
                                <option value="60 days">60 days</option>
                                <option value="90 days">90 days</option>
                              </select>
                              <input
                                type="date"
                                value={mcrcReasonValueDate}
                                id="medicalcertnoreasondate"
                                onChange={selectedValue}
                                style={{ color: "#000000", margin: "5px" }}
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {" "}
                      <label for="pg-degree">
                        <h6>
                          PAN Card No
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          value={panNo}
                          style={{ color: "#000000" }}
                          id="pancardno"
                          onChange={selectedValue}
                          maxLength="10"
                          onBlur={(e) => validatePan(e)}
                          className="form-control"
                          placeholder="PAN Number"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      {" "}
                      <label for="pg-degree">
                        <h6>
                          AADHAR Card No
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          style={{ color: "#000000" }}
                          value={aadNo}
                          id="aadhaarno"
                          onChange={selectedValue}
                          maxLength="12"
                          onInput={(e) => onlyNumbers(e)}
                          className="form-control"
                          placeholder="AADHAR Number"
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      {" "}
                      <label for="pg-degree">
                        <h6>
                          Bank Details
                          <i
                            class="fa fa-asterisk"
                            style={{
                              color: "red",
                              fontSize: "10px",
                              marginLeft: "6px",
                            }}
                            aria-hidden="true"
                          ></i>
                        </h6>
                      </label>
                      <div className="form-group">
                        <select
                          className="form-control"
                          style={{ color: "black" }}
                          id="BankName"
                          onChange={selectedDropdownvalue}
                        >
                          <option value="0">Select Bank</option>
                          <option value="Axis Bank">AXIS Bank</option>
                          <option value="HDFC Bank">HDFC Bank</option>
                          <option value="ICICI Bank">ICICI Bank</option>
                          <option value="YES Bank">YES Bank</option>
                          <option value="SBI Bank">SBI Bank</option>
                          <option value="CANARA Bank">CANARA Bank</option>
                        </select>
                        <input
                          style={{ marginLeft: "5px", color: "black" }}
                          type="text"
                          id="bankaccountno"
                          name="aad-num"
                          value={accountno}
                          onChange={selectedValue}
                          className="form-control"
                          placeholder="Account No"
                          onInput={(e) => onlyNumbers(e)}
                        />
                        <input
                          style={{ marginLeft: "5px", color: "black" }}
                          type="text"
                          id="ifsccode"
                          name="aad-num"
                          value={bankifsc}
                          maxLength="11"
                          onBlur={(e) => IFscValidation(e)}
                          onChange={selectedValue}
                          className="form-control"
                          placeholder="IFSC Code"
                        />
                      </div>
                    </Col>
                    <Col>
                      <label for="pg-degree">
                        <h6>Stamp Date</h6>
                      </label>
                      <input
                        type="date"
                        style={{ color: "#000000" }}
                        value={stampdate}
                        id="stampdate"
                        name="stampdate"
                        onChange={selectedValue}
                        maxLength="12"
                        className="form-control"
                        placeholder="Stamp Date"
                      />
                    </Col>
                  </Row>

                  <hr style={{ backgroundColor: "black", height: "1px" }} />
                  <Row>
                    <Col md={12}>
                      <h5>Contract Terms and Details</h5>
                    </Col>
                  </Row>
                  <br />
                  {location.state.data.category === "Minimum Guarentee(MG)" ? (
                    <Row>
                      <Col md={12}>
                        <div className={classes.root}>
                          <AppBar
                            position="static"
                            style={{
                              backgroundColor: "#ffffff1f",
                              color: "black",
                            }}
                          >
                            <Tabs
                              variant="fullWidth"
                              value={value}
                              onChange={handleChange}
                              aria-label="nav tabs example"
                            >
                              <LinkTab
                                label=" Fixed MG Fees"
                                href="/drafts"
                                {...a11yProps(0)}
                                // disabled={table.length > 0}
                              />
                              <LinkTab
                                label=" Out-Patient Services"
                                href="/trash"
                                {...a11yProps(1)}
                                disabled={fixed === true}
                              />
                              <LinkTab
                                label=" In-Patient Services"
                                href="/spam"
                                {...a11yProps(2)}
                                disabled={fixed === true}
                              />
                            </Tabs>
                          </AppBar>
                          <TabPanel value={value} index={0}>
                            <Row style={{ margin: "1.5rem" }}>
                              <Col xs={1}>
                                <Checkbox
                                  color="primary"
                                  checked={fixed}
                                  onChange={CheckFixed}
                                ></Checkbox>
                              </Col>
                              <Col>
                                <label style={{ color: "black" }}>
                                  {" "}
                                  Fixed MG Only
                                </label>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6}>
                                <label for="ctc-month">
                                  <h6>Minimum Guarentee /Month</h6>
                                </label>
                                <TextField
                                  variant="outlined"
                                  name="mgpermonth"
                                  label="Minimum Guarentee /Month"
                                  value={mgpermonth}
                                  onChange={handlemgpermonth}
                                  // onBlur={unitCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Col>
                              <Col md={6}>
                                <label for="contract-term">
                                  <h6>MG Period</h6>
                                </label>
                                <div className="form-group">
                                  <span>In Months</span>
                                  <input
                                    className="form-control form-doctor"
                                    type="number"
                                    style={{ color: "#000000" }}
                                    placeholder="MG Period"
                                    id="mgterm"
                                    name="mgterm"
                                    value={mgterm}
                                    onChange={handlemgterm}
                                    required
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "10px" }}>
                              <Col md={6}>
                                {" "}
                                <label for="pg-degree">
                                  <h6>Settlement Calculation Period</h6>
                                </label>
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    style={{ color: "black" }}
                                    id="mgsettlementcalculationperiod"
                                    // value={noticeperiod}
                                    onChange={selectedDropdownvalue}
                                  >
                                    <option>
                                      Select Settlement Calculation Period
                                    </option>

                                    <option value="Monthly">Monthly</option>
                                    <option value="Quaterly">Quaterly</option>
                                    <option value="Half Yearly">
                                      Half Yearly
                                    </option>
                                    <option value="Yearly">Yearly</option>
                                  </select>
                                </div>
                              </Col>

                              <Col md={6}>
                                <label for="ctc-month">
                                  <h6>Settlement Term</h6>
                                </label>
                                <TextField
                                  variant="outlined"
                                  name="mgsettlementterm"
                                  label="Settlement Term"
                                  value={mgsettlementterm}
                                  onChange={handlemgsettlementterm}
                                  // onBlur={unitCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Col>
                            </Row>

                            <Row style={{ marginTop: "10px" }}>
                              <Col md={6}>
                                {" "}
                                <label for="pg-degree">
                                  <h6>Settlement Payment Period</h6>
                                </label>
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    style={{ color: "black" }}
                                    id="mgsettlementpaymentperiod"
                                    // value={noticeperiod}
                                    onChange={selectedDropdownvalue}
                                  >
                                    <option>
                                      Select Settlement Payment Period
                                    </option>

                                    <option value="Monthly">Monthly</option>
                                    <option value="Quaterly">Quaterly</option>
                                    <option value="Half Yearly">
                                      Half Yearly
                                    </option>
                                    <option value="Yearly">Yearly</option>
                                  </select>
                                </div>
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <Grid container spacing={1}>
                              <Grid
                                item={6}
                                style={{
                                  height: "150px",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                  width: "50%",
                                  borderRight: "1px solid grey",
                                  marginBottom: "20px",
                                  borderBottom: "1px solid gray",
                                }}
                              >
                                <FormControl
                                  component="fieldset"
                                  style={{
                                    marginBottom: ".5rem",
                                    marginTop: "-.6rem",
                                  }}
                                >
                                  <h6
                                    component="legend"
                                    style={{
                                      color: "black",
                                      fontSize: "15px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Select Item Group
                                  </h6>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="selectall"
                                        onClick={handleChnageforSelectAllOp}
                                        checked={
                                          chkopList.length < 8
                                            ? false
                                            : chkopList.length === 8
                                            ? true
                                            : selectAllval === true
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label="Select All"
                                  />
                                  <FormGroup aria-label="position" columns>
                                    {opListData.map((item) => (
                                      <FormControlLabel
                                        value="start"
                                        control={
                                          <Checkbox
                                            color="primary"
                                            checked={chkopList.includes(
                                              item.value
                                            )}
                                          />
                                        }
                                        label={item.value}
                                        labelPlacement="end"
                                        onChange={() => {
                                          handleChangeChkboxforOP(item.value);
                                        }}
                                      />
                                    ))}
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                              {chkopList.length === 1 &&
                              chkopList.includes("Radiology") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Procedure
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={
                                            handleChnageforSelectRadiology
                                          }
                                          checked={
                                            radiologyChild.length <
                                            opListData[3].child.length
                                              ? false
                                              : radiologyChild.length ===
                                                opListData[3].child.length
                                              ? true
                                              : selectRadiology === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {opListData[3].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          checked={radiologyChild.includes(
                                            item.display
                                          )}
                                          labelPlacement="end"
                                          onChange={() => {
                                            handleChangeChkboxRadiologyChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : chkopList.length === 1 &&
                                chkopList.includes(
                                  "Non OT Procedures/OP Procedures"
                                ) ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Procedure
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={
                                            handleChnageforSelectNonotProcedure
                                          }
                                          checked={
                                            radiologyChild.length <
                                            opListData[6].child.length
                                              ? false
                                              : radiologyChild.length ===
                                                opListData[6].child.length
                                              ? true
                                              : selectNonOt === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {opListData[6].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          checked={radiologyChild.includes(
                                            item.display
                                          )}
                                          labelPlacement="end"
                                          onChange={() => {
                                            handleChangeChkboxRadiologyChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : chkopList.length === 1 &&
                                chkopList.includes("Other Charges") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Procedure
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={
                                            handleChnageforSelectOtherProcedure
                                          }
                                          checked={
                                            radiologyChild.length <
                                            opListData[7].child.length
                                              ? false
                                              : radiologyChild.length ===
                                                opListData[7].child.length
                                              ? true
                                              : selectNonOt === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {opListData[7].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          labelPlacement="end"
                                          checked={radiologyChild.includes(
                                            item.display
                                          )}
                                          onChange={() => {
                                            handleChangeChkboxRadiologyChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : (
                                ""
                              )}
                              <Grid item xs={12}></Grid>
                              <Grid item xs={6}>
                                <TextField
                                  variant="outlined"
                                  name="opcount"
                                  label="Count"
                                  value={opservice.opcount}
                                  onChange={handleInputChange}
                                  onBlur={opCalculation}
                                  onInput={(e) => onlyNumbers(e)}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <label style={{ marginTop: "-40px" }}>
                                  Select No of Month{" "}
                                </label>
                                <select
                                  className="form-control"
                                  style={{ color: "black" }}
                                  id="MonthCount"
                                  // onChange={getDiscount}
                                >
                                  <option value="0">Select No of Month </option>
                                  <option value=" 1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                </select>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  variant="outlined"
                                  name="opperunitcharge"
                                  label="Per Unit Charge"
                                  value={opservice.opperunitcharge}
                                  onChange={handleInputChange}
                                  onBlur={opCalculation}
                                  onInput={(e) => onlyNumbers(e)}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={4}>
                                <select
                                  className="form-control"
                                  style={{ color: "black" }}
                                  id="Discount"
                                  onChange={getDiscount}
                                >
                                  <option value="0">
                                    Is Discount Applicable
                                  </option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  variant="outlined"
                                  name="opadhoc"
                                  label="Deduction / Unit"
                                  value={opservice.opadhoc}
                                  onInput={(e) => onlyNumbers(e)}
                                  onChange={handleInputChange}
                                  onBlur={opCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  variant="outlined"
                                  name="fixedpercentage"
                                  label="Fixed Percentage"
                                  value={opservice.fixedpercentage}
                                  onChange={handleInputChange}
                                  onBlur={opCalculation}
                                  onKeyUp={fixedPercentageOrAmount}
                                  onInput={(e) => onlyNumbers(e)}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  variant="outlined"
                                  name="fixedamount"
                                  label="Fixed Amount"
                                  value={opservice.fixedamount}
                                  onKeyUp={fixedPercentageOrAmount}
                                  onChange={handleInputChange}
                                  onBlur={opCalculation}
                                  onInput={(e) => onlyNumbers(e)}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  variant="outlined"
                                  name="totalRevenue"
                                  label="Total Revenue"
                                  value={opservice.totalRevenue}
                                  onKeyUp={fixedPercentageOrAmount}
                                  onChange={handleInputChange}
                                  onBlur={opCalculation}
                                  onInput={(e) => onlyNumbers(e)}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="opamount"
                                  label="Gross"
                                  value={gross}
                                  onChange={handleInputChange}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="netamount"
                                  label="Net"
                                  value={Discount === "No" ? 0 : opNetAmount}
                                  onChange={handleInputChange}
                                  onBlur={fixedpayCalculation}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  variant="outlined"
                                  name="opadhoc"
                                  style={{ backgroundColor: "#80808030" }}
                                  label="Total Deduction"
                                  value={totaldeduction}
                                  onInput={(e) => onlyNumbers(e)}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="netamount"
                                  label="Estimated Earning"
                                  value={opRevenue}
                                  onChange={handleInputChange}
                                  onBlur={fixedpayCalculation}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="oppayout"
                                  label="Payout"
                                  value={opNetPayout}
                                  onChange={handleInputChange}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={12}>
                                {" "}
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  onClick={opCalculation}
                                  style={{ height: "100%", width: "100%" }}
                                >
                                  Calculate
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ height: "100%" }}
                                  startIcon={<AddIcon />}
                                  // fullWidth
                                  onClick={addTableData}
                                >
                                  ADD
                                </Button>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  style={{ height: "100%", marginLeft: "18px" }}
                                  startIcon={<RefreshIcon />}
                                  // fullWidth
                                  onClick={refreshopRecord}
                                >
                                  REFRESH
                                </Button>
                              </Grid>

                              <Grid item xs={3}></Grid>
                              <Grid item xs={12}>
                                {table.length > 0 ? (
                                  <Paper className={tables.root}>
                                    <MaterialTable
                                      columns={[
                                        {
                                          title: "Item Group Name",
                                          field: "itemGroup",
                                        },

                                        {
                                          title: "Discount Applicable",
                                          field: "opdiscounttype",
                                        },
                                        {
                                          title: "Fixed Amount",
                                          field: "fixedamount",
                                        },
                                        {
                                          title: "Fixed Percentage",
                                          field: "fixedpercentage",
                                        },
                                        {
                                          title: "Total Revenue",
                                          field: "totalRevenue",
                                        },
                                        {
                                          title: "Deduction",
                                          field: "opadhoc",
                                        },
                                        {
                                          title: "Count",
                                          field: "opcount",
                                        },
                                        {
                                          title: "Per Unit Charge",
                                          field: "opperunitcharge",
                                        },
                                        { title: "Gross", field: "opgross" },
                                        { title: "Net", field: "opnet" },
                                        {
                                          title: "Revenue",
                                          field: "oprevenue",
                                        },
                                        // { title: "Revenue", field: "oprevenue" },
                                        { title: "Payout", field: "oppayout" },
                                      ]}
                                      actions={[
                                        {
                                          icon: "delete",
                                          tooltip: "Delete",
                                          iconProps: {
                                            style: {
                                              fontSize: "24px",
                                              color: "#f55151",
                                            },
                                          },
                                          onClick: (event, row) =>
                                            deleteRow(row),
                                        },
                                        // {
                                        //   icon: "edit",
                                        //   tooltip: "Edit",
                                        //   iconProps: {
                                        //     style: {
                                        //       fontSize: "24px",
                                        //       color: "#1071eab3",
                                        //     },
                                        //   },
                                        //   onClick: (event, row) =>
                                        //     editOpRow(row),
                                        // },
                                      ]}
                                      data={table}
                                      options={{
                                        // filtering: true,
                                        sorting: true,
                                        exportButton: true,
                                        pageSize: 5,
                                        pageSizeOptions: [
                                          5, 10, 50, 100, 200, 500,
                                        ],
                                        search: true,
                                      }}
                                      title="Out-Patient Service"
                                    />
                                  </Paper>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>

                            <br />
                            <Grid item xs={12}>
                              {table && table.length > 0 ? (
                                <MaterialTable
                                  columns={[
                                    {
                                      title: "Item Group/Procedures",
                                      field: "itemGroup",
                                    },

                                    {
                                      title: "Commercial Term",
                                      field: "Action",
                                      filtering: false,
                                      render: (row) =>
                                        row.totalRevenue !== 0
                                          ? row.fixedpercentage +
                                            "% of the Total Revenue"
                                          : row.opdiscounttype === "Yes" &&
                                            row.opadhoc !== 0
                                          ? row.fixedpercentage !== 0
                                            ? row.fixedpercentage +
                                              "% of the Net Bill excluding Deductions"
                                            : row.fixedamount + " /- Per case"
                                          : row.opdiscounttype === "No" &&
                                            row.opadhoc === 0
                                          ? row.fixedpercentage !== 0
                                            ? row.fixedpercentage +
                                              " % of the Gross Amount"
                                            : row.fixedamount + " /- Per case"
                                          : row.opdiscounttype === "Yes" &&
                                            row.opadhoc === 0
                                          ? row.fixedpercentage !== 0
                                            ? row.fixedpercentage +
                                              " % of the Net Amount"
                                            : row.fixedamount + " /- Per case"
                                          : row.opdiscounttype === "No" &&
                                            row.opadhoc !== 0
                                          ? row.fixedpercentage !== 0
                                            ? row.fixedpercentage +
                                              "% of the Gross Bill excluding Deductions"
                                            : row.fixedamount + " /- Per case"
                                          : "",
                                    },
                                  ]}
                                  data={table}
                                  options={{
                                    // filtering: true,
                                    sorting: true,
                                    exportButton: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                    search: true,
                                  }}
                                  title="OP Commercials"
                                />
                              ) : (
                                ""
                              )}
                            </Grid>
                          </TabPanel>
                          <TabPanel value={value} index={2}>
                            <Grid container spacing="1">
                              <Grid
                                item={6}
                                style={{
                                  height: "150px",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                  borderRight: "1px solid grey",
                                  width: "50%",
                                  marginBottom: "20px",
                                  borderBottom: "1px solid gray",
                                }}
                              >
                                <FormControl
                                  component="fieldset"
                                  style={{
                                    marginBottom: ".5rem",
                                    marginTop: "-.6rem",
                                  }}
                                >
                                  <h6
                                    component="legend"
                                    style={{
                                      color: "black",
                                      fontSize: "15px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Select Ip Catagory
                                  </h6>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="selectall"
                                        onClick={handleChnageforSelectAllIP}
                                        checked={
                                          chkipList.length < ipListData.length
                                            ? false
                                            : chkipList.length ===
                                              ipListData.length
                                            ? true
                                            : selectAllvalIp === true
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label="Select All"
                                  />
                                  <FormGroup aria-label="position" columns>
                                    {ipListData.map((item) => (
                                      <FormControlLabel
                                        value="start"
                                        control={<Checkbox color="primary" />}
                                        label={item.value}
                                        labelPlacement="end"
                                        checked={chkipList.includes(item.value)}
                                        onChange={() => {
                                          handleChangeChkboxforIP(item.value);
                                        }}
                                      />
                                    ))}
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                              {chkipList.length === 1 &&
                              chkipList.includes("Delivery") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Package
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={
                                            handleChnageforSelectDelivery
                                          }
                                          checked={
                                            ipChild.length <
                                            ipListData[0].child.length
                                              ? false
                                              : ipChild.length ===
                                                ipListData[0].child.length
                                              ? true
                                              : selectAllDelivery === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {ipListData[0].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          checked={ipChild.includes(
                                            item.display
                                          )}
                                          labelPlacement="end"
                                          onChange={() => {
                                            handleChangeChkboxIpChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : chkipList.length === 1 &&
                                chkipList.includes("Gynec") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Package
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={handleChnageforSelectGynic}
                                          checked={
                                            ipChild.length <
                                            ipListData[1].child.length
                                              ? false
                                              : ipChild.length ===
                                                ipListData[1].child.length
                                              ? true
                                              : selectGynic === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {ipListData[1].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          checked={ipChild.includes(
                                            item.display
                                          )}
                                          labelPlacement="end"
                                          onChange={() => {
                                            handleChangeChkboxIpChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : chkipList.length === 1 &&
                                chkipList.includes("Fertility") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Package
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={
                                            handleChnageforSelectFertility
                                          }
                                          checked={
                                            ipChild.length <
                                            ipListData[2].child.length
                                              ? false
                                              : ipChild.length ===
                                                ipListData[2].child.length
                                              ? true
                                              : selectFertility === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {ipListData[2].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          labelPlacement="end"
                                          checked={ipChild.includes(
                                            item.display
                                          )}
                                          onChange={() => {
                                            handleChangeChkboxIpChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : chkipList.length === 1 &&
                                chkipList.includes("NICU") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Package
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={handleChnageforSelectNicu}
                                          checked={
                                            ipChild.length <
                                            ipListData[4].child.length
                                              ? false
                                              : ipChild.length ===
                                                ipListData[4].child.length
                                              ? true
                                              : selectNicu === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {ipListData[4].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          labelPlacement="end"
                                          checked={ipChild.includes(
                                            item.display
                                          )}
                                          onChange={() => {
                                            handleChangeChkboxIpChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : chkipList.length === 1 &&
                                chkipList.includes("Open billing") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Package
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={
                                            handleChnageforSelectOpenbilling
                                          }
                                          checked={
                                            ipChild.length <
                                            ipListData[5].child.length
                                              ? false
                                              : ipChild.length ===
                                                ipListData[5].child.length
                                              ? true
                                              : selectOpenbilling === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {ipListData[5].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          labelPlacement="end"
                                          checked={ipChild.includes(
                                            item.display
                                          )}
                                          onChange={() => {
                                            handleChangeChkboxIpChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : (
                                ""
                              )}
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid
                              container
                              spacing={1}
                              style={{
                                marginTop: "20px",
                                borderTop: "1px grey black",
                              }}
                            >
                              <Grid item xs={6}>
                                {/* <Grid item xs={6}> */}
                                <FormControl
                                  component="fieldset"
                                  style={{
                                    marginBottom: ".5rem",
                                    marginTop: "-.6rem",
                                  }}
                                >
                                  <h6
                                    component="legend"
                                    style={{
                                      color: "black",
                                      fontSize: "15px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Select Room
                                  </h6>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="selectall"
                                        onClick={handleChangeForRoomTypeAll}
                                        checked={
                                          roomType.length < opRoomType.length
                                            ? false
                                            : roomType.length ===
                                              opRoomType.length
                                            ? true
                                            : selectRoomType === true
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label="Select All"
                                  />
                                  <FormGroup aria-label="position" columns>
                                    {roomType.map((item) => (
                                      <FormControlLabel
                                        value="start"
                                        control={<Checkbox color="primary" />}
                                        label={item.name}
                                        labelPlacement="end"
                                        checked={opRoomType.includes(item.name)}
                                        onChange={() => {
                                          handleChangeForRoomType(item.name);
                                        }}
                                      />
                                    ))}
                                  </FormGroup>
                                </FormControl>
                              </Grid>

                              <Grid item xs={6}>
                                <FormControl
                                  component="fieldset"
                                  style={{
                                    marginBottom: ".5rem",
                                    marginTop: "-.6rem",
                                  }}
                                >
                                  <h6
                                    component="legend"
                                    style={{
                                      color: "black",
                                      fontSize: "15px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Select MOP
                                  </h6>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="selectall"
                                        onClick={handleChangeForMOPTypeAll}
                                        checked={
                                          paymentMode.length < ipMop.length
                                            ? false
                                            : paymentMode.length ===
                                              ipMop.length
                                            ? true
                                            : selectipMop === true
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label="Select All"
                                  />
                                  <FormGroup aria-label="position" columns>
                                    {paymentMode.map((item) => (
                                      <FormControlLabel
                                        value="start"
                                        control={<Checkbox color="primary" />}
                                        label={item.name}
                                        labelPlacement="end"
                                        checked={ipMop.includes(item.name)}
                                        onChange={() => {
                                          handleChangeForMop(item.name);
                                        }}
                                      />
                                    ))}
                                  </FormGroup>
                                </FormControl>
                              </Grid>

                              <Grid item xs={6}>
                                <TextField
                                  variant="outlined"
                                  name="count"
                                  label="Count"
                                  onBlur={ipCalculation}
                                  value={ipservice.count}
                                  onInput={(e) => onlyNumbers(e)}
                                  onChange={handleIpserviceInputChange}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <label style={{ marginTop: "-40px" }}>
                                  Select No of Month{" "}
                                </label>
                                <select
                                  className="form-control"
                                  style={{ color: "black" }}
                                  id="MonthCount"
                                  // onChange={getDiscount}
                                >
                                  <option value="0">Select No of Month</option>
                                  <option value=" 1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                </select>
                              </Grid>

                              <Grid item xs={6}>
                                <TextField
                                  onBlur={ipCalculation}
                                  variant="outlined"
                                  name="ippackageamount"
                                  value="5000"
                                  onChange={handleIpserviceInputChange}
                                  disabled
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disable={disableippackageamountperc}
                                  variant="outlined"
                                  name="ippackageamountperc"
                                  label=" Package Amount Percentage"
                                  value={ipservice.ippackageamountperc}
                                  onInput={(e) => onlyNumbers(e)}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                {" "}
                                <TextField
                                  disable={disableipsurgeonfeeperc}
                                  variant="outlined"
                                  name="ipsurgeonfeeamount"
                                  label="Surgeon Fee Amount"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipservice.ipsurgeonfeeamount}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disable={disableipsurgeonfeeperc}
                                  variant="outlined"
                                  name="ipsurgeonfeeperc"
                                  label="Surgeon Fee Percentage"
                                  value={ipservice.ipsurgeonfeeperc}
                                  onInput={(e) => onlyNumbers(e)}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              {/* IP GROSS BILL PER UNIT */}
                              <Grid item xs={6}>
                                <TextField
                                  // disable={disableippackageamountperc}
                                  variant="outlined"
                                  name="ipgrossbillperunit"
                                  label="Gross Bill Per Unit"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipservice.ipgrossbillperunit}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disable={disableippackageamountperc}
                                  variant="outlined"
                                  name="ipdeductionperunit"
                                  label="Deduction Per Unit"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipservice.ipdeductionperunit}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disable={disableippackageamountperc}
                                  variant="outlined"
                                  name="ipgrossbill"
                                  label="Gross Bill Total"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipgrossbillTot}
                                  // onChange={handleIpserviceInputChange}
                                  // onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disable={disableippackageamountperc}
                                  variant="outlined"
                                  name="ipgrossbillpercentage"
                                  label="Gross Bill Percentage"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipservice.ipgrossbillpercentage}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={4}>
                                <select
                                  className="form-control"
                                  style={{ color: "black" }}
                                  id="Discount"
                                  onChange={getIPDiscount}
                                  name="ipdiscountType"
                                  value={ipdiscount}
                                >
                                  <option value="Not applicable">
                                    Is Discount Applicable
                                  </option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </Grid>

                              <Grid item xs={4}>
                                {" "}
                                <select
                                  className="form-control"
                                  style={{ color: "black" }}
                                  id="DeductionCategory"
                                  name="DeductionCategory"
                                  onChange={handleIpserviceInputChange}
                                >
                                  <option>Select Deduction Category</option>
                                  <option>Radiology</option>
                                  <option>Lab</option>
                                  <option>Pharmacy</option>
                                </select>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  disable={disableippackageamountperc}
                                  variant="outlined"
                                  name="ipdeductaionvalue"
                                  label="Deduction Value"
                                  // onInput={(e) => onlyNumbers(e)}
                                  value={ipdeductiontot}
                                  // value={ipservice.ipdeductaionvalue}
                                  // onChange={handleIpserviceInputChange}
                                  // onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={6}>
                                <TextField
                                  variant="outlined"
                                  name="ipnetbillpercentage"
                                  label="Net Bill Percentage"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipservice.ipnetbillpercentage}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={8}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="ipnetbillamount"
                                  label="Net Bill Amount"
                                  value={
                                    ipdiscount === "No" ? 0 : ipNetBillAmountTot
                                  }
                                  onChange={handleIpserviceInputChange}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  variant="outlined"
                                  name="ipfixedamount"
                                  label="Fixed Amount"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipservice.ipfixedamount}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="iptotalamount"
                                  label="Estimated Earning"
                                  value={ipAmountTot}
                                  onChange={handleIpserviceInputChange}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="payout"
                                  label="Payout "
                                  value={ipPayoutTotal}
                                  onChange={handleIpserviceInputChange}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  // onClick={opCalculation}
                                  style={{ height: "100%", width: "100%" }}
                                >
                                  Calculate
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ height: "100%" }}
                                  startIcon={<AddIcon />}
                                  onClick={addIpTableData}
                                >
                                  ADD
                                </Button>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  style={{ height: "100%", marginLeft: "18px" }}
                                  startIcon={<RefreshIcon />}
                                  onClick={RefreshIpRecord}
                                >
                                  REFRESH
                                </Button>
                              </Grid>

                              <Grid item xs={3}></Grid>
                        
                              <Grid item xs={12}>
                                {iptable.length > 0 ? (
                                  <Paper className={tables.root}>
                                    <MaterialTable
                                      columns={[
                                        {
                                          title: "Package Name",
                                          field: "itemGroup",
                                        },
                                        {
                                          title: "Room Type",
                                          field: "iproom",
                                        },
                                        {
                                          title: "Mode of Payment",
                                          field: "ipmodeofpayment",
                                        },
                                        {
                                          title: "Fixed Amount",
                                          field: "ipfixedamount",
                                        },
                                        {
                                          title: "Surgeon Fee Percentage",
                                          field: "ipsurgeonfeeperc",
                                        },
                                        {
                                          title: "Package Amount Percentage",
                                          field: "ippackageamountperc",
                                        },
                                        {
                                          title: "Discount Applicable",
                                          field: "ipdiscountType",
                                        },
                                        {
                                          title: "Deduction Value",
                                          field: "ipdeductaionvalue",
                                        },
                                        {
                                          title: "Net Bill Amount",
                                          field: "ipTotalNetBillAmount",
                                        },
                                        {
                                          title: "Amount",
                                          field: "ipTotalAmount",
                                        },
                                        {
                                          title: "Count",
                                          field: "count",
                                        },
                                        // { title: "Revenue", field: "revenue" },
                                        { title: "Payout", field: "payout" },
                                      ]}
                                      actions={[
                                        {
                                          icon: "delete",
                                          tooltip: "Delete",
                                          iconProps: {
                                            style: {
                                              fontSize: "24px",
                                              color: "#f55151",
                                            },
                                          },
                                          onClick: (event, row) =>
                                            deleteIptableRow(row),
                                        },
                                      ]}
                                      data={iptable}
                                      options={{
                                        sorting: true,
                                        exportButton: true,
                                        pageSize: 5,
                                        pageSizeOptions: [
                                          5, 10, 50, 100, 200, 500,
                                        ],
                                        search: true,
                                      }}
                                      title="IN-Patient Service"
                                    />
                                  </Paper>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>
                            {totalippayout && totalippayout.length > 0 ? (
                              <Grid
                                xs={12}
                                style={{
                                  float: "right",
                                  marginTop: "20px",
                                  color: "black",
                                }}
                              >
                                Total Payout:{" "}
                                {totalippayout.reduce((a, b) => a + b)}
                              </Grid>
                            ) : (
                              ""
                            )}

                            <br />
                            <br />

                            {iptable && iptable.length > 0 ? (
                              <MaterialTable
                                columns={[
                                  {
                                    title: "Item Group/Procedures",
                                    field: "itemgroup",
                                  },
                                  { title: "Room Category", field: "iproom" },
                                  { title: "MOP", field: "ipmodeofpayment" },

                                  {
                                    title: "Commercial Term",
                                    field: "Action",
                                    filtering: false,
                                    render: (row) => row.commercialterm,
                                  },
                                ]}
                                data={commercialIpTable}
                                options={{
                                  // filtering: true,
                                  sorting: true,
                                  exportButton: true,
                                  pageSize: 5,
                                  pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                  search: true,
                                }}
                                title="IP Commercials"
                              />
                            ) : (
                              ""
                            )}
                          </TabPanel>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col md={12}>
                        <div className={classes.root}>
                          <AppBar
                            position="static"
                            style={{
                              backgroundColor: "#ffffff1f",
                              color: "black",
                            }}
                          >
                            <Tabs
                              variant="fullWidth"
                              value={value}
                              onChange={handleChange}
                              aria-label="nav tabs example"
                            >
                              <LinkTab
                                label=" Fixed Fees"
                                href="/drafts"
                                {...a11yProps(0)}
                                // disabled={table.length > 0}
                              />
                              <LinkTab
                                label=" Out-Patient Services"
                                href="/trash"
                                {...a11yProps(1)}
                                // disabled={disable}
                              />
                              <LinkTab
                                label=" In-Patient Services"
                                href="/spam"
                                {...a11yProps(2)}
                                // disabled={disable}
                              />
                            </Tabs>
                          </AppBar>
                          <TabPanel value={value} index={0}>
                            <Row>
                              <Col md={6}>
                                <label for="ctc-month">
                                  <h6>Professional Fee / Month</h6>
                                </label>
                                <TextField
                                  variant="outlined"
                                  name="ctcmonth"
                                  label="Professional Fee / Month"
                                  value={ctcpermonth ? numberWithCommas(ctcpermonth) : ""}
                                  onChange={handleInputChangeForCtc}
                                  // onBlur={unitCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Col>
                              <Col md={2}>
                                <br />
                                {!visibleFee ? (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      height: "50%",
                                      marginTop: "10px",
                                      padding: "3px",
                                      width: "60%",
                                    }}
                                    startIcon={<AddIcon />}
                                    fullWidth
                                    onClick={makeVisible}
                                  >
                                    ADD
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{
                                      height: "50%",
                                      marginTop: "10px",
                                      padding: "3px",
                                      width: "70%",
                                    }}
                                    startIcon={<RemoveIcon />}
                                    fullWidth
                                    onClick={makeVisiblefalse}
                                  >
                                    REMOVE
                                  </Button>
                                )}
                              </Col>
                            </Row>
                            {visible ? (
                              <Row style={{ marginTop: "10px" }}>
                                <Col md={6}>
                                  <label for="ctc-month">
                                    <h6>Additional Fee Name</h6>
                                  </label>
                                  <TextField
                                    variant="outlined"
                                    name="additionalfeename"
                                    label="Additional Fee Name"
                                    value={additionalfeename}
                                    onChange={handleInputChangeForAddName}
                                    // onBlur={unitCalculation}
                                    size="small"
                                    fullWidth
                                  />
                                </Col>
                                <Col md={6}>
                                  <label for="ctc-month">
                                    <h6>Additonal Fee value</h6>
                                  </label>
                                  <TextField
                                    variant="outlined"
                                    name="additionalfee"
                                    label="Additonal Fee value"
                                    value={additionalfee ? numberWithCommas(additionalfee) : ""}
                                    onChange={handleInputChangeFoAddVal}
                                    // onBlur={unitCalculation}
                                    size="small"
                                    fullWidth
                                  />
                                </Col>
                              </Row>
                            ) : (
                              ""
                            )}
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                            <Grid container spacing={1}>
                              <Grid
                                item={6}
                                style={{
                                  height: "150px",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                  width: "50%",
                                  borderRight: "1px solid grey",
                                  marginBottom: "20px",
                                  borderBottom: "1px solid gray",
                                }}
                              >
                                <FormControl
                                  component="fieldset"
                                  style={{
                                    marginBottom: ".5rem",
                                    marginTop: "-.6rem",
                                  }}
                                >
                                  <h6
                                    component="legend"
                                    style={{
                                      color: "black",
                                      fontSize: "15px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Select Item Group
                                  </h6>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="selectall"
                                        onClick={handleChnageforSelectAllOp}
                                        checked={
                                          chkopList.length < 8
                                            ? false
                                            : chkopList.length === 8
                                            ? true
                                            : selectAllval === true
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label="Select All"
                                  />
                                  <FormGroup aria-label="position" columns>
                                    {opListData.map((item) => (
                                      <FormControlLabel
                                        value="start"
                                        control={
                                          <Checkbox
                                            color="primary"
                                            checked={chkopList.includes(
                                              item.value
                                            )}
                                          />
                                        }
                                        label={item.value}
                                        labelPlacement="end"
                                        onChange={() => {
                                          handleChangeChkboxforOP(item.value);
                                        }}
                                      />
                                    ))}
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                              {chkopList.length === 1 &&
                              chkopList.includes("Radiology") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Procedure
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={
                                            handleChnageforSelectRadiology
                                          }
                                          checked={
                                            radiologyChild.length <
                                            opListData[3].child.length
                                              ? false
                                              : radiologyChild.length ===
                                                opListData[3].child.length
                                              ? true
                                              : selectRadiology === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {opListData[3].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          checked={radiologyChild.includes(
                                            item.display
                                          )}
                                          labelPlacement="end"
                                          onChange={() => {
                                            handleChangeChkboxRadiologyChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : chkopList.length === 1 &&
                                chkopList.includes(
                                  "Non OT Procedures/OP Procedures"
                                ) ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Procedure
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={
                                            handleChnageforSelectNonotProcedure
                                          }
                                          checked={
                                            radiologyChild.length <
                                            opListData[6].child.length
                                              ? false
                                              : radiologyChild.length ===
                                                opListData[6].child.length
                                              ? true
                                              : selectNonOt === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {opListData[6].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          labelPlacement="end"
                                          checked={radiologyChild.includes(
                                            item.display
                                          )}
                                          onChange={() => {
                                            handleChangeChkboxRadiologyChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : chkopList.length === 1 &&
                                chkopList.includes("Other Charges") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Procedure
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={
                                            handleChnageforSelectOtherProcedure
                                          }
                                          checked={
                                            radiologyChild.length <
                                            opListData[7].child.length
                                              ? false
                                              : radiologyChild.length ===
                                                opListData[7].child.length
                                              ? true
                                              : selectNonOt === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {opListData[7].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          labelPlacement="end"
                                          checked={radiologyChild.includes(
                                            item.display
                                          )}
                                          onChange={() => {
                                            handleChangeChkboxRadiologyChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : (
                                ""
                              )}
                              <Grid item xs={12}></Grid>
                              <Grid item xs={6}>
                                <TextField
                                  variant="outlined"
                                  name="opcount"
                                  label="Count"
                                  value={opservice.opcount}
                                  onChange={handleInputChange}
                                  onBlur={opCalculation}
                                  onInput={(e) => onlyNumbers(e)}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <label style={{ marginTop: "-40px" }}>
                                  Select No of Month{" "}
                                </label>
                                <select
                                  className="form-control"
                                  style={{ color: "black" }}
                                  id="MonthCount"
                                  // onChange={getDiscount}
                                >
                                  <option value="0">Select No of Month</option>
                                  <option value=" 1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                </select>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  variant="outlined"
                                  name="opperunitcharge"
                                  label="Per Unit Charge"
                                  value={opservice.opperunitcharge}
                                  onChange={handleInputChange}
                                  onBlur={opCalculation}
                                  onInput={(e) => onlyNumbers(e)}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={4}>
                                <select
                                  className="form-control"
                                  style={{ color: "black" }}
                                  id="Discount"
                                  onChange={getDiscount}
                                >
                                  <option value="0">
                                    Is Discount Applicable
                                  </option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  variant="outlined"
                                  name="opadhoc"
                                  label="Deduction / Unit"
                                  value={opservice.opadhoc}
                                  onInput={(e) => onlyNumbers(e)}
                                  onChange={handleInputChange}
                                  onBlur={opCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  variant="outlined"
                                  name="fixedpercentage"
                                  label="Fixed Percentage"
                                  value={opservice.fixedpercentage}
                                  onChange={handleInputChange}
                                  onBlur={opCalculation}
                                  onKeyUp={fixedPercentageOrAmount}
                                  onInput={(e) => onlyNumbers(e)}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  variant="outlined"
                                  name="fixedamount"
                                  label="Fixed Amount"
                                  value={opservice.fixedamount}
                                  onKeyUp={fixedPercentageOrAmount}
                                  onChange={handleInputChange}
                                  onBlur={opCalculation}
                                  onInput={(e) => onlyNumbers(e)}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  variant="outlined"
                                  name="totalRevenue"
                                  label="Total Revenue"
                                  value={opservice.totalRevenue}
                                  onKeyUp={fixedPercentageOrAmount}
                                  onChange={handleInputChange}
                                  onBlur={opCalculation}
                                  onInput={(e) => onlyNumbers(e)}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="opamount"
                                  label="Gross"
                                  value={gross}
                                  onChange={handleInputChange}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="netamount"
                                  label="Net"
                                  value={Discount === "No" ? 0 : opNetAmount}
                                  onChange={handleInputChange}
                                  onBlur={fixedpayCalculation}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  variant="outlined"
                                  name="opadhoc"
                                  style={{ backgroundColor: "#80808030" }}
                                  label="Total Deduction"
                                  value={totaldeduction}
                                  onInput={(e) => onlyNumbers(e)}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="netamount"
                                  label="Estimated Earning"
                                  value={opRevenue}
                                  onChange={handleInputChange}
                                  onBlur={fixedpayCalculation}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="oppayout"
                                  label="Payout"
                                  value={opNetPayout}
                                  onChange={handleInputChange}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={12}>
                                {" "}
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  onClick={opCalculation}
                                  style={{ height: "100%", width: "100%" }}
                                >
                                  Calculate
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ height: "100%" }}
                                  startIcon={<AddIcon />}
                                  // fullWidth
                                  onClick={addTableData}
                                >
                                  ADD
                                </Button>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  style={{ height: "100%", marginLeft: "18px" }}
                                  startIcon={<RefreshIcon />}
                                  // fullWidth
                                  onClick={refreshopRecord}
                                >
                                  REFRESH
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                              </Grid>

                              <Grid item xs={12}>
                                {table.length > 0 ? (
                                  <Paper className={tables.root}>
                                    <MaterialTable
                                      columns={[
                                        {
                                          title: "Item Group Name",
                                          field: "itemGroup",
                                        },

                                        {
                                          title: "Discount Applicable",
                                          field: "opdiscounttype",
                                        },
                                        {
                                          title: "Deduction Value",
                                          field: "opadhoc",
                                        },
                                        {
                                          title: "Fixed Amount",
                                          field: "fixedamount",
                                        },
                                        {
                                          title: "Fixed Percentage",
                                          field: "fixedpercentage",
                                        },
                                        {
                                          title: "Total Revenue",
                                          field: "totalRevenue",
                                        },
                                        {
                                          title: "Count",
                                          field: "opcount",
                                        },
                                        {
                                          title: "Per Unit Charge",
                                          field: "opperunitcharge",
                                        },
                                        { title: "Gross", field: "opgross" },
                                        { title: "Net", field: "opnet" },
                                        {
                                          title: "Revenue",
                                          field: "oprevenue",
                                        },
                                        // { title: "Revenue", field: "oprevenue" },
                                        { title: "Payout", field: "oppayout" },
                                      ]}
                                      actions={[
                                        {
                                          icon: "delete",
                                          tooltip: "Delete",
                                          iconProps: {
                                            style: {
                                              fontSize: "24px",
                                              color: "#f55151",
                                            },
                                          },
                                          onClick: (event, row) =>
                                            deleteRow(row),
                                        },
                                      ]}
                                      data={table}
                                      options={{
                                        // filtering: true,
                                        sorting: true,
                                        exportButton: true,
                                        pageSize: 5,
                                        pageSizeOptions: [
                                          5, 10, 50, 100, 200, 500,
                                        ],
                                        search: true,
                                      }}
                                      title="Out-Patient Service"
                                    />
                                  </Paper>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>

                            <br />

                            <Grid item xs={12}>
                              {table && table.length > 0 ? (
                                <MaterialTable
                                  columns={[
                                    {
                                      title: "Item Group/Procedures",
                                      field: "itemGroup",
                                    },

                                    {
                                      title: "Commercial Term",
                                      field: "Action",
                                      filtering: false,
                                      render: (row) =>
                                        row.totalRevenue !== 0
                                          ? row.fixedpercentage +
                                            "% of the Total Revenue"
                                          : row.opdiscounttype === "Yes" &&
                                            row.opadhoc !== 0
                                          ? row.fixedpercentage !== 0
                                            ? row.fixedpercentage +
                                              "% of the Net Bill excluding Deductions"
                                            : row.fixedamount + " /- Per case"
                                          : row.opdiscounttype === "No" &&
                                            row.opadhoc === 0
                                          ? row.fixedpercentage !== 0
                                            ? row.fixedpercentage +
                                              " % of the Gross Amount"
                                            : row.fixedamount + " /- Per case"
                                          : row.opdiscounttype === "Yes" &&
                                            row.opadhoc === 0
                                          ? row.fixedpercentage !== 0
                                            ? row.fixedpercentage +
                                              " % of the Net Amount"
                                            : row.fixedamount + " /- Per case"
                                          : row.opdiscounttype === "No" &&
                                            row.opadhoc !== 0
                                          ? row.fixedpercentage !== 0
                                            ? row.fixedpercentage +
                                              "% of the Gross Bill excluding Deductions"
                                            : row.fixedamount + " /- Per case"
                                          : "",
                                    },
                                  ]}
                                  data={table}
                                  options={{
                                    // filtering: true,
                                    sorting: true,
                                    exportButton: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                    search: true,
                                  }}
                                  title="OP Commercials"
                                />
                              ) : (
                                ""
                              )}
                            </Grid>
                          </TabPanel>
                          <TabPanel value={value} index={2}>
                            <Grid container spacing="1">
                              <Grid
                                item={6}
                                style={{
                                  height: "150px",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                  borderRight: "1px solid grey",
                                  width: "50%",
                                  marginBottom: "20px",
                                  borderBottom: "1px solid gray",
                                }}
                              >
                                <FormControl
                                  component="fieldset"
                                  style={{
                                    marginBottom: ".5rem",
                                    marginTop: "-.6rem",
                                  }}
                                >
                                  <h6
                                    component="legend"
                                    style={{
                                      color: "black",
                                      fontSize: "15px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Select Ip Catagory
                                  </h6>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="selectall"
                                        onClick={handleChnageforSelectAllIP}
                                        checked={
                                          chkipList.length < ipListData.length
                                            ? false
                                            : chkipList.length ===
                                              ipListData.length
                                            ? true
                                            : selectAllvalIp === true
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label="Select All"
                                  />
                                  <FormGroup aria-label="position" columns>
                                    {ipListData.map((item) => (
                                      <FormControlLabel
                                        value="start"
                                        control={<Checkbox color="primary" />}
                                        checked={chkipList.includes(item.value)}
                                        label={item.value}
                                        labelPlacement="end"
                                        onChange={() => {
                                          handleChangeChkboxforIP(item.value);
                                        }}
                                      />
                                    ))}
                                  </FormGroup>
                                </FormControl>
                              </Grid>
                              {chkipList.length === 1 &&
                              chkipList.includes("Delivery") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Package
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={
                                            handleChnageforSelectDelivery
                                          }
                                          checked={
                                            chkipList.length <
                                            ipListData[0].child.length
                                              ? false
                                              : chkipList.length ===
                                                ipListData[0].child.length
                                              ? true
                                              : selectAllDelivery === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {ipListData[0].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          checked={ipChild.includes(
                                            item.display
                                          )}
                                          label={item.value}
                                          labelPlacement="end"
                                          onChange={() => {
                                            handleChangeChkboxIpChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : chkipList.length === 1 &&
                                chkipList.includes("Gynec") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Package
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={handleChnageforSelectGynic}
                                          checked={
                                            ipChild.length <
                                            ipListData[1].child.length
                                              ? false
                                              : ipChild.length ===
                                                ipListData[1].child.length
                                              ? true
                                              : selectGynic === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {ipListData[1].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          labelPlacement="end"
                                          checked={ipChild.includes(
                                            item.display
                                          )}
                                          onChange={() => {
                                            handleChangeChkboxIpChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : chkipList.length === 1 &&
                                chkipList.includes("Fertility") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Package
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={
                                            handleChnageforSelectFertility
                                          }
                                          checked={
                                            ipChild.length <
                                            ipListData[2].child.length
                                              ? false
                                              : ipChild.length ===
                                                ipListData[2].child.length
                                              ? true
                                              : selectFertility === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {ipListData[2].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          labelPlacement="end"
                                          checked={ipChild.includes(
                                            item.display
                                          )}
                                          onChange={() => {
                                            handleChangeChkboxIpChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : chkipList.length === 1 &&
                                chkipList.includes("NICU") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Package
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={handleChnageforSelectNicu}
                                          checked={
                                            ipChild.length <
                                            ipListData[4].child.length
                                              ? false
                                              : ipChild.length ===
                                                ipListData[4].child.length
                                              ? true
                                              : selectNicu === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {ipListData[4].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          labelPlacement="end"
                                          checked={ipChild.includes(
                                            item.display
                                          )}
                                          onChange={() => {
                                            handleChangeChkboxIpChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : chkipList.length === 1 &&
                                chkipList.includes("Open billing") ? (
                                <Grid
                                  item
                                  xs={6}
                                  style={{
                                    height: "150px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    width: "100%",
                                    borderBottom: "1px solid gray",
                                  }}
                                >
                                  <FormControl
                                    component="fieldset"
                                    style={{
                                      marginBottom: ".5rem",
                                      marginTop: "-.6rem",
                                    }}
                                  >
                                    <h6
                                      component="legend"
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Select Package
                                    </h6>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name="selectall"
                                          onClick={
                                            handleChnageforSelectOpenbilling
                                          }
                                          checked={
                                            ipChild.length <
                                            ipListData[5].child.length
                                              ? false
                                              : ipChild.length ===
                                                ipListData[5].child.length
                                              ? true
                                              : selectOpenbilling === true
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label="Select All"
                                    />
                                    <FormGroup aria-label="position" columns>
                                      {ipListData[5].child.map((item) => (
                                        <FormControlLabel
                                          value="start"
                                          control={<Checkbox color="primary" />}
                                          label={item.value}
                                          labelPlacement="end"
                                          checked={ipChild.includes(
                                            item.display
                                          )}
                                          onChange={() => {
                                            handleChangeChkboxIpChild(
                                              item.display
                                            );
                                          }}
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              ) : (
                                ""
                              )}
                            </Grid>
                            <Divider orientation="vertical" flexItem />
                            <Grid
                              container
                              spacing={1}
                              style={{
                                marginTop: "20px",
                                borderTop: "1px grey black",
                              }}
                            >
                              <Grid item xs={6}>
                                {/* <Grid item xs={6}> */}
                                <FormControl
                                  component="fieldset"
                                  style={{
                                    marginBottom: ".5rem",
                                    marginTop: "-.6rem",
                                  }}
                                >
                                  <h6
                                    component="legend"
                                    style={{
                                      color: "black",
                                      fontSize: "15px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Select Room
                                  </h6>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="selectall"
                                        onClick={handleChangeForRoomTypeAll}
                                        checked={
                                          roomType.length < opRoomType.length
                                            ? false
                                            : roomType.length ===
                                              opRoomType.length
                                            ? true
                                            : selectRoomType === true
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label="Select All"
                                  />
                                  <FormGroup aria-label="position" columns>
                                    {roomType.map((item) => (
                                      <FormControlLabel
                                        value="start"
                                        control={<Checkbox color="primary" />}
                                        label={item.name}
                                        labelPlacement="end"
                                        checked={opRoomType.includes(item.name)}
                                        onChange={() => {
                                          handleChangeForRoomType(item.name);
                                        }}
                                      />
                                    ))}
                                  </FormGroup>
                                </FormControl>
                              </Grid>

                              <Grid item xs={6}>
                                <FormControl
                                  component="fieldset"
                                  style={{
                                    marginBottom: ".5rem",
                                    marginTop: "-.6rem",
                                  }}
                                >
                                  <h6
                                    component="legend"
                                    style={{
                                      color: "black",
                                      fontSize: "15px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Select MOP
                                  </h6>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="selectall"
                                        onClick={handleChangeForMOPTypeAll}
                                        checked={
                                          paymentMode.length < ipMop.length
                                            ? false
                                            : paymentMode.length ===
                                              ipMop.length
                                            ? true
                                            : selectipMop === true
                                            ? true
                                            : false
                                        }
                                      />
                                    }
                                    label="Select All"
                                  />
                                  <FormGroup aria-label="position" columns>
                                    {paymentMode.map((item) => (
                                      <FormControlLabel
                                        value="start"
                                        control={<Checkbox color="primary" />}
                                        label={item.name}
                                        labelPlacement="end"
                                        checked={ipMop.includes(item.name)}
                                        onChange={() => {
                                          handleChangeForMop(item.name);
                                        }}
                                      />
                                    ))}
                                  </FormGroup>
                                </FormControl>
                              </Grid>

                              <Grid item xs={6}>
                                <TextField
                                  variant="outlined"
                                  name="count"
                                  label="Count"
                                  onBlur={ipCalculation}
                                  value={ipservice.count}
                                  onInput={(e) => onlyNumbers(e)}
                                  onChange={handleIpserviceInputChange}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <label style={{ marginTop: "-40px" }}>
                                  Select No of Month{" "}
                                </label>
                                <select
                                  className="form-control"
                                  style={{ color: "black" }}
                                  id="MonthCount"
                                  // onChange={getDiscount}
                                >
                                  <option value="0">Select No of Month </option>
                                  <option value=" 1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                </select>
                              </Grid>

                              <Grid item xs={6}>
                                <TextField
                                  onBlur={ipCalculation}
                                  variant="outlined"
                                  name="ippackageamount"
                                  value="5000"
                                  onChange={handleIpserviceInputChange}
                                  disabled
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disable={disableippackageamountperc}
                                  variant="outlined"
                                  name="ippackageamountperc"
                                  label=" Package Amount Percentage"
                                  value={ipservice.ippackageamountperc}
                                  onInput={(e) => onlyNumbers(e)}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                {" "}
                                <TextField
                                  disable={disableipsurgeonfeeperc}
                                  variant="outlined"
                                  name="ipsurgeonfeeamount"
                                  label="Surgeon Fee Amount"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipservice.ipsurgeonfeeamount}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disable={disableipsurgeonfeeperc}
                                  variant="outlined"
                                  name="ipsurgeonfeeperc"
                                  label="Surgeon Fee Percentage"
                                  value={ipservice.ipsurgeonfeeperc}
                                  onInput={(e) => onlyNumbers(e)}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              {/* IP GROSS BILL PER UNIT */}
                              <Grid item xs={6}>
                                <TextField
                                  // disable={disableippackageamountperc}
                                  variant="outlined"
                                  name="ipgrossbillperunit"
                                  label="Gross Bill Per Unit"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipservice.ipgrossbillperunit}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disable={disableippackageamountperc}
                                  variant="outlined"
                                  name="ipdeductionperunit"
                                  label="Deduction Per Unit"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipservice.ipdeductionperunit}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disable={disableippackageamountperc}
                                  variant="outlined"
                                  name="ipgrossbill"
                                  label="Gross Bill Total"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipgrossbillTot}
                                  // onChange={handleIpserviceInputChange}
                                  // onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disable={disableippackageamountperc}
                                  variant="outlined"
                                  name="ipgrossbillpercentage"
                                  label="Gross Bill Percentage"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipservice.ipgrossbillpercentage}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <select
                                  className="form-control"
                                  style={{ color: "black" }}
                                  id="Discount"
                                  name="ipdiscountType"
                                  onChange={getIPDiscount}
                                  value={ipdiscount}
                                >
                                  <option value="Not applicable">
                                    Is Discount Applicable
                                  </option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </Grid>
                              <Grid item xs={4}>
                                {" "}
                                <select
                                  className="form-control"
                                  style={{ color: "black" }}
                                  id="DeductionCategory"
                                  name="DeductionCategory"
                                  onChange={handleIpserviceInputChange}
                                >
                                  <option>Select Deduction Category</option>
                                  <option>Radiology</option>
                                  <option>Lab</option>
                                  <option>Pharmacy</option>
                                </select>
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  disable={disableippackageamountperc}
                                  variant="outlined"
                                  name="ipdeductaionvalue"
                                  label="Deduction Value"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipdeductiontot}
                                  // value={ipservice.ipdeductaionvalue}
                                  // onChange={handleIpserviceInputChange}
                                  // onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>

                              <Grid item xs={3}>
                                <TextField
                                  variant="outlined"
                                  name="ipnetbillpercentage"
                                  label="Net Bill Percentage"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipservice.ipnetbillpercentage}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={8}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="ipnetbillamount"
                                  label="Net Bill Amount"
                                  value={
                                    ipdiscount === "No" ? 0 : ipNetBillAmountTot
                                  }
                                  onChange={handleIpserviceInputChange}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  variant="outlined"
                                  name="ipfixedamount"
                                  label="Fixed Amount"
                                  onInput={(e) => onlyNumbers(e)}
                                  value={ipservice.ipfixedamount}
                                  onChange={handleIpserviceInputChange}
                                  onBlur={ipCalculation}
                                  size="small"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="iptotalamount"
                                  label="Estimated Earning"
                                  value={ipAmountTot}
                                  onChange={handleIpserviceInputChange}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  style={{ backgroundColor: "#80808030" }}
                                  variant="outlined"
                                  name="payout"
                                  label="Payout "
                                  value={ipPayoutTotal}
                                  onChange={handleIpserviceInputChange}
                                  size="small"
                                  fullWidth
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  // onClick={opCalculation}
                                  style={{ height: "100%", width: "100%" }}
                                >
                                  Calculate
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ height: "100%" }}
                                  startIcon={<AddIcon />}
                                  onClick={addIpTableData}
                                >
                                  ADD
                                </Button>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  style={{ height: "100%", marginLeft: "18px" }}
                                  startIcon={<RefreshIcon />}
                                  onClick={RefreshIpRecord}
                                >
                                  REFRESH
                                </Button>
                              </Grid>

                              <Grid item xs={12}>
                                {iptable.length > 0 ? (
                                  <Paper className={tables.root}>
                                    <MaterialTable
                                      columns={[
                                        {
                                          title: "Package Name",
                                          field: "itemGroup",
                                        },
                                        {
                                          title: "Room Type",
                                          field: "iproom",
                                        },

                                        {
                                          title: "Mode of Payment",
                                          field: "ipmodeofpayment",
                                        },
                                        {
                                          title: "Fixed Amount",
                                          field: "ipfixedamount",
                                        },
                                        {
                                          title: "Deduction Value",
                                          field: "ipdeductaionvalue",
                                        },
                                        {
                                          title: "Discount Applicable",
                                          field: "ipdiscountType",
                                        },
                                        {
                                          title: "Surgeon Fee Percentage",
                                          field: "ipsurgeonfeeperc",
                                        },
                                        {
                                          title: "Package Amount Percentage",
                                          field: "ippackageamountperc",
                                        },
                                        // {
                                        //   title: "Gross Percentage",
                                        //   field: "ipgrossperc",
                                        // },
                                        {
                                          title: "Net Bill Amount",
                                          field: "ipTotalNetBillAmount",
                                        },
                                        {
                                          title: "Amount",
                                          field: "ipTotalAmount",
                                        },
                                        {
                                          title: "Count",
                                          field: "count",
                                        },
                                        // { title: "Revenue", field: "revenue" },
                                        { title: "Payout", field: "payout" },
                                      ]}
                                      actions={[
                                        {
                                          icon: "delete",
                                          tooltip: "Delete",
                                          iconProps: {
                                            style: {
                                              fontSize: "24px",
                                              color: "#f55151",
                                            },
                                          },
                                          onClick: (event, row) =>
                                            deleteIptableRow(row),
                                        },
                                      ]}
                                      data={iptable}
                                      options={{
                                        sorting: true,
                                        exportButton: true,
                                        pageSize: 5,
                                        pageSizeOptions: [
                                          5, 10, 50, 100, 200, 500,
                                        ],
                                        search: true,
                                      }}
                                      title="IN-Patient Service"
                                    />
                                  </Paper>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>
                            {totalippayout && totalippayout.length > 0 ? (
                              <Grid
                                xs={12}
                                style={{
                                  float: "right",
                                  marginTop: "20px",
                                  color: "black",
                                }}
                              >
                                Total Payout:{" "}
                                {totalippayout.reduce((a, b) => a + b)}
                              </Grid>
                            ) : (
                              ""
                            )}
                            <br />
                            <br />

                            {iptable && iptable.length > 0 ? (
                              <MaterialTable
                                columns={[
                                  {
                                    title: "Item Group/Procedures",
                                    field: "itemgroup",
                                  },
                                  { title: "Room Category", field: "iproom" },
                                  { title: "MOP", field: "ipmodeofpayment" },

                                  {
                                    title: "Commercial Term",
                                    field: "Action",
                                    filtering: false,
                                    render: (row) => row.commercialterm,
                                  },
                                ]}
                                data={commercialIpTable}
                                // columns={[
                                //   {
                                //     title: "Item Group/Procedures",
                                //     field: "itemGroup",
                                //   },
                                //   { title: "Room Category", field: "iproom" },
                                //   { title: "MOP", field: "ipmodeofpayment" },

                                //   {
                                //     title: "Commercial Term",
                                //     field: "Action",
                                //     filtering: false,
                                //     render: (row) =>
                                //       row.ipdeductaionvalue === 0 &&
                                //       row.ipfixedamount === 0 &&
                                //       row.ipdiscountType === "Yes" ? (
                                //         <>
                                //           {" "}
                                //           {row.ipsurgeonfeeperc === 0 &&
                                //           row.ippackageamountperc === 0 &&
                                //           row.ipnetbillpercentage !== 0
                                //             ? row.ipnetbillpercentage
                                //             : row.ipsurgeonfeeperc === 0 &&
                                //               row.ippackageamountperc === 0 &&
                                //               row.ipnetbillpercentage === 0
                                //             ? row.ipgrossbillpercentage
                                //             : row.ipsurgeonfeeperc === 0 &&
                                //               row.ipgrossbillpercentage === 0 &&
                                //               row.ipnetbillpercentage === 0
                                //             ? row.ippackageamountperc
                                //             : row.ipsurgeonfeeperc}
                                //           % of the Net Amount{" "}
                                //         </>
                                //       ) : row.ipdeductaionvalue !== 0 &&
                                //         row.ipfixedamount === 0 &&
                                //         row.ipdiscountType === "No" ? (
                                //         <>
                                //           {" "}
                                //           {row.ipsurgeonfeeperc === 0 &&
                                //           row.ippackageamountperc === 0 &&
                                //           row.ipnetbillpercentage !== 0
                                //             ? row.ipnetbillpercentage
                                //             : row.ipsurgeonfeeperc === 0 &&
                                //               row.ippackageamountperc === 0 &&
                                //               row.ipnetbillpercentage === 0
                                //             ? row.ipgrossbillpercentage
                                //             : row.ipsurgeonfeeperc === 0 &&
                                //               row.ipgrossbillpercentage === 0 &&
                                //               row.ipnetbillpercentage === 0
                                //             ? row.ippackageamountperc
                                //             : row.ipsurgeonfeeperc}
                                //           % of the Gross Bill excluding
                                //           Deductions{" "}
                                //         </>
                                //       ) : row.ipdeductaionvalue !== 0 &&
                                //         row.ipfixedamount === 0 &&
                                //         row.ipdiscountType === "Yes" ? (
                                //         <>
                                //           {" "}
                                //           {row.ipsurgeonfeeperc === 0 &&
                                //           row.ippackageamountperc === 0 &&
                                //           row.ipnetbillpercentage !== 0
                                //             ? row.ipnetbillpercentage
                                //             : row.ipsurgeonfeeperc === 0 &&
                                //               row.ippackageamountperc === 0 &&
                                //               row.ipnetbillpercentage === 0
                                //             ? row.ipgrossbillpercentage
                                //             : row.ipsurgeonfeeperc === 0 &&
                                //               row.ipgrossbillpercentage === 0 &&
                                //               row.ipnetbillpercentage === 0
                                //             ? row.ippackageamountperc
                                //             : row.ipsurgeonfeeperc}
                                //           % of the Net Bill excluding Deductions{" "}
                                //         </>
                                //       ) : row.ippackageamountperc !== 0 &&
                                //         row.ipfixedamount === 0 &&
                                //         row.ipdeductaionvalue === 0 &&
                                //         row.ipdiscountType === "No" ? (
                                //         <>
                                //           {" "}
                                //           {row.ippackageamountperc}% of the
                                //           Package Amount{" "}
                                //         </>
                                //       ) : row.ipsurgeonfeeperc !== 0 &&
                                //         row.ipfixedamount === 0 &&
                                //         row.ipdeductaionvalue === 0 &&
                                //         row.ipdiscountType === "No" ? (
                                //         <>
                                //           {" "}
                                //           {row.ipsurgeonfeeperc}% of the Surgeon
                                //           Fee{" "}
                                //         </>
                                //       ) : row.ipnetbillpercentage !== 0 &&
                                //         row.ipfixedamount === 0 &&
                                //         row.ipdeductaionvalue === 0 &&
                                //         row.ipdiscountType === "No" ? (
                                //         <>
                                //           {" "}
                                //           {row.ipnetbillpercentage}% of the Net
                                //           Bill Amount{" "}
                                //         </>
                                //       ) : row.ipgrossbillpercentage !== 0 &&
                                //         row.ipfixedamount === 0 &&
                                //         row.ipdeductaionvalue === 0 &&
                                //         row.ipdiscountType === "No" ? (
                                //         <>
                                //           {" "}
                                //           {row.ipgrossbillpercentage}% of the
                                //           Gross Bill Amount{" "}
                                //         </>
                                //       ) : row.ipfixedamount !== 0 ? (
                                //         <>
                                //           {" "}
                                //           Rs{row.ipfixedamount}/- of the Fixed
                                //           Amount{" "}
                                //         </>
                                //       ) : (
                                //         ""
                                //       ),
                                //   },
                                // ]}
                                // data={iptable}
                                options={{
                                  // filtering: true,
                                  sorting: true,
                                  exportButton: true,
                                  pageSize: 5,
                                  pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                  search: true,
                                }}
                                title="IP Commercials"
                              />
                            ) : (
                              ""
                            )}
                          </TabPanel>
                        </div>
                      </Col>
                    </Row>
                  )}

                  {/* ------------ */}
                  <br />
                  <Row
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Col
                      md={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        className="btn btn-primary"
                        style={{ width: "100px" }}
                        // onClick={handleOnboarding}
                        type="submit"
                      >
                        Confirm
                      </button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </form>
          </div>
        </div>
        <Backdrop
          className={backClasses.backdrop}
          open={backDropOpen}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
}

export default DoctorForm;
