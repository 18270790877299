import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col, Table, ProgressBar } from "react-bootstrap";
import { useLocation } from "react-router-dom";
//ui component

import ConfirmDialog from "../../Components/ConfirmDialog";
import Navbar from "../../Components/Navbar";
import DoctorSidebar from "../../Components/DoctorSidebar";
import Navmain from "../../Components/Navbar";
import Iframe from "react-iframe";
import { onboardState } from "../../CommomMethods/States";
import { onboardById } from "../../actions/onboardActions";
import { APIURL } from "../../CommomMethods/Fetch";
//import axios
import axios from "axios";
import Timeline from "../../Components/Timeline";
import Message from "../../Components/Message";
import Footer from "../../Components/Footer";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@material-ui/icons/Delete";
import { v4 as uuidv4 } from "uuid";
import Checkbox from "@material-ui/core/Checkbox";
import { dateFormatter } from "../../actions/createPositionActions";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Backdrop from "@material-ui/core/Backdrop";
import c9gif from '../../SampleFile/cloudnine.gif';
const stylesmodal = makeStyles({
  root: {
    marginTop: "18px",
  },
});
const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function DoctorDashboard({ history }) {
  const backClasses = backDropStyle();
  const [openDialog, setOpenDialog] = useState(false);
  const [backDropOpen, setbackDropOpen] = useState(false);
  const [file, setFile] = useState();
  const [resume, setresume] = useState();
  const [status, setStatus] = useState(false);
  const [openAlldoc, setOpenAlldoc] = useState(false);
  const [mcrc, setmcrc] = useState();
  const styleClasses = stylesmodal();
  const [pan, setpan] = useState();
  const [adhaar, setadhaar] = useState();
  const [cc, setcc] = useState();
  const [address, setaddress] = useState();
  const [payslip, setpayslip] = useState();
  const [mbbs, setmbbs] = useState();
  const [pg, setpg] = useState();
  const [certIssueDate, setCertIssueDate] = useState();
  const [certIssueDatiice, setcertIssueDatiice] = useState();
  const [photo, setphoto] = useState();
  const [form16, setform16] = useState();
  const [bank, setbank] = useState();
  const [iic, setiic] = useState();
  const [aac, setaac] = useState();
  const [odd, setodd] = useState();
  const [aacVal, setAacVal] = useState();
  const [oddVal, setOddVal] = useState();
  const [docValue, setdocValue] = useState();
  const [mcrcValue, setMcrcValue] = useState();
  const [bankstateval, setBankstateValue] = useState();
  const [bankval, setBankValue] = useState();
  const [pgval, setPgValue] = useState();
  const [photoval, setphotoValue] = useState();
  const [iicval, setiicValue] = useState();

  const dispatch = useDispatch();
  const [filename, setFilename] = useState("");
  const [list, setList] = useState([]);
  const [form16val, setForm16val] = useState("");
  const [filePath, setfilePath] = useState();
  const [remark, setRemark] = useState();
  const [selectedmcrc, setSelectedmcrc] = useState();
  const [aadNo, setAadNo] = useState();
  const [panNo, setpanNo] = useState();
  const [ccBank, setccBank] = useState();
  const [ccAccountNo, setccAccountNo] = useState();
  const [ifsc, setifsc] = useState();
  const [disable, setDisable] = useState(false);
  const [addressProof, setAddressProof] = useState();

  const [payslipno, setpayslipno] = useState();
  const [mbbsCertificate, setmbbsCertificate] = useState();
  const [uploadData, setUploadData] = useState([]);
  const [apidata, setapidata] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [resumestatus, setResumeStatus] = useState(false);
  const [rowdata, setRoWData] = useState(0);

  const [step, setStep] = useState(1);
  const back = useRef(null);
  const next = useRef(null);
  const reviewAndSubmit = useRef(null);
  const [checkedValue, setcheckedValue] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const [inputFields, setInputFields] = useState([
    { id: uuidv4(), additionalInfo: "" },
  ]);
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;
  const onboardId = useSelector((state) => state.onboardId);
  const { oboard } = onboardId;

  const location = useLocation();

  console.log(location, "location-pass-data");
  console.log(oboard, "oboard-data");

  useEffect(() => {
    dispatch(onboardById(user.user.id));
  }, [dispatch]);

  useEffect(() => {
    axios
      .get(APIURL() + "ProfileDetails/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
        setapidata(rows);
      });
  }, []);

  console.log(apidata, "apidata");
  const fileHandler = async (e) => {
    if (
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFilename(e.target.files[0].name);
      setfilePath(e.target.value);
    }
  };
  const fileHandler1 = (e) => {
    if (
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    if (e.target.files[0]) {
      if (
        e.target.files[0].type != "application/pdf" &&
        e.target.files[0].type != "application/msword" &&
        e.target.files[0].type !=
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        setConfirmDialog({
          isOpen: true,
          title: "Alert",
          subtitle: "Please upload pdf/doc file",
        });
        return;
      }
      setmcrc(e.target.files[0]);
      setfilePath(e.target.value);
      setFilename(e.target.files[0].name);
    }
  };
  const fileHandler2 = (e) => {
    if (
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    if (e.target.files[0]) {
      setpan(e.target.files[0]);
      setfilePath(e.target.value);
      setFilename(e.target.files[0].name);
    }
  };
  const fileHandler3 = (e) => {
    if (
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    if (e.target.files[0]) {
      setadhaar(e.target.files);
      setfilePath(e.target.value);
      setFilename(e.target.files.name);
    }
  };
  const fileHandler4 = (e) => {
    if (
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    if (e.target.files[0]) {
      setcc(e.target.files[0]);
      setfilePath(e.target.value);
      setFilename(e.target.files[0].name);
    }
  };
  const fileHandler5 = (e) => {
    if (
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    if (e.target.files[0]) {
      setaddress(e.target.files);
      setfilePath(e.target.value);
      setFilename(e.target.files.name);
    }
  };
  const fileHandler6 = (e) => {
    if (
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    if (e.target.files[0]) {
      setpayslip(e.target.files);
      setfilePath(e.target.value);
      setFilename(e.target.files.name);
    }
  };
  const fileHandler7 = (e) => {
    if (
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    if (e.target.files[0]) {
      setmbbs(e.target.files[0]);
      setfilePath(e.target.value);
      setFilename(e.target.files[0].name);
    }
  };
  const fileHandler8 = (e) => {
    if (
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    if (e.target.files[0]) {
      setpg(e.target.files);
      setfilePath(e.target.value);
      setFilename(e.target.files.name);
    }
  };
  const fileHandler9 = (e) => {
    // alert(e.target.files[0].type);
    if (
      e.target.files[0].type != "image/jpeg" &&
      e.target.files[0].type != "image/jpg" &&
      e.target.files[0].type != "image/png"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload jpeg/jpg/png file",
      });
      return;
    }
    if (e.target.files[0]) {
      setphoto(e.target.files[0]);
      setfilePath(e.target.value);
      setFilename(e.target.files[0].name);
    }
  };
  const fileHandler10 = (e) => {
    if (
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    if (e.target.files[0]) {
      setform16(e.target.files);
      setfilePath(e.target.value);
      setFilename(e.target.files.name);
    }
  };
  const fileHandler11 = (e) => {
    if (
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    if (e.target.files[0]) {
      setbank(e.target.files);
      setfilePath(e.target.value);
      setFilename(e.target.files.name);
    }
  };

  const fileHandler12 = (e) => {
    if (
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    if (e.target.files[0]) {
      setiic(e.target.files[0]);
      setfilePath(e.target.value);
      setFilename(e.target.files[0].name);
    }
  };

  const fileHandler13 = (e) => {
    if (
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    if (e.target.files[0]) {
      setaac(e.target.files[0]);
      setfilePath(e.target.value);
      setFilename(e.target.files[0].name);
    }
  };
  const fileHandler15 = (e) => {
    if (
      e.target.files[0].type != "image/jpeg" &&
      e.target.files[0].type != "image/jpg" &&
      e.target.files[0].type != "image/png" &&
      e.target.files[0].type != "application/pdf" &&
      e.target.files[0].type != "application/msword" &&
      e.target.files[0].type !=
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please upload pdf/doc file",
      });
      return;
    }
    console.log(e.target.files[0],"errors_table")
    if (e.target.files[0]) {
      setodd(e.target.files[0]);
      setfilePath(e.target.value);
      setFilename(e.target.files[0].name);
    }
  };

  useEffect(() => {
    axios.get(APIURL() + "onboard/" + user.user.id).then((res) => {
      if (res.data.onBoardData.length > 0) {
        setRoWData(res.data.onBoardData[0].id);
        var k = res.data.onBoardData.length - 1;
        var l = res.data.onBoardData[k];
        console.log(l, "ppp");
        if (l.currentAction !== "DR_INFO") {
          setDisable(true);
        }
      }
      console.log(res, "*!@@#$#####");
      // setRoWData(res.data.onBoardData[0].id);
    });
  }, []);

  const deleteDoc = (id) => {
    axios.delete(APIURL() + "documents/" + id).then((res) => {
      getAllDocList();
    });
  };

  const handleSubmit = (e, onUploadProgress) => {
    setbackDropOpen(true)
    e.preventDefault();
    setStatus(false);
    var resume = document.getElementById("Resume").innerHTML;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("doctorId", user.user.id);
    formData.append("url", filePath);
    formData.append("docTitle", resume);
    formData.append("docValue", docValue);
    formData.append("verificationRemark", remark);
    formData.append("docFile", file);
    setRefresh(false);
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        setStatus(true);
        setResumeStatus(true);
        setRefresh(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setbackDropOpen(false)
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setbackDropOpen(false)
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setbackDropOpen(false)
        }
        toast.success("Successfully Uploaded");
        setbackDropOpen(false)
      });
  };

  const handleMCRC = (e) => {
    e.preventDefault();
    setStatus(false);
    var MCRC = document.getElementById("MCRC").innerHTML;
    const formData = new FormData();
    formData.append("name", filename);
    formData.append("doctorId", user.user.id);
    formData.append("url", filePath);
    formData.append("docTitle", MCRC);
    formData.append("docValue", mcrcValue);
    formData.append("verificationRemark", remark);
    formData.append("docFile", mcrc);
    formData.append("registeration", selectedmcrc);
    formData.append("certIssueDate", certIssueDate);
    var k = moment(moment(certIssueDate).add(60, "M").calendar()).format(
      "DD-MM-YYYY"
    );
    formData.append("certExpiryDate", k);
    setRefresh(false);
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        setRefresh(true);
        setStatus(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        toast.success("Successfully Uploaded");
      });
  };

  const handlePan = (e) => {
    e.preventDefault();
    setStatus(false);
    var panTitle = document.getElementById("pan").innerHTML;
    const formData = new FormData();
    formData.append("docFile", pan);
    formData.append("doctorId", user.user.id);
    formData.append("name", filename);
    formData.append("docValue", panNo);
    formData.append("docTitle", panTitle);
    formData.append("url", filePath);
    setRefresh(false);
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        setRefresh(true);
        setStatus(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        toast.success("Successfully Uploaded");
      });
  };
  const handleAdhaar = (e) => {
    e.preventDefault();
    setStatus(false);
    var Adhaar = document.getElementById("Adhaar").innerHTML;
    // var verifystatus= "AWAITING APPROVAL"

    const formData = new FormData();
    // formData.append("docFile", adhaar);
    var a = "";
    for (var x = 0; x < adhaar.length; x++) {
      formData.append("docFiles", adhaar[x]);
      a = adhaar[x].name;
    }
    formData.append("doctorId", user.user.id);
    formData.append("docTitle", Adhaar);
    formData.append("name", a);
    formData.append("url", filePath);
    formData.append("docValue", aadNo);
    a = "";
    // formData.append("isVerified",verifystatus );
    setRefresh(false);
    axios
      .post(APIURL() + "documents/uploadMany", formData)
      .then(function (response) {
        setRefresh(true);
        setStatus(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        // toast.success("Successfully Uploaded");
      });
  };
  const handleCC = (e) => {
    e.preventDefault();
    setStatus(false);
    var CC = document.getElementById("CC").innerHTML;
    const formData = new FormData();
    formData.append("docFile", cc);
    formData.append("doctorId", user.user.id);
    formData.append("docTitle", CC);
    formData.append("name", filename);
    formData.append("url", filePath);
    formData.append("Bank", ccBank);
    formData.append("docValue", ccAccountNo);
    formData.append("Ifsc", ifsc);
    setRefresh(false);
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        setRefresh(true);
        setStatus(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        toast.success("Successfully Uploaded");
      });
  };
  const handleAdress = (e) => {
    e.preventDefault();
    setStatus(false);
    var AddressProof = document.getElementById("AddressProofid").innerHTML;
    const formData = new FormData();
    var a = "";
    for (var x = 0; x < address.length; x++) {
      formData.append("docFiles", address[x]);
      a = address[x].name;
    }

    formData.append("doctorId", user.user.id);
    formData.append("docTitle", AddressProof);
    formData.append("name", a);
    formData.append("docValue", addressProof);
    formData.append("url", filePath);
    a = "";
    setRefresh(false);
    axios
      .post(APIURL() + "documents/uploadMany", formData)
      .then(function (response) {
        setStatus(true);
        setRefresh(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };
  const handlePayslip = (e) => {
    e.preventDefault();
    setStatus(false);
    var paySlip = document.getElementById("paySlip").innerHTML;
    const formData = new FormData();
    var a = "";
    for (var x = 0; x < payslip.length; x++) {
      formData.append("docFiles", payslip[x]);
      a = payslip[x].name;
    }
    formData.append("name", a);
    formData.append("doctorId", user.user.id);
    formData.append("docTitle", paySlip);
    formData.append("docValue", payslipno);
    formData.append("url", filePath);
    a = "";
    setRefresh(false);
    axios
      .post(APIURL() + "documents/uploadMany", formData)
      .then(function (response) {
        setRefresh(true);
        setStatus(false);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };
  const handleMbbs = (e) => {
    e.preventDefault();
    setStatus(false);
    var Mbbs = document.getElementById("Mbbs").innerHTML;
    const formData = new FormData();
    formData.append("doctorId", user.user.id);
    formData.append("docTitle", Mbbs);
    formData.append("docFile", mbbs);
    formData.append("docValue", mbbsCertificate);
    formData.append("name", filename);
    formData.append("url", filePath);
    setRefresh(false);
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        setRefresh(true);
        setStatus(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        toast.success("Successfully Uploaded");
      });
  };
  const handlePg = (e) => {
    e.preventDefault();
    setStatus(false);
    var PG = document.getElementById("PG").innerHTML;
    const formData = new FormData();
    var a = "";
    for (var x = 0; x < pg.length; x++) {
      formData.append("docFiles", pg[x]);
      a = pg[x].name;
    }
    formData.append("docValue", pgval);
    formData.append("doctorId", user.user.id);
    formData.append("docTitle", PG);
    formData.append("name", a);
    formData.append("url", filePath);
    a = "";
    setRefresh(false);
    axios
      .post(APIURL() + "documents/uploadMany", formData)
      .then(function (response) {
        setRefresh(true);
        setStatus(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };
  const handlePhoto = (e) => {
    e.preventDefault();
    setStatus(false);
    var Photo = document.getElementById("photo").innerHTML;
    const formData = new FormData();
    formData.append("docTitle", Photo);
    formData.append("docValue", photoval);
    formData.append("doctorId", user.user.id);
    formData.append("docFile", photo);
    formData.append("name", filename);
    formData.append("url", filePath);
    setRefresh(false);

    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        setRefresh(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setStatus(true);
        toast.success("Successfully Uploaded");
      });
  };
  const handleForm16 = (e) => {
    e.preventDefault();
    setStatus(false);
    var Form16 = document.getElementById("Form16").innerHTML;

    const formData = new FormData();
    var a = "";
    for (var x = 0; x < form16.length; x++) {
      formData.append("docFiles", form16[x]);
      a = form16[x].name;
    }
    formData.append("docValue", form16val);
    formData.append("doctorId", user.user.id);
    formData.append("docTitle", Form16);
    formData.append("name", a);
    a = "";
    formData.append("url", filePath);

    setRefresh(false);
    axios
      .post(APIURL() + "documents/uploadMany", formData)
      .then(function (response) {
        setRefresh(true);
        setStatus(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };
  const handleBank = (e) => {
    e.preventDefault();
    setStatus(false);
    var BankStatement = document.getElementById("BankStatement").innerHTML;
    const formData = new FormData();
    var a = "";
    for (var x = 0; x < bank.length; x++) {
      formData.append("docFiles", bank[x]);
      a = bank[x].name;
      //  formData.append("name", );
    }
    formData.append("doctorId", user.user.id);
    formData.append("docValue", bankstateval);
    formData.append("docTitle", BankStatement);
    formData.append("name", a);
    formData.append("url", filePath);
    a = "";
    setRefresh(false);

    axios
      .post(APIURL() + "documents/uploadMany", formData)
      .then(function (response) {
        setRefresh(true);
        setStatus(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const handleIIC = (e) => {
    e.preventDefault();
    setStatus(false);
    var IIc = document.getElementById("IIc").innerHTML;
    // var dob = document.getElementById("dob").value;
    const formData = new FormData();
    formData.append("doctorId", user.user.id);
    formData.append("docTitle", IIc);
    formData.append("docValue", iicval);
    formData.append("docFile", iic);
    formData.append("name", filename);
    formData.append("url", filePath);
    formData.append("certIssueDate", certIssueDatiice);
    var k = moment(moment(certIssueDatiice).add(36, "M").calendar()).format(
      "DD-MM-YYYY"
    );
    formData.append("certExpiryDate", k);
    setRefresh(false);
    const docdata = {
      indemnityexpiry: certIssueDatiice,
    };
    console.log(docdata, "doc-data");
    axios
      .put(APIURL() + "ProfileDetails/indemnity/" + user.user.id, docdata)
      .then((res) => {
        console.log(res, "RES");
      });
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        setRefresh(true);
        setStatus(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        toast.success("Successfully Uploaded");
      });
  };

  const handleAAc = (e) => {
    e.preventDefault();
    setStatus(false);
    var AAc = document.getElementById("AAC").innerHTML;
    const formData = new FormData();
    formData.append("doctorId", user.user.id);
    formData.append("docTitle", AAc);
    formData.append("docFile", aac);
    formData.append("docValue", aacVal);
    formData.append("name", filename);
    formData.append("url", filePath);
    setRefresh(false);
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        setRefresh(true);
        setStatus(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        toast.success("Successfully Uploaded");
      });
  };
  const handleOdd = (e) => {
    e.preventDefault();
    setStatus(false);
    var ODd = document.getElementById("ODD").innerHTML;
    const formData = new FormData();
    formData.append("doctorId", location.state.state.id);
    formData.append("docTitle", ODd);
    formData.append("docFile", odd);
    formData.append("docValue", oddVal);
    formData.append("name", filename);
    formData.append("url", filePath);
    setRefresh(false);
    axios
      .post(APIURL() + "documents/uploadOne", formData)
      .then(function (response) {
        setRefresh(true);
        setStatus(true);
        if (response.data.message == "Invalid File") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "No File uploaded") {
          toast.warning(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else if (response.data.message == "Successfully Uploaded") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        toast.success("Successfully Uploaded");
      });
  };
  function clickNext() {
    if (step == 1) {
      back.current.className = "standardButton rejectButton";
    } else if (step == 3) {
      next.current.className += " hide";
      back.current.className += " hide";
      reviewAndSubmit.current.className = "standardButton acceptButton";
    }
    setStep((s) => s + 1);
  }

  function clickBack() {
    if (step == 2) {
      back.current.className += " hide";
    }
    setStep((s) => s - 1);
  }
  const [urlData, setUrlData] = useState("");
  const handleDownload = (url, filename) => {
    fetch(APIURL() + "documents/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });

      //window.location.href = response.url;
    });
  };
  const viewDocFile = (url, filename) => {
    setOpenDialog(true);

    fetch(APIURL() + "documents/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        setUrlData(url);
      });
    });
  };

  console.log(uploadData, "uploadDatauploadData");
  ///api/onboard
  const Confirm = (e) => {
    if (checkedValue == false) {
      toast.warning("Please select the checkbox");
      return false;
    }

    if (
      uploadData &&
      uploadData.includes("Resume") &&
      uploadData &&
      uploadData.includes("Medical Council Registration Certificate No") &&
      uploadData &&
      uploadData.includes("PAN Card") &&
      uploadData &&
      uploadData.includes("Aadhaar Card") &&
      uploadData &&
      uploadData.includes("Address Proof") &&
      uploadData &&
      uploadData.includes("MBBS Degree Certificate") &&
      uploadData &&
      uploadData.includes("All PG Degree Certificates") &&
      uploadData &&
      uploadData.includes("Passport Size Photograph") &&
      uploadData &&
      uploadData.includes("Indemnity Insurance Copy")
    ) {
      setbackDropOpen(true)
      e.preventDefault();
      const onboardData = {
        doctorId: user.user.id,
        name: user.user.username,
        position:apidata && apidata.details && apidata.details[0] && apidata.details[0].departmentlist,
        currentAction: onboardState.upload.state,
        currentActionBy: user.user.id,
        role: user.user.roles,
        requested_id: oboard.vacancyData[0].id,
        nextAction: onboardState.hr.state,
        status: 1,
      };
      axios.post(APIURL() + "onboard/action/", onboardData).then((res) => {
        toast.success("Successfully Submitted!");
        window.location.href = "/Estimation";
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      });
      setbackDropOpen(false)
    } else {
      e.preventDefault();
      setOpenAlldoc(true);
      return false;
    }
  };

  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  // const handleChangeInput = (id, event) => {
  //     const newInputFields = inputFields.map(i => {
  //       if(id === i.id) {
  //         i[event.target.name] = event.target.value
  //       }
  //       return i;
  //     })

  //     setInputFields(newInputFields);
  //   }

  //   const handleAddFields = () => {
  //     setInputFields([...inputFields, { id: uuidv4(),  aadnum: '',adressproof:'' }])
  //   }

  // const getAlldata = () => {
  //   axios
  //     .get("documents/list/" + user.user.id)
  //     .then((res) => res.data)
  //     .then((rows) => {
  //       var k = rows.data;
  //       var l = [];
  //       k.forEach((element) => {
  //         l.push(element.docTitle);
  //       });

  //       setUploadData(l);
  //     });
  // };

  function getAllDocList() {
    axios
      .get(APIURL() + "documents/list/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
        setList(rows.data);
        var k = rows.data;
        var l = [];
        k.forEach((element) => {
          l.push(element.docTitle);
        });

        setUploadData(l);
      });
  }

  useEffect(() => {
    getAllDocList();
  }, [refresh, status]);
  const handleCheckboxClick = (e) => {
    setcheckedValue(e.target.checked);
  };
  // var array = [];
  // var k =
  //   uploadData &&
  //   uploadData.data &&
  //   uploadData.data.map((e) => {
  //     array.push(e);
  //   });
  console.log(list, "kkkkk");
  return (
    <>
      <DoctorSidebar />

      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />
            {/* <div className="progressbar-wrapper">
                            {" "}
                            <div class="col-md-8 col-sm-12 col-xs-12  container">
                                <ul class="progressbar">
                                    <li class="active"></li>
                                    <li> </li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div> */}

            <form encType="multipart/form-data">
              <br />
              <Card style={formStyle}>
                <Card.Header style={headerStyle}>
                  <h4> Uploaded Documents </h4>
                </Card.Header>
                <Card.Body
                  style={bodyStyle}
                  // style={{ overflowY: "auto", height: "300px" }}
                >
                  <Table style={{ width: "100%" }} borderless>
                    <thead>
                      <tr
                        style={{
                          textAlign: "center",
                          borderBottom: "1px solid rgb(200, 200, 200)",
                        }}
                      >
                        <th className="col-2">Document Title</th>
                        <th className="col-2"> Download</th>
                        <th className="col-2"> View</th>
                        <th className="col-2"> File Name</th>
                        <th className="col-2"> Uploaded On</th>
                        <th className="col-2"> Remarks</th>
                        <th className="col-2"> Remove</th>
                      </tr>
                      {/* )} */}
                    </thead>
                    <tbody>
                      {list &&
                        list.map((ele) => (
                          <tr>
                            <td style={{ textAlign: "center" }}>
                              {ele.docTitle}
                            </td>
                            <td
                              style={{ cursor: "pointer", textAlign: "center" }}
                            >
                              {" "}
                              <i
                                className="fas fa-download"
                                onClick={() =>
                                  handleDownload(ele.url, ele.docTitle)
                                }
                                title={"Download"}
                                style={{ fontSize: "22px", color: "#810e36" }}
                              />
                            </td>
                            <td
                              style={{ cursor: "pointer", textAlign: "center" }}
                            >
                              {" "}
                              <i
                                className="fas fa-file"
                                onClick={() =>
                                  viewDocFile(ele.url, ele.docTitle)
                                }
                                title={"Download"}
                                style={{ fontSize: "22px", color: "#810e36" }}
                              />
                            </td>
                            <td>{ele.name}</td>
                            <td style={{ textAlign: "center" }}>
                              {ele.updatedAt
                                ? dateFormatter(ele.updatedAt.substring(0, 10))
                                : ""}
                            </td>
                            {ele.verificationRemark === null ? (
                              <td style={{ textAlign: "center" }}>
                                <span className="badge badge-success">
                                  APPROVED
                                </span>
                              </td>
                            ) : ele.verificationRemark === "2" ? (
                              <td style={{ textAlign: "center" }}>
                                <span className="badge badge-success">
                                  APPROVED
                                </span>
                              </td>
                            ) : ele.verificationRemark === "1" ? (
                              <td style={{ textAlign: "center" }}>
                                AWAITING APPROVAL
                              </td>
                            ) : (
                              <td style={{ textAlign: "center" }}>
                                {ele.verificationRemark}
                              </td>
                            )}
                            <td style={{ textAlign: "center" }}>
                              <DeleteIcon
                                style={{ color: "red" }}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Deleting a file may result in some features unresponsive ?",
                                    subtitle:
                                      "Are you sure you want to delete this file?",
                                    onConfirm: () => {
                                      deleteDoc(ele.id);
                                      setConfirmDialog({ isOpen: false });
                                    },
                                  });
                                  // deleteDoc(ele.id);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <br />
              <Card style={formStyle}>
                <Card.Header style={headerStyle}>
                  <h4>
                    Dr.{" "}
                    {oboard.doctorCommercial &&
                      oboard.doctorCommercial[0] &&
                      oboard.doctorCommercial[0].fullname}{" "}
                    - Documents Upload
                  </h4>
                  <h6 style={{ fontWeight: "lighter" }}>
                    Raised by - Business Head
                  </h6>
                  <h6 style={{ fontWeight: "bold" }}>
                    We only support- Jpeg , PNG ,Pdf Format (Max file size is
                    15Mb).
                  </h6>
                </Card.Header>
                <Card.Body style={bodyStyle}>
                  <Table style={{ width: "100%" }} borderless>
                    <thead>
                      {/* {uploadData &&
                      uploadData.data &&
                      uploadData.data.filter(
                        (z) =>
                          z.docTitle === "Resume" &&
                          "Medical Council Registration Certificate" &&
                          "PAN Card" &&
                          "Aadhaar Card" &&
                          "Cancelled Cheque (Bank Details)" &&
                          "Address Proof"
                      ) ? (
                        ""
                      ) : ( */}
                      {uploadData &&
                      uploadData.includes(
                        "Resume" &&
                          "Medical Council Registration Certificate No" &&
                          "PAN Card" &&
                          "Aadhaar Card" &&
                          "Cancelled Cheque (Bank Details)" &&
                          "Address Proof" &&
                          "Payslips for the last 3 months" &&
                          "MBBS Degree Certificate" &&
                          "All PG Degree Certificates" &&
                          "Passport Size Photograph" &&
                          "Form 16 for last 2 years" &&
                          "Bank Statement for 6 months" &&
                          "Indemnity Insurance Copy"
                      ) ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            textAlign: "center",
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <th className="col-1">#</th>
                          <th className="col-2">Document to upload</th>
                          <th className="col-2">Document Number</th>
                          <th className="col-3">Upload</th>
                          <th className="col-3">Additional Information</th>
                          <th className="col-1">Status</th>
                          <th className="col-1">Submit</th>
                        </tr>
                      )}
                      {/* )} */}
                    </thead>
                    <tbody>
                      {uploadData && uploadData.includes("Resume") ? (
                        ""
                      ) : (
                        <tr
                          id="row1"
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>1.</td>
                          <td>
                            <span id="Resume">Resume</span>{" "}
                            <span>
                              <i
                                class="fa fa-asterisk"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "6px",
                                }}
                                aria-hidden="true"
                              ></i>
                            </span>
                          </td>
                          <td>
                            <input
                              type="text"
                              id="docValue"
                              value={docValue}
                              onChange={(e) => setdocValue(e.target.value)}
                              className="form-control"
                              disabled={!file}
                            />
                          </td>

                          <td>
                            <input
                              type="file"
                              id={1}
                              accept=".pdf,.docx"
                              name="resume"
                              className="form-control"
                              onChange={fileHandler}
                            />
                            {/* {progress && <ProgressBar now={progress} label={`${progress}%`} />} */}
                          </td>

                          <td>
                            <input
                              type="text"
                              value={remark}
                              onChange={(e) => setRemark(e.target.value)}
                              className="form-control"
                              disabled={!file}
                            />
                          </td>
                          <td id="status">
                            {!file ? "Not Uploaded" : "Uploaded"}
                          </td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!file}
                              onClick={handleSubmit}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}
                      {/* )} */}
                      {/* {list.includes(" Medical Council Registration Certificate") ? (alert("hi")) : alert("bye")} */}
                      {uploadData &&
                      uploadData.includes(
                        "Medical Council Registration Certificate No"
                      ) ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>2.</td>
                          <td>
                            <span id="MCRC">
                              Medical Council Registration Certificate No
                            </span>
                            <i
                              class="fa fa-asterisk"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "6px",
                              }}
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              type="text"
                              value={mcrcValue}
                              onChange={(e) => setMcrcValue(e.target.value)}
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="file"
                              accept=".pdf,.docx"
                              id={2}
                              name="mcrc"
                              onChange={fileHandler1}
                              className="form-control"
                              // disabled
                            />
                          </td>
                          <td>
                            <Row>
                              <Col md={12}>
                                <input
                                  type="text"
                                  // value={apidata && apidata.details && apidata.details[0]  && apidata.details[0].}
                                  // onChange={(e) => setMcrcValue(e.target.value)}
                                  className="form-control"
                                />
                              </Col>
                              <Col md={12}>
                                <input
                                  type="text"
                                  // value={apidata && apidata.details && apidata.details[0]  && apidata.details[0].}
                                  // onChange={(e) => setMcrcValue(e.target.value)}
                                  className="form-control"
                                />
                              </Col>
                            </Row>
                          </td>
                          <td id="status">
                            {!mcrc ? "Not Uploaded" : "Uploaded"}
                          </td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!mcrc}
                              onClick={handleMCRC}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}
                      {uploadData && uploadData.includes("PAN Card") ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>3.</td>
                          <td>
                            {" "}
                            <span id="pan">PAN Card</span>
                            <i
                              class="fa fa-asterisk"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "6px",
                              }}
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              type="text"
                              id="resume"
                              name="resume"
                              className="form-control"
                              value={
                                apidata &&
                                apidata.details &&
                                apidata.details[0] &&
                                apidata.details[0].panno
                              }
                              disabled
                              // ={!pan}
                            />
                          </td>
                          <td>
                            <input
                              type="file"
                              accept=".pdf,.docx"
                              onChange={fileHandler2}
                              id={3}
                              name="pan"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={panNo}
                              onChange={(e) => setpanNo(e.target.value)}
                              className="form-control"
                              placeholder="Additional Information"
                              disabled={!pan}
                            />
                          </td>
                          <td>{!pan ? "Not Uploaded" : "Uploaded"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!pan}
                              onClick={handlePan}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}
                      {uploadData && uploadData.includes("Aadhaar Card") ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>4.</td>
                          <td>
                            {" "}
                            <span id="Adhaar">Aadhaar Card</span>
                            <i
                              class="fa fa-asterisk"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "6px",
                              }}
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              type="text"
                              // value={aadNo}
                              // onChange={(e) => setAadNo(e.target.value)}
                              value={
                                apidata &&
                                apidata.details &&
                                apidata.details[0] &&
                                apidata.details[0].adhaarno
                              }
                              className="form-control"
                              placeholder="AADHAAR Number"
                              disabled
                            />
                          </td>
                          <td>
                            <input
                              type="file"
                              id={4}
                              accept=".pdf,.docx"
                              onChange={fileHandler3}
                              name="adhaar"
                              className="form-control"
                              multiple
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              id="resume"
                              name="resume"
                              className="form-control"
                              disabled={!adhaar}
                            />
                          </td>
                          <td>{!adhaar ? "Not Uploaded" : "Uploaded"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!adhaar}
                              onClick={handleAdhaar}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}
                      {uploadData &&
                      uploadData.includes("Cancelled Cheque (Bank Details)") ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>5.</td>
                          <td>
                            {" "}
                            <span id="CC">Cancelled Cheque (Bank Details)</span>
                          </td>
                          <td>
                            <input
                              type="text"
                              value={bankval}
                              onChange={(e) => setBankValue(e.target.value)}
                              className="form-control"
                              placeholder="Cheque Number"
                              disabled={!cc}
                            />
                          </td>
                          <td>
                            <input
                              type="file"
                              accept=".pdf,.docx"
                              onChange={fileHandler4}
                              name="CC"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <Row>
                              <Col md={12}>
                                <input
                                  type="text"
                                  value={
                                    apidata &&
                                    apidata.details &&
                                    apidata.details[0] &&
                                    apidata.details[0].bankname
                                  }
                                  onChange={(e) => setccBank(e.target.value)}
                                  className="form-control"
                                  placeholder="Bank Name"
                                  disabled={!cc}
                                />
                              </Col>
                              <br />
                              <Col md={12}>
                                <input
                                  type="text"
                                  value={
                                    apidata &&
                                    apidata.details &&
                                    apidata.details[0] &&
                                    apidata.details[0].bankaccno
                                  }
                                  // onChange={(e) =>
                                  //   setccAccountNo(e.target.value)
                                  // }
                                  className="form-control"
                                  placeholder="Account Number"
                                  disabled={!cc}
                                />
                              </Col>
                              <br />
                              <Col md={12}>
                                <input
                                  type="text"
                                  value={
                                    apidata &&
                                    apidata.details &&
                                    apidata.details[0] &&
                                    apidata.details[0].bankifsc
                                  }
                                  // onChange={(e) => setifsc(e.target.value)}
                                  className="form-control"
                                  placeholder="IFSC code"
                                  disabled={!cc}
                                />
                              </Col>
                              <br />
                            </Row>
                          </td>
                          <td>{!cc ? "Not Uploaded" : "Uploaded"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!cc}
                              onClick={handleCC}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}
                      {uploadData && uploadData.includes("Address Proof") ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>6.</td>
                          <td>
                            <span id="AddressProofid">Address Proof</span>{" "}
                            <i
                              class="fa fa-asterisk"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "6px",
                              }}
                              aria-hidden="true"
                            ></i>
                          </td>
                          {/* value={inputField.aadnum} onChange={event => handleChangeInput(inputField.id, event)} */}
                          <td>
                            <input
                              type="text"
                              name="aadnum"
                              id="aad-num"
                              className="form-control"
                              placeholder=""
                              disabled={!address}
                            />
                          </td>
                          <td>
                            <input
                              type="file"
                              accept=".pdf,.docx"
                              onChange={fileHandler5}
                              name="adressproof"
                              id="adressproof"
                              className="form-control"
                              multiple
                            />
                          </td>
                          <td>
                            <textarea
                              value={addressProof}
                              onChange={(e) => setAddressProof(e.target.value)}
                              type="text"
                              className="form-control"
                              disabled={!address}
                              placeholder="Enter Address as mentioned in Proof"
                            />
                          </td>
                          <td>{!address ? "Not Uploaded" : "Uploaded"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!address}
                              onClick={handleAdress}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}
                      {uploadData &&
                      uploadData.includes("Payslips for the last 3 months") ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>7.</td>
                          <td>
                            <span id="paySlip">
                              Payslips for the last 3 months
                            </span>
                          </td>
                          <td>
                            <input
                              type="text"
                              value={payslipno}
                              onChange={(e) => setpayslipno(e.target.value)}
                              id="aad-num"
                              name="aad-num"
                              className="form-control"
                              disabled={!payslip}
                            />
                          </td>
                          <td>
                            <input
                              type="file"
                              accept=".pdf,.docx"
                              onChange={fileHandler6}
                              id={7}
                              name="payslip"
                              className="form-control"
                              multiple
                            />
                          </td>
                          {/* <td><input type="text" className="form-control" disabled={!payslip} /> <i className="fas fa-check" onClick={handleAddFields}></i></td> */}
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              disabled={!payslip}
                            />{" "}
                          </td>
                          <td>{!payslip ? "Not Uploaded" : "Uploaded"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!payslip}
                              onClick={handlePayslip}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                          {/* <td><button type="button" class="btn btn-primary me-3" onClick={() => setNoOfRows(noOfRows + 1)}>Add</button></td> */}
                        </tr>
                      )}

                      {/* })} */}
                      {uploadData &&
                      uploadData.includes("MBBS Degree Certificate") ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>8.</td>
                          <td>
                            <span id="Mbbs">MBBS Degree Certificate</span>{" "}
                            <i
                              class="fa fa-asterisk"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "6px",
                              }}
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              type="text"
                              id="aad-num"
                              name="aad-num"
                              className="form-control"
                              disabled={!mbbs}
                            />
                          </td>
                          <td>
                            <input
                              type="file"
                              accept=".pdf,.docx"
                              onChange={fileHandler7}
                              id={8}
                              name="mbbs"
                              className="form-control"
                              multiple
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={mbbsCertificate}
                              onChange={(e) =>
                                setmbbsCertificate(e.target.value)
                              }
                              id="resume"
                              name="resume"
                              className="form-control"
                              disabled={!mbbs}
                            />
                          </td>
                          <td>{!mbbs ? "Not Uploaded" : "Uploaded"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!mbbs}
                              onClick={handleMbbs}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}
                      {uploadData &&
                      uploadData.includes("All PG Degree Certificates") ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>9.</td>
                          <td>
                            {" "}
                            <span id="PG">All PG Degree Certificates</span>
                            <i
                              class="fa fa-asterisk"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "6px",
                              }}
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              type="text"
                              id="aad-num"
                              name="aad-num"
                              className="form-control"
                              disabled={!pg}
                            />
                          </td>
                          <td>
                            <input
                              type="file"
                              accept=".pdf,.docx"
                              onChange={fileHandler8}
                              id={9}
                              name="pg"
                              className="form-control"
                              multiple
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              id="resume"
                              value={pgval}
                              onChange={(e) => setPgValue(e.target.value)}
                              name="resume"
                              className="form-control"
                              disabled={!pg}
                            />
                          </td>
                          <td>{!pg ? "Not Uploaded" : "Uploaded"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!pg}
                              onClick={handlePg}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}
                      {uploadData &&
                      uploadData.includes("Passport Size Photograph") ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>10.</td>
                          <td>
                            <span id="photo">Passport Size Photograph</span>{" "}
                            <i
                              class="fa fa-asterisk"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "6px",
                              }}
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            <input
                              type="text"
                              id="aad-num"
                              name="aad-num"
                              className="form-control"
                              disabled={!photo}
                            />
                          </td>
                          <td>
                            <input
                              type="file"
                              accept=".jpeg,.jpg,.png"
                              onChange={fileHandler9}
                              id={10}
                              name="photo"
                              className="form-control"
                              // multiple
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={photoval}
                              onChange={(e) => setphotoValue(e.target.value)}
                              id="resume"
                              name="resume"
                              className="form-control"
                              disabled={!photo}
                            />
                          </td>
                          <td>{!photo ? "Not Uploaded" : "Uploaded"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!photo}
                              onClick={handlePhoto}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}
                      {uploadData &&
                      uploadData.includes("Form 16 for last 2 years") ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>11.</td>
                          <td>
                            <span id="Form16">Form 16 for last 2 years</span>
                          </td>
                          <td>
                            <input
                              type="text"
                              id="aad-num"
                              name="aad-num"
                              className="form-control"
                              disabled={!form16}
                            />
                          </td>
                          <td>
                            <input
                              type="file"
                              // id={11}
                              accept=".pdf,.docx"
                              onChange={fileHandler10}
                              name="form16"
                              id="form16"
                              className="form-control"
                              multiple
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              id="resume"
                              value={form16val}
                              onChange={(e) => setForm16val(e.target.value)}
                              name="resume"
                              className="form-control"
                              disabled={!form16}
                            />
                          </td>
                          <td>{!form16 ? "Not Uploaded" : "Uploaded"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!form16}
                              onClick={handleForm16}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}
                      {uploadData &&
                      uploadData.includes("Bank Statement for 6 months") ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>12.</td>
                          <td>
                            <span id="BankStatement">
                              Bank Statement for 6 months
                            </span>
                          </td>
                          <td>
                            <input
                              type="text"
                              id="aad-num"
                              name="aad-num"
                              className="form-control"
                              disabled={!bank}
                            />
                          </td>
                          <td>
                            <input
                              type="file"
                              id={12}
                              accept=".pdf,.docx"
                              onChange={fileHandler11}
                              name="bank"
                              className="form-control"
                              multiple
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              id="resume"
                              value={bankstateval}
                              onChange={(e) =>
                                setBankstateValue(e.target.value)
                              }
                              name="resume"
                              className="form-control"
                              disabled={!bank}
                            />
                          </td>
                          <td>{!bank ? "Not Uploaded" : "Uploaded"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!bank}
                              onClick={handleBank}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}
                      {uploadData &&
                      uploadData.includes("Indemnity Insurance Copy") ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>13.</td>
                          <td>
                            <span id="IIc">Indemnity Insurance Copy</span>{" "}
                            <i
                              class="fa fa-asterisk"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "6px",
                              }}
                              aria-hidden="true"
                            ></i>
                          </td>
                          <td>
                            {/* <input
                              type="date"
                              id="iicdate"
                              // name="certIssueDate"
                              value={certIssueDatiice}
                              min={dateFormatter(new Date())}
                              onChange={(e) =>
                                setcertIssueDatiice(e.target.value)
                              }
                              className="form-control"
                            /> */}
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                margin="normal"
                                minDate={new Date()}
                                // disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                autoOk
                                label="Expiry Date"
                                // disablePast
                                id="birthday"
                                value={certIssueDatiice}
                                className={styleClasses.root}
                                onChange={(e) => {
                                  console.log(e);
                                  setcertIssueDatiice(e);
                                }}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                fullWidth
                              />
                            </MuiPickersUtilsProvider>
                          </td>
                          <td>
                            <input
                              type="file"
                              id={13}
                              accept=".pdf,.docx"
                              onChange={fileHandler12}
                              name="iic"
                              className="form-control"
                              multiple
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              id="resume"
                              value={
                                apidata &&
                                apidata.details &&
                                apidata.details[0] &&
                                apidata.details[0].indemnityvalue
                              }
                              // onChange={(e) => setiicValue(e.target.value)}
                              name="resume"
                              placeholder="Indemnity Insurance Value"
                              className="form-control"
                              disabled
                            />
                          </td>
                          <td>{!iic ? "Not Uploaded" : "Uploaded"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!iic}
                              onClick={handleIIC}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}

                      {uploadData &&
                      uploadData.includes(
                        "Additional Qualification Certificate"
                      ) ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>14.</td>
                          <td>
                            <span id="AAC">
                              Additional Qualification Certificate
                            </span>
                          </td>
                          <td>
                            {/* <input
                              type="date"
                              id="iicdate"
                              // name="certIssueDate"
                              value={certIssueDatiice}
                              onChange={(e) =>
                                setcertIssueDatiice(e.target.value)
                              }
                              className="form-control"
                            /> */}
                          </td>
                          <td>
                            <input
                              type="file"
                              id={14}
                              accept=".pdf,.docx"
                              onChange={fileHandler13}
                              name="aac"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={aacVal}
                              onChange={(e) => setAacVal(e.target.value)}
                              name="resume"
                              placeholder="Additional Qualification Certificate"
                              className="form-control"
                              disabled={!aac}
                            />
                          </td>
                          <td>{!aac ? "Not Uploaded" : "Uploaded"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!aac}
                              onClick={handleAAc}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}
                      {uploadData &&
                        uploadData.includes(
                          "Other Documents"
                        ) ? (
                        ""
                      ) : (
                        <tr
                          style={{
                            borderBottom: "1px solid rgb(200, 200, 200)",
                          }}
                        >
                          <td>15.</td>
                          <td>
                            <span id="ODD">
                              Other Documents
                            </span>
                          </td>
                          <td>
                            {/* <input
                              type="date"
                              id="iicdate"
                              // name="certIssueDate"
                              value={certIssueDatiice}
                              onChange={(e) =>
                                setcertIssueDatiice(e.target.value)
                              }
                              className="form-control"
                            /> */}
                          </td>
                          <td>
                            <input
                              type="file"
                              id={14}
                              accept=".pdf,.docx,.jpeg,.jpg,.png"
                              onChange={fileHandler15}
                              name="odd"
                              className="form-control"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              value={oddVal}
                              onChange={(e) => setOddVal(e.target.value)}
                              name="resume"
                              placeholder="Other Documents"
                              className="form-control"
                              // disabled={!odd}
                            />
                          </td>
                          <td>{!odd ? "Not Uploaded" : "Uploaded"}</td>
                          <td>
                            {" "}
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!odd}
                              onClick={handleOdd}
                            >
                              {" "}
                              Submit
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {/* {list.length < 13 ?  "" :
               <>  */}
                  <h6 style={{ margin: "1rem" }}>
                    {disable ? null : (
                      <Checkbox
                        color="primary"
                        checked={checkedValue}
                        onChange={handleCheckboxClick}
                      />
                    )}{" "}
                    I,
                    <i>
                      {" "}
                      {oboard.doctorCommercial &&
                        oboard.doctorCommercial[0] &&
                        oboard.doctorCommercial[0].fullname}
                    </i>{" "}
                    S/o / D/o{" "}
                    <i>
                      {oboard.doctorCommercial &&
                        oboard.doctorCommercial[0] &&
                        oboard.doctorCommercial[0].fathername}
                    </i>{" "}
                    certify that the statements made by me are true, complete
                    and correct to the best of my knowledge and belief. I
                    understand that the uploaded information if found false or
                    incorrect, at any time during the course of my contract, my
                    professional services shall be terminated fothwith without
                    any notice or compensation.
                  </h6>
                  <button
                    id="button"
                    className="btn btn-success confirm-btn"
                    onClick={Confirm}
                    // disabled={list.length < 13 }
                    // disabled={ oboard && oboard.onBoardData &&
                    //   oboard.onBoardData.currentAction !== "RD_VERIFY" }
                    disabled={disable}
                  >
                    Confirm
                  </button>
                  {/* </>
} */}
                </Card.Body>
              </Card>
              <br />
            </form>
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        disableBackdropClick
      >
        <DialogTitle
          id="customized-dialog-title"
          // onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers></DialogContent>
        <Iframe
          url={urlData}
          width="900px"
          height="800px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
        />

        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenAlldoc(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openAlldoc}
        disableBackdropClick
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{
            backgroundColor: "#810e36",
            color: "white",
            textAlign: "center",
          }}
        >
          Alert
        </DialogTitle>
        <DialogContent dividers>
          <h3 style={{ textAlign: "center" }}>
            Please upload all mandatory documents!
          </h3>
        </DialogContent>

        <DialogActions style={{ margin: "10px" }}>
          <Button
            onClick={() => {
              setOpenAlldoc(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        className={backClasses.backdrop}
        open={backDropOpen}
      >
        <img src={c9gif} alt="loading..." />
      </Backdrop>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default DoctorDashboard;
