/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// import Crypto from "bcryptjs";
import Cryptr from "bcryptjs";
import { useLocation } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
// import { AuthContext } from "CommonMethods/Authentication";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Button from "@mui/material/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
// core components
import { APIURL } from "../../CommomMethods/Fetch";

// sanckbar and notistack
import Snackbar from "@material-ui/core/Snackbar";
import ErrorIcon from "@material-ui/icons/Error";
import { putRecord, postRecord } from "../../CommomMethods/Save";
import axios from "axios";
// import { APIURL } from "CommonMethods/Fetch";
// const APIUser = APIURL() + "users/";
// const APIChangePwd = APIURL() + "SendChangePasswordMail/";
// const useCommonStyles = makeStyles(styles);

const useNewStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
    // backgroundImage: `url(${patternlandscp})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "right",
    background: "rgb(70 165 70 / 14%)" /* fallback for old browsers */,
    background:
      "-webkit-linear-gradient(to top, rgb(70 165 70 / 14%), #ffffff)" /* Chrome 10-25, Safari 5.1-6 */,
    background:
      "linear-gradient(to top, rgb(70 165 70 / 14%), #ffffff)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
  },
}));
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(1.0)",
    color: "red !important",
    fontWeight: "bold",
  },
}))(Tooltip);
export default function ResetPassword(props) {
  const location = useLocation();
  var emailid = new URLSearchParams(location.search).get("email");
  var usertoken = new URLSearchParams(location.search).get("token");
  const newClasses = useNewStyles();
  const bull = <span className={HtmlTooltip.bullet}>•</span>;
  const { history, ...rest } = props;
  const initialState = {
    _New_Password: "",
    _Confirm_Password: "",
  };
  const [pwd, setPwd] = useState(false);
  const [open, setOpen] = useState(false); // to open close Snackbar
  const [notify, setnotify] = useState(""); // to set Snackbar Content and Design
  const [thisform, setThisForm] = useState(initialState);
  const [isAvailable, setIsAvailable] = useState(false);
  const [thisuser, setThisUser] = useState({});
  const [opendialog, setOpenDialog] = useState(false);
  const [pwdtooltip, setPwdtooltip] = useState(false);
  const [disable, setDisable] = useState(true);

  
  const UpdateUserPassword = () => {
    const obj = {
      emailid: emailid,
      usertoken: usertoken,
      pwd: thisform._New_Password,
    };
    axios
      .post(APIURL()+"users/ResetPassowrd/", obj)
      .then((res) => {
      });
    setOpenDialog(true);
  };

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setThisForm({ ...thisform, [name]: value });
  };
  const handleInputChangeforPassword = (event) => {
    const { name, value } = event.target;
    setThisForm({ ...thisform, [name]: value });
    var passwordChk =
      /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9](?!.*\s).{7,20})$/;
    if (value.length >= 8 && passwordChk.test(value)) {
      setPwd(true);
      setPwdtooltip(false);
      setDisable(false);
    } else {
      setPwd(false);
      setPwdtooltip(true);
      setDisable(true);
    }
  };
  const handleClickShowNewPassword = () => {
    setThisForm({ ...thisform, showNewPassword: !thisform.showNewPassword });
  };
  const handleClickShowConPassword = () => {
    setThisForm({ ...thisform, showConPassword: !thisform.showConPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleLogin = () => {
    props.history.push("/");
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <div>
      <div className={newClasses.root}>
        <CssBaseline />

        <div
          style={{ margin: "100px auto" }}
        >
          <div>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                if (thisform._New_Password === thisform._Confirm_Password) {
                  thisuser.User_Password = thisform._Confirm_Password;
                  UpdateUserPassword();
                  setThisForm(initialState);
                } else
                  alert("Your New Password And Confirm Password Should Match");
              }}
            >
              <HtmlTooltip
                open={pwdtooltip}
                placement="left-start"
                title={
                  <>
                    <Typography variant="body2" color="inherit">
                      Password Policy
                    </Typography>
                    <span style={{ color: "orange" }}>
                      {bull}
                      {"Minimum length is 8 characters"}
                      <br />
                      {bull}
                      {"Atleast one upper case character"}
                      <br />
                      {bull}
                      {"Atleast one special character"}
                      <br />
                      {bull}
                      {"Atleast one number"}
                    </span>
                  </>
                }
              >
                <TextField
                  style={{
                    marginBottom: "20px",
                  }}
                  label="New Password"
                  name="_New_Password"
                  value={thisform._New_Password}
                  onChange={handleInputChangeforPassword}
                  required
                  fullWidth
                  onCopy={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                  type={thisform.showNewPassword ? "text" : "password"}
                  InputProps={{
                    autoComplete: "off",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{
                            padding: "0",
                            color: "rgb(73, 80, 87)",
                          }}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {thisform.showNewPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </HtmlTooltip>
              {!pwd ? (
                <label style={{ color: "red" }}>
                  Please check password policy !
                </label>
              ) : (
                <label style={{ color: "green" }}>
                  Password policy matched !
                </label>
              )}
              <TextField
                style={{
                  marginBottom: "20px",
                }}
                label="Confirm Password"
                name="_Confirm_Password"
                value={thisform._Confirm_Password}
                onChange={handlePasswordChange}
                required
                fullWidth
                onCopy={(e) => e.preventDefault()}
                onPaste={(e) => e.preventDefault()}
                type={thisform.showConPassword ? "text" : "password"}
                InputProps={{
                  autoComplete: "off",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{
                          padding: "0",
                          color: "rgb(73, 80, 87)",
                        }}
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {thisform.showConPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                // className={commonclasses.successColor}
                disabled={disable}
              >
                <span>
                  <SaveOutlinedIcon />
                </span>
                Change Password
              </Button>
            </form>
          </div>
        </div>
      </div>

      <Dialog
        open={opendialog}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle style={{ textAlign: "center" }} id="form-dialog-title">
          Reset Successfully !!!
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your password reset successfully.Kindly login again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogin} color="primary">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
