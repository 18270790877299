import React from "react";
import CountReport from "../../Pages/Reports/CountReport";
import SidebarRD from "../../Components/Rd-sidebar";

export default function Count() {
  return (
    <>
      <SidebarRD />
      <CountReport />
    </>
  );
}
