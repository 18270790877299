import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
  ReferenceLine,
  Sector,
} from "recharts";
const CustomTooltip = ({ active, payload, label }) => {
  console.log(payload,"payload>")
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p
          className="label"
          style={{ color: "black", backgroundColor: "white" }}
        >{`${payload[0].payload.name} : ${payload[0].payload.status}`}</p>
      </div>
    );
  }

  return null;
};

function BarChartStatusCount({ datalist }) {
  var totaldata = [];
  datalist && datalist.length>0 && datalist.map((elem)=>{
    totaldata.push({
      name:elem.status,
      "status": elem.onboardcount
    })
  })
console.log(totaldata,datalist,"totaldata555");
  return (
    <div className="cardsTheme1 pb-5 pr-4" style={{ height: '285px' }}>
      <h4 style={{ textAlign: "center", marginBottom: '15px' }}>Onboard Status</h4>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart width={500} height={300} data={totaldata}>
          <CartesianGrid strokeDasharray="3 3" />
          {/* <XAxis dataKey="status" /> */}
          <YAxis dataKey="status" />
          <Tooltip content={<CustomTooltip />} />
          <Legend verticalAlign="bottom" name="Centers" wrapperStyle={{ lineHeight: "40px" }} />
          <Bar dataKey="status" fill="#8884d8" />
          {/* <Bar dataKey="value" fill="#82ca9d" /> */}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default BarChartStatusCount;
