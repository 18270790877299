import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo2 from "../images/Logos-04.png";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { listusers } from "../actions/userActions";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { documentlist } from "../actions/documentActions";

function Sidebar({ userType }) {
  const [dash, setDash] = useState("bh-dashboard");
  const [item4, setItem4] = useState("visibleDiv");
  const [item5, setItem5] = useState("visibleDiv");
  const [item6, setItem6] = useState("visibleDiv");
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  // bringing things from the reducer
  const { loading, error, user } = userLogin;
  const listDoc = useSelector((state) => state.listDoc);
  const { list } = listDoc;

  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  function CheckUser() {
    if (userType === "BH") {
      setDash("bh-dashboard");
    } else if (userType === "HR") {
      setDash("hr-dashboard");
      setItem5("hiddenDiv");
    } else if (userType === "COO") {
      setDash("rd-dashboard");
      setItem4("hiddenDiv");
      setItem6("hiddenDiv");
    }
    useEffect(() => {
      dispatch(listusers());
    }, [dispatch]);
  }
  // useEffect(() => {
  //   dispatch(documentlist(location && location.state && location.state.id));
  // }, [dispatch]);

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <div className="sidebar main-sidebar main-sidebar-sticky side-menu ps ps--active-y">
      <div className="main-sidebar-header header-img">
        <Link to="/bh-dashboard">
          <img src={logo2} className="img-responsive" alt="logo" />
        </Link>
      </div>
      <div className="main-sidebar-body overflow-auto">
        <ul className="nav">
          <li className="nav-item">
            <Link to="/bh-dashboard" id="nav-link" className="new-nav-link" style={{ backgroundColor: "transparent", height: "2.6rem", border: "none", color: "white" }}>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <i className="ti-home sidemenu-icon mr-2"></i>
              <span className="sidemenu-label" style={{ fontSize: "16px" }}>{'   '}Dashboard</span>
            </Link>
          </li>
          {user.user.activityAssigments.filter((elem) => elem.activityId === 4 || elem.activityId === 5).find(item => item.RoleId === user.user.roles) ?
            <li className="nav-item">
              <div>
                <Link to="/UploadJoining" id="nav-link" className="new-nav-link" style={{ backgroundColor: "transparent", height: "2.6rem", border: "none", marginTop: "1px", color: "white", }}>
                  <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                  <span className="sidemenu-label" style={{ fontSize: "15px" }}>
                    Upload Joining
                  </span>
                </Link>
              </div>
            </li>
            : null}
          {user.user.activityAssigments.filter((elem) => elem.activityId === 29).find(item => item.RoleId === user.user.roles) ?
            <li className="nav-item">
              <div>
                <Link
                  to="/manage-agreement-annexure"
                  id="nav-link"
                  className="new-nav-link"
                  style={{ backgroundColor: "transparent", height: "2.6rem", border: "none", marginTop: "1px", color: "white", }}
                >
                  <i className="ti-folder sidemenu-icon mr-2"></i>
                  <span className="sidemenu-label" style={{ fontSize: "15px" }}>
                    Annexure
                  </span>
                </Link>
              </div>
            </li>
            : null}
          {user.user.activityAssigments.filter((elem) => elem.activityId === 6).find(item => item.RoleId === user.user.roles) ?
            <li className="nav-item">
              <Link to="/UploadedDocHR" id="nav-link" className="new-nav-link" style={{ backgroundColor: "transparent", height: "2.6rem", border: "none", color: "white" }}>
                <span className="shape1"></span>
                <span className="shape2"></span>
                <i className="fas fa-file-upload sidemenu-icon mr-2"></i>
                <span className="sidemenu-label" style={{ fontSize: "16px" }}>{'   '}Documents Approval</span>
              </Link>
            </li>
            : null}
          {user.user.activityAssigments.filter((elem) => elem.activityId === 7).find(item => item.RoleId === user.user.roles) ?
            <li className="nav-item">
              <Link to="/view-status" id="nav-link" className="new-nav-link" style={{ backgroundColor: "transparent", height: "2.6rem", border: "none", color: "white" }}>
                <span className="shape1"></span>
                <span className="shape2"></span>
                <i className="fas fa-file-upload sidemenu-icon mr-2"></i>
                <span className="sidemenu-label" style={{ fontSize: "16px" }}>{'   '}Agreement Approval</span>
              </Link>
            </li>
            : null}
          {/* {user.user.activityAssigments.filter((elem) => elem.activityId !== 9 && elem.activityId !== 10 && elem.activityId !== 11).find(item => item.RoleId === user.user.roles) ?  
          <li className="nav-item">
            <Link to="/bh-agreement" id="nav-link" className="new-nav-link" style={{ backgroundColor: "transparent", height: "2.6rem", border: "none", color: "white" }}>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
              <span className="sidemenu-label" style={{ fontSize: "16px" }}>{'   '}Agreements</span>
            </Link>
          </li>
          : null} */}
          {user.user.activityAssigments.filter((elem) => elem.activityId === 15).find(item => item.RoleId === user.user.roles) ?
            <li className="nav-item">
              <Link to="/renewal-process" id="nav-link" className="new-nav-link" style={{ backgroundColor: "transparent", height: "2.6rem", border: "none", color: "white" }}>
                <span className="shape1"></span>
                <span className="shape2"></span>
                <i className="ti-pencil-alt sidemenu-icon mr-2"></i>
                <span className="sidemenu-label" style={{ fontSize: "16px" }}>{'   '}Agreement Renewal</span>
              </Link>
            </li>
            : null}
          {user.user.roles == 3 || user.user.roles == 10 || (user.user.roles == 3 && user.user.id == 540) || (user.user.roles == 10 && user.user.id == 7639) ?
            <li className="nav-item">
              <Link to="/retainer-payout" id="nav-link" className="new-nav-link" style={{ backgroundColor: "transparent", height: "2.6rem", border: "none", color: "white" }}>
                <span className="shape1"></span>
                <span className="shape2"></span>
                <i className="ti-pencil-alt sidemenu-icon mr-2"></i>
                <span className="sidemenu-label" style={{ fontSize: "16px" }}>{'   '}Retainer PayRoll</span>
              </Link>
            </li>
            : null}
          {/* {user.user.activityAssigments.filter((elem) => elem.activityId === 12 || elem.activityId === 13 || elem.activityId === 14).find(item => item.RoleId === user.user.roles) || user.user.id == 7941 ?
            <li className={"nav-item"}>
              <Link to="/ippayout-process" id="nav-link" className="new-nav-link" style={{ backgroundColor: "transparent", height: "2.6rem", border: "none", color: "white" }}>
                <span className="shape1"></span>
                <span className="shape2"></span>
                <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                <span className="sidemenu-label" style={{ fontSize: "16px" }}>{'   '}Payouts</span>
              </Link>
            </li>
            : null} */}
          {user.user.activityAssigments.filter((elem) => elem.activityId === 31).find(item => item.RoleId === user.user.roles) ?
            <li className="nav-item">
              <Link to="/History" id="nav-link" className="new-nav-link" style={{ backgroundColor: "transparent", height: "2.6rem", border: "none", color: "white" }}>
                <span className="shape1"></span>
                <span className="shape2"></span>
                <i className="fas fa-route sidemenu-icon mr-2"></i>
                <span className="sidemenu-label" style={{ fontSize: "16px" }} >{'   '}Audit Trail</span>
              </Link>
            </li>
            : null}
          {/* {user.user.activityAssigments.filter((elem) => elem.activityId === 9 || elem.activityId === 10 || elem.activityId === 11 || elem.activityId === 12 || elem.activityId === 13 || elem.activityId === 14).find(item => item.RoleId === user.user.roles) || user.user.id == 7941 ? */}
          {user.user.activityAssigments.filter((elem) => elem.activityId === 9 || elem.activityId === 10 || elem.activityId === 11 || elem.activityId === 12 || elem.activityId === 13 || elem.activityId === 14).find(item => item.RoleId === user.user.roles) || user.user.id == 520 || user.user.id == 523 || user.user.roles == 12 ?
            <li className="nav-item">
              <Link to="/GenerateOpPayout" id="nav-link" className="new-nav-link" style={{ backgroundColor: "transparent", height: "2.6rem", border: "none", color: "white" }}>
                <span className="shape1"></span>
                <span className="shape2"></span>
                <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                <span className="sidemenu-label" style={{ fontSize: "16px" }} >{'   '}Payouts</span>
              </Link>
            </li>
            : null}

          {user.user.id == 10000000000 && user.user.activityAssigments.filter((elem) => elem.activityId === 9 || elem.activityId === 10 || elem.activityId === 11 || elem.activityId === 12 || elem.activityId === 13 || elem.activityId === 14).find(item => item.RoleId === user.user.roles) || user.user.id == 520 || user.user.id == 523 ?
            <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent", position: "sticky", top: '0' }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}
                  >
                    <span className="shape1"></span>
                    <span className="shape2"></span>
                    <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                    <span className="sidemenu-label" style={{ fontSize: "16px", marginLeft: "10px" }} >{'   '}Payouts</span>
                  </Accordion.Toggle>
                </Card.Header>
                {user.user.id !== 520 && user.user.id !== 523 && user.user.roles != 1 && user.user.roles != 2 && user.user.roles != 11 ?
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="new-nav-link"
                      style={{
                        backgroundColor: "transparent",
                        height: "1.2rem",
                        border: "none",
                      }}
                    >
                      <Link to="/ippayout-process" id="nav-link" className="nav-link"
                        style={{ marginTop: "-1rem" }}>
                        <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                        <span className="sidemenu-label" style={{ fontSize: "13px" }}>
                          {user.user.roles != 1 && user.user.roles != 2 && user.user.roles != 11 ? "IP Payout" : "Payouts"}
                        </span>
                      </Link>
                    </Card.Body>
                  </Accordion.Collapse>
                  :
                  ""
                }
                {/* {user.user.roles != 1 && user.user.roles != 2 && user.user.roles != 11 ? */}
                {user.user.id !== 520 && user.user.id !== 523 && user.user.roles != 1 && user.user.roles != 2 && user.user.roles != 11 ?
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="new-nav-link"
                      style={{
                        backgroundColor: "transparent",
                        height: "1.2rem",
                        border: "none",
                      }}
                    >
                      <Link
                        to="/oppayout-process"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                        <span className="sidemenu-label" style={{ fontSize: "13px" }}>
                          OP Payout
                        </span>
                      </Link>
                    </Card.Body>
                  </Accordion.Collapse>
                  :
                  ""
                }
                {/* : ""} */}
                {/* <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/UploadCommercials"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                        <span className="sidemenu-label" style={{ fontSize: "13px" }}>
                          Upload Commercials
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/ip-los"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                        <span className="sidemenu-label" style={{ fontSize: "13px" }}>
                          IP LOS
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/manage-doctor-commercial"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                        <span className="sidemenu-label" style={{ fontSize: "13px" }}>
                          Doctor Commercial
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        // to="/manage-doctor-commercial"
                        to="/ManageCommercials"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                        <span className="sidemenu-label" style={{ fontSize: "13px" }}>
                          Op Commercial Master
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/Manage-doctor"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                        <span className="sidemenu-label" style={{ fontSize: "13px" }}>
                          Doctor master
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse> */}
                {/* <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/manage-payable"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                      <span className="sidemenu-label" style={{ fontSize: "13px" }}>
                        Payable Item
                      </span>
                    </Link>
                  </Card.Body>
                </Accordion.Collapse> */}
                {/* <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/manage-ipitem-master"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                        <span className="sidemenu-label" style={{ fontSize: "13px" }}>
                          Ip Packages
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                      <Link
                        to="/manage-discard-payout"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                        <span className="sidemenu-label" style={{ fontSize: "13px" }}>
                          Discard Payout
                        </span>
                      </Link>
                  </Card.Body>
                </Accordion.Collapse> */}
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/GenerateOpPayout"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                      <span className="sidemenu-label" style={{ fontSize: "13px" }}>
                        Payout Report
                      </span>
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            : null}
          {user.user.roles === 12 ? (
            <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent" }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}
                  >
                    <i className="fas fa-angle-down sidemenu-icon mr-4"></i>
                    Masters
                  </Accordion.Toggle>
                </Card.Header>

                {user.user.roles === 12 ? (
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      className="new-nav-link"
                      style={{
                        backgroundColor: "transparent",
                        height: "1.2rem",
                        border: "none",
                      }}
                    >
                      <Link
                        to="/manage-agreement-template"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="ti-folder sidemenu-icon mr-2"></i>
                        Agreement Template
                      </Link>
                    </Card.Body>
                  </Accordion.Collapse>
                ) : null}

                {user.user.roles === 12 ? (
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      className="new-nav-link"
                      style={{
                        backgroundColor: "transparent",
                        height: "1.2rem",
                        border: "none",
                      }}
                    >
                      <Link
                        to="/manage-department"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="ti-folder sidemenu-icon mr-2"></i>
                        Department
                      </Link>
                    </Card.Body>
                  </Accordion.Collapse>
                ) : null}

                {user.user.roles === 12 ? (
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      className="new-nav-link"
                      style={{
                        backgroundColor: "transparent",
                        height: "1.2rem",
                        border: "none",
                      }}
                    >
                      <Link
                        to="/manage-user"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="ti-folder sidemenu-icon mr-2"></i>
                        User
                      </Link>
                    </Card.Body>
                  </Accordion.Collapse>
                ) : null}

                {user.user.roles === 12 ? (
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      className="new-nav-link"
                      style={{
                        backgroundColor: "transparent",
                        height: "1.2rem",
                        border: "none",
                      }}
                    >
                      <Link
                        to="/manage-doctors"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="ti-folder sidemenu-icon mr-2"></i>
                        Doctor
                      </Link>
                    </Card.Body>
                  </Accordion.Collapse>
                ) : null}
                {user.user.roles === 12 ? (
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      className="new-nav-link"
                      style={{
                        backgroundColor: "transparent",
                        height: "1.2rem",
                        border: "none",
                      }}
                    >
                      <Link
                        to="/manage-requisitions"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="ti-folder sidemenu-icon mr-2"></i>
                        Requisitions
                      </Link>
                    </Card.Body>
                  </Accordion.Collapse>
                ) : null}

                {user.user.roles === 12 ? (
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      className="new-nav-link"
                      style={{
                        backgroundColor: "transparent",
                        height: "1.2rem",
                        border: "none",
                      }}
                    >
                      <Link
                        to="/manage-designation"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="ti-folder sidemenu-icon mr-2"></i>
                        Designation
                      </Link>
                    </Card.Body>
                  </Accordion.Collapse>
                ) : null}

                {user.user.roles === 12 ? (
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      className="new-nav-link"
                      style={{
                        backgroundColor: "transparent",
                        height: "1.2rem",
                        border: "none",
                      }}
                    >
                      <Link
                        to="/manage-center"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="ti-folder sidemenu-icon mr-2"></i>
                        Center
                      </Link>
                    </Card.Body>
                  </Accordion.Collapse>
                ) : null}
              </Card>
            </Accordion>
          ) : null}
          {user.user.roles === 12 ? (
            <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent" }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}
                  >
                    <i className="fas fa-angle-down sidemenu-icon mr-4"></i>
                    Mapping
                  </Accordion.Toggle>
                </Card.Header>

                {user.user.roles === 12 ? (
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      className="new-nav-link"
                      style={{
                        backgroundColor: "transparent",
                        height: "1.2rem",
                        border: "none",
                      }}
                    >
                      <Link
                        to="/activity-assignment"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="ti-folder sidemenu-icon mr-2"></i>
                        Activity Mapping
                      </Link>
                    </Card.Body>
                  </Accordion.Collapse>
                ) : null}

                {user.user.roles === 12 ? (
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      className="new-nav-link"
                      style={{
                        backgroundColor: "transparent",
                        height: "1.2rem",
                        border: "none",
                      }}
                    >
                      <Link
                        to="/manage-usermapcenter"
                        id="nav-link"
                        className="nav-link"
                        style={{ marginTop: "-1rem" }}
                      >
                        <span className="shape1"></span>
                        <span className="shape2"></span>
                        <i className="ti-folder sidemenu-icon mr-2"></i>
                        User Mapping
                      </Link>
                    </Card.Body>
                  </Accordion.Collapse>
                ) : null}

                {user.user.roles === 12 ? (
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      className="new-nav-link"
                      style={{
                        backgroundColor: "transparent",
                        height: "1.2rem",
                        border: "none",
                      }}
                    >
                      {/* <Link
                      to="/manage-userregionmap"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-folder sidemenu-icon mr-2"></i>
                      COO Mapping
                    </Link> */}
                    </Card.Body>
                  </Accordion.Collapse>
                ) : null}

              </Card>
            </Accordion>
          ) : null}
          <Accordion
            defaultActiveKey="1"
            style={{ backgroundColor: "transparent" }}
          >
            <Card style={{ backgroundColor: "transparent" }}>
              <Card.Header style={{ backgroundColor: "transparent" }}>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="0"
                  style={{ color: "white" }}
                >
                  <span className="shape1"></span>
                  <span className="shape2"></span>
                  <i className="fas fa-user-circle sidemenu-icon mr-2"></i>
                  <span className="sidemenu-label" style={{ fontSize: "16px", marginLeft: "6px" }} >{'   '}My Account</span>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="new-nav-link"
                  style={{
                    backgroundColor: "transparent",
                    height: "1.2rem",
                    border: "none",
                  }}
                >
                  <Link
                    to="/BHProfile"
                    id="nav-link"
                    className="nav-link"
                    style={{ marginTop: "-1rem" }}
                  >
                    <span className="shape1"></span>
                    <span className="shape2"></span>
                    <i className="ti-user sidemenu-icon mr-2"></i>
                    Profile
                  </Link>
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Collapse eventKey="0">
                <Card.Body className="new-nav-link"
                  style={{
                    backgroundColor: "transparent",
                    height: "1.2rem",
                    border: "none",
                    marginBottom: "10px",
                  }}
                >
                  <Link
                    to="/ChangePassword"
                    id="nav-link"
                    className="nav-link"
                    style={{ marginTop: "-1rem" }}
                  >
                    <span className="shape1"></span>
                    <span className="shape2"></span>
                    <i className="fas fa-unlock sidemenu-icon mr-2"></i>
                    Settings
                  </Link>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
// reports, analytics, onboard actions, agreements,payout, user
