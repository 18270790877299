import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import axios from "axios";
import { Card, Table, Toast } from "react-bootstrap";
import { agreementState } from "../../CommomMethods/States";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Navmain from "../../Components/Navbar";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
import { listusers } from "../../actions/userActions";
import { agreementlist } from "../../actions/agreementActions";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { putRecord } from "../../CommomMethods/Save";
import { APIURL } from "../../CommomMethods/Fetch";
const { onboardState } = require("../../CommomMethods/States");

const APIRENEWINDEMINITY = APIURL() + "onboard/updateDetail/";
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

export default function IndeminityProcess() {
  const tables = myTableStyle();
  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  const [onboardData, setOnboardData] = useState([]);
  const [openr, setOpenr] = React.useState(false);
  const [openOp, setOpenOp] = React.useState(false);
  const [openIp, setOpenIp] = React.useState(false);
  const [openCommercials, setOpenCommercials] = React.useState(false);
  const [renewDialog, setRenewDialog] = useState(false);

  const [docid, setDocid] = useState("");
  const [doctorDetail, setDoctorDetail] = useState([]);
  const [doctorOpDetail, setDoctorOpDetail] = useState([]);

  const [doctorIpDetail, setDoctorIpDetail] = useState([]);
  const [doctorOpCommercial, setDoctorOpCommercial] = useState([]);
  const [doctorIpCommercial, setDoctorIpCommercial] = useState([]);
  const [remark, setRemark] = useState("");
  const [allRecord, setAllrecord] = useState({});
  const [staus, setStatus] = useState(false);

  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const agreementList = useSelector((state) => state.agreementList);
  const { agreements } = agreementList;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(agreementlist());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/currentstatusrenewal")
      .then((res) => {
        setOnboardData(res.data);
      })
      .catch((error) => {
      });
  }, [staus]);

  function getDocdetail(id) {
    axios
      .get(APIURL() + "onboard/previewOne/" + id)
      .then((res) => {
        setDoctorDetail(res.data);
        setDoctorIpDetail(res.data.doctorIPParameter);
        setDoctorOpDetail(res.data.doctorOPParameter);
        setDoctorOpCommercial(res.data.doctorOpCommercial);
        setDoctorIpCommercial(res.data.doctorIpCommercial);

      })
      .catch((error) => {
      });
  }

  useEffect(() => {
    getDocdetail(docid);
  }, [docid]);

  const handlerClickOpen = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenr(true);
  };
  const handlerClose = () => {
    setOpenr(false);
  };
  const handlerClickOpenOp = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenOp(true);
  };
  const handlerCloseOp = () => {
    setOpenOp(false);
  };

  const handlerClickOpenIp = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenIp(true);
  };

  const handlerClickOpenCommercials = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }
    setOpenCommercials(true);
  };

  const handlerClickcloseCommercials = () => {
    setOpenCommercials(false);
  };

  const handlerCloseIp = () => {
    setOpenIp(false);
  };

  const openDailog = (row) => {
    setRenewDialog(true);
    setAllrecord(row);
  };
  const [expTodate, setExpTodate] = useState("");
  const [expFromdate, setExpFromdate] = useState("");

  const handleexpiryFromDate = (event) => {
    const { name, value } = event.target;
    setExpFromdate(value);
  };

  const handleexpiryToDate = (event) => {
    const { name, value } = event.target;
    setExpTodate(value);
  };

  const handleRenewExpiry = () => {
    setStatus(false);
    allRecord.indemnityexpiry = expTodate;
    allRecord.status = 2;
    const obj = {
      indemnityexpiry: expTodate,
      status: 2,
    };

    // allRecord.doj = expTodate;

    axios
            .put(APIURL()+"onboard/updateDetail/" + allRecord.doctorId,
        allRecord
      )
      .then((res) => {
        setStatus(true);
        setRenewDialog(false);
      });
  };
  const handleApprove = (ele) => {
    // alert("The Agreement is APPROVED")
    const obj = {
      doctorId: ele.doctorId,
      name: ele.name,
      currentAction: agreementState.init.state,
      currentActionBy: users[0].id,
      nextAction: agreementState.hr_revision.state,
      status: 1,
    };
    const obj1 = {
      doctorId: ele.doctorId,
      name: ele.name,
      currentAction: onboardState.rd.state,
      currentActionBy: users[0].id,
      nextAction: onboardState.done.state,
      status: 1,
    };
    axios
      .post(APIURL() + "onboard/action/", obj1)
      .then((res) => {
      });
    axios
      .post(APIURL() + "onboard/agreementAction/", obj)
      .then((res) => {
      });
    window.location.reload();
  };
  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  var n;
  function inWords(num) {
    if ((num = num.toString()).length > 9) return "overflow";
    n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    return str;
  }

  var amount = parseInt(
    doctorDetail.doctorCommercial &&
      doctorDetail.doctorCommercial[0] &&
      doctorDetail.doctorCommercial[0].ctcmonth
  );

  var addamount = parseInt(
    doctorDetail.doctorCommercial &&
      doctorDetail.doctorCommercial[0] &&
      doctorDetail.doctorCommercial[0].additionalfee
  );

  const Remark = (e) => {
    setRemark(e.target.value);
  };
  const updateRecord = () => {
    const obj = {
      doctorOpDetail,
      doctorIpDetail,
      doctorOpCommercial,
      doctorIpCommercial,
    };
    axios
            .put(APIURL()+"onboard/updateFourtables/" +
          doctorOpDetail[0].doctorId,
        obj
      )
      .then((res) => {
      });
    if (remark != "") {
      const obj1 = {
        doctorId: doctorDetail.doctorDetails.id,
        name: doctorDetail.doctorDetails.name,
        currentAction: onboardState.rd.state,
        currentActionBy: 1,
        nextAction: onboardState.ch.state,
        remark: remark,
        status: 1,
      };
      axios
        .post(APIURL() +"onboard/action/", obj1)
        .then((res) => {
        });
      window.location.reload();
    }
  };

  return (
    <>
      <Sidebar />

      <div className="main-content side-content pt-0">
        <Navmain />
        <Card.Body
          // style={bodyStyle}
          style={{ width: "100%", marginTop: "4rem" }}
        >
          <MaterialTable
            columns={[
              { title: "Doctor Name", field: "name" },
              { title: "Mobile", field: "mobile" },
              { title: "Indeminity Value", field: "indemnityvalue" },

              { title: "Indeminity Expiry Date", field: "indemnityexpiry" },
              // {
              //   title: "Agreement Expiry Date",
              //   field: "agreementexpirydate",
              //   type: "date",
              // },
              // {
              //   title: "View Commercials",
              //   field: "Action",
              //   filtering: false,
              //   render: (row) => (
              //     <div style={{ width: "140px" }}>
              //       <i
              //         className="fas fa-address-card"
              //         style={{ fontSize: "20px" }}
              //         onClick={() => handlerClickOpenOp(row)}
              //       />
              //     </div>
              //   ),
              // },

              // {
              //   title: "Action",
              //   field: "Action",
              //   filtering: false,
              //   render: (row) => (
              //     <Button
              //       size="small"
              //       color="secondary"
              //       variant="contained"
              //       size="small"
              //       onClick={() => {
              //         openDailog(row);
              //       }}
              //     >
              //       Renew
              //     </Button>
              //   ),
              // },

              {
                title: "Extend",
                field: "Action",
                filtering: false,
                render: (row) => (
                  <div style={{ width: "140px" }}>
                    {row.status === 2 ? (
                      <span style={{ color: "green" }}>
                        <b>Renewal Done</b>
                      </span>
                    ) : (
                      <Button
                        size="small"
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={() => {
                          openDailog(row);
                        }}
                      >
                        Extend
                      </Button>
                    )}
                  </div>
                ),
              },
            ]}
            data={onboardData && onboardData.data}
            options={{
              // filtering: true,
              sorting: true,
              exportButton: true,
              pageSize: 5,
              pageSizeOptions: [5, 10, 50, 100, 200, 500],
              search: true,
            }}
            title="Indeminity Renewal Process"
          />
        </Card.Body>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Doctor Detail
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Name:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].fullname}
          </Typography>
          <Typography gutterBottom>
            Email-Id:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].email}
          </Typography>
          <Typography gutterBottom>
            Mobile:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].mobile}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerCloseOp}
        aria-labelledby="customized-dialog-title"
        open={openOp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseOp}
          style={{ textAlign: "center" }}
        >
          <h5 style={{ margin: ".4rem", textAlign: "center" }}>
            <b>Doctor Details</b>
          </h5>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <b>Name: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].fullname}
          </Typography>
          <Typography gutterBottom>
            <b> Email-Id: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].email}
          </Typography>
          <Typography gutterBottom>
            <b> Mobile: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].mobile}
          </Typography>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Revenue", field: "oprevenue" },
                { title: "Pay Out", field: "oppayout" },
                { title: "Net Amount", field: "netamount" },
              ]}
              // data={doctorDetail.doctorOPParameter} setDoctorOpDetail
              data={doctorOpDetail}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...doctorOpDetail];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setDoctorOpDetail([...dataUpdate]);

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Op Details"
            />
            <br />
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Revenue", field: "revenue" },
                { title: "Pay Out", field: "payout" },
                { title: "Net Amount", field: "ipTotalAmount" },
              ]}
              // data={doctorDetail.doctorIPParameter}
              data={doctorIpDetail}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...doctorIpDetail];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setDoctorIpDetail([...dataUpdate]);
                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Ip Details"
            />
            <br />
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemgroup" },

                { title: "Commercial Term", field: "commercialterm" },
              ]}
              // data={doctorDetail.doctorIPParameter}
              data={doctorOpCommercial}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...doctorOpCommercial];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setDoctorOpCommercial([...dataUpdate]);

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Op Commercial"
            />
            <br />
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemgroup" },

                { title: "Commercial Term", field: "commercialterm" },
              ]}
              // data={doctorDetail.doctorIPParameter}
              data={doctorIpCommercial}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...doctorIpCommercial];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setDoctorIpCommercial([...dataUpdate]);

                      resolve();
                    }, 1000);
                  }),
              }}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Ip Commercial"
            />
          </Paper>
          <br />
          <label>
            Remark{" "}
            <i
              class="fa fa-asterisk"
              style={{
                color: "red",
                fontSize: "10px",
                marginLeft: "3px",
                marginTop: "15px",
              }}
              aria-hidden="true"
            ></i>
          </label>
          <textarea
            placeholder="Remark"
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={updateRecord}
            color="primary"
            variant="contained"
            size="small"
          >
            Update Data
          </Button>
          <Button
            autoFocus
            onClick={handlerCloseOp}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerCloseIp}
        aria-labelledby="customized-dialog-title"
        open={openIp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseIp}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Revenue", field: "revenue" },
                { title: "Pay Out", field: "payout" },
                { title: "Net Amount", field: "ipTotalAmount" },
              ]}
              data={doctorDetail.doctorIPParameter}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Ip Details"
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerCloseIp}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClickcloseCommercials}
        aria-labelledby="customized-dialog-title"
        open={openCommercials}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group/Procedures", field: "itemGroup" },
                { title: "Commercial Term", field: "CommercialTerm" },
              ]}
              data={doctorDetail.doctorIPParameter}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Commercial Details"
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerClickcloseCommercials}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        onClose={() => {
          setRenewDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={renewDialog}
        disableBackdropClick
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            {/* <label for="pg-relexp">
              <h6> From Date</h6>
            </label>
            <div className="form-group">
              <input
                className="form-control form-doctor"
                type="date"
                placeholder="Post PG Degree Relevant Experience"
                id="expFromdate"
                style={{ color: "#000000" }}
                value={expFromdate}
                name="expFromdate"
                onChange={handleexpiryFromDate}
              />
            </div> */}
            <label for="pg-relexp">
              <h6> Indeminity Expiry Date </h6>
            </label>
            <div className="form-group">
              <input
                className="form-control form-doctor"
                type="date"
                placeholder="Post PG Degree Relevant Experience"
                id="expTodate"
                style={{ color: "#000000" }}
                value={expTodate}
                name="expTodate"
                onChange={handleexpiryToDate}
              />
            </div>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleRenewExpiry();
            }}
            color="success"
            variant="contained"
            size="small"
          >
            Extend
          </Button>
          <Button
            onClick={() => {
              setRenewDialog(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
