import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { APIURL } from "../CommomMethods/Fetch";
import "react-toastify/dist/ReactToastify.css";
import {
  addpositions,
  removeRequest,
  findpositions,
  dateFormatter,
} from "../actions/createPositionActions";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";

function RequestTable({
  reqTableData,
  reqType,
  match,
  history,
  newDoctorData,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [vacay, setVacay] = useState([]);
  const handleShow = (id) => {
    setShow(true);
    dispatch(findpositions(id));
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addpositions());
  }, [dispatch]);

  const userLogin = useSelector((state) => state.userLogin);

  // bringing things from the reducer
  const { user } = userLogin;

  console.log(newDoctorData, "USER");
  console.log(user, "USERuser");
  const addPosition = useSelector((state) => state.addPosition);
  const { addpos } = addPosition;

  console.log(addpos, "addpos");
  const findPos = useSelector((state) => state.findPos);
  const { findpos } = findPos;

  useEffect(() => {
    axios.get(APIURL() + "vacancies/findAllvacwithname/").then((res) => {
      setVacay(res.data.data);
      console.log(res.data.data, "PPPPPPPPPP");
    });
  }, []);

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "RequestId",
        field: "requestId",
        width: 100,
      },
      {
        label: "Request Type",
        field: "requestType",
        width: 100,
      },
      {
        label: "Request Raised By",
        field: "requestRaisedBy",
        sort: "asc",
        width: 200,
      },
      {
        label: "Date",
        field: "date",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Subject",
        field: "subject",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Current Status",
        field: "currentStatus",
        sort: "disabled",
        width: 100,
      },
    ],

    rows: reqTableData,
  });
  const infoStyle = {
    border: "2px solid #858585",
  };

  const removeRequestHandler = (id) => {
    // dispatch(removeRequest(id))
  };

  return (
    <div className="card custom-card" style={infoStyle}>
      {user && user.user && user.user.roles == "1" ? (
        <div className="card-body">
          <h3 className="main-content-title tx-24 mb-4 mg-b-5">New Requests</h3>
          <table
            className="table card-table table-striped table-vcenter text-nowrap mb-0 ctable"
            id="table"
            style={{ overflowY: "auto", height: "300px", position: "sticky", top: "0", zIndex: "10" }}
          >
            <thead >
              <tr>
                <th className="wd-lg-20p">
                  <span>Request ID</span>
                </th>
                <th className="wd-lg-20p">
                  <span>Doctor Id</span>
                </th>
                <th className="wd-lg-20p">
                  <span>Doctor Email</span>
                </th>
                <th className="wd-lg-20p">
                  <span>Request Type</span>
                </th>
                <th className="wd-lg-20p">
                  <span>Request Raised By</span>
                </th>
                <th className="wd-lg-20p">
                  <span>Date</span>
                </th>
                <th className="wd-lg-20p">
                  <span>Designation</span>
                </th>
                {/* <th className="wd-lg-20p">
                <span>Current Status</span>
              </th> */}
              </tr>
            </thead>
            <tbody>
              {newDoctorData &&
                newDoctorData.map((ele) => (
                  <tr key={ele.id}>
                    <td>{ele.id} </td>
                    <td>{ele.doctorId ? ele.doctorId : "---"} </td>
                    <td>{ele.email} </td>
                    <td>{ele.category} </td>
                    <td>{ele.label}</td>
                    <td>{dateFormatter(ele.createdAt)}</td>
                    <td>{ele.designationLabel}</td>
                    {/* <td>{ele.status}</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : user && user.user && user.user.roles == "3" ? (
        <div className="card-body">
          <h3 className="main-content-title tx-24 mb-4 mg-b-5">New Requests</h3>
          <div>
            <table
              className="table card-table table-striped table-vcenter text-nowrap mb-0 ctable"
              id="table"
              style={{ overflowY: "auto", height: "300px", position: "sticky", top: "0", zIndex: "10" }}
            >
              <thead >
                <tr>
                  <th className="wd-lg-20p">
                    <span>Request ID</span>
                  </th>
                  <th className="wd-lg-20p">
                    <span>Doctor Id</span>
                  </th>
                  <th className="wd-lg-20p">
                    <span>Doctor Email</span>
                  </th>
                  <th className="wd-lg-20p">
                    <span>Request Type</span>
                  </th>
                  <th className="wd-lg-20p">
                    <span>Request Raised By</span>
                  </th>
                  <th className="wd-lg-20p">
                    <span>Date</span>
                  </th>
                  <th className="wd-lg-20p">
                    <span>Designation</span>
                  </th>
                  {/* <th className="wd-lg-20p">
                <span>Current Status</span>
              </th> */}
                </tr>
              </thead>
              <tbody>
                {newDoctorData &&
                  newDoctorData.map((ele) => (
                    <tr key={ele.id}>
                      <td>{ele.id} </td>
                      <td>{ele.doctorId ? ele.doctorId : "---"} </td>
                      <td>{ele.email} </td>
                      <td>{ele.category} </td>
                      <td>{ele.label}</td>
                      <td>{dateFormatter(ele.createdAt)}</td>
                      <td>{ele.designationLabel}</td>
                      {/* <td>{ele.status}</td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : user && user.user && user.user.roles == "2" ? (
        <div className="card-body">
          <h3 className="main-content-title tx-24 mb-4 mg-b-5">New Requests</h3>
          <div>
            <table
              className="table card-table table-striped table-vcenter text-nowrap mb-0 ctable"
              id="table"
              style={{ overflowY: "auto", height: "300px", position: "sticky", top: "0", zIndex: "10" }}
            >
              <thead >
                <tr>
                  <th className="wd-lg-20p">
                    <span>Request ID</span>
                  </th>
                  <th className="wd-lg-20p">
                    <span>Doctor Id</span>
                  </th>
                  <th className="wd-lg-20p">
                    <span>Doctor Email</span>
                  </th>
                  <th className="wd-lg-20p">
                    <span>Request Type</span>
                  </th>
                  <th className="wd-lg-20p">
                    <span>Request Raised By</span>
                  </th>
                  <th className="wd-lg-20p">
                    <span>Date</span>
                  </th>
                  <th className="wd-lg-20p">
                    <span>Designation</span>
                  </th>
                  {/* <th className="wd-lg-20p">
                <span>Current Status</span>
              </th> */}
                </tr>
              </thead>
              <tbody>
                {newDoctorData &&
                  newDoctorData.map((ele) => (
                    <tr key={ele.id}>
                      <td>{ele.id} </td>
                      <td>{ele.doctorId ? ele.doctorId : "---"} </td>
                      <td>{ele.email} </td>
                      <td>{ele.category} </td>
                      <td>{ele.label}</td>
                      <td>{dateFormatter(ele.createdAt)}</td>
                      <td>{ele.designationLabel}</td>
                      {/* <td>{ele.status}</td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : <>
        <div className="card-body">
          <h3 className="main-content-title tx-24 mb-4 mg-b-5">New Doctors</h3>
          <table
            className="table card-table table-striped table-vcenter text-nowrap mb-0 ctable"
            id="table"
            style={{ overflowY: "auto", height: "300px", position: "sticky", top: "0", zIndex: "10" }}
          >
            <thead >
              <tr>
                <th className="wd-lg-20p">
                  <span>Request ID</span>
                </th>
                <th className="wd-lg-20p">
                  <span>Doctor Id</span>
                </th>
                <th className="wd-lg-20p">
                  <span>Doctor Email</span>
                </th>
                <th className="wd-lg-20p">
                  <span>Request Type</span>
                </th>
                <th className="wd-lg-20p">
                  <span>Request Raised By</span>
                </th>
                <th className="wd-lg-20p">
                  <span>Date</span>
                </th>
                <th className="wd-lg-20p">
                  <span>Designation</span>
                </th>
                {/* <th className="wd-lg-20p">
                  <span>Current Status</span>
                </th> */}
              </tr>
            </thead>
            <tbody>
              {newDoctorData &&
                newDoctorData.map((ele) => (
                  <tr key={ele.id}>
                    <td>{ele.id} </td>
                    <td>{ele.doctorId ? ele.doctorId : "---"} </td>
                    <td>{ele.email} </td>
                    <td>{ele.category} </td>
                    <td>{ele.label}</td>
                    <td>{dateFormatter(ele.createdAt)}</td>
                    <td>{ele.designationLabel}</td>
                    {/* <td>{ele.status}</td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div> </>}
      <Modal
        size="lg"
        scrollable
        dialogClassName="modalStandard"
        centered
        aria-labelledby="example-modal-sizes-title-lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="modalHeader" closeButton>
          <Modal.Title className="modalTitle">
            Requested Id:{findpos.id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {addpos.data && addpos.data.map((ele,index) =>( */}
          <div className="row">
            <div className="col-6 ">
              <label className="tx-inverse text-muted">Request Id</label>
              <input
                className="form-control"
                id="RequestId"
                value={findpos.id}
                placeholder="Input box"
                type="text"
              />
              <br />
              <label className="tx-inverse text-muted">Onboarding Type</label>
              <input
                className="form-control"
                id="RequestType"
                placeholder="Input box"
                value={findpos.category}
                type="text"
              />
            </div>

            <div className="col-6 ">
              <label className="tx-inverse text-muted">Request Raised By</label>
              <input
                className="form-control"
                id="RequestRaisedBy"
                placeholder="Input box"
                value={findpos.label}
                type="text"
              />
              <br />
              <label className="tx-inverse text-muted">Date</label>
              <input
                className="form-control"
                id="Date"
                placeholder="Input box"
                value={findpos.createdAt}
                type="text"
              />
            </div>
          </div>
          {/* ))}   */}
          <br />
          <div className="row">
            <div className="col-6 ">
              <label className="tx-inverse text-muted">Subject</label>
              <input
                className="form-control"
                id="Subject"
                placeholder="Input box"
                value={findpos.designationLabel}
                type="text"
              />
            </div>

            <div className="col-6 ">
              <label className="tx-inverse text-muted">Current Status</label>
              <input
                className="form-control"
                id="CurrentStatus"
                placeholder="Input box"
                value={findpos.status}
                type="text"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6 ">
              <label className="tx-inverse text-muted">Designation</label>
              <input
                className="form-control"
                id="Subject"
                placeholder="Input box"
                value={findpos.designationLabel}
                type="text"
              />
            </div>

            <div className="col-6 ">
              <label className="tx-inverse text-muted">Category</label>
              <input
                className="form-control"
                id="CurrentStatus"
                placeholder="Input box"
                value={findpos.category}
                type="text"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6 ">
              <label className="tx-inverse text-muted">Department</label>
              <input
                className="form-control"
                id="Subject"
                placeholder="Input box"
                value={findpos.departmentLabel}
                type="text"
              />
            </div>

            <div className="col-6 ">
              <label className="tx-inverse text-muted">Center Name</label>
              <input
                className="form-control"
                id="CurrentStatus"
                placeholder="Input box"
                value={findpos.centerNames}
                type="text"
              />
            </div>
          </div>

          <br />
          <label className="tx-inverse text-muted">Comments</label>
          <textarea
            class="form-control"
            placeholder="Textarea"
            rows="3"
          ></textarea>

          {/* <p className="tx-inverse "> Personal Information</p>

                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <label className="tx-inverse text-muted">Full Name</label>
                            <input className="form-control" placeholder="Input box" type="text" />
                        </div>

                        <div className="col-12 col-sm-4">
                            <label className="tx-inverse text-muted">Contact Number</label>
                            <input className="form-control" placeholder="Input box" type="text" />
                        </div>

                        <div className="col-12 col-sm-4">
                            <label className="tx-inverse text-muted">Email Id</label>
                            <input className="form-control" placeholder="Input box" type="text" />
                        </div>
                    </div> */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Link
            to={{
              pathname: "/create-doctor",

              state: { data: findpos },
            }}
            className="standardButton acceptButton pt-2"
            style={{ textAlign: "center" }}
          >
            Initiate Onboarding
          </Link>

          <button className="standardButton rejectButton" onClick={handleClose}>
            Review
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RequestTable;
