import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { Paper } from "@material-ui/core";
import Navbar from "../../Components/Navbar";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
//ui component
import SidebarRD from "../../Components/Rd-sidebar";

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  customizedButton: {
    position: "absolute",
    left: "95%",
    top: "-9%",
    backgroundColor: "lightgray",
    color: "gray",
  },
}));
const styleSheet = makeStyles((theme) => ({
  iconButtonStyle: {
    float: "right",
  },
  marginforMaster: {
    marginTop: "30px",
  },
}));
export default function CenterWisePayoutDetailsRD() {
  const tables = myTableStyle();
  const location = useLocation();
  const [rows, setRows] = useState([]);
  const [values, setValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = styleSheet();
  const [data, setData] = useState("Select the value");
  const [reload, setReload] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  function selectedDropdownvalue(e) {
    setLoading(false);
    const { name, value } = e.target;
    setData(value);
    axios
      .get(APIURL() + "billings/MonthwiseCenter/" + value)
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows, "XXXXXXXXXXXX");
        var k = rows.data.filter((z) => z.center === location.state.data );
        setValues(k);
        setLoading(true);
      });
  }
  console.log(user, "****(");
  useEffect(() => {
    axios
      .get(APIURL() + "billings/findByCenterwise/" + location.state.data)
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows, "AllExcelData");
        setRows(rows);
      });
  }, [reload]);
  console.log(rows, "123");
  return (
    <>
      <SidebarRD />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
        <Typography
          variant="subtitle1"
          style={{
            margin: ".4rem",
            textAlign: "center",
            padding: "7px",
            backgroundColor: "#810e36",
            color: "white",
          }}
        >
          Center Wise PayoutDetails
        </Typography>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            id="demo-simple-select-outlined-label"
            style={{ margin: "15px" }}
          >
            <b>Filter By-</b>
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={data}
            onChange={selectedDropdownvalue}
            label="select the value"
            fullWidth
          >
            <MenuItem value="Select the value" disabled>
              Select the value
            </MenuItem>
            <MenuItem value="Current Month">Current Month</MenuItem>
            <MenuItem value="Previous Month">Previous Month</MenuItem>
            <MenuItem value="Two Months Ago">Two Months Ago</MenuItem>
          </Select>
        </FormControl>
        <Grid container spacing={1} style={{ marginBottom: "40px" }}>
          {!loading ? (
            <Skeleton
              animation="wave"
              variant="rect"
              width={1045}
              height={500}
            />
          ) : (
            <Paper className={tables.root}>
              <MaterialTable
                columns={[
                  { title: "SlNo", field: "SlNo" },
                  { title: "MPI", field: "MPI" },
                  { title: "Item Group Name", field: "ItemGroupName" },
                  { title: "Item Name", field: "ItemName" },
                  { title: "Specialisation", field: "Specialisation" },
                  { title: "Center", field: "center" },
                  { title: "Ordering Physician", field: "OrderingPhysician" },
                  {
                    title: "Conducted By",
                    field: "ConductedBy",
                    filtering: false,
                    render: (row) => (
                      <div>
                        {row.ConductedBy !== "nan" ? row.ConductedBy : "NA"}
                      </div>
                    ),
                  },
                  { title: "Doctor Payout", field: "calculatedValue" },
                ]}
                data={values}
                options={{
                  sorting: true,
                  exportButton: true,
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 50, 100, 200, 500],
                  search: true,
                  exportAllData: true,
                }}
                title="Payout-Detail"
              />
            </Paper>
          )}
        </Grid>
      </Container>
    </>
  );
}
