import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import PayoutSummaryCard from "../../Components/PayoutSummaryCard";
import DoctorSidebar from "../../Components/DoctorSidebar";
import Skeleton from "@material-ui/lab/Skeleton";

export default function CenterWisePayout() {
  const [rows, setRows] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  console.log(user, "****(");
  useEffect(() => {
    setLoading(false);
    axios
      .get(APIURL() + "billings/Centerwise")
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows, "AllExcelData");
        setRows(rows.data);
        setLoading(true);
      });
  }, [reload]);
  var loadingdata = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }];
  return (
    <>
      <DoctorSidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <div
          style={{
            margin: ".4rem",
            textAlign: "center",
            padding: "7px",
            backgroundColor: "#810e36",
            color: "white",
          }}
        >
          Center wise Payout
        </div>
        {!loading ? (
          <>
            <Grid container spacing={1} style={{ marginBottom: "40px" }}>
              <Grid item xs={4} sm={4} lg={4} md={4} xl={4}>
                {" "}
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={250}
                  height={100}
                />
              </Grid>
              <Grid item xs={4} sm={4} lg={4} md={4} xl={4}>
                {" "}
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={250}
                  height={100}
                />
              </Grid>
              <Grid item xs={4} sm={4} lg={4} md={4} xl={4}>
                {" "}
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={250}
                  height={100}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid container spacing={1} style={{ marginBottom: "40px" }}>
            {rows &&
              rows.map((opt, index) => (
                <Grid item xs={4} sm={4} lg={4} md={4} xl={4}>
                  <PayoutSummaryCard data={opt} detail="doctorcenter" />
                </Grid>
              ))}
          </Grid>
        )}
      </Container>
      {/* <Card data={opt} */}
    </>
  );
}
