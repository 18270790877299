import React, { useState } from "react";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Box, FormControl, Paper, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const styleSheet = makeStyles((theme) => ({
  paperStyle: { marginTop: "10px" },
  textFieldstyle: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  btnStyle: {
    width: '10%',
    marginLeft: '50%',
    marginRight: '80px',
    marginBottom: '10px',
    marginTop: 'inherit',
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    width: "100%",
  },
}));

export default function AddFirmMapping({
  addDepartment,
  firmList,
  doctorList,
  centerList
}) {
  const style = styleSheet();
  const classes = useStyles();
  const initialFormState = {
    FirmId: 0,
    siteCode: '',
    doctorId: [],
  };

  const [department, setDepartment] = useState(initialFormState);
  const [centerValue, setCenterValue] = useState({});
  const [DocValue, setDocValue] = useState([]);
  const [doctorListByCenter, setDoctorListByCenter] = useState({});

  const handleInputChange =(event)=>{
    setDepartment({...department, 'FirmId': event.target.value})
  }
  console.log(firmList,"firmList")
  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          addDepartment(department);
        }}
      >
        <Paper className={style.paperStyle}>
          <div className="row mt-2 pt-2">
            <div className="col-4" style={{display: 'flex',alignContent: 'center',justifyContent: 'center',alignItems: 'center'}}>Select Firm :</div>
            <div className="col-6">
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Firm
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="regionid"
                  value={department.FirmId}
                  style={{width:'94%'}}
                  onChange={handleInputChange}
                  label="Select Firm"
                >
                  {firmList &&
                    firmList.map((opt) => (
                      <MenuItem key={opt.firmId} value={opt.firmId}>
                        {opt.firmName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="row mt-2 pt-2">
            <div className="col-4" style={{display: 'flex',alignContent: 'center',justifyContent: 'center',alignItems: 'center'}}>Select Center :</div>
            <div className="col-6">
              <Autocomplete
                id="combo-box-demo"
                value={centerValue}
                onChange={(event, newValue) => {
                  setCenterValue(newValue);
                  setDoctorListByCenter(doctorList.filter((item => item.centerCode == newValue.code)));
                  setDepartment({...department, "siteCode": newValue.code})
                }}
                options={centerList}
                getOptionLabel={(option) => option.name ? option.name : ''}
                sx={{ width: 515, height: 20 }}
                renderInput={(params) => <TextField {...params} label="Centers" />}
                renderOption={(props, option, state) => {
                  return (
                    <Typography key={option.name} {...props} style={{ color: 'black' }}>{option.name}</Typography>
                  );
                }}
              />
            </div>
          </div>
          <div className="row pt-2" style={{marginTop:'43px'}}>
            <div className="col-4" style={{display: 'flex',alignContent: 'center',justifyContent: 'center',alignItems: 'center'}}>Select Doctors :</div>
            <div className="col-6">
              <Autocomplete
                multiple
                disabled={centerValue && Object.keys(centerValue).length > 0 ? false : true}
                id="checkboxes-tags-demo"
                options={doctorListByCenter}
                onChange={(event, newValue) => {
                  // console.log(newValue,"DocValue------")
                  setDocValue(newValue);
                  let doctorList = []
                  newValue.map(item => doctorList.push(item.doctorId))
                  setDepartment({...department, "doctorId":doctorList})
                }}
                disableCloseOnSelect
                getOptionLabel={(option) => option.doctorName}
                renderOption={(props, option, { selected }) => (
                  <Typography {...props} style={{ color: 'black' }}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.doctorName}
                  </Typography>
                )}
                style={{ width: 515 }}
                renderInput={(params) => (
                  <TextField {...params} label="Doctor List" placeholder="Doctor List" />
                )}
              />
            </div>
          </div>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={style.btnStyle}
          >
            Add
          </Button>
        </Paper>
      </form>
    </>
  );
}
