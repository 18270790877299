import React, { useEffect, useState } from "react";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  FormControl,
  FormControlLabel,
  Paper,
  RadioGroup,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

const styleSheet = makeStyles((theme) => ({
  paperStyle: { marginTop: "10px" },
  textFieldstyle: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  btnStyle: {
    right: "5px",
    marginBottom: "20px",
  },
  cancellBtnStyle: {
    marginBottom: "20px",
  },
}));

export default function EditPermissions({
  currentDepartment,
  setEditing,
  setPreview,
  updateDepartment,
  resourcelist,
}) {
  const style = styleSheet();
  //   const initialFormState = { id: null, name: "", code: "", status: "" };
  const [department, setDepartment] = useState(currentDepartment);
  const [value, setValue] = React.useState(0);
  const [check, setCheck] = useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartment({ ...department, [name]: value });
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    var ischecked = checked ? "1" : "0";
    setDepartment({ ...department, [name]: ischecked });
  };
  useEffect(() => {
    setValue(department.status);
  }, [currentDepartment]);

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          department.status = value;
          updateDepartment(department.id, department);
        }}
      >
        <CssBaseline />

        <Paper className={style.paperStyle}>
          <CssBaseline />
          <Container>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select No Of Month
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="roleid"
                    value={department.roleid}
                    onChange={handleInputChange}
                    label="Select Role"
                  >
                    <MenuItem value="4">Doctor</MenuItem>
                    <MenuItem value="3">HR Personnel</MenuItem>
                    <MenuItem value="5">Legal Advisor</MenuItem>
                    <MenuItem value="6">Finance Team</MenuItem>
                    <MenuItem value="1">Regional Director</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  variant="outlined"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Module
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="module"
                    value={department.module}
                    onChange={handleInputChange}
                    label="Select Role"
                  >
                    {resourcelist &&
                      resourcelist.map((opt) => (
                        <MenuItem key={opt.id} value={opt.resourcemodule}>
                          {opt.resourcemodule}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl
                  variant="outlined"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Reouurce
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="resource"
                    value={department.resource}
                    onChange={handleInputChange}
                    label="Select Role"
                  >
                    {resourcelist &&
                      resourcelist.map((opt) => (
                        <MenuItem key={opt.id} value={opt.resourcename}>
                          {opt.resourcename}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}></Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div>
                  <FormControl
                    component="fieldset"
                    // className={classes.formControl}
                    style={{ marginTop: "18px" }}
                  >
                    <FormLabel component="legend">Access Permissions</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={department.canview == "1" ? true : false}
                            onChange={handleCheckboxChange}
                            value={department.canview}
                            name="canview"
                          />
                        }
                        label="Can View"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={department.cancreate == "1" ? true : false}
                            value={department.cancreate}
                            onChange={handleCheckboxChange}
                            name="cancreate"
                          />
                        }
                        label="Can Create"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={department.canedit == "1" ? true : false}
                            value={department.canedit}
                            onChange={handleCheckboxChange}
                            name="canedit"
                          />
                        }
                        label="Can Edit"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={department.candelete == "1" ? true : false}
                            value={department.candelete}
                            onChange={handleCheckboxChange}
                            name="candelete"
                          />
                        }
                        label="Can Delete"
                      />
                    </FormGroup>
                    {/* <FormHelperText>Be careful</FormHelperText> */}
                  </FormControl>
                </div>
              </Grid>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={style.btnStyle}
              >
                Update
              </Button>
            </Grid>
          </Container>
        </Paper>
      </form>
    </>
  );
}
