import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import "jspdf-autotable";
import _filefy from "filefy";
import moment from "moment";
import { calculateIpAgreement } from '../BusinessLogic/ipCalc';
const myTableStyle = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "20%",
        marginTop: theme.spacing(3),
        "& td": {
            fontFamili: "Roboto !important",
        },
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflow: "auto",
        marginBottom: "50px",
    },
}));

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "fixed",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AgreementMgip({ ipcomp, history }) {
    console.log(ipcomp, "*111")
    const classes = useStyles();

    const userList = useSelector((state) => state.userList);
    const { users } = userList;

    return (
        ipcomp && ipcomp.length > 0 ?
            <div className="card custom-card" >

                <div className="card-body">

                    <p><strong>(ii) For In-Patient Procedures: </strong></p>

                    <div className="sublistAgmt">

                        {ipcomp ? ipcomp.map((ele, key) => (
                            <p>
                                ({key + 1}) For {ele.group ? ele.group : ""} {ele.roomCategory !== "" ? ele.roomCategory + "," : ""}{ele.itemGroup ? ele.itemGroup : ""} ({ele.itemName ? ele.itemName : ""}) - {ele.Center} - {ele.commercialTerm} {ele.ipmodeofpayment}
                                {/* ({key + 1}) For {""} {ele.iproom !== "" ? ele.iproom + "," : ""}  ({ele.itemGroup.split("-")[0]}{ele.itemGroup.split("-")[1] === undefined ? "" : "/" + ele.itemGroup.split("-")[1]}) - Rs. {ele.ipfixedamount}/- per case  */}
                            </p>
                        ))
                            : ""}

                        {/* <p>({ipcomp && ipcomp.length ? (ipcomp.length + 1) : ""}) For all other In Inpatient Services (IP) provided by the Consultant during the term of this agreement, the company shall pay a fixed professional fee as defined in the Hospital Tariff Package approved by the management of the company from time to time. The details of the package is enclosed herewith as ANNEXURE-Platinum. For any other Inpatient services provided by the consultant, which are not covered in the Hospital Tariff Package, for such services the Consultant shall be compensated on case to case basis as decided by the management of the company.</p> */}
                    </div>
                    {
                        // ipcomp[ipcomp.length - 1].feeNature !== 'Visiting Consultant' ?
                        //     <p><strong>(iii).</strong> The Consultant is permitted to avail a maximum of 15 (fifteen) paid leaves in a calendar year. Post which a deduction will be applicable on the Minimum Guarantee amount for the days the Consultant is on leave.</p>
                        //     : ""
                    }
                </div>

            </div>
            : ''
    );
}

export default AgreementMgip;
