export const ONBOARDING_ADD_REQUEST ='ONBOARDING_ADD_REQUEST'
export const ONBOARDING_ADD_SUCCESS ='ONBOARDING_ADD_SUCCESS'
export const ONBOARDING_ADD_FAIL ='ONBOARDING_ADD_FAIL'


export const ONBOARDING_REQUEST ='ONBOARDING_REQUEST'
export const ONBOARDING_SUCCESS ='ONBOARDING_SUCCESS'
export const ONBOARDING_FAIL ='ONBOARDING_FAIL'

export const ONBOARD_ID_REQUEST ='ONBOARD_ID_REQUEST'
export const ONBOARD_ID_SUCCESS ='ONBOARD_ID_SUCCESS'
export const ONBOARD_ID_FAIL ='ONBOARD_ID_FAIL'


export const ONBOARDING_GET_REQUEST ='ONBOARDING_GET_REQUEST'
export const ONBOARDING_GET_SUCCESS ='ONBOARDING_GET_SUCCESS'
export const ONBOARDING_GET_FAIL ='ONBOARDING_GET_FAIL'