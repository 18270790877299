import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo2 from "../images/Logos-04.png";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { listusers } from "../actions/userActions";

function DoctorSidebar({ userType }) {
  const [dash, setDash] = useState("bh-dashboard");
  const [item4, setItem4] = useState("visibleDiv");
  const [item5, setItem5] = useState("visibleDiv");
  const [item6, setItem6] = useState("visibleDiv");
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const dispatch = useDispatch();
  function CheckUser() {
    if (userType === "BH") {
      setDash("bh-dashboard");
    } else if (userType === "HR") {
      setDash("hr-dashboard");
      setItem5("hiddenDiv");
    } else if (userType === "COO") {
      setDash("rd-dashboard");
      setItem4("hiddenDiv");
      setItem6("hiddenDiv");
    }
    useEffect(() => {
      dispatch(listusers());
    }, [dispatch]);
  }

  //  checkUser();

  // useEffect(() => {
  //   checkUser();
  //  } )

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <div class="sidebar main-sidebar main-sidebar-sticky side-menu ps ps--active-y">
      <div className="main-sidebar-header header-img">
        <Link to="/doctor-dashboard">
          <img src={logo2} className="img-responsive" alt="logo" />
        </Link>
      </div>
      <div className="main-sidebar-body">
        <ul className="nav">
          {/* <li
        className={splitLocation[1] === "AddQualification" ? "active "+"nav-item" : "nav-item"}
      >
        <Link to="/AddQualification" className="nav-link" id="nav-link">
        <span className="shape1"></span>
       <span className="shape2"></span>
          <i className="fas fa-plus sidemenu-icon mr-2"></i>
          <span className="sidemenu-label">Add Qualification</span>
        </Link>
      </li>
      <li
        className={splitLocation[1] === "AddEmployer" ? "active "+"nav-item" : "nav-item"}
      >
        <Link to="/AddEmployer" className="nav-link" id="nav-link">
        <span className="shape1"></span>
       <span className="shape2"></span>
          <i className="fas fa-plus sidemenu-icon mr-2"></i>
          <span className="sidemenu-label">Add Employer</span>
        </Link>
      </li>
      <li
        className={splitLocation[1] === "DoctorProfileStatus" ? "active "+"nav-item" : "nav-item"}
      >
        <Link to="/DoctorProfileStatus" className="nav-link" id="nav-link">
        <span className="shape1"></span>
       <span className="shape2"></span>
          <i className="ti-user sidemenu-icon mr-2"></i>
          <span className="sidemenu-label">Doctor Profile</span>
        </Link>
      </li> */}

          <div
            style={{
              display: "block",
              width: "100%",
              marginTop: "-.2rem",
              marginLeft: "2px",
            }}
          >
            <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent" }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}
                  >
                    <i className="fas fa-angle-down  sidemenu-icon mr-4"></i>
                    Onboard Actions
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/Estimation"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-bookmark-alt sidemenu-icon mr-2"></i>
                      Estimation
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/ProfileDetails"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-pencil-alt sidemenu-icon mr-2"></i>
                      Profile Details
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/DoctorDashboard"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fas fa-file-upload sidemenu-icon mr-2"></i>
                      Documents
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
                {/* <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/check-agreement"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                      Agreement
                    </Link>
                  </Card.Body>
                </Accordion.Collapse> */}
              </Card>
            </Accordion>
          </div>

          {/* <div
            style={{
              display: "block",
              width: "100%",
              marginTop: "-.2rem",
              marginLeft: "2px",
            }}
          >
            <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent" }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}
                  >
                    <i className="ti-folder sidemenu-icon mr-4"></i>
                    Payout
                  </Accordion.Toggle>
                </Card.Header>
              
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/BillingDetails"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-pencil-alt sidemenu-icon mr-2"></i>
                      Billing Details
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/CenterWisePayout"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-pencil-alt sidemenu-icon mr-2"></i>
                      Center Wise
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/DoctorWisePayout"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-pencil-alt sidemenu-icon mr-2"></i>
                      Doctor Wise
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div> */}
          <div
            style={{
              display: "block",
              width: "100%",
              marginTop: "-.2rem",
              marginLeft: "2px",
            }}
          >
            <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent" }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}
                  >
                    <i className="fas fa-user-circle sidemenu-icon mr-4"></i>
                    My Account
                  </Accordion.Toggle>
                </Card.Header>

                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to={{ pathname: "/user-profile", state: { id: user.user.id } }}
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-user sidemenu-icon mr-2"></i>
                      Doctor Profile
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
                {/* <Accordion.Collapse eventKey="0">
                  <Card.Body
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/AddEmployer"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fas fa-plus sidemenu-icon mr-2"></i>
                       Employer
                    </Link>
                  </Card.Body>
                </Accordion.Collapse> */}
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/AddQualification"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fas fa-plus sidemenu-icon mr-2"></i>
                      Qualification
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>

          <div
            style={{
              display: "block",
              width: "100%",
              marginTop: "-.2rem",
              marginLeft: "2px",
            }}
          >
            <Link
              to="/DoctorOpPayout"
              id="nav-link"
              className="new-nav-link"
              style={{ backgroundColor: "transparent", height: "2.6rem", border: "none", marginTop: "1px", color: "white",}}
            >
              <i className="ti-user sidemenu-icon mr-2"></i>
              <span className="sidemenu-label" style={{ fontSize: "16px" }}>Doctor Payout</span>
            </Link>
            {/* <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent" }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}
                  >
                    <i className="fas fa-user-circle sidemenu-icon mr-4"></i>
                    Payout
                  </Accordion.Toggle>
                </Card.Header>

                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/DoctorOpPayout"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-user sidemenu-icon mr-2"></i>
                      Doctor Payout
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>

              </Card>
            </Accordion> */}
          </div>
        </ul>
      </div>
    </div>
  );
}

export default DoctorSidebar;