export const USER_CREATE_REQUEST ='USER_CREATE_REQUEST'
export const USER_CREATE_SUCCESS ='USER_CREATE_SUCCESS'
export const USER_CREATE_FAIL ='USER_CREATE_FAIL'

export const USER_LIST_REQUEST ='USER_LIST_REQUEST'
export const USER_LIST_SUCCESS ='USER_LIST_SUCCESS'
export const USER_LIST_FAIL ='USER_LIST_FAIL'

export const USER_LIST_ALL_REQUEST ='USER_LIST_ALL_REQUEST'
export const USER_LIST_ALL_SUCCESS ='USER_LIST_ALL_SUCCESS'
export const USER_LIST_ALL_FAIL ='USER_LIST_ALL_FAIL'

