import React from "react";
// material-table component
import MaterialTable from "material-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

export default function ListCommercials({ rows, editRow, deleteDepartment }) {
  const tables = myTableStyle();

  console.log(rows,"rows");
  return (
    <>
      <Paper className={tables.root}>
        <MaterialTable

          columns={[
            { title: "Doctor Name", field: "doctorName" },
            { title: "Doctor Type", field: "natureofdoc" },
            { title: "Region", field: "region" },
            { title: "Item Group", field: "itemGroup" },
            { title: "Item Name", field: "itemName" },
            { title: "Fixed Amount", field: "fixedamount" },
            { title: "Fixed %", field: "fixedpercentage" },
            { title: "Applicable On", field: "applicable" },
          ]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit",
              iconProps: { style: { fontSize: "24px", color: "#5f5fe7" } },
              onClick: (event, row) => editRow(row),
            },
            {
              icon: "delete",
              tooltip: "Delete",
              iconProps: { style: { fontSize: "24px", color: "#f55151" } },
              onClick: (event, row) => {
                deleteDepartment(row);
              },
            },
          ]}
          data={rows}
          options={{
            // filtering: true,
            sorting: true,
            exportButton: true,
            exportAllData: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 50, 100, 200, 500],
            search: true,
            rowStyle: (rowData) => ({
              backgroundColor: rowData.chstatus === "Yes" ? "#ad4079" : "#FFF",
            }),
          }}
          title="OP Commercials"
        />
      </Paper>
    </>
  );
}
