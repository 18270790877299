import React, { useEffect, useState } from "react";
//import from mui
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
//import component
import ConfirmDialog from "../../Components/ConfirmDialog";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import PersonalDetail from "./Onboard/PersonalDetail";
import TabComponent from "../../Components/TabComponent";
import { agreementState } from "../../CommomMethods/States";
import { useDispatch, useSelector } from "react-redux";
import { userslistall } from "../../actions/userActions";
import { APIURL } from "../../CommomMethods/Fetch";
import c9gif from '../../SampleFile/cloudnine.gif';
//axios
import axios from "axios";
import { TextField, TextareaAutosize } from "@material-ui/core";

const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function OnboardForm(props) {
  // const [record, setRecord] = useState({});
  console.log(props.location.state, "props.location.state");
  const [record, setRecord] = useState({
    emailId: "",
    fullName: "",
    McrcName: "",
    contactNumber: "",
    term: "",
    Center: "",
    noticeperiod: "",
    lockinperiod: "",
  });
  const [recordfullname, setRecordfullname] = useState({
    selectedName: ""
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const [backDropOpen, setBackDropOpen] = useState(false);
  const backClasses = backDropStyle();
  const [category, setCategory] = useState({});
  const [categoryTable, setCategoryTable] = useState([]);
  const [opItems, setOpItems] = useState([]);
  const [opItemsCommercial, setOpItemsCommercial] = useState([]);
  const [centerData, setCenterData] = useState([]);
  const [ipItems, setIpItems] = useState([]);
  const [ipItemsCommercial, setIpItemsCommercial] = useState([]);
  const [error, setError] = useState({});
  const usersAll = useSelector((state) => state.usersAll);
  const { usersall } = usersAll;
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const [term, setTerm] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [lockinPeriod, setLockinPeriod] = useState("");
  const [contractTerm, setContractTerm] = useState("");
  const [agreementTemplate, setAgreementTemplate] = useState("");
  const [primaryCenter, setPrimaryCenter] = useState(null);
  const [agreementAnnexure, setAgreementAnnexure] = useState("");
  const [estimation_remarks, setEstimationRemarks] = useState("");
  const [centerList, setCenterList] = useState("");
  const [doctorData, setDoctorData] = useState("");

  // console.log(props.location.state.data.id, "props.location.state.data");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userslistall());
  }, [dispatch]);
  useEffect(() => {
    setCategory({ ...category, mgperiod: contractTerm ? parseInt((contractTerm).split(' ')[0]) : '' })
    console.log(category, term, contractTerm, 'cattttt')
  }, [contractTerm]);
  useEffect(() => {
    if (props.location.state.data.doctorId) {
      getDoctorDetails(props.location.state.data.doctorId)
    }
  }, [props.location.state]);
  useEffect(() => {
    axios
      .get(APIURL() + "centers/")
      .then((res) => res.data)
      .then((rows) => {
        // setRows(rows);
        setCenterList(rows.filter((center) => center.status));
      });
  }, []);
  console.log(usersall, "#####");
  console.log(lockinPeriod, "lockinPeriod-data");
  const onchange = (data) => {
    console.log(data, "data from personal detail");
    setRecord(data);
  };

  const onchangefulname = (data) => {
    console.log(data, "ffffff");
    setRecordfullname(data);
  };
  const onchangeOpTable = (data) => {
    setOpItems(data);
  };

  const onchangeOpTableCommercial = (data) => {
    setOpItemsCommercial(data);
  };

  const onchangeIpTable = (data) => {
    setIpItems(data);
  };

  const onchangeIpTableCommercial = (data) => {
    setIpItemsCommercial(data);
  };

  const onChangeEstimateRemarks = (event) => {
    const { name, value } = event.target;
    setEstimationRemarks(value)
  }

  const onchnagecategory = (data) => {
    setCategory(data);
    console.log(data, "PPPPPPPOOOOOOOOOOOOOOOOOOo");
  };
  const onchnagecategoryTable = (data) => {
    setCategoryTable(data);
    console.log(data, "PPPPPPPOOOOOOOOOOOOOOOOOOo");
  };
  // console.log(record,"errors_in_table")
  console.log(Object.values(category).length, "category.length < 0");

  // Save as Draft Commercial for doctor
  const saveAsDraft = async (event) => {
    await setBackDropOpen(true);
    let temp = {};
    var phoneno = /^[1-9]\d{9}$/;
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var pan = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    if (record.emailId != "" && record.emailId != null) {
      temp.emailId = record.emailId != "" ? "" : "This is required field.";
      if (record.emailId) {
        temp.emailId = record.emailId.includes('@cloudninecare') ? "Email should be doctor's personal email" : re.test(record.emailId) ? "" : "Invalid email Id.";
      }
    } else {
      temp.emailId = "This is required field.";
    }

    if (record.fullName == "" || record.fullName == null) {
      temp.fullName = "This is required field.";
    } else {
      temp.fullName = "";
    }

    if (record.McrcName == "" || record.McrcName == null) {
      temp.McrcName = "This is required field.";
    } else {
      temp.McrcName = "";
    }

    temp.contactNumber =
      record.contactNumber != "" && record.contactNumber != null ? "" : "This is required field.";
    if (record.contactNumber) {
      temp.contactNumber = phoneno.test(record.contactNumber)
        ? ""
        : "Invalid phone number.";
    }
    temp.annexure = agreementAnnexure && error.annexure ? error.annexure : ""
    console.log(record, "errors_in_table")
    await setError({ ...temp });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (!Object.values(temp).every((x) => x === "")) {
      await setBackDropOpen(false);
      await setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please fill all the required details first",
      });
      return;
    }
    console.log(record, recordfullname, props.location.state.data, "#############3");
    record.Center = props.location.state.data.centerNames;
    record.cat = props.location.state.data.category;
    console.log(ipItems, "ipItems");
    record.centerlist = centerData;
    record.noticeperiod = noticePeriod;
    record.term = term;
    record.lockinperiod = lockinPeriod;
    record.agreementTemplate = agreementTemplate;
    record.primaryCenter = primaryCenter;
    record.agreementAnnexure = agreementAnnexure;
    // record.contractterm = contractTerm.terminmonths;
    record.contractterm = term;
    if (recordfullname.selectedName != "") {
      record.fullName = recordfullname.selectedName;
    }
    console.log(record, "@@@@@@@");
    const dataRecord = {
      // obj:obj,
      allcenter: centerData,
      record: record,
      category: categoryTable,
      opItems: opItems,
      opItemsCommercial: opItemsCommercial,
      ipItems: ipItems,
      ipItemsCommercial: ipItemsCommercial,
      region: user.user.usermap && user.user.usermap[0].regionname,
      requestId: props.location.state.data.requestId ? props.location.state.data.requestId : props.location.state.data.id,
      role: user.user.roles,
      user_id: user.user.id,
      estimation_remarks: estimation_remarks,
    };
    console.log(dataRecord, centerData, error, temp, "dataRecorddataRecord");
    await axios.post(APIURL() + "onboard/save-commercials-draft", dataRecord).then(async (result) => {
      console.log(result, 'save-commercials-draft')
      await setBackDropOpen(false);
      if (result.data && result.data.message) {
        await setError({ ...error, 'emailId': result.data.message })
        await setConfirmDialog({
          isOpen: true,
          title: "Alert",
          subtitle: result.data.message,
        });
        return;
      }
      props.history.push("/bh-dashboard");
    })
    // return;
  }

  const handleOnboarding = async (event) => {
    let temp = {};
    var phoneno = /^[1-9]\d{9}$/;
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var pan = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    if (record.emailId != "" && record.emailId != null) {
      temp.emailId = record.emailId != "" ? "" : "This is required field.";
      if (record.emailId) {
        temp.emailId = record.emailId.includes('@cloudninecare') ? "Email should be doctor's personal email" : re.test(record.emailId) ? "" : "Invalid email Id.";
      }
    } else {
      temp.emailId = "This is required field.";
    }

    if (record.fullName == "" || record.fullName == null) {
      temp.fullName = "This is required field.";
    } else {
      temp.fullName = "";
    }

    if (record.McrcName == "" || record.McrcName == null) {
      temp.McrcName = "This is required field.";
    } else {
      temp.McrcName = "";
    }

    // if (term == "" || term == null) {
    //   temp.term = "This is required field.";
    // } else {
    //   temp.term = "";
    // }

    if (noticePeriod == "" || noticePeriod == null) {
      temp.noticeperiod = "This is required field.";
    } else {
      temp.noticeperiod = "";
    }

    if (lockinPeriod == "" || lockinPeriod == null) {
      temp.lockinperiod = "This is required field.";
    } else {
      temp.lockinperiod = "";
    }

    temp.contactNumber =
      record.contactNumber != "" && record.contactNumber != null ? "" : "This is required field.";
    if (record.contactNumber) {
      temp.contactNumber = phoneno.test(record.contactNumber)
        ? ""
        : "Invalid phone number.";
    }
    temp.agreementTemplate = agreementTemplate != "" && agreementTemplate != null ? "" : "This is required field.";
    temp.primaryCenter = primaryCenter != "" && primaryCenter != null ? "" : "This is required field.";
    temp.annexure = agreementAnnexure && error.annexure ? error.annexure : ""

    // temp.PanCard = record.PanCard != "" ? "" : "This is required field.";

    // if (record.PanCard != "") {
    //   temp.PanCard = record.PanCard != "" ? "" : "This is required field.";
    //   if (record.PanCard) {
    //     temp.PanCard = pan.test(record.PanCard) ? "" : "Invalid PAN Number.";
    //   }
    // }
    console.log(record, "errors_in_table")
    await setError({ ...temp });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (!Object.values(temp).every((x) => x === "")) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please fill all the required details first",
      });
      return;
    }
    console.log(record, recordfullname, props.location.state.data, "#############3");
    record.Center = props.location.state.data.centerNames;
    record.cat = props.location.state.data.category;
    console.log(ipItems, "ipItems");
    record.centerlist = centerData;
    record.noticeperiod = noticePeriod;
    record.term = term;
    record.lockinperiod = lockinPeriod;
    record.agreementTemplate = agreementTemplate;
    record.primaryCenter = primaryCenter;
    record.agreementAnnexure = agreementAnnexure;
    // record.contractterm = contractTerm.terminmonths;
    record.contractterm = term;
    if (recordfullname.selectedName != "") {
      record.fullName = recordfullname.selectedName;
    }
    console.log(record, "@@@@@@@");
    const dataRecord = {
      // obj:obj,
      allcenter: centerData,
      record: record,
      category: categoryTable,
      opItems: opItems,
      opItemsCommercial: opItemsCommercial,
      ipItems: ipItems,
      ipItemsCommercial: ipItemsCommercial,
      region: user.user.usermap && user.user.usermap[0].regionname,
      requestId: props.location.state.data.requestId ? props.location.state.data.requestId : props.location.state.data.id,
      role: user.user.roles,
      user_id: user.user.id,
      estimation_remarks: estimation_remarks,
    };
    console.log(dataRecord, centerData, error, temp, "dataRecorddataRecord");
    // return;
    if (props.location.state.data.category !== 'Visiting Consultant') {
      if (categoryTable.length <= 0) {
        setConfirmDialog({
          isOpen: true,
          title: "Alert",
          subtitle: props.location.state.data.category === 'Minimum Guarentee(MG)' ? "Please Enter Fixed MG" : "Please Enter Fixed Fee",
        });
        return;
      }
    }
    if (dataRecord.record.doctorId && (dataRecord.record.doctorId != 'undefined' || dataRecord.record.doctorId != '' || dataRecord.record.doctorId != null)) {
      console.log(error, 'I am here to update onboard')
      if (Object.keys(temp).length) {
        const isEmpty = Object.values(temp).every((x) => x === "");
        console.log(isEmpty, "rcc");
        if (isEmpty === true) {
          setBackDropOpen(true);
          var url = "onboard/renew"
          if (props.location.state.data.OcurrentAction === 'RD_REVISION') {
            url = "onboard/update-onboard"
          }
          axios
            .post(APIURL() + url, dataRecord)
            .then((res) => {
              setBackDropOpen(false);
              console.log(res.data, "email-id exists or not");
              if (res.data.message) {
                // return alert(res.data.message);
                setConfirmDialog({
                  isOpen: true,
                  title: "Alert",
                  subtitle: res.data.message,
                });
                return;
              }
              if (res.data != null) {
                props.history.push("/bh-dashboard");
                //   const obj = {
                //     doctorId: dataRecord.record.doctorId,
                //     centerNames: centerData,
                //     record: record
                //   };
                //   axios
                //     .put(
                //       APIURL() +
                //       "vacancies/centerupdate/" +
                //       props.location.state.data.id,
                //       obj
                //     )
                //     .then((res) => {
                //       props.history.push("/bh-dashboard");
                //     });
              }
            }).catch((err) => {
              console.log(err, 'error response from Create Onboard')
              setBackDropOpen(false);
              setConfirmDialog({
                isOpen: true,
                title: "Alert",
                subtitle: err.message,
              });
            });
        }
      }
    }
    else {
      if (Object.keys(temp).length) {
        const isEmpty = Object.values(temp).every((x) => x === "");
        console.log(isEmpty, "rcc");
        if (isEmpty === true) {
          console.log('I am here to create onboard')
          // return;
          setBackDropOpen(true);
          axios
            .post(APIURL() + "onboard/createOnboard", dataRecord)
            .then((res) => {
              console.log(res, 'response from Create Onboard')
              setBackDropOpen(false);
              console.log(res.data, "email-id exists or not");
              if (res.data.message) {
                // return alert(res.data.message);
                setConfirmDialog({
                  isOpen: true,
                  title: "Alert",
                  subtitle: res.data.message,
                });
                return;
              }
              if (res.data != null) {
                props.history.push("/bh-dashboard");
                // const obj = {
                //   doctorId: res.data.doctorData.id,
                //   centerNames: centerData,
                //   record: record
                // };
                // axios
                //   .put(
                //     APIURL() +
                //     "vacancies/centerupdate/" +
                //     props.location.state.data.id,
                //     obj
                //   )
                //   .then((res) => {
                //     props.history.push("/bh-dashboard");
                //   });
              }
            }).catch((err) => {
              console.log(err, 'error response from Create Onboard')
              setBackDropOpen(false);
              setConfirmDialog({
                isOpen: true,
                title: "Alert",
                subtitle: err.message,
              });
            });
        }
      }
    }
  };

  const getDoctorDetails = async (docId) => {
    await axios
      .get(APIURL() + "onboard/agreementView/" + docId)
      .then(async (res) => {
        if (res.data != null) {
          console.log(res.data.data, "category-view");
          // setCategory(res.data.data.vacancyData[0].category);
          await setDoctorData(res.data.data);
          if (res.data.data.doctorCommercial && res.data.data.doctorCommercial[0]) {
            setEstimationRemarks(res.data.data.doctorCommercial[0].estimation_remarks)
          }
          setRecord({
            emailId: props.location.state.data.AdditionalEmailId ? props.location.state.data.AdditionalEmailId : props.location.state.data.email,
            fullName: props.location.state.data.name ? props.location.state.data.name : props.location.state.data.doctorName,
            McrcName: props.location.state.data.McrcName,
            contactNumber: props.location.state.data.mobile,
            Center: props.location.state.data.businessunit ? props.location.state.data.businessunit : props.location.state.data.centerNames,
            noticeperiod: props.location.state.data.noticeperiod,
            lockinperiod: props.location.state.data.lockinperiod,
            term: props.location.state.data.contractterm,
            doctorId: props.location.state.data.doctorId
          })
          setTerm(props.location.state.data.contractterm);
          setNoticePeriod(props.location.state.data.noticeperiod);
          setLockinPeriod(props.location.state.data.lockinperiod)
        }
      });
  }

  console.log(error, "errorrs")
  return (
    <>
      <CssBaseline />

      <div style={{ marginLeft: "250px" }}>
        <div>
          <Grid container>
            <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
              <Sidebar />
              <Navbar />
              <Container style={{ marginTop: "20px" }}>
                <Paper elevation={24} style={{ padding: "10px" }}>
                  <PersonalDetail
                    error={error}
                    setError={setError}
                    onchange={(e) => {
                      onchange(e);
                    }}
                    onchangefulname={(e) => {
                      onchangefulname(e);
                    }}
                    setCenterData={setCenterData}
                    centerData={centerData}
                    centerList={centerList}
                    term={term}
                    setTerm={setTerm}
                    noticePeriod={noticePeriod}
                    setNoticePeriod={setNoticePeriod}
                    lockinPeriod={lockinPeriod}
                    setLockinPeriod={setLockinPeriod}
                    setAgreementTemplate={setAgreementTemplate}
                    setPrimaryCenter={setPrimaryCenter}
                    setAgreementAnnexure={setAgreementAnnexure}
                    agreementTemplate={agreementTemplate}
                    primaryCenter={primaryCenter}
                    agreementAnnexure={agreementAnnexure}
                    contractTerm={contractTerm}
                    setContractTerm={setContractTerm}
                    doctorData={doctorData}
                    data={props.location.state.data.doctorId ? record : props.location.state.data}
                  />
                  <Divider sytle={{ marginTop: "20px" }} />
                  <TabComponent
                    centerData={centerData}
                    centerList={centerList}
                    doctorData={doctorData}
                    data={props.location.state.data}
                    categorychk={props.location.state.data.category}
                    onchnagecategory={onchnagecategory}
                    onchnagecategoryTable={onchnagecategoryTable}
                    category={category}
                    onchangeOpTable={onchangeOpTable}
                    onchangeOpTableCommercial={onchangeOpTableCommercial}
                    onchangeIpTable={onchangeIpTable}
                    onchangeIpTableCommercial={onchangeIpTableCommercial}
                  />
                  <Divider sytle={{ marginTop: "20px" }} />
                  <TextareaAutosize
                    id="outlined-basic"
                    label="Remarks"
                    placeholder="Remarks"
                    // required
                    variant="outlined"
                    fullWidth
                    name="estimation_remarks"
                    value={estimation_remarks}
                    onChange={onChangeEstimateRemarks}
                    style={{ marginBottom: "20px", marginTop: "25px", width: "100%", border: "2px solid grey", minHeight: 50, }}
                    autoComplete="off"
                  />
                  {console.log(props.location.state.data.category, "LDJKDLDJKDLSJDJ")}
                  {props.location.state.data.category !==
                    "Full Time Consultant" ? (
                    <>
                      {" "}
                      {props.location.state.data.category == "Retainer" ?
                        (
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ margin: "20px 20px 20px 440px" }}
                            onClick={handleOnboarding}
                            disabled={categoryTable.length > 0 ? false : true}
                          >
                            Confirm
                          </Button>
                        )
                        // : opItems.length > 0 || ipItems.length > 0 ? (
                        //   <>
                        //     <Button
                        //       variant="contained"
                        //       color="secondary"
                        //       style={{ margin: "20px 20px 20px 440px" }}
                        //       onClick={handleOnboarding}
                        //     >
                        //       Confirm
                        //     </Button>
                        //   </>
                        // )
                        : (
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ margin: "20px 20px 20px 440px" }}
                            onClick={handleOnboarding}
                            disabled={categoryTable.length > 0 || opItems.length > 0 || ipItems.length ? false : true}
                          >
                            Confirm
                          </Button>
                        )}{" "}
                    </>
                  ) : (
                    <>
                      {/* {category && Object.values(category).length <= 0 ? (
                        <>
                          {" "}
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ margin: "20px 20px 20px 440px" }}
                            onClick={handleOnboarding}
                            disabled
                          >
                            Confirm
                          </Button>
                        </>
                      ) : (
                        <> */}
                      {" "}
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ margin: "20px 20px 20px 440px" }}
                        onClick={handleOnboarding}
                      >
                        Confirm
                      </Button>
                      {/* </>
                      )} */}
                    </>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => saveAsDraft()}
                  >
                    Save as Draft
                  </Button>
                </Paper>
              </Container>
            </Grid>
          </Grid>
        </div>
      </div>
      <Backdrop
        className={backClasses.backdrop}
        open={backDropOpen}
      // onClick={handleClose}
      >
        {/* <CircularProgress color="inherit" /> */}
        <img src={c9gif} alt="loading..." />
      </Backdrop>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
