import React, { useEffect, useState } from "react";
//import axios
import axios from "axios";
//ui component
import ChartComponent1 from "../../Components/ChartComponent";
//mui component
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import { APIURL } from "../../CommomMethods/Fetch";

export default function BhDashboard() {
  const [dashBoardCount, setDashBoardCount] = useState([]);
  const [centerwiseData, setCenterwiseData] = useState([]);

  useEffect(() => {
    // axios.get(APIURL()+"vacancies/centerwise").then((res) => {
      axios.get( APIURL() + "vacancies/centerwise").then((res) => {
      setCenterwiseData(res.data);
    })
    .catch((err)=>{
      toast.error(err.message);
    })
    axios.get(APIURL() + "vacancies/allvacancy").then((res) => {
      setDashBoardCount(res.data);
    })
    .catch((err)=>{
      toast.error(err.message);
    })
  }, []);
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
          <ChartComponent1 datalist={dashBoardCount} />
        </Grid>
        <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
        </Grid>
      </Grid>
      {/* <div>
    <ChartComponent1 datalist={dashBoardCount} />
    </div>
     
      <br />
      <BarChart datalist={centerwiseData} /> */}
    </>
  );
}
