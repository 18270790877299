import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import SidebarRD from "../../Components/Rd-sidebar";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import PayoutSummaryCard from "../../Components/PayoutSummaryCard";

export default function CenterWisePayout() {
  const [rows, setRows] = useState([]);
  const [reload, setReload] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  console.log(user, "****(");

  useEffect(() => {
    axios
      .get(APIURL() + "billings/Centerwise")
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows, "AllExcelData");
        setRows(rows.data);
      });
  }, [reload]);
  console.log(rows, "123");
  return (
    <>
      <SidebarRD />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <div
          style={{
            margin: ".4rem",
            textAlign: "center",
            padding: "7px",
            backgroundColor: "#810e36",
            color: "white",
          }}
        >
          Center wise Payout
        </div>

        <Grid container spacing={1} style={{ marginBottom: "40px" }}>
          {rows &&
            rows.map((opt, index) => (
              <Grid item xs={4} sm={4} lg={4} md={4} xl={4}>
                <PayoutSummaryCard data={opt} detail="rdcenter" />
              </Grid>
            ))}
        </Grid>
      </Container>
      {/* <Card data={opt} */}
    </>
  );
}
