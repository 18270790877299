import React from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";

const stylesmodal = makeStyles({
  //   root: {
  //     marginBottom: "10px",
  //   },
  title: {
    color: "#61b15a",
    fontFamily: "Roboto",
    fontVariant: "all-petite-caps",
    wordSpacing: "2px",
    letterSpacing: "1px",
  },
});

export default function EditProfileDetail({ profileData }) {
  const classes = stylesmodal();
  return (
    <>
      <Grid container spacing={2} style={{ padding: "10px" }}>
        <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
          <Typography
            color="textSecondary"
            variant="h6"
            gutterBottom
            className={classes.title}
          >
            Email : {profileData.email}
          </Typography>
          <Typography
            color="textSecondary"
            variant="h6"
            gutterBottom
            className={classes.title}
          >
            Mobile : {profileData.mobile}
          </Typography>
          <Typography
            color="textSecondary"
            variant="h6"
            gutterBottom
            className={classes.title}
          >
            Address : {profileData.Address}
          </Typography>
          <Typography
            color="textSecondary"
            variant="h6"
            gutterBottom
            className={classes.title}
          >
            City : {profileData.City}
          </Typography>
          <div>
            {/* <FormControl component="fieldset">
              <FormLabel
                component="legend"
                style={{
                  color: "black",
                  fontSize: "13px",
                  marginBottom: "20px",
                }}
              ></FormLabel>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  // value="top"
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleChangePermanentAdd}
                    />
                  }
                  label="Same as Present Address?"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>{" "} */}
          </div>
          <Typography
            color="textSecondary"
            variant="h6"
            gutterBottom
            className={classes.title}
          >
            Permanent Address : {profileData.PermanentAddress}
          </Typography>
          <Typography
            color="textSecondary"
            variant="h6"
            gutterBottom
            className={classes.title}
          >
            Permanent City : {profileData.PermanentCity}
          </Typography>
          <Typography
            color="textSecondary"
            variant="h6"
            gutterBottom
            className={classes.title}
          >
            About doctor : {profileData.aboutdoctor}
          </Typography>

          {/* <FormControl component="fieldset">
            <FormLabel
              component="legend"
              style={{ color: "black", fontSize: "13px" }}
            ></FormLabel>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    color="primary"
                    checked={maritalStat}
                    onChange={handleChangemaritalStat}
                  />
                }
                label="Marital Status(Yes)?"
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>{" "} */}
        </Grid>
        <Grid item xs={12} md={12} lg={6} sm={6} xl={6}>
          {/* <Typography
            color="textSecondary"
            variant="h6"
            gutterBottom
            className={classes.title}
          >
            {profileData.email}
          </Typography> */}
          <Typography
            color="textSecondary"
            variant="h6"
            gutterBottom
            className={classes.title}
          >
            Father Name : {profileData.fathername}
          </Typography>
          <Typography
            color="textSecondary"
            variant="h6"
            gutterBottom
            className={classes.title}
          >
            State : {profileData.State}
          </Typography>
          <Typography
            color="textSecondary"
            variant="h6"
            gutterBottom
            className={classes.title}
          >
            Pincode : {profileData.PinCode}
          </Typography>

          <div className="form-group">
            {/* <FormControl component="fieldset">
              <FormLabel component="legend">Gender</FormLabel>
              <RadioGroup
                row
                aria-label="Gender"
                name="Gender"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="M"
                  control={<Radio color="#fd7e14" />}
                  label="Male"
                  checked={value == "M" ? true : false}
                />
                <FormControlLabel
                  value="F"
                  control={<Radio color="#00963f" />}
                  label="Female"
                  checked={value == "F" ? true : false}
                />
                <FormControlLabel
                  value="O"
                  control={<Radio color="#00963f" />}
                  label="Other"
                  checked={value == "O" ? true : false}
                />
              </RadioGroup>
            </FormControl> */}
          </div>
          <Typography
            color="textSecondary"
            variant="h6"
            gutterBottom
            className={classes.title}
          >
            Permanent State : {profileData.PermanentState}
          </Typography>
          <Typography
            color="textSecondary"
            variant="h6"
            gutterBottom
            className={classes.title}
          >
            Permanent Pincode : {profileData.PermanentPinCode}
          </Typography>

          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-dialog"
              autoOk
              label="Date of Birth"
              // disablePast
              id="birthday"
              value={birthdaydate}
              className={styleClasses.root}
              onChange={handleChangeBirthday}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              fullWidth
            />
          </MuiPickersUtilsProvider> */}
        </Grid>
      </Grid>
    </>
  );
}
