import React from "react";
import { useDispatch, useSelector } from "react-redux";
//ui components
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import ManagePayableNonPayable from "./PayableNonPayableMaster/ManagePayableNonPayable";
import ManageIpPayableNonPayable from "./IpPayableNonPayableMaster/ManageIpPayableNonPayable";
//mui components
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

export default function PayableItem() {
  const [value, setValue] = React.useState("1");
  const handleChangetab = (event, newValue) => {
    setValue(newValue);
  };

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  console.log(user, "user data frontend111");
  return (
    <>
      <Sidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
        <TabContext value={value}>
          <AppBar
            position="static"
            style={{ marginTop: "10px", backgroundColor: "#810e36" }}
          >
            <TabList
              onChange={handleChangetab}
              aria-label="simple tabs example"
            >
              <Tab label="Op Payable" value="1" />
              <Tab label="Ip Payable" value="2" />
            </TabList>
          </AppBar>
          <TabPanel value="1">
            <ManagePayableNonPayable user={user} />
          </TabPanel>
          <TabPanel value="2">
            <ManageIpPayableNonPayable user={user} />
          </TabPanel>
        </TabContext>
      </Container>
    </>
  );
}
