import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import axios from "axios";
import { Card, Table, Toast } from "react-bootstrap";
import { agreementState } from "../../CommomMethods/States";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Navmain from "../../Components/Navbar";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
import { listusers } from "../../actions/userActions";
import { agreementlist } from "../../actions/agreementActions";
import { Paper } from "@material-ui/core";
import { APIURL } from "../../CommomMethods/Fetch";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { numberWithCommas } from "../../actions/createPositionActions";
const { onboardState } = require("../../CommomMethods/States");

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

export default function CalculationBH() {
  const tables = myTableStyle();
  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  const [onboardData, setOnboardData] = useState([]);
  const [openr, setOpenr] = React.useState(false);
  const [openOp, setOpenOp] = React.useState(false);
  const [openIp, setOpenIp] = React.useState(false);
  const [openCommercials, setOpenCommercials] = React.useState(false);

  const [docid, setDocid] = useState("");
  const [doctorDetail, setDoctorDetail] = useState([]);
  const [doctorOpDetail, setDoctorOpDetail] = useState([]);

  const [doctorIpDetail, setDoctorIpDetail] = useState([]);
  const [doctorOpCommercial, setDoctorOpCommercial] = useState([]);
  const [doctorIpCommercial, setDoctorIpCommercial] = useState([]);
  const [remark, setRemark] = useState("");

  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  let userid = users[0] && users[0].id;

  const agreementList = useSelector((state) => state.agreementList);
  const { agreements } = agreementList;

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  console.log(user.user.usermap, "get user data after ch login");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(agreementlist());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/currentOnboardStatusbyCh", {
        params: { obj: user.user.usermap },
      })
      .then((res) => {
        console.log(res.data.data, "res data");
        setOnboardData(res.data.data);
      })
      .catch((err)=>{
        toast.error(err.message);
      });
  }, []);

  function getDocdetail(id) {
    axios
      .get(APIURL() + "onboard/previewOne/" + id)
      .then((res) => {
        setDoctorDetail(res.data);
        setDoctorIpDetail(res.data.doctorIPParameter);
        setDoctorOpDetail(res.data.doctorOPParameter);
        setDoctorOpCommercial(res.data.doctorOpCommercial);
        setDoctorIpCommercial(res.data.doctorIpCommercial);
      })
      .catch((err)=>{
        toast.error(err.message);
      });
  }

  useEffect(() => {
    getDocdetail(docid);
  }, [docid]);

  const handlerClickOpen = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenr(true);
  };
  const handlerClose = () => {
    setOpenr(false);
  };
  const handlerClickOpenOp = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenOp(true);
  };
  const handlerCloseOp = () => {
    setOpenOp(false);
  };

  const handlerClickOpenIp = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenIp(true);
  };

  const handlerClickOpenCommercials = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }
    setOpenCommercials(true);
  };

  const handlerClickcloseCommercials = () => {
    setOpenCommercials(false);
  };

  const handlerCloseIp = () => {
    setOpenIp(false);
  };

  const handleApprove = (ele) => {
    // alert("The Agreement is APPROVED")
    const obj = {
      doctorId: ele.doctorId,
      name: ele.name,
      currentAction: onboardState.ch.state,
      currentActionBy: users[0].id,
      nextAction: onboardState.rd.state,
      status: 1,
    };
    axios.post(APIURL() + "onboard/action/", obj).then((res) => {
      window.location.reload();
      // toast.success("Successfully Confirmed");
    })
    .catch((err)=>{
      toast.error(err.message);
    });
  };
  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  var n;
  function inWords(num) {
    if ((num = num.toString()).length > 9) return "overflow";
    n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
          (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
          "only "
        : "";
    return str;
  }

  var amount = parseInt(
    doctorDetail.doctorCommercial &&
      doctorDetail.doctorCommercial[0] &&
      doctorDetail.doctorCommercial[0].ctcmonth
  );

  var addamount = parseInt(
    doctorDetail.doctorCommercial &&
      doctorDetail.doctorCommercial[0] &&
      doctorDetail.doctorCommercial[0].additionalfee
  );

  const Remark = (e) => {
    setRemark(e.target.value);
  };
  const updateRecord = () => {
    doctorOpDetail.forEach((element) => {
      element.status = 0;
    });
    doctorIpDetail.forEach((element) => {
      element.status = 0;
    });
    doctorOpCommercial.forEach((element) => {
      element.status = 0;
    });
    doctorIpCommercial.forEach((element) => {
      element.status = 0;
    });
    const obj = {
      doctorOpDetail,
      doctorIpDetail,
      doctorOpCommercial,
      doctorIpCommercial,
    };
    axios
      .put(
        APIURL() + "onboard/updateFourtables/" + doctorOpDetail[0].doctorId,
        obj
      )
      .then((res) => {})
      .catch((err)=>{
        toast.error(err.message);
      });
    const obj1 = {
      doctorId: doctorDetail.doctorDetails.id,
      name: doctorDetail.doctorDetails.name,
      currentAction: onboardState.ch.state,
      currentActionBy: 1,
      nextAction: onboardState.rd.state,
      remark: remark,
      status: 1,
    };
    axios.post(APIURL() + "onboard/action/", obj1).then((res) => {
      // toast.success("Rectification successfully submitted");
    })
    .catch((err)=>{
      toast.error(err.message);
    });
    window.location.reload();
  };
  return (
    <>
      <Sidebar />

      <div className="main-content side-content pt-0">
        <Navmain />
        <Card.Body
          // style={bodyStyle}
          style={{ width: "100%", marginTop: "4rem" }}
        >
          <MaterialTable
            columns={[
              { title: "Doctor Name", field: "name" },
              {
                title: "Estimation Details",
                field: "Action",
                filtering: false,
                render: (row) => (
                  <div style={{ width: "140px" }}>
                    <i
                      className="fas fa-address-card"
                      style={{ fontSize: "20px", color: "#810e36" }}
                      onClick={() => handlerClickOpenOp(row)}
                    />
                  </div>
                ),
              },
              {
                title: "View Remark",
                field: "remark",
                render: (row) => (!row.remark ? "No Remark" : row.remark),
              },

              {
                title: "Current Action",
                field: "currentAction",
                filtering: false,
                render: (row) => (
                  <div style={{ width: "140px" }}>
                    {row.currentAction === onboardState.init.state ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        {onboardState.init.message}
                      </span>
                    ) : row.currentAction === onboardState.upload.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.upload.message}
                      </span>
                    ) : row.currentAction === onboardState.hr.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.hr.message}
                      </span>
                    ) : row.currentAction === onboardState.ch.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.ch.message}
                      </span>
                    ) : row.currentAction === onboardState.rd.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.rd.message}
                      </span>
                    ) : row.currentAction === onboardState.done.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.done.message}
                      </span>
                    ) : row.currentAction === onboardState.ch_doc.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.ch_doc.message}
                      </span>
                    ) : row.currentAction === onboardState.completed ? (
                      <span style={{ color: "blue" }}>"COMPLETED"</span>
                    ) : row.currentAction === onboardState.ch_renew.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.ch_renew.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                ),
              },
              {
                title: "Next Action",
                field: "nextAction",
                filtering: false,
                render: (row) => (
                  <div style={{ width: "140px" }}>
                    {row.nextAction === onboardState.init.state ? (
                      <span style={{ color: "red" }}>
                        {" "}
                        {onboardState.init.message}
                      </span>
                    ) : row.nextAction === onboardState.upload.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.upload.message}
                      </span>
                    ) : row.nextAction === onboardState.hr.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.hr.message}
                      </span>
                    ) : row.nextAction === onboardState.ch.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.ch.message}
                      </span>
                    ) : row.nextAction === onboardState.rd.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.rd.message}
                      </span>
                    ) : row.nextAction === onboardState.done.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.done.message}
                      </span>
                    ) : row.nextAction === onboardState.ch_doc.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.ch_doc.message}
                      </span>
                    ) : row.nextAction === onboardState.completed ? (
                      <span style={{ color: "blue" }}>"COMPLETED"</span>
                    ) : row.nextAction === onboardState.ch_renew.state ? (
                      <span style={{ color: "blue" }}>
                        {onboardState.ch_renew.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                ),
              },
              // {
              //   title: "Action",
              //   field: "Action",
              //   filtering: false,
              //   render: (row) =>
              //     row.nextAction === onboardState.ch.state ? (
              //       // && row.currentAction !== onboardState.hr.state
              //       <Button
              //         size="small"
              //         color="primary"
              //         variant="contained"
              //         onClick={() => {
              //           handleApprove(row);
              //         }}
              //       >
              //         Approve
              //       </Button>
              //     ) : (
              //       ""
              //     ),
              // },
            ]}
            data={onboardData}
            // data={onboardData &&
            //   onboardData.data &&
            //   onboardData.data
            //     .filter((z) => z.nextAction === onboardState.ch.state)}
            options={{
              // filtering: true,
              sorting: true,
              exportButton: true,
              pageSize: 5,
              pageSizeOptions: [5, 10, 50, 100, 200, 500],
              search: true,
            }}
            title="Details"
          />
        </Card.Body>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Doctor Detail
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom>
            Name:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].fullname}
          </Typography>
          <Typography gutterBottom>
            Email-Id:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].email}
          </Typography>
          <Typography gutterBottom>
            Mobile:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].mobile}
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerCloseOp}
        aria-labelledby="customized-dialog-title"
        open={openOp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseOp}
          style={{ textAlign: "center" }}
        >
          <h5 style={{ margin: ".4rem", textAlign: "center" }}>
            <b>Doctor Details</b>
          </h5>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom>
            <b>Name: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].fullname}
          </Typography>
          <Typography gutterBottom>
            <b> Email-Id: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].email}
          </Typography>
          <Typography gutterBottom>
            <b> Mobile: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].mobile}
          </Typography> */}
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                {
                  title: "Item Group",
                  field: "itemGroup",
                },
                { title: "Revenue", field: "oprevenue",render: (row) => (
                  <div>
                    {row.oprevenue
                      ? "Rs. " + numberWithCommas(row.oprevenue)
                      : "---"}
                  </div>
                ) },
                { title: "Pay Out", field: "oppayout",render: (row) => (
                  <div>
                    {row.oppayout
                      ? "Rs. " + numberWithCommas(row.oppayout)
                      : "---"}
                  </div>
                ) },
                // { title: "Net Amount", field: "netamount" },
              ]}
              // data={doctorDetail.doctorOPParameter} setDoctorOpDetail
              data={doctorOpDetail}
              // editable={{
              //   onRowUpdate: (newData, oldData) =>
              //     new Promise((resolve, reject) => {
              //       newData.chstatus = 1;
              //       setTimeout(() => {
              //         const dataUpdate = [...doctorOpDetail];
              //         const index = oldData.tableData.id;
              //         dataUpdate[index] = newData;
              //         setDoctorOpDetail([...dataUpdate]);

              //         resolve();
              //       }, 1000);
              //     }),
              // }}
              options={{
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.status === true ? "orangered" : "#FFF",
                }),
              }}
              title="OP Details"
            />
            <br />
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Room Type", field: "iproom" },
                { title: "Revenue", field: "revenue",render: (row) => (
                  <div>
                    {row.revenue
                      ? "Rs. " + numberWithCommas(row.revenue)
                      : "---"}
                  </div>
                )},
                { title: "Pay Out", field: "payout",  render: (row) => (
                  <div>
                    {row.payout
                      ? "Rs. " + numberWithCommas(row.payout)
                      : "---"}
                  </div>
                )},
                // { title: "Net Amount", field: "ipTotalAmount" },
              ]}
              // data={doctorDetail.doctorIPParameter}
              data={doctorIpDetail}
              // editable={{
              //   onRowUpdate: (newData, oldData) =>
              //     new Promise((resolve, reject) => {
              //       newData.chstatus = 1;
              //       setTimeout(() => {
              //         const dataUpdate = [...doctorIpDetail];
              //         const index = oldData.tableData.id;
              //         dataUpdate[index] = newData;
              //         setDoctorIpDetail([...dataUpdate]);
              //         resolve();
              //       }, 1000);
              //     }),
              // }}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.status === true ? "orangered" : "#FFF",
                }),
              }}
              title="IP Details"
            />
            <br />
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemgroup" },

                { title: "Commercial Term", field: "commercialterm" },
              ]}
              // data={doctorDetail.doctorIPParameter}
              data={doctorOpCommercial}
              // editable={{
              //   onRowUpdate: (newData, oldData) =>
              //     new Promise((resolve, reject) => {
              //       newData.chstatus = 1;
              //       setTimeout(() => {
              //         const dataUpdate = [...doctorOpCommercial];
              //         const index = oldData.tableData.id;
              //         dataUpdate[index] = newData;
              //         setDoctorOpCommercial([...dataUpdate]);

              //         resolve();
              //       }, 1000);
              //     }),
              // }}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.status === true ? "orangered" : "#FFF",
                }),
              }}
              title="OP Commercials"
            />
            <br />
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemgroup" },
                { title: "Room Category", field: "iproom" },
                { title: "MOP", field: "ipmodeofpayment" },

                { title: "Commercial Term", field: "commercialterm" },
              ]}
              // data={doctorDetail.doctorIPParameter}
              data={doctorIpCommercial}
              // editable={{
              //   onRowUpdate: (newData, oldData) =>
              //     new Promise((resolve, reject) => {
              //       newData.chstatus = 1;
              //       setTimeout(() => {
              //         const dataUpdate = [...doctorIpCommercial];
              //         const index = oldData.tableData.id;
              //         dataUpdate[index] = newData;
              //         setDoctorIpCommercial([...dataUpdate]);

              //         resolve();
              //       }, 1000);
              //     }),
              // }}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.status === true ? "orangered" : "#FFF",
                }),
              }}
              title="IP Commercials"
            />
          </Paper>
          <br />
          <label>
            Remark{" "}
            <i
              class="fa fa-asterisk"
              style={{
                color: "red",
                fontSize: "10px",
                marginLeft: "3px",
                marginTop: "15px",
              }}
              aria-hidden="true"
            ></i>
          </label>
          <textarea
            placeholder="Remark"
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={updateRecord}
            color="primary"
            variant="contained"
            size="small"
          >
            Update Data
          </Button>
          <Button
            autoFocus
            onClick={handlerCloseOp}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerCloseIp}
        aria-labelledby="customized-dialog-title"
        open={openIp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseIp}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Revenue", field: "revenue" },
                { title: "Pay Out", field: "payout" },
                { title: "Net Amount", field: "ipTotalAmount" },
              ]}
              data={doctorDetail.doctorIPParameter}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Ip Details"
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerCloseIp}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClickcloseCommercials}
        aria-labelledby="customized-dialog-title"
        open={openCommercials}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group/Procedures", field: "itemGroup" },
                { title: "Commercial Term", field: "CommercialTerm" },
              ]}
              data={doctorDetail.doctorIPParameter}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Commercial Details"
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerClickcloseCommercials}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
