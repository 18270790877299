import axios from 'axios'
import { USER_CREATE_FAIL, USER_CREATE_REQUEST,
     USER_CREATE_SUCCESS, USER_LIST_FAIL, USER_LIST_REQUEST, 
     USER_LIST_SUCCESS ,USER_LIST_ALL_REQUEST,
     USER_LIST_ALL_SUCCESS,USER_LIST_ALL_FAIL} from '../constants/userConstants'
     import { APIURL } from "../CommomMethods/Fetch";
// create user
export const createUsers = () => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_CREATE_REQUEST })
        const {
            userLogin : {userInfo},
        }= getState()
        const config = {
            headers:{
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        const { data } = await axios.post(APIURL() +'/users/add', {}, config)
        dispatch({
            type: USER_CREATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

// user list
export const listusers = (id) => async (dispatch,getState) => {
    try {
        dispatch({ type: USER_LIST_REQUEST })
        const {
            userLogin : {user},
        }= getState()

        // const { data } = await axios.get(APIURL() +'users/2' )
        const { data } = await axios.get(APIURL() +"users/"+user.user.id )
        dispatch({
            type: USER_LIST_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}



export const userslistall = () => async (dispatch,getState) => {
    try {
        dispatch({ type: USER_LIST_ALL_REQUEST })
        const { data } = await axios.get(APIURL()+"users/" )
        dispatch({
            type: USER_LIST_ALL_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: USER_LIST_ALL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        })
    }
}

export const numbersManager = (number) => {
    let nf = new Intl.NumberFormat('en-IN');
    if (!isNaN(number)) {
        if(number > 100000){
            return nf.format(Math.abs(number / 100000).toFixed(0))
          }else{
            return nf.format(number.toFixed(0))
          }
    }
  }
