import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
// import { SnackbarContent } from "@material-ui/core";
import SnackbarContent from "@material-ui/core/SnackbarContent";
// axios
import axios from "axios";
//bootstrap
import { Container } from "react-bootstrap";
import { APIURL } from "../../../CommomMethods/Fetch";
//ui components
import AddCommercials from "./forms/AddCommercials";
import EditCommercials from "./forms/EditCommercials";
import ListCommercials from "./tables/ListCommercials";
import Navbar from "../../../Components/Navbar";
import AppSidebar from "../../../Components/PTSidebar";
import { createLocumDoctors } from "../../../Pages/Calculations/docLocum";


const styleSheet = makeStyles((theme) => ({
  iconButtonStyle: {
    float: "right",
  },
  marginforMaster: {
    marginTop: "30px",
  },
}));

export default function ManageCommercials() {
  const classes = styleSheet();
  const dispatch = useDispatch();
  //useStae for Snackbar
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    color: "",
    icon: "",
  });
  //variables for snack bar
  const { vertical, horizontal, open } = state;
  //function for snackbar
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const initialFormState = {
    id: null,
    doctorName: "",
    // Center: "",
    itemGroup: "",
    itemName: "",
    fixedamount: "",
    fixedpercentage: "",
    applicable: "",
    natureofdoc: "",
    status: "",
    chstatus: "",
    center: ""
  };
  const [department, setDepartment] = useState(initialFormState);
  const [preview, setPreview] = useState(true);
  const [editing, setEditing] = useState(false);
  const [rows, setRows] = useState([]);
  const [docid, setDocid] = useState();
  const [regionRows, setRegionRows] = useState([]);
  const [data, setData] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  console.log(user.user, "user from payout team login");
  // get all records from doctor
  useEffect(() => {
    axios
      .get(APIURL() + "opparameters/findOPParam")
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows, "ManageCommercials");
        var filterData = [];
        if (user.user.id == 401) {
          filterData = rows.filter((z) => z.region == "NORTH");
        }
        if (user.user.id == 400) {
          filterData = rows.filter((z) => z.region == "SOUTH");
        }
        if (user.user.id == 402) {
          filterData = rows.filter((z) => z.region == "WEST");
        }
        setRows(filterData);
      });
  }, [data]);

  //populate single record in the Edit component
  const editRow = (department) => {
    setPreview(false);
    setEditing(true);
    setDepartment({
      id: department.id,
      doctorName: department.doctorName,
      natureofdoc: department.natureofdoc,
      // region: department.region,
      itemGroup: department.itemGroup,
      itemName: department.itemName,
      fixedamount: department.fixedamount,
      fixedpercentage: department.fixedpercentage,
      applicable: department.applicable,
      status: department.status,
    });
  };

  //update department
  const updateDepartment = (id, department) => {
    setData(false);
    console.log(id, department, "doctor row data");
    axios
      .put(APIURL() + "opparameters/updateCommercial/" + id, department)
      .then((res) => {
        if (res.status === 200) {
          setEditing(false);
          setPreview(true);
          setData(true);

          setState({
            open: true,
            vertical: "top",
            horizontal: "center",
            message: res.data.message,
            color: "success",
          });
        }
      });
  };


  //add designation
  const addDepartment = (department) => {
    setData(false);
    console.log(department, "department");
    if (department.chstatus == "Yes") {
      const obj = {
        email: "",
        name: department.doctorName,
        mobile: 9988800009,
        users_role_id: 4,
        status: 9
      }
      axios.post(APIURL() + "users/", obj)
        .then((res) => {
          setDocid(res.data.data.id);
          department.doctorId = res.data.data.id;
          axios.post(APIURL() + "opparameters/createCommercial", department)
            .then((res) => {
              setPreview(true);
              setData(true);
              if (res.status === 200) {
                setState({
                  open: true,
                  vertical: "top",
                  horizontal: "center",
                  message: "Doctor Commercial added successfully.",
                  color: "success",
                });
              }
            });
        });
    }
    else if (department.chstatus == "No") {
      console.log(department.doctorId, "department.doctorId=");
      axios.post(APIURL() + "opparameters/createCommercial", department)
        .then((res) => {
          setPreview(true);
          setData(true);
          if (res.status === 200) {
            setState({
              open: true,
              vertical: "top",
              horizontal: "center",
              message: "Doctor Commercial added successfully.",
              color: "success",
            });
          }
        });
    }
  };

  //deletion of designation
  const deleteDepartment = (row) => {
    setData(false);
    var k = window.confirm("Are you sure want to delete this record ?");
    if (k) {
      axios.delete(APIURL() + "opparameters/deleteCommercials/" + row.id).then((res) => {
        console.log(res, "DELETE");
        setData(true);
        if (res.status === 200) {
          setState({
            open: true,
            vertical: "top",
            horizontal: "center",
            message: res.data.message,
            color: "danger",
          });
        }
      });
    }
  };

  return (
    <>
      <AppSidebar />
      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                className={classes.marginforMaster}
              >
                <Typography variant="h6">Manage Commercials</Typography>
                {preview ? (
                  <Typography variant="body2">
                    Masters / Manage Commercials / Commercials List
                  </Typography>
                ) : editing ? (
                  <Typography variant="body2">
                    Masters / Manage Commercials / Edit Commercials
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    Masters / Manage Commercials / Add Commercials
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.marginforMaster}
              >
                <div className={classes.iconButtonStyle}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setPreview(true);
                      setEditing(false);
                    }}
                  >
                    <ListIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setPreview(false);
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {preview ? (
                  <ListCommercials
                    rows={rows}
                    editRow={editRow}
                    deleteDepartment={deleteDepartment}

                  />
                ) : editing ? (
                  <EditCommercials
                    editing={editing}
                    setEditing={setEditing}
                    setPreview={setPreview}
                    currentDepartment={department}
                    updateDepartment={updateDepartment}
                  />
                ) : (
                  <AddCommercials
                    addDepartment={addDepartment}
                    regionRows={regionRows}
                    currentDepartment={department}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={5000}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <SnackbarContent color={state.color} message={state.message} />
      </Snackbar>
    </>
  );
}
