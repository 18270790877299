import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from "react-redux";
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';
import { AppContext } from "../actions/contextApi";
import axios from "axios";
import { APIURL } from "../CommomMethods/Fetch";
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from "react-toastify";
function CustomToolbar() {
  return (
    <GridToolbarContainer >
      <GridToolbarFilterButton style={{color: "#e4308b"}} />
      <GridToolbarExport style={{color: "#e4308b"}} />
    </GridToolbarContainer>
  );
}
const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export default function CustomGraphTable() {
  const [loader, setLoader] = useState(false);
  const [GraphTableData, setGraphTableData] = useState([]);
  const [HeaderArray, setHeaderArray] = useState([]);
  const { centerNames, setCenterNames } = useContext(AppContext);
  const { Months, setMonths } = useContext(AppContext);
  const { Years, setYears } = useContext(AppContext);
  const { LOSName, setLOSName } = useContext(AppContext);
  const { IPOPName, setIPOPName } = useContext(AppContext);
  const { itemGroupNames, setitemGroupNames } = useContext(AppContext);
  const { DoctorNames, setDoctorNames } = useContext(AppContext);
  const { regionNames, setRegionNames } = useContext(AppContext);
  const { graphFilter, setGraphFilter } = useContext(AppContext);
  const {graphFilterByGroup, setGraphFilterByGroup} = useContext(AppContext)
  const {graphFilterByDept, setGraphFilterByDept} = useContext(AppContext)
  const {graphFilterByYear, setGraphFilterByYear} = useContext(AppContext)
  const {graphFilterByLegend, setGraphFilterByLegend} = useContext(AppContext);
  const {tableHeaderName, setTableHeaderName} = useContext(AppContext);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  // console.log(graphFilterByLegend, "abc----------->>>>>>>>123")
  useEffect(() => {
    setLoader(true)
    if (tableHeaderName === "Payout Table") {
      axios
        .post(APIURL() + "graph/get-payout-table", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, Months: Months, Years: Years, itemGroupNames: itemGroupNames, DoctorNames: DoctorNames, regionNames: regionNames, graphFilter: graphFilter, LOSName: LOSName, IPOPName: IPOPName})
        .then((res) => {
          if (res.data && res.data.data && res.data.data.length > 0) {
            let arr = res.data.data
            let headerArray = []
            var uniqueKeys = Object.keys(arr.reduce((result, obj) => {
              return Object.assign(result, obj);
            }, {}))
            uniqueKeys.map(elem => {
              headerArray.push({
                field: elem,
                headerName: elem.includes('_') ? elem.replaceAll('_', ' ') : elem
              })
            })
            setHeaderArray(headerArray);
            setGraphTableData(res.data.data);
          } else {
            setGraphTableData([]);
            setHeaderArray([])
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
    if (tableHeaderName === "Revenue Table") {
      axios
        .post(APIURL() + "graph/get-revenue-table", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, Months: Months, Years: Years, itemGroupNames: itemGroupNames, DoctorNames: DoctorNames, regionNames: regionNames, graphFilter: graphFilter, LOSName: LOSName, IPOPName: IPOPName })
        .then((res) => {
          if (res.data && res.data.data && res.data.data.length > 0) {
            let arr = res.data.data
            let headerArray = []
            var uniqueKeys = Object.keys(arr.reduce((result, obj) => {
              return Object.assign(result, obj);
            }, {}))
            uniqueKeys.map(elem => {
              headerArray.push({
                field: elem,
                headerName: elem.includes('_') ? elem.replaceAll('_', ' ') : elem
              })
            })
            setHeaderArray(headerArray);
            setGraphTableData(res.data.data);
          } else {
            setGraphTableData([]);
            setHeaderArray([])
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
    if (tableHeaderName == "Revenue-LOS Table") {
      console.log(graphFilterByGroup, graphFilterByYear, "asdfghjklpoiuytrew")
      axios
        .post(APIURL() + "graph/get-revenue-los-table", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, Months: Months, Years: Years, itemGroupNames: itemGroupNames, DoctorNames: DoctorNames, regionNames: regionNames, graphFilterByGroup: graphFilterByGroup, graphFilterByYear: graphFilterByYear, graphFilterByLegend: graphFilterByLegend, LOSName: LOSName, IPOPName: IPOPName })
        .then((res) => {
          if (res.data && res.data.data && res.data.data.length > 0) {
            let arr = res.data.data
            let headerArray = []
            var uniqueKeys = Object.keys(arr.reduce((result, obj) => {
              return Object.assign(result, obj);
            }, {}))
            uniqueKeys.map(elem => {
              headerArray.push({
                field: elem,
                headerName: elem.includes('_') ? elem.replaceAll('_', ' ') : elem
              })
            })
            setHeaderArray(headerArray);
            setGraphTableData(res.data.data);
          } else {
            setGraphTableData([]);
            setHeaderArray([])
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
    if (tableHeaderName == "Payout-LOS Table") {
      axios
        .post(APIURL() + "graph/get-payout-los-table", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, Months: Months, Years: Years, itemGroupNames: itemGroupNames, DoctorNames: DoctorNames, regionNames: regionNames, graphFilterByGroup: graphFilterByGroup, graphFilterByYear: graphFilterByYear, graphFilterByLegend: graphFilterByLegend, LOSName: LOSName, IPOPName: IPOPName})
        .then((res) => {
          if (res.data && res.data.data && res.data.data.length > 0) {
            let arr = res.data.data
            let headerArray = []
            var uniqueKeys = Object.keys(arr.reduce((result, obj) => {
              return Object.assign(result, obj);
            }, {}))
            uniqueKeys.map(elem => {
              headerArray.push({
                field: elem,
                headerName: elem.includes('_') ? elem.replaceAll('_', ' ') : elem
              })
            })
            setHeaderArray(headerArray);
            setGraphTableData(res.data.data);
          } else {
            setGraphTableData([]);
            setHeaderArray([])
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
    if (tableHeaderName === "Doctor Profile Table") {
      axios
        .post(APIURL() + "graph/get-doctor-by-dept-table", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, Months: Months, Years: Years, itemGroupNames: itemGroupNames, DoctorNames: DoctorNames, regionNames: regionNames, graphFilter: graphFilter, graphFilterByDept: graphFilterByDept, LOSName: LOSName, IPOPName: IPOPName})
        .then((res) => {
          if (res.data && res.data.data && res.data.data.length > 0) {
            let arr = res.data.data
            let headerArray = []
            var uniqueKeys = Object.keys(arr.reduce((result, obj) => {
              return Object.assign(result, obj);
            }, {}))
            uniqueKeys.map(elem => {
              headerArray.push({
                field: elem,
                headerName: elem.includes('_') ? elem.replaceAll('_', ' ') : elem
              })
            })
            setHeaderArray(headerArray);
            setGraphTableData(res.data.data);
          } else {
            setGraphTableData([]);
            setHeaderArray([])
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
    setLoader(false)
  }, [centerNames, Months, Years, itemGroupNames, DoctorNames, regionNames, graphFilter, graphFilterByGroup, graphFilterByYear, tableHeaderName, graphFilterByDept, LOSName, IPOPName])
  
  return (
    <Box sx={{ height: 450, width: '99%', display: 'flex' }}>
      {loader ?
        <CircularProgress />
        :
        <DataGrid
          // rows={rows}
          rows={GraphTableData}
          // columns={columns}
          columns={HeaderArray}
          pageSize={25}
          rowsPerPageOptions={[25, 50, 100]}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      }
    </Box>
  );
}