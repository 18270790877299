import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo2 from "../images/Logos-04.png";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { listusers } from "../actions/userActions";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

function HrSidebar({ userType }) {
  const [dash, setDash] = useState("bh-dashboard");
  const [item4, setItem4] = useState("visibleDiv");
  const [item5, setItem5] = useState("visibleDiv");
  const [item6, setItem6] = useState("visibleDiv");
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  // bringing things from the reducer
  const { loading, error, user } = userLogin;
  function CheckUser() {
    if (userType === "BH") {
      setDash("bh-dashboard");
    } else if (userType === "HR") {
      setDash("hr-dashboard");
      setItem5("hiddenDiv");
    } else if (userType === "COO") {
      setDash("rd-dashboard");
      setItem4("hiddenDiv");
      setItem6("hiddenDiv");
    }
    useEffect(() => {
      dispatch(listusers());
    }, [dispatch]);
  }

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <div class="sidebar main-sidebar main-sidebar-sticky side-menu ps ps--active-y">
      <div className="main-sidebar-header header-img">
        <Link to="/bh-dashboard">
          <img src={logo2} className="img-responsive" alt="logo" />
        </Link>
      </div>
      <div className="main-sidebar-body">
        <ul className="nav">
          {/* <li
            className={
              splitLocation[1] === "HRProfile" ? "active " + "nav-item" : "nav-item"
            }
          >
            <Link to="/HRProfile" id="nav-link" className="nav-link">
              <span className="shape1"></span>
              <span className="shape2"></span>
              <i className="ti-user sidemenu-icon mr-2"></i>
              <span className="sidemenu-label">Profile</span>
            </Link>
          </li> */}
          <li
            className={
              splitLocation[1] === "hr-dashboard"
                ? "active" + "nav-item"
                : "nav-item"
            }
          >
            <Link to="/hr-dashboard" id="nav-link"  className="new-nav-link"  style={{
                backgroundColor: "transparent",
                height: "2.6rem",
                border: "none",
                color:"white",
                fontSize:"16px"
                //marginTop: "1px",
              }}>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <i className="ti-home sidemenu-icon mr-2"></i>
              <span className="sidemenu-label"  style={{fontSize:"16px",marginLeft:"10px"}}>Dashboard</span>
            </Link>
          </li>

          <div
            style={{
              display: "block",
              width: "100%",
              marginTop: "-.2rem",
              marginLeft: "2px",
            }}
          >
            <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent" }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}
                  >
                    <i className="fas fa-angle-down sidemenu-icon mr-4"></i>
                    Onboard Actions
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                  className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/UploadedDocHR"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i class="fas fa-file-upload sidemenu-icon mr-2"></i>
                       Documents
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                  className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/hr-agreement"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                      Agreements
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          {/* <div
            style={{
              display: "block",
              width: "100%",
              // marginTop: ,
              marginLeft: "2px",

            }}
          >
            <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent" }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}

                  >
                    <i className="fas fa-list sidemenu-icon mr-4"></i>
                 Reports
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    style={{ backgroundColor: "transparent", height: "1.2rem", border: "none" }}
                  >
                    <Link
                      to="/DoctorListHR"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fas fa-list sidemenu-icon mr-2"></i>
                      Doctor List
                  </Link>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div> */}

          <div
            style={{
              display: "block",
              width: "100%",
              marginTop: "-.2rem",
              marginLeft: "2px",
            }}
          >
            <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent" }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}
                  >
                    <i className="fas fa-user-circle sidemenu-icon mr-4"></i>
                    My Account
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                  className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/HRProfile"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-user sidemenu-icon mr-2"></i>
                      Profile
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                  className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/ChangePassword"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fas fa-unlock sidemenu-icon mr-2"></i>
                      Change Password
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default HrSidebar;
