import {
    AGREEMENTS_FAIL,
    AGREEMENTS_REQUEST,
    AGREEMENTS_SUCCESS,
    AGREEMENTS_VIEW_REQUEST,
    AGREEMENTS_VIEW_SUCCESS,
    AGREEMENTS_VIEW_FAIL
  } from "../constants/agreementConstants";

export const agreementlistReducer = (state = { agreements: [] }, action) => {
    switch (action.type) {
        case AGREEMENTS_REQUEST:
            return { loading: true}
        case AGREEMENTS_SUCCESS:
            return { loading: false, agreements: action.payload}
        case AGREEMENTS_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state

    }
}

export const agreementviewReducer = (state = { agreementview: [] }, action) => {
    switch (action.type) {
        case AGREEMENTS_VIEW_REQUEST:
            return { loading: true}
        case AGREEMENTS_VIEW_SUCCESS:
            return { loading: false, agreementview: action.payload}
        case AGREEMENTS_VIEW_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state

    }
}