import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Navbar from "../../Components/Navbar";
import { APIURL } from "../../CommomMethods/Fetch";
import CssBaseline from "@material-ui/core/CssBaseline";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ConfirmDialog from "../../Components/ConfirmDialog";
import DoctorSidebar from "../../Components/DoctorSidebar";
import { Button, Paper, Typography } from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl } from "@material-ui/core";
import { numberWithCommas, dateFormatter } from "../../actions/createPositionActions"
// Formatting Date
const formatDate = (dateRaw) => {

    let date = new Date(dateRaw);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) { dt = '0' + dt; }
    if (month < 10) { month = '0' + month; }

    return year + '-' + month + '-' + dt;
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    formControl: {
        width: "100%",
        marginBottom: "10px",
    },
    root2: {
        height: "235px",
        overflowY: "auto",
        // marginTop: "10px",
        width: "100%",
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    iconStyle: {
        color: "#820e37",
        cursor: "pointer",
        marginRight: "20px",
        height: "20px",
        width: "20px",
    },
}));
const backDropStyle = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
const stylesmodal = makeStyles({
    root: {
        marginTop: "18px",
        marginBottom: "15px",
    },
});
export default function DoctorOpPayout() {
    const classes = useStyles();
    const styleClasses = stylesmodal();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subtitle: "",
    });
    const [changeRowValue, setChangeRowValue] = useState([]);
    const [rowSelection, setRowSelection] = useState(false);
    const [dataVal, setDataVal] = useState([]);
    const [backDropOpen, setBackDropOpen] = useState(false);
    const backClasses = backDropStyle();
    const [rows, setRows] = useState([]);
    const [center, setCenter] = useState([]);
    const [year, setYear] = useState([]);
    const [month, setMonth] = useState([]);
    const [monthValue, setmonthValue] = useState();
    const [centerName, setCenterName] = useState([]);
    const [payOutCat, setPayOutCat] = useState([]);
    const [iPDetails, setIPDetails] = useState([]);
    const [oPDetails, setOPDetails] = useState([]);
    const [LOP, setLOP] = useState(0);
    const [otherEarnings, setOtherEarnings] = useState(0);
    const [advance, setAdvance] = useState(0);
    const [deduction, setDeduction] = useState(0);
    const [TDSPerc, setTDSPerc] = useState(0);
    const [OPPayout, setOPPayout] = useState('');
    const [IPPayout, setIPPayout] = useState('');
    const userLogin = useSelector((state) => state.userLogin);
    const { user } = userLogin;

    console.log(user, "@@@@@@");
    const [FromDate, setFromDate] = React.useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [toDate, setToDate] = React.useState(new Date());

    function selectedMonth(e) {
        const { name, value } = e.target;
        setMonth(value);
        if (value === "JANUARY") {
            setmonthValue(1)
        }
        if (value === "FEBRUARY") {
            setmonthValue(2)
        }
        if (value === "MARCH") {
            setmonthValue(3)
        }
        if (value === "APRIL") {
            setmonthValue(4)
        }
        if (value === "MAY") {
            setmonthValue(5)
        }
        if (value === "JUNE") {
            setmonthValue(6)
        }
        if (value === "JULY") {
            setmonthValue(7)
        }
        if (value === "AUGUST") {
            setmonthValue(8)
        }
        if (value === "SEPTEMBER") {
            setmonthValue(9)
        }
        if (value === "OCTOBER") {
            setmonthValue(10)
        }
        if (value === "NOVEMBER") {
            setmonthValue(11)
        }
        if (value === "DECEMBER") {
            setmonthValue(12)
        }
    }

    function selectedYear(e) {
        const { name, value } = e.target;
        setYear(value);
    }
    function selectedCenter(e) {
        const { name, value } = e.target;
        setCenter(value);
    }
    const handleFromDate = (e) => {
        let input = e.target.value
        // console.log(e.target.value, "Onchange Console")
        setFromDate(input)
    }
    const handleToDate = (e) => {
        let input = e.target.value
        console.log(e.target.value, "Onchange Console")
        setToDate(input)
    }
    // console.log(date.toLocaleDateString(),toDate.toISOString().substr(0,10),"%%%");
    // function generatePay() {
    //     setBackDropOpen(true);
    //     axios.get(APIURL() + 'billings/Payout/payoutFinalOPDatewise', { params: { user: user.user.username, createdAt: date.toISOString().substr(0, 10), updatedAt: toDate.toISOString().substr(0, 10) } })
    //         .then((res) => res.data)
    //         .then((rows) => {
    //             // setBackDropOpen(false);
    //         });
    //     axios.get(APIURL() + 'billings/Payout/generatedOpDatewisePayout', { params: { doctorName: user.user.username, center: center, createdAt: date.toISOString().substr(0, 10), updatedAt: toDate.toISOString().substr(0, 10) } })
    //         .then((res) => res.data)
    //         .then((rows) => {
    //             console.log(rows, "0002");
    //             setDataVal(rows.data);
    //             setBackDropOpen(false);
    //         });
    // }
    // const generatePay = async () => {
    //     let tempData = [...payOutCat]
    //     let IPDoctorDetails = []
    //     let OPDoctorDetails = []
    //     await setBackDropOpen(true);
    //     await axios.get(APIURL() + `billings/initiateCalculation/${center}/${year}/${month}`)
    //         .then((res) => {
    //             let dataArray = res.data.data.filter(elem => elem.conductingDoctor === user.user.username)
    //             IPDoctorDetails = Array.from(dataArray.reduce(
    //                 (m, { conductingDoctor, doctorFee }) => m.set(conductingDoctor, (m.get(conductingDoctor) || 0) + parseFloat(doctorFee)), new Map
    //                 ), ([conductingDoctor, doctorFee]) => ({ conductingDoctor, doctorFee }))
    //                 setIPDetails(IPDoctorDetails)
    //             }) // for IP doctorFee
    //             await axios.get(APIURL() + `billings/loadbills/${center}/${year}/${month}/calcPayByMonth`, { params: { regions: center } })
    //             .then((res) => {
    //             let dataArray1 = res.data.data.filter(elem => elem.ConductedBy === user.user.username)
    //             OPDoctorDetails = Array.from(dataArray1.reduce(
    //                 (m, { ConductedBy, DoctorPayout }) => m.set(ConductedBy, (m.get(ConductedBy) || 0) + parseFloat(DoctorPayout)), new Map
    //             ), ([ConductedBy, DoctorPayout]) => ({ ConductedBy, DoctorPayout }))
    //             setOPDetails(OPDoctorDetails)
    //         })
    //     for (let i = 0; i < tempData.length; i++) {
    //         let item;
    //         let item1;
    //         for (item in IPDoctorDetails) {
    //             if (IPDoctorDetails[item].conductingDoctor == tempData[i].name) {
    //                 tempData[i].IPPayout = tempData[i].IPPayout + IPDoctorDetails[item].doctorFee
    //             }
    //         }
    //         for (item1 in OPDoctorDetails) {
    //             if (OPDoctorDetails[item1].ConductedBy == tempData[i].name) {
    //                 tempData[i].OPPayout = tempData[i].OPPayout + OPDoctorDetails[item1].DoctorPayout
    //             }
    //         }
    //         if (tempData[i].category == "Fixed") {
    //             let numOfDays = new Date(year,monthValue,0).getDate()
    //             let fixedAmtGross = (((parseFloat(tempData[i].fixedAmount) / numOfDays)*(numOfDays - LOP))+ otherEarnings)- deduction
    //             let fixedAmtNet = (fixedAmtGross - ((fixedAmtGross * TDSPerc)/100)) - advance
    //             tempData[i].Payout = parseFloat(tempData[i].IPPayout) + parseFloat(tempData[i].OPPayout) + parseFloat(fixedAmtNet)
    //         } else if (tempData[i].category == "VC") {
    //             tempData[i].Payout = parseFloat(tempData[i].IPPayout) + parseFloat(tempData[i].OPPayout)
    //         } else if (tempData[i].category == "RETAINER") {
    //             tempData[i].Payout = tempData[i].fixedAmount
    //         } else if (tempData[i].category == "MG") {
    //             if ((parseFloat(tempData[i].IPPayout) + parseFloat(tempData[i].OPPayout)) > tempData[i].minguranteepermonth) {
    //                 tempData[i].Payout = parseFloat(tempData[i].IPPayout) + parseFloat(tempData[i].OPPayout)
    //             } else {
    //                 tempData[i].Payout = tempData[i].minguranteepermonth
    //             }
    //         }
    //     }
    //     await setPayOutCat(tempData)
    //     await setBackDropOpen(false);
    // }

    useEffect(() => {
        console.log(formatDate(FromDate),formatDate(toDate),"FromDate,toDate")
        // axios.get(APIURL() + `billings/payoutCategoryDetails`)
        //     .then((res) => {
        //         let dataArray = res.data.data.filter((elem) => elem.name === user.user.username)
        //         // console.log(dataArray,"dataArray")
        //         setPayOutCat(dataArray)
        //     })
        axios.post(APIURL() + `billings/get-doc-payout-summery`,{
            docId: user.user.id,
            FromDate: formatDate(FromDate),
            ToDate: formatDate(toDate)
        })
            .then((res) => {
                let dataArray = res.data.data
                let OPPayout = res.data.OPPayout[0].OPPayout
                let IPPayout = res.data.IPPayout[0].IPPayout
                setPayOutCat(dataArray)
                setOPPayout(OPPayout)
                setIPPayout(IPPayout)
            })
    }, [FromDate, toDate])

    // console.log(dataVal, 'dataVal');
    // console.log(new Date(year,monthValue,0).getDate(),"HGHGHGHGHGHGHG")
    return (
        <>

            <DoctorSidebar />
            <CssBaseline />
            <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
                <Navbar />
                <div style={{
                    margin: ".4rem", textAlign: "center", padding: "7px", backgroundColor: "#810e36",
                    color: "white"
                }}>PAYMENT STATEMENT FROM &nbsp;<span style={{ fontWeight: 'bold' }}>{dateFormatter(FromDate)}</span> &nbsp;TO&nbsp; <span style={{ fontWeight: 'bold' }}>{dateFormatter(toDate)}</span></div>
                <Paper style={{ height: "100px", border: "solid 1px" }}>
                    <div className="row">
                        <div className="col" style={{ marginTop: 'auto', marginLeft: '30px' }}>
                            <div className="row">
                                <div className="col" style={{ display: 'flex', alignItems: 'center', minWidth: '97px' }}>From Date</div>
                                <div className="col"><input className="form-control" type='date' onChange={handleFromDate} value={formatDate(FromDate)} style={{ border: 'solid 1px', fontWeight: '500', color: 'black' }}></input></div>
                                <div className="col" style={{ display: 'flex', alignItems: 'center' }}>To Date</div>
                                <div className="col"><input className="form-control" type='date' onChange={handleToDate} value={formatDate(toDate)} style={{ border: 'solid 1px', fontWeight: '500', color: 'black' }}></input></div>
                            </div>
                        </div>
                        <div className="col" style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", paddingRight: '30px', marginTop: 'auto' }}>
                            <div style={{ marginTop: 'auto' }}>
                                <div>Total OP Payout : &#x20b9; {OPPayout > 0 ? numberWithCommas(OPPayout) : 0}</div>
                                <div>Total IP Payout : &#x20b9; {IPPayout > 0 ? numberWithCommas(IPPayout) : 0}</div>
                                <div>Total Payout : &#x20b9; {OPPayout + IPPayout > 0 ? numberWithCommas(OPPayout + IPPayout) : 0}</div>
                            </div>
                        </div>
                    </div>
                </Paper>
                <MaterialTable style={{ border: "solid 1px" }}
                    columns={[
                        { title: "MPI", field: "MPI" },
                        { title: "Bill No", field: "BillNo" },
                        { title: "Bill Date", field: "BillDate" },
                        { title: "Bill Time", field: "BillTime" },
                        { title: "Patient Name", field: "PatientName" },
                        { title: "Item Group", field: "ItemGroupName" },
                        { title: "Item Name", field: "ItemName" },
                        { title: "Ordering Physician", field: "OrderingPhysician" },
                        { title: "Specialisation", field: "Specialisation" },
                        { title: "PaymentMonth", field: "PaymentMonth" },
                        { title: "Year", field: "Period" },
                        { title: "Location", field: "Location" },
                        { title: "Patient Type", field: "patientType" },
                        { title: "Doctor Fee", field: "doctorFee" },
                    ]}
                    data={payOutCat}
                    options={{
                        sorting: true,
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 10,
                        pageSizeOptions: [10, 50, 100, 200, 500],
                        search: true,
                    }}
                    onSelectionChange={(rows) => {
                        setRowSelection(true);
                        setChangeRowValue(rows);
                    }}
                    title="Payout Report"
                />
            </Container>
            <Backdrop
                className={backClasses.backdrop}
                open={backDropOpen}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
            {/* <Card data={opt} */}
        </>
    );
}


