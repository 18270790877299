import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AppSidebar from "../../Components/AppSidebar";
import Sidebar from "../../Components/Sidebar";
import DoctorSidebar from "../../Components/DoctorSidebar";
import Navbar from "../../Components/Navbar";
import { APIURL } from "../../CommomMethods/Fetch";
import { Button, IconButton } from "@material-ui/core";
import axios from "axios";
import MaterialTable from "material-table";
import AssignmentIcon from "@material-ui/icons/Assignment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import { onboardState } from "../../CommomMethods/States";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

export default function UploadJoining(...props) {
  // let history = useHistory();
  // const onboardId = useSelector((state) => state.onboardId);
  // const { oboard } = onboardId;
  console.log("Top")
  const [filename, setFilename] = useState("");
  const [file, setFile] = useState();
  const [filePath, setfilePath] = useState();
  const [rows, setRows] = useState([]);
  //for user Destails
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;
  // useEffect(() => {
  //   axios.get(APIURL() + "users/doctorlist/" + user.user.id).then((res) => {
  //     let data = res.data.data.filter((z) => z.users_role_id == 4);
  //     console.log(data, "res-data-user");
  //     setRows(data);
  //   });
  // }, []);
  console.log(props, "props.location.state.data")
  return (
    <>
      {
        user.user.roles == 4 ? <DoctorSidebar />
          :
          user.user.roles !== 0 ? <Sidebar />
            : <AppSidebar />
      }
      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />
            {user.user.activityAssigments.filter((elem) => elem.activityId === 4 || elem.activityId === 5).find(item => item.RoleId === user.user.roles) ||  user.user.roles == 0 ?
              <div style={{ marginTop: "20px" }}>
                <MaterialTable
                  style={{float:"center"}}
                  columns={[
                    { title: "Doctor Name", field: "name", cellStyle: { textAlign: 'center' } },
                    { title: "Email", field: "email", cellStyle: { textAlign: 'center' } },
                    {
                      title: "View Document",
                      field: "Action",
                      filtering: false,
                      cellStyle: { textAlign: 'center' },
                      render: (row) => (
                        row.OnextAction == onboardState.upload.state || row.OnextAction == onboardState.hr.state ||  user.user.roles == 0 ?
                          <Link
                            to={{
                              pathname: "/ViewDocument",
                              state: { state: row },
                            }}
                          >
                            <VisibilityIcon
                              style={{ color: "#810e36", cursor: "pointer" }}
                            ></VisibilityIcon>
                          </Link>
                          :
                          <IconButton aria-label="close" disabled>
                            <VisibilityOffIcon />
                          </IconButton>
                      ),
                    },
                    {
                      title: "View Profile",
                      field: "Action",
                      filtering: false,
                      cellStyle: { textAlign: 'center' },
                      render: (row) => (
                        row.OnextAction == onboardState.dr_info.state ?
                          <Link
                            to={{
                              pathname: "/ViewProfile",
                              state: { state: row },
                            }}
                          >
                            <VisibilityIcon
                              style={{ color: "#810e36", cursor: "pointer" }}
                            ></VisibilityIcon>
                          </Link>
                          :
                          <IconButton aria-label="close" disabled>
                            <VisibilityOffIcon />
                          </IconButton>
                      ),
                    },
                  ]}
                  // data={rows}
                  data={query =>
                    new Promise((resolve, reject) => {
                      let url = APIURL() + "users/doctorlist/" + user.user.id + "?"
                      url += `per_page=${query.pageSize}`
                      url += `&page=${(query.page + 1)}`
                      url += `&search=${query.search}`
                      if (query.filters.length) {
                        query.filters.forEach(item => {
                          url += `&${item.column.field}=${item.value}`
                        })
                      }
                      if (query.orderBy) {
                        url += `&orderByField=${query.orderBy.field}`
                        url += `&orderBySort=${query.orderDirection}`
                      }
                      // console.log(query, tableRef, "activitylogs")
                      axios.get(url)
                        .then(response => response.data)
                        .then(result => {
                          resolve({
                            data: result.data,
                            page: result.page - 1,
                            totalCount: result.total,
                          })
                        })
                    })
                  }
                  options={{
                    rowStyle: {
                      textAlign: 'center',
                    },
                    // filtering: true,
                    sorting: true,
                    exportButton: true,
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                    search: true,
                    searchText: props && props[0].location && props[0].location.state && props[0].location.state.data && props[0].location.state.data.doctorName ? (props[0].location.state.data.doctorName).toString() : ''
                  }}
                  title="Doctor Details"
                />
              </div>
              :
              <div>
                <h2 style={{ marginTop: "200px", display: 'flex', justifyContent: 'center', color: "#d11d53" }}>You Are Not Authorized To See This Page</h2>
              </div>}


          </div>
        </div>
      </div>
    </>
  );
}
