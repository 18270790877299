import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import {
  addpositions,
  removeRequest,
  findpositions,
} from "../../actions/createPositionActions";
import MaterialTable from "material-table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { onboardState } from "../../CommomMethods/States";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import AppBar from "@material-ui/core/AppBar";

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Test from "../../Pages/Payout/test";
import { APIURL } from "../../CommomMethods/Fetch";
import { listusers } from "../../actions/userActions";
import axios from "axios";
import {
  createOnboarding,
  addonboard,
  onboardById,
} from "../../actions/onboardActions";

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RejectedRequests({ reqTableData, reqType, match, history }) {
  const classes = useStyles();
  // const id=match.params.id
  const tables = myTableStyle();
  const [show, setShow] = useState(false);
  const [viewAgreement, setViewAgreement] = useState(false);
  const [row, setRows] = useState({});
  const [doctorId, setDoctorId] = useState("");
  const [docData, setDocData] = useState([]);
  const [categoty, setCategory] = useState("");
  const [orow, setOrow] = useState({});
  const [remark, setRemark] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openr, setOpenr] = React.useState(false);
  const [openRemark, setOpenRemark] = React.useState(false);
  const [viewRemark, setViewRemark] = useState("");

  useEffect(() => {
    axios.get(APIURL()+"onboard/" + 69).then((res) => {
      setDocData(res.data);
    });
  }, [doctorId]);

  const openAgreement = (row) => {
    if (row.doctorId == null) {
      alert("Onboarding In Progress");
    } else {
      setViewAgreement(true);
      setRows(row);
      dispatch(onboardById(row.doctorId));
      setDoctorId(row.doctorId);
      setCategory(row.category);
    }
  };
  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  const onboardId = useSelector((state) => state.onboardId);
  const { oboard } = onboardId;


  const closeAgreement = () => {
    setViewAgreement(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = (row) => {
    if (row.doctorId != null) {
      return alert("Onboard Initiated");
    } else {
      setShow(true);
      dispatch(findpositions(row.id));
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addpositions());
  }, [dispatch]);
  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);
  const userLogin = useSelector((state) => state.userLogin);

  // bringing things from the reducer
  const { user } = userLogin;
  const addPosition = useSelector((state) => state.addPosition);
  const { addpos } = addPosition;

  const getOnboard = useSelector((state) => state.getOnboard);
  const { getonboard } = getOnboard;

  const findPos = useSelector((state) => state.findPos);
  const { findpos } = findPos;
  // reqTableData.forEach(dataRow => dataRow.clickEvent = (id) => handleShow(id))

  useEffect(() => {
    dispatch(addonboard());
  }, [dispatch]);

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: "RequestId",
        field: "requestId",
        width: 100,
      },
      {
        label: "Request Type",
        field: "requestType",
        width: 100,
      },
      {
        label: "Request Raised By",
        field: "requestRaisedBy",
        sort: "asc",
        width: 200,
      },
      {
        label: "Date",
        field: "date",
        sort: "disabled",
        width: 100,
      },
      {
        label: "Subject",
        field: "subject",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Current Status",
        field: "currentStatus",
        sort: "disabled",
        width: 100,
      },
    ],

    rows: reqTableData,
  });
  const infoStyle = {
    border: "2px solid #858585",
  };

  const removeRequestHandler = (id) => {
    // dispatch(removeRequest(id))
  };
  const handlerClose = () => {
    setOpenr(false);
  };

  const handlerClickOpen = (row) => {
    // setrId(id);
    setOrow(row);
    setOpenr(true);
  };
  const handleApprove = (row) => {
    const onboardData = {
      doctorId: row.doctorId,
      name: row.name,
      currentAction: onboardState.hr.state,
      currentActionBy: user.user.id,
      nextAction: onboardState.rd.state,
      status: 1,
    };
  
    axios
      .post(APIURL() + "onboard/action/", onboardData)
      .then((res) => {
      });
    setTimeout(() => {
      // window.location.reload();
    }, 1000);
  };
  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);
  const handleSubmitReject = () => {
    const onboardData = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: onboardState.hr.state,
      currentActionBy: users[0] && users[0].users_role_id,
      nextAction: onboardState.rd.state,
      remark: remark,
      status: 1,
    };
    
    // axios
    //   .post("onboard/action/", onboardData)
    //   .then((res) => {
    //   });
    setTimeout(() => {
      // window.location.reload();
    }, 1000);
  };

  const handleRemarkOpen = (remark) => {
    setViewRemark(remark);
    setOpenRemark(true);
  };
  const handleCloseRemark = () => {
    setOpenRemark(false);
  };

  const Remark = (e) => {
    setRemark(e.target.value);
  };
  return (
    <>
      <Sidebar />
      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <Navbar />
          <br />
          <br />
          <div className="card custom-card" style={infoStyle}>
            {user && user.user && user.user.roles == "1" ? (
              <div className="card-body">
                <h3 className="main-content-title tx-24 mb-4 mg-b-5">
                  Rejected Requests
                </h3>
                <table
                  className="table card-table table-striped table-vcenter text-nowrap mb-0 ctable"
                  id="table"
                >
                  <thead>
                    <tr>
                      <th className="wd-lg-20p">
                        <span>Doctor Name </span>
                      </th>
                      <th className="wd-lg-20p">
                        <span>Current Action</span>
                      </th>
                      <th className="wd-lg-20p">
                        <span>Next Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getonboard &&
                      getonboard.data &&
                      getonboard.data.map((ele) => (
                        <tr key={ele.id} onClick={() => handleShow(ele.id)}>
                          <td>{ele.name} </td>
                          <td>{ele.currentAction} </td>
                          <td>{ele.nextAction}</td>

                          <td>
                            <button
                              className="danger"
                              onClick={() => removeRequestHandler(ele.id)}
                            >
                              <i
                                className="fas fa-trash"
                                style={{ color: "red" }}
                              ></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="card-body">
                <h3 className="main-content-title tx-24 mb-4 mg-b-5">
                  Rejected Requests
                </h3>
                <table
                  className="table card-table table-striped table-vcenter text-nowrap mb-0 ctable"
                  id="table"
                >
                  <thead>
                    <tr>
                      <th className="wd-lg-20p">
                        <span>Doctor Name </span>
                      </th>
                      <th className="wd-lg-20p">
                        <span>Current Action</span>
                      </th>
                      <th className="wd-lg-20p">
                        <span>Next Action</span>
                      </th>
                      <th className="wd-lg-20p">
                        <span> Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getonboard &&
                      getonboard.data &&
                      getonboard.data.map((ele) => (
                        <tr key={ele.id}>
                          <td>{ele.name} </td>
                          <td>{ele.currentAction} </td>
                          <td>{ele.nextAction}</td>

                          <td>
                            {ele.currentAction === "rd-approval" ? (
                              "Approved By HR"
                            ) : ele.currentAction === "Rejected by HR" ? (
                              "Rejected By HR"
                            ) : ele.currentAction === "CH_VERIFY" &&
                              ele.nextAction === "HR_VERIFY" ? (
                              <div style={{ whiteSpace: "nowrap" }}>
                                <Button
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    handleApprove(ele);
                                  }}
                                >
                                  Approve
                                </Button>

                                {/* <Button
                                      style={{ marginLeft: "5px" }}
                                      size="small"
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => {
                                        handlerClickOpen(ele);
                                      }}
                                    >
                                      Reject
                                    </Button> */}
                              </div>
                            ) : (
                              " "
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            <Modal
              size="lg"
              scrollable
              dialogClassName="modalStandard"
              centered
              aria-labelledby="example-modal-sizes-title-lg"
              show={show}
              onHide={handleClose}
            >
              <Modal.Header className="modalHeader" closeButton>
                <Modal.Title className="modalTitle">
                  Requested Id:{findpos.id}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* {addpos.data && addpos.data.map((ele,index) =>( */}
                <div className="row">
                  <div className="col-6 ">
                    <label className="tx-inverse  ">Request Id</label>
                    <input
                      className="form-control"
                      id="RequestId"
                      value={findpos.id}
                      placeholder="Input box"
                      type="text"
                      style={{ color: "black" }}
                    />
                    <br />
                    <label className="tx-inverse  ">Onboarding Type</label>
                    <input
                      className="form-control"
                      id="RequestType"
                      placeholder="Input box"
                      value={findpos.category}
                      type="text"
                      style={{ color: "black" }}
                    />
                  </div>

                  <div className="col-6 ">
                    <label className="tx-inverse  ">Request Raised By</label>
                    <input
                      className="form-control"
                      id="RequestRaisedBy"
                      placeholder="Input box"
                      value={findpos.label}
                      type="text"
                      style={{ color: "black" }}
                    />
                    <br />
                    <label className="tx-inverse ">Date</label>
                    <input
                      className="form-control"
                      id="Date"
                      placeholder="Input box"
                      value={findpos.createdAt}
                      type="text"
                      style={{ color: "black" }}
                    />
                  </div>
                </div>
                {/* ))}   */}
                <br />
                <div className="row">
                  <div className="col-6 ">
                    <label className="tx-inverse ">Subject</label>
                    <input
                      className="form-control"
                      id="Subject"
                      placeholder="Input box"
                      value={findpos.designationLabel}
                      type="text"
                      style={{ color: "black" }}
                    />
                  </div>

                  <div className="col-6 ">
                    <label className="tx-inverse  ">Current Status</label>
                    <input
                      className="form-control"
                      id="CurrentStatus"
                      placeholder="Input box"
                      value={findpos.status}
                      type="text"
                      style={{ color: "black" }}
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6 ">
                    <label className="tx-inverse  ">Designation</label>
                    <input
                      className="form-control"
                      id="Subject"
                      placeholder="Input box"
                      value={findpos.designationLabel}
                      type="text"
                      style={{ color: "black" }}
                    />
                  </div>

                  <div className="col-6 ">
                    <label className="tx-inverse ">Category</label>
                    <input
                      className="form-control"
                      id="CurrentStatus"
                      placeholder="Input box"
                      value={findpos.category}
                      type="text"
                      style={{ color: "black" }}
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-6 ">
                    <label className="tx-inverse  ">Department</label>
                    <input
                      className="form-control"
                      id="Subject"
                      placeholder="Input box"
                      value={findpos.departmentLabel}
                      type="text"
                      style={{ color: "black" }}
                    />
                  </div>

                  <div className="col-6 ">
                    <label className="tx-inverse  ">Center Name</label>
                    <input
                      className="form-control"
                      id="CurrentStatus"
                      placeholder="Input box"
                      value={findpos.centerNames}
                      type="text"
                      style={{ color: "black" }}
                    />
                  </div>
                </div>

                <br />
                <label className="tx-inverse  ">Comments</label>
                <textarea
                  class="form-control"
                  value={findpos.comments}
                  id="comments"
                  placeholder="Textarea"
                  rows="3"
                  style={{ color: "black" }}
                  readOnly
                ></textarea>

                {/* <p className="tx-inverse "> Personal Information</p>

                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <label className="tx-inverse text-muted">Full Name</label>
                            <input className="form-control" placeholder="Input box" type="text" />
                        </div>

                        <div className="col-12 col-sm-4">
                            <label className="tx-inverse text-muted">Contact Number</label>
                            <input className="form-control" placeholder="Input box" type="text" />
                        </div>

                        <div className="col-12 col-sm-4">
                            <label className="tx-inverse text-muted">Email Id</label>
                            <input className="form-control" placeholder="Input box" type="text" />
                        </div>
                    </div> */}
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center">
                <Link
                  to={{
                    pathname: "/create-doctor",

                    state: { data: findpos },
                  }}
                  className="standardButton acceptButton pt-2"
                  style={{ textAlign: "center" }}
                >
                  Initiate Onboarding
                </Link>

                <button
                  className="standardButton rejectButton"
                  onClick={handleClose}
                >
                  Review
                </button>
              </Modal.Footer>
            </Modal>

            <Dialog
              fullScreen
              open={viewAgreement}
              onClose={handleClose}
              TransitionComponent={Transition}
            >
              <AppBar className={classes.appBar}>
                <Toolbar>
                  <IconButton
                    edge="end"
                    style={{ marginLeft: "99%" }}
                    color="inherit"
                    onClick={closeAgreement}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <List>
                <Test history={row} recData={docData} catagory={categoty} />
              </List>
            </Dialog>
          </div>
        </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Review
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Remark</b>
          </label>
          <textarea
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitReject}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseRemark}
        aria-labelledby="customized-dialog-title"
        open={openRemark}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Remark
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{viewRemark}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCloseRemark}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RejectedRequests;
