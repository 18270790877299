// North 
// payable amount = X% of Net Amount 
// ItemGroup - Non OT --> Item Name --> payable(0/1): for 1 ---> check Share part ---> share*net

function northOtherCharges(row, oboard) {
    let earnings = 0;
    var returns = 0;
    console.log(row, oboard, "$$$$$")
    for (let i = 0; i < oboard.doctorOpCommercial.length; i++) {
        if (row.ItemGroupName.indexOf(oboard.doctorOpCommercial[i].itemgroup) >= 0 && row.PayableNonPayable === "1") {

            var commercial = oboard.doctorOpCommercial[i].commercialterm
            if (commercial) {
                let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
                let ratio = parseFloat("" + row.Ratio);
                let share = parseFloat("" + row.Share);
                // let net=parseFloat(""+row.Gross)-parseFloat(row.Discount);
                earnings = parseFloat("" + row.Net) * share;
                // earnings=parseFloat(row.Net) *share;
                returns = -(earnings);
            }

        }

    }
    return earnings;
}

// South

// Scenario1 :Non-OT Procedure - Item Group --> Item Name --> payable --> ratio/fixed  --> ratio*net , fixed
function southOtherCharges(row, oboard) {
    let earnings = 0;
    var returns = 0;
    console.log(row, oboard, "$$$$$")
    for (let i = 0; i < oboard.doctorOpCommercial.length; i++) {
        if (row.ItemGroupName.indexOf(oboard.doctorOpCommercial[i].itemgroup) >= 0 && row.PayableNonPayable === "1") {
            var commercial = oboard.doctorOpCommercial[i].commercialterm
            if (oboard.doctorOPParameter[i].fixedamount === "0") {
                if (commercial) {
                    let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
                    let ratio = parseFloat("" + row.Ratio);
                    let share = parseFloat("" + row.Share);
                    // let net=parseFloat(""+row.Gross)-parseFloat(row.Discount);
                    earnings = parseFloat("" + row.Net) * share;
                    // earnings=parseFloat(row.Net) *share;
                    returns = -(earnings);
                }

            } else {
                earnings = parseInt(oboard.doctorOPParameter[i].fixedamount);
            }

        }

    }
    return earnings;
}


//Scenario2: Non-OT Procedure - Item Group --> Item Name --> payable --> ratio/misc  --> ratio*net , fixed
function southOtherChargesMisc(row, oboard) {
    let earnings = 0;
    var returns = 0;
    console.log(row, oboard, "$$$$$")
    for (let i = 0; i < oboard.doctorOpCommercial.length; i++) {
        if (row.ItemGroupName.indexOf(oboard.doctorOpCommercial[i].itemgroup) >= 0 && row.PayableNonPayable === "1") {
            var commercial = oboard.doctorOpCommercial[i].commercialterm
            if (oboard.doctorOPParameter[i].fixedamount === "0") {
                if (commercial) {
                    let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
                    let ratio = parseFloat("" + row.Ratio);
                    let share = parseFloat("" + row.Share);
                    // let net=parseFloat(""+row.Gross)-parseFloat(row.Discount);
                    earnings = parseFloat("" + row.Net) * share;
                    // earnings=parseFloat(row.Net) *share;
                    returns = -(earnings);
                }

            } else {
                earnings = parseInt(oboard.doctorOPParameter[i].fixedamount);
            }

        }

    }
    return earnings;
}
// west

// Non-OT Procedure - Item Group --> Item Name --> payable --> ratio/fixed  --> ratio*net , fixed
function westOtherCharges(row, oboard) {
    let earnings = 0;
    var returns = 0;
    console.log(row, oboard, "$$$$$")
    for (let i = 0; i < oboard.doctorOpCommercial.length; i++) {
        if (row.ItemGroupName.indexOf(oboard.doctorOpCommercial[i].itemgroup) >= 0 && row.PayableNonPayable === "1") {
            var commercial = oboard.doctorOpCommercial[i].commercialterm
            if (oboard.doctorOPParameter[i].fixedamount === "0") {
                if (commercial) {
                    let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
                    let ratio = parseFloat("" + row.Ratio);
                    let share = parseFloat("" + row.Share);
                    // let net=parseFloat(""+row.Gross)-parseFloat(row.Discount);
                    earnings = parseFloat("" + row.Net) * share;
                    returns = -(earnings);
                    // earnings=parseFloat(row.Net) *share;
                }

            } else {
                earnings = parseInt(oboard.doctorOPParameter[i].fixedamount);
            }

        }

    }
    return earnings;
}
exports.payoutCalcOtherCharges = {
    northOtherChargesCalculation: northOtherCharges,
    southOtherChargesCalculation: southOtherCharges,
    southOtherChargesMiscCalculation: southOtherChargesMisc,
    westOtherChargesCalculation: westOtherCharges,
};