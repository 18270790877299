// import "./styles.css";
import { Box, Typography } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { PieChart, Pie, Cell, Sector, LabelList } from "recharts";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { numbersManager } from "../actions/userActions";
const data = [
  { name: "Total", value: 100 },
];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => (
  {
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
    },
  }));

let COLORS = ['#94438b', '#e4308b'];
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  console.log(payload, "payloadpayloadpayload")
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 5}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={payload.ColorCode ? payload.ColorCode : fill}
      />
    </g>
  );
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
function graphDatakey(props){
  let datakey = props.IPOPgraphvalue;
  let colors = ['#94438b', '#e4308b'];
  props.iPOPcontriList.map( item => 
    {
      if(item.name == "Revenue" && item.value == 0){
        datakey = data;
        colors = ['#DCDCDC'];
      }
      if(item.name == "Payout" && item.value == 0){
        datakey = data;
        colors = ['#DCDCDC'];
      }
    }
    )
    COLORS = colors;
    console.log(datakey, "hello")
    return datakey;
};
export default function PiechartIPOP(props) {
  const [activeIndex, setActiveIndex] = useState(null)
  const onPieEnter = (_, index) => {
    setActiveIndex(index)
  };
  const onPieExit = (_, index) => {
    setActiveIndex(null)
  };
  console.log(props, "abc12345")

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box>
        <PieChart width={330} height={220} style={{ cursor: 'pointer' }}>
          <Pie
            data={graphDatakey(props)}
            cx={150}
            cy={170}
            width={400}
            height={300}
            startAngle={180}
            endAngle={0}
            innerRadius={60}
            outerRadius={130}
            labelLine={false}
            paddingAngle={0}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
            onMouseLeave={onPieExit}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
          > 
            {props.iPOPcontriList.map((entry, index) => (
              <Cell key={index} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </Box>
      <Box>
        {props.iPOPcontriList && props.iPOPcontriList.length > 0 && props.iPOPcontriList.map((item, index) =>
          <Box onClick={() => { setActiveIndex(index) }} className={activeIndex == index ? 'pieLabelSelected' : 'pieLabel'} style={{ display: "flex", margin: "3px", justifyContent: "flex-start", cursor: 'pointer' }} >
            <Typography variant="subtitle2" style={{ color: COLORS[index % COLORS.length], display: 'inline-block', fontSize: '10px', paddingTop: '5px', width: '110px' }} >{item.name ? item.name : ''} : &#x20b9; {numbersManager(item.value)} <BorderLinearProgress variant="determinate" value={props.IPOPcontriListPerc[item.name]} sx={{ '& .MuiLinearProgress-bar1Determinate': { backgroundColor: COLORS[index % COLORS.length] } }} /> {props.IPOPcontriListPerc[item.name] ? `${props.IPOPcontriListPerc[item.name]}%` : null}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
}
