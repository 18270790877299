import { payoutCalcRadiology } from "../Calculations/payoutCalcRadiology";
import { payoutCalcOtherCharges } from "../Calculations/payoutOtherCharges";
import { payoutCalcNonOt } from "../Calculations/payoutNonOt";

function rowLevelPayout(row, oboard) {
  // console.log(oboard, "aaaa****");
  // console.log(row, "ccccc****");
  // console.log(row.Gross, "ddddd****");
  // console.log(parseFloat("" + row.Share), "bbbbb****");
  let earnings = 0;
  var returns = 0;
  for (let i = 0; i < oboard.doctorOPParameter.length; i++) {
    if (row.ItemName.indexOf(oboard.doctorOPParameter[i].itemGroup) >= 0) {
      var commercial =
        oboard.doctorOPParameter[i].commercialterm.includes("Gross");
      if (commercial) {
        let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
        let ratio = parseFloat("" + row.Share);
        earnings = parseInt(row.Gross) * (perc / 100);
        returns = -earnings;
      }
      var commercial2 =
        oboard.doctorOPParameter[i].commercialterm.includes("Net");
      if (commercial2) {
        let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
        earnings = parseInt(row.Net) * (perc / 100) - parseInt(row.Discount);
        returns = -earnings;
      }
    }
  }
  return earnings;
  // console.log(earnings, "())")
}

// initiate all calculation
function initiateCalOp(allRecord, oboard, data) {
  console.log(oboard, "all doctor data");
  var array = [];
  var sumArray = [];
  for (let i = 0; i < allRecord.length; i++) {
    if (data === "Radiology North") {
      let k = payoutCalcRadiology.northRadiologyCalculation(
        allRecord[i],
        oboard
      );
      allRecord[i].CalculatedValue = k;
      // sumArray.push(k);
      array.push(allRecord[i]);
    } else if (data === "Radiology South") {
      let k = payoutCalcRadiology.southRadiologyOneCalculation(
        allRecord[i],
        oboard
      );
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Radiology West") {
      let k = payoutCalcRadiology.westRadiologyOneCalculation(
        allRecord[i],
        oboard
      );
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Consultation North") {
      let k = northRegionOp(allRecord[i], oboard);
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Consultation South One") {
      let k = southRegionOneOp(allRecord[i], oboard);
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Consultation South Two") {
      let k = southRegionTowOp(allRecord[i], oboard);
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Consultation South Three") {
      let k = southRegionThreeOp(allRecord[i], oboard);
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Consultation South Four") {
      let k = southRegionFourOp(allRecord[i], oboard);
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Consultation West One") {
      let k = WestRegionOneOp(allRecord[i], oboard);
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Consultation West Two") {
      let k = westRegionTwoOp(allRecord[i], oboard);
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Consultation West Three") {
      let k = westRegionThreeOp(allRecord[i], oboard);
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Non OT North") {
      let k = payoutCalcNonOt.northNonOtCalculation(allRecord[i], oboard);
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Non OT South") {
      let k = payoutCalcNonOt.southNonOtCalculation(allRecord[i], oboard);
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Non OT West") {
      let k = payoutCalcNonOt.westNonOtCalculation(allRecord[i], oboard);
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Other Charges North") {
      let k = payoutCalcOtherCharges.northOtherChargesCalculation(
        allRecord[i],
        oboard
      );
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Other Charges South") {
      let k = payoutCalcOtherCharges.southOtherChargesCalculation(
        allRecord[i],
        oboard
      );
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Other Charges West") {
      let k = payoutCalcOtherCharges.southOtherChargesMiscCalculation(
        allRecord[i],
        oboard
      );
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    } else if (data === "Other Charges Misc") {
      let k = payoutCalcOtherCharges.westOtherChargesCalculation(
        allRecord[i],
        oboard
      );
      allRecord[i].CalculatedValue = k;
      array.push(allRecord[i]);
    }
  }
  return allRecord;
}

// North Region
function northRegionOp(row, oboard) {
  // console.log(oboard, "aaaa****");
  // console.log(row, "ccccc****");
  let earnings = 0;
  var returns = 0;
  for (let i = 0; i < oboard.doctorOPParameter.length; i++) {
    if (row.ItemName.indexOf(oboard.doctorOPParameter[i].itemGroup) >= 0) {
      var commercial = oboard.doctorOPParameter[i].commercialterm;
      if (commercial) {
        let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
        let ratio = parseFloat("" + row.Share);
        earnings = parseFloat(row.Net) * (perc / 100);
        returns = -earnings;
        if (isNaN(earnings)) {
          earnings = 0;
        } else {
        }
      }
    }
  }
  return earnings;
}

// south region scenarios
// Net = Gross - Discount + packageOverRun
// 1.Corporate/vouchers/earlybird
function southRegionOneOp(row, oboard) {
  let earnings = 0;
  var returns = 0;
  for (let i = 0; i < oboard.doctorOPParameter.length; i++) {
    if (row.ItemName.indexOf(oboard.doctorOPParameter[i].itemGroup) >= 0) {
      var commercial = oboard.doctorOPParameter[i].commercialterm;
      if (commercial) {
        let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
        let ratio = parseFloat("" + row.Share);
        earnings = parseInt(row.Gross) * (perc / 100);
        returns = -earnings;
        if (isNaN(earnings)) {
          earnings = 0;
        } else {
        }
      }
    }
  }
  return earnings;
}

// 2: Discount-->Staff/Doctor
function southRegionTowOp(row, oboard) {
  let earnings = 0;
  var returns = 0;
  for (let i = 0; i < oboard.doctorOPParameter.length; i++) {
    if (row.ItemName.indexOf(oboard.doctorOPParameter[i].itemGroup) >= 0) {
      var commercial =
        oboard.doctorOPParameter[i].commercialterm.includes("Gross");
      if (commercial) {
        let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
        let ratio = parseFloat("" + row.Share);
        let gross = parseInt(row.Gross) * (perc / 100);
        earnings =
          gross - parseInt(row.Discount) + parseInt(row.PackageOverrun);
        returns = -earnings;
      }
      var commercial2 =
        oboard.doctorOPParameter[i].commercialterm.includes("Net");
      if (commercial2) {
        let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
        earnings = parseInt(row.Net) * (perc / 100);
        returns = -earnings;
        // earnings = (parseInt(row.Net) * (perc / 100)) - parseInt(row.Discount);
      }
      if (isNaN(earnings)) {
        earnings = 0;
      } else {
      }
    }
  }
  return earnings;
}

// 3: Admin Charges

function southRegionThreeOp(row, oboard) {
  let earnings = 0;
  var returns = 0;
  for (let i = 0; i < oboard.doctorOPParameter.length; i++) {
    if (row.ItemName.indexOf(oboard.doctorOPParameter[i].itemGroup) >= 0) {
      var commercial =
        oboard.doctorOPParameter[i].commercialterm.includes("Gross");
      if (commercial) {
        let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
        let ratio = parseFloat("" + row.Share);
        // discount=admin charges
        let gross = parseInt(row.Gross) * (perc / 100);
        earnings =
          gross - parseInt(row.Discount) + parseInt(row.PackageOverrun);
        returns = -earnings;
        if (isNaN(earnings)) {
          earnings = 0;
        } else {
        }
      }
      var commercial2 =
        oboard.doctorOPParameter[i].commercialterm.includes("Net");
      if (commercial2) {
        let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
        // earnings = (parseInt(row.Net) * (perc / 100));
        earnings = parseInt(row.Net) * (perc / 100) - parseInt(row.Discount);
        if (isNaN(earnings)) {
          earnings = 0;
        } else {
        }
      }
    }
  }
  return earnings;
}

// 4: Fixed Amount of Consultations
function southRegionFourOp(row, oboard) {
  let earnings = 0;
  var returns = 0;
  for (let i = 0; i < oboard.doctorOPParameter.length; i++) {
    if (row.ItemName.indexOf(oboard.doctorOPParameter[i].itemGroup) >= 0) {
      // var commercial = oboard.doctorOPParameter[i].commercialterm.includes("Gross");
      let fixedamount = parseInt(oboard.doctorOPParameter[i].fixedamount);
      earnings = fixedamount;
      returns = -earnings;
      if (isNaN(earnings)) {
        earnings = 0;
      } else {
      }
    }
  }
  return earnings;
}

// WEST Resgion

// 1: Discount-->Staff/Doctor
function WestRegionOneOp(row, oboard) {
  let earnings = 0;
  var returns = 0;
  for (let i = 0; i < oboard.doctorOPParameter.length; i++) {
    if (row.ItemName.indexOf(oboard.doctorOPParameter[i].itemGroup) >= 0) {
      var commercial =
        oboard.doctorOPParameter[i].commercialterm.includes("Gross");
      if (commercial) {
        let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
        let ratio = parseFloat("" + row.Share);
        let gross = parseInt(row.Gross) * (perc / 100);
        earnings = gross - parseInt(row.Discount);
        returns = -earnings;
      }
      var commercial2 =
        oboard.doctorOPParameter[i].commercialterm.includes("Net");
      if (commercial2) {
        let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
        // earnings = (parseInt(row.Net) * (perc / 100));
        earnings = parseInt(row.Net) * (perc / 100) - parseInt(row.Discount);
        if (isNaN(earnings)) {
          earnings = 0;
        } else {
        }
      }
    }
  }
  return earnings;
}

// 2: Admin Charges

function westRegionTwoOp(row, oboard) {
  let earnings = 0;
  var returns = 0;
  for (let i = 0; i < oboard.doctorOPParameter.length; i++) {
    if (row.ItemName.indexOf(oboard.doctorOPParameter[i].itemGroup) >= 0) {
      var commercial =
        oboard.doctorOPParameter[i].commercialterm.includes("Gross");
      if (commercial) {
        let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
        let ratio = parseFloat("" + row.Share);
        // discount=admin charges
        let gross = parseInt(row.Gross) * (perc / 100);
        earnings = gross - parseInt(row.Discount);
        returns = -earnings;
        if (isNaN(earnings)) {
          earnings = 0;
        } else {
        }
      }
    }
  }
  return earnings;
}

// 3: Fixed Amount of Consultations
function westRegionThreeOp(row, oboard) {
  let earnings = 0;
  var returns = 0;
  for (let i = 0; i < oboard.doctorOPParameter.length; i++) {
    if (row.ItemName.indexOf(oboard.doctorOPParameter[i].itemGroup) >= 0) {
      // var commercial = oboard.doctorOPParameter[i].commercialterm.includes("Gross");
      let fixedamount = parseInt(oboard.doctorOPParameter[i].fixedamount);
      earnings = fixedamount;
      returns = -earnings;
    }
  }
  return earnings;
}
export const payoutCalculation = {
  rowLevelPayoutCalculation: rowLevelPayout,
  northRegionOpCalculation: northRegionOp,
  southRegionOneOpCalculation: southRegionOneOp,
  southRegionTowOpCalculation: southRegionTowOp,
  southRegionThreeOpCalculation: southRegionThreeOp,
  southRegionFourOpCalculation: southRegionFourOp,
  WestRegionOneOpCalculation: WestRegionOneOp,
  westRegionTwoOpCalculation: westRegionTwoOp,
  westRegionThreeOpCalculation: westRegionThreeOp,
  initiateCalOpCalc: initiateCalOp,
};

// earning= earning * percentage
// payout(net)= earning-discount
