import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Autocomplete, createFilterOptions } from "@mui/material";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import { CheckBox as CheckBoxIcon } from "@mui/icons-material";
import AddIcon from "@material-ui/icons/Add";
import UpgradeIcon from '@mui/icons-material/Upgrade';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveIcon from "@material-ui/icons/Remove";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import RefreshIcon from "@material-ui/icons/Refresh";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import InputAdornment from "@mui/material/InputAdornment";
import CountertopsIcon from "@mui/icons-material/Countertops";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import MoneyIcon from "@mui/icons-material/Money";
import CalculateIcon from "@mui/icons-material/Calculate";
import { postRecord, putRecord } from "../../../CommomMethods/Save";
import { toast } from "react-toastify";
import { APIURL } from "../../../CommomMethods/Fetch";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from "@material-ui/core";
import { Paper } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Backdrop from "@material-ui/core/Backdrop";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  MenuProps,
  useStylesmulti,
  ipListData,
  paymentMode,
  gipsaOptions,
} from "../../CommonFIle/util";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
//ui components
import ConfirmDialog from "../../../Components/ConfirmDialog";
import axios from "axios";
import { numberWithCommas } from "../../../actions/createPositionActions";

const groups = [
  {
    label: 'OBG (Delivery)',
    value: 'Delivery'
  }, {
    label: 'Gynec',
    value: 'Gynec'
  }, {
    label: 'Pead',
    value: 'Pead'
  }, {
    label: 'Fertility',
    value: 'Fertility'
  },
]

const applicableOnOptions = [
  {
    label: '% on Gross Bill Amount',
    value: 'Open Bill Gross'
  },
  {
    label: '% on Net Bill Amount',
    value: 'Open Bill Net'
  },
  {
    label: '% on Gross of Item Amount',
    value: 'Gross'
  },
  {
    label: '% on Net of Item Amount',
    value: 'Net'
  },
  // {
  //   label: 'Surgeon Percentage',
  //   value: 'Surgeon'
  // },
  // {
  //   label: 'Consultation Percentage',
  //   value: 'Consultation'
  // },
  {
    label: '% on Package',
    value: 'Package'
  },
  {
    label: 'Fixed Amount on Package',
    value: 'Fixed on Package'
  },
  {
    label: 'Fixed Amount',
    value: 'Fixed'
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    width: "100%",
  },
}));

const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const multiSelectStyle = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    whiteSpace: "nowrap",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
      whiteSpace: "nowrap",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  root1: {
    "& label + .MuiInput-formControl": {
      marginTop: "1vw",
    },
  },
}));

const useStyles1 = makeStyles({
  paper: {
    border: "5px solid black",
  },
});
export default function IpDetail({ sendDataTIpParent, sendCalculatedIpData, propdata, doctorData, centerData, centerList }) {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const multiclasses = useStylesmulti();
  // const [selected, setSelected] = useState([]);
  const location = useLocation();
  const [isError, setIsError] = useState(false);

  const backClasses = backDropStyle();
  const [backDropOpen, setBackDropOpen] = useState(false);
  const classes1 = useStyles1();
  const tables = myTableStyle();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [totaldeduction, setTotaldeduction] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [selecteddata, setSelectedData] = useState({});
  const [show, setShow] = useState(false);
  const [Consultation, setConsultation] = useState("");
  const [Discount, setDiscount] = useState("No");
  const [Package, setPackage] = useState("");
  const [fixedPay, setFixedPay] = useState("");
  const [payout, setpayout] = useState("");
  const [gross, setGross] = useState("");
  const [netC, setNetC] = useState("");
  const [IpRoom, setIpRoom] = useState("");
  const [IpMop, setIpMop] = useState("");
  const [disableipsurgeonfeeperc, setDisableipsurgeonfeeperc] = useState(false);
  const [disableippackageamountperc, setDisableippackageamountperc] =
    useState(false);
  const [amount, setAmount] = useState("");
  const [adhoc, setAdhoc] = useState("");
  const [ipmonth, setIpMonth] = useState(1);
  const [finalpayout, setFinalpayout] = useState("");
  const [ipNetBillAmountTot, setipNetBillAmountTot] = useState(0);
  const [ipfixedamount, setIpfixedamount] = useState(0);
  const [ipAmountTot, setIpAmountTot] = useState(0);
  const [ipPayoutTotal, setIpPayoutTotal] = useState(0);
  const [fixed, setFixed] = useState(false);
  const [maritalStat, setmaritalStat] = useState(false);
  const [totalippayout, setTotalIppayout] = useState([]);
  const [totalipRevenue, setTotalIpRevenue] = useState([]);
  const [totalIpPayoutNew, setTotalIpPayoutNew] = useState(0);
  const [totalIpRevenueNew, setTotalIpRevenueNew] = useState(0);
  const [ipdiscount, setIpDiscount] = useState("Not applicable");
  const [iptable, setIptable] = useState([]);
  const [centerOptions, setCenterOptions] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState('');
  const initialFormtStateipservice = {
    perunitcharge: "",
    itemGroup: "",
    ipconsultaion: "",
    iproom: "",
    ipmodeofpayment: "",
    ipfixedamount: "",
    ipsurgeonfeeperc: "",
    ippackageamountperc: "",
    ipsurgeonfeeamount: "",
    ipdiscountvalue: "",
    ipdiscountType: "",
    ipgrossperc: "",
    ipnetperc: "",
    ipmonth: "",
    netperc: "",
    amount: "",
    count: "",
    revenue: "",
    payout: "",
    ipgrossbill: "",
    ipgrossbillpercentage: "",
    ipdeductaionvalue: "",
    deductionCat: "",
    ipnetbillpercentage: "",
    iptotalamount: "",
    ipnetbillamount: "",
    ipTotalAmount: "",
    ipTotalNetBillAmount: "",
    ipgrossbillperunit: "",
    ipdeductionperunit: "",
    slab: false,
    gipsa: [],
  };
  const [ipservice, setIpservice] = useState([
    {
      initialFormtStateipservice,
    },
  ]);
  const [radiologyChild, setRadiologyChild] = useState([]);
  const [ipgrossbillTot, setipgrossbillTot] = useState("");
  const [ipdeductiontot, setipdeductiontot] = useState(0);
  const [commercialIpTable, setCommercialIpTable] = useState([]);
  const [ipChild, setIpChild] = useState([]);
  const [grossIp, setGrossIp] = useState("");
  const [ipdeductionCat, setIpdeductionCat] = useState("");
  const [netIp, setnetIp] = useState("");
  const [slab, setSlab] = useState(false);
  const [gipsa, setGipsa] = useState([]);
  const [selectAllvalIp, setSelectAllvalIp] = useState(false);
  const [selectAllDelivery, setSelectAllDelivery] = useState(false);
  const [chkipList, setChkIpList] = useState([]);
  const [paymentMode, setPaymentMode] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = useState('');
  const [selectedchild, setSelectedChild] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState([]);
  const [selectedPaymentmode, setSelectedPaymentmode] = useState([]);

  const [parentItem, setParentItem] = useState([]);
  const [childItem, setChildItem] = useState([]);
  const [options, setOptions] = useState([]);
  const [roomType, setRoomType] = useState([]);
  const [childoptions, setChildOptions] = useState([]);
  const [subItemsIpData, setSubItemsIpData] = useState([]);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const [applicableOn, setApplicableOn] = useState('');
  const [applicableOnOption, setApplicableOnOption] = useState(null);
  const [group, setGroup] = useState('');
  const [groupLabel, setGroupLabel] = useState(null);
  const [selectedRows, setSelectedRows] = useState([])
  const [ipCommercialItem, setIpCommercialItem] = useState([])

  useEffect(() => {
    let centerDataValue = [...centerData]
    if (propdata.centerNames && propdata.centerNames !== undefined) {
      centerDataValue.push(propdata.centerNames)
    }
    if (propdata.doctorId && propdata.businessunit && propdata.businessunit !== undefined) {
      centerDataValue.push(propdata.businessunit)
    }
    console.log(centerDataValue, centerDataValue.length, propdata.centerNames, "centerDataValue")
    setCenterOptions(centerDataValue);
    // setSelectedCenter(propdata.centerNames)
    for (let i = 0; i < centerList.length; i++) {
      const element = centerList[i];
      if (propdata.centerNames == element.name) {
        getIpItemGroups(element.code)
        getRoomType(element.code)
      }
    }
  }, [data, centerData]);

  useEffect(() => {
    if (doctorData && doctorData.doctorIPParameter) {
      updateIpCommercials(doctorData.doctorIPParameter)
    }
  }, [doctorData]);

  const getIpItemGroups = async (siteCode, itemGroupName = '', itemName = '') => {
    await axios
      .get(APIURL() + "onboard/fetchipItemData/" + siteCode)
      .then((res) => res.data)
      .then(async (rows) => {
        console.log(rows.data, "fetchipItemData");
        await rows.data.forEach(async (element) => {
          if (itemGroupName == element.itemGroupName) {
            await setSelected(itemGroupName);
            await setParentItem(element)
            await getIpSubItems(siteCode, element.itemGroupId, itemName)
          }
          element.selected = false;
        });
        await setOptions(rows.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  const getIpSubItems = async (siteCode, itemGroupId, itemName = '') => {
    var childItem = []
    var selectedItemName = []
    await axios
      .get(APIURL() + "onboard/fetchipSubItemData/" + siteCode + "/" + itemGroupId)
      .then((res) => res.data)
      .then(async (rows) => {
        console.log(rows.data, "fetchipSubItemData");
        await rows.data.forEach((element) => {
          if (element.itemName === itemName) {
            childItem.push(element)
            selectedItemName.push(itemName)
          }
          element.selected = false;
        });
        await setChildOptions(rows.data);
        await setChildItem(childItem);
        await setSelectedChild(selectedItemName);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  const getIPPackages = async (siteCode, itemGroup) => {
    var childItem = []
    await axios
      .post(APIURL() + "onboard/packages", {
        siteCode: siteCode,
        itemGroup: itemGroup,
      })
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows.data, "packagesItemData");
        rows.data.forEach((element) => {
          element.selected = false;
          element.itemName = element.packagename;
          element.itemGroupId = element.packageid;
        });
        setChildOptions(rows.data);
        setChildItem(childItem);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  const getRoomType = async (siteCode) => {
    await axios
      .get(APIURL() + "onboard/bed-categories/" + siteCode)
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows.data, "fetchipSubItemData");
        rows.data.forEach((element) => {
          element.selected = false;
        });
        setRoomType(rows.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  const isAllChildSelected =
    childoptions.length > 0 && childItem.length === childoptions.length;

  const isAllSelectedRoom =
    roomType.length > 0 && selectedRoom.length === roomType.length;
  const isAllSelectedPaymentmode =
    paymentMode.length > 0 && selectedPaymentmode.length === paymentMode.length;
  const isAllSelectedGipsa =
    gipsaOptions.length > 0 && gipsa.length === gipsaOptions.length;

  const parentItemChange = async (event, value) => {
    var itemGroupName = ''
    var siteCode = ''
    for (let i = 0; i < centerList.length; i++) {
      const element = centerList[i];
      if (selectedCenter == element.name) {
        siteCode = element.code
      }
    }
    if (value) {
      setApplicableOn("");
      setApplicableOnOption(null);
      itemGroupName = value.itemGroupName
      // if (applicableOn.includes('Package') || applicableOn.includes('Surgeon')) {
      //   getIPPackages(siteCode, itemGroupName)
      // } else {
      //   getIpSubItems(siteCode, value.itemGroupId)
      // }
      getIpSubItems(siteCode, value.itemGroupId)
      var bed_category = [];
      roomType.forEach((element) => {
        bed_category.push(element.bed_category_name);
      });
      setSelectedRoom(bed_category);
      var modeOfPayment = [];
      paymentMode.forEach((element) => {
        modeOfPayment.push(element.display);
      });
      setSelectedPaymentmode(modeOfPayment);
      var gipsaValue = [];
      await gipsaOptions.forEach((element) => {
        gipsaValue.push(element.value);
      });
      await setGipsa(gipsaValue);
      await setIpservice({ ...ipservice, ['gipsa']: gipsaValue });
    }
    setSelected(itemGroupName);
    setParentItem(value)
  };

  const handleSelectAll = isSelected => {
    if (isSelected) {
      let Itemname = []
      childoptions.forEach((element) => {
        Itemname.push(element.itemName);
      });
      setChildItem(childoptions);
      setSelectedChild(Itemname.length === childoptions.length ? [] : Itemname);
    } else {
      handleClearOptions();
    }
  };

  const handleClearOptions = () => {
    setSelectedChild([])
    setChildItem([])
  };
  const handleToggleOption = selectedOptions => {
    let Itemname = []
    selectedOptions.forEach((element) => {
      Itemname.push(element.itemName);
    });
    setSelectedChild(Itemname);
    setChildItem(selectedOptions);
  }

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!isAllChildSelected);
  };

  const getOptionSelected = (option, anotherOption) => {
    return option.itemName === anotherOption.itemName;
  }

  const filter = createFilterOptions()

  const childItemChange = async (event, selectedOptions, reason) => {
    console.log(reason, selectedOptions, 'childItemChange')
    if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find(option => option.itemName === "Select all")) {
        handleToggleSelectAll();
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };

  console.log(selectedchild, "selectedchild");
  console.log(selected, "selected");
  const handleChangeMulti = (event) => {
    setSelectedChild([]);
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      var k = [];
      ipListData.forEach((element) => {
        k.push(element.display);
      });
      setSelected(selected.length == ipListData.length ? [] : k);
      return;
    }
    setSelected(value);
  };

  const handleChangeIpmonth = (e) => {
    setIpMonth(e.target.value);
  };
  useEffect(() => {
    sendDataTIpParent(iptable, commercialIpTable);
    // .catch((err)=>{
    //   toast.error(err.message);
    // })
  }, [iptable, commercialIpTable]);

  const handleChangeMultiRoom = (event) => {
    setSelectedRoom([]);
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      var k = [];
      roomType.forEach((element) => {
        k.push(element.bed_category_name);
      });
      setSelectedRoom(selectedRoom.length == roomType.length ? [] : k);
      return;
    }
    setSelectedRoom(value);
  };

  const handleChangeMultiPaymentmode = async (event) => {
    setSelectedPaymentmode([]);
    const value = event.target.value;
    console.log(value, 'payment mode value')
    if (value && value.length == 1 && value[0] == 'Cash ') {
      var k = [];
      await gipsaOptions.forEach((element) => {
        k.push(element.value);
      });
      await setGipsa(
        k
      );
      await setIpservice({ ...ipservice, ['gipsa']: gipsa.length == gipsaOptions.length ? [] : k });
    }
    if (value.length == 0 || (value.length == 1 && value[0] == 'all')) {
      await setGipsa(
        []
      );
      await setIpservice({ ...ipservice, ['gipsa']: [] });
    }
    if (value[value.length - 1] === "all") {
      var k = [];
      paymentMode.forEach((element) => {
        k.push(element.display);
      });
      setSelectedPaymentmode(
        selectedPaymentmode.length == paymentMode.length ? [] : k
      );
      await setGipsa(
        []
      );
      await setIpservice({ ...ipservice, ['gipsa']: [] });
      return;
    }
    setSelectedPaymentmode(value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    setPaymentMode([
      { id: 1, name: "Credit ", display: "Credit " },
      { id: 2, name: "Cash ", display: "Cash " },
    ]);
  }, []);

  const handleChangeChkboxforIP = (name) => {
    //  else
    if (chkipList.includes(name)) {
      if (chkipList.length > 1) {
        setIpChild([]);
        var k = [];
        k = chkipList.filter((z) => z != name);
        setChkIpList(k);
      } else {
        var k = [];
        k = chkipList.filter((z) => z != name);
        setChkIpList(k);
      }
    } else {
      if (chkipList.length > 1) {
        setIpChild([]);
        setChkIpList((prevItems) => [...prevItems, name]);
      } else {
        setChkIpList((prevItems) => [...prevItems, name]);
      }
    }
  };
  useEffect(() => {
    sendCalculatedIpData(totalIpRevenueNew);
    // .catch((err)=>{
    //   toast.error(err.message);
    // })
  }, [totalIpRevenueNew]);

  const handleIpserviceInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "ipsurgeonfeeperc") {
      setDisableippackageamountperc(true);
    }
    if (name === "ippackageamountperc") {
      setDisableipsurgeonfeeperc(true);
    }
    setIpservice({ ...ipservice, [name]: value });
  };

  const handleSlab = async (event) => {
    await setSlab(event.target.checked)
    await setIpservice({ ...ipservice, ['slab']: event.target.checked });
    console.log(ipservice, "ipservice")
  };

  const handleGipsa = async (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      var k = [];
      await gipsaOptions.forEach((element) => {
        k.push(element.value);
      });
      await setGipsa(
        gipsa.length == gipsaOptions.length ? [] : k
      );
      await setIpservice({ ...ipservice, ['gipsa']: gipsa.length == gipsaOptions.length ? [] : k });
      return;
    }
    await setGipsa(value);
    await setIpservice({ ...ipservice, ['gipsa']: value });
    console.log(ipservice, "ipservice")
  };

  const handleDeliveryCharges = (event) => {
    const { name, value } = event.target;
    setIpservice({ ...ipservice, [name]: value });
  };

  const handleChnageforSelectAllIP = () => {
    if (selectAllvalIp === false) {
      setChkIpList([]);
      setSelectAllvalIp(true);
      ipListData.map((z) => {
        setChkIpList((prev) => [...prev, z.display]);
      });
    } else {
      setSelectAllvalIp(false);
      setChkIpList([]);
    }
  };

  const handleChnageforSelectDelivery = () => {
    if (selectAllDelivery === false) {
      setIpChild([]);
      setSelectAllDelivery(true);
      ipListData[0].child.map((z) => {
        setIpChild((prev) => [...prev, z.display]);
      });
    } else {
      setSelectAllDelivery(false);
      setIpChild([]);
    }
  };
  const [opRoomType, setOpRoomType] = useState([]);
  const [selectRoomType, setSelectRoomType] = useState(false);
  const handleChangeForRoomTypeAll = () => {
    if (selectRoomType === false) {
      setOpRoomType([]);
      setSelectRoomType(true);
      roomType.map((z) => {
        setOpRoomType((prev) => [...prev, z.name]);
      });
    } else {
      setSelectRoomType(false);
      setOpRoomType([]);
    }
  };

  const [ipMop, setIpMopayment] = useState([]);
  const [selectipMop, setSelectIpMop] = useState(false);
  const handleChangeForMOPTypeAll = () => {
    if (selectipMop === false) {
      setIpMopayment([]);
      setSelectIpMop(true);
      paymentMode.map((z) => {
        setIpMopayment((prev) => [...prev, z.name]);
      });
    } else {
      setSelectIpMop(false);
      setIpMopayment([]);
    }
  };

  const handleChangeForRoomType = (name) => {
    if (opRoomType.includes(name)) {
      var k = [];
      k = opRoomType.filter((z) => z != name);
      setOpRoomType(k);
    } else {
      setOpRoomType((prevItems) => [...prevItems, name]);
    }
  };

  const handleChangeForMop = (name) => {
    if (ipMop.includes(name)) {
      var k = [];
      k = ipMop.filter((z) => z != name);
      setIpMopayment(k);
    } else {
      setIpMopayment((prevItems) => [...prevItems, name]);
    }
  };

  const [selectGynic, setSelectGynic] = useState(false);
  const handleChnageforSelectGynic = () => {
    if (selectGynic === false) {
      setIpChild([]);
      setSelectGynic(true);
      ipListData[1].child.map((z) => {
        setIpChild((prev) => [...prev, z.display]);
      });
    } else {
      setSelectGynic(false);
      setIpChild([]);
    }
  };

  const [selectFertility, setSelectFertility] = useState(false);
  const handleChnageforSelectFertility = () => {
    if (selectFertility === false) {
      setIpChild([]);
      setSelectFertility(true);
      ipListData[2].child.map((z) => {
        setIpChild((prev) => [...prev, z.display]);
      });
    } else {
      setSelectFertility(false);
      setIpChild([]);
    }
  };
  const getIPDiscount = (e) => {
    if (e.target.value === "Not applicable") {
      setIpDiscount("No");
    } else {
      setIpDiscount(e.target.value);
    }
  };
  const getIPDedCat = (e) => {
    setIpdeductionCat(e.target.value);
  };

  const [selectNicu, setSelectNicu] = useState(false);
  const handleChnageforSelectNicu = () => {
    if (selectNicu === false) {
      setIpChild([]);
      setSelectNicu(true);
      ipListData[4].child.map((z) => {
        setIpChild((prev) => [...prev, z.display]);
      });
    } else {
      setSelectNicu(false);
      setIpChild([]);
    }
  };

  const [selectOpenbilling, setSelectOpenBilling] = useState(false);
  const handleChnageforSelectOpenbilling = () => {
    if (selectOpenbilling === false) {
      setIpChild([]);
      setSelectOpenBilling(true);
      ipListData[5].child.map((z) => {
        setIpChild((prev) => [...prev, z.display]);
      });
    } else {
      setSelectOpenBilling(false);
      setIpChild([]);
    }
  };

  const handleChangeChkboxRadiologyChild = (name) => {
    if (radiologyChild.includes(name)) {
      var k = [];
      k = radiologyChild.filter((z) => z != name);
      setRadiologyChild(k);
    } else {
      setRadiologyChild((prevItems) => [...prevItems, name]);
    }
  };

  const handleChangeChkboxIpChild = (name) => {
    if (ipChild.includes(name)) {
      var k = [];
      k = ipChild.filter((z) => z != name);
      setIpChild(k);
    } else {
      setIpChild((prevItems) => [...prevItems, name]);
    }
  };

  //IP CALCULATION FORMULAE
  useEffect(() => {
    calculatePR(iptable);
    // .catch((err)=>{
    //   toast.error(err.message);
    // })
  }, [iptable]);

  const ipCalculation = (event) => {
    // console.log(event, parseFloat(event.target.value), Number(event.target.value), 'eventTarget')
    let packageamount = 5000;
    let surgeonfee = parseFloat(ipservice.ipsurgeonfeeamount);
    var fixedAmount = parseFloat(ipservice.fixedAmount);
    var fixedpercentage = parseFloat(ipservice.fixedpercentage);
    let count = parseFloat(ipservice.count);
    let perunitcharge = parseFloat(ipservice.perunitcharge);
    let disperu = parseFloat(ipservice.ipdeductionperunit);

    if (isNaN(count)) {
      count = 0;
    } else {
    }
    if (isNaN(perunitcharge)) {
      perunitcharge = 0;
    } else {
    }
    if (isNaN(disperu)) {
      disperu = 0;
    } else {
    }

    let deductiontot = disperu * count;
    var grossbilltot = parseFloat(perunitcharge) * count;
    var finalNetbillcount = parseFloat(perunitcharge) * count;
    if (finalNetbillcount && finalNetbillcount > 0) {
      finalNetbillcount = finalNetbillcount - deductiontot
    }
    var ipPayouttot = 0
    if (applicableOn && applicableOn.includes('Fixed')) {
      ipPayouttot = fixedAmount * count
    } else {
      ipPayouttot = grossbilltot * (fixedpercentage / 100)
    }
    var amountip = grossbilltot
    setIpAmountTot(amountip);
    setipdeductiontot(deductiontot);
    setipgrossbillTot(grossbilltot);
    setipNetBillAmountTot(finalNetbillcount);
    setIpPayoutTotal(ipPayouttot);



    // if (isNaN(packageamount)) {
    //   packageamount = 0;
    // } else {
    // }
    // //count value checking
    // if (isNaN(surgeonfee)) {
    //   surgeonfee = 0;
    // } else {
    // }
    // if (isNaN(count)) {
    //   count = 0;
    // } else {
    // }

    // // let GrossPercentage = packageamount
    // //   ? packageamount * count
    // //   : surgeonfee * count;

    // // if (ipservice.ipdiscountType === "No") {
    // //   setGrossIp(GrossPercentage);
    // // } else {
    // //   setnetIp(GrossPercentage)
    // // }

    // let grossbilip = parseFloat(ipservice.ipgrossbillperunit);
    // let deductaionvalip = parseFloat(ipservice.ipdeductaionvalue) * count;
    // let discountvalip = parseFloat(ipservice.ipdiscountvalue) * count;
    // let grossbilltot = parseFloat(ipservice.ipgrossbillperunit) * count;

    // let netbillpercent = parseFloat(ipservice.ipnetbillpercentage);

    // if (isNaN(grossbilltot)) {
    //   grossbilltot = 0;
    // } else {
    // }

    // setipgrossbillTot(grossbilltot);



    // if (isNaN(grossbilip)) {
    //   grossbilip = 0;
    // } else {
    // }
    // if (isNaN(deductaionvalip)) {
    //   deductaionvalip = 0;
    // } else {
    // }
    // if (isNaN(discountvalip)) {
    //   discountvalip = 0;
    // } else {
    // }

    // let finalNetbillcount =
    //   netbillpercent ? grossbilltot - deductiontot :
    //     discountvalip === 0 && deductiontot === 0
    //       ? 0
    //       : discountvalip === 0
    //         ? grossbilltot - deductiontot
    //         : deductiontot === 0
    //           ? grossbilltot - discountvalip
    //           : grossbilltot - discountvalip - deductiontot;

    // setipNetBillAmountTot(finalNetbillcount);

    // // let grossipAmount = grossbilip * count;
    // let surgoenipAmount = surgeonfee * count;
    // let netAmountip = finalNetbillcount * count;
    // let amountval = 0;
    // if (event.target.name === "ipfixedamount") {
    //   amountval = parseFloat(ipservice.ipfixedamount);
    // }

    // if (isNaN(amountval)) {
    //   amountval = 0;
    // } else {
    // }

    // let fixedAmount = amountval * count;
    // console.log(finalNetbillcount, ipservice, deductiontot, fixedAmount, amountval, "finalNetbillcount");

    // let amountip = finalNetbillcount
    //   ? finalNetbillcount
    //   : // ? finalNetbillcount * count
    //   grossbilip
    //     ? grossbilltot
    //     : surgeonfee
    //       ? surgoenipAmount
    //       : ipNetBillAmountTot === 0 && packageamount === 0
    //         ? surgeonfee * count
    //         : ipNetBillAmountTot === 0 && surgeonfee === 0
    //           ? 5000 * count
    //           : 0;
    // console.log(amountip, "amountip");
    // setIpAmountTot(amountip);

    // let surgeopnfeeperc = parseFloat(ipservice.ipsurgeonfeeperc);

    // let packageamountpercnt = parseFloat(ipservice.ippackageamountperc);
    // if (isNaN(surgeopnfeeperc)) {
    //   surgeopnfeeperc = 0;
    // } else {
    // }
    // if (isNaN(packageamountpercnt)) {
    //   packageamountpercnt = 0;
    // } else {
    // }
    // if (isNaN(netbillpercent)) {
    //   netbillpercent = 0;
    // } else {
    // }
    // let grossbillperc = parseFloat(ipservice.ipgrossbillpercentage);
    // let surgeopnfeeperccalculate = (surgoenipAmount * surgeopnfeeperc) / 100;
    // if (isNaN(grossbillperc)) {
    //   grossbillperc = 0;
    // } else {
    // }
    // // let surgeonpayoutip = surgeopnfeeperccalculate * amountip;
    // let packageamountpercntcalculate =
    //   packageamount * count * (packageamountpercnt / 100);
    // let grossbillcal = (grossbilltot - ipdeductiontot) * (grossbillperc / 100);
    // let netbillpercntage =
    //   (grossbilltot - ipdeductiontot) * (netbillpercent / 100);
    // // let netbillpercntage = netAmountip * (netbillpercent / 100);

    // //netAmountip

    // let ipPayouttot = fixedAmount
    //   ? fixedAmount
    //   : netbillpercntage
    //     ? netbillpercntage
    //     : surgeopnfeeperccalculate
    //       ? surgeopnfeeperccalculate
    //       : surgeopnfeeperc === 0 && packageamountpercnt === 0
    //         ? grossbillcal
    //         : grossbillperc === 0 && packageamountpercnt === 0
    //           ? netbillpercntage * amountip
    //           : surgeopnfeeperc === 0 && finalNetbillcount === 0
    //             ? packageamountpercntcalculate
    //             : packageamountpercnt === 0 && finalNetbillcount === 0;

    // setIpPayoutTotal(ipPayouttot);

    // let GrossPercentage = grossbillperc
    //   ? grossbilltot
    //   : netbillpercntage
    //     ? grossbilltot
    //     : surgeonfee * count
    //       ? surgeonfee * count
    //       : packageamount * count;

    // console.log(ipdiscount, "ipservice.ipdiscountType");
    // if (ipdiscount === "Not applicable" || ipdiscount === "No") {
    //   setGrossIp(GrossPercentage);
    //   setnetIp("");
    // } else {
    //   setGrossIp("");
    //   setnetIp(GrossPercentage);
    // }
  };
  console.log(selected, "selectedchildselectedchild")

  const validationIp = async () => {
    if (selected == 'OT Surgeon Charges' && (group == '' || group == null || group == undefined)) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please Select Group",
      });
      return false;
    }
    if (applicableOn == '' || applicableOn == null || applicableOn == undefined) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please Select Applicable On",
      });
      return false;
    }
    if (selected == '' || selected == null || selected == undefined) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please select Item Group",
      });
      return false;
    }
    if (selectedRoom.length <= 0) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please select Room Type",
      });
      return false;
    }
    if (selectedPaymentmode.length <= 0) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please select mode of payment",
      });
      return false;
    }
    if (gipsa.length <= 0) {
      setConfirmDialog({
        isOpen: true,
        title: "Alert",
        subtitle: "Please select gipsa option",
      });
      return false;
    }
    return true;
  }

  const addIpTableData = () => {
    var valid = validationIp()
    if (!valid) {
      return;
    }

    console.log(selected, "selected array");
    ipservice.ipmonth = ipmonth;
    ipservice.applicableOn = applicableOn;
    ipservice.consultation = Consultation;
    ipservice.itemGroup = selected;
    ipservice.group = group;
    ipservice.iproom = selectedRoom.join();
    ipservice.gipsa = gipsa.join();
    ipservice.ipmodeofpayment = selectedPaymentmode.join();
    ipservice.netperc = netC;
    ipservice.ipgrossperc = grossIp;
    ipservice.ipnetperc = netIp;
    ipservice.payout = ipPayoutTotal;
    ipservice.revenue = grossIp;
    ipservice.ipTotalAmount = ipAmountTot;
    ipservice.deductionCat = ipdeductionCat;
    ipservice.ipdiscountType =
      ipdiscount === "Not applicable" ? "No" : ipdiscount;
    ipservice.ipTotalNetBillAmount = ipNetBillAmountTot;
    ipservice.ipTotalGrossNillAmount = ipgrossbillTot;
    ipservice.ipfixedamount = ipservice.ipfixedamount
      ? ipservice.ipfixedamount
      : 0;
    ipservice.fixedAmount = ipservice.fixedAmount
      ? ipservice.fixedAmount
      : 0;
    ipservice.fixedpercentage = ipservice.fixedpercentage
      ? ipservice.fixedpercentage
      : 0;
    ipservice.ratio = ipservice.fixedpercentage
      ? ipservice.fixedpercentage
      : 0;
    ipservice.ipdiscountvalue = ipservice.ipdiscountvalue
      ? ipservice.ipdiscountvalue
      : 0;
    ipservice.ipdeductaionvalue = ipdeductiontot;
    ipservice.ipdeductionperunit = ipservice.ipdeductionperunit
      ? ipservice.ipdeductionperunit
      : 0;
    ipservice.ipsurgeonfeeperc = ipservice.ipsurgeonfeeperc
      ? ipservice.ipsurgeonfeeperc
      : 0;
    ipservice.ipgrossbillpercentage = ipservice.ipgrossbillpercentage
      ? ipservice.ipgrossbillpercentage
      : 0;
    ipservice.ippackageamountperc = ipservice.ippackageamountperc
      ? ipservice.ippackageamountperc
      : 0;
    ipservice.ipnetbillpercentage = ipservice.ipnetbillpercentage
      ? ipservice.ipnetbillpercentage
      : 0;
    console.log(ipservice, iptable, "ipserviceipserviceipservice");
    var commercialTerm = ''
    commercialTerm = ipservice.applicableOn.includes('Fixed') ? "Fixed Amount of Rs. " + ipservice.fixedAmount + " /- Per case" : ipservice.applicableOn == 'Gross' ? ipservice.fixedpercentage + " % of the Gross Item Amount" : ipservice.applicableOn == 'Net' ? ipservice.fixedpercentage + " % of the Net Item Amount" : ipservice.applicableOn == 'Open Bill Gross' ? ipservice.fixedpercentage + " % of the Gross Bill Amount" : ipservice.applicableOn == 'Open Bill Net' ? ipservice.fixedpercentage + " % of the Net Bill Amount" : ipservice.applicableOn == 'Surgeon' ? ipservice.fixedpercentage + " % of the Surgeon Fee" : ipservice.applicableOn == 'Package' ? ipservice.fixedpercentage + " % of the Package Amount" : ipservice.applicableOn == 'Consultation' ? ipservice.fixedpercentage + " % of the Consutation Fee" : ''

    if (commercialTerm != "NA") {
      if (selectedPaymentmode && selectedPaymentmode.length == 1 && selectedPaymentmode[0] == 'Cash ') {
      } else {
        if (ipservice.gipsa && ipservice.gipsa.includes('Yes') && ipservice.gipsa.includes('No')) {
          commercialTerm = commercialTerm + " for Gipsa and Non Gipsa cases";
        } else if (ipservice.gipsa && ipservice.gipsa.includes('Yes') && !ipservice.gipsa.includes('No')) {
          commercialTerm = commercialTerm + " for Gipsa cases";
        }
      }
      if (ipservice.slab) {
        commercialTerm = commercialTerm + " for " + ipservice.count + " and more than " + ipservice.count + " procedures";
      } else {
        ipservice.slab = false
      }
    }
    ipservice.commercialTerm = commercialTerm
    let ipserviceDataArray = [];
    let opRevenueLocal = 0;
    let opPayoutLocal = 0;
    if (selectedchild.length > 0 && selected) {
      for (let k = 0; k < selectedchild.length; k++) {
        for (let m = 0; m < iptable.length; m++) {
          if (
            group + "," + selected + "," + selectedchild[k] + "," + selectedRoom.join() + "," + selectedPaymentmode.join() + "," + gipsa.join() + "," + selectedCenter + "," + (slab && slab == true ? ('true,' + ipservice.count) : 'false') ===
            iptable[m].group + "," + iptable[m].itemGroup + "," + iptable[m].itemName + "," + iptable[m].iproom + "," + iptable[m].ipmodeofpayment + "," + iptable[m].gipsa + "," + iptable[m].Center + "," + (iptable[m].slab && iptable[m].slab == true ? ('true,' + iptable[m].count) : 'false')
          ) {
            setConfirmDialog({
              isOpen: true,
              title: "Alert",
              subtitle:
                iptable[m].group +
                " and " +
                iptable[m].itemGroup +
                " and " +
                iptable[m].itemName +
                " and " +
                iptable[m].iproom +
                " for MOP " +
                iptable[m].ipmodeofpayment +
                " for Gipsa " +
                iptable[m].gipsa +
                " for Center " +
                iptable[m].Center +
                " already exist.",
            });
            setSelectedChild([]);
            return;
          }
        }
        if (selectedchild.length > 0) {
          ipservice.itemGroup = selected;
        }
        let ipserviceData = {
          ...ipservice,
          itemGroup: selected,
          itemName: selectedchild[k],
          Center: selectedCenter
        };
        // let ipserviceData = { ...ipservice, itemGroup: selectedchild[k] };
        ipserviceDataArray.push(ipserviceData);
      }
    } else {
      for (let m = 0; m < iptable.length; m++) {
        console.log((iptable[m].group + "," + iptable[m].itemGroup + "," + iptable[m].itemName + "," + iptable[m].iproom + "," + iptable[m].ipmodeofpayment + "," + iptable[m].gipsa + "," + iptable[m].Center + "," + iptable[m].slab), " --- ", (group + "," + selected + ",null," + selectedRoom.join() + "," + selectedPaymentmode.join() + "," + gipsa.join() + "," + selectedCenter + "," + slab), "data11111");
        if (
          group + "," + selected + ",null," + selectedRoom.join() + "," + selectedPaymentmode.join() + "," + gipsa.join() + "," + selectedCenter + "," + (slab && slab == true ? ('true,' + ipservice.count) : 'false') ===
          iptable[m].group + "," + iptable[m].itemGroup + "," + iptable[m].itemName + "," + iptable[m].iproom + "," + iptable[m].ipmodeofpayment + "," + iptable[m].gipsa + "," + iptable[m].Center + "," + (iptable[m].slab && iptable[m].slab == true ? ('true,' + iptable[m].count) : 'false')
        ) {
          setConfirmDialog({
            isOpen: true,
            title: "Alert",
            subtitle:
              iptable[m].group +
              " and " +
              iptable[m].itemGroup +
              " and " +
              iptable[m].iproom +
              " for MOP " +
              iptable[m].ipmodeofpayment +
              " for Gipsa " +
              iptable[m].gipsa +
              " for Center " +
              iptable[m].Center +
              " already exist.",
          });
          return;
        }
      }
      if (selected.length > 0) {
        // if (selectedchild.length > 0) {
        ipservice.itemGroup = selected;
      }
      console.log(selected, "selected[i]");
      let ipserviceData = { ...ipservice, itemGroup: selected, itemName: null, Center: selectedCenter };
      // let ipserviceData = { ...ipservice, itemGroup: selected[i] };
      ipserviceDataArray.push(ipserviceData);
    }

    console.log(ipserviceDataArray, "data11111");
    createCommercialIp(ipserviceDataArray);
    setSelected('');
    setParentItem([])
    setSelectedChild([]);
    setIptable((prevItems) => [...prevItems, ...ipserviceDataArray]);
    setIpservice(initialFormtStateipservice);
    setSelectIpMop(false);
    setSelectedRoom([]);
    setSelectedPaymentmode([]);
    setSelectRoomType(false);
    setIpMopayment([]);
    // setSelectIpMop([]);
    setOpRoomType([]);
    setChildItem([])
    setChildOptions([])
    setPackage("");
    setSlab(false);
    setGipsa([]);
    setNetC("");
    setGross("");
    setIpRoom("");
    setIpMop("");
    setFixedPay("");
    setpayout("");
    setAdhoc("");
    setAmount("");
    setApplicableOn("");
    setApplicableOnOption(null);
    setGroupLabel(null)
    setGroup('')
  };

  const calculatePR = (array) => {
    console.log(array.length);
    let payout = 0;
    let revenue = 0;
    array.forEach((element) => {
      payout += new Number(element.payout);
      revenue += new Number(element.revenue);

      console.log(payout, revenue);
    });

    setTotalIpPayoutNew(payout);
    setTotalIpRevenueNew(revenue);
  };

  const createCommercialIp = (datalist) => {
    console.log(datalist, 'datalist')
    datalist.map((ele) => {
      let ob = {};
      let group = ele.group;
      let itemgroup = ele.itemGroup;
      let itemName = ele.itemName;
      let ipmodeofpayment = ele.ipmodeofpayment;
      let iproom = ele.iproom;
      let Center = ele.Center
      var commercialterm = "";
      commercialterm = ele.applicableOn.includes('Fixed') ? "Fixed Amount of Rs. " + ele.fixedAmount + " /- Per case" : ele.applicableOn == 'Open Bill Gross' ? ele.fixedpercentage + " % of the Gross Bill Amount" : ele.applicableOn == 'Open Bill Net' ? ele.fixedpercentage + " % of the Net Bill Amount" : ele.applicableOn == 'Gross' ? ele.fixedpercentage + " % of the Gross Item Amount" : ele.applicableOn == 'Net' ? ele.fixedpercentage + " % of the Net Item Amount" : ele.applicableOn == 'Surgeon' ? ele.fixedpercentage + " % of the Surgeon Fee" : ele.applicableOn == 'Package' ? ele.fixedpercentage + " % of the Package Amount" : ele.applicableOn == 'Consultation' ? ele.fixedpercentage + " % of the Consutation Fee" : ''

      if (commercialterm != "NA") {
        if (selectedPaymentmode && selectedPaymentmode.length == 1 && selectedPaymentmode[0] == 'Cash ') {
        } else {
          if (ele.gipsa && ele.gipsa.includes('Yes') && ele.gipsa.includes('No')) {
            commercialterm = commercialterm + " for Gipsa and Non Gipsa cases";
          } else if (ele.gipsa && ele.gipsa.includes('Yes') && !ele.gipsa.includes('No')) {
            commercialterm = commercialterm + " for Gipsa cases";
          }
        }
        if (ele.slab) {
          commercialterm = commercialterm + " for " + ele.count + " and more than " + ele.count + " procedures";
        }
        ob = {
          ...ob,
          itemgroup,
          itemName,
          ipmodeofpayment,
          iproom,
          commercialterm,
          Center,
          group
        };
        commercialIpTable.push(ob);
      }
    });
  };
  const RefreshIpRecord = () => {
    setChkIpList([]);
    setSelected('');
    setParentItem([])
    setSelectedChild([]);
    setChildItem([])
    setChildOptions([])
    setIpMopayment([]);
    setSelectedRoom([]);
    setSelectedPaymentmode([]);
    setIpChild([]);
    setIpAmountTot(0);
    setIpPayoutTotal(0);
    setipNetBillAmountTot(0);
    // var dropDown1 = document.getElementById("MonthCount");
    // dropDown1.selectedIndex = 0;
    // var dropDown = document.getElementById("Discount");
    // dropDown.selectedIndex = "Not applicable";
    setIpDiscount("Not applicable");
    setIpservice(initialFormtStateipservice);
    setPackage("");
    setIpRoom("room");
    setIpMop("");
    setSlab(false);
    setGross("");
    setFinalpayout("");
    setNetC("");
    setipgrossbillTot("");
    setipdeductiontot("");
    setApplicableOnOption(null)
    setApplicableOn('')
    setGroupLabel(null)
    setGroup('')
    setSelectedCenter('')
  };

  const deleteIptableRow = async (row, Index, event) => {
    // var k = window.confirm("Are you sure want to delete this record ?");
    // if (k) {
    console.log(row, event, Index, iptable, commercialIpTable, "dhdhdhdhdh")
    var data = [...iptable]
    var data1 = [...commercialIpTable]
    var ids = []
    row.map(elem => {
      ids.push(elem.tableData.id)
      const index = totalipRevenue.indexOf(elem.revenue);
      if (index > -1) {
        totalipRevenue.splice(index, 1);
      }
      const index1 = totalippayout.indexOf(elem.payout);
      if (index1 > -1) {
        totalippayout.splice(index1, 1);
      }
    })
    data = data.filter((z, i) => {
      if (!ids.includes(i)) {
        return z
      }
    })
    data1 = data1.filter((z, i) => {
      if (!ids.includes(i)) {
        return z
      }
    })
    console.log(data, data1, "dhdhdhdhdh")
    // return false
    setIptable(data);
    calculatePR(data);
    setCommercialIpTable(data1);
    // }
  };
  const onlyNumbers = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
  };
  const TableTitle = ({ text }) => (
    <Typography
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "black",
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "15px"
      }}
    >
      {text}
    </Typography>
  );
  const nop = <p>No. of Procedures<span style={{ color: "red" }} > *</span></p>;
  const parentItemChangeCenter = async (event, value) => {
    for (let i = 0; i < centerList.length; i++) {
      const element = centerList[i];
      if (value == element.name) {
        getIpItemGroups(element.code)
        getRoomType(element.code)
      }
    }
    setSelectedCenter(value);
    setParentItem('');
    setSelectedChild([])
    setChildItem([])
  };

  const applicableOnChange = async (event, value) => {
    console.log(value, 'applicableon')
    if (value) {
      setApplicableOnOption(value)
      setApplicableOn(value.value)
      if (value.value == 'Fixed') {
        setIpservice({ ...ipservice, ipfixedpercentage: 0 });
      } else {
        setIpservice({ ...ipservice, ipfixedamount: 0 });
      }
      var siteCode = ''
      for (let i = 0; i < centerList.length; i++) {
        const element = centerList[i];
        if (selectedCenter == element.name) {
          siteCode = element.code
        }
      }
      if (selected) {
        // if (value.value.includes('Package') || value.value.includes('Surgeon')) {
        //   getIPPackages(siteCode, selected)
        // } else {
        //   getIpSubItems(siteCode, parentItem.itemGroupId)
        // }
        getIpSubItems(siteCode, parentItem.itemGroupId)
      }
    } else {
      setApplicableOnOption(value)
      setApplicableOn('')
      setIpservice({ ...ipservice, ipfixedpercentage: 0 });
      setIpservice({ ...ipservice, ipfixedamount: 0 });
    }
  }

  const groupChange = async (event, value) => {
    if (value) {
      setGroupLabel(value)
      setGroup(value.value)
    } else {
      setGroupLabel(null)
      setGroup('')
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setIpservice({ ...ipservice, [name]: value });
  };

  const fixedPercentageOrAmount = (e) => {
    if (e.target.name === "fixedpercentage") {
      setIpservice({ ...ipservice, fixedAmount: 0 });
    }
    if (e.target.name === "fixedAmount") {
      setIpservice({ ...ipservice, fixedpercentage: 0 });
    }
  };

  const handleSelectionRows = async (rows) => {
    setSelectedRows(rows)
  }

  const updateIpCommercials = async (commercials) => {
    commercials.forEach((item) => {
      item.iproom = item.roomCategory
      item.gipsa = item.Gipsa
      item.fixedpercentage = item.fixedPercentage
      item.ipTotalGrossNillAmount = item.ipTotalAmount
    })
    await setIptable((prevItems) => [...prevItems, ...commercials])
    await createCommercialIp(commercials);
  }

  const editCommercial = async (row) => {
    var item = row[0]
    console.log(row, 'Ipcommercials Data')
    var siteCode = ''
    var itemGroupName
    for (let i = 0; i < centerList.length; i++) {
      const element = centerList[i];
      if (item.Center == element.name) {
        siteCode = element.code
        await setSelectedCenter(item.Center);
        itemGroupName = item.itemGroup
        await getRoomType(element.code)
        await getIpItemGroups(element.code, itemGroupName, item.itemName)
        // await setSelected(itemGroupName);
        console.log(options, 'Opcommercials Data options')
      }
    }
    await setGroup(item.group)
    await setGroupLabel(groups.find(group => group.value == item.group))
    await setApplicableOn(item.applicableOn);
    await setSelectedRoom(item.iproom ? (item.iproom).split(',') : []);
    await setSelectedPaymentmode(item.ipmodeofpayment ? (item.ipmodeofpayment).split(',') : []);
    await setGipsa(item.Gipsa ? (item.Gipsa).split(',') : ['Yes', 'No']);
    await setApplicableOnOption(applicableOnOptions.find((elem) => elem.value == item.applicableOn));
    await setIpMonth(item.ipmonth);
    await setIpDiscount(item.ipdiscountType);
    await setIpdeductionCat(item.deductionCat);
    await setIpAmountTot(item.ipTotalAmount);
    await setipdeductiontot(item.ipdeductionperunit * item.count);
    await setipgrossbillTot(item.ipgrossperc);
    await setipNetBillAmountTot(item.revenue);
    await setIpPayoutTotal(item.payout);
    await setSlab(item.slab);
    ipservice.count = item.count
    ipservice.gipsa = item.Gipsa
    ipservice.slab = item.slab
    ipservice.iproom = item.roomCategory
    ipservice.perunitcharge = item.ipTotalAmount / item.count
    ipservice.ipdeductionperunit = item.ipdeductionperunit
    ipservice.fixedpercentage = item.fixedpercentage
    ipservice.fixedAmount = item.fixedAmount
    await setIpservice({ ...ipservice });
    await setIpCommercialItem(row)
    await ipCalculation()
  }

  const UpdateTableData = async () => {
    var valid = validationIp()
    if (!valid) {
      return;
    }

    ipservice.ipmonth = ipmonth;
    ipservice.applicableOn = applicableOn;
    ipservice.consultation = Consultation;
    ipservice.itemGroup = selected;
    ipservice.group = group;
    ipservice.iproom = selectedRoom.join();
    ipservice.gipsa = gipsa.join();
    ipservice.ipmodeofpayment = selectedPaymentmode.join();
    ipservice.netperc = netC;
    ipservice.ipgrossperc = grossIp;
    ipservice.ipnetperc = netIp;
    ipservice.payout = ipPayoutTotal;
    ipservice.revenue = grossIp;
    ipservice.ipTotalAmount = ipAmountTot;
    ipservice.deductionCat = ipdeductionCat;
    ipservice.ipdiscountType =
      ipdiscount === "Not applicable" ? "No" : ipdiscount;
    ipservice.ipTotalNetBillAmount = ipNetBillAmountTot;
    ipservice.ipTotalGrossNillAmount = ipgrossbillTot;
    ipservice.ipfixedamount = ipservice.ipfixedamount
      ? ipservice.ipfixedamount
      : 0;
    ipservice.fixedAmount = ipservice.fixedAmount
      ? ipservice.fixedAmount
      : 0;
    ipservice.fixedpercentage = ipservice.fixedpercentage
      ? ipservice.fixedpercentage
      : 0;
    ipservice.ratio = ipservice.fixedpercentage
      ? ipservice.fixedpercentage
      : 0;
    ipservice.ipdiscountvalue = ipservice.ipdiscountvalue
      ? ipservice.ipdiscountvalue
      : 0;
    ipservice.ipdeductaionvalue = ipdeductiontot;
    ipservice.ipdeductionperunit = ipservice.ipdeductionperunit
      ? ipservice.ipdeductionperunit
      : 0;
    ipservice.ipsurgeonfeeperc = ipservice.ipsurgeonfeeperc
      ? ipservice.ipsurgeonfeeperc
      : 0;
    ipservice.ipgrossbillpercentage = ipservice.ipgrossbillpercentage
      ? ipservice.ipgrossbillpercentage
      : 0;
    ipservice.ippackageamountperc = ipservice.ippackageamountperc
      ? ipservice.ippackageamountperc
      : 0;
    ipservice.ipnetbillpercentage = ipservice.ipnetbillpercentage
      ? ipservice.ipnetbillpercentage
      : 0;
    console.log(ipservice, "ipserviceipserviceipservice");
    let ipserviceDataArray = iptable;
    var commercialIpTableData = [...commercialIpTable]
    let opRevenueLocal = 0;
    let opPayoutLocal = 0;
    var commercialterm = "";
    commercialterm = ipservice.applicableOn.includes('Fixed') ? "Fixed Amount of Rs. " + ipservice.fixedAmount + " /- Per case" : ipservice.applicableOn == 'Open Bill Gross' ? ipservice.fixedpercentage + " % of the Gross Bill Amount" : ipservice.applicableOn == 'Open Bill Net' ? ipservice.fixedpercentage + " % of the Net Bill Amount" : ipservice.applicableOn == 'Gross' ? ipservice.fixedpercentage + " % of the Gross Item Amount" : ipservice.applicableOn == 'Net' ? ipservice.fixedpercentage + " % of the Net Item Amount" : ipservice.applicableOn == 'Surgeon' ? ipservice.fixedpercentage + " % of the Surgeon Fee" : ipservice.applicableOn == 'Package' ? ipservice.fixedpercentage + " % of the Package Amount" : ipservice.applicableOn == 'Consultation' ? ipservice.fixedpercentage + " % of the Consutation Fee" : ''

    if (commercialterm != "NA") {
      if (selectedPaymentmode && selectedPaymentmode.length == 1 && selectedPaymentmode[0] == 'Cash ') {
      } else {
        if (ipservice.gipsa && ipservice.gipsa.includes('Yes') && ipservice.gipsa.includes('No')) {
          commercialterm = commercialterm + " for Gipsa and Non Gipsa cases";
        } else if (ipservice.gipsa && ipservice.gipsa.includes('Yes') && !ipservice.gipsa.includes('No')) {
          commercialterm = commercialterm + " for Gipsa cases";
        }
      }
      if (ipservice.slab) {
        commercialterm = commercialterm + " for " + ipservice.count + " and more than " + ipservice.count + " procedures";
      }
    }
    ipservice.commercialTerm = commercialterm
    let tableIndex = iptable.findIndex((row) => ipCommercialItem && ipCommercialItem[0] && ipCommercialItem[0].tableData.id === row.tableData.id)
    if (selectedchild.length > 0 && selected) {
      for (let k = 0; k < selectedchild.length; k++) {
        for (let m = 0; m < iptable.length; m++) {
          if (ipCommercialItem && ipCommercialItem[0] && ipCommercialItem[0].tableData.id !== iptable[m].tableData.id) {
            if (
              group + "," + selected + "," + selectedchild[k] + "," + selectedRoom.join() + "," + selectedPaymentmode.join() + "," + gipsa.join() + "," + selectedCenter + "," + (slab && slab == true ? ('true,' + ipservice.count) : 'false') ===
              iptable[m].group + "," + iptable[m].itemGroup + "," + iptable[m].itemName + "," + iptable[m].iproom + "," + iptable[m].ipmodeofpayment + "," + iptable[m].gipsa + "," + iptable[m].Center + "," + (iptable[m].slab && iptable[m].slab == true ? ('true,' + iptable[m]) : 'false')
            ) {
              setConfirmDialog({
                isOpen: true,
                title: "Alert",
                subtitle:
                  iptable[m].group +
                  " and " +
                  iptable[m].itemGroup +
                  " and " +
                  iptable[m].itemName +
                  " and " +
                  iptable[m].iproom +
                  " for MOP " +
                  iptable[m].ipmodeofpayment +
                  " for Gipsa " +
                  iptable[m].gipsa +
                  " for Center " +
                  iptable[m].Center +
                  " already exist.",
              });
              setSelectedChild([]);
              return;
            }
          }
        }
        if (selectedchild.length > 0) {
          ipservice.itemGroup = selected;
        }
        let ipserviceData = {
          ...ipservice,
          itemGroup: selected,
          itemName: selectedchild[k],
          Center: selectedCenter
        };
        if (k === 0) {
          ipserviceDataArray[tableIndex].ipmonth = ipservice.ipmonth;
          ipserviceDataArray[tableIndex].applicableOn = ipservice.applicableOn;
          ipserviceDataArray[tableIndex].itemGroup = selected;
          ipserviceDataArray[tableIndex].itemName = selectedchild[k];
          ipserviceDataArray[tableIndex].Center = selectedCenter;
          ipserviceDataArray[tableIndex].group = ipservice.group;
          ipserviceDataArray[tableIndex].iproom = ipservice.iproom;
          ipserviceDataArray[tableIndex].gipsa = ipservice.gipsa;
          ipserviceDataArray[tableIndex].ipmodeofpayment = ipservice.ipmodeofpayment;
          ipserviceDataArray[tableIndex].netperc = ipservice.netperc;
          ipserviceDataArray[tableIndex].ipgrossperc = ipservice.ipgrossperc;
          ipserviceDataArray[tableIndex].ipnetperc = ipservice.ipnetperc;
          ipserviceDataArray[tableIndex].payout = ipservice.payout;
          ipserviceDataArray[tableIndex].revenue = ipservice.revenue;
          ipserviceDataArray[tableIndex].ipTotalAmount = ipservice.ipTotalAmount;
          ipserviceDataArray[tableIndex].deductionCat = ipservice.deductionCat;
          ipserviceDataArray[tableIndex].ipTotalNetBillAmount = ipservice.ipTotalNetBillAmount;
          ipserviceDataArray[tableIndex].ipTotalGrossNillAmount = ipservice.ipTotalGrossNillAmount;
          ipserviceDataArray[tableIndex].ipfixedamount = ipservice.ipfixedamount;
          ipserviceDataArray[tableIndex].fixedAmount = ipservice.fixedAmount;
          ipserviceDataArray[tableIndex].fixedpercentage = ipservice.fixedpercentage;
          ipserviceDataArray[tableIndex].ratio = ipservice.ratio;
          ipserviceDataArray[tableIndex].ipdiscountvalue = ipservice.ipdiscountvalue;
          ipserviceDataArray[tableIndex].ipdeductaionvalue = ipservice.ipdeductaionvalue;
          ipserviceDataArray[tableIndex].ipdeductionperunit = ipservice.ipdeductionperunit;
          ipserviceDataArray[tableIndex].ipsurgeonfeeperc = ipservice.ipsurgeonfeeperc;
          ipserviceDataArray[tableIndex].ipgrossbillpercentage = ipservice.ipgrossbillpercentage;
          ipserviceDataArray[tableIndex].ippackageamountperc = ipservice.ippackageamountperc;
          ipserviceDataArray[tableIndex].ipnetbillpercentage = ipservice.ipnetbillpercentage;
          ipserviceDataArray[tableIndex].commercialTerm = commercialterm;
          ipserviceDataArray[tableIndex].count = ipservice.count;
          ipserviceDataArray[tableIndex].slab = ipservice.slab ? true : false;

          commercialIpTableData[tableIndex].itemgroup = selected
          commercialIpTableData[tableIndex].itemName = selectedchild[k]
          commercialIpTableData[tableIndex].ipmodeofpayment = ipservice.ipmodeofpayment
          commercialIpTableData[tableIndex].iproom = ipservice.iproom
          commercialIpTableData[tableIndex].commercialterm = commercialterm
          commercialIpTableData[tableIndex].Center = selectedCenter
          commercialIpTableData[tableIndex].group = ipservice.group
        } else {
          // let ipserviceData = { ...ipservice, itemGroup: selectedchild[k] };
          ipserviceDataArray.push(ipserviceData);
        }
      }
    } else {
      for (let m = 0; m < iptable.length; m++) {
        if (ipCommercialItem && ipCommercialItem[0] && ipCommercialItem[0].tableData.id !== iptable[m].tableData.id) {
          if (
            group + "," + selected + ",null," + selectedRoom.join() + "," + selectedPaymentmode.join() + "," + gipsa.join() + "," + selectedCenter + "," + (slab && slab == true ? ('true,' + ipservice.count) : 'false') ===
            iptable[m].group + "," + iptable[m].itemGroup + "," + iptable[m].itemName + "," + iptable[m].iproom + "," + iptable[m].ipmodeofpayment + "," + iptable[m].gipsa + "," + iptable[m].Center + "," + (iptable[m].slab && iptable[m].slab == true ? ('true,' + iptable[m]) : 'false')
          ) {
            setConfirmDialog({
              isOpen: true,
              title: "Alert",
              subtitle:
                iptable[m].group +
                " and " +
                iptable[m].itemGroup +
                " and " +
                iptable[m].iproom +
                " for MOP " +
                iptable[m].ipmodeofpayment +
                " for Gipsa " +
                iptable[m].gipsa +
                " for Center " +
                iptable[m].Center +
                " already exist.",
            });
            return;
          }
        }
      }
      if (selected.length > 0) {
        // if (selectedchild.length > 0) {
        ipservice.itemGroup = selected;
      }
      console.log(selected, "selected[i]");
      let ipserviceData = { ...ipservice, itemGroup: selected, Center: selectedCenter };
      // let ipserviceData = { ...ipservice, itemGroup: selected[i] };
      ipserviceDataArray[tableIndex].ipmonth = ipservice.ipmonth;
      ipserviceDataArray[tableIndex].applicableOn = ipservice.applicableOn;
      ipserviceDataArray[tableIndex].itemGroup = selected;
      ipserviceDataArray[tableIndex].itemName = null;
      ipserviceDataArray[tableIndex].Center = selectedCenter;
      ipserviceDataArray[tableIndex].group = ipservice.group;
      ipserviceDataArray[tableIndex].iproom = ipservice.iproom;
      ipserviceDataArray[tableIndex].gipsa = ipservice.gipsa;
      ipserviceDataArray[tableIndex].ipmodeofpayment = ipservice.ipmodeofpayment;
      ipserviceDataArray[tableIndex].netperc = ipservice.netperc;
      ipserviceDataArray[tableIndex].ipgrossperc = ipservice.ipgrossperc;
      ipserviceDataArray[tableIndex].ipnetperc = ipservice.ipnetperc;
      ipserviceDataArray[tableIndex].payout = ipservice.payout;
      ipserviceDataArray[tableIndex].revenue = ipservice.revenue;
      ipserviceDataArray[tableIndex].ipTotalAmount = ipservice.ipTotalAmount;
      ipserviceDataArray[tableIndex].deductionCat = ipservice.deductionCat;
      ipserviceDataArray[tableIndex].ipTotalNetBillAmount = ipservice.ipTotalNetBillAmount;
      ipserviceDataArray[tableIndex].ipTotalGrossNillAmount = ipservice.ipTotalGrossNillAmount;
      ipserviceDataArray[tableIndex].ipfixedamount = ipservice.ipfixedamount;
      ipserviceDataArray[tableIndex].fixedAmount = ipservice.fixedAmount;
      ipserviceDataArray[tableIndex].fixedpercentage = ipservice.fixedpercentage;
      ipserviceDataArray[tableIndex].ratio = ipservice.ratio;
      ipserviceDataArray[tableIndex].ipdiscountvalue = ipservice.ipdiscountvalue;
      ipserviceDataArray[tableIndex].ipdeductaionvalue = ipservice.ipdeductaionvalue;
      ipserviceDataArray[tableIndex].ipdeductionperunit = ipservice.ipdeductionperunit;
      ipserviceDataArray[tableIndex].ipsurgeonfeeperc = ipservice.ipsurgeonfeeperc;
      ipserviceDataArray[tableIndex].ipgrossbillpercentage = ipservice.ipgrossbillpercentage;
      ipserviceDataArray[tableIndex].ippackageamountperc = ipservice.ippackageamountperc;
      ipserviceDataArray[tableIndex].ipnetbillpercentage = ipservice.ipnetbillpercentage;
      ipserviceDataArray[tableIndex].commercialTerm = commercialterm;
      ipserviceDataArray[tableIndex].count = ipservice.count;
      ipserviceDataArray[tableIndex].slab = ipservice.slab ? true : false;

      commercialIpTableData[tableIndex].itemgroup = selected
      commercialIpTableData[tableIndex].itemName = null
      commercialIpTableData[tableIndex].ipmodeofpayment = ipservice.ipmodeofpayment
      commercialIpTableData[tableIndex].iproom = ipservice.iproom
      commercialIpTableData[tableIndex].commercialterm = commercialterm
      commercialIpTableData[tableIndex].Center = selectedCenter
      commercialIpTableData[tableIndex].group = ipservice.group
    }
    console.log(ipserviceDataArray, ipservice, 'Ipcommercials Data Updted data')
    // setTotalpayout((j) => [...(j + opservice.oppayout)]);
    await setIptable(ipserviceDataArray);
    await setCommercialIpTable(commercialIpTableData)
    await cancelIpUpdation();
  }

  const cancelIpUpdation = async () => {
    await setIpCommercialItem([])
    await setSelectedRows([])
    await RefreshIpRecord()
  }

  console.log(ipservice, "ipservice")
  return (
    <>
      <CssBaseline />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
            <Autocomplete
              id="combo-box-demo"
              value={selectedCenter}
              onChange={(event, newValue) => parentItemChangeCenter(event, newValue)}
              options={centerOptions}
              getOptionLabel={(option) => option ? option : ''}
              renderInput={(params) => <TextField {...params} label="Select Center" helperText={''} FormHelperTextProps={{
                style: { color: "red" },
              }} />}
              renderOption={(props, option, state) => {
                return (
                  <Typography key={option} {...props} style={{ color: 'black' }}>{option}</Typography>
                );
              }}
            />
          </Grid>
          {
            selectedCenter ?
              <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
                <Autocomplete
                  id="combo-box-demo"
                  value={parentItem}
                  onChange={(event, newValue) => parentItemChange(event, newValue)}
                  options={options}
                  getOptionLabel={(option) => option.itemGroupName ? option.itemGroupName : ''}
                  renderInput={(params) => <TextField {...params} label="Select Item Group" helperText={''} FormHelperTextProps={{
                    style: { color: "red" },
                  }} />}
                  renderOption={(props, option, state) => {
                    return (
                      <Typography key={option.itemGroupId} {...props} style={{ color: 'black' }}>{option.itemGroupName}</Typography>
                    );
                  }}
                />
              </Grid>
              : ''
          }
          {
            selectedCenter && selected ?
              <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
                <Autocomplete
                  id="combo-box-demo"
                  value={groupLabel}
                  disabled={selected != "OT Surgeon Charges"}
                  onChange={(event, newValue) => groupChange(event, newValue)}
                  options={groups}
                  getOptionLabel={(option) => option.label ? option.label : ''}
                  renderInput={(params) => <TextField {...params} label="Select Group" helperText={''} FormHelperTextProps={{
                    style: { color: "red" },
                  }} />}
                  renderOption={(props, option, state) => {
                    return (
                      <Typography key={option.label} {...props} style={{ color: 'black' }}>{option.label}</Typography>
                    );
                  }}
                />
              </Grid>
              : ''
          }
          {
            selectedCenter && selected ?
              <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
                < Autocomplete
                  id="combo-box-demo"
                  value={applicableOnOption}
                  onChange={(event, newValue) => applicableOnChange(event, newValue)}
                  options={applicableOnOptions.filter(elem => (selected !== 'OT Surgeon Charges' && (elem.value === 'Gross' || elem.value === 'Net' || elem.value === 'Fixed')) || selected === 'OT Surgeon Charges')}
                  getOptionLabel={(option) => option ? option.label : ''}
                  renderInput={(params) => <TextField {...params} label="Select Applicable On" helperText={''} FormHelperTextProps={{
                    style: { color: "red" },
                  }} />}
                  renderOption={(props, option, state) => {
                    return (
                      <Typography key={option} {...props} style={{ color: 'black' }}>{option.label}</Typography>
                    );
                  }}
                />
              </Grid>
              : ''
          }
          <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
            {selectedCenter && selected && applicableOn && applicableOn !== 'Open Bill Gross' && applicableOn !== 'Open Bill Net' && childoptions.length > 0 ? (
              <Autocomplete
                multiple
                id="combo-box-demo"
                value={childItem}
                onChange={(event, newValue, reason) => childItemChange(event, newValue, reason)}
                options={childoptions}
                getOptionLabel={(option) => option.itemName}
                getOptionSelected={getOptionSelected}
                renderInput={(params) =>
                  <TextField {...params}
                    // label={applicableOn.includes('Package') || applicableOn.includes('Surgeon') ? "Select Package" : "Select Procedures"}
                    label={"Select Procedures"}
                    helperText={''} FormHelperTextProps={{
                      style: { color: "red" },
                    }} />
                }
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  return [{ itemName: "Select all" }, ...filtered];
                }}
                renderOption={(props, option, { selected }) => {
                  const selectAllProps =
                    option.itemName === "Select all" // To control the state of 'select-all' checkbox
                      ? { checked: isAllChildSelected }
                      : {};
                  return (
                    <>
                      <Typography key={option.itemGroupId} {...props} style={{ color: 'black' }}>
                        <Checkbox
                          color="primary"
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          {...selectAllProps}
                        />
                        {option.itemName}
                      </Typography>

                    </>
                  );
                }}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        {
          selected && applicableOn ?
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
                <FormControl className={multiclasses.formControl}>
                  <InputLabel id="mutiple-select-label"> Select Room <i
                    class="fa fa-asterisk"
                    style={{
                      color: "red",
                      fontSize: "6px",
                      marginLeft: "5px",
                      verticalAlign: "super",
                      // marginTop: "-150px",
                    }}
                    aria-hidden="true"
                  ></i></InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={selectedRoom}
                    onChange={handleChangeMultiRoom}
                    renderValue={(selectedRoom) => selectedRoom.join(", ")}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelectedRoom ? multiclasses.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: multiclasses.indeterminateColor,
                          }}
                          checked={isAllSelectedRoom}
                          indeterminate={
                            selectedRoom.length > 0 &&
                            selectedRoom.length < roomType.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: multiclasses.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {roomType &&
                      roomType.map((option) => (
                        <MenuItem key={option.id} value={option.bed_category_name}>
                          <ListItemIcon>
                            <Checkbox
                              checked={selectedRoom.indexOf(option.bed_category_name) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={option.bed_category_name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              {
                selectedRoom && selectedRoom.length > 0 ?
                  <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
                    <FormControl className={multiclasses.formControl}>
                      <InputLabel id="mutiple-select-label"> Select MOP <i
                        class="fa fa-asterisk"
                        style={{
                          color: "red",
                          fontSize: "6px",
                          marginLeft: "5px",
                          verticalAlign: "super",
                          // marginTop: "-150px",
                        }}
                        aria-hidden="true"
                      ></i></InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        multiple
                        value={selectedPaymentmode}
                        onChange={handleChangeMultiPaymentmode}
                        renderValue={(selectedPaymentmode) =>
                          selectedPaymentmode.join(", ")
                        }
                        MenuProps={MenuProps}
                      >
                        <MenuItem
                          value="all"
                          classes={{
                            root: isAllSelectedPaymentmode
                              ? multiclasses.selectedAll
                              : "",
                          }}
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{
                                indeterminate: multiclasses.indeterminateColor,
                              }}
                              checked={isAllSelectedPaymentmode}
                              indeterminate={
                                selectedPaymentmode.length > 0 &&
                                selectedPaymentmode.length < paymentMode.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            classes={{ primary: multiclasses.selectAllText }}
                            primary="Select All"
                          />
                        </MenuItem>
                        {paymentMode &&
                          paymentMode.map((option) => (
                            <MenuItem key={option.id} value={option.display}>
                              <ListItemIcon>
                                <Checkbox
                                  checked={
                                    selectedPaymentmode.indexOf(option.display) > -1
                                  }
                                />
                              </ListItemIcon>
                              <ListItemText primary={option.display} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  : ''
              }
            </Grid>
            : ''
        }
        {
          selectedPaymentmode && selectedPaymentmode.length > 0 ?
            <Grid container spacing={2} className="mt-2">
              <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
                <FormControlLabel control={
                  <Checkbox
                    color="primary"
                    checked={slab}
                    onChange={handleSlab}
                  />
                } label="Slab" />
              </Grid>
              <Grid item xs={12} sm={12} xl={6} lg={6} md={6}>
                <FormControl className={multiclasses.formControl}>
                  <InputLabel id="mutiple-select-label"> Select Gipsa <i
                    class="fa fa-asterisk"
                    style={{
                      color: "red",
                      fontSize: "6px",
                      marginLeft: "5px",
                      verticalAlign: "super",
                      // marginTop: "-150px",
                    }}
                    aria-hidden="true"
                  ></i></InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={gipsa}
                    onChange={handleGipsa}
                    renderValue={(gipsa) =>
                      gipsa.join(", ")
                    }
                    disabled={selectedPaymentmode && selectedPaymentmode.length == 1 && selectedPaymentmode[0] == 'Cash '}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelectedGipsa
                          ? multiclasses.selectedAll
                          : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            indeterminate: multiclasses.indeterminateColor,
                          }}
                          checked={isAllSelectedGipsa}
                          indeterminate={
                            gipsa.length > 0 &&
                            gipsa.length < gipsaOptions.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: multiclasses.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {gipsaOptions &&
                      gipsaOptions.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                          <ListItemIcon>
                            <Checkbox
                              checked={
                                gipsa.indexOf(option.value) > -1
                              }
                            />
                          </ListItemIcon>
                          <ListItemText primary={option.display} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            : ''
        }


        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={12} sm={12} xl={12}>
            <Divider orientation="vertical" flexItem />
            {
              applicableOn && selected && selectedRoom && selectedRoom.length > 0 && gipsa && gipsa.length > 0 ?
                <Grid
                  container
                  spacing={1}
                  style={{
                    marginTop: "20px",
                    borderTop: "1px grey black",
                  }}
                >

                  <Grid item xs={6}>
                    <TextField
                      variant="outlined"
                      name="count"
                      label={nop}
                      onBlur={ipCalculation}
                      value={ipservice.count}
                      onInput={(e) => onlyNumbers(e)}
                      onChange={handleIpserviceInputChange}
                      size="medium"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CountertopsIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        No. of Months
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={ipmonth}
                        onChange={handleChangeIpmonth}
                        label="No. of Months"
                      >
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="6">6</MenuItem>
                        <MenuItem value="7">7</MenuItem>
                        <MenuItem value="8">8</MenuItem>
                        <MenuItem value="9">9</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="11">11</MenuItem>
                        <MenuItem value="12">12</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      onBlur={ipCalculation}
                      variant="outlined"
                      name="perunitcharge"
                      label="Per Unit Charge"
                      value={
                        ipservice.perunitcharge
                          ? numberWithCommas(ipservice.perunitcharge)
                          : ""
                      }
                      onChange={handleInputChange}
                      onInput={(e) => onlyNumbers(e)}
                      fullWidth
                      size="medium"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CountertopsIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {applicableOn && !applicableOn.includes('Fixed') ?
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        name="fixedpercentage"
                        label="Fixed Percentage"
                        value={ipservice.fixedpercentage ? ipservice.fixedpercentage : ''}
                        onChange={handleIpserviceInputChange}
                        onBlur={ipCalculation}
                        onKeyUp={fixedPercentageOrAmount}
                        onInput={(e) => onlyNumbers(e)}
                        fullWidth
                        size="medium"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    :
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        name="fixedAmount"
                        label="Fixed Amount"
                        value={
                          ipservice.fixedAmount
                            ? numberWithCommas(ipservice.fixedAmount)
                            : ""
                        }
                        onKeyUp={fixedPercentageOrAmount}
                        onChange={handleIpserviceInputChange}
                        onBlur={ipCalculation}
                        onInput={(e) => onlyNumbers(e)}
                        fullWidth
                        size="medium"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MoneyIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  }
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Is Discount Applicable
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="Discount"
                        onChange={getIPDiscount}
                        name="ipdiscountType"
                        value={ipdiscount}
                        // value={age}
                        // onChange={handleChange}
                        label="Is Discount Applicable"
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disable={disableippackageamountperc}
                      variant="outlined"
                      name="ipdeductionperunit"
                      label="Deduction Per Unit"
                      onInput={(e) => onlyNumbers(e)}
                      value={
                        ipservice.ipdeductionperunit
                          ? numberWithCommas(ipservice.ipdeductionperunit)
                          : ""
                      }
                      onChange={handleIpserviceInputChange}
                      onBlur={ipCalculation}
                      size="medium"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IndeterminateCheckBoxIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Deduction Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="Discount"
                        onChange={getIPDedCat}
                        name="deductionCat"
                        value={ipdeductionCat}
                        // value={age}
                        // onChange={handleChange}
                        label="Is Discount Applicable"
                      >
                        <MenuItem value="Radiology">Radiology</MenuItem>
                        <MenuItem value="Lab">Lab</MenuItem>
                        <MenuItem value="Pharmacy">Pharmacy</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disable={disableippackageamountperc}
                      variant="outlined"
                      name="ipdeductaionvalue"
                      label="Deduction Value"
                      onInput={(e) => onlyNumbers(e)}
                      value={ipdeductiontot ? numberWithCommas(ipdeductiontot) : ""}
                      // value={ipdeductiontot}
                      // value={ipservice.ipdeductaionvalue}
                      // onChange={handleIpserviceInputChange}
                      // onBlur={ipCalculation}
                      size="medium"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalculateIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ backgroundColor: "#80808030" }}
                      variant="outlined"
                      name="ipgrossbill"
                      label="Gross Bill Amount"
                      value={ipgrossbillTot && ipgrossbillTot > 0 ? numberWithCommas(ipgrossbillTot) : ""}
                      size="medium"
                      onChange={handleIpserviceInputChange}
                      fullWidth
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalculateIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ backgroundColor: "#80808030" }}
                      variant="outlined"
                      name="ipnetbillamount"
                      label="Net Bill Amount"
                      size="medium"
                      value={
                        ipNetBillAmountTot && ipNetBillAmountTot > 0
                          ? numberWithCommas(ipNetBillAmountTot)
                          : ""
                      }
                      onChange={handleIpserviceInputChange}
                      fullWidth
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalculateIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={8}>
                    <TextField
                      style={{ backgroundColor: "#80808030" }}
                      variant="outlined"
                      name="ipnetbillamount"
                      label="Net Bill Amount"
                      value={ipNetBillAmountTot
                            ? numberWithCommas(ipNetBillAmountTot)
                            : ""
                      }
                      onChange={handleIpserviceInputChange}
                      size="medium"
                      fullWidth
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalculateIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid> */}
                  <Grid item xs={6}>
                    <TextField
                      style={{ backgroundColor: "#80808030" }}
                      variant="outlined"
                      name="iptotalamount"
                      label="Estimated Revenue"
                      value={ipAmountTot ? numberWithCommas(ipAmountTot) : ""}
                      onChange={handleIpserviceInputChange}
                      size="medium"
                      fullWidth
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ backgroundColor: "#80808030" }}
                      variant="outlined"
                      name="payout"
                      label="Payout "
                      value={ipPayoutTotal ? numberWithCommas(ipPayoutTotal) : ""}
                      onChange={handleIpserviceInputChange}
                      size="medium"
                      fullWidth
                      disabled
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      // onClick={opCalculation}
                      style={{ height: "100%", width: "100%" }}
                    >
                      Calculate
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      ipCommercialItem && ipCommercialItem.length ?
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ height: "100%" }}
                            startIcon={<UpgradeIcon />}
                            // fullWidth
                            onClick={() => UpdateTableData()}
                            disabled={ipservice.count && ipmonth && ipservice.perunitcharge && (ipAmountTot > 0) && (ipPayoutTotal > 0) ? false : true}
                          >
                            Update
                          </Button>
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ height: "100%", marginLeft: "18px" }}
                            startIcon={<CancelIcon />}
                            // fullWidth
                            onClick={() => cancelIpUpdation()}
                          >
                            Cancel
                          </Button>
                        </>
                        :
                        (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ height: "100%" }}
                              startIcon={<AddIcon />}
                              // fullWidth
                              disabled={ipservice.count && ipmonth && ipservice.perunitcharge && (ipAmountTot > 0) && (ipPayoutTotal > 0) ? false : true}
                              onClick={addIpTableData}
                            >
                              ADD
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{ height: "100%", marginLeft: "18px" }}
                              startIcon={<RefreshIcon />}
                              // fullWidth
                              onClick={RefreshIpRecord}
                            >
                              REFRESH
                            </Button>
                          </>
                        )}
                  </Grid>
                </Grid>
                : ''
            }

            <Grid
              container
              spacing={1}
              style={{
                marginTop: "20px",
                borderTop: "1px grey black",
              }}
            >

              <Grid item xs={12}>
                {iptable.length > 0 ? (
                  <Paper className={tables.root}>
                    <MaterialTable
                      columns={[
                        {
                          title: "Center Name",
                          field: "Center",
                        },
                        {
                          title: "Group",
                          field: "group",
                        },
                        {
                          title: "Item Group Name",
                          field: "itemGroup",
                        },
                        {
                          title: "Procedure Name",
                          field: "itemName",
                          render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>)
                        },
                        {
                          title: "Room Type",
                          field: "iproom",
                          render: (row) => (<div>{row.iproom ? row.iproom : "---"}</div>)
                        },

                        {
                          title: "Mode of Payment",
                          field: "ipmodeofpayment",
                          render: (row) => (<div>{row.ipmodeofpayment ? row.ipmodeofpayment : "---"}</div>)
                        },
                        {
                          title: "Slab",
                          field: "slab",
                          render: (row) => row.slab ? 'Yes' : "No"
                        },
                        {
                          title: "Fixed Amount",
                          field: "fixedAmount",
                          render: (row) => (<div>{row.fixedAmount ? "Rs. " + numberWithCommas(row.fixedAmount) : "---"}</div>)
                        },
                        {
                          title: "Fixed Percentage",
                          field: "fixedpercentage",
                          render: (row) => (<div>{row.fixedpercentage ? row.fixedpercentage + "%" : "---"}</div>)
                        },
                        {
                          title: "Deduction Value",
                          field: "ipdeductaionvalue",
                          render: (row) => (<div>{row.ipdeductaionvalue ? "Rs. " + numberWithCommas(row.ipdeductaionvalue) : "---"}</div>)
                        },
                        {
                          title: "Discount Applicable",
                          field: "ipdiscountType",
                        },
                        {
                          title: "Gross Bill Amount",
                          field: "ipTotalGrossNillAmount",
                          render: (row) => (<div>{row.ipTotalGrossNillAmount ? "Rs. " + numberWithCommas(row.ipTotalGrossNillAmount) : "---"}</div>)
                        },
                        {
                          title: "Net Bill Amount",
                          field: "ipTotalNetBillAmount",
                          render: (row) => (<div>{row.ipTotalNetBillAmount ? "Rs. " + numberWithCommas(row.ipTotalNetBillAmount) : "---"}</div>)
                        },
                        {
                          title: "Amount",
                          field: "ipTotalAmount",
                          render: (row) => (<div>{row.ipTotalAmount ? "Rs. " + numberWithCommas(row.ipTotalAmount) : "---"}</div>)
                        },
                        {
                          title: "Count",
                          field: "count",
                          render: (row) => (<div>{row.count ? row.count : "---"}</div>)
                        },
                        // { title: "Revenue", field: "revenue" },
                        { title: "Payout", field: "payout", render: (row) => (<div>{row.payout ? "Rs. " + numberWithCommas(row.payout) : "---"}</div>) },
                      ]}
                      actions={[
                        {
                          icon: "delete",
                          tooltip: "Delete",
                          iconProps: {
                            style: {
                              fontSize: "24px",
                              color: "#f55151",
                            },
                          },
                          // onClick: (event, row) => deleteIptableRow(row),
                          onClick: (event, row, Index) => {
                            setConfirmDialog({
                              isOpen: true,
                              title:
                                "Deleting Ip-Detail may result in some features unresponsive ?",
                              subtitle:
                                "Are you sure you want to delete this record ?",
                              onConfirm: () => {
                                deleteIptableRow(row, Index, event);
                                setConfirmDialog({ isOpen: false });
                              },
                            });
                          },
                        },
                        {
                          icon: "edit",
                          tooltip: "Edit",
                          iconProps: {
                            style: {
                              fontSize: "24px",
                              color: "#f55151",
                            },
                          },
                          onClick: (event, row) => {
                            if (row.length === 1) {
                              editCommercial(row)
                            }
                            console.log("event in row", row)
                          },
                          hidden: (selectedRows.length !== 1),
                        },
                      ]}
                      data={iptable}
                      options={{
                        sorting: true,
                        paging: false,
                        search: false,
                        selection: true,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                          fontSize: 12,
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                        rowStyle: {
                          fontSize: 12,
                        },
                      }}
                      onSelectionChange={handleSelectionRows}
                      title={<TableTitle text="IN-Patient Service" />}
                    />
                  </Paper>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "2px", marginBottom: "20px" }}>
              {iptable && iptable.length > 0 ? (
                <MaterialTable
                  columns={[
                    {
                      title: "Center Name",
                      field: "Center",
                    },
                    {
                      title: "Group",
                      field: "group",
                    },
                    {
                      title: "Item Group/Procedures",
                      field: "itemgroup",
                    },
                    {
                      title: "Item Name",
                      field: "itemName",
                    },
                    { title: "Room Category", field: "iproom" },
                    { title: "MOP", field: "ipmodeofpayment" },

                    {
                      title: "Commercial Term",
                      field: "Action",
                      filtering: false,
                      render: (row) => row.commercialterm,
                    },
                  ]}
                  data={commercialIpTable}
                  options={{
                    sorting: true,
                    paging: false,
                    search: false,
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontSize: 12,
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                    rowStyle: {
                      fontSize: 12,
                    },
                  }}
                  title={<TableTitle text="IP Commercials" />}
                />
              ) : (
                ""
              )}
            </Grid>
            {iptable && iptable.length > 0 ? (
              <Grid
                xs={12}
                style={{
                  float: "right",
                  marginTop: "20px",
                  marginBottom: "20px",
                  color: "black",
                }}
              >
                <Typography style={{ marginBottom: "20px", float: "right" }}>
                  <span style={{ width: "100%", display: "block", margin: "15px" }}> <b>Total Estimated Revenue Generated:</b> Rs. {totalIpRevenueNew ? numberWithCommas(totalIpRevenueNew.toFixed(2)) + "/-" : ""}</span>
                  <span style={{ width: "100%", display: "block", margin: "15px" }}> <b>Total Payout:</b>  Rs. {totalIpPayoutNew ? numberWithCommas(totalIpPayoutNew.toFixed(2)) + "/-" : ""}</span>
                </Typography>
                {/* Total Payout: {totalIpPayoutNew ? "Rs. "+ numberWithCommas(totalIpPayoutNew) : ""}{" "}
                Total Estimated Revenue Generated: {totalIpRevenueNew ? "Rs. "+ numberWithCommas(totalIpRevenueNew) : ""} */}
              </Grid>
            ) : (
              ""
            )}
            <br />
            <br />
          </Grid>
        </Grid>
      </Container>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
