import { makeStyles } from "@material-ui/core/styles";

const useStylesmulti = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

// OP LIST DATA
const opListData = [
  { id: 1, display: "Consultation", value: "Consultation" },
  { id: 2, display: "Pharmacy", value: "Pharmacy" },
  { id: 3, display: "Lab", value: "Lab" },
  {
    id: 4,
    display: "Radiology",
    value: "Radiology",
    child: [
      { display: "Radiology-Radiology1", value: "Radiology1" },
      { display: "Radiology-Radiology2", value: "Radiology2" },
      { display: "Radiology-Radiology3", value: "Radiology3" },
    ],
  },
  { id: 5, display: "Anesthesia Services", value: "Anesthesia Services" },
  { id: 6, display: "Physical Therapy", value: "Physical Therapy" },
  {
    id: 7,
    display: "Non OT Procedures/OP Procedures",
    value: "Non OT Procedures/OP Procedures",
    child: [
      { display: "Non OT Procedures/OP Procedures-MTP", value: "MTP" },
      { display: "Non OT Procedures/OP Procedures-IUI", value: "IUI" },
      { display: "Non OT Procedures/OP Procedures-IUCD", value: "IUCD" },
    ],
  },
  {
    id: 8,
    display: "Other Charges",
    value: "Other Charges",
    child: [
      { display: "Other Charges-DENTAL", value: "DENTAL" },
      {
        display: "Other Charges-EMERENCY RADIOLOGY CHARGE",
        value: "EMERENCY RADIOLOGY CHARGE",
      },
      {
        display: "Other Charges-SUTURING / WOUND DRESSING",
        value: "SUTURING / WOUND DRESSING",
      },
    ],
  },
];

//Ip LIST DATA
const ipListData = [
  {
    id: 1,
    display: "Delivery",
    value: "Delivery",
    child: [
      { display: "Delivery-LSCS", value: "LSCS" },
      { display: "Delivery-Normal", value: "Normal" },
      { display: "Delivery-Forceps", value: "Forceps" },
    ],
  },
  {
    id: 2,
    display: "Gynec",
    value: "Gynec",
    child: [
      {
        display: "Gynec-Cerivical encirclage",
        value: "Cerivical encirclage",
      },
      {
        display: "Gynec-Mid Trimister Abortion",
        value: "Mid Trimister Abortion",
      },
    ],
  },
  {
    id: 3,
    display: "Fertility",
    value: "Fertility",
    child: [
      { display: "Fertility-ICSI", value: "ICSI" },
      {
        display: "Fertility-IVF",
        value: "IVF",
      },
      {
        display: "Fertility-FET",
        value: "FET",
      },
    ],
  },
  { id: 4, display: "Consultations", value: "Consultations" },
  {
    id: 5,
    display: "NICU",
    value: "NICU",
    child: [
      {
        display: "NICU-NICU",
        value: "NICU",
      },
      {
        display: "NICU-NICU with ventilator",
        value: "NICU with ventilator",
      },
    ],
  },
  {
    id: 6,
    display: "Open billing",
    value: "Open billing",
    child: [
      { display: "Open billing-LSCS", value: "LSCS" },
      { display: "Open billing-Normal", value: "Normal" },
      { display: "Open billing-Forceps", value: "Forceps" },
      {
        display: "Open billing-Cerivical encirclage",
        value: "Cerivical encirclage",
      },
      {
        display: "Open billing-Mid Trimister Abortion",
        value: "Mid Trimister Abortion",
      },
      { display: "Open billing-ICSI", value: "ICSI" },
      {
        display: "Open billing-IVF",
        value: "IVF",
      },
      {
        display: "Open billing-FET",
        value: "FET",
      },
      {
        display: "Open billing-NICU",
        value: "NICU",
      },
      {
        display: "Open billing-NICU with ventilator",
        value: "NICU with ventilator",
      },
      {
        display: "Open billing-Cerivical encirclage",
        value: "Cerivical encirclage",
      },
      {
        display: "Open billing-Mid Trimister Abortion",
        value: "Mid Trimister Abortion",
      },
    ],
  },
];

const roomType = [
  { id: 1, name: "Sharing ", display: "Sharing " },
  { id: 2, name: "Luxury ", display: "Luxury " },
  { id: 3, name: "Suite ", display: "Suite " },
];

const paymentMode = [
  { id: 1, name: "Credit ", display: "Credit " },
  { id: 2, name: "Cash ", display: "Cash " },
];
const gipsaOptions = [
  { id: 1, name: "Include Gipsa", value: "Yes", display: "Include Gipsa" },
  { id: 2, name: "Exclude Gipsa", value: "No", display: "Exclude Gipsa" },
];

export {
  useStylesmulti,
  MenuProps,
  opListData,
  ipListData,
  roomType,
  paymentMode,
  gipsaOptions,
};
