import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Tooltip } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ConfirmDialog(props) {
  const { confirmDialog, setConfirmDialog } = props;
  return (
    <>
      <Dialog
        style={{ overflowY: "unset" }}
        open={confirmDialog.isOpen}
        disableBackdropClick
        maxWidth="sm"
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setConfirmDialog({ isOpen: false });
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          onClose={() => {
            setConfirmDialog({ isOpen: false });
          }}
          id="alert-dialog-slide-title"
          style={{
            backgroundColor: "#810e36",
            color: "white",
            textAlign: "center",
          }}
        >
          {confirmDialog.title}
          {/* {"Action Required"} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "black", textAlign: "center" }}
          >
            {confirmDialog.subtitle}
            {/* RD Approval required */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {confirmDialog.onConfirm ? (
            <Tooltip title="Yes">
              <IconButton>
                <DoneOutlineIcon
                  onClick={confirmDialog.onConfirm}
                  style={{ color: "green" }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            // <Button color="secondary" onClick={confirmDialog.onConfirm}>
            //   Yes
            // </Button>
            ""
          )}
          <Tooltip title="Cancel">
            <IconButton>
              <CloseIcon
                onClick={() => {
                  setConfirmDialog({ isOpen: false });
                }}
                style={{ color: "red" }}
              />
            </IconButton>
          </Tooltip>

          {/* <Button
            color="secondary"
            onClick={() => {
              setConfirmDialog({ isOpen: false });
            }}
          >
            Close
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
