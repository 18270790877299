import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Navmain from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import Footer from "../../Components/Footer";
import { Modal, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import moment from 'moment'
import {
    addpositions,
    numberToEnglish,
    findpositions,
    dateFormatter,
    numberWithCommas,
} from "../../actions/createPositionActions";
import XLSX from 'xlsx'
import MaterialTable from "material-table";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import ConfirmDialog from "../../Components/ConfirmDialog";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress, Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Test from "../../Pages/Payout/test";
import FixedfullTime from "../../Pages/Payout/NEW/FixedfullTime";
import FixedMG from "../../Pages/Payout/NEW/fixedMG";
import VcChennai from "../../Pages/Payout/NEW/VcChennai";
import VisitingCon from "../../Pages/Payout/NEW/VisitingCon";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import VisitingConsultant from "../../Pages/Payout/VisitingConsultant";
import FixedConsultant from "../../Pages/Payout/FixedConsultant";
import MGConsultant from "../../Pages/Payout/MGConsultant";
import { agreementState, onboardState } from "../../CommomMethods/States";
import { listusers } from "../../actions/userActions";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CsvBuilder } from "filefy";
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import {
    createOnboarding,
    addonboard,
    onboardById,
} from "../../actions/onboardActions";
import _filefy from "filefy";
import DoctorSidebar from "../../Components/DoctorSidebar";
import AppSidebar from "../../Components/AppSidebar";
function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [year, month, day].join('-');
}
const myTableStyle = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "20%",
        marginTop: theme.spacing(3),
        "& td": {
            fontFamili: "Roboto !important",
        },
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflow: "auto",
        marginBottom: "50px",
    },
    customizedButton: {
        position: "absolute",
        left: "95%",
        top: "-9%",
        backgroundColor: "lightgray",
        color: "gray",
    },
}));

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "fixed",
        backgroundColor: "#810e36",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const backDropStyle = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

function History({ reqTableData, reqType, match, history, openPosModal, ...props }) {
    const classes = useStyles();
    // const id=match.params.id
    const tables = myTableStyle();
    const [show, setShow] = useState(false);
    const [jCShow, setJCShow] = useState(false);
    const [viewAgreement, setViewAgreement] = useState(false);
    const [viewHistorys, setViewHistory] = useState(false);
    const [historyRow, setHistoryRow] = useState(false);
    const [row, setRows] = useState({});
    const [doctorId, setDoctorId] = useState("");
    const [docData, setDocData] = useState([]);
    const [commencement, setCommencement] = useState([]);
    const [categoty, setCategory] = useState("");
    const [alldata, setAllData] = useState([]);
    const [OnboardData, setOnboardData] = useState([]);

    const [rowData, setRowData] = useState({});
    const [viewFinancialDialog, setViewFinancialDialog] = useState(false);
    const [data, setData] = useState({});
    const [open, setOpen] = React.useState(false);
    const [docid, setDocid] = useState("");
    const [openReject, setOpenRejectmodal] = React.useState(false);
    const [openApprove, setOpenApprovemodal] = React.useState(false);
    const [approve, setapprove] = useState([]);
    const [action, setAction] = useState('');
    const [rej, setRej] = useState([]);
    const [review, setReview] = useState(""); const [doctorDetail, setDoctorDetail] = useState([]);
    const [doctorOpDetail, setDoctorOpDetail] = useState([]);

    const [doctorIpDetail, setDoctorIpDetail] = useState([]);
    const [doctorOpCommercial, setDoctorOpCommercial] = useState([]);
    const [doctorIpCommercial, setDoctorIpCommercial] = useState([]);
    const [doctorMgDetails, setDoctorMgDetails] = useState([]);
    const [doctorFixedDetails, setDoctorFixedDetails] = useState([]);
    const [TotalPayOutValue, setTotalPayOutValue] = useState([]);
    const [openEstimation, setOpenEstimation] = React.useState(false);
    const [openInitialEstimation, setOpenInitialEstimation] = React.useState(false);
    const [RequestDetail, setRequestDetail] = useState([]);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subtitle: "",
    });
    const backClasses = backDropStyle();
    const [backDropOpen, setBackDropOpen] = useState(false);
    const [muiTableKey, setMuiTableKey] = React.useState(0);
    useEffect(() => {
        setBackDropOpen(true);
        axios.get(APIURL() + "onboard/get-activity-logs?per_page=All").then((res) => {
            setAllData(res.data.data);
            setBackDropOpen(false);
        });
    }, []);
    const tableRef = React.createRef();
    console.log(alldata, "OnboardData+++>")
    const userList = useSelector((state) => state.userList);
    const { users } = userList;
    const onboardId = useSelector((state) => state.onboardId);
    const { oboard } = onboardId;

    var newdata = [];
    function selectedValue(e) {
        newdata = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
    }

    const downloadExcel = () => {
        const newData = alldata.map(row => {
            console.log(row, 'downloadExcel')
            var newRow = {
                'Activity': row.activity,
                'Category': row.catagory,
                'Date': row.date,
                'Time': row.time,
                'Doctor Name': row.doctor_name,
                'Requisition Number': row.request_id,
                'Acted By': row.user ? row.user.name : "System",
                'Role': row.roles ? row.roles.name : "System",
            }
            delete row.tableData
            return newRow
        })
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "DoctorsData.xlsx")
    }


    const closeAgreement = () => {
        setViewAgreement(false);
    };
    const viewHistory = (row) => {
        console.log(row, "ROOOOOOOOOOOOOOOW");
        setHistoryRow(row.doctorId);
        dispatch(onboardById(row.doctorId));
        setViewHistory(true);
    };

    console.log(props, "OOOOOO");

    const handleHistoryClose = () => setViewHistory(false);

    const handleClose = () => setShow(false);

    const handleShow = (row) => {
        console.log(row, "ROWWW");
        if (row.rdApproval === "R") {
            setConfirmDialog({
                isOpen: true,
                title: "COO approval required",
                subtitle: "Action Required",
            });
            // setOpen(true);
        } else {
            setShow(true);
            dispatch(findpositions(row.id));
        }
    };


    const handleLocum = (row) => {
        console.log(row, "HI");
        alert("hi");
    };
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin);

    // bringing things from the reducer
    const { user } = userLogin;
    const addPosition = useSelector((state) => state.addPosition);
    const { addpos } = addPosition;

    console.log(addpos, "addposaddpos");
    const findPos = useSelector((state) => state.findPos);
    const { findpos } = findPos;
    console.log(addpos, "addpos-->")
    // reqTableData.forEach(dataRow => dataRow.clickEvent = (id) => handleShow(id))

    const infoStyle = {
        border: "2px solid #858585",
        width: "-webkit-fill-available"
    };
    const removeRequestHandler = (id) => {
        // dispatch(removeRequest(id))
    };

    const confirmJoiningClose = (id) => {
        setJCShow(false);
    };
    // const confirmDoctor = () => {};
    const confirmJoining = (row) => {
        console.log(row, "(((((((((111");
        setJCShow(true);
        setDoctorId(row.doctorId);
        axios.get(APIURL() + 'ProfileDetails/' + row.doctorId).then(res => {
            console.log(res.data.details, "&*&************");
            setCommencement(res.data.details);
        })
        // setTimeout(() => {
        //   toast.success("Successfully CONFIRMED!");
        //   window.location.reload();
        // }, 1000);
    };


    console.log(commencement && commencement[0] && commencement[0].doj && commencement[0].doj.substr(0, 10), "commencement && commencement")
    const confirm = () => {
        axios
            .put(APIURL() + "onboard/updateConfirmdog/" + doctorId, data)
            .then((res) => {

                toast.success("Successfully CONFIRMED!");
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            });
    };
    const declinedJoining = (data) => {
        // console.log(data,"data========>")
        axios
            .post(APIURL() + "onboard/DeclinedJoining", { VacancyId: data.id })
            .then((res) => { });
        setTimeout(() => {
            toast.success("Onboarding Process Declined!");
            // window.location.reload();
        }, 1000);
    };
    console.log(alldata, "&&&&7");

    const viewFinancialGrid = (row) => {
        console.log(row, "view-financial-grid");
        setRowData(row);
        setViewFinancialDialog(true);
    };

    const closeFinancialDialog = () => {
        setViewFinancialDialog(false);
    };

    const handleRejectDialog = (row, action) => {
        setDocid(row.doctorId);
        setOpenRejectmodal(true);
        setRej(row);
        setAction(action);
    };

    const handleComments = (e) => {
        setReview(e.target.value);
    };
    const handleApproveDialog = (row, action) => {
        setDocid(row.doctorId);
        setOpenApprovemodal(true);
        setapprove(row);
        setAction(action);
    };

    // RD Approval Financial Grid
    const handleApprove = async (ele) => {
        // console.log(approve, 'approveapprove')
        // return false
        // alert("The Agreement is APPROVED")
        if (review == '') {
            await toast.error("Kindly provide some comments");
            return false;
        }
        console.log(ele, "row-data");
        if (action == 'financial_grid') {
            const obj = {
                rdApproval: "OK",
                comments: review,
                postedBy: user.user.id,
            };
            console.log(obj, "PPPPPP");
            await axios.put(APIURL() + "vacancies/" + approve.id, obj).then((res) => { });
        }
        if (action == 'estimation') {
            const obj1 = {
                doctorId: approve.doctorId,
                name: approve.name,
                currentAction: onboardState.rd.state,
                currentActionBy: user.user.id,
                nextAction: onboardState.dr_info.state,
                remark: review,
                status: 2,
            };
            await axios.post(APIURL() + "onboard/action/", obj1).then((res) => {
                // setStatus(false)
            });
        }

        // await window.location.reload();
    };

    // RD Review Financial Grid
    const handleReject = async (ele) => {
        // alert("The Agreement is APPROVED")
        if (review == '') {
            toast.error("Kindly provide some comments");
            return false;
        }
        if (action == 'financial_grid') {
            const obj = {
                rdApproval: "RJ",
                comments: review,
                postedBy: user.user.id,
            };

            const rejectElement = {
                userID: user.user.id,
            };

            await axios.put(APIURL() + "vacancies/" + rej.id, obj).then((res) => { });
            await axios
                .post(APIURL() + "onboard/createOnboardOnReject/", rejectElement)
                .then((res) => { });
        }
        if (action == 'estimation') {
            const obj1 = {
                doctorId: rej.doctorId,
                name: rej.name,
                currentAction: onboardState.rd.state,
                currentActionBy: user.user.id,
                nextAction: onboardState.dr_info.state,
                remark: review,
                status: 2,
            };
            await axios.post(APIURL() + "onboard/action/", obj1).then((res) => {
                // setStatus(false)
            });
        }

        // await window.location.reload();
    };

    const viewEstimation = async (row) => {
        if (row.doctorId) {
            setDocid(row.doctorId);
            getDocdetail(row.doctorId);
            setRowData(row);
        }

        setOpenEstimation(true);
    }
    const viewInitialEstimation = (row) => {
        if (row.doctorId) {
            setDocid(row.doctorId);
            getDocdetail(row.doctorId);
            setRequestDetail(row)
        }

        setOpenInitialEstimation(true);
    };

    const handlerCloseInitialEstimation = () => {
        setOpenInitialEstimation(false);
    }

    const getDocdetail = async (id) => {
        axios
            .get(APIURL() + "onboard/previewOne/" + id)
            .then((res) => {
                setDoctorDetail(res.data);
                setDoctorIpDetail(res.data.doctorIPParameter);
                setDoctorOpDetail(res.data.doctorOPParameter);
                setDoctorOpCommercial(res.data.doctorOpCommercial);
                setDoctorIpCommercial(res.data.doctorIpCommercial);
                setDoctorMgDetails(res.data.doctorMgDetails);
                setDoctorFixedDetails(res.data.doctorFixedDetails);
                let TotalRev = 0
                let TotalPayOutOP = 0
                let TotalPayOutIP = 0
                let TotalMGorFixedValue = 0
                let TotalPayout = 0
                if (res.data.doctorIPParameter && res.data.doctorIPParameter.length > 0) {
                    res.data.doctorIPParameter.map((elem) => {
                        TotalRev = TotalRev + parseFloat(elem.ipTotalAmount)
                        TotalPayOutIP = TotalPayOutIP + parseFloat(elem.payout)
                    })
                }
                if (res.data.doctorOPParameter && res.data.doctorOPParameter.length > 0) {
                    res.data.doctorOPParameter.map((elem) => {
                        TotalRev = TotalRev + parseFloat(elem.oprevenue)
                        TotalPayOutOP = TotalPayOutOP + parseFloat(elem.oppayout)
                    })
                }
                if (res.data.doctorMgDetails && res.data.doctorMgDetails.length > 0) {
                    res.data.doctorMgDetails.map((elem) => {
                        TotalMGorFixedValue = TotalMGorFixedValue + parseFloat(elem.minguranteepermonth)
                    })
                    if (TotalMGorFixedValue > (TotalPayOutIP + TotalPayOutOP)) {
                        TotalPayout = TotalMGorFixedValue
                    } else {
                        TotalPayout = (TotalPayOutIP + TotalPayOutOP)
                    }
                }
                if (res.data.doctorFixedDetails && res.data.doctorFixedDetails.length > 0) {
                    res.data.doctorFixedDetails.map((elem) => {
                        TotalMGorFixedValue = TotalMGorFixedValue + parseFloat(((elem.professionalfeepermonth).toString()).replaceAll(',', '')) + parseFloat(((elem.additionalfeevalue).toString()).replaceAll(',', ''))
                    })
                    TotalPayout = TotalMGorFixedValue + (TotalPayOutIP + TotalPayOutOP)
                }
                setTotalPayOutValue([{
                    "TotalRevenue": TotalRev,
                    "TotalIP": TotalPayOutIP,
                    "TotalOP": TotalPayOutOP,
                    "TotalMGorFixedValue": TotalMGorFixedValue,
                    "Payout": TotalPayout
                }])
            })
            .catch((error) => { });
    }

    const handlerCloseEstimation = async () => {
        setOpenEstimation(false);
    }

    const TableTitle = ({ text }) => (
        <Typography
            style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "black",
                textDecoration: "underline",
                fontWeight: "bold",
                fontSize: "15px"
            }}
        >
            {text}
        </Typography>
    );

    const sendtoMaster = (data) => {
        // console.log(data, "data========>")
        var type = ""
        if (data.category.includes("MG")) {
            type = "MG";
        } else if (data.category.includes("Full Time Consultant")) {
            type = "Fixed";
        } else if (data.category.includes("Visiting Consultant")) {
            type = "VC"
        } else if (data.category.includes("Retainer")) {
            type = "Retainer"
        }
        const masterdata = {
            doctorId: data.doctorId,
            doctorLTId: "",
            doctorName: data.doctorName,
            type: type,
            mgvalue: "",
            value: 0,
            centerCode: data.centerNames,
            centerId: "",
            status: 0
        }
        axios.post(APIURL() + "onboard/sendToMaster", masterdata).then((res) => { });
        setTimeout(() => {

            history.push("/DoctorProfileFinal", { state: { id: data.doctorId } });

        }, 1000);
    };
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    return (
        <>
            {user.user.roles == 1 || user.user.roles == 2 || user.user.roles == 3 || user.user.roles == 8 ? <Sidebar />
                : user.user.roles == 4 ? <DoctorSidebar />
                    : <AppSidebar />}

            <div className="main-content side-content pt-0">
                <div className="container-fluid">
                    <div className="inner-body">
                        <Navmain />
                        <div className="page-header">
                            <div className="card custom-card mt-2" style={infoStyle}>
                                <div className="card-body">
                                    <h3 className="main-content-title tx-24 mb-4 mg-b-5"> </h3>
                                    <Paper className={tables.root}>
                                        <MaterialTable
                                            tableRef={tableRef}
                                            columns={[
                                                { title: "Date", field: "date" },
                                                { title: "Time", field: "time" },
                                                {
                                                    title: "Category", field: "category",
                                                    lookup: {
                                                        "Onboarding": "Onboarding",
                                                        "Agreement Annexure": "Agreement Annexure",
                                                        "Payout": "Payout",
                                                        "Onboarding": "Onboarding",
                                                    }
                                                },
                                                { title: "Request Id", field: "request_id" },
                                                {
                                                    title: "Doctor Name",
                                                    field: "doctor_name",
                                                    render: (row) =>
                                                        row.doctor_name ? row.doctor_name : "-----"
                                                },
                                                {
                                                    title: "Role", field: "roles.name",
                                                    lookup: {
                                                        "Admin": "Admin",
                                                        "Center Head": "Center Head",
                                                        "Corporate HR": "Corporate HR",
                                                        "COO": "COO",
                                                        "CEO": "CEO",
                                                        "Doctor": "Doctor",
                                                        "DRM": "DRM",
                                                        "HR Personnel": "HR Personnel",
                                                        "Payout Team": "Payout Team",
                                                    }
                                                },
                                                { title: "Acted By", field: "user.name" },
                                                {
                                                    title: "Activity",
                                                    field: "activity",
                                                },
                                            ]}
                                            // data={alldata}
                                            data={query =>
                                                new Promise((resolve, reject) => {
                                                    let url = APIURL() + "onboard/get-activity-logs?"
                                                    url += `per_page=${query.pageSize}`
                                                    url += `&page=${(query.page + 1)}`
                                                    url += `&search=${query.search}`
                                                    if (query.filters.length) {
                                                        query.filters.forEach(item => {
                                                            url += `&${item.column.field}=${item.value}`
                                                        })
                                                    }
                                                    if (query.orderBy) {
                                                        url += `&orderByField=${query.orderBy.field}`
                                                        url += `&orderBySort=${query.orderDirection}`
                                                    }
                                                    console.log(query, tableRef, "activitylogs")
                                                    axios.get(url)
                                                        .then(response => response.data)
                                                        .then(result => {
                                                            resolve({
                                                                data: result.data,
                                                                page: result.page - 1,
                                                                totalCount: result.total,
                                                            })
                                                        })
                                                })
                                            }
                                            // data={alldata.rows}
                                            // data={addpos.data}
                                            actions={[
                                                {
                                                    icon: () => <button>Export</button>,// you can pass icon too
                                                    tooltip: "Export to Excel",
                                                    onClick: () => downloadExcel(),
                                                    isFreeAction: true
                                                },
                                                {
                                                    icon: () => <ClearAllIcon />,
                                                    tooltip: "Clear All Filters",
                                                    isFreeAction: true,
                                                    onClick: async (event) => {
                                                        await tableRef.current.onQueryChange() // set new key causing remount
                                                        // tableRef.current.onFilterChange()
                                                        await setMuiTableKey(muiTableKey + 1);
                                                    }
                                                }
                                            ]}
                                            options={{
                                                filtering: true,
                                                sorting: true,
                                                // exportButton: true,
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                                search: true,
                                                exportAllData: true,
                                                searchText: props && props.location && props.location.state && props.location.state.data && props.location.state.data.id ? (props.location.state.data.id).toString() : ''
                                            }}
                                            icons={{
                                                Filter: "filter",
                                            }}
                                            title="Request Trail"
                                        />
                                    </Paper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer style={{}} />
                </div>

                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={viewHistorys}
                    onClose={handleHistoryClose}
                    TransitionComponent={Transition}
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={handleHistoryClose}
                        style={{ textAlign: "center" }}
                    >
                        Onboard History
                    </DialogTitle>
                    <hr />
                    <DialogContent>
                        <div>
                            <Paper
                                elevation={24}
                                style={{ height: "520px", padding: "10px" }}
                            >
                                {/* <Typography variant="h5" style={{ textAlign: "center" }}>
                    DOCUMENT APPROVAL STATUS
                  </Typography>  */}

                                <MaterialTable
                                    columns={[
                                        {
                                            title: "Onboard Status",
                                            field: "currentAction",
                                            filtering: false,
                                            render: (row) => (
                                                <div style={{ width: "140px" }}>
                                                    {row.currentAction === onboardState.init.state ? (
                                                        <span style={{ color: "green" }}>
                                                            {" "}
                                                            {onboardState.init.message}
                                                        </span>
                                                    ) : row.currentAction ===
                                                        onboardState.upload.state ? (
                                                        <span style={{ color: "blue" }}>
                                                            {onboardState.upload.message}
                                                        </span>
                                                    ) : row.currentAction === onboardState.dr_info.state ? (
                                                        <span style={{ color: "blue" }}>
                                                            {onboardState.dr_info.message}
                                                        </span>
                                                    ) : row.currentAction === onboardState.hr.state ? (
                                                        <span style={{ color: "blue" }}>
                                                            {onboardState.hr.message}
                                                        </span>
                                                    )
                                                        : row.currentAction === onboardState.ch.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.ch.message}
                                                            </span>
                                                        ) : row.currentAction === onboardState.rd.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.rd.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            onboardState.done.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.done.message}
                                                            </span>
                                                        ) : row.currentAction ===
                                                            onboardState.ch_doc.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {/* {onboardState.ch_doc.message} */}
                                                                Documents to be verified by CH
                                                            </span>
                                                        ) : row.nextAction ===
                                                            onboardState.completed ? (
                                                            <span style={{ color: "blue" }}>
                                                                <span style={{ color: "black", backgroundColor: "green" }}>"COMPLETED"</span>
                                                            </span>
                                                        ) : row.currentAction ===
                                                            onboardState.ch_Ok.state ? (
                                                            <span style={{ color: "blue", backgroundColor: "green" }}>
                                                                {onboardState.ch_Ok.message}
                                                            </span>
                                                        ) : row.currentAction === onboardState.completed ? (
                                                            <span style={{ color: "black", backgroundColor: "green" }}>"COMPLETED"</span>
                                                        ) : row.currentAction ===
                                                            onboardState.ch_renew.state ? (
                                                            <span style={{ color: "blue" }}>
                                                                {onboardState.ch_renew.message}
                                                            </span>
                                                        ) : row.nextAction ===
                                                            onboardState.completed ? (
                                                            <span style={{ color: "blue" }}>
                                                                <span style={{ color: "black", backgroundColor: "green" }}>"COMPLETED"</span>
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                </div>
                                            ),
                                        },
                                        //   {
                                        //     title: "Next Action",
                                        //     field: "nextAction",
                                        //     filtering: false,
                                        //     render: (row) => (
                                        //       <div style={{ width: "140px" }}>
                                        //         {row.nextAction === onboardState.init.state ? (
                                        //           <span style={{ color: "green" }}>
                                        //             {" "}
                                        //             {onboardState.init.message}
                                        //           </span>
                                        //         ) : row.nextAction === onboardState.upload.state ? (
                                        //           <span style={{ color: "blue" }}>
                                        //             {onboardState.upload.message}
                                        //           </span>
                                        //         ) : row.nextAction === onboardState.hr.state ? (
                                        //           <span style={{ color: "blue" }}>
                                        //             {onboardState.hr.message}
                                        //           </span>
                                        //         ) : row.nextAction === onboardState.ch.state ? (
                                        //           <span style={{ color: "blue" }}>
                                        //             {onboardState.ch.message}
                                        //           </span>
                                        //         ) : row.nextAction === onboardState.rd.state ? (
                                        //           <span style={{ color: "blue" }}>
                                        //             {onboardState.rd.message}
                                        //           </span>
                                        //         ) : row.nextAction === onboardState.done.state ? (
                                        //           <span style={{ color: "blue" }}>
                                        //             {onboardState.done.message}
                                        //           </span>
                                        //         ) : row.nextAction === onboardState.ch_doc.state ? (
                                        //           <span style={{ color: "blue" }}>
                                        //             {onboardState.ch_doc.message}
                                        //           </span>
                                        //         ) : row.nextAction === onboardState.completed ? (
                                        //           <span style={{ color: "green" }}>"COMPLETED"</span>
                                        //         ) : row.nextAction ===
                                        //           onboardState.ch_renew.state ? (
                                        //           <span style={{ color: "blue" }}>
                                        //             {onboardState.ch_renew.message}
                                        //           </span>
                                        //         ) : (
                                        //           ""
                                        //         )}
                                        //       </div>
                                        //     ),
                                        //   },
                                        {
                                            title: "Action By",
                                            field: "currentActionBy",
                                            render: (row) =>
                                            (<div style={{ width: "140px" }}>
                                                {row.currentActionBy == 2 ? (
                                                    <span style={{ color: "blue" }}>
                                                        {" "}
                                                        CH
                                                    </span>
                                                ) : row.currentActionBy == 1 || row.currentActionBy == 520 ? (
                                                    <span style={{ color: "blue" }}>
                                                        {" "}
                                                        COO
                                                    </span>
                                                ) : row.currentActionBy == 3 ? (
                                                    <span style={{ color: "blue" }}>
                                                        {" "}
                                                        HR
                                                    </span>
                                                ) : row.currentActionBy == row.doctorId ? (
                                                    <span style={{ color: "blue" }}>
                                                        {" "}
                                                        {row.name}
                                                    </span>
                                                ) : ("")}</div>)
                                        },
                                        {
                                            title: "Comments",
                                            field: "action",
                                            render: (row) => (
                                                <div style={{ width: "140px" }}>
                                                    {row.remark === "" || row.remark === null ? (
                                                        <div style={{ color: "blue" }}>
                                                            ---
                                                        </div>
                                                    ) :
                                                        (
                                                            <div style={{ color: "green" }}>
                                                                {row.remark}
                                                            </div>
                                                        )

                                                    }
                                                </div>
                                            ),
                                        },
                                        //   {
                                        //     title:"Process Completed",
                                        //     field:"currentActionBy",
                                        //     render: (row)=>
                                        //     (<div style={{ width: "140px" }}>
                                        //         {row.nextAction == "COMPLETED" ? (
                                        //       <span style={{ color: "green" }}>
                                        //         {" "}
                                        //         COMPLETED
                                        //       </span>
                                        //     ) :(<span style={{ color: "blue" }}>PENDING</span>)}</div>)
                                        // },
                                        {
                                            title: "Approved On",
                                            field: "createdAt",
                                            render: (row) =>
                                            (<div style={{ width: "140px" }}>
                                                {row.createdAt ? moment(row.createdAt).format('DD-MM-YYYY') : "---"}</div>)
                                        }
                                    ]}
                                    data={oboard.onBoardData}
                                    options={{
                                        sorting: true,
                                        exportButton: false,
                                        pageSize: 5,
                                        pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                        search: false,
                                    }}
                                    title=""
                                // title="DOCUMENT APPROVAL STATUS"
                                />
                            </Paper>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={handleHistoryClose}
                            color="secondary"
                            variant="contained"
                            size="small"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Modal
                    size="lg"
                    scrollable
                    dialogClassName="modalStandard"
                    centered
                    aria-labelledby="example-modal-sizes-title-lg"
                    show={show}
                    onHide={handleClose}
                >
                    <Modal.Header className="modalHeader" closeButton>
                        <Modal.Title className="modalTitle">
                            Requested Id:{findpos.id}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* {addpos.data && addpos.data.map((ele,index) =>( */}

                        <div className="row">
                            <div className="col-6 ">
                                <label className="tx-inverse  ">Onboarding Type</label>
                                <input
                                    className="form-control"
                                    id="RequestType"
                                    placeholder="Input box"
                                    value={findpos.category}
                                    type="text"
                                    style={{ color: "black" }}
                                    readOnly
                                />
                            </div>

                            <div className="col-6 ">
                                <label className="tx-inverse  ">Request Raised By</label>
                                <input
                                    className="form-control"
                                    id="RequestRaisedBy"
                                    placeholder="Input box"
                                    value={findpos.label}
                                    type="text"
                                    style={{ color: "black" }}
                                    readOnly
                                />
                                <br />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 ">
                                <label className="tx-inverse  ">Current Status</label>
                                <input
                                    className="form-control"
                                    id="CurrentStatus"
                                    placeholder="Input box"
                                    value={findpos.status === 1 ? "OPEN" : ""}
                                    type="text"
                                    disabled
                                    style={{ color: "black" }}
                                    readOnly
                                />
                            </div>
                            <div className="col-6 ">
                                <label className="tx-inverse  ">Designation</label>
                                <input
                                    className="form-control"
                                    id="Subject"
                                    placeholder="Input box"
                                    value={findpos.designationLabel}
                                    type="text"
                                    style={{ color: "black" }}
                                    readOnly
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-6 ">
                                <label className="tx-inverse  ">Department</label>
                                <input
                                    className="form-control"
                                    id="Subject"
                                    placeholder="Input box"
                                    value={findpos.departmentLabel}
                                    type="text"
                                    style={{ color: "black" }}
                                    readOnly
                                />
                            </div>

                            <div className="col-6 ">
                                <label className="tx-inverse  ">Center Name</label>
                                <input
                                    className="form-control"
                                    id="CurrentStatus"
                                    placeholder="Input box"
                                    value={findpos.centerNames}
                                    type="text"
                                    style={{ color: "black" }}
                                    readOnly
                                />
                            </div>
                        </div>

                        <br />
                        <label className="tx-inverse  ">Comments</label>
                        <textarea
                            class="form-control"
                            value={findpos.comments}
                            id="comments"
                            placeholder="Textarea"
                            rows="3"
                            style={{ color: "black" }}
                            readOnly
                        ></textarea>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center">
                        {console.log(findpos, "findpos=====>")}
                        <Link
                            to={{
                                // pathname: "/create-doctor",
                                pathname: "/onboard-doctor",
                                state: { data: findpos },
                            }}
                            className="standardButton acceptButton pt-2"
                            style={{ textAlign: "center" }}
                        >
                            Initiate Onboarding
                        </Link>

                        <button className="standardButton rejectButton" onClick={handleClose}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

                <Dialog
                    fullScreen
                    open={viewAgreement}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="end"
                                style={{ marginLeft: "99%" }}
                                color="inherit"
                                onClick={closeAgreement}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <List>
                        {categoty === "Full Time Consultant" || categoty === 'Retainer' ? (
                            <FixedfullTime
                                history={row}
                                recData={docData}
                                catagory={categoty}
                            />
                        ) : categoty === "Minimum Guarentee(MG)" ? (
                            <FixedMG history={row} recData={docData} catagory={categoty} />
                        ) : categoty === "Visiting Consultant" ? (
                            <VisitingCon history={row} recData={docData} catagory={categoty} />
                        ) : categoty === "Visiting Consultant Chennai" ? (
                            <VcChennai history={row} recData={docData} catagory={categoty} />
                        ) : (
                            "hi"
                        )}
                        {/* <Test history={row} recData={docData} catagory={categoty} /> */}
                    </List>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={jCShow}
                    onClose={confirmJoiningClose}
                    TransitionComponent={Transition}
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={confirmJoiningClose}
                        style={{ textAlign: "center" }}
                    >
                        Joining Confirmation
                    </DialogTitle>
                    <hr />
                    <DialogContent>
                        <div>
                            <Row>
                                <Col md={2}>
                                    {" "}
                                    <label>
                                        <b>Joining Date:</b>
                                    </label>
                                </Col>
                                <Col md={5}>
                                    <input
                                        type="date"
                                        id="joiningdate"
                                        style={{ color: "#000000" }}
                                        name="confirmdoj"
                                        min={commencement && commencement[0] && commencement[0].doj && commencement[0].doj.substr(0, 10)}
                                        className="form-control"
                                        onChange={selectedValue}
                                        required
                                    />
                                </Col>
                            </Row>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={confirm}
                            style={{
                                backgroundColor: "#810e36",
                                color: "white",
                            }}
                            variant="contained"
                            size="small"
                        >
                            Confirm
                        </Button>
                        <Button
                            autoFocus
                            onClick={confirmJoiningClose}
                            color="secondary"
                            variant="contained"
                            size="small"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* confirm Dialog */}
                <ConfirmDialog
                    confirmDialog={confirmDialog}
                    setConfirmDialog={setConfirmDialog}
                />
                <Dialog
                    style={{ overflowY: "unset" }}
                    open={open}
                    disableBackdropClick
                    maxWidth="sm"
                    fullWidth={true}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => {
                        setOpen(false);
                    }}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle
                        onClose={() => {
                            setOpen(false);
                        }}
                        id="alert-dialog-slide-title"
                        style={{
                            backgroundColor: "#810e36",
                            color: "white",
                            textAlign: "center",
                        }}
                    >
                        {"Action Required"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            id="alert-dialog-slide-description"
                            style={{ color: "black", textAlign: "center" }}
                        >
                            COO Approval required
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpen(false);
                            }}
                            color="primary"
                        ></Button>

                        <Button
                            onClick={() => {
                                setOpen(false);
                            }}
                            color="primary"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth="sm"
                    fullWidth={true}
                    open={viewFinancialDialog}
                    onClose={closeFinancialDialog}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Financial Grid</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <table style={{ width: "99%", border: "1px solid black" }}>
                                <tr >
                                    <td> </td>
                                    <th style={{ border: "1px solid black" }}>Min Professional Fees</th>
                                    <th style={{ border: "1px solid black" }}>Max Professional Fees</th>
                                </tr>
                                <tr>
                                    <th style={{ width: "20%", border: "1px solid black" }}>Pre-set</th>

                                    <td style={{ border: "1px solid black" }}>
                                        {rowData.fg_from ? "Rs. " + numberWithCommas(rowData.fg_from) : 0}
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                        {rowData.fg_to ? "Rs. " + numberWithCommas(rowData.fg_to) : 0}
                                    </td>
                                </tr>
                                <tr>
                                    <th style={{ width: "20%", border: "1px solid black" }}>Requested</th>

                                    <td style={{ border: "1px solid black" }}>
                                        {" "}
                                        {rowData.minsalary ? "Rs. " + numberWithCommas(rowData.minsalary) : 0}
                                    </td>
                                    <td style={{ border: "1px solid black" }}>
                                        {" "}
                                        {rowData.maxsalary ? "Rs. " + numberWithCommas(rowData.maxsalary) : 0}{" "}
                                    </td>
                                </tr>
                            </table>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                handleApproveDialog(rowData, 'financial_grid');
                            }}
                        >
                            Approve
                        </Button>
                        <Button
                            style={{
                                marginLeft: "10px",
                                backgroundColor: "#810e36",
                                color: "#fff",
                            }}
                            size="small"
                            color="error"
                            variant="contained"
                            onClick={() => {
                                handleRejectDialog(rowData, 'financial_grid');
                            }}
                        >
                            Review
                        </Button>
                        <Button onClick={closeFinancialDialog} color="secondary" size="small" variant="contained">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* APPROVE MODAL */}
                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    onClose={() => {
                        setOpenApprovemodal(false);
                    }}
                    aria-labelledby="customized-dialog-title"
                    open={openApprove}
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={() => {
                            setOpenApprovemodal(false);
                        }}
                        style={{ textAlign: "center" }}
                    >
                        Approve
                    </DialogTitle>
                    <DialogContent dividers>
                        <label>Comments </label>
                        <textarea
                            placeholder="Comments"
                            type="text"
                            onChange={handleComments}
                            style={{ color: "black", border: "1px solid black" }}
                            className="form-control"
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={handleApprove}
                            color="secondary"
                            variant="contained"
                            size="small"
                        >
                            Approve
                        </Button>
                        <Button
                            autoFocus
                            onClick={() => {
                                setOpenApprovemodal(false);
                            }}
                            color="secondary"
                            variant="contained"
                            size="small"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* REJECT MODAL */}
                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    onClose={() => {
                        setOpenRejectmodal(false);
                    }}
                    aria-labelledby="customized-dialog-title"
                    open={openReject}
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={() => {
                            setOpenRejectmodal(false);
                        }}
                        style={{ textAlign: "center" }}
                    >
                        Review
                    </DialogTitle>
                    <DialogContent dividers>
                        <label>Comments </label>
                        <textarea
                            placeholder="Comments"
                            type="text"
                            onChange={handleComments}
                            style={{ color: "black", border: "1px solid black" }}
                            className="form-control"
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={handleReject}
                            color="secondary"
                            variant="contained"
                            size="small"
                        >
                            Review
                        </Button>
                        <Button
                            autoFocus
                            onClick={() => {
                                setOpenRejectmodal(false);
                            }}
                            color="secondary"
                            variant="contained"
                            size="small"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Estimtions & Commercials Summary MODAL */}
                <Dialog
                    fullWidth={true}
                    maxWidth="xl"
                    onClose={handlerCloseEstimation}
                    aria-labelledby="customized-dialog-title"
                    open={openEstimation}
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={handlerCloseEstimation}
                        style={{ textAlign: "center" }}
                    >
                        <h5
                            style={{
                                margin: ".4rem",
                                textAlign: "center",
                                padding: "7px",
                                backgroundColor: "#810e36",
                                color: "white",
                            }}
                        >
                            <b>Estimations and Commercials Summary</b>
                        </h5>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Paper className={tables.root} style={{ border: "1px solid" }}>
                            <h5 className="p-2" style={{ fontWeight: "bold", borderBottom: "1px solid" }}>Estimations Summary</h5>
                            <MaterialTable
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    border: "none"
                                }}
                                columns={[
                                    {
                                        title: "Center Name",
                                        field: "center",
                                    },
                                    {
                                        title: "Item Group Name",
                                        field: "itemGroup",
                                    },
                                    {
                                        title: "Item  Name",
                                        field: "itemName",
                                        render: (row) => (
                                            <div>{row.itemName ? row.itemName : "---"}</div>
                                        ),
                                    },
                                    {
                                        title: "Discount Applicable",
                                        field: "opdiscounttype",
                                        render: (row) => (
                                            <div>
                                                {row.opdiscounttype ? row.opdiscounttype : "---"}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: "Deduction Value",
                                        field: "opadhoc",
                                        render: (row) => (
                                            <div>
                                                {row.opadhoc && row.opadhoc !== "0"
                                                    ? "Rs. " + numberWithCommas(row.opadhoc)
                                                    : "---"}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: "Fixed Amount",
                                        field: "fixedamount",
                                        render: (row) => (
                                            <div>
                                                {row.fixedamount && row.fixedamount !== "0"
                                                    ? "Rs. " + numberWithCommas(row.fixedamount)
                                                    : "---"}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: "Fixed Percentage",
                                        field: "fixedpercentage",
                                        render: (row) => (
                                            <div>
                                                {row.fixedpercentage ? row.fixedpercentage + "%" : "---"}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: "Total Revenue",
                                        field: "totalRevenue",
                                        render: (row) => (
                                            <div>
                                                {row.totalRevenue
                                                    ? "Rs. " + numberWithCommas(row.totalRevenue)
                                                    : "---"}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: "No. of Procedures",
                                        field: "opcount",
                                        render: (row) => (
                                            <div>{row.opcount ? row.opcount : "---"}</div>
                                        ),
                                    },
                                    // {
                                    //   title: "No. of Months",
                                    //   field: "opmonth",
                                    //   render: (row) => (
                                    //     <div>{row.opmonth ? row.opmonth : "---"}</div>
                                    //   ),
                                    // },
                                    {
                                        title: "Per Unit Charge",
                                        field: "opperunitcharge",
                                        render: (row) => (
                                            <div>
                                                {row.opperunitcharge
                                                    ? "Rs. " + numberWithCommas(row.opperunitcharge)
                                                    : "---"}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: "Gross",
                                        field: "opgross",
                                        render: (row) => (
                                            <div>
                                                {row.opgross
                                                    ? "Rs. " + numberWithCommas(row.opgross)
                                                    : "---"}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: "Net",
                                        field: "opnet",
                                        render: (row) => (
                                            <div>
                                                {row.opnet
                                                    ? "Rs. " + numberWithCommas(row.opnet)
                                                    : "---"}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: "Revenue",
                                        field: "oprevenue",
                                        render: (row) => (
                                            <div>
                                                {row.oprevenue
                                                    ? "Rs. " + numberWithCommas(row.oprevenue)
                                                    : "---"}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: "Payout",
                                        field: "oppayout",
                                        render: (row) => (
                                            <div>
                                                {row.oppayout
                                                    ? "Rs. " +
                                                    numberWithCommas(
                                                        parseFloat(row.oppayout).toFixed()
                                                    )
                                                    : "---"}
                                            </div>
                                        ),
                                    },
                                ]}
                                data={doctorOpDetail}
                                options={{
                                    sorting: true,
                                    search: false,
                                    paging: false,
                                    rowStyle: {
                                        fontSize: 12,
                                        padding: 0
                                    },
                                    cellStyle: {
                                        border: "1px solid rgba(224, 224, 224, 1)",
                                        padding: "3px 10px"
                                    },
                                    headerStyle: {
                                        border: "1px solid rgba(224, 224, 224, 1)",
                                        padding: "3px 10px",
                                        fontSize: 12,
                                    },
                                }}
                                title={<TableTitle text="Out-Patient Service Estimation" />}
                            />
                            {/* <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Item Name", field: "itemName" },
                { title: "Revenue", field: "oprevenue", render: (row) => row.oprevenue ? "Rs. " + numberWithCommas(parseFloat(row.oprevenue).toFixed()) : "" },
                { title: "Pay Out", field: "oppayout", render: (row) => row.oppayout ? "Rs. " + numberWithCommas(parseFloat(row.oppayout).toFixed()) : "" },
                { title: "Number of Months", field: "opmonth" },
                // { title: "Net Amount", field: "netamount" },
              ]}
              // data={doctorDetail.doctorOPParameter} setDoctorOpDetail
              data={doctorOpDetail}

              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.chstatus === true ? "orangered" : "#FFF",
                }),
              }}
              title="OP Details"
            /> */}
                            <MaterialTable
                                columns={[
                                    {
                                        title: "Package Name",
                                        field: "itemGroup",
                                    },
                                    {
                                        title: "Procedure Name",
                                        field: "itemName",
                                        render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>)
                                    },
                                    {
                                        title: "Room Type",
                                        field: "roomCategory",
                                        render: (row) => (<div>{row.roomCategory ? row.roomCategory : "---"}</div>)
                                    },

                                    {
                                        title: "Mode of Payment",
                                        field: "ipmodeofpayment",
                                        render: (row) => (<div>{row.ipmodeofpayment ? row.ipmodeofpayment : "---"}</div>)
                                    },
                                    {
                                        title: "Fixed Amount",
                                        field: "ipfixedamount",
                                        render: (row) => (<div>{row.ipfixedamount && row.ipfixedamount !== "0" ? "Rs. " + numberWithCommas(row.ipfixedamount) : "---"}</div>)
                                    },
                                    {
                                        title: "Deduction Value",
                                        field: "ipdeductaionvalue",
                                        render: (row) => (<div>{row.ipdeductaionvalue && row.ipdeductaionvalue !== "0" ? "Rs. " + numberWithCommas(row.ipdeductaionvalue) : "---"}</div>)
                                    },
                                    {
                                        title: "Discount Applicable",
                                        field: "ipdiscountType",
                                    },
                                    {
                                        title: "Surgeon Fee Percentage",
                                        field: "ipsurgeonfeeperc",
                                        render: (row) => (<div>{row.ipsurgeonfeeperc && row.ipsurgeonfeeperc !== "0" ? row.ipsurgeonfeeperc + '%' : "---"}</div>)
                                    },
                                    {
                                        title: "Package Amount Percentage",
                                        field: "ippackageamountperc",
                                        render: (row) => (<div>{row.ippackageamountperc && row.ippackageamountperc !== "0" ? row.ippackageamountperc + '%' : "---"}</div>)
                                    },
                                    {
                                        title: "Gross Percentage",
                                        field: "ipgrossbillpercentage",
                                        render: (row) => (<div>{row.ipgrossbillpercentage && row.ipgrossbillpercentage !== "0" ? row.ipgrossbillpercentage + "%" : "---"}</div>)
                                    },
                                    {
                                        title: "Net Percentage",
                                        field: "ipnetbillpercentage",
                                        render: (row) => (<div>{row.ipnetbillpercentage && row.ipnetbillpercentage !== "0" ? row.ipnetbillpercentage + "%" : "---"}</div>)
                                    },
                                    {
                                        title: "Net Bill Amount",
                                        field: "ipTotalNetBillAmount",
                                        render: (row) => (<div>{row.ipTotalNetBillAmount && row.ipTotalNetBillAmount !== "0" ? "Rs. " + numberWithCommas(row.ipTotalNetBillAmount) : "---"}</div>)
                                    },
                                    {
                                        title: "Amount",
                                        field: "ipTotalAmount",
                                        render: (row) => (<div>{row.ipTotalAmount ? "Rs. " + numberWithCommas(row.ipTotalAmount) : "---"}</div>)
                                    },
                                    {
                                        title: "Count",
                                        field: "count",
                                        render: (row) => (<div>{row.count ? row.count : "---"}</div>)
                                    },
                                    // { title: "Revenue", field: "revenue" },
                                    { title: "Payout", field: "payout", render: (row) => (<div>{row.payout ? "Rs. " + numberWithCommas(row.payout) : "---"}</div>) },
                                ]}
                                data={doctorIpDetail}
                                options={{
                                    sorting: true,
                                    search: false,
                                    paging: false,
                                    rowStyle: {
                                        fontSize: 12,
                                    },
                                    headerStyle: {
                                        border: "1px solid rgba(224, 224, 224, 1)",
                                        padding: "3px 10px",
                                        fontSize: 12,
                                    },
                                    cellStyle: {
                                        border: "1px solid rgba(224, 224, 224, 1)",
                                        padding: "3px 10px"
                                    },
                                }}
                                title={<TableTitle text="In-Patient Service Estimation" />}
                            />
                            {doctorMgDetails && doctorMgDetails.length > 0 ?
                                <MaterialTable
                                    columns={[
                                        {
                                            title: "Minimum Guarentee /Month",
                                            field: "minguranteepermonth",
                                            render: (row) => (<div>{row.minguranteepermonth && row.minguranteepermonth !== "0" ? "Rs. " + numberWithCommas(row.minguranteepermonth) : "---"}</div>)
                                        },
                                        {
                                            title: "MG Period In Years",
                                            field: "mgperiod",
                                            render: (row) => row.mgperiod && row.mgperiod !== "0" ? row.mgperiod + " years" : "---"
                                        },
                                        {
                                            title: "Settlement Calculation Period",
                                            field: "settlementperiod",
                                            render: (row) => row.settlementperiod ? row.settlementperiod : "---"
                                        },
                                        {
                                            title: "Settlement Payment Period",
                                            field: "settlementpaymentperiod",
                                            render: (row) => row.settlementpaymentperiod ? row.settlementpaymentperiod : "---"
                                        },
                                        {
                                            title: "Settlement Term",
                                            field: "settlementterm",
                                            render: (row) => row.settlementterm && row.settlementterm !== "0" ? "Rs. " + numberWithCommas(row.settlementterm) : "---"
                                        },
                                    ]}
                                    // data={doctorDetail.doctorIPParameter}
                                    data={doctorMgDetails}
                                    options={{
                                        // filtering: true,
                                        sorting: true,
                                        paging: false,
                                        search: false,
                                        rowStyle: {
                                            fontSize: 12,
                                        },
                                        headerStyle: {
                                            border: "1px solid rgba(224, 224, 224, 1)",
                                            padding: "3px 10px",
                                            fontSize: 12,
                                        },
                                        cellStyle: {
                                            border: "1px solid rgba(224, 224, 224, 1)",
                                            padding: "3px 10px"
                                        },
                                    }}
                                    title={<TableTitle text="Minimum Guarentee (MG) Estimation" />}
                                />
                                : ""}
                            {TotalPayOutValue && TotalPayOutValue.length > 0 ?
                                <MaterialTable
                                    columns={[
                                        {
                                            title: "Total Estimated Revenue",
                                            field: "TotalRevenue",
                                            render: (row) => (<div>{row.TotalRevenue && row.TotalRevenue !== "0" ? "Rs. " + numberWithCommas(row.TotalRevenue) : "---"}</div>)
                                        },
                                        {
                                            title: "Total OP",
                                            field: "TotalOP",
                                            render: (row) => (<div>{row.TotalOP && row.TotalOP !== "0" ? "Rs. " + numberWithCommas(row.TotalOP) : "---"}</div>)
                                        },
                                        {
                                            title: "Total IP",
                                            field: "TotalIP",
                                            render: (row) => (<div>{row.TotalIP && row.TotalIP !== "0" ? "Rs. " + numberWithCommas(row.TotalIP) : "---"}</div>)
                                        },
                                        {
                                            title: doctorFixedDetails && doctorFixedDetails.length > 0 ? "Fixed Amount /Month" : "Minimum Guarentee /Month",
                                            field: "TotalMGorFixedValue",
                                            render: (row) => row.TotalMGorFixedValue && row.TotalMGorFixedValue !== "0" ? "Rs. " + numberWithCommas(((row.TotalMGorFixedValue).toString()).replaceAll(',', '')) : "---"
                                        },
                                        {
                                            title: "Payout",
                                            field: "Payout",
                                            render: (row) => row.Payout && row.Payout !== "0" ? "Rs. " + numberWithCommas(row.Payout) : "---"
                                        }
                                    ]}
                                    // data={doctorDetail.doctorIPParameter}
                                    data={TotalPayOutValue}
                                    options={{
                                        // filtering: true,
                                        sorting: true,
                                        paging: false,
                                        search: false,
                                        rowStyle: {
                                            fontSize: 12,
                                        },
                                        headerStyle: {
                                            border: "1px solid rgba(224, 224, 224, 1)",
                                            padding: "3px 10px",
                                            fontSize: 12,
                                        },
                                        cellStyle: {
                                            border: "1px solid rgba(224, 224, 224, 1)",
                                            padding: "3px 10px"
                                        },
                                    }}
                                    title={<TableTitle text="Total Estimated Payout" />}
                                />
                                : ""}
                        </Paper>
                        <Paper className={tables.root} style={{ border: "1px solid" }}>
                            <h5 className="p-2" style={{ fontWeight: "bold", borderBottom: "1px solid" }}>Commercials Summary</h5>

                            <MaterialTable
                                columns={[
                                    { title: "Center Name", field: "center", render: (row) => (<div>{row.center ? row.center : "---"}</div>) },
                                    { title: "Item Group", field: "itemgroup", render: (row) => (<div>{row.itemgroup ? row.itemgroup : "---"}</div>) },
                                    { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
                                    { title: "Commercial Term", field: "commercialterm", render: (row) => (<div>{row.commercialterm ? row.commercialterm : "---"}</div>) },
                                ]}
                                data={doctorOpCommercial}
                                options={{
                                    // filtering: true,
                                    sorting: true,
                                    paging: false,
                                    search: false,
                                    rowStyle: {
                                        fontSize: 12,
                                    },
                                    headerStyle: {
                                        border: "1px solid rgba(224, 224, 224, 1)",
                                        padding: "3px 10px",
                                        fontSize: 12,
                                    },
                                    cellStyle: {
                                        border: "1px solid rgba(224, 224, 224, 1)",
                                        padding: "3px 10px"
                                    },
                                }}
                                title={<TableTitle text="Out-Patient Commercial" />}
                            />
                            <MaterialTable
                                columns={[
                                    { title: "Center Name", field: "Center", render: (row) => (<div>{row.Center ? row.Center : "---"}</div>) },
                                    { title: "Item Group", field: "itemGroup", render: (row) => (<div>{row.itemGroup ? row.itemGroup : "---"}</div>) },
                                    { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
                                    { title: "Room Category", field: "roomCategory", render: (row) => (<div>{row.roomCategory ? row.roomCategory : "---"}</div>) },
                                    { title: "MOP", field: "ipmodeofpayment", render: (row) => (<div>{row.ipmodeofpayment ? row.ipmodeofpayment : "---"}</div>) },
                                    { title: "Commercial Term", field: "commercialTerm", render: (row) => (<div>{row.commercialTerm ? row.commercialTerm : "---"}</div>) },
                                ]}
                                // data={doctorDetail.doctorIPParameter}
                                data={doctorIpDetail}
                                options={{
                                    // filtering: true,
                                    sorting: true,
                                    paging: false,
                                    search: false,
                                    rowStyle: {
                                        fontSize: 12,
                                    },
                                    headerStyle: {
                                        border: "1px solid rgba(224, 224, 224, 1)",
                                        padding: "3px 10px",
                                        fontSize: 12,
                                    },
                                    cellStyle: {
                                        border: "1px solid rgba(224, 224, 224, 1)",
                                        padding: "3px 10px"
                                    },
                                }}
                                title={<TableTitle text="In-Patient Commercials" />}
                            />
                            {doctorMgDetails && doctorMgDetails.length > 0 ?
                                doctorMgDetails.map((item) =>
                                    <MaterialTable
                                        columns={[
                                            { title: "Minimum Guarantee Payment: The Company agrees to pay the Consultant an amount of Rs. " + numberWithCommas(item.minguranteepermonth) + " (Rs. " + numberToEnglish((item.minguranteepermonth).replaceAll(',', '')) + ") per month (“Minimum Guarantee Amount”) or the “Calculated Earnings” whichever is higher subject to statutory deductions. ", field: "mgCommercial" },
                                        ]}
                                        // data={doctorDetail.doctorIPParameter}
                                        data={
                                            [
                                                {
                                                    mgCommercial: 'The Minimum Guarantee Amount will be paid for ' + item.mgperiod + ' months'
                                                },
                                                {
                                                    mgCommercial: 'The earnings shall be calculated on ' + item.settlementperiod + ' basis and difference amount (Calculated Earnings minus Minimum Guarantee), if any, shall be paid at the end of ' + item.settlementpaymentperiod + '.'
                                                },
                                            ]
                                        }
                                        options={{
                                            // filtering: true,
                                            sorting: true,
                                            paging: false,
                                            search: false,
                                            headerStyle: {
                                                border: "1px solid rgba(224, 224, 224, 1)",
                                                padding: "3px 10px",
                                                fontSize: 12,
                                                fontWeight: 400
                                            },
                                            rowStyle: {
                                                fontSize: 12,
                                            },
                                            cellStyle: {
                                                border: "1px solid rgba(224, 224, 224, 1)",
                                                padding: "3px 10px"
                                            },
                                        }}
                                        title={<TableTitle text={"Minimum Guarentee (MG) Commercial for " + item.Center} />}
                                    />
                                )
                                : ''}
                            {doctorFixedDetails && doctorFixedDetails.length > 0 ?
                                doctorFixedDetails.map((item) =>
                                    <MaterialTable
                                        columns={[
                                            { title: "Monthly Fixed Fee: The Company will pay an all-inclusive amount of Rs. " + numberWithCommas((item.professionalfeepermonth).replaceAll(',', '')) + " (Rs. " + numberToEnglish((item.professionalfeepermonth).replaceAll(',', '')) + ") per month. ", field: "fixedCommercial", width: "100%" },
                                        ]}
                                        // data={doctorDetail.doctorIPParameter}
                                        data={
                                            [
                                                {
                                                    fixedCommercial: item.additionalfeevalue != '0' ? "Additional Fee for " + item.additionalfeename + ": The Company will pay an additional amount of Rs. " + numberWithCommas(((item.additionalfeevalue).toString()).replaceAll(',', '')) + " (Rs. " + numberToEnglish(((item.additionalfeevalue).toString()).replaceAll(',', '')) + ") per month. " : ""
                                                },
                                            ]
                                        }
                                        options={{
                                            // filtering: true,
                                            sorting: false,
                                            paging: false,
                                            search: false,
                                            headerStyle: {
                                                border: "1px solid rgba(224, 224, 224, 1)",
                                                padding: "3px 10px",
                                                fontSize: 12,
                                                fontWeight: 400
                                            },
                                            rowStyle: {
                                                fontSize: 12,
                                            },
                                            cellStyle: {
                                                border: "1px solid rgba(224, 224, 224, 1)",
                                                padding: "3px 10px"
                                            },
                                        }}
                                        title={<TableTitle text={"Full Time Consultant Commercial for " + item.Center} />}
                                    />
                                )
                                : ''}
                        </Paper>
                        <br />
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                                handleApproveDialog(rowData, 'estimation');
                            }}
                        >
                            Approve
                        </Button>

                        <Button
                            style={{ marginLeft: '10px', backgroundColor: '#810e36', color: '#fff' }}
                            color="error"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                handleRejectDialog(rowData, 'estimation');
                            }}
                        >
                            Review
                        </Button>
                        <Button
                            autoFocus
                            onClick={handlerCloseEstimation}
                            color="secondary"
                            variant="contained"
                            size="small"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    onClose={handlerCloseInitialEstimation}
                    aria-labelledby="customized-dialog-title"
                    open={openInitialEstimation}
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={handlerCloseInitialEstimation}
                        style={{ textAlign: "center" }}
                    >
                        <h5
                            style={{
                                margin: ".4rem",
                                textAlign: "center",
                                padding: "7px",
                                backgroundColor: "#810e36",
                                color: "white",
                            }}
                        >
                            <b>Doctor Details</b>
                        </h5>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Paper className={tables.root}>
                            <MaterialTable
                                columns={[
                                    {
                                        title: "Edit", field: "action", render: (row) => (
                                            <EditIcon style={{ color: "#810e36" }} />
                                        )
                                    },
                                    { title: "Item Group", field: "itemGroup" },
                                    {
                                        title: "Item Name", field: "itemName", render: (row) => (
                                            <div>{row.itemName ? row.itemName : "---"}</div>
                                        ),
                                    },
                                    { title: "Revenue", field: "oprevenue", render: (row) => row.oprevenue ? "Rs. " + numberWithCommas(parseFloat(row.oprevenue).toFixed()) : "" },
                                    { title: "Pay Out", field: "oppayout", render: (row) => row.oppayout ? "Rs. " + numberWithCommas(parseFloat(row.oppayout).toFixed()) : "" },
                                    { title: "Number of Months", field: "opmonth" },
                                    // { title: "Net Amount", field: "netamount" },
                                ]}
                                // data={doctorDetail.doctorOPParameter} setDoctorOpDetail
                                data={doctorOpDetail}
                                options={{
                                    // filtering: true,
                                    sorting: true,
                                    exportButton: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                    search: true,
                                    exportAllData: true,
                                    rowStyle: (rowData) => ({
                                        backgroundColor:
                                            rowData.chstatus === true ? "orangered" : "#FFF",
                                    }),
                                }}
                                title="OP Details"
                            />
                            <br />
                            <MaterialTable
                                columns={[
                                    {
                                        title: "Edit", field: "action", render: (row) => (
                                            <EditIcon style={{ color: "#810e36" }} />
                                        ),
                                    },
                                    { title: "Item Group", field: "itemGroup" },
                                    {
                                        title: "Item Name", field: "itemName", render: (row) => (
                                            <div>{row.itemName ? row.itemName : "---"}</div>
                                        ),
                                    },
                                    {
                                        title: "Room Type", field: "iproom", render: (row) => (
                                            <div>{row.iproom ? row.iproom : "---"}</div>
                                        ),
                                    },
                                    { title: "Revenue", field: "revenue", render: (row) => row.revenue ? "Rs. " + numberWithCommas(parseFloat(row.revenue).toFixed()) : "" },
                                    { title: "Pay Out", field: "payout", render: (row) => row.payout ? "Rs. " + numberWithCommas(parseFloat(row.payout).toFixed()) : "" },
                                    { title: "Number of Months", field: "ipmonth" },
                                ]}
                                // data={doctorDetail.doctorIPParameter}
                                data={doctorIpDetail}
                                options={{
                                    // filtering: true,
                                    sorting: true,
                                    exportButton: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                    search: true,
                                    exportAllData: true,
                                    rowStyle: (rowData) => ({
                                        backgroundColor:
                                            rowData.chstatus === true ? "orangered" : "#FFF",
                                    }),
                                }}
                                title="IP Details"
                            />
                            <br />
                            <MaterialTable
                                columns={[
                                    { title: "Item Group", field: "itemgroup" },
                                    { title: "Commercial Term", field: "commercialterm" },
                                ]}
                                data={doctorOpCommercial}
                                options={{
                                    sorting: true,
                                    exportButton: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                    search: true,
                                    exportAllData: true,
                                    rowStyle: (rowData) => ({
                                        backgroundColor:
                                            rowData.chstatus === true ? "orangered" : "#FFF",
                                    }),
                                }}
                                title="OP Commercials"
                            />
                            <br />
                            <MaterialTable
                                columns={[
                                    { title: "Item Group", field: "itemgroup" },
                                    {
                                        title: "Room Category", field: "iproom", render: (row) => (
                                            <div>{row.iproom ? row.iproom : "---"}</div>
                                        ),
                                    },
                                    {
                                        title: "MOP", field: "ipmodeofpayment", render: (row) => (
                                            <div>{row.ipmodeofpayment ? row.ipmodeofpayment : "---"}</div>
                                        ),
                                    },
                                    { title: "Commercial Term", field: "commercialterm" },
                                ]}
                                // data={doctorDetail.doctorIPParameter}
                                data={doctorIpCommercial}
                                options={{
                                    sorting: true,
                                    exportButton: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                    search: true,
                                    exportAllData: true,
                                    rowStyle: (rowData) => ({
                                        backgroundColor:
                                            rowData.chstatus === true ? "orangered" : "#FFF",
                                    }),
                                }}
                                title="IP Commercials"
                            />
                        </Paper>
                        <br />
                        <br />
                    </DialogContent>
                    <DialogActions>
                        {(RequestDetail.CurrentActionOnboard == onboardState.doc_revise.state) || (RequestDetail.CurrentActionOnboard == agreementState.dr_decline.state) || (RequestDetail.CurrentActionOnboard == agreementState.finance_decline.state) ?
                            <Button
                                autoFocus
                                // onClick={updateRecord}
                                color="primary"
                                variant="contained"
                                size="small"
                            >
                                Update
                            </Button>
                            : null}
                        <Button
                            autoFocus
                            onClick={handlerCloseInitialEstimation}
                            color="secondary"
                            variant="contained"
                            size="small"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Backdrop
                    className={backClasses.backdrop}
                    open={backDropOpen}
                // onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div >
        </>
    );
}

export default History;
