// North 
// payable amount = X% of Net Amount 

function northRadiology(row, oboard) {
    let earnings = 0;
    var returns = 0;
    for (let i = 0; i < oboard.doctorOpCommercial.length; i++) {
        if (row.ItemGroupName.indexOf(oboard.doctorOpCommercial[i].itemgroup) >= 0) {
            let commercial = oboard.doctorOpCommercial[i].commercialterm
            if (commercial) {
                let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
                let ratio = parseFloat("" + row.Share);
                earnings = parseFloat(row.Net) * (perc / 100);
                returns=-(earnings);
            }
        }
    }
    return earnings;
}
// South

// payable amount = X% of (gross-discount)
function southRadiologyOne(row, oboard) {
    let earnings = 0;
    var returns = 0;
    for (let i = 0; i < oboard.doctorOpCommercial.length; i++) {
        if (row.ItemGroupName.indexOf(oboard.doctorOpCommercial[i].itemgroup) >= 0) {
            var commercial = oboard.doctorOpCommercial[i].commercialterm.includes("Gross");
            if (commercial) {
                let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
                let ratio = parseFloat("" + row.Share);
                let gross = parseInt(row.Gross) * (perc / 100);
                earnings = gross - parseInt(row.Discount);
                returns=-(earnings);
            }
            var commercial2 = oboard.doctorOpCommercial[i].commercialterm.includes("Net");
            if (commercial2) {
                let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage)
                earnings = (parseInt(row.Net) * (perc / 100)) + parseInt(row.PackageOverrun);
                returns=-(earnings);
                // earnings = (parseInt(row.Net) * (perc / 100)) - parseInt(row.Discount);
            }
           

        }
       
    }
    return earnings;
}

// West

// payable amount = X% of (gross-discount) only
function westRadiologyOne(row, oboard) {
    let earnings = 0;
    var returns = 0;
    for (let i = 0; i < oboard.doctorOpCommercial.length; i++) {
        if (row.ItemGroupName.indexOf(oboard.doctorOpCommercial[i].itemgroup) >= 0) {
            var commercial = oboard.doctorOpCommercial[i].commercialterm.includes("Gross");
            if (commercial) {
                let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage);
                let ratio = parseFloat("" + row.Share);
                let gross = parseInt(row.Gross) * (perc / 100);
                earnings = gross - parseInt(row.Discount);
                returns=-(earnings);
            }
            var commercial2 = oboard.doctorOpCommercial[i].commercialterm.includes("Net");
            if (commercial2) {
                let perc = parseInt(oboard.doctorOPParameter[i].fixedpercentage)
                earnings = (parseInt(row.Net) * (perc / 100));
                returns=-(earnings);
                // earnings = (parseInt(row.Net) * (perc / 100)) - parseInt(row.Discount);
            }
           
        }
        
    }
    return earnings;
}
exports.payoutCalcRadiology = {
    northRadiologyCalculation: northRadiology,
    southRadiologyOneCalculation: southRadiologyOne,
    westRadiologyOneCalculation: westRadiologyOne,
};