import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import DoctorSidebar from "../../Components/DoctorSidebar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";

import Container from "@material-ui/core/Container";
import PayoutSummaryCard from "../../Components/PayoutSummaryCard";
import { APIURL } from "../../CommomMethods/Fetch";
export default function DoctorWisePayout() {
  const [rows, setRows] = useState([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const [filteredRows, setFilteredRows] = useState([]);
  console.log(user, "****(");
  useEffect(() => {
    setLoading(false);
    axios
      .get(APIURL() + "billings/BillingDetRecords")
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows, "AllExcelData");
        setRows(rows.data);
        var k = rows.data.filter((z) => z.ConductedBy === user.user.username);
        setFilteredRows(k);
        setLoading(true);
      });
  }, [reload]);
  console.log(filteredRows, "123");
  return (
    <>
      <DoctorSidebar />

      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "82%" }}>
        <div
          style={{
            margin: ".4rem",
            textAlign: "center",
            padding: "7px",
            backgroundColor: "#810e36",
            color: "white",
          }}
        >
          <b>{user.user.username} Payout</b>
        </div>
        {!loading ? (
          <>
            <Grid container spacing={1} style={{ marginBottom: "40px" }}>
              <Grid item xs={4} sm={4} lg={4} md={4} xl={4}>
                {" "}
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={250}
                  height={100}
                />
              </Grid>
              <Grid item xs={4} sm={4} lg={4} md={4} xl={4}>
                {" "}
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={250}
                  height={100}
                />
              </Grid>
              <Grid item xs={4} sm={4} lg={4} md={4} xl={4}>
                {" "}
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={250}
                  height={100}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid container spacing={1} style={{ marginBottom: "40px" }}>
            {filteredRows &&
              filteredRows.map((opt, index) => (
                <Grid item xs={4} sm={4} lg={4} md={4} xl={4}>
                  <PayoutSummaryCard data={opt} detail="doctor" />
                </Grid>
              ))}
          </Grid>
        )}
      </Container>
    </>
  );
}
