import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo2 from "../images/Logos-04.png";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import { listusers } from "../actions/userActions";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

function CeoSidebar({ userType }) {
  const [dash, setDash] = useState("bh-dashboard");
  const [item4, setItem4] = useState("visibleDiv");
  const [item5, setItem5] = useState("visibleDiv");
  const [item6, setItem6] = useState("visibleDiv");
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  // bringing things from the reducer
  const { loading, error, user } = userLogin;
  function CheckUser() {
    if (userType === "BH") {
      setDash("bh-dashboard");
    } else if (userType === "HR") {
      setDash("hr-dashboard");
      setItem5("hiddenDiv");
    } else if (userType === "COO") {
      setDash("rd-dashboard");
      setItem4("hiddenDiv");
      setItem6("hiddenDiv");
    }
    useEffect(() => {
      dispatch(listusers());
    }, [dispatch]);
  }

  //  checkUser();

  // useEffect(() => {
  //   checkUser();
  //  } )

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <div class="sidebar main-sidebar main-sidebar-sticky side-menu ps ps--active-y">
      <div className="main-sidebar-header header-img">
        <img src={logo2} className="img-responsive" alt="logo" />
      </div>
      <div className="main-sidebar-body">
        <ul className="nav">
        <li
            className={
              splitLocation[1] === "CeoProfile" ? "active " + "nav-item" : "nav-item"
            }
          >
            <Link to="/CeoProfile" id="nav-link" className="nav-link">
              <span className="shape1"></span>
              <span className="shape2"></span>
              <i className="ti-user sidemenu-icon mr-2"></i>
              <span className="sidemenu-label">Profile</span>
            </Link>
          </li>
          <li
            className={
              splitLocation[1] === "CeoAgreement" ? "active " + "nav-item" : "nav-item"
            }
          >
            <Link to="/CeoAgreement" id="nav-link" className="nav-link">
              <span className="shape1"></span>
              <span className="shape2"></span>
              <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
              <span className="sidemenu-label">Approve Agreement</span>
            </Link>
          </li>

         
        </ul>
        

        <div
          style={{
            display: "block",
            width: "100%",
            marginTop: "-.5rem",
            marginLeft: "13px",
           
          }}
        >
         
        </div>
      </div>
    </div>
  );
}

export default CeoSidebar;
