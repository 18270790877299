import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navmain from "../../Components/Navbar";
import SidebarRD from "../../Components/Rd-sidebar";
import Footer from "../../Components/Footer";
import RequestTable from "../../Components/RequestTable";
import Piechart from '../../Components/PieChart'
import RequestCard from "../../Components/RequestCard";
import ChartComponent1 from "../../Components/ChartComponent";
import AreaChart from "../../Components/areaChart";
import ActivitiesCard from "../../Components/ActivitiesCard";
import StatsCard from "../../Components/statsCard";
import {
  createPositions,
  formlist,
  addpositions,
} from "../../actions/createPositionActions";
import { listusers } from "../../actions/userActions";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
function RdDashboard({ reqTableData, history }) {
  toast.configure();
  const [newVis, setNewVis] = useState("hiddenDiv");
  const [ongVis, setOngVis] = useState("hiddenDiv");
  const [compVis, setCompVis] = useState("hiddenDiv");
  const dispatch = useDispatch();

  const [data, setData] = useState({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [doctorCount, setDoctorCount] = useState([]);
  const [count, setCount] = useState([]);
  const [centerData, setCenterData] = useState([]);
  const [centerposition, setCenterposition] = useState([]);
  const [statusCount, setStatusCount] = useState([]);
  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  const userloginid = users && users[0] && users[0].id;
  console.log(userloginid, "user  login   id");

  useEffect(() => {
    axios.get(APIURL() + "onboard/onboardCountRecords").then((res) => {
      setCount(res.data);
    });
    axios
      .get(APIURL() + "onboard/onboardStatus")
      .then((res) => {
        setStatusCount(res.data.data);
        console.log(res.data.data, "res.data.data");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, []);

  useEffect(() => {
    getposition()
  }, [userloginid]);


  function getAllDoctorCount() {
    axios
      .get(APIURL() + "doctor/")
      .then((res) => res.data)
      .then((rows) => {
        setDoctorCount({ rows });

      });
    //
  }
  useEffect(() => {
    getAllDoctorCount();
  }, []);

  const uploadDoc = useSelector((state) => state.uploadDoc);
  const { upload } = uploadDoc;

  const formList = useSelector((state) => state.formList);
  const { form, success } = formList;


  const getposition = () => {
    axios
      .get(
        APIURL() + `centerpositionmaps/findAllCenterPositionByID/${userloginid}`
      )
      .then((res) => {
        console.log(res.data.data, "center map data");
        const fetchdata = res.data.data
        // const newFilter =fetchdata.filter(z =>{
        //   z.openposition > 0
        // })
        // console.log(newFilter,"fetchdata");
        setCenterposition(fetchdata);
        console.log()
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  const cardStyle = {
    background: "transparent",
    border: "0px",
  };
  const infoStyle = {
    border: "2px solid #858585",
  };
  const subTitle = {
    fontSize: "13px",
  };

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(formlist());
  }, [dispatch]);

  const handleCreatePosition = () => {
    dispatch(createPositions(data));
    toast("Success");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };


  function tabChange(stat) {
    if (stat == "new") {
      if (newVis == "hiddenDiv") {
        setNewVis("visibleDiv");
        setOngVis("hiddenDiv");
        setCompVis("hiddenDiv");
      } else {
        setNewVis("hiddenDiv");
      }
    }
  }
  var testdata = newdata;
  var newdata = [];
  function selectedValue(e) {
    newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);

    //  var selectedcenter= document.getElementById('centerlist').value;
    //  var selecteddept = document.getElementById('departmentlist').value;
    //  var selecteddesignation = document.getElementById('designationlist').value;
    //  var selectedtype = document.getElementById('typelist').value;
  }
  const info = count && count.data && count.data.onboardedInprogressCount && count.data.onboardedCount[0] && count.data.onboardedCount[0].onboaredCount;
  const info2 = count && count.data && count.data.onboardedInprogressCount && count.data.onboardedInprogressCount[0] && count.data.onboardedInprogressCount[0].onboaredprogressCount;
  return (
    <>
      {/* <RdSidebar /> */}
      <SidebarRD />

      <div className="main-content side-content pt-0">
        <div className="container-fluid">
          <div className="inner-body">
            <Navmain />

            <div className="page-header">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">Welcome {users && users[0] && users[0].name}</h2>
                <br />
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="dashboard">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Dashboard
                  </li>
                </ol>
              </div>
            </div>
            {/* new request */}
            {/* <div className="justify-content-center"> */}
            <div className="row wrapstylerd">
              <div className="col-md-4 requestCol linebreak colbreakreq">
                  {/* <div id=" requestCol-md"> */}
                  <a className="requestCard" onClick={() => tabChange("new")}>
                    <RequestCard
                      onClick={() => tabChange("new")}
                      reqType="new"
                      datalist={statusCount}
                    // num={5}
                    />
                  </a>
                  {/* </div> */}
                
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12 requestCol linebreak colbreakrstat">
                {/* <div className="col-md-8 ">
                <div id=" requestCol-md"> */}
                  <StatsCard />
                </div>
                {/* </div> */}
              </div>
            {/* </div> */}

            {/* hidden new requests table */}
            <div className={`${newVis}`} id="newReqTable">
              <RequestTable
                reqTableData={reqTableData}
                reqType="new"
                history={history}
              />
            </div>

            <div className={`${ongVis}`} id="ongReqTable">
              <RequestTable reqTableData={reqTableData} reqType="ongoing" />
            </div>

            <div className={`${compVis}`} id="compReqTable">
              <RequestTable reqTableData={reqTableData} reqType="completed" />
            </div>
            <Row style={{ marginTop: "100px" }}>
              <Col >
                <div className="row heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                  <div className="col-xl-3 col-md-3 col-xs-3 col-lg-3 col-sm-3 div-style-rd onboardeddoc" >

                    <Card className="p-12" style={infoStyle}>
                      <Card.Header
                        style={{
                          paddingTop: "5px",
                          paddingLeft: "5px",
                          paddingBottom: "5px",
                          borderBottom: "1px solid #858585",
                        }}
                      >
                        <h4 className="wraptext">Onboarded Doctors</h4>
                      </Card.Header>
                      <Card.Body style={{ margin: "0px" }}>
                        <CircularProgressbarWithChildren
                          value={count && count.data && count.data.onboardedInprogressCount && count.data.onboardedCount[0] && count.data.onboardedCount[0].onboaredCount}
                          strokeWidth={6}
                          styles={buildStyles({
                            pathColor: "rgb(98, 89, 202, 0.8)",
                            trailColor: "transparent",
                          })}
                        >
                          {/*
															  Width here needs to be (100 - 2 * strokeWidth)% 
															  in order to fit exactly inside the outer progressbar.
															*/}
                          <div style={{ width: "88.5%" }}>
                            <CircularProgressbar
                              value={20}
                              text={info}
                              styles={buildStyles({
                                pathColor: "rgba(186, 8, 66, 0.8)",
                                trailColor: "transparent",
                                textSize: "10px",
                              })}
                            />
                          </div>
                        </CircularProgressbarWithChildren>
                      </Card.Body>
                    </Card>

                  </div>



                  <div className="col-xl-3 col-md-3 col-xs-3 col-lg-3 col-sm-3 onboardinprog" >
                    {/* <Col className="col-md-6 col-sm-6 col-xs-6"> */}
                    <Card className="p-12" style={infoStyle}>
                      <Card.Header
                        style={{
                          paddingTop: "5px",
                          paddingLeft: "5px",
                          paddingBottom: "5px",
                          borderBottom: "1px solid #858585",
                        }}
                      >
                        <h4 className="wraptext">Onboarding Progress</h4>
                      </Card.Header>
                      <Card.Body style={{ margin: "0px" }}>
                        <CircularProgressbarWithChildren
                          value={count && count.data && count.data.onboardedInprogressCount && count.data.onboardedInprogressCount[0] && count.data.onboardedInprogressCount[0].onboaredprogressCount}
                          strokeWidth={6}
                          styles={buildStyles({
                            // pathColor: "rgb(98, 89, 202, 0.8)",
                            trailColor: "transparent",
                          })}
                        >
                          {/*
															  Width here needs to be (100 - 2 * strokeWidth)% 
															  in order to fit exactly inside the outer progressbar.
															*/}
                          <div style={{ width: "88.5%" }}>
                            <CircularProgressbar
                              value={20}
                              text={info2}
                              styles={buildStyles({
                                pathColor: "rgba(186, 8, 66, 0.8)",
                                trailColor: "transparent",
                                textSize: "10px",
                              })}
                            />
                          </div>
                        </CircularProgressbarWithChildren>
                      </Card.Body>
                    </Card>

                  </div>


                  <div className="col-xl-6 col-md-6 col-xs-6 col-lg-6 col-sm-6 " style={{marginTop: "-59px"}}> 
                    {/* <div className="row vacancystyle vacancystyle-md" > */}
                    <div className="card ht-xs-90p" style={infoStyle}>
                      <div style={{
                        height: "342px",
                        overflowY: "scroll"
                      }}>
                        {/* <ChartComponent1 datalist={doctorCount && doctorCount.rows && doctorCount.rows.data2 && doctorCount.rows.data2.length} /> */}
                        <h4 style={{ textAlign: "center" }}>Vacancy</h4>
                        <table
                          style={{
                            fontFamily: " arial, sans-serif",
                            borderCollapse: "collapse",
                            width: " 100%",

                          }}
                        >
                          <tr style={{ backgroundColor: "#7f0c348c" }}>
                            <td
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Center
                          </td>
                            <td
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Doctor Type
                          </td>
                            <td
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Department
                          </td>
                            <td
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Open Position
                          </td>

                            <td
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Professional Fees (Minimum)
                          </td>
                            <td
                              style={{
                                border: "1px solid #dddddd",
                                textAlign: "left",
                                padding: "8px",
                              }}
                            >
                              Professional Fees (Maximum)
                          </td>
                          </tr>

                          {centerposition.length > 0 ? (
                            centerposition.map((item) => (
                              <tr>
                                {/* {
                                item.allocated - item.occupiedposition === 0 || item.allocated - item.occupiedposition < 0 ? (
                                  <>
                                    <div className="col-12">
                                      <span style={{
                                        color: "orangered",
                                        textAlign: "left",
                                        padding: "8px",
                                      }}>
                                      </span>
                                    </div>
                                  </>) :
                                  (<> */}
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.centername}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.category}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.departmentname}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.openposition}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.fg_from}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #dddddd",
                                    textAlign: "left",
                                    padding: "8px",
                                  }}
                                >
                                  {item.fg_to}
                                </td>
                                {/* </>
                                  )} */}
                              </tr>
                            ))
                          ) : (
                            <tr><td colspan={5} style={{ textAlign: "center", margin: "20px" }}>No Records</td></tr>
                          )}
                        </table>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Card style={cardStyle}>
              <Card.Body>
                <div >
                  {/* <div className="row divalign-md"> */}
                  <div
                    className="col-xl-4 col-md-4"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "-73px"
                    }}
                  >
                    <Container style={{ display: "flex" }}>
                      {/* <Row className="Card-align Card-align-md"> */}

                      {/* <Row> */}
                      {/* <Col className="alignonboard alignonboarded-md "> */}

                      {/* <Col className="alignonboard alignonboard-md alignonboard-sm rd-align-md"> */}

                      {/* </Row> */}
                      {/* <Row>
												<Col>
													<Card className="p-6" style={infoStyle}>
														<Card.Body>
															<Card.Title>Card title</Card.Title>
															<Card.Text>
																This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
															</Card.Text>
														</Card.Body>
													</Card>
												</Col>
												<Col>
													<Card className="p-6" style={infoStyle}>
														<Card.Body>
															<Card.Title>Card title</Card.Title>
															<Card.Text>
																This is a wider card with supporting text below as a natural lead-in to
																additional content. This content is a little bit longer.
															</Card.Text>
														</Card.Body>
													</Card>
												</Col>
											</Row> */}
                      {/* <br></br> */}

                      {/* <Row>
                        <br />
                        <Col>
                          <div>
                            <br />
                            <div className="row row-sm">
                              <div className="col-sm-6 col-md-12 col-lg-12 col-xl-12 grid-margin">
                                <div className="card custom-card">

                                </div>
                              </div>

                            </div>
                          </div>
                        </Col>
                      </Row> */}
                    </Container>
                  </div>
                  {/* <Row className="col-md-12 col-sm-12 col-xs-12"> */}

                  {/* </div> */}
                  {/* </Row> */}
                </div>
                {/* <br></br> */}



                {/* <div className="reqcard reqcard-md reqcard-xl"> */}
                  <div className="row" style={{ marginTop: "-230px" }}>
                    <div className="col-xl-12 col-md-12">
                      <div className="card ht-xs-100p" style={infoStyle}>
                        <AreaChart />
                      </div>
                    {/* </div> */}
                  </div>
                </div>

              </Card.Body>
            </Card>
           
            <br />
          </div>
        </div>
        <Footer style={{}} />
      </div>
      <Modal
        size="lg"
        scrollable
        dialogClassName="modalStandard"
        centered
        aria-labelledby="example-modal-sizes-title-lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="modalHeader" closeButton>
          {/* {success && <Message variant="success">{form.message}</Message>} */}
          <Modal.Title className="modalTitle">Create Position</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <label
                className="tx-inverse"
                style={{ textDecoration: "underline" }}
              >
                Select a Designation
              </label>
              <select
                className="form-control"
                style={{ color: "black" }}
                id="designationlist"
                onChange={selectedValue}
              >
                {form.designationList &&
                  form.designationList.map((ele) => (
                    <option value={ele.name}>{ele.name}</option>
                  ))}
              </select>
              <br />
            </div>
            <div className="col-6 ">
              <label
                className="tx-inverse"
                style={{ textDecoration: "underline" }}
              >
                Select a Position Type
              </label>
              <select
                className="form-control"
                style={{ color: "black" }}
                id="typelist"
                onChange={selectedValue}
              >
                <option>Minimum Guarentee(MG)</option>
                <option>Visiting Consultant</option>
                <option>Full Time Consultant</option>
                <option>Retainer</option>
                {/* {form.typeList && form.typeList.map((ele) => ( */}
                {/* <option value={ele.id}>{ele.name}</option> */}
                {/* ))} */}
              </select>
            </div>
            <div className="col-6 ">
              <label
                className="tx-inverse"
                style={{ textDecoration: "underline" }}
              >
                Select a Specialization
              </label>
              <select
                className="form-control"
                style={{ color: "black" }}
                id="departmentlist"
                onChange={selectedValue}
              >
                {form.departmentList &&
                  form.departmentList.map((ele) => (
                    <option value={ele.name}>{ele.name}</option>
                  ))}
              </select>
            </div>
            <div className="col-6">
              <label
                className="tx-inverse"
                style={{ textDecoration: "underline" }}
              >
                Select the Branch
              </label>
              <select
                className="form-control"
                style={{ color: "black" }}
                id="centerlist"
                onChange={selectedValue}
              >
                {users[0] &&
                  users[0].centers &&
                  users[0].centers.map((ele, index) => (
                    <option value={ele.name}>{ele.name}</option>
                  ))}
              </select>
              <br />
            </div>
            <div className="col-12">
              <label
                className="tx-inverse"
                style={{ textDecoration: "underline" }}
              >
                Reason for Position Creation
              </label>
              <textarea
                style={{ color: "black" }}
                class="form-control"
                placeholder="Textarea"
                rows="3"
                onChange={selectedValue}
              ></textarea>
            </div>
          </div>
          {/* ))} */}
          <hr
            style={{ backgroundColor: "black", height: "1px", opacity: "0.4" }}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            className="btn btn-primary"
            style={{ width: "100px" }}
            onClick={handleCreatePosition}
          >
            Confirm
          </button>
          <button
            className="btn btn-dark"
            style={{ width: "100px" }}
            onClick={handleClose}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RdDashboard;
