import React, { useEffect, useState } from "react";
import { Card, Table, Toast } from "react-bootstrap";
import Navbar from "../../Components/Navbar";
import { Modal } from "react-bootstrap";
//import ui components
import { agreementState, onboardState } from "../../CommomMethods/States";
//import material-ui component
import Slide from "@material-ui/core/Slide";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
//import axios
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { listusers } from "../../actions/userActions";
import HrSidebar from "../../Components/hrSidebar";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import FixedfullTime from "../Payout/NEW/FixedfullTime";
import FixedMG from "../Payout/NEW/fixedMG";
import VcChennai from "../Payout/NEW/VcChennai";
import VisitingCon from "../Payout/NEW/VisitingCon";
import AssignmentIcon from '@material-ui/icons/Assignment';
import { APIURL } from "../../CommomMethods/Fetch";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    backgroundColor:"#810e36"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));
export default function CheckAgreement() {
  const [show, setShow] = useState(false);
  const [rows, setRows] = useState([]);
  const [orow, setOrow] = useState({});
  const [openr, setOpenr] = useState(false);
  const [remark, setRemark] = useState("");
  const [row, setRow] = useState({});
  const [categoty, setCategory] = useState("");
  const [docData, setDocData] = useState([]);
  const [viewAgreement, setViewAgreement] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    axios
      .get(APIURL() + "onboard/currentAgreementStatus/")
      .then((res) => {
        setRows(res.data.data);
      })
      .catch((error) => {
      });
  }, []);

  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  const handleClose = () => setShow(false);
  const handleShow = (doctorid) => {
    setShow(true);
  };

  const handleApprove = (row) => {
    const obj = {
      doctorId: row.doctorId,
      name: row.name,
      currentAction: agreementState.hr_accepted.state,
      currentActionBy: users[0].id,
      // nextAction: agreementState.completed,
      nextAction: agreementState.ch_approved.state,
      status: 1,
    };
    axios
      .post(APIURL() + "onboard/agreementAction/", obj)
      .then((res) => {
        window.location.reload();
        // toast.success("Successfully Confirmed");
      });
  };

  const handlerClickOpen = (row) => {
    setOrow(row);
    setOpenr(true);
  };
  const Remark = (e) => {
    setRemark(e.target.value);
  };

  const handlerClose = () => {
    setOpenr(false);
  };
  const handleSubmitReject = () => {
    const obj = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: agreementState.hr_accepted.state,
      currentActionBy: users[0].id,
      // nextAction: agreementState.hr_revision.state,
      nextAction: agreementState.hr_decline.state,
      remark: remark,
      status: 1,
    };

    axios
      .post(APIURL() + "onboard/agreementAction/", obj)
      .then((res) => {
        toast.success("Rejection successfully submitted");
      });

    const obj1 = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: agreementState.hr_decline.state,
      currentActionBy: users[0].id,
      nextAction: onboardState.ch.state,
      remark: remark,
      status: 1,
    };

    axios
      .post(APIURL() + "onboard/action/", obj1)
      .then((res) => {
        toast.success("Rectification successfully submitted");
      });

    window.location.reload();

    // ..................................
    // const obj = {
    //   doctorId: orow.doctorId,
    //   name: orow.name,
    //   currentAction: agreementState.hr_accepted.state,
    //   currentActionBy: users[0].id,
    //   nextAction: agreementState.dr_accepted.state,
    //   remark: remark,
    //   status: 1,
    // };

    // axios
    //   .post("onboard/agreementAction/", obj)
    //   .then((res) => {
    //     toast.success("Rejection successfully submitted");
    //   });
    // window.location.reload();
  };

  const closeAgreement = () => {
    setViewAgreement(false);
  };

  const viewAgreementDoc = (row) => {
    setRow(row);
    axios
      .get(APIURL() + "onboard/agreementView/" + row.doctorId)
      .then((res) => {
        if (res.data != null) {
          setCategory(res.data.data.vacancyData[0].category);
          setDocData(res.data.data);
        }
      });
    setViewAgreement(true);
  };

  return (
    <>
      <HrSidebar />

      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />

            <form>
              <br />
              <Card style={formStyle}>
                <Card.Body style={bodyStyle}>
                  <MaterialTable
                    columns={[
                      { title: "Doctor Name", field: "name" },

                      {
                        title: "Current Action",
                        field: "Action",
                        filtering: false,
                        render: (row) => (
                          <>{agreementState.dr_accepted.message}</>
                        ),
                      },

                      {
                        title: " View Agreements",
                        field: "Action",
                        filtering: false,
                        render: (row) => (
                          <AssignmentIcon  
          
                            style={{ color:"#810e36"}}
                            onClick={() => {
                              viewAgreementDoc(row);
                            }}
                          ></AssignmentIcon>
                        ),
                      },
                      {
                        title: "Action",
                        field: "Action",
                        filtering: false,
                        render: (row) =>
                          row.nextAction ===
                          agreementState.hr_accepted.state ? (
                            <div style={{ whiteSpace: "nowrap" }}>
                              <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  handleApprove(row);
                                }}
                              >
                                Approve
                              </Button>

                              <Button
                                style={{ marginLeft: "5px" }}
                                size="small"
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  handlerClickOpen(row);
                                }}
                              >
                                Rectification Required
                              </Button>
                            </div>
                          ) : (
                            <Button
                              style={{ marginLeft: "5px" }}
                              size="small"
                              color="secondary"
                              variant="contained"
                              disabled
                            >
                              No Action
                            </Button>
                          ),
                      },
                    ]}
                    data={rows}
                    // data={
                    //   rows && rows.filter((z) => z.nextAction === "HR_ACCEPTED")
                    // }
                    options={{
                      // filtering: true,
                      sorting: true,
                      exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    title="Agreement Details"
                  />
                </Card.Body>
              </Card>
            </form>
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Review
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Remark</b>
          </label>
          <textarea
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitReject}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={viewAgreement}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="end"
              style={{ marginLeft: "99%" }}
              color="inherit"
              onClick={closeAgreement}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          {categoty === "Full Time Consultant" || categoty === 'Retainer' ? (
            <FixedfullTime
              history={row}
              recData={docData}
              catagory={categoty}
            />
          ) : categoty === "Minimum Guarentee(MG)" ? (
            <FixedMG history={row} recData={docData} catagory={categoty} />
          ) : categoty === "Visiting Consultant" ? (
            <VisitingCon history={row} recData={docData} catagory={categoty} />
          ) : categoty === "Visiting Consultant Chennai" ? (
            <VcChennai history={row} recData={docData} catagory={categoty} />
          ) : (
            "hi"
          )}
        </List>
      </Dialog>
    </>
  );
}
