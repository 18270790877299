import React from 'react';

function Footer(props) {
    return (
        <div class="main-footer d-flex align-items-end justify-content-center">
            {/* <div class="container">
                <div class="row row-sm">
                    <div class="col-md-12"> */}
                        {/* <span>Copyright © 2021 <a href="#">Cloudnine</a> All rights reserved.</span> */}
                    {/* </div>
                </div>
            </div> */}
        </div>
    );
}

export default Footer;

