import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
// import { SnackbarContent } from "@material-ui/core";
import SnackbarContent from "@material-ui/core/SnackbarContent";
// axios
import axios from "axios";
//bootstrap
import { Container } from "react-bootstrap";

//ui components
import AddActivityAssignment from "./forms/AddActivityAssignment";
import ActivityAssignmentList from "./tables/ActivityAssignmentList";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/AppSidebar";
import { APIURL } from "../../CommomMethods/Fetch";
import { fontWeight } from "@mui/system";

const styleSheet = makeStyles((theme) => ({
  iconButtonStyle: {
    float: "right",
  },
  marginforMaster: {
    marginTop: "30px",
  },
}));

export default function ActivityAssignment() {
  const classes = styleSheet();
  //useStae for Snackbar
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    color: "",
    icon: "",
  });
  //variables for snack bar
  const { vertical, horizontal, open } = state;
  //function for snackbar
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const [preview, setPreview] = useState(true);
  const [allRoles, setAllRoles] = useState([]);
  const [activities, setActivies] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [ColumnHeadArray, setColumnHeadArray] = useState([]);
  const [EditData, setEditData] = useState({});
  const [variable, setVariable] = useState('Now');
  useEffect(() => {
    let ColumnHeader = { "Name": "" }
    let tableData = []
    let columnHeadArray = []
    axios
      .get(APIURL() + "usercentermap/getAllRoles")
      .then((res) => res.data)
      .then(async (rows) => {
        await rows.data.filter(el => el.name != 'Finance Team' && el.name != 'Legal Advisor').map(elem => {
          ColumnHeader = { ...ColumnHeader, [elem.code]: "-" }
        })
        await Object.keys(ColumnHeader).map((item) => {
          let obj = { title: item, field: item }
          console.log(ColumnHeader[item], "ColumnHeader[item]")
          if (item == "Name") {
            obj = {
              ...obj,
              width:"100%",
              cellStyle: {
                innerWidth: "20%",
              },
            }
          }
          columnHeadArray.push(obj)
        })
        setColumnHeadArray(columnHeadArray)
        setAllRoles(rows.data);
      });
    axios
      .get(APIURL() + "usercentermap/getAllActivities")
      .then((res) => res.data)
      .then(async (rows) => {
        await rows.data.map(async (elem) => {
          ColumnHeader = { ...ColumnHeader, Name: elem.activityName, ActivityId: elem.activityId }
          await tableData.push(ColumnHeader)
        })
        // console.log(tableData, "ColumnHeader")
        setActivies(rows.data);
      });
    axios
      .get(APIURL() + "usercentermap/getAllActivityAssignments")
      .then((res) => res.data)
      .then(async (rows) => {
        await rows.data.map((elem) => {
          if (tableData.find(item => item.ActivityId == elem.activityId)[elem.code]) {
            tableData.find(item => item.ActivityId == elem.activityId)[elem.code] = 'Yes'
          }
        })
        setTableData(tableData)
      });
  }, []);

  const editRow = (row) => {
    setPreview(false);
    setEditData(row)
    console.log(row, "department")
  };

  return (
    <>
      <Sidebar />
      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                className={classes.marginforMaster}
              >
                <Typography variant="h6">Manage Activity Assignments</Typography>
                {preview ? (
                  <Typography variant="body2">
                    Mapping / Activity Assignments / Activity Assignment List
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    Mapping / Activity Assignments / Mapping Activity Assignment
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.marginforMaster}
              >
                <div className={classes.iconButtonStyle}>
                  {preview == false ?
                    <IconButton color="primary" onClick={() => { setPreview(true) }}>
                      <ListIcon />
                    </IconButton>
                    : null}
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {preview ? (
                  <ActivityAssignmentList
                    tableData={tableData}
                    ColumnHeadArray={ColumnHeadArray}
                    editRow={editRow}
                  />
                ) : (
                  <AddActivityAssignment
                    EditData={EditData}
                    setPreview={setPreview}
                    setVariable = {setVariable}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={5000}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <SnackbarContent color={state.color} message={state.message} />
      </Snackbar>
    </>
  );
}
