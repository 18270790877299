import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
// import { SnackbarContent } from "@material-ui/core";
import SnackbarContent from "@material-ui/core/SnackbarContent";
// axios
import axios from "axios";
//bootstrap
import { Container } from "react-bootstrap";

//ui components
import AddPoolMapping from "./forms/AddPoolMapping";
import ListPoolMapping from "./tables/ListPoolMapping";
import Navbar from "../../Components/Navbar";
// import Sidebar from "../../Components/Sidebar";
import Sidebar from "../../Components/AppSidebar";
import { APIURL } from "../../CommomMethods/Fetch";

const styleSheet = makeStyles((theme) => ({
  iconButtonStyle: {
    float: "right",
  },
  marginforMaster: {
    marginTop: "30px",
  },
}));

export default function ManageUserMapCenter() {
  const classes = styleSheet();
  const dispatch = useDispatch();
  //useStae for Snackbar
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    color: "",
    icon: "",
  });
  //variables for snack bar
  const { vertical, horizontal, open } = state;
  //function for snackbar
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const initialFormState = {
    FirmId: 0,
    siteCode: '',
    doctorId: 0,
  };
  const [department, setDepartment] = useState(initialFormState);
  const [preview, setPreview] = useState(true);
  const [rows, setRows] = useState([]);
  const [ItemGroups, setItemGroups] = useState([]);
  const [ItemNames, setItemNames] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [data, setData] = useState(false);

  useEffect(() => {
    axios
      .get(APIURL() + "firms/get-all-pool-mapping")
      .then((res) => res.data)
      .then((rows) => {
        // console.log(rows,"rows.data")
        setRows(rows.data);
      });
    axios
      .get(APIURL() + "firms/get-all-item-names")
      .then((res) => res.data)
      .then((rows) => {
        // console.log(rows,"rows.data")
        setItemNames(rows.data);
      });
    axios
      .get(APIURL() + "firms/get-all-item-groups")
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows,"rows.data")
        setItemGroups(rows.data);
      });
    axios
      .get(APIURL() + "doctor/DoctorList")
      .then((res) => res.data)
      .then((rows) => {
        // console.log(rows,"rows.data")
        setDoctorList(rows);
      });
    axios
      .get(APIURL() + "centers/")
      .then((res) => res.data)
      .then((rows) => {
        setCenterList(rows.filter((center) => center.status));
      });
  }, [data]);

  //add designation
  const addDepartment = (department) => {
    setData(false);
    console.log(department,"insert")
    // return false
    axios.post(APIURL() + "firms/insert-pool-mapping", department).then((res) => {
      setPreview(true);
      setData(true);
      console.log(res, "user center map");
      if (res.status === 200) {
        setState({
          open: true,
          vertical: "top",
          horizontal: "center",
          message: res.data.message,
          color: "success",
        });
      }
    });
  };

  //deletion of designation
  const deleteDepartment = (row) => {
    console.log(row,"JKJKJKJKJKKKKJ")
    // return false
    setData(false);
    var k = window.confirm("Are you sure want to delete this record ?");
    if (k) {
      axios.post(APIURL() + "firms/delete-pool-mapping" , row).then((res) => {
        setData(true);
        if (res.status === 200) {
          setState({
            open: true,
            vertical: "top",
            horizontal: "center",
            message: res.data.message,
            color: "danger",
          });
        }
      });
    }
  };

  return (
    <>
      <Sidebar />
      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                className={classes.marginforMaster}
              >
                <Typography variant="h6">Manage Pool Mapping</Typography>
                {preview ? (
                  <Typography variant="body2">
                    Masters / Manage Pool Mapping / Pool Mapping List
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    Masters / Manage Pool Mapping / Add Pool Mapping
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                className={classes.marginforMaster}
              >
                <div className={classes.iconButtonStyle}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setPreview(true);
                    }}
                  >
                    <ListIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setPreview(false);
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {preview ? (
                  <ListPoolMapping
                    rows={rows}
                    deleteDepartment={deleteDepartment}
                  />
                ) : (
                  <AddPoolMapping
                    addDepartment={addDepartment}
                    ItemGroups={ItemGroups}
                    ItemNames={ItemNames}
                    doctorList = {doctorList}
                    centerList = {centerList}
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={5000}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <SnackbarContent color={state.color} message={state.message} />
      </Snackbar>
    </>
  );
}
