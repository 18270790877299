import React, { useState,useRef } from 'react';
import {Card, Container, Row, Col, Table} from 'react-bootstrap';
import Navbar from '../../../Components/Navbar';
import Sidebar from '../../../Components/Sidebar';
import FixedForm from '../../../Components/FixedForm';
import OpForm from '../../../Components/OpForm';
import IpForm from '../../../Components/IpForm';
import Footer from '../../../Components/Footer';
import { dateFormatter } from '../../../actions/createPositionActions';

function VcChennai(props) { 

const formStyle={
	borderRadius:"5px",
	border:"1px solid black"
}
const headerStyle={
	backgroundColor:"#94248E",
	margins:"0px",
	padding:"10px",
	color:"white",
	borderRadius:"5px",
	borderBottom:"3px black solid",
}
const bodyStyle={
	borderRadius:"5px",
}

console.log(props && props.recData,"props && props.recData")
    return (
        <>
        
            <div className="main-content  pt-0 mg-b-30">
                <div className="container-fluid">
                    <div className="inner-body">
                        <br />
                        <Container fluid style={{padding:"0px"}}>
                            <Card style={formStyle}>
                                <Card.Header style={headerStyle}>
                                    <h4>Dr. Test - Onboarding Agreement</h4>
                                    <h6 style={{fontWeight:"lighter"}}>Raised by - Business Head</h6>
                                </Card.Header>
                                <Card.Body className="bodyAgmt" style={{padding:"50px"}}>
                                    <Container fluid className="cardAgmt">
                                        <h6 className="titleAgmt"><strong>Contract for Professional Services</strong></h6>

                                        <p>This Contract for professional services is mutually agreed made and executed at <strong>Bengaluru</strong>, on the <strong>{props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[0]}<sup>{props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[0][1] == "1" ? "st" : props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[0][1] == "2" ? "nd" : props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[0][1] == "3" ? "rd" : "th"}</sup> day of {props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[1]=='01' ? "January" : props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[1]=='02' ? "February" : props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[1]=='03' ? "March" : props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[1]=='04' ? "April" : props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[1]=='05' ? "May" : props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[1]=='06' ? "June" : props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[1]=='07' ? "July" : props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[1]=='08' ? "August" : props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[1]=='09' ? "September" : props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[1]=='10' ? "October" : props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[1]=='11' ? "November" : props && props.recData && props.recData.doctorCommercial &&
                              dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[1]=='12' ? "December" : ""}, {props.recData && props.recData.doctorCommercial && dateFormatter(props.recData.doctorCommercial[0].doj).split('-')[2]}</strong></p>

                                        <p><strong>BETWEEN</strong></p>

                                        <p><strong>Kids Clinic India Private Limited</strong>, a company incorporated under the Indian Companies Act and having its registered office at No. 1533, 9<sup>th</sup> Main, Jayanagar 3<sup>rd</sup> Block, Bangalore - 560011 having its Company hospital, Chennai unit at <strong>No.54, opposite Hyatt Regency, Vijaya Raghava Road, T Nagar, Chennai, Tamil Nadu 600017 ("Chennai-T Nagar") </strong>hereinafter referred to as <strong>"Company"</strong> which term and expression shall mean and include its subsidiaries, affiliates, successors in title and assigns) of the <strong>FIRST PARTY</strong></p>

                                        <p><strong>AND</strong></p>

                                        <p><strong>Dr. Shantha G</strong> Herein after referred to as “Consultant” (which expression shall unless repugnant to the context, be deemed to include his/her successors, legal heirs, administrators, successors, executors and assigns) of the SECOND PARTY.</p>

                                        <p><strong>WHEREAS</strong></p>

                                        <div className="listAgmt">
                                            <p><strong>a. </strong>The Company is engaged in the business of rendering healthcare services under its brand “<strong>CLOUDNINE”</strong> herein after referred to as Hospital and in search of a Consultant to offer expert consultancy services in <strong>No.54, opposite Hyatt Regency, Vijaya Raghava Road, T Nagar, Chennai, Tamil Nadu 600017 ("Chennai-T Nagar").</strong></p>

                                            <p><strong>b. </strong>The Consultant has offered his/her willingness to provide his/her services as a professionally qualified Consultant in the field of <strong>- Obstetrics and Gynaecology</strong></p>

                                            <p><strong>c. </strong>The Company, desirous of availing the services of the Consultant, accepted the offer of the Consultant.</p>

                                            <p><strong>d. </strong>The Company and the Consultant have now agreed to enter into this agreement to govern the professional services to be rendered by the Consultant to “<strong>Cloudnine”</strong> on the following terms and conditions.</p>
                                        </div>

                                        <p>NOW THIS AGREEMENT WITNESSES AND IT IS HEREBY AGREED BY AND BETWEEN THE PARTIES HERETO AS FOLLOWS:</p>

                                        <p>1. The professional services of the Consultant are retained as a Visiting Consultant with effect and mutually agreed from 19 August 2021 to 18 August 2022. Unless terminated by either party, in accordance with the terms and conditions contained herein, this agreement shall be valid for a period of 12 months from the above said effective date. Further, this arrangement continues for a Period of 1 Year, both parties shall mutually discuss to execute a fresh agreement on such terms that may be agreed upon by and between the parties.</p>

                                        <p>2. The Consultant shall provide his/her professional services to both in patient and out- patients of the Hospital located at Chennai.</p>

                                        <p>3. The Consultant shall be empaneled with the Company based on the condition that Consultant’s medical degree MBBS and the specialization degree DNB is registered with Medical Council of India and/or State Medical Council as the case may be. The Consultant shall maintain the aforesaid registration valid during the term of this agreement at Consultant’s own cost and expenses and shall renew the registration as when required and provide the copies of the same to Company. If in case the Consultant fails to renew the registrations within the stipulated time, this Agreement shall cease to be valid and the Company at its sole discretion would be at a liberty to terminate this Agreement without notice. Consultant shall produce copies of original certificates supporting degrees and/or professional qualification, registration and experience certificates and any other documents as the Company requires.</p>

                                        <p>4. The Consultant shall provide the medical services as mentioned in Annexure 1.to this agreement to the best of his/her ability. The services provided by the Consultant should be to the absolute satisfaction of the company and the patient. The same shall be the essence of this contract. </p>

                                        <p>5. Subject to the provisions of this Clause 3 and subject to the Consultant performing all the obligation under this agreement to the subjective satisfaction of the Company, the Company will pay the Consultant for the Medical Services provided under this Agreement as per <strong>Annexure 2</strong> The Consultant shall not be entitled to any benefits from the Company including all those benefits which are made available to the employees of the Company. The payment to the Consultant shall be made, after deduction of applicable taxes at source, on monthly basis within 10<sup>th</sup> day of the following month. All the payments are subject to realization of money from patients/ insurance companies/ guarantors. The Consultant shall pay all his personal and other applicable taxes under the applicable laws and shall always keep the Company indemnified in this respect.</p>

                                        <p>6. The Consultant represents and warrants to Company that, </p>
                                        <div className="listAgmt">
                                            <p>a. The Consultant has full right to enter into and fully perform this Agreement and entering into this Agreement with the Company will not in any way infringe upon or violate any applicable law, rule or regulation, any contract with a third party or any rights of any third person.</p>

                                            <p>b. The Consultant represents that he/she shall provide the services in a diligent manner to the full and complete satisfaction of the Company in compliance with the applicable standards and Protocols of the Company.</p>

                                            <p>c. The Consultant will be responsible for medical investigation, treatment, discharge, and follow-ups and pre- and post-operative care of the patients admitted under her/his supervision and care.</p>

                                            <p>d. The Consultant warrants that all the Documents provided by the Consultant under Annexure …. to this agreement are true.</p>

                                            <p>e. The Consultant assures and warrants not solicit the patients of the Company after termination of this Agreement.</p>

                                            <p>f. The Consultant assures and warrants not to use “Confidential Information” as defined under for his/her benefit without prior written approval from the Company and further warrants to keep such “Confidential Information” strictly confidential.</p>
                                        </div>

                                        <p>7. For purposes of this Agreement, “Confidential Information” means any data or information that is proprietary and/or Confidential information to <strong>Cloudnine</strong>, whether intangible or in a tangible form, whenever and however disclosed, including, but not limited to: <strong>(i)</strong> any marketing strategies, plans, financial information, or projections, operations, sales estimates, business plans and performance results relating to the past, present or future business activities of such party, its affiliates, subsidiaries and affiliated companies; <strong>(ii)</strong> plans for products or services, and customer or supplier lists; <strong>(iii)</strong> any scientific or technical information, invention, design, process, procedure, formula, improvement, technology or method; <strong>(iv)</strong> any concepts, reports, data, know-how, works-in-progress, designs, development tools, specifications, computer software, source code, object code, flow charts, databases, inventions, information and trade secrets; <strong>(v)</strong> patient demographic information, clinical information relating to patient diagnosis or treatment, clinical protocols and in-house clinical processes, standards (vi) Patient details and <strong>(vii)</strong> any other information that should reasonably be recognized as confidential information of Cloudnine. Confidential Information need not be novel, unique, patentable, copyrightable or constitute a trade secret in order to be designated Confidential Information. The Consultant acknowledges that the Confidential Information is proprietary to the Company, has been developed and obtained through great efforts by the Company and that the Company regards all of its Confidential Information as trade secrets.</p>

                                        <div className="sublistAgmt">
                                            <p>Notwithstanding anything to the contrary herein contained, if there is any hindrance/obstruction/Disruption in the business of the company, which results into complete or partial stoppage of work or business activity of the Company or any reason whatsoever including any Force majeure event, acts of God, fires, explosions, accidents, embargoes, wars, riots, labour disputes, strikes, outbreak of an epidemic or pandemic disease, governmental requirements ,civil unrest, civil commotion, strike, Lockout, Court orders, Government order etc. or for any reason whatsoever then in such an event, the Company will not be obligated to pay any consideration for the period of such Interruption/disruption/hinderance to the Consultant. Such situation shall be notified to the Consultant within 30(Thirty days) days from the occurrence of the same.</p>
                                        </div>

                                        <p>9. The Consultant shall be fully responsible for the clinical treatment given to the patient(s) under this agreement. The Company shall be kept indemnified against any medico legal liability arising out of the medical treatment provided by the Consultant and which may arise during or after the expiry of this agreement. The Consultant agrees to defend with counsel acceptable to the Company (or settle at the Consultant’s sole cost &amp;expense), indemnify and hold the Company/Hospital its affiliates, assignees and each of its and their managing directors, directors, officers, partners, employees and agents (collectively, the “Indemnified Parties”) harmless from and against any and all suits, liabilities incurred, claims and proceedings by third parties relating to and/or resulting in liabilities, damages, costs, losses and expenses, including court costs and reasonable attorneys’ fees, which arise out of the performance or non-performance by Consultant pursuant to this Agreement or out of the breach of any of the terms of this Agreement. The Consultant shall obtain <strong>professional indemnity insurance policy of RS. 10,00,000/-</strong>Notwithstanding anything stated herein, if any authority or Courts or Tribunals or statutory authorities were to find that the Company is liable for any medical negligence in furtherance of the treatment to patients under the Consultant’s care, the Consultant agrees to indemnify the Company to the extent of any claim to which the Company may become liable including expenses to be incurred for defending any proceedings.</p>

                                        <p>10. The Consultant hereby more specifically agrees that he shall not, during the tenure of this agreement and thereafter for a further period of 12 months from the date of termination of this Agreement for whatsoever reason, including the expiry of the agreement on completion of its tenure, either personally or through an agent, person or otherwise in any other manner, directly or indirectly, induce or attempt to induce any employee of the Company to leave his/her employment. The Consultant further agrees that for a period of 12 months after the termination of this agreement for whatsoever reason, including the expiry of the agreement on completion of its tenure, the consultant will not work as a consultant or will not take up any similar services/employment within the radius of 10 kilometres from the Company.</p>

                                        <div className="listAgmt">
                                            <p>This Agreement is entered into between the Consultant and the Company on principal-to-principal basis. The Consultant is neither an agent nor an employee of the Company. The parties agree and acknowledge that the Consultant is an independent professional and not an employee of the Company and the Company is not responsible for any legal obligations, liabilities and expenses in respect of personal taxation, worker's compensation, superannuation, leave entitlements and the like. Nothing contained in this Agreement shall be deemed to create an employee employer relationship between Consultant and the Company. Company shall not be liable to make any statutory payments to Consultant including but not limited to provident fund, ESI contribution etc. Consultant also agrees not to act or represent before any other party as an employee of the Company.</p>
                                        </div>

                                        <p>12. The Consultant will abide by and adhere to the terms and conditions of this agreement and shall not conduct himself/herself in any manner which is prejudicial to the interests of the Company</p>

                                        <p>13. The Consultant hereby agrees to obtain and keep valid at all times, adequate <strong>Professional Indemnity insurance cover of Rs. 10,00,000/-</strong> for the professional services rendered by the Consultant at the Company, during the tenure of this agreement. </p>

                                        <p>14. The Consultant acknowledges that this is a <strong>non-exclusive</strong> Agreement and that the Company is free to enter into same or similar agreements with any party as it deems fit. </p>

                                        <p>15. Either party shall have right to terminate this Agreement, without assigning any reason whatsoever, at any time by <strong>giving 3(Three) months advance notice</strong> of their intention to pre close this agreement in writing to the other party.</p>
                                        
                                        <p>16. (i) The Company shall be entitled to terminate the this agreement without any notice or compensation in-lieu of such notice on occurrence of any of the following events:</p>

                                        <div className="listAgmt">
                                        <p>a. if the Consultant is in breach of any of the terms and conditions of this Agreement and/or the conduct of the Consultant is considered as detrimental to the interest of the Company or in violation of the Protocols of the Company.</p>

                                        <p>b. Any information, declaration and/or representation given by the consultant is found to be incorrect, false or misleading.</p>

                                        <p>c. Death or insolvency of the Consultant.</p>

                                        <p>d. On acceptance of any assignments in violation of this Agreement.</p>

                                        <p>e. If any regulatory authority disqualifies the Consultant from providing the medical services or on expiry of Registration certificate issued by Medical Council of India and/or State Medical Council as the case maybe and the same being not renewed within stipulated period as prescribed in terms of any specific regulation or otherwise.</p>

                                        <p>f. engages in serious or wilful misconduct.</p>

                                        <p>g. is convicted of any offence including moral turpitude.</p>

                                        <p>h. if the services rendered by the Consultant are not satisfactory to the Company.</p>

                                        <p>i. if the Consultant commits breach of confidentiality as envisaged under clause 6(f) of this agreement.</p>
                                        </div>
                                        
                                        <p>(ii) The Consultant hereby agrees that the medical services provided under this agreement is critical in nature and Patients/Customers shall not be deprived of on earlier termination, thus the Consultant shall continue to provide the services as agreed upon, during the Notice Period. In case Consultant fails to provide the required Notice Period and/ or fails to provide the services during the Notice Period, the Consultant shall require to pay damages to the Company which is equivalent to last three (03) months monthly professional fees received by the Consultant under this Agreement.</p>

                                        <p>(iii) Upon expiry or earlier termination of this Agreement, the Consultant shall immediately handover to Company all confidential information including all correspondence, documents, papers, property belonging to the Company which may be in Consultant’s possession or under Consultant’s control including without limitation all the creations that may be in Consultant’s possession. The Consultant shall not keep any copies of these items nor distribute the same to any party without the prior written authorization of the Company. The consultant agrees that failure by her/him to return the Property of the company upon termination shall be deemed to be criminal breach of trust.</p>

                                        <p>17. Any notice or other communication to be given by either party under, or in connection with this Agreement shall be made in writing and signed by or on behalf of the party giving it. It shall be served by letter or facsimile transmission (save as otherwise provided herein) and shall be deemed to be duly given or made when delivered/ transmitted or 10 days after being dispatched in the post, postage prepaid, by the most efficient form of mail available and by registered mail, if available to such party at its address or facsimile number specified in clause 15 or at such other address or facsimile number as such party may hereafter specify for such purpose to the other Parties hereto by notice in writing.</p>

                                        <p>The addresses and fax numbers for the purpose of clause 14 are as follows:</p>

                                        <p><strong>For The Company: </strong></p>

                                        <p>Kind Attn: Mr. Raviganesh Venkataraman</p>

                                        <p>Kids Clinic India Private Limited</p>

                                        <p>No. 1533, 9th Main, Jayanagar 3rd Block, Bangalore-560011</p>

                                        <p>Ph: 080-40202222</p>

                                        <p>Email: legal@cloudninecare.com</p>

                                        <h4> For Consultant</h4>

                                        <p>Dr. Shantha G</p>

                                        <p>No. 34, 1st Street, Gill Nagar, Choolaimedu, Chennai - 600094, Tamilnadu</p>

                                        <p>9841056625</p>

                                        <p>In the event of any dispute arising between the parties with respect to this Agreement, the same shall be referred to the Sole Arbitrator and the arbitration shall be in accordance with Arbitration and Conciliation Act of 1996. The language of arbitration proceeding shall be English. The seat and place of arbitration shall be Bangalore and the decision of the Arbitrator shall be final and binding on both parties herein.</p>
                                        <p>No modification, variation, waiver or amendment of any term or condition of this agreement shall be effective unless and until it shall be reduced to writing and signed by both the parties thereto.</p>
                                        <p>This Agreement shall supersede all previous agreements, understandings, contracts and any other documents in respect to the services mentioned under this Agreement. </p>
                                        <p><strong>IN WITNESS WHEREOF</strong> the parties hereto have signed and executed this Agreement on the date first mentioned hereinabove in the presence of the following witnesses;</p>

                                        <p>For Kids Clinic India Pvt. Ltd. Consultant</p>

                                        <p>Name: Mr. Raviganesh Venkataraman  Dr. Shantha G</p>

                                        <p>Designation: <strong>Chief Executive Officer </strong></p>

                                        <p>Witnesses:</p>

                                        <br />
                                        <br />
                                    </Container>
                                    <br />
                                    <Container fluid className="cardAgmt" style={{}}>
                                    <h6 className="subtitleAgmt"><strong>SCHEDULE 1:</strong></h6>
                                        <div className="col-md-12" style={{width:"100%"}}>
                                            <table  border="1" cellspacing="0" cellpadding="0" align="left" width="100%" > 
                                            <tbody>
                                            <tr>
                                            <td  valign="bottom" ><p><strong>Name of the Consultant</strong></p></td>
                                            <td  valign="bottom" ><p>Dr. Shantha G</p>

                                            </td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>Designation</strong></p></td>
                                            <td  valign="bottom" ><p>Visiting Consultant</p>

                                            </td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>Father/ Husband name of the Consultant</strong></p></td>
                                            <td  valign="bottom" ><p>D Guruswami / </p>

                                            </td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>PAN</strong></p></td>
                                            <td  valign="bottom" ><p>AQFPS6719R</p></td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>Medical Council Certificate No.</strong></p></td>
                                            <td  valign="bottom" ><p>TNMC: 53793</p></td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>Aadhaar No.</strong></p></td>
                                            <td  valign="bottom" ><p>857256071652</p></td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>Correspondence Address of the Consultant</strong></p></td>
                                            <td  valign="bottom" ><p>No. 34, 1st Street, Gill Nagar, Choolaimedu, Chennai - 600094, Tamilnadu</p></td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>Contact number</strong></p></td>
                                            <td  valign="bottom" ><p>9841056625</p></td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>E-mail</strong></p></td>
                                            <td  valign="bottom" >  </td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>Commencement Date</strong></p></td>
                                            <td  valign="bottom" ><p>19 August 2021</p></td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>Term</strong></p></td>
                                            <td  valign="bottom" ><p>1 Year</p></td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>Expiry Date</strong></p></td>
                                            <td  valign="bottom" ><p>18 August 2022</p></td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>Hospital Address</strong></p></td>
                                            <td  valign="bottom" ><p>No.54, opposite Hyatt Regency, Vijaya Raghava Road, T Nagar, Chennai, Tamil Nadu 600017 ("Chennai-T Nagar")</p></td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>Professional Indemnity Amount</strong></p></td>
                                            <td  valign="bottom" ><p>Rs. 10,00,000/-</p></td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>Department (area of service to be provided)</strong></p></td>
                                            <td  valign="bottom" ><p>Obstetrics and Gynaecology</p></td>
                                            </tr>

                                            <tr>
                                            <td  valign="bottom" ><p><strong>Bank Name </strong></p>

                                            <p><strong>Account No. &amp;amp; </strong></p>

                                            <p><strong>IFSC Code</strong></p></td>
                                            <td  valign="bottom" ><p>STATE BANK OF INDIA</p>

                                            <p>10123370274</p>

                                            <p>SBIN0007991</p></td>
                                            </tr>

                                            </tbody>
                                            </table>
                                        </div>
                                        <div className="sch2">
                                        
                                        <br />
                                        <br />
                                        <p className="subtitleAgmt"><strong>ANNEXURE -1</strong></p>

                                        <p>Medical Services by Consultant</p>

                                        <p>The Consultant shall perform all the medical services during the term of this agreement including:</p>

                                        <p>(a) Obstetrics and Gynaecology</p>

                                        <p>…………………………….……………………………………………………………………………………</p>
                                        </div>
                                        <div className="sch3">
                                            <p className="subtitleAgmt"><strong>ANNEXURE-2</strong></p>

                                        <p>(Description of Professional Fee)</p>

                                        <p><strong>A.1. For Out-Patient Consultations:</strong> For Out-Patient consultations provided by the Consultant under this agreement, the Company shall pay<strong> 80% of the consultation fee </strong>collected from the patients/ customers attended by the Consultant.</p>

                                        <p><strong>A.2. For In-Patient Consultations:</strong> For In-Patient consultations provided by the Consultant under this agreement, the Company shall pay<strong> 80% of the consultation fee </strong>collected from the patients/ customers attended by the Consultant.</p>

                                        <p><strong>A.3. For In-Patient Procedures: </strong></p>

                                        <p>(a) For any other Inpatient services provided by the consultant, which are not covered in the Hospital Tariff Package, for such services the Consultant shall be compensated on case to case basis as decided by the management of the company.</p>

                                        <p>…………………………….……………………………………………………………………………………</p>
                                    </div>
                                    </Container>
                                    <br />
                                    <Container fluid className="cardAgmt" style={{}}> 
                                    <p className="subtitleAgmt">MEDICAL STAFF BYLAWS Cloudnine hospitals [January 2017]</p>

                                        <p>Preamble: This Medical Staff Byelaws define and codify the broad framework or charter of rules and regulations, policies and procedures, mutual rights and obligations of the Hospital and the Consultant/medical staff in relation to their practice in the Hospital/s managed or operated by Cloudnine hospitals</p>

                                        <p>The Medical Staff Bylaws shall be construed, read and interpreted in conjunction with the binding Medical Professional Services Agreement (hereinafter referred to as the Services Agreement) entered into between Cloudnine hospitals (for and on behalf of hospitals) and the consultant. In the event of any inconsistency or contradiction between the provisions of the Medical Staff Byelaws vis-à-vis the Services Agreement on any subject matter, then the provisions mentioned in the latter( Services Agreement) shall always prevail over the former (Medical Staff Byelaws). </p>

                                        <p>I. DEFINITIONS </p>

                                        <p>a. A center head is a person appointed by the Management of Cloudnine Hospitals from time to time conferred with necessary authority to administer the day to day administration of the Hospital. </p>

                                        <p>b. Central Medical Quality (CMQ): shall mean a committee constituted by the management to supervise and manage medical quality at each of the Hospitals. The constitution, roles and responsibilities of the CMQ are more fully described in Clinical Manual, Medical Administration. </p>

                                        <p>c. Clinical Manual, Medical Administration –is a comprehensive codified document explaining rules, responsibilities, protocols, byelaws and polices of the hospital applicable to the Medical Staff </p>

                                        <p>d. Medical director means a member of the Medical Staff appointed by the management of Cloudnine Hospitals to manage the day to day medical/clinical administration of the hospital. The roles and responsibilities of are as mentioned in this document </p>

                                        <p>e. Committees of the Hospital: shall mean all such committees of the Hospital as may be constituted by the management from time to time to address hospital and medical staff related issues that may arise from time to time. The constitution, composition, tenure, powers, duties, roles and responsibilities of the Committee and its members are more fully mentioned in the Clinical Manual, Medical Administration </p>

                                        <p>f. Consultant means the medical staff member directly responsible for patient care at the Hospital possessing requisite academic qualification and experience, more fully mentioned in the Clinical Manual, Medical Administration and privileged by the Medical Practices Committee (MPC). The basic requirement to be appointed as a consultant the doctor should have completed MD/MS/DNB in their specialty at least 5 years before the date of application or should have completed DGO/ DA/ DCH 8 years before the date of application. This stipulation can be waived at the discretion of the Chairman or DMS.</p>

                                        <p>g. Quality Assurance Department (QAD) is a department constituted by the management to formulate and oversee the implementation of the polices at each of the hospitals of Cloudnine. The constitution, composition, tenure, powers, duties, roles and responsibilities of the department are more fully mentioned in the Clinical Manual, Medical Administration </p>

                                        <p>h. Hospital shall mean Cloudnine Hospital <strong>“No.54, opposite Hyatt Regency, Vijaya Raghava Road, T Nagar, Chennai, Tamil Nadu 600017 ("Chennai-T Nagar")”</strong> (A unit of Cloudnine Hospitals) and includes the following: </p>

                                        <p>(i) Primary Hospital. For the purposes of this Medical Staff Byelaws and with respect to any consultant full time, part time or visiting, as the case may be, a Primary Hospital shall mean such hospital/facility of Cloudnine where the consultant has agreed to render substantial portion of his/her professional services and charges for his/her professional services are accrued and paid from such hospital. </p>

                                        <p>(ii) Associate Hospital/Facilities For the purposes of this Medical Staff Byelaws and with respect to any consultant rendering his/her services at the hospital either full time, part time or visiting, as the case may be, an Associate Hospital/Facility shall mean and include all such other hospitals and facility/ies (other than the primary hospital) controlled and or managed by Cloudnine where any consultant is required to render his/her professional services on a temporary/long term basis. For the purposes of Medical Staff Byelaws the term “Facilities” shall mean to include the existing clinics, community hospitals, referral hospitals, day care centers, research institutes managed and controlled directly by Cloudnine or to be established in future directly or in association with any other institutions under any arrangement with such other institution. </p>

                                        <p>Medical Staff means all the Consultants (full time, part time, visiting, and junior), Registrars and Medical Officers conferred with practicing rights in the Hospital, including the following categories: </p>

                                        <p>(i) Full Time Consultant: means and includes a medical practitioner possessing the requisite academic qualifications, credentialed and privileged (more fully described in Clinical Manual, Medical Administration) to perform such invasive and/or noninvasive medical procedures at the Hospital either individually or forming a part of consultant’s group rendering his/her professional services at the hospitals controlled and managed by Cloudnine, having agreed to render his/her professional services of not less than 48 hours in aggregate in any week of 7 days having the following rights and restrictions: </p>

                                        <p>Right of outpatient consultation and treatment within the hospital premises Right to admit patients for invasive or noninvasive treatment and procedures under his/her care at the hospital. Shall not have any rights to his/her private practice in any clinics, teaching or non-teaching hospitals, medical colleges, clinics and other medical institutions other than the facilities managed by Cloudnine in any capacity without obtaining Cloudnine’s specific express consent in writing. </p>

                                        <p>(ii) Part time consultant means and include a medical practitioner possessing the requisite academic qualifications, credentialed and privileged (more fully described in Clinical Manual, Medical Administration) to perform such invasive and/or noninvasive medical procedures at the Hospital either individually or forming a part of the consultant’s group rendering his/her professional services at the hospitals controlled and managed by Cloudnine having agreed to render his/her professional services of not less than 12 hours and not more than 48 hours in aggregate in any week of 7 days having the following rights and restrictions. The number of hours mentioned before can be changed with the consent of the Cloudnine administration.</p>

                                        <p>a. Right to outpatient consultation and treatment within the hospital premises b. Right to admit patients for invasive or noninvasive treatment and procedures under his/her care at the Hospital. c. Rights to his/her private practice in any clinics, teaching or non-teaching hospitals, medical colleges, clinics and other medical institutions other than the facilities managed by Cloudnine in any capacity. However, such outside Hospital practice shall not have any conflict of interest. </p>

                                        <p>2. Visiting consultant means and includes a medical practitioner possessing the requisite academic qualifications, credentialed and privileged (more fully described in Clinical Manual, Medical Administration) to perform such invasive and/or non invasive medical procedures at the Hospital either individually or forming a part of consultant’s group rendering his/her professional services at the hospitals controlled and managed by Cloudnine having agreed to render his/her professional services as and when required by the hospital or suo motto having the following rights and restrictions </p>

                                        <p>a. Right to admit patients for invasive or non invasive treatment and procedures under his/her care at the Hospital. b. Rights to his/her private practice in any clinics, teaching or non teaching hospitals, medical colleges, clinics and other medical institutions other than the facilities managed by Cloudnine in any capacity. However, such outside Hospital practice shall not have any conflict of interest. c. Shall not have the right to outpatient consultation and treatment within the hospital premises, for any patient other than from his/her own practice </p>

                                        <p>3. Associate consultant means and includes a medical practitioner possessing the requisite academic qualifications, credentialed and privileged (more fully described in Clinical Manual, Medical Administration) to perform such invasive and/or noninvasive medical procedures at the Hospital either individually or forming a part of consultant’s group rendering his/her professional services at the hospitals controlled and managed by Cloudnine having agreed to render his/her professional services as and when required by the hospital or suo motto having the following rights and restrictions. These doctors are consultants who do not have independent admitting rights and will need to under the supervision of a consultant.</p>

                                        <p>3. Junior Consultant means and includes a medical practitioner possessing the requisite necessary academic qualifications, credentialed and privileged (more fully described in Clinical Manual, Medical Administration) to perform such invasive and/or noninvasive medical procedures at the hospital either individually or forming a part of consultants group rendering his/ her professional services at the hospitals controlled and managed by Cloudnine having agreed to render his/her professional services only under the supervision/guidance of a consultant. Working hours should not be less than 208 hours in month.</p>

                                        <p>4. Registrars means and include a medical practitioner possessing the requisite academic qualifications, credentialed and privileged (more fully described in Clinical Manual, Medical Administration) to perform such invasive and/or noninvasive medical procedures at the hospital either individually or forming a part of consultant’s group rendering his/her professional services at the hospitals controlled and managed by Cloudnine having agreed to render his/her professional services only under the supervision/guidance of a consultant. Working hours should not be less than 208 hours in month.</p>

                                        <p>5. Fellow means and include a medical practitioner possessing the requisite academic qualifications, credentialed and privileged (more fully described in Clinical Manual, Medical Administration) to perform such invasive and/or noninvasive medical procedures at the hospital either individually or forming a part of consultant’s group rendering his/her professional services at the hospitals controlled and managed by Cloudnine having agreed to render his/her professional services only under the supervision/guidance of a consultant and is enrolled in a Fellowship training program. </p>

                                        <p>6. Medical officer means and include a medical practitioner possessing the requisite academic qualifications, credentialed and privileged (more fully described in Clinical Manual) to perform such invasive and/or noninvasive medical procedures at the hospital either individually or forming a part of consultant’s group rendering his/her professional services at the hospitals controlled and managed by Cloudnine having agreed to render his/her professional services only under the supervision /guidance of a consultant. </p>

                                        <p>7. Primary consultant: shall mean a consultant either full time/part time/visiting consultant as the case may be who is primarily responsible and accountable to the hospital and to the patient in connection with admission and treatment of a patient including for the acts and omissions of the associate consultants/medical registrar/ medical staff (hereinafter referred to as “authorised persons” acting under his/her instruction, supervision and guidance. </p>

                                        <p>8. Referral consultant: shall mean a consultant either full time/part time/visiting consultant of the hospital as the case may be whose opinion is sought by the primary consultant as it is beyond his/her scope of training. </p>

                                        <p>ii. Management means the Board of Directors of Cloudnine hospital, or it’s designate. </p>

                                        <p>II SELECTION OF MEDICAL STAFF </p>

                                        <p>A. CREDENTIALING </p>

                                        <p>a. All Consultants possessing minimum eligibility criterion (as mentioned in Clinical Manual, Medical Administration)) and interested in obtaining practicing rights in the Hospital shall submit an application, along with other relevant documents as determined by the Management from time to time to the Group Medical Director through the Regional director/Center head/ Medical director/Human Resource Department of the hospital as the case may be for evaluation and approval. </p>

                                        <p>b. The first privileging of the consultants will be granted by the GMD and the same will be ratified after six months in the MPC which will be attended by the GMD or his authorized representative. </p>

                                        <p>c. Medical Practices Committee (MPC) shall confirm all subsequent practicing privileges including renewals. </p>

                                        <p>d. The Center head shall prepare Consultant’s credentials and practicing privileges and place it before the Credentialing Committee (CC), for their confirmation. Any differences between the Center head and the credentialing committee shall be forwarded to the Director of Medical services (DMS) for final approval </p>

                                        <p>e. All Medical Staff members of the hospital shall be entitled to exercise only those medical practicing privileges specifically confirmed to them by the CC. </p>

                                        <p>f. The CC shall investigate, evaluate and recommend to the DMS the entitled medical practicing privileges of the applicant based upon the applicant’s education, training, experience, demonstrated competence, health status, references and other relevant information. The applicant shall have the burden of establishing his qualifications, and competence. </p>

                                        <p>g. The validity of the approval for practicing privileges is 2 years and the renewal may be confirmed by the CC </p>

                                        <p>h. The New Medical Staff Members, or locums will be granted temporary privileges by the Medical director, if needed, following a Consultant joining the Hospital until the applicant can be formally confirmed by the CC at the next meeting. In case of locums, the temporary privileges are valid for a maximum period of three months from date granted to the locum consultant by the Medical director before the locum consultant is allowed to practice in the hospital. For locum Consultants, it is the responsibility of the inviting consultant to forward the locum consultants credentials to the Medical director, for the granting of temporary privileges. The locum consultant must sign the Medical Bye laws and is bound by this document. </p>

                                        <p>i. Applications for medical practicing privileges shall list in writing the privileges and state any relevant training and/or experience appropriate to the request. This request shall be made in the specialty specific privileging form </p>

                                        <p>j. Any consultant may expand the scope of their practicing privileges, utilizing the procedure described above. </p>

                                        <p>k. In the case of an emergency, any Medical Staff member, to the degree permitted by his or her license, shall be permitted and assisted to do everything possible to save the life of a patient, using every facility of the hospital necessary. When the emergency situation no longer exists, such Medical Staff member must request the Medical director to temporarily grant the privileges necessary to continue to treat the patient, or transfer the patient to an appropriate member of the Medical Staff, or to an outside consultant. For the purpose of this situation, an emergency is defined as a condition in which there is danger of serious permanent harm to a patient, or in which the life of a patient is in imminent danger or any delay in administering treatment would add to that danger. </p>

                                        <p>l. Once the CC grants practicing privileges to a Consultant, the Consultant will be considered as a Medical Staff member and the privileges shall remain valid for a 2 year period. This can be terminated if the consultant performs in any way that is detrimental to patient care after due process.</p>

                                        <p>m. Notwithstanding the above, the decision to grant or renew the privileges to the Medical staff who (i) absents him/her self from the hospital for a continuous period of sixty (60) days or more due to a medical illness or (ii) who has passed their sixty-fifth birthday shall be subject to the approval of the DMS based on the information provided about the current health and medical reports of the said practitioner. In this regard the decision of the DMS is final. </p>

                                        <p>n. Participation of Medical staff members in the “Controlled Clinical Trials” (CCT) or Clinical Research (CR) shall be in accordance with the “Controlled Clinical Trials” (CCT) or Clinical Research (CR) manual adopted by the Hospital from time to time. </p>

                                        <p>III. REMEDIAL ACTIONS: RULES AND REGULATIONS </p>

                                        <p>a. Any Medical Staff member, employee, patient (or their proxy), the Medical director or Center head may request an inquiry regarding a consultant’s conduct or medical care when said party believes a consultant has acted in a way that is: </p>

                                        <p>I. Detrimental to patient care or safety; or </p>

                                        <p>II. Against hospital policy; or </p>

                                        <p>III. Disruptive to hospital operations. </p>

                                        <p>b. All complaints shall be in writing and addressed to the Center head or the DMS and handled as follows: </p>

                                        <p>i. The Center head and the Medical director will review all complaints, as described hereinabove and discuss with the Consultant in question and ask for written accounts from all parties involved. In such an event all such concerned parties shall provide all the necessary information and documents in their knowledge and possession to Center head and the Medical director and they shall forward all related information to the DMS or to the person or committee nominated/designated by the DMS from time to time for this purpose. If involved parties refuse to provide written details, then the Center head/ Medical director will provide such details, based on their investigation and the Medical director reserves the right to initiate appropriate disciplinary actions against such erring members. </p>

                                        <p>Based on the available facts and circumstances, if the DMS or the person/ committee nominated/designated by the DMS is of the opinion that, the matter needs a detailed further investigation then, the same shall be forwarded to the CMQ for a detailed report on the allegation. CMQ shall furnish their report to the DMS. If the findings of the CMQ are suggestive of a prima-facie case then the DMS shall forward the case to the CC for adjudication. However, the report of CMQ being a confidential report meant for the use of DMS/CC, the same shall not be called in question or compelled to be made public by any of the concerned party. </p>

                                        <p>ii. The CC with a minimum quorum of will hear each complaint and submit its report and their recommendation to the Medical director who shall forward the recommendations of the CC to the DMS. Depending upon the recommendation of the CC and the gravity of the situation, the DMS shall decide appropriate course of action which may include the termination of services of the consultant. Notwithstanding the difference of opinion among the members of the CC, the decision of the DMS is final and cannot be called in question by the consultant charged with the allegation or any member of the CC. </p>

                                        <p>iii. Any Consultant whose credentials are under review for any complaint against the said consultant by the CC shall be entitled to request that the CC investigate the matter within 30 days. If the involved Consultant disagrees with either the findings, or actions recommended by the CC, said Consultant may appeal to the DMS within 30 days of such findings of the CC. </p>

                                        <p>iv. The Medical director may temporarily suspend all or any portion of the clinical privileges of a Consultant, prior to CC review, whenever such action must, in the judgment of the Medical director, be taken immediately to safeguard patient safety. The complaint in question will then be taken up by the CC, as described in III above within 48 hours. Appeals: A Consultant may appeal against the decision of CC to the DMS, within 15 days of such decision of the CC in the event of such Consultant does not agree with the CC’s decision with respect to his/her revocation or change in practicing privileges. The DMS will address such appeal and adjudicate within 30 days of receipt of such appeal and the same shall be binding on the Consultant. </p>

                                        <p>V. DUTIES OF Medical directors: </p>

                                        <p>1. There will be only one functional medical director for a one year period. If there are more than one, this role will be done on a rotational basis.</p>

                                        <p>2. They will need to be informed of all medically related issues on a daily basis including nurse to patient ratio for the last 24 hours, patient complaints, doctor complaints and doctor noncompliance of policies. These should be informed to the Director medical services on a daily basis</p>

                                        <p>3. They will be responsible for creating the CME calendar for the month. There will need to be at least one CME meeting a month. The M&amp;amp;M meeting will need to be held every 3 months and can replace one of the CME meetings.</p>

                                        <p>4. They will be responsible for ensuring the completion and submission of doctor (consultant and junior) duty rosters to the center heads.</p>

                                        <p>5. They will be responsible for helping the center heads resolve all medical care related complaints in a timely manner. The closure of these complaints need to be informed to the Director, Medical services.</p>

                                        <p>6. They will be responsible for talking to doctors who are not compliant with hospital policies. In the event there is a conflict of interest the Director of Medical services will do the needful.</p>

                                        <p>7. The unit medical directors are encouraged to come up with clinical audits. The Director of Medical services will help with this process whenever required. </p>

                                        <p>8. The unit medical directors with the help of the center heads and the nursing in charge should ensure that all mandatory committee meetings are held and the minutes with action taken are documented and stored.</p>

                                        <p>9. The unit medical directors with the help of the center heads and the nursing in charge should ensure that all end of the month reports are sent to the quality team by the 2<sup>nd</sup> of every month.</p>

                                        <p>10. The following are the reports that need to be sent on a monthly basis</p>

                                        <p>- All NABH QI data including corrective and preventive action (CAPA) taken</p>

                                        <p>- All clinical audits done or ongoing with current status</p>

                                        <p>- All Incident reports data with details and CAPA</p>

                                        <p>- Report of all CMEs conducted with attendance data</p>

                                        <p>- M&amp;amp;M report if it is conducted that month with brief report of discussion and CAPA</p>

                                        <p>- All patient complaints with CAPA</p>

                                        <p>- Report of any disciplinary issues related to doctors and nurses</p>

                                        <p>11. They will be involved in the credentialing and privileging the consultants in their units with the help of HRD</p>

                                        <p>VI. ORGANIZATION OF THE MEDICAL STAFF </p>

                                        <p>a. Each medical staff member shall be a member of a hospital department, as defined by their respective medical specialty. The departments shall consist of the following: </p>

                                        <p>OBG and Surgery   : To include all surgery subspecialties </p>

                                        <p>OB/GYN  : To include all OB/GYN subspecialties </p>

                                        <p>Pediatric Medicine  : To include all Pediatrics and NICU </p>

                                        <p>Anesthesia and Radiology</p>

                                        <p>b. Each department shall have a co-ordinator, who shall be nominated by Medical director.</p>

                                        <p>The functions of each department shall be to assure the delivery of high quality patient care in their respective department by implementation of the policies formulated by the Central Quality Assurance Department (QAD). In the event of a need for an amendment to the existing departmental policy then the proposal for an amendment shall be routed through Medical director to the QAD and QAD shall decide on the necessity for the proposed amendment and decide accordingly. The decision of the QAD is final and binding. All amendments shall take effect only after the approval of the QAD. </p>

                                        <p>Each department shall hold meeting for the department at regular intervals to discuss the issues pertaining to the department. However, the interval between two such departmental meetings shall not exceed 2(one) calendar month. All the business transacted at the departmental meeting shall be minuted and a copy of the same shall be forwarded to the Medical director and center head. The DMS shall have the right to inspect the minutes and take copies without any prior notice. </p>

                                        <p>All the material issues discussed at the department meetings requiring the consideration of the Medical director/DMS / Management shall be immediately brought to the notice of Medical director. If the referred issues are not addressed/no response is received within a reasonable time (however, not later than 2 months) then the Department shall bring the same to the notice of the DMS</p>

                                        <p>VIII MEDICAL STAFF MEETINGS </p>

                                        <p>As and when the situation so warrants, the DMS in consultation with the Medical director shall have the right to call for a meeting of the Medical staff </p>

                                        <p>IX OBLIGATIONS OF MEDICAL STAFF MEMBERS </p>

                                        <p>The following are obligations of all Medical Staff members. Failure to perform these obligations shall constitute a default/misconduct by any medical staff. Any repeated default may result in the revocation of clinical privileges at the Hospital, at the discretion of the Management. </p>

                                        <p>a. General: </p>

                                        <p>i. Admitting Privileges: Only members of the Medical Staff may admit patients to the Hospital and serve as the Consultant. A Consultant shall at all times be designated for each patient receiving care in the Hospital. </p>

                                        <p>ii. Availability: subject to the extent of services agreed to be rendered by a consultant (i.e Full time/Part time/Visiting as the case may be) the Consultants must be available at all times, except when he/she has made arrangements for another Medical Staff member to replace him or her. A primary practitioner will be considered unavailable if he or she has not responded ( by telephone or otherwise ) to a call from the Hospital within 30 minutes and the Medical director/ Center head will then be notified to appoint a replacement consultant. </p>

                                        <p>iii. Emergency Department Call: All Medical Staff members, except the visiting consultants, are expected to participate in the on-call rotation for the Emergency Department. On call request will be considered unperformed if a consultant has not responded (by telephone or otherwise) within 30 minutes. Call shall be optional for the consultant who has crossed the age of 60 years, or if granted a waiver from call, by the CC. </p>

                                        <p>iv. Continuing Medical Education: All Medical Staff are required to accumulate a minimum of 24 hours of continuing medical education credits every calendar year. The CME requirement will begin on January 1, following a Consultant’s signing of the service contract. If a Consultant fails to comply with this CME requirement, the matter will be forwarded to the CC for review. </p>

                                        <p>v. Clinical Practice Guidelines: All Medical Staff shall conform to the Clinical Practice Guidelines adopted by the hospital from time to time. </p>

                                        <p>vi. Participate in induction imparted by the hospital before commencing his/her clinical practice at the hospital. In case of conflicting medical opinions by the consultants the same shall be escalated to CMS for seeking expert/second opinion. Participate in various committees of hospital. Shall adhere to the grooming and dress code norms as may be adopted by the hospital from time to time Except for furnishing recommendations to the hospital administration for procurement of drugs, implants, equipments and other consumables, the medical staff shall not involve in promotion of any particular drug, implant, equipment as the case may be . No indulgence in unfair/unethical trade practices Shall adhere to the safety policy as adopted by the hospital in specific and Medical code of ethics in general Each and every document required to be signed by the primary consultant (not limited to the prescription, case progress notes etc.,) shall be promptly signed by the respective primary consultant. However, in the event of any document being signed by a person other than the primary consultant but under the express authority of the primary consultant then the primary consultant shall subsequently verify and endorse the correctness of the contents of the document signed by the authorised person. Notwithstanding the fact that, the primary consultant is not in agreement with the medical opinion expressed by his authorised person, still be accountable and liable for the same. However, this fact shall be brought to the notice of the Medical director without any delay and shall extend all support to the Medical director/ Center head to minimize the impact arising out of the act or omission of his/her authorised person. Maintain Membership of an organization in good standing in their specialty. </p>

                                        <p>Not be convicted of, or have pled nolo contendere to any criminal activity. </p>

                                        <p>Participate in Hospital’s quality improvement, utilization review and risk management programs. </p>

                                        <p>Comply with hospitals procedures and policies to monitor and control the expenses of the hospital in order to promote cost effective services. </p>

                                        <p>Comply with hospitals corporate responsibility program and standard of conduct which is part of that program. </p>

                                        <p>Cooperate in good faith any investigation or assist the hospital to investigate any complaints made by the patients or hospital personnel and to resolve such complaints in a reasonable time with appropriate action. </p>

                                        <p>Participate in training and educational programs applicable to the service provided as mandated by the hospital. </p>

                                        <p>Consultants shall also comply with all the hospital and professional licensure and reimbursement laws, regulations, rules and policies, including without limitations all applicable standards and recommendations of the National Board of Accreditation for hospitals and health care workers. </p>

                                        <p>Consultant agrees to participate with all the insurance companies and TPA that Cloudnine partners with time to time. Consultant shall follow all the rules of the Cloudnine billing policy. </p>

                                        <p>Participate in letter and spirit in the hospital’s Infection Control Program. </p>

                                        <p>Implement all clinical programs that are rolled out for various specialties from time to time by Cloudnine.</p>

                                        <p>Any Consultant who is allegedly on substance abuse will submit to the processes established by Cloudnine to confirm/ rule out substance abuse. Failure to submit to such processes shall be grounds for termination. </p>

                                        <p>The Medical director shall call upon any or several consultants involved in the management of a case to be present at the Mortality and Morbidity (M&amp;amp;M) meeting or any similar forum for discussion. Failure to appear for such discussions without due reason or prior intimation is sufficient ground for disciplinary action. </p>

                                        <p>In accordance with the statutory provisions , pre-natal sex determination shall not be conducted in Cloudnine hospitals by any medical staff. </p>

                                        <p>The examination of the opposite gender shall be done only in presence of a chaperone of the same gender as the patient, or a family member of the patient under examination. Appropriate precautions shall be taken while examining the patients of the opposite gender. Personal beliefs/views about a patient’s lifestyle, culture, gender, belief, age, social status or perceived economic worth must never prejudice the treatment of the patient under the Medical staff’s care.  </p>

                                        <p>Patient/family as the case may be to be fully involved in all decision making with respect to patients treatment /procedure </p>

                                        <p>All referrals should be sent to the consultant on call unless specific conditions warrant otherwise and this shall be documented in the patient notes. </p>

                                        <p>The Medical Staff shall participate in the roles assigned in various codes like code blue </p>

                                        <p>The Medical staff is also responsible to follow all policies, manuals, guidelines and protocols etc. issued from time to time. </p>

                                        <p>The medical staff is bound by the Prevention of sexual harassment (POSH) policy of the hospitals.</p>

                                        <p>Admission and Discharge of Patients: </p>

                                        <p>i. Provisional diagnosis: Patients shall not be admitted to the Hospital until a provisional diagnosis of the patient, or a valid reason for admission has been made. </p>

                                        <p>ii. Necessary information: It is the duty of the consultant to provide such available written information as may be necessary to assist in the protection of a patient from self-harm or for the protection of others. </p>

                                        <p>iii. Consent for admission and treatment: Prior to initiating any non-emergent operation or procedure, or to admitting a patient to the hospital, the primary consultant shall obtain the general consent for admission and treatment from the patient and/or the patient’s family. Except in emergency situations, such treatment shall not be ordered until proper consent has been obtained. Further, the Consultant must explain to the patient and/or patient’s family:1) the procedure to be performed, the potential complication and potential benefits of the procedure, or 2) the reasons for admitting the patient. </p>

                                        <p>In emergency situations where a patient is indisposed/legally unable to give consent and a guardian is not readily available, consent for medical procedures must be obtained from the Medical director.</p>

                                        <p>iv. Discharge Orders: Patients will be discharged only on order of the consultant and /or his designee. If a patient leaves the hospital without an order and against the primary consultant’s advice, the patient should be asked to sign an “Against Medical Advice” form. </p>

                                        <p>The Primary Consultant then must make note of the incident and the surrounding circumstances in the patient’s medical record. </p>

                                        <p>v. Day-passes/transfer to outside facilities: Day passes or transfers to outside facilities of patients for tests/evaluations/treatment not available in Hospital are permitted. These passes should be supported by an order of the primary consultant in the patient’s medical record. </p>

                                        <p>Orders: </p>

                                        <p>1. All orders for admission, treatment and performance of test or other procedures shall be in writing/ electronically in the HER, in the patient’s medical record. Under unavoidable circumstances, verbal order shall be routed through the in-house medical staff of the hospital and the same shall be authenticated by the ordering consultant within 24 hours from the time of order. </p>

                                        <p>2. All orders must be timed, date, and signed by the primary consultant </p>

                                        <p>3. Admitting orders must contain admitting diagnosis, and desired ward (i.e. ICU/CCU vs. Non ICU ward room). </p>

                                        <p>4. The primary consultant’s orders must be written legibly. Orders that are illegible or improperly written will not be carried out until clarified by the primary consultant. </p>

                                        <p>Medical Records – Inpatient </p>

                                        <p>i. The Primary Consultant is responsible for the following components in patient medical record: </p>

                                        <p>1. A brief current history and physical examination by the primary consultant or any member of his team shall be recorded and appropriate orders written within 30 minutes after admission of the patient. The consultant must countersign the detailed initial admission record within 24 hours of admission. </p>

                                        <p>2. Operative Report(s): Operative reports shall be completed immediately following surgery. The reports shall include the pre-operative and post-operative diagnoses, an account of the findings at surgery and the relevant details of the surgical technique. Complications and whether any specimens were sent to pathology should also be noted </p>

                                        <p>3. Anesthetic Record: An anesthetic record shall be completed by the primary anesthetist on all patients receiving regional block or general anesthesia. This record should include a pre anesthetic assessment, clinical findings on physical exam, relevant lab tests and overall impression of anesthetic risk. Details regarding delivery of anesthesia, ET tube, regional block location, etc. should also be mentioned. All drugs or fluids given, along with time, dosage and route should be noted. Periodic vital signs should be recorded. Any intra-operative or post-anesthetic complications should be noted. </p>

                                        <p>4. Progress Note (daily): Patient progress notes shall be recorded at least twice by the treating time and at least once by the primary consultant daily at the time of observation. They shall reflect a pertinent, chronological report of the patient’s course in the hospital, changes in the patient’s condition, the results of treatment and any briefing of patient or family. </p>

                                        <p>5. Discharge Summary: A discharge summary form shall be completed for all patients’ hospitalized. The discharge summary should reflect the reason for hospitalization, the significant findings, procedures performed and treatment rendered, the condition at discharge and any instructions (including discharge medications) given to the patient and/or family. In case of discharge against medical advice then the same shall be explicitly mentioned in the discharge summary including the associated risks of going against medical advice. </p>

                                        <p>6. Referral and Referral Consultant’s Report: all referrals shall be in accordance with the referral protocol/policy adopted by the hospital. The Referral consultant’s report shall document a review of the patient’s record, pertinent findings and his/her opinion on the referred subject and recommendations. All referrals should be seen within the specified time limit mentioned hereinafter and the medication ordered should be informed /discussed with the primary consultant </p>

                                        <p>Time limits </p>

                                        <p>(i) For emergency consultation like life threatening conditions – immediate (ii) For urgent- like unstable vitals – within 4 hours from the time of referral (iii) For routine – within 24 hours (iv) pre-operative consultation shall be recorded prior to surgery. . </p>

                                        <p>In the event of a request by the patient/patient’s kin/ medical staff/ Center head for the change of a consultant (primary/referral) as the case may be, then all such requests shall be forwarded to the Medical director and the Medical director subject to acceptance by the new consultant shall make necessary arrangements to transfer the patient’s care to the new consultant. </p>

                                        <p>ii. Completion of Medical Records: All medical staff shall strictly adhere to the Electronic/ written Medical Records policy adopted by the hospital of his/her attachment and the entries in the same shall be completed in all respects on or before discharge of the patient. If a Consultants’ medical records are incomplete within the stipulated period, the Medical director will be alerted. The Medical director will in turn notify the Consultant and ensure compliance. </p>

                                        <p>iii. All medical records are the property of the hospital and shall not be removed or copied from the hospital except by court order/written request of the patient/patient’ next kin/patient’s authorized representative statutory authorities (to the extent permissible under law).In the case of readmission of a patient, all previous record shall be available for use by the primary consultant. </p>

                                        <p>iv. All medical records of MTP shall be hidden from view unless the CMS authorizes the same. </p>

                                        <p>v. Medical information may be released internally without patient consent only for the purpose of patient care, peer review, quality improvement activities, and research. </p>

                                        <p>Medical Records – Outpatient </p>

                                        <p>i. Each outpatient report should include the diagnosis and treatment plan of the consultant, supported by current and historical information, objective physical examination findings plus laboratory and/or radiology information that support the diagnosis and treatment plan. The patient notes shall also reflect any preventive action and nutritional advice given </p>

                                        <p>ii. If there is a consistent pattern of incomplete outpatient medical records by a consultant, the Medical director will consider remedial action </p>

                                        <p>iii. Release of Outpatient Medical Records: Issues regarding custody and release of outpatient medical records shall be treated the same as inpatient medical records. </p>

                                        <p>Use of Hospital Services </p>

                                        <p>i. All drugs and medications administered to patients shall be only those listed in the hospital formulary. If a drug, or its equivalent, is not available on the formulary, then the hospital pharmacist should be requested to obtain the medication. Drugs prescribed outside the formulary should be approved by the Medical director if it is for one time use. Consultants may request addition / deletions as per their requirements to the Medical director, who will then initiate the process for change in the formulary as per policy. </p>

                                        <p>ii. Only if the pharmacist is unsuccessful in obtaining the desired medication, shall the medication be obtained from outside the hospital. Hospital formulary is dynamic in nature and will be updated regularly as per the protocol adopted for the same. The prescriptions must be as per protocol. </p>

                                        <p>iii. All medicines procured from outside as prescribed herein above must be in a container clearly identified as to patient’s name, number, and strength. It must be given to the hospital for safekeeping and administration. It should only be administered if supported by a proper written order and verified by the consultant. </p>

                                        <p>iv. Consultants will utilize hospital diagnostic services (laboratory, radiology, etc.,) as warranted by the patient’s medical condition, and not refer to outside facilities for tests or diagnostic procedures which are available in the hospital. For services not available at the hospital, the consultant shall refer to outside facilities where the hospital have entered into any reciprocal arrangement for such referral diagnostic tests. </p>

                                        <p>v. Consultant will refer to other Medical Staff Specialist for opinions and treatment on patient matters outside of their respective specialty area, on all hospital patients requiring such referrals, unless a patient requests otherwise. Consultant may refer to outside specialists if a specific specialty is not available in the Hospital with the concurrence of the Chief of Medical services. </p>

                                        <p>vi. Consultants shall participate in the training programs organized for any new software/appliance/ instrument either for patient care or for administrative use as and when introduced </p>

                                        <p>X: AMENDMENTS </p>

                                        <p>No amendments to this byelaw shall take effect without the explicit consent of the management in writing.</p>

                                        <p><strong>ACKNOWLEDGEMENT</strong></p>

                                        <p>I acknowledge the receipt of Company’s ‘medical Staff bylaws’ and I have read and understand that being a consultant of the Company I require to abide by all standards and policies contained under this bylaws.</p>

                                        <p>I agree to maintain all company information confidentially and shall not disclose to any third person, unless in the ordinary course of business.</p>

                                        <p>Name: Dr. Shantha G</p>

                                        <p>Department: Obstetrics and Gynaecology</p>

                                        <p>Signature: _____________________________</p>

                                        <p>Date: _________________________________</p>


                                    </Container>
                                </Card.Body>
                            </Card>
                        </Container>
                    </div>
                </div>
            </div>
            
        </>
    );
}

export default VcChennai;