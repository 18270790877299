import React, { useState, useEffect, useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { useStyles } from "./utils";
import { AppContext } from '../actions/contextApi';
import { Box } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { pink } from '@mui/material/colors';
const ITEM_HEIGHT = 55;
const ITEM_PADDING_TOP = 0;
const CenterProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 300,
		}
	},
};
const MnthProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 100,
		}
	},
};
const LOSProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 200,
		}
	},
};
const itemgpProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 300,
		}
	},
};


const FilterCompo = (props) => {
	const { centerNames, setCenterNames } = useContext(AppContext);
	const { regionNames, setRegionNames } = useContext(AppContext);
	const { Months, setMonths } = useContext(AppContext);
	const { Years, setYears } = useContext(AppContext);
	const { LOSName, setLOSName } = useContext(AppContext);
    const { IPOPName, setIPOPName } = useContext(AppContext);
	const { itemGroupNames, setitemGroupNames } = useContext(AppContext);
	const { DoctorNames, setDoctorNames } = useContext(AppContext);
	const classes = useStyles();
	const months = [
		{ label: 'Jan', value: 'JANUARY' },
		{ label: 'Feb', value: 'FEBRUARY' },
		{ label: 'Mar', value: 'MARCH' },
		{ label: 'Apr', value: 'APRIL' },
		{ label: 'May', value: 'MAY' },
		{ label: 'Jun', value: 'JUNE' },
		{ label: 'Jul', value: 'JULY' },
		{ label: 'Aug', value: 'AUGUST' },
		{ label: 'Sep', value: 'SEPTEMBER' },
		{ label: 'Oct', value: 'OCTOBER' },
		{ label: 'Nov', value: 'NOVEMBER' },
		{ label: 'Dec', value: 'DECEMBER' }
	];
	const IPOPList = [
		{ label: 'IP', value: 'IP' },
		{ label: 'OP', value: 'OP' },
	];
	const isAllSelectedCenters = props.Center.length > 0 && centerNames.length === props.Center.length;
	const isAllSelectedMonths = months.length > 0 && Months.length === months.length;
	const isAllSelectedYears = props.YearData.length > 0 && Years.length === props.YearData.length;
	const isAllSelectedItemGroupName = props.ItemGroup.length > 0 && itemGroupNames.length === props.ItemGroup.length;
	const isAllSelectedDoctors = props.Doctor.length > 0 && DoctorNames.length === props.Doctor.length;
	const isAllSelectedRegions = props.RegionList.length > 0 && regionNames.length === props.RegionList.length;
	const isAllSelectedLOS = props.LOSList.length > 0 && LOSName.length === props.LOSList.length;
	const isAllSelectedIPOP = IPOPList.length > 0 && IPOPName.length === IPOPList.length;
	const handleChangeRegion = async (event) => {
		const value = event.target.value;
		if (value[value.length - 1] === "all") {
			let temp = []
			if (regionNames.length !== props.RegionList.length) {
				await props.RegionList.map(elem => { temp.push(elem.regionName) })
			}
			setRegionNames(temp);
			return;
		}
		setRegionNames(value);
	};
	const handleChange = async (event) => {
		const value = event.target.value;
		if (value[value.length - 1] === "all") {
			let temp = []
			if (centerNames.length !== props.Center.length) {
				await props.Center.map(elem => { temp.push(elem.centername) })
			}
			setCenterNames(temp);
			return;
		}
		setCenterNames(value);
	};
	const handleChangeMonth = async (event) => {
		const value = event.target.value;
		if (value[value.length - 1] === "all") {
			let temp = []
			if (Months.length !== months.length) {
				await months.map(elem => { temp.push(elem.value) })
			}
			setMonths(temp);
			return;
		}
		setMonths(value);
	};
	const handleChangeYear = async (event) => {
		const value = event.target.value;
		if (value[value.length - 1] === "all") {
			let temp = []
			if (Years.length !== props.YearData.length) {
				await props.YearData.map(elem => { temp.push(elem.Period) })
			}
			setYears(temp);
			return;
		}
		setYears(value);
	};
	const handleChangeItemGroup = async (event) => {
		const value = event.target.value;
		if (value[value.length - 1] === "all") {
			let temp = []
			if (itemGroupNames.length !== props.ItemGroup.length) {
				await props.ItemGroup.map(elem => { temp.push(elem.ItemGroupName) })
			}
			setitemGroupNames(temp);
			return;
		}
		setitemGroupNames(value);
	};

	const handleChangeDoctor = async (event) => {
		const value = event.target.value;
		if (value[value.length - 1] === "all") {
			let temp = []
			if (DoctorNames.length !== props.Doctor.length) {
				await props.Doctor.map(elem => { temp.push(elem.DoctorName) })
			}
			setDoctorNames(temp);
			return;
		}
		setDoctorNames(value);
	};

	const handleChangeLOS = async (event) => {
		const value = event.target.value;
		if (value[value.length - 1] === "all") {
			let temp = []
			if (setLOSName.length !== setLOSName.length) {
				await props.LOSList.map(elem => { temp.push(elem.LOSName) })
			}
			setLOSName(temp);
			return;
		}
		setLOSName(value);
	};
	const handleChangeIPOP = async (event) => {
		const value = event.target.value;
		if (value[value.length - 1] === "all") {
			let temp = []
			if (setIPOPName.length !== setIPOPName.length) {
				await IPOPList.map(elem => { temp.push(elem.IPOPName) })
			}
			setIPOPName(temp);
			return;
		}
		setIPOPName(value);
	};

	useEffect(async () => {

	}, [])


	return (
		<Box>
			{/* Region Filter start ---- */}
			<Tooltip arrow={true} title={regionNames.toString()}>
				<FormControl sx={{ m: 1, width: 95, marginRight: '2px', marginLeft: '2px', borderColor: pink[600] }}>
					<InputLabel id="demo-multiple-checkbox-label3" size="small" variant='outlined' sx={{ fontSize: '12px', marginTop: '-2px', color: pink[600], borderColor: pink[600] }}>Regions</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label3"
						id="demo-multiple-checkbox3"
						multiple
						value={regionNames}
						onChange={handleChangeRegion}
						sx={{ backgroundColor: '#fff', height: '30px', color: pink[600], borderColor: pink[600], fontSize: '12px' }}
						MenuProps={CenterProps}
						renderValue={(selected) => regionNames.length != props.RegionList.length ? selected.join(', ') : 'All Regions'}
					>
						<MenuItem dense={true} sx={{ padding: '0 2px'}}
							value="all"
							classes={{ root: isAllSelectedRegions ? classes.selectedAll : "" }}
						>
							<ListItemIcon>
								<Checkbox
									classes={{ indeterminate: classes.indeterminateColor }}
									checked={isAllSelectedRegions}
									indeterminate={regionNames.length > 0 && regionNames.length < props.RegionList.length}
								/>
							</ListItemIcon>
							<ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
						</MenuItem>
						{/* select all */}

						{props && props.RegionList.length > 0 && props.RegionList.map((item) => (
							<MenuItem dense={true} sx={{ padding: '0 2px' }} key={item.regionName} value={item.regionName}>
								<Checkbox size="small" checked={regionNames.indexOf(item.regionName) > -1} />
								<ListItemText primary={item.regionName} sx={{ fontSize: '10px' }} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Tooltip>
			{/* Region Filter End ---- */}
			{/* Center Filter start ---- */}
			<Tooltip arrow={true} title={centerNames.toString()}>
				<FormControl sx={{ m: 1, width: 95, marginRight: '2px', marginLeft: '2px', borderColor: pink[600] }}>
					<InputLabel id="demo-multiple-checkbox-label3" size="small" variant='outlined' sx={{ fontSize: '12px', marginTop: '-2px', color: pink[600], borderColor: pink[600] }}>Centers</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label3"
						id="demo-multiple-checkbox3"
						multiple
						value={centerNames}
						onChange={handleChange}
						sx={{ backgroundColor: '#fff', height: '30px', color: pink[600], borderColor: pink[600], fontSize: '12px' }}
						MenuProps={CenterProps}
						renderValue={(selected) => centerNames.length != props.Center.length ? selected.join(', ') : 'All Centers'}
					>
						<MenuItem dense={true} sx={{ padding: '0 2px'}}
							value="all"
							classes={{ root: isAllSelectedCenters ? classes.selectedAll : "" }}
						>
							<ListItemIcon>
								<Checkbox
									classes={{ indeterminate: classes.indeterminateColor }}
									checked={isAllSelectedCenters}
									indeterminate={centerNames.length > 0 && centerNames.length < props.Center.length}
								/>
							</ListItemIcon>
							<ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
						</MenuItem>
						{/* select all */}

						{props && props.Center.length > 0 && props.Center.map((name) => (
							<MenuItem dense={true} sx={{ padding: '0 2px' }} key={name.centername} value={name.centername}>
								<Checkbox size="small" checked={centerNames.indexOf(name.centername) > -1} />
								<ListItemText primary={name.centername + " - (" + name.siteCode + ")"} sx={{ fontSize: '10px' }} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Tooltip>
			{/* Center Filter End ---- */}
			{/* Month Filter Start ---- */}
			<Tooltip arrow={true} title={Months.length == months.length ? 'All' : Months.toString()}>
				<FormControl sx={{ m: 1, width: 95, marginRight: '2px', marginLeft: '2px' }}>
					<InputLabel id="demo-multiple-checkbox-label3" size="small" variant='outlined' sx={{ fontSize: '12px', marginTop: '-2px', color: '#d81b60', borderColor: '#d81b60' }}>Month</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label3"
						id="demo-multiple-checkbox3"
						multiple
						value={Months}
						onChange={handleChangeMonth}
						renderValue={(selected) => months.length != Months.length ? selected.join(', ') : 'All Month'}
						MenuProps={MnthProps}
						sx={{ backgroundColor: '#fff', height: '30px', color: pink[600], fontSize: '12px' }}
					>
						<MenuItem dense={true} sx={{ padding: '0 2px' }}
							value="all"
							classes={{ root: isAllSelectedMonths ? classes.selectedAll : "" }}
						>
							<ListItemIcon>
								<Checkbox
									classes={{ indeterminate: classes.indeterminateColor }}
									checked={isAllSelectedMonths}
									indeterminate={Months.length > 0 && Months.length < months.length}
								/>
							</ListItemIcon>
							<ListItemText classes={{ primary: classes.selectAllText }} primary="All" />
						</MenuItem>

						{months.map((name) => (
							<MenuItem dense={true} sx={{ padding: '0 2px' }} key={name.value} value={name.value}>
								<Checkbox size="small" checked={Months.indexOf(name.value) > -1} />
								<ListItemText primary={name.label} sx={{ fontSize: '5px' }} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Tooltip>
			{/* Month Filter End ---- */}
			{/* Year Filter Start ---- */}
			<Tooltip arrow={true} title={Years.toString()}>
				<FormControl sx={{ m: 1, width: 95, marginRight: '2px', marginLeft: '2px' }}>
					<InputLabel id="demo-multiple-checkbox-label3" size="small" variant='outlined' sx={{ fontSize: '12px', marginTop: '-2px', color: pink[600] }}>Year</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label3"
						id="demo-multiple-checkbox3"
						multiple
						value={Years}
						onChange={handleChangeYear}
						renderValue={(selected) => props.YearData.length != Years.length ? selected.join(', ') : 'All Years'}
						MenuProps={MnthProps}
						sx={{ backgroundColor: '#fff', height: '30px', color: pink[600], fontSize: '12px' }}
					>
						<MenuItem dense={true} sx={{ padding: '0 2px' }}
							value="all"
							classes={{ root: isAllSelectedYears ? classes.selectedAll : "" }}
						>
							<ListItemIcon>
								<Checkbox
									classes={{ indeterminate: classes.indeterminateColor }}
									checked={isAllSelectedYears}
									indeterminate={Years.length > 0 && Years.length < props.YearData.length}
								/>
							</ListItemIcon>
							<ListItemText classes={{ primary: classes.selectAllText }} primary="All" />
						</MenuItem>

						{props.YearData.map((name) => (
							<MenuItem dense={true} sx={{ padding: '0 2px' }} key={name.Year} value={name.Year}>
								<Checkbox size="small" checked={Years.indexOf(name.Year) > -1} />
								<ListItemText primary={name.Year} sx={{ fontSize: '5px' }} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Tooltip>
			{/* Year Filter End ---- */}
			{/* LOS Filter Start ---- */}
			<Tooltip arrow={true} title={LOSName.toString()}>
				<FormControl sx={{ m: 1, width: 95, marginRight: '2px', marginLeft: '2px' }}>
					<InputLabel id="demo-multiple-checkbox-label3" size="small" variant='outlined' sx={{ fontSize: '12px', marginTop: '-2px', color: '#d81b60', borderColor: '#d81b60' }}>LOS</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label3"
						id="demo-multiple-checkbox3"
						multiple
						value={LOSName}
						onChange={handleChangeLOS}
						renderValue={(selected) => LOSName.length != props.LOSList.length ? selected.join(', ') : 'All LOS'}
						MenuProps={LOSProps}
						sx={{ backgroundColor: '#fff', height: '30px', color: pink[600], fontSize: '12px' }}
					>
						<MenuItem dense={true} sx={{ padding: '0 2px' }}
							value="all"
							classes={{ root: isAllSelectedLOS ? classes.selectedAll : "" }}
						>
							<ListItemIcon>
								<Checkbox
									classes={{ indeterminate: classes.indeterminateColor }}
									checked={isAllSelectedLOS}
									indeterminate={LOSName.length > 0 && LOSName.length < props.LOSList.length}
								/>
							</ListItemIcon>
							<ListItemText classes={{ primary: classes.selectAllText }} primary="All" />
						</MenuItem>

						{props && props.LOSList.length > 0 && props.LOSList.map((name) => (
							<MenuItem dense={true} sx={{ padding: '0 2px' }} key={name.Group} value={name.Group}>
								<Checkbox size="small" checked={LOSName.indexOf(name.Group) > -1} />
								<ListItemText primary={name.Group} sx={{ fontSize: '5px' }} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Tooltip>
			{/* LOS Filter End ---- */}
			{/* IP/OP Filter Start ---- */}
			<Tooltip arrow={true} title={IPOPName.length == IPOPList.length ? 'All' : IPOPName.toString()}>
				<FormControl sx={{ m: 1, width: 95, marginRight: '2px', marginLeft: '2px' }}>
					<InputLabel id="demo-multiple-checkbox-label3" size="small" variant='outlined' sx={{ fontSize: '12px', marginTop: '-2px', color: '#d81b60', borderColor: '#d81b60' }}>IP/OP</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label3"
						id="demo-multiple-checkbox3"
						multiple
						value={IPOPName}
						onChange={handleChangeIPOP}
						renderValue={(selected) => months.length != Months.length ? selected.join(', ') : 'All'}
						MenuProps={MnthProps}
						sx={{ backgroundColor: '#fff', height: '30px', color: pink[600], fontSize: '12px' }}
					>
						<MenuItem dense={true} sx={{ padding: '0 2px' }}
							value="all"
							classes={{ root: isAllSelectedIPOP ? classes.selectedAll : "" }}
						>
							<ListItemIcon>
								<Checkbox
									classes={{ indeterminate: classes.indeterminateColor }}
									checked={isAllSelectedIPOP}
									indeterminate={IPOPName.length > 0 && IPOPName.length < IPOPList.length}
								/>
							</ListItemIcon>
							<ListItemText classes={{ primary: classes.selectAllText }} primary="All" />
						</MenuItem>

						{IPOPList.map((name) => (
							<MenuItem dense={true} sx={{ padding: '0 2px' }} key={name.value} value={name.value}>
								<Checkbox size="small" checked={IPOPName.indexOf(name.value) > -1} />
								<ListItemText primary={name.label} sx={{ fontSize: '5px' }} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Tooltip>
			{/* IP/OP Filter End ---- */}
			{/* Item Group Filter Start ---- */}
			<Tooltip arrow={true} title={itemGroupNames.toString()}>
				<FormControl sx={{ m: 1, width: 95, marginRight: '2px', marginLeft: '2px', borderColor: pink[600] }}>
					<InputLabel id="demo-multiple-checkbox-label3" size="small" variant='outlined' sx={{ fontSize: '12px', marginTop: '-2px', color: pink[600], borderColor: pink[600] }}>Item Group</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label3"
						id="demo-multiple-checkbox3"
						multiple
						value={itemGroupNames}
						onChange={handleChangeItemGroup}
						sx={{ backgroundColor: '#fff', height: '30px', color: pink[600], borderColor: pink[600], fontSize: '12px' }}
						MenuProps={itemgpProps}
						renderValue={(selected) => itemGroupNames.length != props.ItemGroup.length ? selected.join(', ') : 'All Item Group'}
					>
						<MenuItem dense={true} sx={{ padding: '0 2px'}}
							value="all"
							classes={{ root: isAllSelectedItemGroupName ? classes.selectedAll : "" }}
						>
							<ListItemIcon>
								<Checkbox
									classes={{ indeterminate: classes.indeterminateColor }}
									checked={isAllSelectedItemGroupName}
									indeterminate={itemGroupNames.length > 0 && itemGroupNames.length < props.ItemGroup.length}
								/>
							</ListItemIcon>
							<ListItemText classes={{ primary: classes.selectAllText }} primary="All Item Group" />
						</MenuItem>
						{/* select all */}

						{props && props.ItemGroup.length > 0 && props.ItemGroup.map((name) => (
							<MenuItem dense={true} sx={{ padding: '0 2px' }} key={name.ItemGroupName} value={name.ItemGroupName}>
								<Checkbox size="small" checked={itemGroupNames.indexOf(name.ItemGroupName) > -1} />
								<ListItemText primary={name.ItemGroupName} sx={{ fontSize: '10px' }} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Tooltip>
			{/* Item Group Filter End ---- */}
			{/* Docter Filter Start ---- */}
			<Tooltip arrow={true} title={DoctorNames.toString()}>
				<FormControl sx={{ m: 1, width: 95, marginRight: '2px', marginLeft: '2px', borderColor: pink[600] }}>
					<InputLabel id="demo-multiple-checkbox-label3" size="small" variant='outlined' sx={{ fontSize: '12px', marginTop: '-2px', color: pink[600], borderColor: pink[600] }}>Doctor</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label3"
						id="demo-multiple-checkbox3"
						multiple
						value={DoctorNames}
						onChange={handleChangeDoctor}
						sx={{ backgroundColor: '#fff', height: '30px', color: pink[600], borderColor: pink[600], fontSize: '12px' }}
						MenuProps={itemgpProps}
						renderValue={(selected) => DoctorNames.length != props.Doctor.length ? selected.join(', ') : 'All Doctor'}
					>
						<MenuItem dense={true} sx={{ padding: '0 2px'}}
							value="all"
							classes={{ root: isAllSelectedDoctors ? classes.selectedAll : "" }}
						>
							<ListItemIcon>
								<Checkbox
									classes={{ indeterminate: classes.indeterminateColor }}
									checked={isAllSelectedItemGroupName}
									indeterminate={DoctorNames.length > 0 && DoctorNames.length < props.Doctor.length}
								/>
							</ListItemIcon>
							<ListItemText classes={{ primary: classes.selectAllText }} primary="All Doctor" />
						</MenuItem>
						{/* select all */}

						{props && props.Doctor.length > 0 && props.Doctor.map((name) => (
							<MenuItem dense={true} sx={{ padding: '0 2px' }} key={name.DoctorName} value={name.DoctorName}>
								<Checkbox size="small" checked={DoctorNames.indexOf(name.DoctorName) > -1} />
								<ListItemText primary={name.DoctorName} sx={{ fontSize: '10px' }} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Tooltip>
			{/* IDoctor Filter End ---- */}
		</Box>

	)

}

export default FilterCompo;