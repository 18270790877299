import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import "jspdf-autotable";
import _filefy from "filefy";
import moment from "moment";
import { calculateIpAgreement } from '../BusinessLogic/ipCalc';

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AgreementComponentip({ ipcomp, history }) {
  const classes = useStyles();

  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  console.log(ipcomp, "ipcomp")
  return (
    <div className="card custom-card" >

      <div className="card-body">

        {ipcomp &&
          ipcomp.map((ele, key) => (
            <>
              <h6 style={{ textAlign: "center" }}><strong><u>IN-PATIENT FOR {(ele.Center).toUpperCase()}</u></strong></h6>
              < p >
                {/* <strong>A.2-{key + 1}. For In-Patient {ele.itemGroup.split("-")[1] === undefined ? ele.itemGroup.split("-")[0] : ele.itemGroup.split("-")[1]}:</strong> For
                          In-Patient {ele.itemGroup.split("-")[1] === undefined ? ele.itemGroup.split("-")[0] : ele.itemGroup.split("-")[1]} provided by the Consultant
                          under this agreement, the Company shall pay {""} */}
                <strong>A.2-{key + 1}. For In-Patient {ele.itemGroup.split("-")[1] === undefined ? ele.itemGroup.split("-")[0] : ele.itemGroup.split("-")[1]}:</strong> For
                In-Patient {ele.itemGroup ? ele.itemGroup : ""}, {ele.itemName ? ele.itemName : ""} provided by the Consultant
                under this agreement, the Company shall pay {""}
                {calculateIpAgreement.ipAgreementCalculations(ele)}

                {/* {ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "Yes" ? <> {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Amount </> :
                ele.ipdeductaionvalue !== "0" && ele.ipdiscountType === "No" ? <>  {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Gross Bill excluding Deductions </> :
                  ele.ipdeductaionvalue !== "0" && ele.ipdiscountType === "Yes" ? <> {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Bill excluding Deductions </> :
                    ele.ippackageamountperc !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "No" ? <> {ele.ippackageamountperc}% of the Package Amount </> :
                      ele.ipsurgeonfeeperc !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "No" ? <> {ele.ipsurgeonfeeperc}% of the  Surgeon Fee </> :
                        ele.ipnetbillpercentage !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "No" ? <> {ele.ipnetbillpercentage}% of the Net Bill Amount </> :
                          ele.ipgrossbillpercentage !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "No" ? <> {ele.ipgrossbillpercentage}% of the Gross Bill Amount </>
                            : ele.ipfixedamount !== 0
                              ? " Fixed Amount of " + ele.ipfixedamount + "/- Rs per case "
                              : ""} */}

                {" "}   collected from the patients/ customers attended by the Consultant.
              </p>
            </>
          ))}

        <p>
          <strong>A.3. For In-Patient Procedures: </strong>
        </p>

        <div className="listAgmt">
          {ipcomp &&
            ipcomp.map((ele, key) => (

              <p>
                {/* (a.{ele.id}) For {ele.iproom}, {ele.itemGroup.split("-")[0]}{ele.itemGroup.split("-")[1] === undefined ? " " : "-" + ele.itemGroup.split("-")[1]} - {ele.ipfixedamount ?  "Rs."+ ele.ipfixedamount +"/- per case" : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc +"%"}  */}
                (a.{key + 1}) For  {ele.iproom !== "" ? ele.iproom + ',' : ""} {ele.itemGroup ? ele.itemGroup : ""}({ele.itemName ? ele.itemName : ""}) -{""}
                {calculateIpAgreement.ipProcAgreementCalculation(ele)} in {ele.ipmodeofpayment}
                {/* {ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue !== "0" ? <> {ele.ipfixedamount ? "Rs." + ele.ipfixedamount + "/- per case" : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Amount </> :
                  ele.ipdeductaionvalue !== "0" && ele.ipdiscountvalue === "0" ? <>  { ele.ipfixedamount ? "Rs." + ele.ipfixedamount + "/- per case" : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Gross Bill excluding Deductions </> :
                    ele.ipdeductaionvalue !== "0" && ele.ipdiscountvalue !== "0" ? <> {ele.ipfixedamount ? "Rs." + ele.ipfixedamount + "/- per case" : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Bill excluding Deductions </> :
                      ele.ipdeductaionvalue !== "0" && ele.ipdiscountvalue !== "0" ? <> {ele.ipfixedamount ? "Rs." + ele.ipfixedamount + "/- per case" : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Bill excluding Deductions </> :
                        ele.ippackageamountperc !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue === "0" ? <> {ele.ipfixedamount !== "0" ? "Rs." + ele.ipfixedamount + "/- per case" : ele.ippackageamountperc + "% of the Package Amount"} </> :
                          ele.ipsurgeonfeeperc !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue === "0" ? <> {ele.ipfixedamount !== "0" ? "Rs." + ele.ipfixedamount + "/- per case" : ele.ipsurgeonfeeperc + "% of the  Surgeon Fee"} </> :
                            ele.ipnetbillpercentage !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue === "0" ? <> {ele.ipfixedamount !== "0" ? "Rs." + ele.ipfixedamount + "/- per case" : ele.ipnetbillpercentage + "% of the Net Bill Amount"} </> :
                              ele.ipgrossbillpercentage !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue === "0" ? <> {ele.ipfixedamount !== "0" ? "Rs." + ele.ipfixedamount + "/- per case" : ele.ipgrossbillpercentage + "% of the Gross Bill Amount"} </> :
                                ele.ipfixedamount !== "0" ? "Rs." + ele.ipfixedamount + "/- per case"
                                  : ""} */}
              </p>
            ))

          }
          <p>
            (b) During the calendar month if Normal/LSCS
            deliveries are more than or equal to 5 then
            Rs.25,000/- per case payable.{" "}
          </p>

          <p>
            (c) For all other In Inpatient Services (IP) provided
            by the Consultant during the term of this agreement,
            the company shall pay a fixed professional fee as
            defined in the Hospital Tariff Package approved by the
            management of the company from time to time. The
            details of the package is enclosed herewith as
            ANNEXURE-Platinum. For any other Inpatient services
            provided by the consultant, which are not covered in
            the Hospital Tariff Package, for such services the
            Consultant shall be compensated on case to case basis
            as decided by the management of the company.
          </p>


        </div>
      </div>
    </div>
  );
}

export default AgreementComponentip;
