import React, { useState, useEffect } from "react";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  FormControlLabel,
  Paper,
  RadioGroup,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { APIURL } from "../../../../CommomMethods/Fetch";
import axios from "axios";

const styleSheet = makeStyles((theme) => ({
  paperStyle: { marginTop: "10px" },
  textFieldstyle: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  btnStyle: {
    marginRight: "80px",
    marginLeft: "auto",
    display: "block",
    marginBottom: "20px",
  },
}));

export default function AddPayableNonPayable({
  rows,
  addDepartment,
  regionRows,
  currentDepartment,
}) {
  const style = styleSheet();
  const dispatch = useDispatch();

  const [department, setDepartment] = useState(currentDepartment);
  const [value, setValue] = React.useState("1");
  const [centerRows, setCenterRows] = useState([]);
  const [regionslist, setRegionslist] = useState([]);
  const [center, setCenter] = useState([]);
  const [region, setRegion] = useState("");
  const [centerCod, setCenterCod] = useState("");
 
  useEffect(() => {
    axios
      .get(APIURL() + "centers/")
      .then((res) => res.data)
      .then((rows) => {
        setCenterRows(rows.filter((center) => center.status));
      });
      axios
      .get(APIURL() + "regions/")
      .then((res) => res.data)
      .then((rows) => {
        setRegionslist(rows);
      }); 
  });

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartment({ ...department, [name]: value });
  };

  let regioncode = 0;
  
  function getcenterdetails(value){
    centerRows.map(item => {
      if(item.id === value){
        setCenterCod(item.code)
        regioncode = item.region_id
      }
    })
    regionslist.map(item => {
      if(item.id === regioncode){
       setRegion(item.name);
      }
    })
    
  }

  const handleInputCenterChange = (event) => {
    const { name, value } = event.target;
    setCenter(value)
    getcenterdetails(value)
  };

  const handleSubmit = () => {
    department.centerCode = centerCod
    department.centerId = center
    department.regionName = region
    addDepartment(department)
  };

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          department.status = value;
          handleSubmit();

        }}
      >
        <Paper className={style.paperStyle}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="ItemGroupname"
                label="ItemGroup Name"
                value={department.ItemGroupname}
                onChange={handleInputChange}
                required
                fullWidth
              />
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="Itemname"
                label="Item Name"
                value={department.Itemname}
                onChange={handleInputChange}
                required
                fullWidth
              />
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "20px", marginBottom: "10px" }}
              >
                <InputLabel id="demo-mutiple-checkbox-label3">
                  Select Center
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label3"
                  id="demo-mutiple-checkbox3"
                  name="centerid"
                  value={center}
                  onChange={handleInputCenterChange}
                  label="Select Center"
                  required
                  fullWidth
                >
                  {centerRows &&
                    centerRows.map((opt) => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {/* <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="centerId"
                label="Center Id"
                value={department.centerId}
                onChange={handleInputChange}
                required
                fullWidth
              /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <FormControl
                variant="outlined"
                className={style.textFieldstyle}
                style={{ width: "100%"}}
              >
                <InputLabel id="demo-mutiple-checkbox-label3">
                  Payable/Non-Payable
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label3"
                  id="demo-mutiple-checkbox3"
                  name="Pnp"
                  value={department.Pnp}
                  onChange={handleInputChange}
                  label="Payable/Non-Payable"
                  required
                >
                <MenuItem key={0} value={"0"}>{0}</MenuItem>
                <MenuItem key={1} value={"1"}>{1}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="regionName"
                label="Region Name"
                value={region}
                disabled
                required
                fullWidth
              />
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="centerCode"
                label="Center Code"
                value={centerCod}
                disabled
                required
                fullWidth
              />
              {/* <FormControl component="fieldset">
                <FormLabel component="legend">Status</FormLabel>
                <RadioGroup
                  row
                  name="status"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio color="#00963f" />}
                    label="Active"
                    checked={value == 1 ? true : false}
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio color="#00963f" />}
                    label="Inactive"
                    checked={value == 0 ? true : false}
                  />
                </RadioGroup>
              </FormControl> */}
            </Grid>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={style.btnStyle}
            >
              Add
            </Button>
          </Grid>
        </Paper>
      </form>
    </>
  );
}
