import { USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT } from "../constants/authConstants"

export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }
        case USER_LOGIN_SUCCESS:
            return { 
                loading: false, user: action.payload
             }
        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }
        case USER_LOGOUT:
                return {  }

        default:
            return state

    }
}

// export const userLoginReducer = (state = initalState, action) => {
//     switch (action.type) {
//         case USER_LOGIN_REQUEST:
//             return { loading: true }
//         case USER_LOGIN_SUCCESS:
//             const user= jwtDecode(action.token)
//             return { 
//                 ...initalState,
//                 token:action.token,
//                 username:user.name,
//                 email:user.email,
//                 id:user.id,
//              }
//         case USER_LOGIN_FAIL:
//             return { loading: false, error: action.payload }
//             case USER_LOGOUT:
//                 return {  }

//         default:
//             return state

//     }
// }