import React from "react";
// material-table component
import MaterialTable from "material-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { numberWithCommas } from "../../../actions/createPositionActions";

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

export default function ListCenterPositionMap({
  rows,
  editRow,
  deleteDepartment,
}) {
  const tables = myTableStyle();

  console.log(rows,"ROWS");
  return (
    <>
      <Paper className={tables.root}>
        <MaterialTable
          columns={[
            // { title: "centerid", field: "centerid" },
            { title: "Center", field: "center" },
            { title: "Category", field: "category" },
            { title: "Department", field: "department" },
            { title: "Occupied", field: "occupiedposition" },
            { title: "No of Position", field: "allocated" },
            { title: "Financial Grid (Min)", field: "fg_from", render: (row)=> row.fg_from ? "Rs. "+numberWithCommas(row.fg_from) : "" },
            { title: "Financial Grid (Max)", field: "fg_to",render: (row)=> row.fg_to ? "Rs. "+numberWithCommas(row.fg_to) : ""  },
            {
              title: "Status",
              field: "status",
              lookup: { 1: "Active", 0: "Inactive" },
            },
          ]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit",
              iconProps: { style: { fontSize: "24px", color: "#5f5fe7" } },
              onClick: (event, row) => editRow(row),
            },
            {
              icon: "delete",
              tooltip: "Delete",
              iconProps: { style: { fontSize: "24px", color: "#f55151" } },
              onClick: (event, row) => {
                deleteDepartment(row);
              },
            },
          ]}
          data={rows}
          options={{
            // filtering: true,
            sorting: true,
            exportButton: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 50, 100, 200, 500],
            search: true,
          }}
          title="Positions"
        />
      </Paper>
    </>
  );
}
