import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import XLSX from "xlsx";
import moment from "moment";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
//ui components
import Navbar from "../Components/Navbar";
import DoctorSidebar from "../Components/DoctorSidebar";
import axios from "axios";
import { onboardById } from "../actions/onboardActions";
import { listusers } from "../actions/userActions";
import { payoutCalcOtherCharges } from '../Pages/Calculations/payoutOtherCharges'
import { payoutCalcNonOt } from '../Pages/Calculations/payoutNonOt'
import { payoutCalculation } from '../Pages/Calculations/payoutCalc'
import {payoutCalcRadiology} from '../Pages/Calculations/payoutCalcRadiology'
import { APIURL } from "../CommomMethods/Fetch";
import { numberWithCommas } from "../actions/createPositionActions";

const styleSheet = makeStyles((theme) => ({
  iconButtonStyle: {
    float: "right",
  },
  marginforMaster: {
    marginTop: "30px",
  },
}));

export default function PayoutDetails() {
  const classes = styleSheet();
  const dispatch = useDispatch();
  const [preview, setPreview] = useState(true);
  const [intVal, setIntVal] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [progress, setProgress] = useState(false);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [reload, setReload] = useState(false);
  const [opPayout, setOpPayout] = useState([]);
  const [opConNorth, setOpConNorth] = useState([]);
  const [opConSouthOne, setOpConSouthOne] = useState([]);
  const [opConSouthTwo, setOpConSouthTwo] = useState([]);
  const [opConSouthThree, setOpConSouthThree] = useState([]);
  const [opConSouthFour, setOpConSouthFour] = useState([]);
  const [opRadNorthPayout, setRadNorthOpPayout] = useState([]);
  const [opRadSouthPayout, setRadSouthOpPayout] = useState([]);
  const [opRadwestPayout, setRadWestOpPayout] = useState([]);
  const [opNonNorthPayout, setNonNorthOpPayout] = useState([]);
  const [opNonSouthPayout, setNonSouthOpPayout] = useState([]);
  const [opNonwestPayout, setNonWestOpPayout] = useState([]);
  const [opOcNorth, setOpOcNorth] = useState([]);
  const [opOcsouth, setOpOcSouth] = useState([]);
  const [opOcwest, setOpOcWest] = useState([]);
  const [opPay, setOpPay] = useState([]);
  const [opPayDisp, setOpPayDisp] = useState([]);
  const [opPayoutRowId, setOpPayoutRowId] = useState([]);
  const [opTotPayout, setOpTotPayout] = useState([]);
  const [doctorOpCommercials, setdoctorOpCommercials] = useState([]);

  const onboardId = useSelector((state) => state.onboardId);
  const { oboard } = onboardId;
  console.log(oboard, "@@@@@@");

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  useEffect(() => {
    axios
      .get(APIURL() + "billings/findAllBillingDet")
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows, "AllExcelData");
        setRows(rows.data);

        var k = rows.data.filter(z => z.ConductedBy === user.user.username)
        setFilteredRows(k);
        console.log(k.length, "******")
      });
  }, [reload]);

  useEffect(() => {
    dispatch(onboardById(user.user.id));
  }, [reload]);


  const calculatePayout = (row) => {
    setReload(true);
    if(row.ItemGroupName === "Non OT Procedures"){
    var a = payoutCalcNonOt.northNonOtCalculation(row, oboard);
    var b = payoutCalcNonOt.westNonOtCalculation(row, oboard);
    var c = payoutCalcNonOt.southNonOtCalculation(row, oboard);
    setNonNorthOpPayout([...opNonNorthPayout, a]);
    setNonSouthOpPayout([...opNonSouthPayout, c]);
    setNonWestOpPayout([...opNonwestPayout, b]);
    setOpPay([...opPay,a]);
    setOpPayoutRowId([...opPayoutRowId, { id: row.id,NonOtNorth:a ,NonOtSouth:c, NonOtWest:b}]);
    }
    else if(row.ItemGroupName === "Other Charges"){
      var d = payoutCalcOtherCharges.northOtherChargesCalculation(row, oboard);
    var e = payoutCalcOtherCharges.southOtherChargesCalculation(row, oboard);
    var f = payoutCalcOtherCharges.westOtherChargesCalculation(row, oboard);
    setOpOcNorth([...opOcNorth, d]);
    setOpOcSouth([...opOcsouth, e]);
    setOpOcWest([...opOcwest, f]);
    setOpPayout([...opPayout, d]);
    setOpPay([...opPay,d]);
    setOpPayoutRowId([...opPayoutRowId, { id: row.id,nothOc:d, southOc:e,westOc:f}]);
    } else if(row.ItemGroupName === "Radiology"){   
      var a = payoutCalcRadiology.northRadiologyCalculation(row, oboard);
      var b = payoutCalcRadiology.westRadiologyOneCalculation(row, oboard);
      var c = payoutCalcRadiology.southRadiologyOneCalculation(row, oboard);
      setRadNorthOpPayout([...opRadNorthPayout, a]);
      setRadSouthOpPayout([...opRadSouthPayout, c]);
      setRadWestOpPayout([...opRadwestPayout, b]);
      setOpPay([...opPay,a]);
      setOpPayoutRowId([...opPayoutRowId, { id: row.id,RadNorth:a ,RadSouth:c,Radwest:b}]);
    }
    else if(row.ItemGroupName === "OP Physician Consulting"){
      var k = payoutCalculation.northRegionOpCalculation(row, oboard);
      var l = payoutCalculation.southRegionOneOpCalculation(row, oboard);
      var m = payoutCalculation.southRegionTowOpCalculation(row, oboard);
      var n = payoutCalculation.southRegionThreeOpCalculation(row, oboard);
      var o = payoutCalculation.southRegionFourOpCalculation(row, oboard);
      setOpConNorth([...opConNorth, k]);
      setOpConSouthOne([...opConSouthOne, l]);
      setOpConSouthTwo([...opConSouthTwo, m]);
      setOpConSouthThree([...opConSouthThree, n]);
      setOpConSouthFour([...opConSouthFour, o]);
      setOpPayoutRowId([...opPayoutRowId, { id: row.id, north: k,southone:l, southtwo:m, souththree:n, southfour:o}]);
      setOpPayout([...opPayout, k]);
      setOpPay([...opPay,k]);
     }
    else{
      alert("Incorrect data!")
    }
    
  }

  // const calculatePayout = (row) => {
  //   setReload(true);
  //   // var k = payoutCalculation.rowLevelPayoutCalculation(row, oboard);
  //   var k = payoutCalculation.northRegionOpCalculation(row, oboard);
  //   setOpPayout([...opPayout, k]);
  //   setOpPay([...opPay,k]);
  //   setOpPayoutRowId([...opPayoutRowId, { id: row.id, value: k }]);
  // }
  // const calculateRadiology = () => {

  // }
 
  const calculatePayoutTot = () => {
    let sum = 0;
    for (let i = 0; i < opPay.length; i++) {
      sum += opPay[i];
      setOpTotPayout(sum);
    }
    console.log(sum, "+++===");
  }

  return (
    <>
      <DoctorSidebar />
      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.marginforMaster}
              >
                <MaterialTable
                  columns={[
                    { title: "SlNo", field: "SlNo" },
                    { title: "MPI", field: "MPI" },
                    { title: "Patient Name", field: "PatientName" },
                    { title: "Bill Time", field: "BillTime" },
                    { title: "Bill No", field: "BillNo", render: row => <div style={{ cursor: "pointer", color: "red" }} onClick={() => alert(row.BillNo)}>{row.BillNo}</div> },
                    { title: "Bill Date", field: "BillDate" },
                    { title: "Admin Fee", field: "AdminFee" },
                    { title: "Item Group Name", field: "ItemGroupName" },
                    { title: "Item Name", field: "ItemName" },
                    { title: "Gross", field: "Gross" },
                    { title: "Tax", field: "Tax" },
                    { title: "Discount", field: "Discount" },
                    { title: "Net", field: "Net" },
                    { title: "Calculate", field: "calculate", render: row => <div  >{!opPayoutRowId.includes(row.id) ? <Button size="small" color="secondary" variant="contained" onClick={() => { calculatePayout(row) }}>Calculate </Button> : opPayout}</div> },
                    {
                      title: "Radiology North",
                      field: "Action",
                      filtering: false,
                      render: (row) => (
                        opPayoutRowId && opPayoutRowId.map((ele) => {
                          return( <div>
                            {ele.id === row.id ? ele.RadNorth : ""}                         
                           </div>)                       
                        })
                      ),
                    },
                    {
                      title: "Radiology South one",
                      field: "Action",
                      filtering: false,
                      render: (row) => (
                        opPayoutRowId && opPayoutRowId.map((ele) => {
                          return( <div>
                            {ele.id === row.id ? ele.RadSouth : ""}                         
                           </div>)                       
                        })
                      ),
                    },
                   
                    {
                      title: "Radiology West one",
                      field: "Action",
                      filtering: false,
                      render: (row) => (
                        opPayoutRowId && opPayoutRowId.map((ele) => {
                          return( <div>
                            {ele.id === row.id ? ele.Radwest : ""}                         
                           </div>)                       
                        })
                      ),
                    },
                    {
                      title: "Consultation North",
                      field: "Action",
                      filtering: false,
                   //   hidden: (filteredRows.ItemGroupName==="Radiology" ? true: false),
                      render: (row) => (
                        opPayoutRowId && opPayoutRowId.map((ele) => {
                          return( <div>
                            {ele.id === row.id ? ele.north : ""}                         
                           </div>)                       
                        })
                      ),
                    },
  
                    {
                      title: "Consultation South One",
                      field: "Action",
                      filtering: false,
                      render: (row) => (
                        opPayoutRowId && opPayoutRowId.map((ele) => {
                          return( <div>
                            {ele.id === row.id ? ele.southone : ""}                         
                           </div>)                       
                        })
                      ),
                    },
                    {
                      title: "Consultation South two",
                      field: "Action",
                      filtering: false,
                      render: (row) => (
                        opPayoutRowId && opPayoutRowId.map((ele) => {
                          return( <div>
                            {ele.id === row.id ? ele.southtwo : ""}                         
                           </div>)                       
                        })
                      ),
                    },
                    {
                      title: "Consultation South three",
                      field: "Action",
                      filtering: false,
                      render: (row) => (
                        opPayoutRowId && opPayoutRowId.map((ele) => {
                          return( <div>
                            {ele.id === row.id ? ele.souththree : ""}                         
                           </div>)                       
                        })
                      ),
                    },
                    {
                      title: "Consultation South Four",
                      field: "Action",
                      filtering: false,
                      render: (row) => (
                        opPayoutRowId && opPayoutRowId.map((ele) => {
                          return( <div>
                            {ele.id === row.id ? ele.southfour : ""}                         
                           </div>)                       
                        })
                      ),
                    },
                    {
                      title: "Calculate North",
                      field: "Action",
                      filtering: false,
                      render: (row) => (
                        opPayoutRowId && opPayoutRowId.map((ele) => {
                          return( <div>
                            {ele.id === row.id ? ele.NonOtNorth : ""}                         
                           </div>)                       
                        })
                      ),
                    },
            
                    {
                      title: "Calculate South",
                      field: "Action",
                      filtering: false,
                      render: (row) => (
                        opPayoutRowId && opPayoutRowId.map((ele) => {
                          return( <div>
                            {ele.id === row.id ? ele.NonOtSouth : ""}                         
                           </div>)                       
                        })
                      ),
                    },
                   
                    {
                      title: "Calculate West",
                      field: "Action",
                      filtering: false,
                      render: (row) => (
                        opPayoutRowId && opPayoutRowId.map((ele) => {
                          return( <div>
                            {ele.id === row.id ? ele.NonOtWest : ""}                         
                           </div>)                       
                        })
                      ),
                    },
                    {
                        title: "Calculate North (OC)",
                        field: "Action",
                        filtering: false,
                        render: (row) => (
                          opPayoutRowId && opPayoutRowId.map((ele) => {
                            return( <div>
                              {ele.id === row.id ? ele.nothOc : ""}                         
                             </div>)                       
                          })
                        ),
                      },
              
                      {
                        title: "Calculate South (OC)",
                        field: "Action",
                        filtering: false,
                        render: (row) => (
                          opPayoutRowId && opPayoutRowId.map((ele) => {
                            return( <div>
                              {ele.id === row.id ? ele.southOc : ""}                         
                             </div>)                       
                          })
                        ),
                      },
                     
                      {
                        title: "Calculate West (OC)",
                        field: "Action",
                        filtering: false,
                        render: (row) => (
                          opPayoutRowId && opPayoutRowId.map((ele) => {
                            return( <div>
                              {ele.id === row.id ? ele.westOc : ""}                         
                             </div>)                       
                          })
                        ),
                      },

                    { title: "Share", field: "Share" },
                    { title: "Payable/NonPayable", field: "PayableNonPayable" },
                
                    { title: "Ordering Physician", field: "OrderingPhysician" },
                    { title: "Specialisation", field: "Specialisation" },
                    { title: "ConductedBy", field: "ConductedBy" },
                    { title: "User", field: "User" },
                    { title: "Conducted By Final", field: "ConductedByFinal" },
                    { title: "Location", field: "Location" },

                  ]}
                  data={filteredRows}

                  options={{
                    // filtering: true,
                    sorting: true,
                    exportButton: true,
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                    search: true,
                  }}
                  title="Payout Details"
                />
                <Grid item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.marginforMaster}>
                  <label><b>Total Payout: Rs. {opTotPayout ? numberWithCommas(opTotPayout) : ""}/-</b> </label>
                  <Button style={{ float: "right", margin: "1rem" }} size="small" color="secondary" variant="contained" onClick={() => { calculatePayoutTot() }}>Calculate All</Button>
                </Grid>

              </Grid>
            </Grid>



          </div>
        </div>
      </div>
    </>
  );
}
