import React, { useState } from "react";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  FormControlLabel,
  Paper,
  RadioGroup,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import ImageUploader from "react-images-upload";

const styleSheet = makeStyles((theme) => ({
  paperStyle: { marginTop: "10px" },
  textFieldstyle: {
    marginBottom: "12px",
    marginTop: "12px",
  },
  btnStyle: {
    float: "right",
    marginTop: "20px",
    // marginRight: "80px",
    // marginLeft: "auto",
    // display: "block",
    // marginBottom: "20px",
  },
}));

export default function AddUser({ addDepartment, roleRows }) {
  const style = styleSheet();
  const initialFormState = {
    id: null,
    email: "",
    name: "",
    mobile: "",
    Gender: "",
    designation: "",
    users_role_id: "",
    profile_picture: "",
    status: "",
  };
  const [department, setDepartment] = useState(initialFormState);
  const [value, setValue] = useState("Male");
  const [desRole, setDesRole] = useState("");
  const [desVal, setDesVal] = useState("");
  const [des, setDes] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartment({ ...department, [name]: value });
  };


  const handleInputChangeDes = (event) => {
    const { name, value } = event.target.value.name;
    setDesRole(event.target.value.code);
    setDesVal(event.target.value.id);
    setDes(event.target.value.name);
    setDepartment({ ...department, [name]: value });
  };
  console.log(des, "E");

  const handleMediaChange = (media) => {
    const data = new FormData();
    data.append("Media_File", media[0]);
    department.profile_picture = data;
  };

  console.log(roleRows, "roleRows")
  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          department.Gender = value;
          department.users_role_id = desVal;
          department.designation = des;
          addDepartment(department);
        }}
      >
        <Paper className={style.paperStyle}>
          <CssBaseline />
          <Container>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  className={style.textFieldstyle}
                  variant="outlined"
                  name="email"
                  label="User Email"
                  value={department.email}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />

                <TextField
                  className={style.textFieldstyle}
                  variant="outlined"
                  name="mobile"
                  label="User Phone"
                  value={department.mobile}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />

                <FormControl
                  variant="outlined"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Select Designation
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="designation"
                    // value={des}
                    // value={department.designation}
                    onChange={handleInputChangeDes}
                    label="Select Designation"
                  >
                    {roleRows &&
                      roleRows.map((opt) => (
                        <MenuItem key={opt.id} value={opt}>
                          {opt.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {/* <ImageUploader
                  withIcon={true}
                  buttonText="Upload Profile Image"
                  name="Media_File"
                  onChange={handleMediaChange}
                  imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
                  maxFileSize={52880}
                  withPreview={true}
                  singleImage={true}
                /> */}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <TextField
                  className={style.textFieldstyle}
                  variant="outlined"
                  name="name"
                  label="User Name"
                  value={department.name}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />

                <FormControl
                  component="fieldset"
                  className={style.textFieldstyle}
                >
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-label="Position"
                    name="Gender"
                    value={department.Gender}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Male"
                      control={<Radio color="#fd7e14" />}
                      label="Male"
                      checked={value == "Male" ? true : false}
                    />
                    <FormControlLabel
                      value="Female"
                      control={<Radio color="#00963f" />}
                      label="Female"
                      checked={value == "Female" ? true : false}
                    />
                  </RadioGroup>
                </FormControl>

                <FormControl variant="outlined" style={{ width: "100%" }}>
                  {/* <InputLabel id="demo-simple-select-outlined-label">
                    Select Role
                  </InputLabel> */}
                  <TextField
                    className={style.textFieldstyle}
                    variant="outlined"
                    name="name"
                    label="Role"
                    value={desRole}
                    // onChange={handleInputChange}
                    required
                    fullWidth
                    disabled
                  />

                  {/* <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name="users_role_id"
                    // value={department.users_role_id}
                    value={desRole}
                    // onChange={handleInputChange}
                    label="Select Role"
                  >

                    {roleRows &&
                      roleRows.map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.code}
                        </MenuItem>
                      ))}
                  </Select> */}
                </FormControl>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={style.btnStyle}
                >
                  Create User
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </form>
    </>
  );
}
