import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
} from "recharts";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const nameValue =  ""
const CustomTooltip = ({ active, payload, label }) => {
  console.log(payload,"payload, label")
  if (active && payload && payload.length) {
    // if(payload[0].name == 'Total Vacancy'){
    //   nameValue = "Filled Vacancy"
    // }
    return (
      <div className="custom-tooltip">
        <p
          className="label"
          style={{ color: "black", backgroundColor: "white" }}
        >{`${payload[0].name} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};
function ChartComponent1({ datalist }) {
  var totaldata = datalist && datalist.data && datalist.data.mergeResult;
  console.log(totaldata,"lkl========>")
  return (
    <div className="cardsTheme1" style={{ height: '285px' }}>
      <h4 style={{ textAlign: "center", marginBottom: '10px' }}>Vacancy </h4>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={totaldata}
            dataKey="value"
            // nameKey="name"
            // valueKey="value"
            cx="50%"
            cy="40%"
            outerRadius={100}
            fill="#8884d8"
          >
            {totaldata &&
              totaldata.length > 0 &&
              totaldata.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
          </Pie>
          <Tooltip content={<CustomTooltip />}/>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ChartComponent1;
