import React from "react";
//ui components
import PTSidebar from "../../Components/PTSidebar";
import Navbar from "../../Components/Navbar";
//mui components
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Button, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import Skeleton from "@material-ui/lab/Skeleton";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";

export default function PayoutReport() {
  return (
    <>
      <PTSidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
      </Container>
    </>
  );
}
