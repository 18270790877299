import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// material-table component
import MaterialTable from "material-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

export default function ListPayableNonPayable({
  rows,
  editRow,
  deleteDepartment,
}) {
  const tables = myTableStyle();

  const [data, setData] = useState("Select the Region");
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  console.log(rows,"@@@@@@");
  function selectedDropdownvalue(e) {
    const { name, value } = e.target;
    setData(value);
  }

  return (
    <>
      <Paper className={tables.root}>
        {/* <FormControl
          variant="outlined"
          style={{ width: "50%", marginBottom: "10px" }}
        >
          <InputLabel
            id="demo-simple-select-outlined-label"
            style={{ margin: "15px" }}
          >
            <b>Filter By-</b>
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={data}
            onChange={selectedDropdownvalue}
            label="select the value"
            fullWidth
          >
            <MenuItem value="Select the value" disabled>
              Select region
            </MenuItem>
            <MenuItem value="South">South</MenuItem>
            <MenuItem value="West">West</MenuItem>
            <MenuItem value="North">North</MenuItem>
          </Select>
        </FormControl> */}
        <MaterialTable
          columns={[
            { title: "ItemGroup name", field: "ItemGroupname" },
            { title: "Item Name", field: "Itemname" },
            {
              title: "Payable/NonPayable",
              field: "Pnp",
              lookup: {
                1: "Yes",
                0: "No",
                true: "Yes",
                false: "No",
              },
            },
            { title: "Region", field: "regionName" },
            { title: "Center Code", field: "centerCode" },
            { title: "Center Name", field: "name" },
          ]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit",
              iconProps: { style: { fontSize: "24px", color: "#5f5fe7" } },
              onClick: (event, row) => editRow(row),
            },
            {
              icon: "delete",
              tooltip: "Delete",
              iconProps: { style: { fontSize: "24px", color: "#f55151" } },
              onClick: (event, row) => {
                deleteDepartment(row);
              },
            },
          ]}
          data={rows
            // user.user.id == 401
            //   ? rows.filter((z) => z.regionName == "NORTH")
            //   : user.user.id == 400
            //     ? rows.filter((z) => z.regionName == "SOUTH")
            //     : user.user.id == 402
            //       ? rows.filter((z) => z.regionName == "WEST")
            //       : ""
          }
          // data={
          //   data == "Select the Region"
          //     ? rows
          //     : rows.filter((z) => z.regionName == data)
          // }
          options={{
            // filtering: true,
            sorting: true,
            exportButton: true,
            exportAllData: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 50, 100, 200, 500],
            search: true,
          }}
          title="Payable-NonPayable List"
        />
      </Paper>
    </>
  );
}
