import React, { useEffect, useState } from "react";
import AppSidebar from "../../../src/Components/AppSidebar";
import Navbar from "../../../src/Components/Navbar";
//material
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Typography } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { APIURL } from "../../CommomMethods/Fetch";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(18),
      height: theme.spacing(18),
    },
  },
}));

export default function CountReport() {
  const [record, setRecord] = useState([]);
  const [recordDrm, setRecordDrm] = useState([]);
  const [recordChAllocation, setRecordChAllocation] = useState([]);
  let countArray = [];
  useEffect(() => {
    axios.get(APIURL()+"get/CountList").then((res) => {
      setRecord(res.data.data);
    });
  }, []);
  useEffect(() => {
    axios.get(APIURL()+"get/CountListDrm").then((res) => {
      setRecordDrm(res.data.data);
    });
  }, []);

  useEffect(() => {
    axios
      .get(APIURL()+"get/CountListAllocation")
      .then((res) => {
        setRecordChAllocation(res.data.data);
      });
  }, []);

  const classes = useStyles();
  return (
    <>
      <AppSidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "82%" }}>
        <Navbar />
        <Grid container spacing={2} style={{ marginTop: "30px" }}>
          <Typography variant="h6">CH - Center Count</Typography>
          <Grid xs={12} sm={12} xl={12} md={12} lg={12}>
            <div className={classes.root}>
              {record &&
                record.map((z) => (
                  <Paper elevation={4}>
                    <div
                      style={{
                        margin: "20px 27px 0px 32px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {z.rname}
                    </div>
                    <br />
                    <div
                      style={{
                        margin: "0px 21px 18px 45px",
                        fontWeight: "bold",
                      }}
                    >
                      {z.chcount}
                    </div>
                  </Paper>
                ))}
            </div>
          </Grid>
          <Typography variant="h6">DRM - Center Count</Typography>
          <Grid xs={12} sm={12} xl={12} md={12} lg={12}>
            <div className={classes.root}>
              {recordDrm &&
                recordDrm.map((z) => (
                  <Paper elevation={4}>
                    <div
                      style={{
                        margin: "20px 27px 0px 32px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {z.rname}
                    </div>
                    <br />
                    <div
                      style={{
                        margin: "0px 21px 18px 45px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      {z.drmcount}
                    </div>
                  </Paper>
                ))}
            </div>
          </Grid>

          <Typography variant="h6">CENTER - Allocation Count</Typography>
          <Grid xs={12} sm={12} xl={12} md={12} lg={12}>
            <div className={classes.root}>
              {recordChAllocation &&
                recordChAllocation.map((z) => (
                  <Paper elevation={4}>
                    <div
                      style={{
                        margin: "4px 27px 0px 20px",
                        whiteSpace: "nowrap",
                        textAlign: "center",
                      }}
                    >
                      {z.name}
                      <br />
                      {z.category}
                      <br />
                      <b>{"From : "}</b>
                      {z.fg_from}
                      <br />
                      <b>{"To : "}</b>
                      {z.fg_to}
                    </div>
                    <br />
                    <div
                      style={{
                        margin: "0px 21px 18px 45px",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      {z.allocated}
                    </div>
                  </Paper>
                ))}
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
