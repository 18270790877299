import React from "react";
import Sidebar from "../../Components/Sidebar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import PayoutSummaryCard from "../../Components/PayoutSummaryCard";

export default function CenterWiseBillingReports() {
  var data = [
    { id: 1, name: "PROCESSED REPORT", count: "20" },
    { id: 2, name: "UNPROCESSED REPORT", count: "17" },
  ];
  return (
    <>
      <Sidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <div style={{
                    margin: ".4rem", textAlign: "center", padding: "7px", backgroundColor: "#810e36",
                    color: "white"
                }}>Center Wise Billing Report</div>
        <Grid container spacing={1} style={{ marginBottom: "40px" }}>
          {data &&
            data.map((opt, index) => (
              <Grid item xs={4} sm={4} lg={4} md={4} xl={4}>
                <PayoutSummaryCard data={opt} detail="chbillings" />
              </Grid>
            ))}
        </Grid>
      </Container>
      {/* <Card data={opt} */}
    </>
  );
}