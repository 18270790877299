import React, { useEffect, useState } from "react";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  FormControl,
  FormControlLabel,
  Paper,
  RadioGroup,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";

const styleSheet = makeStyles((theme) => ({
  paperStyle: { marginTop: "10px" },
  textFieldstyle: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  btnStyle: {
    right: "5px",
    marginBottom: "20px",
  },
  cancellBtnStyle: {
    marginBottom: "20px",
  },
}));

export default function EditCommercials({
  currentDepartment,
  setEditing,
  setPreview,
  updateDepartment,
}) {
  const style = styleSheet();
  //   const initialFormState = { id: null, name: "", code: "", status: "" };
  const [department, setDepartment] = useState(currentDepartment);
  const [value, setValue] = React.useState(0);
  const [check, setCheck] = useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartment({ ...department, [name]: value });
  };

  useEffect(() => {
    setValue(department.status);
  }, [currentDepartment]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          department.status = value;
          updateDepartment(department.id, department);
        }}
      >
        <Paper className={style.paperStyle}>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="doctorName"
                label="Doctor Name"
                value={department.doctorName}
                onChange={handleInputChange}
                required
                fullWidth
              />
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="natureofdoc"
                label="Doctor Type"
                value={department.natureofdoc}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="itemGroup"
                label="Item Group"
                value={department.itemGroup}
                onChange={handleInputChange}
                required
                fullWidth
              />
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="itemName"
                label="Item Name"
                value={department.itemName}
                onChange={handleInputChange}
                
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="fixedamount"
                label="Fixed Amount"
                value={department.fixedamount}
                onChange={handleInputChange}
          
                fullWidth
              />
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="fixedpercentage"
                label="Fixed %"
                value={department.fixedpercentage}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>


            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              {/* <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="Center"
                label="Center Name"
                value={department.Center}
                onChange={handleInputChange}
                required
                fullWidth
              /> */}

              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="applicable"
                label="Applicable On"
                value={department.applicable}
                onChange={handleInputChange}
                required
                fullWidth
              />

            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}></Grid>
            <Grid item xs={4} sm={4} md={5} lg={5} xl={5}></Grid>
            <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={style.btnStyle}
              >
                Update
              </Button>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                className={style.cancellBtnStyle}
                onClick={() => {
                  setEditing(false);
                  setPreview(true);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </>
  );
}
