import React, { useEffect, useState } from "react";
// material-table component
import MaterialTable from "material-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { pink } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

export default function ActivityAssignmentList({ tableData, ColumnHeadArray, editRow }) {
  console.log(tableData, ColumnHeadArray,"rows")
  const tables = myTableStyle();
  
  return (
    <>
      <Paper className={tables.root} style={{ border: "solid", marginTop: "10px" }}>
        <MaterialTable
          columns={ColumnHeadArray.map((elem) => {
            let obj = {...elem, render: (row) => (
              row[elem.field] == 'Yes' ? 
              // <span className="badge badge-success" style={{fontSize:"inherit"}}>Y</span>
              // <span className="badge badge-success">Yes</span>
               <CheckIcon style={{color:"green"}}/> 
            : elem.field !== "Name" && row[elem.field] != 'Yes' ? 
              // <span className="badge badge-danger" style={{fontSize:"inherit"}}>N</span>
              // <span className="badge badge-danger">No</span>
               <CloseIcon style={{color:"red"}}/> 
            :row[elem.field]),}
            return obj
          })}
          data={tableData}
          options={{
            // filtering: true,
            sorting: true,
            exportButton: true,
            paging: false,
            search: true,
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit",
              iconProps: { style: { fontSize: "24px", color: pink[600] } },
              onClick: (event, row) => editRow(row),
            },
          ]}
          ce
          title="Role-based Activity Mapping"
        />
      </Paper>
    </>
  );
}
