import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import icon from "../images/new.png";
import { listusers } from "../actions/userActions";
// import Profile from "../Setting/ProfilePage";
//mui
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import profileImg from "../images/profile.jpg";
import {DateHelper} from "../CommomMethods/DateHelper"
import {APIURL } from "../CommomMethods/Fetch"
function Profiles({ reqType, num }) {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;
  const onboardId = useSelector((state) => state.onboardId);
  const { oboard } = onboardId;
  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);
  console.log(user,"000")
  var dob=users && users[0] && users[0].dob ? users[0].dob.substring(0, 10) : ''

  console.log(DateHelper(dob),"jjj")
  return (
      <div className="card-footer align-items-center">
        <div className="row">
        <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Paper elevation={24} style={{ padding: "20px", border: "1px solid", height: "528px" }}>
                    {users && users.map((ele) => (
                    <img className="img-responsive" src={APIURL() +"documents/download/" + ele.profile_picture} style={{ borderRadius: "49%", marginRight: "1rem", width: "500px" }} />
                  ))}
                  <Typography style={{ textAlign: "center", marginTop: "4px" }}>
                    {oboard.doctorCommercial &&
                      oboard.doctorCommercial[0].designationlist}
                  </Typography>
                  <Typography variant="h6" style={{ textAlign: "center" }}>
                    {user && user.user && user.user.username}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ textAlign: "center" }}
                  >
                    {user && user.user && user.user.role_name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ textAlign: "center" }}
                  >
                    Centers Access :
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ textAlign: "center" }}
                  >
                    {user.user.usermap.map((item) => item.centername).join(', ')}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", border: "1px solid", padding: "20px",  maxHeight: "260px", overflow: "scroll" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        CONTACT
                      </h5>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "left" }}
                      >
                        <b>Email :</b>{" "}
                        {user && user.user && user.user.email}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "left" }}
                      >
                        <b>Phone :</b>{" "}
                        {user && user.user && user.user.mobile}
                      </Typography>{" "}
                      {/* <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "left" }}
                      >
                        <b>Permanent Addr :</b>{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].PermanentAddress}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "left" }}
                      >
                        <b>Present Addr :</b>{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].Address}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "left" }}
                      >
                        <b>pin code :</b>{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].PinCode}
                      </Typography> */}
                    </Paper>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", border: "1px solid", padding: "20px" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        PERSONAL DETAIL
                      </h5>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "left" }}
                      >
                        <b>Aadhaar :</b>{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].aadhaarno}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "left" }}
                      >
                        <b>Pan Card :</b>{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].pancardno}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "left" }}
                      >
                        <b>DOB :</b>{" "}
                        {DateHelper(dob)}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "left" }}
                      >
                        <b>DOJ :</b>{" "}
                        {DateHelper(oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].doj.substring(0, 10))}
                      </Typography>
                    </Paper>
                  </Grid> */}

                  {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", border: "1px solid", padding: "20px" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        BANK DETAIL
                      </h5>
                      <Typography
                        variant="subtitle2"
                        style={{ textAlign: "left" }}
                      ></Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "left" }}
                      >
                        <b>BANk name :</b>
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].BankName}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "left" }}
                      >
                        <b>ifsc :</b>
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].ifsccode}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "left" }}
                      >
                        <b>account no :</b>
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].bankaccountno}
                      </Typography>
                    </Paper>
                  </Grid> */}
                  {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", border: "1px solid", padding: "10px" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        OTHER DETAILS
                      </h5>

                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "left" }}
                      >
                        <b>Indemnity Exp :</b>
                        {DateHelper(oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].indemnityexpiry.substring(0, 10))}
                      </Typography>
                    </Paper>
                  </Grid> */}
                </Grid>
              </Grid>
         </Grid>
        </div>
      </div>
  );
}

export default Profiles;
