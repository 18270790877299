import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import PayoutSummaryCard from "../../Components/PayoutSummaryCard";
import Sidebar from "../../Components/Sidebar";
import { toast } from "react-toastify";

export default function CenterWisePayout() {
  // var data = [
  //   { id: 1, name: "CENTER N1", count: "20" },
  //   { id: 2, name: "CENTER N2", count: "17" },
  //   { id: 3, name: "CENTER N3", count: "29" },
  //   { id: 4, name: "CENTER N4", count: "40" },
  //   { id: 5, name: "CENTER N5", count: "12" },
  //   { id: 6, name: "CENTER N6", count: "8" },
  // ];
  const [rows, setRows] = useState([]);
  const [reload, setReload] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  console.log(user, "****(")
  useEffect(() => {
    axios
      .get(APIURL() + "billings/Centerwise")
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows, "AllExcelData");
        setRows(rows.data);
      })
      .catch((err)=>{
        toast.error(err.message);
      });
  }, [reload]);
  console.log(rows,"123");
  return (
    <>
      <Sidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <div style={{
                    margin: ".4rem", textAlign: "center", padding: "7px", backgroundColor: "#810e36",
                    color: "white"
                }}>Center wise Payout</div>
        <Grid container spacing={1} style={{ marginBottom: "40px" }}>
          {rows &&
            rows.map((opt, index) => (
              <Grid item xs={4} sm={4} lg={4} md={4} xl={4}>
                <PayoutSummaryCard data={opt} detail="chcenter" />
              </Grid>
            ))}
        </Grid>
      </Container>
      {/* <Card data={opt} */}
    </>
  );
}