import axios from "axios";

export const postRecord = (url, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};
export const putRecord = (url, id, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(url + id, data )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};
export const deleteRecord = (url, id) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(url + id )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};
