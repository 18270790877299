import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Sidebar from "../../Components/Sidebar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import PayoutSummaryCard from "../../Components/PayoutSummaryCard";
import { APIURL } from "../../CommomMethods/Fetch";
import Navbar from "../../Components/Navbar";

export default function DoctorWisePayout() {
  var data = [
    { id: 1, name: "Preety Agarwal", count: "20" },
    { id: 2, name: "Rahul Kumar", count: "17" },
    { id: 3, name: "Deepti Pradhan", count: "29" },
    { id: 4, name: "Sanju Gupta", count: "40" },
  ];
  const [rows, setRows] = useState([]);
  const [reload, setReload] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  console.log(user, "****(")
  useEffect(() => {
    axios
      .get(APIURL() + "billings/BillingDetRecords")
      .then((res) => res.data)
      .then((rows) => {
        console.log(rows, "AllExcelData");
        setRows(rows.data);
      });
  }, [reload]);
  console.log(rows,"123");
  return (
    <>
      <Sidebar />

      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "82%" }}>
      <Navbar />
        <div style={{
                    margin: ".4rem", textAlign: "center", padding: "7px", backgroundColor: "#810e36",
                    color: "white"
                }}>Doctor wise Payout</div>
        <Grid container spacing={1} style={{ marginBottom: "40px" }}>
          {rows &&
            rows.map((opt, index) => (
              <Grid item xs={4} sm={4} lg={4} md={4} xl={4}>
                <PayoutSummaryCard data={opt} detail="chdoctor" />
              </Grid>
            ))}
        </Grid>
      </Container>
    </>
  );
}