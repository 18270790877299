import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import "jspdf-autotable";
import _filefy from "filefy";
import moment from "moment";
import { calculateOpAgreement } from '../BusinessLogic/opCalc';

const myTableStyle = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "20%",
        marginTop: theme.spacing(3),
        "& td": {
            fontFamili: "Roboto !important",
        },
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflow: "auto",
        marginBottom: "50px",
    },
}));

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "fixed",
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AgreementComponentOp({ opcomp, history }) {
    const classes = useStyles();

    const userList = useSelector((state) => state.userList);
    const { users } = userList;
    console.log(opcomp,"opcomp")
    return (
        <div className="card custom-card" >

            <div className="card-body">


                {opcomp && opcomp.map((ele, key) => (
                    <>
                        <h6 style={{ textAlign: "center" }}><strong><u>OUT PATIENT FOR {(ele.center).toUpperCase()}</u></strong></h6>
                        <p>
                            <strong>A.1-{key + 1}. For Out-Patient {ele.itemGroup.split("-")[1] === undefined ? ele.itemGroup.split("-")[0] : ele.itemGroup.split("-")[1]}:</strong> For Out-Patient {ele.itemGroup.split("-")[1] === undefined ? ele.itemGroup.split("-")[0] : ele.itemGroup.split("-")[1]}, {ele.itemName ? ele.itemName : ""} provided by the Consultant
                            under this agreement, the Company shall pay {""}
                            {calculateOpAgreement.opAgreementCalculation(ele)}
                            {" "}collected from the patients/ customers attended by the Consultant.
                </p>
                    </>
                ))}

            </div>
        </div>
    );
}

export default AgreementComponentOp;
