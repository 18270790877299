import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
//ui components
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import ConfirmDialog from "../../Components/ConfirmDialog";
//mui components
import CssBaseline from "@material-ui/core/CssBaseline";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Button, Paper, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import Skeleton from "@material-ui/lab/Skeleton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
//import component
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { onboardById } from "../../actions/onboardActions";
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import { payoutCalculation } from "../../Pages/Calculations/payoutCalc";
import { toast } from "react-toastify";
import { dateFormatter
  } from "../../actions/createPositionActions";

const styleSheet = makeStyles((theme) => ({
    iconButtonStyle: {
        float: "right",
    },
    marginforMaster: {
        marginTop: "30px",
    },
}));

const backDropStyle = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
const stylesmodal = makeStyles({
    root: {
        marginTop: "18px",
    },
});
export default function ChEditDiscount() {
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subtitle: "",
    });
    const styleClasses = stylesmodal();
    const style = styleSheet();
    //   const location = useLocation();
    //   console.log(location, "location from anothere state");
    const classes = styleSheet();
    const dispatch = useDispatch();
    const [backDropOpen, setBackDropOpen] = useState(false);
    const [billdate, setbilldate] = React.useState(new Date());
    const backClasses = backDropStyle();
    const [rows, setRows] = useState([]);
    const [name, setName] = useState([]);
    const [center, setCenter] = useState("Select Center");
    const [discCat, setdiscCat] = useState("Select Discount Category");
    const [region, setRegion] = useState("Select Region");
    const [month, setMonth] = useState("Select Month");
    const [year, setYear] = useState("Select Year");
    const [loading, setLoading] = useState(false);
    const [changeRowValue, setChangeRowValue] = useState([]);
    const [rowSelection, setRowSelection] = useState(false);
    const [openr, setOpenr] = React.useState(false);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [reload, setReload] = useState(false);
    const [opTotPayout, setOpTotPayout] = useState([]);
    const [initCalOP, setInitCalOP] = useState([]);
    const [data, setData] = useState("Select the value");
    const [splitDialog, setSplitDialog] = useState(false);
    const [spitRecord, setSplitRecord] = useState({});
    const [splitNewRec, setSplitNewRec] = useState([]);
    const [oboard, setOboard] = useState([]);
    const [oboardVal, setOboardVal] = useState([]);
    const [dataVal, setDataVal] = useState([]);
    const [mpi, setMpi] = useState({ MPI: "" });
    const [loadEachData, setLoadEachdata] = useState(false);
    const [centerName, setCenterName] = useState([]);

    const userLogin = useSelector((state) => state.userLogin);
    const { user } = userLogin;

    console.log("user", user);
    const [rowDetail, setRowDetail] = useState({
        DiscountCategory: "",
        DiscountType: "",
        DiscountValue: "",
        Center: "",
    });
    const [addRow, setAddRow] = useState({
        MPI: "",
        PatientName: "",
        BillNo: "",
        BillDate: "",
        BillAmt: "",
        DiscountCategory: "",
        DiscountType: "",
        DiscountValue: "",
        comment: "",
        category: "",
        user: "",
        Center: "",
        region: "",
        year: "",
        month: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setRowDetail({ ...rowDetail, [name]: value });
    };
    const handleAddChange = (event) => {
        const { name, value } = event.target;
        setAddRow({ ...addRow, [name]: value });
    };
    const handleInputMpi = (event) => {
        const { name, value } = event.target;
        setMpi({ ...mpi, [name]: value });
    };
    const handlePayoutEdit = () => {
        setReload(true);
        const obj = { rowDetail: rowDetail, changeRowValue: changeRowValue };
        axios.put(APIURL() + "billings/updateDiscount", obj).then((res) => {
            setReload(false);
            setOpenr(false);
        })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    const handlePayoutAdd = () => {
        setReload(true);
        addRow.month = month;
        addRow.region = region;
        addRow.Center = center;
        addRow.DiscountCategory = discCat;
        axios.post(APIURL() + "billings/adddiscreate", addRow).then((res) => {
            setReload(false);
            setOpenAdd(false);
        });
    };
    useEffect(() => {
        setLoading(false);
        axios
            .get(APIURL() + "billings/findAllBillingDetProcess")
            .then((res) => res.data)
            .then((rows) => {
                // var k = rows.data.filter(z => z.ItemGroupName === "Other Charges")
                setRows(rows.data);
                setLoading(true);
            })
            .catch((err)=>{
                toast.error(err.message);
              });
    }, []);

    useEffect(() => {
        setLoading(false);
        axios
            .get(APIURL() + "onboard/name")
            .then((res) => res.data)
            .then((rows) => {
                setName(rows);
                setLoading(true);
            })
            .catch((err)=>{
                toast.error(err.message);
              });
    }, []);
    var array = [];
    var k =
        name &&
        name.data &&
        name.data.map((ele) => {
            array.push(ele.doctorName);
        });
    console.log(array, "0012");
    useEffect(() => {
        setLoading(false);
        axios
            .get(APIURL() + "onboard/name/" + array)
            .then((res) => res.data)
            .then((rows) => {
                setOboard(rows);
                setLoading(true);
            })
            .catch((err)=>{
                toast.error(err.message);
              });
    }, []);

    console.log(oboard, "*!!!");
    var doctorList = [
        { id: 0, name: "Dr. Preety Aggarwal" },
        { id: 1, name: "Dr. Shweta Bansal Wazir" },
        { id: 2, name: "Dr. Manish Balde" },
        { id: 3, name: "Dr. Witty Raina" },
        { id: 4, name: "Dr. Binal Shah" },
        { id: 5, name: "Dr. Beena Muktesh" },
        { id: 6, name: "Dr. Shalu Gupta" },
        { id: 7, name: "Dr. Bhavna Dewan" },
        { id: 8, name: "Dr. Radhika Sheth" },
        { id: 9, name: "Dr. Hena Bhagde" },
    ];
    function selectedDropdownvalue(e) {
        const { name, value } = e.target;
        setData(value);
        setInitCalOP([]);
    }
    function selectedMonth(e) {
        const { name, value } = e.target;
        setMonth(value);
    }

    function selectedYear(e) {
        const { name, value } = e.target;
        setYear(value);
    }

    function selectedCenter(e) {
        const { name, value } = e.target;
        setCenter(value);
    }

    function selectedDiscCat(e) {
        const { name, value } = e.target;
        setdiscCat(value);
    }
    function selectedRegion(e) {
        const { name, value } = e.target;
        setRegion(value);
    }

    useEffect(() => {
        setInitCalOP([]);
        setOpTotPayout([]);
    }, [data]);

    // useEffect(() => {
    //   dispatch(onboardById(364));
    // }, [reload]);
    const [valRec, setValRec] = useState([]);
    const [loadVal, setLoadVal] = useState(false);
    var sumArray = [];
    var Val = [];
    var sum = 0;

    function generatePay() {
        setBackDropOpen(true);
        axios.get(APIURL() + 'billings/Payout/generateOpPay')
            .then((res) => res.data)
            .then((rows) => {
                console.log(rows.data, "0002");
                let value = rows.data.forEach(ele => {
                    ele.ConductedByFinal = "Dr." + ele.ConductedByFinal;
                })
                setDataVal(value);
                // setLoading(true);
                setBackDropOpen(false);
            })
            .catch((err)=>{
                toast.error(err.message);
              });
    }
    console.log(mpi, "!!!!!@@################");
    function getRecord() {
        setLoading(false);
        let url = APIURL() + `billings/loadDiscountsrep/${mpi.MPI}/${center}/${year}/${month}`;
        console.log(url, "url");
        axios
            .get(url)
            .then((res) => res.data)
            .then((rows) => {
                // console.log(rows,"###");
                var k = rows.filter(z => (z.region == "SOUTH"));
                setOboardVal(k);
                setLoading(true);
            })
            .catch((err)=>{
                toast.error(err.message);
              });
    }
    useEffect(() => {
        setInitCalOP(valRec);
    }, [loadVal, valRec]);

    const handlerClose = () => {
        setOpenr(false);
    };

    const handlerAddClose = () => {
        setOpenAdd(false);
    };
    const onSplitRow = (row) => {
        setSplitNewRec([]);
        setRowDetail({ DiscountCategory: "", DiscountType: "", DiscountValue: "" });
        setSplitRecord(row);
    };
    const handleToDate = (a) => {
        setbilldate(a);
        addRow.BillDate = a;
    };
    const addRowData = (row) => {
        let rec = {};
        spitRecord.DiscountCategory = rowDetail.DiscountCategory;
        spitRecord.DiscountType = rowDetail.DiscountType;
        spitRecord.DiscountValue = rowDetail.DiscountValue;
        rec = {
            ...spitRecord,
            DiscountCategory: rowDetail.DiscountCategory,
            DiscountType: rowDetail.DiscountType,
            DiscountValue: rowDetail.DiscountValue,
        };
        let newData = [...splitNewRec];
        newData.push(rec);
        newData.forEach((i, index) => {
            console.log(i, index, "record 21");
        });
        rec = {};
        setSplitNewRec(newData);
        setRowDetail({ DiscountCategory: "", DiscountType: "", DiscountValue: "" });
    };
    console.log(splitNewRec, "splitNewRec");
    const changeSplitRecord = () => {
        setLoading(true);
        axios.post(APIURL() + "billings/addDisc", splitNewRec)
            .then(function (response) { setLoading(false); })
            .catch((err)=>{
                toast.error(err.message);
              });
        //createSplidRecord
    };
    console.log(dataVal.length, "dataVal");
    const deleteDepartment = (row) => {
        setData(false);
        setLoading(true);
        console.log(row, "12333");
        var k = window.confirm("Are you sure want to delete this record ?");
        if (k) {
            axios.delete(APIURL() + "billings/deletedisc/" + row.id).then((res) => {
                setData(true);
                setLoading(false);
            })
            .catch((err)=>{
                toast.error(err.message);
              });
        }
    };
    useEffect(() => {
        // axios.get(APIURL() + "usermapcenters/centermaps/" + user.user.id)
        axios.get(APIURL() + "usermapcenters/centermaps/" + 400)
            .then((res) => res.data)
            .then((rows) => {
                console.log(rows, "2323");
                setCenterName(rows.data);
            })
            .catch((err)=>{
                toast.error(err.message);
              });
    }, []);

    const deleteRow = (data) => {
        setLoadEachdata(false);
        axios
            .post(APIURL() + "billings/discardRecord", data)
            .then(function (response) {
                setLoadEachdata(true);
            })
            .catch((err)=>{
                toast.error(err.message);
              });
    };

    const deleteMultipleRows = () => {
        setLoadEachdata(false);
        axios
            .post(APIURL() + "billings/discardRecords", changeRowValue)
            .then(function (response) {
                setLoadEachdata(true);
            })
            .catch((err)=>{
                toast.error(err.message);
              });
    };
    return (
        <>
            {" "}
            <Sidebar />
            <CssBaseline />
            <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
                <Navbar />
                <Typography
                    variant="subtitle1"
                    style={{ margin: "1rem", textAlign: "center" }}
                >
                    Edit Discount
                </Typography>

                {!loading ? (
                    <Skeleton animation="wave" variant="rect" width={950} height={400} />
                ) : (
                    <div style={{ whiteSpace: "nowrap" }}>
                        <Paper
                            style={{ marginBottom: "10px", border: "1px solid #810e36" }}
                        >
                            <div style={{ padding: "8px" }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <FormControl variant="outlined" style={{ width: "100%" }}>
                                            <TextField
                                                id="MPI"
                                                label="MPI"
                                                variant="outlined"
                                                fullWidth
                                                name="MPI"
                                                value={mpi.MPI}
                                                onChange={handleInputMpi}
                                                style={{ marginBottom: "20px" }}
                                                autoComplete="off"
                                            />
                                        </FormControl></Grid>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <FormControl variant="outlined" style={{ width: "100%" }}>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={center}
                                                onChange={selectedCenter}
                                                label=" Filter By Center"
                                                fullWidth
                                            >
                                                <MenuItem value="Select the Center">
                                                    Filter By Center
                                                </MenuItem>
                                                {centerName.length > 0 && centerName.map((ele) => {
                                                    return (<MenuItem value={ele.code}>{ele.name}</MenuItem>)
                                                })}

                                                {/* <MenuItem value="Select the Center">
                                                    Filter By Center
                                                   </MenuItem>
                                                <MenuItem value="NORTH">NORTH</MenuItem>
                                                <MenuItem value="SOUTH">SOUTH</MenuItem>
                                                <MenuItem value="WEST">WEST</MenuItem> */}

                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <FormControl variant="outlined" style={{ width: "100%" }}>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={year}
                                                onChange={selectedYear}
                                                label=" Filter By Year"
                                                fullWidth
                                            >
                                                <MenuItem value="Select the Year">
                                                    Filter By Year
                                                </MenuItem>
                                                <MenuItem value="2021">2021</MenuItem>
                                                <MenuItem value="2022">2022</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <FormControl variant="outlined" style={{ width: "100%" }}>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                value={month}
                                                onChange={selectedMonth}
                                                label=" Filter By Month"
                                                fullWidth
                                            >
                                                <MenuItem value="Select the Month">
                                                    Filter By Month
                                                </MenuItem>
                                                <MenuItem value="JANUARY">JANUARY</MenuItem>
                                                <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                                                <MenuItem value="MARCH">MARCH</MenuItem>
                                                <MenuItem value="APRIL">APRIL</MenuItem>
                                                <MenuItem value="MAY">MAY</MenuItem>
                                                <MenuItem value="JUNE">JUNE</MenuItem>
                                                <MenuItem value="JULY">JULY</MenuItem>
                                                <MenuItem value="AUGUST">AUGUST</MenuItem>
                                                <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                                                <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                                                <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                                                <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <Button
                                            variant="contained"
                                            onClick={getRecord}
                                            fullWidth
                                            style={{
                                                height: "100%",
                                                backgroundColor: "#810e36",
                                                color: "white",
                                            }}
                                        >
                                            Load Data{" "}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            // className={style.btnStyle}
                                            onClick={(evt, data) => setOpenAdd(true)}
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                </Grid>



                            </div>
                        </Paper>
                        <MaterialTable
                            columns={[
                                {
                                    title: "Split",
                                    field: "Action",
                                    filtering: false,
                                    render: (row) => (
                                        <Tooltip title="Split Record">
                                            <IconButton>
                                                <CallSplitIcon
                                                    style={{
                                                        transform: "rotate(90deg)",
                                                        color: "#810e36",
                                                    }}
                                                    onClick={() => {
                                                        setSplitDialog(true);
                                                        onSplitRow(row);
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    ),
                                },
                                {
                                    title: "Discard",
                                    field: "Action",
                                    filtering: false,
                                    render: (row) => (
                                        <Tooltip title="Discard Record">
                                            <IconButton>
                                                <RemoveCircleOutlineIcon
                                                    style={{
                                                        color: "#810e36",
                                                    }}
                                                    onClick={() => deleteDepartment(row)}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    ),
                                },
                                { title: "MPI", field: "MPI" },
                                { title: "Patient Name", field: "PatientName" },
                                {
                                    title: "Bill No",
                                    field: "BillNo",
                                    render: (row) => (
                                        <div
                                            style={{ cursor: "pointer", color: "red" }}
                                            onClick={() => alert(row.BillNo)}
                                        >
                                            {row.BillNo}
                                        </div>
                                    ),
                                },
                                { title: "Bill Date", field: "BillDate", render: (row) => (<div>{dateFormatter(row.BillDate)}</div>) },
                                { title: "Bill Amt", field: "BillAmt" },
                                { title: "Discount Category", field: "DiscountCategory" },
                                { title: "Discount Type", field: "DiscountType" },
                                { title: "Discount Value", field: "DiscountValue" },
                                { title: "category", field: "category" },
                                { title: "Location", field: "Center" },
                            ]}
                            actions={[
                                {
                                    tooltip: "Edit Records",
                                    icon: "edit",
                                    iconProps: { style: { color: "#810e36" } },
                                    onClick: (evt, data) => setOpenr(true),
                                },
                            ]}
                            data={oboardVal}
                            options={{
                                // filtering: true,
                                selection: true,
                                sorting: true,
                                exportButton: true,
                                pageSize: 5,
                                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                search: true,
                            }}
                            onSelectionChange={(rows) => {
                                setRowSelection(true);
                                setChangeRowValue(rows);
                            }}
                            title="Discount Details"
                        />
                    </div>
                )}
            </Container>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                onClose={handlerClose}
                aria-labelledby="customized-dialog-title"
                open={openr}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handlerClose}
                    style={{ textAlign: "center" }}
                >
                    Edit Discount
                </DialogTitle>
                <DialogContent dividers>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                        <label>
                            <b>{changeRowValue && changeRowValue[0] && changeRowValue[0].MPI}</b>
                        </label>
                    </FormControl>
                    <label>
                        <b>Discount Category</b>
                    </label>
                    <TextField
                        id="Payout"
                        label="DiscountCategory"
                        variant="outlined"
                        fullWidth
                        name="Discount Category"
                        value={rowDetail.DiscountCategory}
                        onChange={handleInputChange}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                    />

                    <label>
                        <b>Discount Type</b>
                    </label>
                    <TextField
                        id="Payout"
                        label="DiscountType"
                        variant="outlined"
                        fullWidth
                        name="Discount Type"
                        value={rowDetail.DiscountType}
                        onChange={handleInputChange}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                    />

                    <label>
                        <b>Discount Value</b>
                    </label>
                    <TextField
                        id="Payout"
                        label="DiscountValue"
                        variant="outlined"
                        fullWidth
                        name="Discount Value"
                        value={rowDetail.DiscountValue}
                        onChange={handleInputChange}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                    />

                    <label>
                        <b>Location</b>
                    </label>
                    <TextField
                        id="Payout"
                        label="Location"
                        variant="outlined"
                        fullWidth
                        name="Center"
                        value={rowDetail.Center}
                        onChange={handleInputChange}
                        style={{ marginBottom: "20px" }}
                        autoComplete="off"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={handlePayoutEdit}
                        color="primary"
                        variant="contained"
                    >
                        Update
                    </Button>
                    <Button
                        autoFocus
                        onClick={handlerClose}
                        color="secondary"
                        variant="contained"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* ADD DISC */}
            <Dialog
                fullWidth={true}
                maxWidth="md"
                onClose={handlerAddClose}
                aria-labelledby="customized-dialog-title"
                open={openAdd}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handlerAddClose}
                    style={{ textAlign: "center" }}
                >
                    ADD Discount
                </DialogTitle>
                <DialogContent dividers>
                    <Paper className={style.paperStyle}>
                        <Grid container spacing={1}>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <label>
                                    <b>MPI</b>
                                </label>
                                <TextField
                                    id="MPI"
                                    label="MPI"
                                    variant="outlined"
                                    fullWidth
                                    name="MPI"
                                    value={addRow.MPI}
                                    onChange={handleAddChange}
                                    style={{ marginBottom: "20px" }}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <label>
                                    <b>Patient Name</b>
                                </label>
                                <TextField
                                    id="PatientName"
                                    label="Patient Name"
                                    variant="outlined"
                                    fullWidth
                                    name="PatientName"
                                    value={addRow.PatientName}
                                    onChange={handleAddChange}
                                    style={{ marginBottom: "20px" }}
                                    autoComplete="off"
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <label>
                                    <b>Bill No</b>
                                </label>
                                <TextField
                                    id="BillNo"
                                    label="Bill No"
                                    variant="outlined"
                                    fullWidth
                                    name="BillNo"
                                    value={addRow.BillNo}
                                    onChange={handleAddChange}
                                    style={{ marginBottom: "20px" }}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        // margin="normal"
                                        id="date-picker-dialog"
                                        autoOk
                                        label="Bill Date"
                                        // disablePast
                                        value={billdate}
                                        className={styleClasses.root}
                                        onChange={handleToDate}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        fullWidth
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <label>
                                    <b>Bill Amount</b>
                                </label>
                                <TextField
                                    id="BillAmt"
                                    label="Bill Amt"
                                    variant="outlined"
                                    fullWidth
                                    name="BillAmt"
                                    value={addRow.BillAmt}
                                    onChange={handleAddChange}
                                    style={{ marginBottom: "20px" }}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <label>
                                    <b>Discount Category</b>
                                </label>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={discCat}
                                    onChange={selectedDiscCat}
                                    label=" Filter By Category"
                                    fullWidth
                                >
                                    <MenuItem value="Select the Category">
                                        Filter By Category
                                    </MenuItem>

                                    <MenuItem value="Contra">Contra</MenuItem>
                                    <MenuItem value="Management">Management</MenuItem>
                                    <MenuItem value="Proff">Proff</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <label>
                                    <b>Discount Type</b>
                                </label>
                                <TextField
                                    id="Payout"
                                    label="Discount Type"
                                    variant="outlined"
                                    fullWidth
                                    name="DiscountType"
                                    value={addRow.DiscountType}
                                    onChange={handleAddChange}
                                    style={{ marginBottom: "20px" }}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <label>
                                    <b>Discount Value</b>
                                </label>
                                <TextField
                                    id="Payout"
                                    label="Discount Value"
                                    variant="outlined"
                                    fullWidth
                                    name="DiscountValue"
                                    value={addRow.DiscountValue}
                                    onChange={handleAddChange}
                                    style={{ marginBottom: "20px" }}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <label>
                                    <b>comment</b>
                                </label>
                                <TextField
                                    id="comment"
                                    label="comment"
                                    variant="outlined"
                                    fullWidth
                                    name="comment"
                                    value={addRow.comment}
                                    onChange={handleAddChange}
                                    style={{ marginBottom: "20px" }}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <label>
                                    <b>category</b>
                                </label>
                                <TextField
                                    id="category"
                                    label="category"
                                    variant="outlined"
                                    fullWidth
                                    name="category"
                                    value={addRow.category}
                                    onChange={handleAddChange}
                                    style={{ marginBottom: "20px" }}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <label>
                                    <b>user</b>
                                </label>
                                <TextField
                                    id="user"
                                    label="user"
                                    variant="outlined"
                                    fullWidth
                                    name="user"
                                    value={addRow.user}
                                    onChange={handleAddChange}
                                    style={{ marginBottom: "20px" }}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <label>
                                    <b>year</b>
                                </label>
                                <TextField
                                    id="year"
                                    label="year"
                                    variant="outlined"
                                    fullWidth
                                    name="year"
                                    value={addRow.year}
                                    onChange={handleAddChange}
                                    style={{ marginBottom: "20px" }}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                    <label>
                                        <b> Select Center</b>
                                    </label>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={center}
                                        onChange={selectedCenter}
                                        label=" Filter By Center"
                                        fullWidth
                                    >
                                        <MenuItem value="Select the Center">
                                            Filter By Center
                                        </MenuItem>
                                        {centerName.length > 0 && centerName.map((ele) => {
                                            return (<MenuItem value={ele.code}>{ele.name}</MenuItem>)
                                        })}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                    <label>
                                        <b>Select Region</b>
                                    </label>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={region}
                                        onChange={selectedRegion}
                                        label=" Filter By Region"
                                        fullWidth
                                    >
                                        <MenuItem value="Select the Region">
                                            Filter By Region
                                        </MenuItem>
                                        <MenuItem value="NORTH">NORTH</MenuItem>
                                        <MenuItem value="SOUTH">SOUTH</MenuItem>
                                        <MenuItem value="WEST">WEST</MenuItem>

                                    </Select>
                                </FormControl>

                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormControl variant="outlined" style={{ width: "100%" }}>
                                    <label>
                                        <b>Select Month</b>
                                    </label>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={month}
                                        onChange={selectedMonth}
                                        label=" Filter By Month"
                                        fullWidth
                                    >
                                        <MenuItem value="Select the Month">
                                            Filter By Month
                                        </MenuItem>
                                        <MenuItem value="JANUARY">JANUARY</MenuItem>
                                        <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                                        <MenuItem value="MARCH">MARCH</MenuItem>
                                        <MenuItem value="APRIL">APRIL</MenuItem>
                                        <MenuItem value="MAY">MAY</MenuItem>
                                        <MenuItem value="JUNE">JUNE</MenuItem>
                                        <MenuItem value="JULY">JULY</MenuItem>
                                        <MenuItem value="AUGUST">AUGUST</MenuItem>
                                        <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                                        <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                                        <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                                        <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                                    </Select>
                                </FormControl>

                            </Grid>
                        </Grid>
                    </Paper>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={handlePayoutAdd}
                        color="primary"
                        variant="contained"
                    >
                        ADD
                    </Button>
                    <Button
                        autoFocus
                        onClick={handlerAddClose}
                        color="secondary"
                        variant="contained"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            //splitDialog
            <Dialog
                fullWidth={true}
                maxWidth="md"
                onClose={() => {
                    setSplitDialog(false);
                }}
                aria-labelledby="customized-dialog-title"
                open={splitDialog}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={handlerClose}
                    style={{ textAlign: "center" }}
                >
                    {spitRecord.ConductedBy}
                </DialogTitle>
                <Grid container>
                    <Grid item xs={12} xl={6} md={6} lg={6} sm={12}>
                        <DialogContent dividers>
                            <Typography>MPI: {spitRecord.MPI}</Typography>
                            <Typography>BillDate: {spitRecord.BillDate}</Typography>
                            <label>
                                <b>Discount Category</b>
                            </label>
                            <TextField
                                id="Payout"
                                label="DiscountCategory"
                                variant="outlined"
                                fullWidth
                                name="DiscountCategory"
                                value={rowDetail.DiscountCategory}
                                onChange={handleInputChange}
                                style={{ marginBottom: "20px" }}
                                autoComplete="off"
                            />
                            <label>
                                <b>Discount Type</b>
                            </label>
                            <TextField
                                id="DiscountType"
                                label="Discount Type"
                                variant="outlined"
                                fullWidth
                                name="DiscountType"
                                value={rowDetail.DiscountType}
                                onChange={handleInputChange}
                                style={{ marginBottom: "20px" }}
                                autoComplete="off"
                            />
                            <label>
                                <b>Discount Value</b>
                            </label>
                            <TextField
                                id="Payout"
                                label="Discount Value"
                                variant="outlined"
                                fullWidth
                                name="DiscountValue"
                                value={rowDetail.DiscountValue}
                                onChange={handleInputChange}
                                style={{ marginBottom: "20px" }}
                                autoComplete="off"
                            />
                        </DialogContent>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        xl={6}
                        md={6}
                        lg={6}
                        sm={12}
                        style={{ height: "300px", overflowY: "auto" }}
                    >
                        <MaterialTable
                            columns={[
                                { title: "MPI", field: "MPI" },
                                { title: "Patient Name", field: "PatientName" },
                                {
                                    title: "Bill No",
                                    field: "BillNo",
                                    render: (row) => (
                                        <div
                                            style={{ cursor: "pointer", color: "red" }}
                                            onClick={() => alert(row.BillNo)}
                                        >
                                            {row.BillNo}
                                        </div>
                                    ),
                                },
                                { title: "Bill Date", field: "BillDate", render: (row) => (<div>{dateFormatter(row.BillDate)}</div>) },
                                { title: "Bill Amt", field: "BillAmt" },
                                { title: "Discount Category", field: "DiscountCategory" },
                                { title: "Discount Type", field: "DiscountType" },
                                { title: "Discount Value", field: "DiscountValue" },
                                { title: "category", field: "category" },
                                { title: "Location", field: "Center" },
                            ]}
                            data={splitNewRec}
                            title="Split Rows"
                        />
                    </Grid>
                </Grid>

                <Button
                    color="secondary"
                    onClick={() => {
                        addRowData(spitRecord);
                    }}
                >
                    Add
                </Button>
                <DialogActions>
                    {splitNewRec && splitNewRec.length > 0 ? (
                        <Button
                            autoFocus
                            onClick={() => {
                                changeSplitRecord();
                            }}
                            color="secondary"
                            variant="contained"
                        >
                            Submit
                        </Button>
                    ) : (
                        ""
                    )}

                    <Button
                        autoFocus
                        onClick={() => {
                            setSplitDialog(false);
                        }}
                        color="secondary"
                        variant="contained"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop
                className={backClasses.backdrop}
                open={backDropOpen}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    );
}
