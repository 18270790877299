export const POSITION_CREATE_REQUEST ='POSITION_CREATE_REQUEST'
export const POSITION_CREATE_SUCCESS ='POSITION_CREATE_SUCCESS'
export const POSITION_CREATE_FAIL ='POSITION_CREATE_FAIL'
export const POSITION_CREATE_RESET ='POSITION_CREATE_RESET'

export const FORM_REQUEST ='FORM_REQUEST'
export const FORM_SUCCESS ='FORM_SUCCESS'
export const FORM_FAIL ='FORM_FAIL'

export const POSITION_ADD_REQUEST ='POSITION_ADD_REQUEST'
export const POSITION_ADD_SUCCESS ='POSITION_ADD_SUCCESS'
export const POSITION_ADD_FAIL ='POSITION_ADD_FAIL'

export const POSITION_REMOVE_SUCCESS ='POSITION_REMOVE_SUCCESS'

export const POSITION_ID_CREATE_REQUEST ='POSITION_ID_CREATE_REQUEST'
export const POSITION_ID_CREATE_SUCCESS ='POSITION_ID_CREATE_SUCCESS'
export const POSITION_ID_CREATE_FAIL ='POSITION_ID_CREATE_FAIL'