import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
//ui -component
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import ChnagePasswrd from "../Setting/ChangePassword";
import { login } from "../../actions/authActions";
import CeoSidebar from '../../Components/CeoSidebar'
import DoctorSidebar from "../../Components/DoctorSidebar";
import HrSidebar from "../../Components/hrSidebar";
import SidebarRD from "../../Components/Rd-sidebar";

export default function ChnagePassword() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  // bringing things from the reducer
  const { user } = userLogin;

  return (
    <>
      {
        user.user.roles == 4 ? <DoctorSidebar />
          :
          user.user.roles !== 0 ? <Sidebar />
            : ''
      }

      {/* <Navbar /> */}
      <div style={{ marginTop: "10rem" }}>
        <CssBaseline />
        <Container maxWidth="sm" >
          <ChnagePasswrd />
        </Container>
      </div>
    </>
  );
}
