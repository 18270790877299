import React, { useContext, useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import logo from "../images/Logos-04.png";
import proLogo from "../images/protiviti-logo.png"
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { logout } from "../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import Popper from "@material-ui/core/Popper";
import io from "socket.io-client";
import Badge from "@material-ui/core/Badge";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import Popover from "@material-ui/core/Popover";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import { useHistory } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import FilterCompo from '../Components/FilterCompo'
import { documentlist } from "../actions/documentActions";
import axios from "axios";
import {
  APIURL,
  fetchAllRecords,
  fetchRecordByID,
} from "../CommomMethods/Fetch";
import { AppContext } from "../actions/contextApi";
import { pink } from "@mui/material/colors";
import { onboardState } from "../CommomMethods/States";
// const ENDPOINT = "http://localhost:8090";
const APINOTIFICATION = APIURL() + "notifications";
const PopOverStyle = makeStyles((theme) => ({
  root: {
    width: 350,
    zIndex: 9999,
    // height: "350px",
    maxHeight: "350px",
    overflowY: "auto",
    border: ".5px solid grey ",
    borderRadius: "5px",
    background: "#fff",
  },
  typography: {
    padding: theme.spacing(2),
  },
}));
function Navmain(props) {
  const popStyle = PopOverStyle();
  let history = useHistory();
  const [anchorElPop, setAnchorElPop] = React.useState(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [placementPop, setPlacementPop] = React.useState();
  const handleClickPOP = (newPlacement) => (event) => {
    setAnchorElPop(event.currentTarget);
    setOpenPopper((prev) => placementPop !== newPlacement || !prev);
    setPlacementPop(newPlacement);
  };
  const handlePopperClose = () => {
    setAnchorElPop(null);
    setOpenPopper(false);
  };
  const { centerNames, setCenterNames } = useContext(AppContext);
  const { Months, setMonths } = useContext(AppContext);
  const { Years, setYears } = useContext(AppContext);
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;
  const [userNotification, setUserNotification] = useState([]);
  const [notifyData, setUserNotifyData] = useState([]);
  const getNotificationByID = async () => {
    await axios
      .get(APIURL() + "notifications/" + user.user.id)
      .then(async (res) => {
        // setUserNotification(notifyData);
        var records = await res.data.users
        var newRecords = await records.filter((item) => {
          if (user.user.activityAssigments && user.user.activityAssigments.filter((elem) => (elem.activityName === onboardState.rd.activity && item.OnextAction === onboardState.rd.state) || (elem.activityName === onboardState.commercial_review.activity && item.OnextAction === onboardState.commercial_review.state) || (elem.activityName === onboardState.ch.activity && item.OnextAction === onboardState.ch.state) || (elem.activityName === onboardState.dr_info.activity && item.OnextAction === onboardState.dr_info.state) || (elem.activityName === onboardState.upload.activity && item.OnextAction === onboardState.upload.state) || (elem.activityName === onboardState.init.activity && (!item.OnextAction || item.OnextAction === onboardState.init.state)) || (elem.activityName === onboardState.hr.activity && item.OnextAction === onboardState.hr.state)).find(el => el.RoleId === user.user.roles)) {
            return item
          }
        })
        console.log(newRecords, user.user.activityAssigments, 'notification data')
        // setUserNotification(res.data.notificationData);
        setUserNotification(newRecords);
        // toast.success(res.data.notificationData);
      });
  }


  // function getNotification() {
  //   fetchAllRecords(APINOTIFICATION).then((res) => {
  //     var k = res.data.data.filter((z) => z.userid === "13");
  //     setUserNotification(res.data.data);
  //   });
  // }

  const updateUserNotification = (row) => {
    console.log(row, "uuu");
    // history.push({
    //   pathname: '/',
    //   state: { data: row },
    // });
    history.replace({
      pathname: (row.OnextAction === onboardState.dr_info.state || row.OnextAction === onboardState.upload.state) ? "/UploadJoining" : (row.OnextAction === onboardState.hr.state) ? "/UploadedDocHR" : (row.OnextAction === onboardState.commercial_review.state || row.OnextAction === onboardState.ch.state) ? "/view-status" : (row.OnextAction === onboardState.init.state || row.OnextAction == null) ? "/onboard-doctor" : (row.OnextAction === onboardState.rd.state) ? "/rd-approvalcalc" : "",
      state: { data: row },
    });
    window.location.reload()
    // row.status = "read";
    // axios
    //   .put(APIURL() + "notifications/" + row.id, row.message)
    //   .then((res) => {
    //     console.log(res, "99");
    //     getNotificationByID();
    //   });
  };
  const [notification, setNotification] = useState("");
  // useEffect(() => {
  //   const socket = io("http://localhost:8090/api");
  //   socket.on("notify", (data) => {
  //     setNotification(data);
  //     console.log(data, "navbar");
  //   });
  //   return () => socket.disconnect();
  //   //
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      // getNotification();

      getNotificationByID();
    }, 5000);
  }, [userNotification]);

  const dispatch = useDispatch();
  // defining logout handler
  const logoutHandler = () => {
    dispatch(logout());
    history.push("/");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   const postdata = {
  //     userid: notifyData.userid,
  //     message: notifyData.value,
  //     status: "unread",
  //   }
  //   axios.post(APIURL() + "notifications/",postdata)
  //     .then((res) => {
  //      console.log(res,"111")
  //     });
  // }, [userNotification]);

  const [response, setResponse] = useState("");
  // useEffect(() => {
  //   const socket = io(ENDPOINT, { transports: ['websocket'] });
  //   socket.on("FromAPI2", data => { console.log(data); setResponse(data); });
  //   if ((user && user.user && user.user.roles) == 1) {
  //     socket.on("RDNotify", data => {
  //       console.log(data, "123");
  //       // setResponse(data);
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });
  //   } else if (user && user.user && user.user.roles == 2) {
  //     socket.on("hrOKCH", data => {
  //       console.log(data);
  //       // setResponse(data);
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });
  //     socket.on("complete", data => {
  //       console.log(data);
  //       // setResponse(data);
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });

  //   } else if (user && user.user && user.user.roles == 3) {
  //     socket.on("DocOKHr", data => {
  //       console.log(data);
  //       // setResponse(data);
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });

  //     socket.on("CHOKHR", data => {
  //       console.log(data, "p88");
  //       // setResponse(data)
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });

  //   } else if (user && user.user && user.user.roles == 4) {
  //     socket.on("RDOKDocNotify", data => {
  //       console.log(data);
  //       // setResponse(data);
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });

  //     socket.on("OnboardInit", data => {
  //       console.log(data);
  //       // setResponse(data);
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });


  //   } else if (user && user.user && user.user.roles == 6) {
  //     // Finance;


  //   } else if (user && user.user && user.user.roles == 7) {
  //     // drm;


  //   } else if (user && user.user && user.user.roles == 0) {
  //     // Admin;


  //   }
  //   else {

  //   }
  //   return () => socket.disconnect();
  // }, []);
  const clearFilter = async () => {
    setCenterNames([])
    setMonths([])
    setYears([])
  };
  let path = window.location.pathname;
  let name = path.split("/")
  console.log(notifyData, "ooo77")
  return (
    <>
      <Navbar bg="light" expand="lg" className="nav-main">
        {/* <Navbar.Collapse id="basic-navbar-nav"> */}
        {/* {name[1] == 'bh-dashboard' ?
          <Form inline>
            <FilterCompo Center={user.user.usermap} YearData={user.user.yearData} />
            <Typography onClick={() => clearFilter()} style={{ color: pink[600], fontSize: '12.9px', padding: '5px 10px', cursor: 'pointer', marginLeft: '2px', border: '0.5px solid' + pink[600], borderRadius: '5px' }}>Clear Filter</Typography>
          </Form>
          : null} */}
        <div className="row" style={{ display: 'flex', flexDirection: 'column', width: '70px', marginLeft: 'inherit' }}>
          <span style={{ fontSize: '10px' }}>Powered By</span>
          <img src={proLogo} />
        </div>
        <Nav className="mr-auto"></Nav>

        <Tooltip title={"My Notifications"} onClick={handleClickPOP("top-end")} style={{
          cursor: "pointer",
        }} arrow placement="left-start">
          <Badge badgeContent={userNotification.length} color="error">
            <NotificationImportantIcon />
          </Badge>
        </Tooltip>
        <Popper
          open={openPopper}
          onClose={handlePopperClose}
          anchorEl={anchorElPop}
          placement={placementPop}
          transition
          className={userNotification.length > 0 ? popStyle.root : ""}
        >
          {({ TransitionProps }) =>
            userNotification.map((row) => (
              <Fade
                {...TransitionProps}
                timeout={350}
                key={userNotification.id}
                style={{
                  marginTop: "2px",
                  backgroundColor: "white",
                  // border: "1px solid red",
                }}
              >
                <Paper
                  style={{
                    minHeight: "55px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {/* <Link to={{ pathname: (row.OnextAction === onboardState.dr_info.state || row.OnextAction === onboardState.upload.state) ? "/UploadJoining" : (row.OnextAction === onboardState.hr.state) ? "/UploadedDocHR" : (row.OnextAction === onboardState.commercial_review.state || row.OnextAction === onboardState.ch.state) ? "/view-status" : (row.OnextAction === onboardState.init.state || row.OnextAction == null) ? "/onboard-doctor" : (row.OnextAction === onboardState.rd.state) ? "/rd-approvalcalc" : "", state: { data: row } }}> */}
                  <div
                    style={{
                      // width: "80%",
                      cursor: "pointer",
                      borderRight: "1px dashed #adadad",
                    }}
                    onClick={() => {
                      updateUserNotification(row);
                    }}
                  >
                    <Typography className={popStyle.typography}>
                      {row.message}
                    </Typography>
                  </div>
                  {/* </Link> */}
                  {/* <div style={{ width: "20%", padding: "0 5px" }}>
                    <IconButton
                      style={{
                        cursor: "pointer",
                        margin: "0 auto",
                        display: "block",
                        color: "#6fce6f",
                      }}
                      onClick={() => {
                        updateUserNotification(row);
                      }}
                    >
                      <BeenhereIcon />
                    </IconButton> */}
                  {/* <span
                      style={{
                        fontSize: "11px",
                        color: "#adadad",
                        margin: "0 auto",
                        display: "block",
                        textAlign: "center",
                      }}
                    >
                      Mark as Read
                    </span> */}
                  {/* </div> */}
                </Paper>
              </Fade>
            ))
          }
        </Popper>

        {/* <div className="main-header-notification">
          <a className="nav-link icon" href="chat.html">
            {" "}
            <i className="fe fe-message-square header-icons"></i>{" "}
            <span className="badge badge-success nav-link-badge">6</span>{" "}
          </a>
        </div> */}
        {/* <div className="main-header-notification">
          <a className="nav-link icon" href="chat.html">
            {" "}
            <i className="fe fe-bell header-icons"></i>{" "}
            <span className="badge badge-danger nav-link-badge">3</span>{" "}
          </a>
        </div> */}
        <div>
          <Button
            color="secondary"
            variant="text"
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            {" "}
            {user.user.username}
          </Button>
          <Menu
            style={{ zIndex: 10024 }}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>
              {user && user.user && user.user.roles === 1 ?
                <a href="/RDProfile">Profile</a> :
                user && user.user && user.user.roles === 2 ? <a href="/BHProfile">Profile</a> :
                  user && user.user && user.user.roles === 3 ? <a href="/HRProfile">Profile</a> :
                    user && user.user && user.user.roles === 4 ? <a href="/DoctorProfileStatus">Profile</a> :
                      user && user.user && user.user.roles === 5 ? <a href="/CeoProfile">Profile</a> :
                        <a href="/">Profile</a>}
            </MenuItem>

            <MenuItem onClick={handleClose}><a href="/ChangePassword">Change Password</a></MenuItem>

            <MenuItem onClick={logoutHandler}>
              <a href="/">Logout</a>
            </MenuItem>
          </Menu>
        </div>
        {/* <div className="main-header-notification">
          <a className="nav-link icon" href="/" onClick={logoutHandler}>
            {" "}
            <i className="fe fe-user header-icons"></i>{" "}
            <span className="signOut">Sign Out</span>{" "}
          </a>
        </div> */}
        {/* </Navbar.Collapse> */}
      </Navbar >
    </>
  );
}

export default Navmain;
