import React, { useContext, useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import logo from "../images/Logos-04.png";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { logout } from "../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import Popper from "@material-ui/core/Popper";
import io from "socket.io-client";
import Badge from "@material-ui/core/Badge";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import Popover from "@material-ui/core/Popover";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import { useHistory } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import FilterCompo from '../Components/FilterCompo'
import { documentlist } from "../actions/documentActions";
import axios from "axios";
import {
  APIURL,
  fetchAllRecords,
  fetchRecordByID,
} from "../CommomMethods/Fetch";
import { AppContext } from "../actions/contextApi";
import { pink } from "@mui/material/colors";
// const ENDPOINT = "https://docspay.cloudninecare.com:8090";
const APINOTIFICATION = APIURL() + "notifications";
const PopOverStyle = makeStyles((theme) => ({
  root: {
    width: 350,
    zIndex: 9999,
    // height: "350px",
    overflowY: "auto",
    border: ".5px solid grey ",
  },
  typography: {
    padding: theme.spacing(2),
  },
}));
function Navmain(props) {
  const popStyle = PopOverStyle();
  let history = useHistory();
  const [anchorElPop, setAnchorElPop] = React.useState(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [placementPop, setPlacementPop] = React.useState();
  const handleClickPOP = (newPlacement) => (event) => {
    setAnchorElPop(event.currentTarget);
    setOpenPopper((prev) => placementPop !== newPlacement || !prev);
    setPlacementPop(newPlacement);
  };
  const handlePopperClose = () => {
    setAnchorElPop(null);
    setOpenPopper(false);
  };
  const { centerNames, setCenterNames } = useContext(AppContext);
  const { Months, setMonths } = useContext(AppContext);
  const { IPOPName, setIPOPName } = useContext(AppContext);
  const { Years, setYears } = useContext(AppContext);
  const { LOSName, setLOSName } = useContext(AppContext);
  const { itemGroupNames, setitemGroupNames } = useContext(AppContext);
  const { regionNames, setRegionNames  } = useContext(AppContext);
  const [ itemgroupList, setitemgroupList ] = useState(props.itemgroupList);
  const { DoctorNames, setDoctorNames } = useContext(AppContext);
  const [ DoctorList, setDoctorList ] = useState(props.DoctorList);
  const [ RegionList, setRegionList ] = useState(props.RegionList);
  const [ LOSList, setLOSList ] = useState(props.LOSList);
  const [ YearList, setYearList ] = useState(props.YearList);
  const [ CenterList, setCenterList ] = useState(props.CenterList);
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;
  const [userNotification, setUserNotification] = useState([]);
  const [notifyData, setUserNotifyData] = useState([]);
  function getNotificationByID() {
    axios
      .get(APIURL() + "notifications/" + user.user.id)
      .then((res) => {
        // setUserNotification(notifyData);
        setUserNotification(res.data.notificationData);
        // toast.success(res.data.notificationData);
      });
  }
  
  

  // function getNotification() {
  //   fetchAllRecords(APINOTIFICATION).then((res) => {
  //     var k = res.data.data.filter((z) => z.userid === "13");
  //     setUserNotification(res.data.data);
  //   });
  // }

  const updateUserNotification = (row) => {
    console.log(row, "uuu");
    row.status = "read";
    axios
      .put(APIURL() + "notifications/" + row.id, row.message)
      .then((res) => {
        console.log(res, "99");
        getNotificationByID();
      });
  };
  // const [notification, setNotification] = useState("");
  // useEffect(() => {
  //   const socket = io("https://docspay.cloudninecare.com:8090/api");
  //   socket.on("notify", (data) => {
  //     setNotification(data);
  //     console.log(data, "navbar");
  //   });
  //   return () => socket.disconnect();
  //   //
  // }, []);

  useEffect(() => {
    setTimeout(() => {
      // getNotification();

      getNotificationByID();
    }, 5000);
  }, [userNotification]);

  const dispatch = useDispatch();
  // defining logout handler
  const logoutHandler = () => {
    dispatch(logout());
    history.push("/");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   const postdata = {
  //     userid: notifyData.userid,
  //     message: notifyData.value,
  //     status: "unread",
  //   }
  //   axios.post(APIURL() + "notifications/",postdata)
  //     .then((res) => {
  //      console.log(res,"111")
  //     });
  // }, [userNotification]);

  // const [response, setResponse] = useState("");
  // useEffect(() => {
  //   const socket = io(ENDPOINT, { transports: ['websocket'] });
  //   socket.on("FromAPI2", data => { console.log(data); setResponse(data); });
  //   if ((user && user.user && user.user.roles) == 1) {
  //     socket.on("RDNotify", data => {
  //       console.log(data, "123");
  //       // setResponse(data);
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });
  //   } else if (user && user.user && user.user.roles == 2) {
  //     socket.on("hrOKCH", data => {
  //       console.log(data);
  //       // setResponse(data);
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });
  //     socket.on("complete", data => {
  //       console.log(data);
  //       // setResponse(data);
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });

  //   } else if (user && user.user && user.user.roles == 3) {
  //     socket.on("DocOKHr", data => {
  //       console.log(data);
  //       // setResponse(data);
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });

  //     socket.on("CHOKHR", data => {
  //       console.log(data, "p88");
  //       // setResponse(data)
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });

  //   } else if (user && user.user && user.user.roles == 4) {
  //     socket.on("RDOKDocNotify", data => {
  //       console.log(data);
  //       // setResponse(data);
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });

  //     socket.on("OnboardInit", data => {
  //       console.log(data);
  //       // setResponse(data);
  //       setUserNotifyData(data);
  //       toast.info(data.value, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     });
  //   } 
  //   return () => socket.disconnect();
    
  // }, []);
  const clearFilter = async () => {
    setCenterNames([])
    setMonths([])
    setYears([])
    setitemGroupNames([])
    setDoctorNames([])
    setRegionNames([])
    setLOSName([])
    setIPOPName([])
  };
  let path = window.location.pathname;
  let name = path.split("/")
  console.log(notifyData, "ooo77")
  return (
    <>
      <Navbar bg="light" expand="lg" className="nav-main mx-auto">
        {/* <Navbar.Collapse id="basic-navbar-nav"> */}
        {name[1] == 'bh-dashboard' || name[1] == 'Template' || name[1] == 'doctor-dashboard' || name[1] == 'CFODashBoard' ?
            <Form inline className="mx-auto">
              <FilterCompo Center={props.CenterList} YearData={YearList} ItemGroup={itemgroupList} Doctor={DoctorList} RegionList={RegionList} LOSList={LOSList}/>
              <Typography onClick={() => clearFilter()} style={{ color: pink[600], fontSize: '12.9px', padding: '4.5px 15px', cursor: 'pointer', marginLeft: '2px', marginLeft: '2px', border: '0.5px solid' + pink[600], borderRadius: '5px' }}>Clear Filter</Typography>
            </Form>
          : null}
        
      </Navbar>
    </>
  );
}

export default Navmain;
