import React, { useEffect, useState } from "react";
import AppSidebar from "../../Components/AppSidebar";
import Sidebar from "../../Components/Sidebar";
import DoctorSidebar from "../../Components/DoctorSidebar";
import axios from "axios";
import { Card, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { agreementState } from "../../CommomMethods/States";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Navmain from "../../Components/Navbar";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MaterialTable from "material-table";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import { listusers } from "../../actions/userActions";
import { agreementlist } from "../../actions/agreementActions";
import { ClickAwayListener, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import FixedfullTime from "../Payout/NEW/FixedfullTime";
import DraftMG from "../Payout/NEW/draftMG";
import DraftVisitingCon from "../Payout/NEW/DraftVisitingCon";
import DraftFixedfullTime from "../Payout/NEW/DraftFixedfullTime";
import FixedMG from "../Payout/NEW/fixedMG";
import VcChennai from "../Payout/NEW/VcChennai";
import VisitingCon from "../Payout/NEW/VisitingCon";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { APIURL } from "../../CommomMethods/Fetch";
import { numberWithCommas, numberToEnglish } from '../../actions/createPositionActions'
import Agreement from "../Payout/NEW/Agreement";
const { onboardState } = require("../../CommomMethods/States");
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  buttonProgress: {
    // backgroundColor: "red",
    color: "white",
    position: "absolute",
    top: "50%",
    left: "20%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#810e36",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  // disabledButton: {
  //   backgroundColor:'#810e36',
  //   color:"#ffff",
  //   padding: "6px",
  //   borderRadius: "10%",
  // },
  // approveButton: {
  //   backgroundColor:'green',
  //   color:"#ffff",
  //   padding: "6px",
  //   borderRadius: "10%",
  // }
}));
const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function Estimation({ history }) {
  const tables = myTableStyle();
  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  const [show, setShow] = useState(false);
  const [row, setRow] = useState({});
  const [docData, setDocData] = useState([]);
  const [categoty, setCategory] = useState("");

  const [openReject, setOpenRejectmodal] = React.useState(false);
  const [openApprove, setOpenApprovemodal] = React.useState(false);
  const [review, setReview] = useState("");
  const [approve, setapprove] = useState([]);
  const [rej, setRej] = useState([]);

  const [onboardData, setOnboardData] = useState([]);
  const [openr, setOpenr] = React.useState(false);
  const [openOp, setOpenOp] = React.useState(false);
  const [openIp, setOpenIp] = React.useState(false);
  const [backDropOpen, setBackDropOpen] = useState(false);
  const backClasses = backDropStyle();
  const [openCommercials, setOpenCommercials] = React.useState(false);
  const [viewAgreement, setViewAgreement] = useState(false);
  const [docid, setDocid] = useState("");
  const [doctorDetail, setDoctorDetail] = useState([]);
  const [doctorOpDetail, setDoctorOpDetail] = useState([]);
  const classes = useStyles();
  const [doctorIpDetail, setDoctorIpDetail] = useState([]);
  const [doctorOpCommercial, setDoctorOpCommercial] = useState([]);
  const [doctorIpCommercial, setDoctorIpCommercial] = useState([]);
  const [doctorMgDetails, setDoctorMgDetails] = useState([]);
  const [doctorFixedDetails, setDoctorFixedDetails] = useState([]);
  const [remark, setRemark] = useState("");
  const [status, setStatus] = useState(false);
  const userList = useSelector((state) => state.userList);
  const { users } = userList;

  const agreementList = useSelector((state) => state.agreementList);
  const { agreements } = agreementList;
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(agreementlist());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  console.log(user.user.id, "user.user.id");
  useEffect(() => {
    axios
      .get(APIURL() + "onboard/currentstatus")
      .then((res) => {
        console.log(res.data, "PPPPPPPPPP");
        setOnboardData(res.data.datas);
      })
      .catch((error) => { });
  }, [status]);

  function getDocdetail(id) {
    axios
      .get(APIURL() + "onboard/previewOne/" + id)
      .then((res) => {
        setDoctorDetail(res.data);
        setDoctorIpDetail(res.data.doctorIPParameter);
        setDoctorOpDetail(res.data.doctorOPParameter);
        setDoctorOpCommercial(res.data.doctorOpCommercial);
        setDoctorIpCommercial(res.data.doctorIpCommercial);
        setDoctorMgDetails(res.data.doctorMgDetails);
        setDoctorFixedDetails(res.data.doctorFixedDetails);
      })
      .catch((error) => { });
  }

  useEffect(() => {
    getDocdetail(docid);
  }, [docid]);

  useEffect(() => { }, []);

  const handlerClickOpen = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenr(true);
  };
  const handleClose = () => setShow(false);
  const handlerClose = () => {
    setOpenr(false);
  };
  const handleComments = (e) => {
    setReview(e.target.value)
  };
  const handlerClickOpenOp = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenOp(true);
  };
  const closeAgreement = () => {
    setViewAgreement(false);
  };
  const viewAgreementDoc = async (row) => {
    setRow(row);
    console.log(row, "ROWW");
    await axios
      .get(APIURL() + "onboard/agreementView/" + row.doctorId,)
      .then((res) => {
        if (res.data != null) {
          console.log(res.data.data, "resssss");
          setCategory(res.data.data.vacancyData[0].category);
          setDocData(res.data.data);
        }
      });

    setViewAgreement(true);
  };
  const handlerCloseOp = () => {
    setOpenOp(false);
  };

  const handlerClickcloseCommercials = () => {
    setOpenCommercials(false);
  };

  const handlerCloseIp = () => {
    setOpenIp(false);
  };
  const handleRejectDialog = (row) => {
    setDocid(row.doctorId);
    setOpenRejectmodal(true);
    setRej(row);
  };
  const handleApproveDialog = (row) => {
    setDocid(row.doctorId);
    setOpenApprovemodal(true)
    setapprove(row);
  };
  const handleReject = async () => {
    if (review == '') {
      toast.error("Kindly provide some comments");
      return false;
    }
    setBackDropOpen(true);
    const obj1 = {
      doctorId: rej.doctorId,
      name: rej.name,
      currentAction: onboardState.doc_revise.state,
      currentActionBy: users[0].id,
      nextAction: onboardState.init.state,
      remark: review,
      status: 2,
    };
    await axios.post(APIURL() + "onboard/action/", obj1).then((res) => {
      setBackDropOpen(false);
    });
    toast.success("Sent for Review");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }
  const handleApprove = (ele) => {
    // setStatus(true);
    if (review == '') {
      toast.error("Kindly provide some comments");
      return false;
    }

    setBackDropOpen(true);
    const obj = {
      doctorId: approve.doctorId,
      name: approve.name,
      currentAction: agreementState.init.state,
      currentActionBy: users[0].id,
      nextAction: agreementState.hr_revision.state,
      remark: review,
      status: 1,
    };
    const obj1 = {
      doctorId: approve.doctorId,
      name: approve.name,
      currentAction: onboardState.dr_proposed_commercials_accepted.state,
      currentActionBy: users[0].id,
      nextAction: onboardState.rd.state,
      remark: review,
      status: 2,
    };
    axios.post(APIURL() + "onboard/action/", obj1).then((res) => {
      setBackDropOpen(false);
    });
    // axios.post(APIURL() + "onboard/agreementAction/", obj).then((res) => {
    //   setBackDropOpen(false);
    //   //  toast.success("Success");
    // });
    toast.success("Success");
    setTimeout(() => {
      // toast.success("Success!");
      window.location.reload();
    }, 3000);
  };

  const Remark = (e) => {
    setRemark(e.target.value);
  };
  const updateRecord = () => {
    doctorOpDetail.forEach((element) => {
      element.chstatus = 0;
    });
    doctorIpDetail.forEach((element) => {
      element.chstatus = 0;
    });
    doctorOpCommercial.forEach((element) => {
      element.chstatus = 0;
    });
    doctorIpCommercial.forEach((element) => {
      element.chstatus = 0;
    });
    const obj = {
      doctorOpDetail,
      doctorIpDetail,
      doctorOpCommercial,
      doctorIpCommercial,
    };
    axios
      .put(APIURL() + "onboard/updateFourtables/" + docid, obj)
      .then((res) => { });
    if (remark != "") {
      const obj1 = {
        doctorId: doctorDetail.doctorDetails.id,
        name: doctorDetail.doctorDetails.name,
        currentAction: onboardState.doc_reject.state,
        currentActionBy: 1,
        nextAction: onboardState.ch.state,
        remark: remark,
        status: 1,
      };
      axios.post(APIURL() + "onboard/action/", obj1).then((res) => {
        // toast.success("Rectification successfully submitted");
      });
      window.location.reload();
    }
  };
  const TableTitle = ({ text }) => (
    <Typography
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "black",
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "15px"
      }}
    >
      {text}
    </Typography>
  );
  console.log(onboardState, categoty, "onboardState.upload.state")
  return (
    <>
      {
        user.user.roles == 4 ? <DoctorSidebar />
          :
          user.user.roles !== 0 ? <Sidebar />
            : <AppSidebar />
      }

      <div className="main-content side-content pt-0">
        <Navmain />
        {user.user.activityAssigments.filter((elem) => elem.activityId === 4 || elem.activityId === 5).find(item => item.RoleId === user.user.roles) ?
          <Card.Body
            // style={bodyStyle}
            style={{ width: "100%", marginTop: "4rem" }}
          >
            <MaterialTable
              columns={[
                { title: "Doctor Name", field: "name" },
                { title: "Comments", field: "comments" },
                {
                  title: "Draft Agreement",
                  field: "Action",
                  filtering: false,
                  render: (row) => (
                    <div style={{ width: "140px" }}>
                      {console.log(row, "row-888")}
                      <i
                        className="fas fa-address-card"
                        style={{ fontSize: "17px", color: "#810e36" }}
                        onClick={() => viewAgreementDoc(row)}
                      />
                    </div>
                  ),
                },
                {
                  title: "Proposed Commercials",
                  field: "Action",
                  filtering: false,
                  render: (row) => (
                    <div style={{ width: "140px" }}>
                      <InsertDriveFileIcon
                        style={{ color: "#810e36" }}
                        onClick={() => handlerClickOpenOp(row)}
                      />
                    </div>
                  ),
                },
                {
                  title: "Action",
                  field: "Action",
                  filtering: false,
                  render: (row) =>
                    row.currentAction == onboardState.doc_revise.state ?
                      <span className="badge badge-primary">Sent for Review</span> :
                      row.nextAction == onboardState.dr_info.state ?
                        <Link to={{
                          pathname: "/ProfileDetails",
                        }} >
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                          >
                            Fill Info
                          </Button>
                        </Link>
                        :
                        row.nextAction == onboardState.upload.state ?
                          <>
                            <div
                            // style={{ width: "420px" }}
                            >
                              <Link
                                to={{
                                  pathname: "/DoctorDashboard",
                                }} >
                                <Button
                                  variant="contained"
                                  // color="primary"
                                  size="small"
                                  style={{
                                    backgroundColor: "#810e36",
                                    color: "white",
                                  }}
                                >
                                  Upload Documents
                                </Button>
                              </Link>
                              {/* {row.agreementNextAction == agreementState.dr_accepted.state ?
                            <Link
                              to={{
                                pathname: "/check-agreement",
                              }} >
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ marginLeft: "4px" }}
                              >
                                Accept Draft Agreement
                              </Button>
                            </Link>
                            : ""} */}
                            </div>


                          </>
                          :
                          row.nextAction == onboardState.hr.state ?
                            <>
                              <div
                              // style={{ width: "420px" }}
                              >
                                <Link to={{
                                  pathname: "/DoctorDashboard",
                                }} >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                  >
                                    View Documents
                                  </Button>
                                </Link>
                                {/* {row.agreementNextAction == agreementState.dr_accepted.state ?
                              <Link
                                to={{
                                  pathname: "/check-agreement",
                                }} >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  style={{ marginLeft: "4px" }}
                                >
                                  Accept Draft Agreement
                                </Button>
                              </Link>
                              : ""} */}
                              </div>
                            </>
                            : row.nextAction == onboardState.rd.state ? <span className="badge" style={{ color: "white", backgroundColor: "grey", }}>Awaiting for COO Approval </span>
                              : <Link to={{ pathname: "/DoctorDashboard" }}><Button variant="contained" color="primary" size="small">View Documents</Button></Link>,
                },
              ]}
              data={
                onboardData.data &&
                onboardData.data.filter((z) => z.doctorId == user.user.id)
              }
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
              }}
              title="Estimation Approval"
            />
          </Card.Body>
          : <div>
            <h2 style={{ marginTop: "200px", display: 'flex', justifyContent: 'center', color: "#d11d53" }}>You Are Not Authorized To See This Page</h2>
          </div>
        } 
        
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Doctor Details
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Name:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].fullname}
          </Typography>
          <Typography gutterBottom>
            Email-Id:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].email}
          </Typography>
          <Typography gutterBottom>
            Mobile:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].mobile}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="xl"
        onClose={handlerCloseOp}
        aria-labelledby="customized-dialog-title"
        open={openOp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseOp}
          style={{ textAlign: "center" }}
        >
          <h5
            style={{
              margin: ".4rem",
              textAlign: "center",
              padding: "7px",
              backgroundColor: "#810e36",
              color: "white",
            }}
          >
            <b>Commercials Summary</b>
          </h5>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom>
            <b>Name: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].fullname}
          </Typography>
          <Typography gutterBottom>
            <b> Email-Id: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].email}
          </Typography>
          <Typography gutterBottom>
            <b> Mobile: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].mobile}
          </Typography> */}
          <Paper className={tables.root} style={{ border: "1px solid" }}>
            {/* <h5 className="p-2" style={{ fontWeight: "bold", borderBottom: "1px solid" }}>Commercials Summary</h5> */}

            <MaterialTable
              columns={[
                { title: "Center Name", field: "center", render: (row) => (<div>{row.center ? row.center : "---"}</div>) },
                { title: "Item Group", field: "itemgroup", render: (row) => (<div>{row.itemgroup ? row.itemgroup : "---"}</div>) },
                { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
                { title: "Commercial Term", field: "commercialterm", render: (row) => (<div>{row.commercialterm ? row.commercialterm : "---"}</div>) },
              ]}
              data={doctorOpCommercial}
              options={{
                // filtering: true,
                sorting: true,
                paging: false,
                search: false,
                headerStyle: {
                  border: "1px solid rgba(224, 224, 224, 1)",
                  padding: "3px 10px",
                },
                cellStyle: {
                  border: "1px solid rgba(224, 224, 224, 1)",
                  padding: "3px 10px"
                },
              }}
              title={<TableTitle text="Out-Patient Commercial" />}
            />
            <MaterialTable
              columns={[
                { title: "Center Name", field: "Center", render: (row) => (<div>{row.Center ? row.Center : "---"}</div>) },
                { title: "Item Group", field: "itemGroup", render: (row) => (<div>{row.itemGroup ? row.itemGroup : "---"}</div>) },
                { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
                { title: "Room Category", field: "roomCategory", render: (row) => (<div>{row.roomCategory ? row.roomCategory : "---"}</div>) },
                { title: "MOP", field: "ipmodeofpayment", render: (row) => (<div>{row.ipmodeofpayment ? row.ipmodeofpayment : "---"}</div>) },
                { title: "Commercial Term", field: "commercialTerm", render: (row) => (<div>{row.commercialTerm ? row.commercialTerm : "---"}</div>) },
              ]}
              // data={doctorDetail.doctorIPParameter}
              data={doctorIpDetail}
              options={{
                // filtering: true,
                sorting: true,
                paging: false,
                search: false,
                headerStyle: {
                  border: "1px solid rgba(224, 224, 224, 1)",
                  padding: "3px 10px",
                },
                cellStyle: {
                  border: "1px solid rgba(224, 224, 224, 1)",
                  padding: "3px 10px"
                },
              }}
              title={<TableTitle text="In-Patient Commercials" />}
            />
            {doctorMgDetails && doctorMgDetails.length > 0 ?
              doctorMgDetails.map((item) =>
                <MaterialTable
                  columns={[
                    { title: "Minimum Guarantee Payment: The Company agrees to pay the Consultant an amount of Rs. " + numberWithCommas(item.minguranteepermonth) + " (Rs. " + numberToEnglish((item.minguranteepermonth).replaceAll(',', '')) + ") per month (“Minimum Guarantee Amount”) or the “Calculated Earnings” whichever is higher subject to statutory deductions. ", field: "mgCommercial" },
                  ]}
                  // data={doctorDetail.doctorIPParameter}
                  data={
                    [
                      {
                        mgCommercial: 'The Minimum Guarantee Amount will be paid for ' + item.mgperiod + ' months'
                      },
                      {
                        mgCommercial: 'The earnings shall be calculated on ' + item.settlementperiod + ' basis and difference amount (Calculated Earnings minus Minimum Guarantee), if any, shall be paid at the end of ' + item.settlementpaymentperiod + '.'
                      },
                    ]
                  }
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontWeight: 400
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text={"Minimum Guarentee (MG) Commercial for " + item.Center} />}
                />
              )
              : ''}
            {doctorFixedDetails && doctorFixedDetails.length > 0 ?
              doctorFixedDetails.map((item) =>
                <MaterialTable
                  columns={[
                    { title: "Monthly Fixed Fee: The Company will pay an all-inclusive amount of Rs. " + numberWithCommas((item.professionalfeepermonth).replaceAll(',', '')) + " (Rs. " + numberToEnglish((item.professionalfeepermonth).replaceAll(',', '')) + ") per month. ", field: "fixedCommercial", width: "100%" },
                  ]}
                  // data={doctorDetail.doctorIPParameter}
                  data={
                    [
                      {
                        fixedCommercial: item.additionalfeevalue != '0' ? "Additional Fee for " + item.additionalfeename + ": The Company will pay an additional amount of Rs. " + numberWithCommas(((item.additionalfeevalue).toString()).replaceAll(',', '')) + " (Rs. " + numberToEnglish(((item.additionalfeevalue).toString()).replaceAll(',', '')) + ") per month. " : ""
                      },
                    ]
                  }
                  options={{
                    // filtering: true,
                    sorting: false,
                    paging: false,
                    search: false,
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontWeight: 400
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text={"Full Time Consultant Commercial for " + item.Center} />}
                />
              )
              : ''}
          </Paper>
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerCloseOp}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerCloseIp}
        aria-labelledby="customized-dialog-title"
        open={openIp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseIp}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                {
                  title: "Revenue",
                  field: "revenue",
                  render: (row) => (<div>{row.revenue ? "Rs. " + numberWithCommas(parseFloat(row.revenue).toFixed()) : null}</div>)
                },
                {
                  title: "Pay Out",
                  field: "payout",
                  render: (row) => (<div>{row.payout ? "Rs. " + numberWithCommas(parseFloat(row.payout).toFixed()) : null}</div>)
                },
                // { title: "Net Amount", field: "ipTotalAmount" },
              ]}
              data={doctorDetail.doctorIPParameter}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
              }}
              title="IP Details"
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerCloseIp}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClickcloseCommercials}
        aria-labelledby="customized-dialog-title"
        open={openCommercials}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group/Procedures", field: "itemGroup" },
                { title: "Commercial Term", field: "CommercialTerm" },
              ]}
              data={doctorDetail.doctorIPParameter}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
                exportAllData: true,
              }}
              title="Commercial Details"
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerClickcloseCommercials}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        open={viewAgreement}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="end"
              style={{ marginLeft: "99%" }}
              color="inherit"
              onClick={closeAgreement}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          {docData && docData.agreementTemplate ?
            <Agreement
              history={row}
              recData={docData}
              catagory={categoty}
            />
            :
            categoty === "Fixed Consultant" || categoty === "Full Time Consultant" || categoty === 'Retainer' ? (
              <FixedfullTime
                history={row}
                recData={docData}
                catagory={categoty}
              />
            ) : //   <FixedfullTime
              //   history={row}
              //   recData={docData}
              //   catagory={categoty}
              // />
              categoty === "Minimum Guarentee(MG)" ? (
                <FixedMG history={row} recData={docData} catagory={categoty} />
              ) : // <FixedMG history={row} recData={docData} catagory={categoty} />
                categoty === "Visiting Consultant" ? (
                  <DraftVisitingCon
                    history={row}
                    recData={docData}
                    catagory={categoty}
                  />
                ) : // <VisitingCon history={row} recData={docData} catagory={categoty} />
                  categoty === "Visiting Consultant Chennai" ? (
                    <VisitingCon history={row} recData={docData} catagory={categoty} />
                  ) : (
                    // <VcChennai history={row} recData={docData} catagory={categoty} />
                    "hi"
                  )}
        </List>
      </Dialog>
      {/* APPROVE MODAL */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenApprovemodal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openApprove}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenApprovemodal(false);
          }}
          style={{ textAlign: "center" }}
        >Accept</DialogTitle>
        <DialogContent dividers>
          <label>
            Comments{" "}
          </label>
          <textarea
            placeholder="Comments"
            type="text"
            onChange={handleComments}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleApprove}
            color="secondary"
            variant="contained"
            size="small"
          >

            Accept
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setOpenApprovemodal(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* REJECT MODAL */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenRejectmodal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openReject}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenRejectmodal(false);
          }}
          style={{ textAlign: "center" }}
        >Review</DialogTitle>
        <DialogContent dividers>
          <label>
            Comments{" "}
          </label>
          <textarea
            placeholder="Comments"
            type="text"
            onChange={handleComments}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleReject}
            color="secondary"
            variant="contained"
            size="small"
          >
            Review
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setOpenRejectmodal(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>



      <Backdrop
        className={backClasses.backdrop}
        open={backDropOpen}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
