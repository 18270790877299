import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
export default function ipHead() {

  return (
    <>
    <Navbar />
  <h1 style={{textAlign:"center"}}>WELCOME IP HEAD</h1>
    </>
  );
}
