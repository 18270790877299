import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
//ui components
import PTSidebar from "../../Components/PTSidebar";
import Sidebar from "../../Components/Sidebar";
import Navbar from "../../Components/Navbar";
import ConfirmDialog from "../../Components/ConfirmDialog";
//mui components
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Button, Paper, Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import Skeleton from "@material-ui/lab/Skeleton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
//import component
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import { FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { onboardById } from "../../actions/onboardActions";
import axios from "axios";
import { APIURL } from "../../CommomMethods/Fetch";
import { payoutState } from "../../CommomMethods/States";
import { payoutCalculation } from "../../Pages/Calculations/payoutCalc";
import moment from 'moment';
import { dateFormatter } from "../../actions/createPositionActions";
import { toast } from "react-toastify";
import XLSX from 'xlsx';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const styleSheet = makeStyles((theme) => ({
  iconButtonStyle: {
    float: "right",
  },
  marginforMaster: {
    marginTop: "30px",
  },
}));

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function OpPayoutProcess() {
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const tableRef = useRef();
  const location = useLocation();
  // console.log(location, "location from anothere state");
  const classes = styleSheet();
  const dispatch = useDispatch();
  const [backDropOpen, setBackDropOpen] = useState(false);
  const backClasses = backDropStyle();
  const [rows, setRows] = useState([]);
  const [name, setName] = useState([]);
  const [center, setCenter] = useState("Select Center");
  const [month, setMonth] = useState("Select Month");
  const [year, setYear] = useState("Select Year");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [changeRowValue, setChangeRowValue] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const [openr, setOpenr] = React.useState(false);
  const [reload, setReload] = useState(false);
  const [opTotPayout, setOpTotPayout] = useState([]);
  const [initCalOP, setInitCalOP] = useState([]);
  const [data, setData] = useState("Select the value");
  const [splitDialog, setSplitDialog] = useState(false);
  const [spitRecord, setSplitRecord] = useState({});
  const [splitNewRec, setSplitNewRec] = useState([]);
  const [oboard, setOboard] = useState([]);
  const [oboardVal, setOboardVal] = useState([]);
  const [dataVal, setDataVal] = useState([]);
  const [loadEachData, setLoadEachdata] = useState(false);
  const [centerName, setCenterName] = useState([]);
  const [payoutDataStatus, setPayoutDataStatus] = useState([]);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  // console.log("user", user);
  const [rowDetail, setRowDetail] = useState({
    doctorname: "",
    ratio: "",
    percentage: "",
  });
  // const onboardId = useSelector((state) => state.onboardId);
  // const { oboard } = onboardId;p

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRowDetail({ ...rowDetail, [name]: value });
  };
  const handlePayoutEdit = () => {
    setReload(true);
    const obj = { rowDetail: rowDetail, changeRowValue: changeRowValue };
    axios.put(APIURL() + "billings/updateBillingRowData", obj).then((res) => {
      setReload(false);
      setOpenr(false);
    });
  };

  useEffect(() => {
    setLoading(false);
    axios.get(APIURL() + "usermapcenters/centermaps/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
        // console.log(rows, "2323");
        setLoading(true);
        setCenterName(rows.data);
      });
  }, []);

  // useEffect(() => {
  //   setLoading(false);
  //   axios
  //     .get(APIURL() + "billings/findAllBillingDetProcess")
  //     .then((res) => res.data)
  //     .then((rows) => {
  //       setRows(rows.data);
  //       // console.log(rows.data, "fjgsdjcgsdfhdsgfjdghfdg")
  //       setLoading(true);
  //     });
  // }, []);

  // useEffect(() => {
  //   setLoading(false);
  //   axios
  //     .get(APIURL() + "onboard/name")
  //     .then((res) => res.data)
  //     .then((rows) => {
  //       setName(rows);
  //       setLoading(true);
  //     });
  // }, []);
  var array = [];
  var k =
    name &&
    name.data &&
    name.data.map((ele) => {
      array.push(ele.doctorName);
    });
  // console.log(array, "0012");
  // useEffect(() => {
  //   setLoading(false);
  //   axios
  //     .get(APIURL() + "onboard/name/" + array)
  //     .then((res) => res.data)
  //     .then((rows) => {
  //       setOboard(rows);
  //       setLoading(true);
  //     });
  // }, []);

  // console.log(oboard, "*!!!");
  var doctorList = [
    { id: 0, name: "Dr. Preety Aggarwal" },
    { id: 1, name: "Dr. Shweta Bansal Wazir" },
    { id: 2, name: "Dr. Manish Balde" },
    { id: 3, name: "Dr. Witty Raina" },
    { id: 4, name: "Dr. Binal Shah" },
    { id: 5, name: "Dr. Beena Muktesh" },
    { id: 6, name: "Dr. Shalu Gupta" },
    { id: 7, name: "Dr. Bhavna Dewan" },
    { id: 8, name: "Dr. Radhika Sheth" },
    { id: 9, name: "Dr. Hena Bhagde" },
  ];
  function selectedDropdownvalue(e) {
    const { name, value } = e.target;
    setData(value);
    setInitCalOP([]);
  }
  function selectedMonth(e) {
    const { name, value } = e.target;
    setMonth(value);
  }

  function selectedYear(e) {
    const { name, value } = e.target;
    setYear(value);
  }

  function selectedCenter(e) {
    const { name, value } = e.target;
    setCenter(value);
  }

  // console.log(center, "CENTER");
  // useEffect(() => {
  //   setInitCalOP([]);
  //   setOpTotPayout([]);
  // }, [data]);

  // useEffect(() => {
  //   dispatch(onboardById(364));
  // }, [reload]);
  const [valRec, setValRec] = useState([]);
  const [loadVal, setLoadVal] = useState(false);
  var sumArray = [];
  var Val = [];
  var sum = 0;

  function generatePay() {
    setBackDropOpen(true);
    axios.get(APIURL() + 'billings/Payout/generateOpPay')
      .then((res) => res.data)
      .then((rows) => {
        // console.log(rows.data, "0002");
        let value = rows.data.forEach(ele => {
          ele.ConductedByFinal = "Dr." + ele.ConductedByFinal;
        })
        setDataVal(value);
        // setLoading(true);
        setBackDropOpen(false);
      });
  }

  const calculatePayoutAll = async () => {
    setBackDropOpen(true);
    var regions = [];
    if (user.user.id == 401) {
      regions = "NORTH";
    }
    if (user.user.id == 400) {
      regions = "SOUTH";
    }
    if (user.user.id == 402) {
      regions = "WEST";
    }
    // console.log(regions, "REGION");
    await axios.get(APIURL() + `billings/loadbills/${center}/${year}/${month}/${startDate}/${endDate}/calcPayByMonth`, { params: { regions: regions } })
      .then((res) => res.data)
      .then(async (rows) => {
        // console.log(rows.data, "#########");
        await setDataVal(rows.data);
        await setBackDropOpen(false);
      });
  }
  const downloadCsv = (data, fileName) => {
    const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    a.setAttribute("download", finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  // useEffect(() => {
  //   axios.get(APIURL() + "usermapcenters/centermaps/" + user.user.id)
  //     .then((res) => res.data)
  //     .then((rows) => {
  //       // console.log(rows, "2323");
  //       setCenterName(rows.data);
  //     });
  // }, []);

  // console.log(centerName, "centerName");

  const LoadData = async () => {
    var data = {
      Center: center,
      // Year: year,
      // Month: month,
      startDate: moment(new Date(startDate)).format('YYYY-MM-DD'),
      endDate: moment(new Date(endDate)).format('YYYY-MM-DD'),
    };
    // await axios
    //   .post(APIURL() + "billings/LoadopBillingDetailProcessDump", data)
    //   .then((res) => {
    //     console.log(res, 'OP Bill data')
    //     toast.success(res.data.message);
    //   })
    //   .catch((err) => {
    //     toast.error(err.message);
    //   });

    await getRecord();
  }

  const getRecord = async () => {
    setLoading(false);
    let url = APIURL() + `billings/loadbills/${center}/${year}/${month}/${startDate}/${endDate}`;
    // console.log(url, "url");
    await axios
      .get(url)
      .then((res) => res.data)
      .then((rows) => {
        // console.log(rows, "@!!45");

        setOboardVal(rows);

        setLoading(true);
      });
    await getPayoutStatus()
  }

  // useEffect(() => {
  //   setInitCalOP(valRec);
  // }, [loadVal, valRec]);
  const handlerClose = () => {
    setOpenr(false);
  };
  const onSplitRow = (row) => {
    setSplitNewRec([]);
    setRowDetail({ doctorname: "", ratio: "", percentage: "" });
    setSplitRecord(row);
  };
  const addRowData = (row) => {
    let rec = {};
    spitRecord.ConductedBy = rowDetail.doctorname;
    spitRecord.Share = rowDetail.ratio;
    rec = {
      ...spitRecord,
      ConductedBy: rowDetail.doctorname,
      Share: rowDetail.ratio,
    };
    let newData = [...splitNewRec];
    newData.push(rec);
    newData.forEach((i, index) => {
      // console.log(i, index, "record 21");
    });
    rec = {};
    setSplitNewRec(newData);
    setRowDetail({ doctorname: "", ratio: "", percentage: "" });
  };

  const changeSplitRecord = () => {
    axios
      .post(APIURL() + "billings/createSplidRecord", splitNewRec)
      .then(function (response) { });
    //createSplidRecord
  };
  // console.log(dataVal, "dataVal");

  const deleteRow = (data) => {
    setLoadEachdata(false);
    axios
      .post(APIURL() + "billings/discardRecord", data)
      .then(function (response) {
        setLoadEachdata(true);
      });
  };

  const deleteMultipleRows = () => {
    setLoadEachdata(false);
    axios
      .post(APIURL() + "billings/discardRecords", changeRowValue)
      .then(function (response) {
        setLoadEachdata(true);
      });
  };

  const sendToCH = async (patientType) => {
    // console.log(patientType, 'patientType')
    setBackDropOpen(true);
    var data = {
      center: center,
      startDate: startDate,
      endDate: endDate,
      year: year,
      month: month,
      currentAction: payoutState.init.state,
      currentActionBy: user.user.id,
      nextAction: payoutState.ch_approval.state,
      status: 1,
      patientType: patientType,
    }
    await axios.post(APIURL() + 'billings/send-for-approval', data)
      .then(async () => {
        toast.success(payoutState.ch_approval.message);
        setBackDropOpen(false);
      })
    await getPayoutStatus()
  }

  var currentdate = new Date().toJSON().slice(0, 10);
  const handleChangeDate = (e) => {
    const { name, value } = e.target;
    if (name == "StartDate") {
      setStartDate(value)
      setEndDate(value);
    }
    if (name == "EndDate") {
      setEndDate(value)
    }
  }

  const getPayoutStatus = async () => {
    var data = {
      startDate: startDate,
      endDate: endDate,
      center: center,
      patientType: "OP",
    }
    await axios.post(APIURL() + 'billings/payout-status', data)
      .then(async (result) => {
        setPayoutDataStatus(result.data.data);
        console.log(payoutDataStatus, result, 'payoutDataStatus')
      })
  }
  const downloadExcel = () => {
    let StartDate = moment(new Date(startDate)).format('YYYY-MM-DD')
    let EndDate = moment(new Date(endDate)).format('YYYY-MM-DD')
    const newData = dataVal.map(row => {
      delete row.tableData
      let obj = {
        // "SlNo": row.SlNo,
        "MPI": row.MPI.includes("'") ? row.MPI : "'" + row.MPI,
        "Bill No": row.BillNo,
        "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
        "Bill Time": row.BillTime ? row.BillTime : "Invalid Time",
        "Patient Name": row.PatientName,
        "Item Group Name": row.ItemGroupName,
        "Item Name": row.ItemName,
        "Gross": row.Gross,
        "Tax": row.Tax,
        "Package Overrun": row.PackageOverrun,
        "Discount": row.Discount,
        "Discount Type": row.DiscountType,
        "Net": row.Net,
        "Doctor Payout": row.calculatedvalue != null && row.calculatedvalue != 0 ? row.calculatedvalue : 0,
        "Fixed Amount": row.fixedamount != null && row.fixedamount != 0 ? row.fixedamount : 0,
        "Ordering Physician": row.OrderingPhysician ? row.OrderingPhysician.includes('Dr') ? row.OrderingPhysician : 'Dr. ' + row.OrderingPhysician : row.OrderingPhysician,
        "Specialisation": row.Specialisation,
        "ConductedBy": row.ConductedBy ? row.ConductedBy.includes('Dr') ? row.ConductedBy : 'Dr. ' + row.ConductedBy : row.ConductedBy,
        "LT ID": row.CONDUCTED_BY_LOGIN_ID,
        "User": row.User,
        "Payable NonPayable": row.PayableNonPayable,
        // "Conducted By Final": row.ConductedByFinal ? row.ConductedByFinal.includes('Dr') ? row.ConductedByFinal : 'Dr. ' + row.ConductedByFinal : row.ConductedByFinal,
        "Admin Fee": row.ItemName != null && row.ItemName.includes('Consultation') ? row.consultation : row.ItemName != null && row.ItemName.includes('Follow') && row.followup != null ? row.followup : 0,
        "Type": row.doctorType ? row.doctorType : '',
        "Share": row.Share > 0 ? row.Share : 0,
        "OP/IPD": row.OPIPD != null ? row.OPIPD : 'OPD',
        "Center": row.Center,
        // "Region": row.region,
        "Month": row.month,
        "Year": row.year,
        "Exception": row.exceptionIssue,
      }
      return obj
    })
    // console.log(newData,"newData--------------------")
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
    //Download
    XLSX.writeFile(workBook, "OP-Payout_" + center + "_" + StartDate + "_to_" + EndDate + ".xlsx")
  }

  const updateBillItemData = async (newData, oldData) => {
    console.log(newData, oldData, 'function calls')
    var data = {
      payout_detail_id: oldData.id,
      newData: newData
    }
    await axios.post(APIURL() + 'billings/update-bill-item', data).catch((error) => {
      console.log(error)
    }).then((res) => {
      console.log(res, 'Updated bill item')
      toast.success("Bill item updated successfully");
    })
  }
  return (
    <>
      {" "}
      {/* <PTSidebar /> */}
      <Sidebar />
      <CssBaseline />
      <Container style={{ margin: "10px 0px 7px 235px", width: "80%" }}>
        <Navbar />
        <Typography
          variant="subtitle1"
          style={{ margin: "1rem", textAlign: "center" }}
        >
          OP-Payout Process
        </Typography>

        {!loading ? (
          <Skeleton animation="wave" variant="rect" width={950} height={400} />
        ) : (
          <div style={{ whiteSpace: "nowrap" }}>
            <Paper
              style={{ marginBottom: "10px", border: "1px solid #810e36" }}
            >
              <div style={{ padding: "8px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={center}
                        onChange={selectedCenter}
                        label=" Filter By Center"
                        fullWidth
                      >
                        <MenuItem value="Select the Center">
                          Filter By Center
                        </MenuItem>
                        {/* {user.user.id == 401 ? (
                          <MenuItem value="NORTH">NORTH</MenuItem>
                        ) : user.user.id == 400 ? (
                          <MenuItem value="SOUTH">SOUTH</MenuItem>
                        ) : user.user.id == 402 ? (
                          <MenuItem value="WEST">WEST</MenuItem>
                        ) : (
                          ""
                        )} */}
                        {centerName.length > 0 && centerName.map((ele) => {
                          return (<MenuItem value={ele.code}>{ele.name}</MenuItem>)
                        })}
                        {/* <MenuItem value="NORTH">NORTH</MenuItem>
                        <MenuItem value="JNR">JNR</MenuItem>
                        <MenuItem value="SOUTH">SOUTH</MenuItem>
                        <MenuItem value="WEST">WEST</MenuItem>
                        */}

                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={year}
                        onChange={selectedYear}
                        label=" Filter By Year"
                        fullWidth
                      >
                        <MenuItem value="Select the Year">
                          Filter By Year
                        </MenuItem>
                        <MenuItem value="2021">2021</MenuItem>
                        <MenuItem value="2022">2022</MenuItem>
                        <MenuItem value="2023">2023</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={month}
                        onChange={selectedMonth}
                        label=" Filter By Month"
                        fullWidth
                      >
                        <MenuItem value="Select the Month">
                          Filter By Month
                        </MenuItem>
                        <MenuItem value="JANUARY">JANUARY</MenuItem>
                        <MenuItem value="FEBRUARY">FEBRUARY</MenuItem>
                        <MenuItem value="MARCH">MARCH</MenuItem>
                        <MenuItem value="APRIL">APRIL</MenuItem>
                        <MenuItem value="MAY">MAY</MenuItem>
                        <MenuItem value="JUNE">JUNE</MenuItem>
                        <MenuItem value="JULY">JULY</MenuItem>
                        <MenuItem value="AUGUST">AUGUST</MenuItem>
                        <MenuItem value="SEPTEMBER">SEPTEMBER</MenuItem>
                        <MenuItem value="OCTOBER">OCTOBER</MenuItem>
                        <MenuItem value="NOVEMBER">NOVEMBER</MenuItem>
                        <MenuItem value="DECEMBER">DECEMBER</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <Input
                        type="date"
                        name="StartDate"
                        value={startDate}
                        max={currentdate}
                        onChange={(e) => handleChangeDate(e)}
                        style={{ color: "#000000", height: "55.98px" }}
                        className="form-control"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <Input
                        type="date"
                        label="From"
                        name="EndDate"
                        min={startDate}
                        max={currentdate}
                        value={endDate}
                        onChange={(e) => handleChangeDate(e)}
                        style={{ color: "#000000", height: "55.98px" }}
                        className="form-control"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} xl={3} lg={3} md={3}>
                    <Button
                      variant="contained"
                      onClick={LoadData}
                      fullWidth
                      style={{
                        height: "100%",
                        backgroundColor: "#810e36",
                        color: "white",
                      }}
                    >
                      Load Data{" "}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <MaterialTable
              columns={[
                // {
                //   title: "Split",
                //   field: "Action",
                //   filtering: false,
                //   render: (row) => (
                //     <Tooltip title="Split Record">
                //       <IconButton>
                //         <CallSplitIcon
                //           style={{
                //             transform: "rotate(90deg)",
                //             color: "#810e36",
                //           }}
                //           onClick={() => {
                //             setSplitDialog(true);
                //             onSplitRow(row);
                //           }}
                //         />
                //       </IconButton>
                //     </Tooltip>
                //   ),
                // },
                // {
                //   title: "Discard",
                //   field: "Action",
                //   filtering: false,
                //   render: (row) => (
                //     <Tooltip title="Discard Record">
                //       <IconButton>
                //         <RemoveCircleOutlineIcon
                //           style={{
                //             color: "#810e36",
                //           }}
                //           onClick={() => {
                //             setConfirmDialog({
                //               isOpen: true,
                //               title:
                //                 "Discarding record may result in some features unresponsive ?",
                //               subtitle:
                //                 "Are you sure you want to discard this record ?",
                //               onConfirm: () => {
                //                 deleteRow(row);
                //                 setConfirmDialog({ isOpen: false });
                //               },
                //             });
                //           }}
                //         />
                //       </IconButton>
                //     </Tooltip>
                //   ),
                // },

                // { title: "SlNo", field: "SlNo" },
                { title: "MPI", field: "MPI" },
                { title: "Patient Name", field: "PatientName" },
                { title: "Bill Time", field: "BillTime" },
                {
                  title: "Bill No",
                  field: "BillNo",
                  render: (row) => (
                    <div
                      style={{ cursor: "pointer", color: "red" }}
                      onClick={() => alert(row.BillNo)}
                    >
                      {row.BillNo}
                    </div>
                  ),
                },
                { title: "Bill Date", field: "BillDate" },
                { title: "Admin Fee", field: "AdminFee" },
                { title: "Item Group Name", field: "ItemGroupName" },
                { title: "Item Name", field: "ItemName" },
                { title: "Gross", field: "Gross" },
                { title: "Tax", field: "Tax" },
                { title: "Discount", field: "Discount" },
                { title: "Net", field: "Net" },
                {
                  title: "Share",
                  field: "Share",
                  render: (row) =>
                    row.Share == null ? <div>NA</div> : <div>{row.Share}</div>,
                },
                {
                  title: "Ordering Physician",
                  field: "OrderingPhysician",
                },
                { title: "Specialisation", field: "Specialisation" },
                {
                  title: "ConductedBy",
                  field: "ConductedBy",
                  render: (row) =>
                    row.ConductedBy == null ? <div>NA</div> : <div>{row.ConductedBy}</div>,
                },
                { title: "User", field: "User" },
                {
                  title: "Payable",
                  field: "PayableNonPayable",
                  lookup: {
                    1: "Yes",
                    0: "No",
                  },
                },
                {
                  title: "Conducted By Final",
                  field: "ConductedByFinal",
                },
                { title: "Location", field: "Center" },
              ]}
              // actions={[
              //   {
              //     tooltip: "Edit Records",
              //     icon: "edit",
              //     iconProps: { style: { color: "#810e36" } },
              //     onClick: (evt, data) => setOpenr(true),
              //   },
              //   {
              //     tooltip: "Discard Records",
              //     icon: () => (
              //       <RemoveCircleOutlineIcon style={{ color: "#810e36" }} />
              //     ),
              //     onClick: (evt, row) =>
              //       setConfirmDialog({
              //         isOpen: true,
              //         title:
              //           "Discarding record may result in some features unresponsive ?",
              //         subtitle:
              //           "Are you sure you want to discard this record ?",
              //         onConfirm: () => {
              //           deleteMultipleRows(row);
              //           setConfirmDialog({ isOpen: false });
              //         },
              //       }),
              //   },
              // ]}
              tableRef={tableRef}
              data={oboardVal}
              options={{
                // filtering: true,
                // selection: true,
                sorting: true,
                exportButton: true,
                exportAllData: true,
                exportCsv: (columns, data) => {
                  // console.log(columns, "col");
                  // Turn headers into array of strings
                  const headerRow = columns.map(col => {
                    // if(){}
                    // console.log(col.title, "TITLE");
                    if (typeof col.title === 'object') {
                      return col.title.props.text;
                    }
                    if (col.title != "Split" || col.title != "Discard" || col.title != "SlNo") {
                      return col.title;
                    }
                    // return col.title;
                  }).filter(col => col != "Split" && col != "Discard" && col != "SlNo");
                  // console.log(headerRow, "headerRow");
                  // Turn data into an array of string arrays, without the `tableData` prop
                  const dataRows = data.map(({ tableData, ...row }) => {
                    let object = {
                      "MPI": row.MPI,
                      "Bill No": row.BillNo,
                      "Bill Date": row.BillDate,
                      "Bill Time": row.BillTime,
                      "Patient Name": row.PatientName,
                      "Item Group Name": row.ItemGroupName,
                      "Item Name": row.ItemName,
                      "Gross": row.Gross,
                      "Tax": row.Tax,
                      "Discount": row.Discount,
                      "Net": row.Net,
                      "Admin Fee": row.AdminFee,
                      "Ordering Physician": row.OrderingPhysician,
                      "Specialisation": row.Specialisation,
                      "ConductedBy": row.ConductedBy,
                      "User": row.User,
                      "Share": row.Share,
                      "Payable NonPayable": row.PayableNonPayable,
                      "Conducted By Final": row.ConductedByFinal,
                      "Location": row.Center,
                    }
                    return Object.values(object);
                  });
                  // console.log(dataRows, "dataRows");
                  // Aggregate header data and 'body' data
                  // Mirror default export behavior by joining data via 
                  // the delimiter specified within material table (by default comma delimited)
                  const { exportDelimiter } = tableRef.current.props.options;
                  const delimiter = exportDelimiter ? exportDelimiter : ",";
                  const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
                  // console.log(csvContent, "csvContent");
                  // This mirrors the default export behavior where the 
                  // exported file name is the table title.
                  const csvFileName = tableRef.current.props.title;

                  // Allow user to download file as .csv
                  downloadCsv(csvContent, csvFileName);
                },
                fixedColumns: {
                    top: 1,
                    right: 0
                },
                maxBodyHeight: '500px',
                filtering: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              icons={{
                  Filter: "filter",
              }}
              onSelectionChange={(rows) => {
                setRowSelection(true);
                setChangeRowValue(rows);
              }}
              title="OP-Payout Billing Details"
            />

            {oboardVal && oboardVal.length > 1 ? (
              <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={calculatePayoutAll}
              // style={{float:"right"}}
              >
                Initiate Calculation{" "}
              </Button>
            ) : (
              ""
            )}
            {dataVal.length > 0 ?
              <>
                <MaterialTable
                  columns={[{
                    title: "Split",
                    field: "Action",
                    editable: 'never',
                    filtering: false,
                    hidden: (payoutDataStatus && payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approval.state) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.nextAction == payoutState.init.state))) ? false : true,
                    render: (row) => (
                      <Tooltip title="Split Record">
                        <IconButton>
                          <CallSplitIcon
                            style={{
                              transform: "rotate(90deg)",
                              color: "#810e36",
                            }}
                            onClick={() => {
                              setSplitDialog(true);
                              onSplitRow(row);
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    ),
                  },
                  // { title: "SlNo", field: "SlNo" },
                  { title: "MPI", field: "MPI", editable: 'never' },
                  {
                    title: "Bill No",
                    editable: 'never',
                    field: "BillNo",
                    render: (row) => (
                      <div
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => alert(row.BillNo)}
                      >
                        {row.BillNo}
                      </div>
                    ),
                  },
                  { title: "Bill Date", field: "BillDate", editable: 'never' },
                  {
                    title: "Bill Time", editable: 'never',
                    field: "BillTime",
                    render: (row) =>
                      <div>{row.BillTime == null ? moment(row.BillTime).format("LT") : row.BillTime}</div>,
                  },
                  // { title: "Bill Time", field: "BillTime" },
                  { title: "Patient Name", field: "PatientName", editable: 'never' },
                  { title: "Item Group Name", field: "ItemGroupName", editable: 'never' },
                  { title: "Item Name", field: "ItemName", editable: 'never' },
                  { title: "Gross", field: "Gross", editable: 'never' },
                  { title: "Tax", field: "Tax", editable: 'never' },
                  { title: "Package Overrun", field: "PackageOverrun", editable: 'never' },
                  { title: "Discount", field: "Discount", editable: 'never' },
                  { title: "Discount Type", field: "DiscountType", editable: 'never' },
                  {
                    title: "Admin Fee",
                    field: "AdminFee", editable: 'never',
                    render: (row) =>
                      // row.AdminFee == null ? <div>NA</div> : <div>{row.AdminFee}</div>,
                      row.ItemName.includes('Consultation') ? row.consultation : row.ItemName.includes('Follow') ? row.followup : 0,
                  },
                  {
                    title: "Type",
                    field: "doctorType", editable: 'never',
                  },
                  {
                    title: "OP/IPD",
                    field: "OPIPD", editable: 'never',
                    render: (row) =>
                      row.OPIPD == null ? <div>OPD</div> : <div>{row.OPIPD}</div>,
                  },
                  // { title: "Comment", field: "comment" },
                  {
                    title: "Ordering Physician",
                    field: "OrderingPhysician", editable: 'never',
                  },
                  { title: "Specialisation", field: "Specialisation", editable: 'never' },
                  { title: "User", field: "User", editable: 'never' },
                  { title: "Net", field: "Net", editable: 'never' },
                  {
                    title: "Conducted By",
                    field: "ConductedBy",
                    render: (row) =>
                      row.ConductedBy == null ? <div>NA</div> : <div>{row.ConductedBy}</div>,
                  },
                  {
                    title: "Conducted By Login Id",
                    field: "CONDUCTED_BY_LOGIN_ID",
                    render: (row) =>
                      row.CONDUCTED_BY_LOGIN_ID == null ? <div>NA</div> : <div>{row.CONDUCTED_BY_LOGIN_ID}</div>,
                  },
                  {
                    title: "Doctor Payout",
                    field: "doctorFee",
                    render: (row) =>
                      row.doctorFee != null ? (
                        <div style={{ cursor: "pointer", color: "green" }}>
                          {row.doctorFee.toFixed(2)}
                        </div>
                      ) : (
                        <div style={{ cursor: "pointer", color: "red" }}>
                          {row.doctorFee}
                        </div>
                      ),
                    type: 'numeric', validate: rowData => rowData.doctorFee == '' || rowData.doctorFee == null || parseFloat(rowData.doctorFee) <= 0 ? "Dr fee cannot be empty or less than 0" : "", render: (row) => row.doctorFee ? numberWithCommas(parseFloat(row.doctorFee).toFixed()) : 0
                  },
                  { title: "DP Team Remarks ", field: "Concatenate", validate: rowData => rowData.Concatenate !== '' && rowData.Concatenate !== null ? "" : "Remarks cannot be empty" },
                  {
                    title: "Share",
                    field: "Ratio",
                    type: 'numeric',
                    render: (row) =>
                      row.Ratio != null ? (
                        <div >
                          {row.Ratio}
                        </div>
                      ) : (
                        <div >
                          NA
                        </div>
                      ),
                    validate: rowData => parseFloat(rowData.Ratio) < 0 ? "Share cannot be less than 0" : "", render: (row) => row.Ratio ? numberWithCommas(parseFloat(row.Ratio).toFixed()) : 0
                  },
                  {
                    title: "Fixed Amount",
                    field: "doctorAmount",
                    type: 'numeric',
                    render: (row) =>
                      row.doctorAmount != null ? (
                        <div >
                          {row.doctorAmount}
                        </div>
                      ) : (
                        <div>
                          0
                        </div>
                      ),
                    validate: rowData => parseFloat(rowData.doctorAmount) < 0 ? "Fixed Amount cannot be less than 0" : "", render: (row) => row.doctorAmount ? numberWithCommas(parseFloat(row.doctorAmount).toFixed()) : 0
                  },
                  // {
                  //   title: "Payable",
                  //   field: "PayableNonPayable",
                  //   lookup: {
                  //     1: "Yes",
                  //     0: "No",
                  //   },
                  //   editable: 'never',
                  // },
                  // {
                  //   title: "Conducted By Final",
                  //   field: "ConductedByFinal",
                  // },
                  // { title: "OP/IPD", field: "OPIPD" },
                  { title: "Center", field: "Location", editable: 'never' },
                  { title: "Exception Issue", field: "vlookup", editable: 'never' },
                  // { title: "Month", field: "Month", editable: 'never' },
                  // { title: "Year", field: "Period", editable: 'never' },
                  ]}
                  actions={[
                    {
                      icon: () => <Button size="small" color="secondary" variant="contained" startIcon={<FileDownloadOutlinedIcon fontSize="x-large" />}> Export{" "}</Button>,// you can pass icon too
                      tooltip: "Export to Excel",
                      onClick: () => downloadExcel(),
                      isFreeAction: true
                    }
                  ]}
                  data={dataVal}
                  editable={(payoutDataStatus && payoutDataStatus.nextAction && payoutDataStatus.nextAction == payoutState.ch_approval.state) || (user.user.roles == 7 && (payoutDataStatus == null || (payoutDataStatus && payoutDataStatus.nextAction == payoutState.init.state))) ? {
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          const dataUpdate = [...dataVal];
                          const index = oldData.tableData.id;
                          dataUpdate[index] = newData;
                          console.log(oldData, newData, 'exception item update')
                          if (newData.Concatenate == '') {
                            setConfirmDialog({
                              isOpen: true,
                              title: "Alert",
                              subtitle: "Please Add Remarks",
                            });
                            reject()
                            return;
                          } else {
                            updateBillItemData(newData, oldData)
                            setDataVal([...dataUpdate]);
                            resolve();
                          }

                        }, 1000)
                      }),
                  } : {}}
                  icons={{
                      Filter: "filter",
                  }}
                  options={{
                    sorting: true,
                    fixedColumns: {
                        top: 1,
                        right: 0
                    },
                    maxBodyHeight: '500px',
                    filtering: true,
                    exportButton: false,
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.status === "Yes" ? "#ad4079" : "#FFF",
                    }),
                    // exportCsv: (columns, data) => {
                    //   // console.log(columns, "col");
                    //   // Turn headers into array of strings
                    //   const headerRow = columns.map(col => {
                    //     // if(){}
                    //     // console.log(col.title, "TITLE");
                    //     if (typeof col.title === 'object') {
                    //       return col.title.props.text;
                    //     }
                    //     // if (col.title != "Split" || col.title != "Discard" || col.title != "SlNo") {
                    //     //   return col.title;
                    //     // }
                    //     return col.title;
                    //   })
                    //   // .filter(col => col != "Split" && col != "Discard" && col != "SlNo");
                    //   // console.log(headerRow, "headerRow");
                    //   // Turn data into an array of string arrays, without the `tableData` prop
                    //   const dataRows = data.map(({ tableData, ...row }) => {
                    //     // console.log(row,"lkjhgfdsaqwertyuiopkjhgfd")
                    //     let object = {
                    //       "SlNo": row.SlNo,
                    //       "MPI": row.MPI.includes("'") ? row.MPI : "'" + row.MPI,
                    //       "Bill No": row.BillNo,
                    //       // "Bill Date": row.BillDate ? moment(row.BillDate).format('DD-MM-YYYY') : row.BillDate,
                    //       "Bill Date": row.BillDate ? row.BillDate : "Invalid Date",
                    //       // "Bill Time": row.BillTime ? moment(row.BillTime).format("LT") : row.BillTime,
                    //       "Bill Time": row.BillTime ? row.BillTime : "Invalid Time",
                    //       "Patient Name": row.PatientName,
                    //       "Item Group Name": row.ItemGroupName,
                    //       "Item Name": row.ItemName,
                    //       "Gross": row.Gross,
                    //       "Tax": row.Tax,
                    //       "Package Overrun": row.PackageOverrun,
                    //       "Discount": row.Discount,
                    //       "Discount Type": row.DiscountType,
                    //       "Net": row.Net,
                    //       "Doctor Payout": row.calculatedvalue != null && row.calculatedvalue != 0 ? row.calculatedvalue : 0,
                    //       "Fixed Amount": row.fixedamount != null && row.fixedamount != 0 ? row.fixedamount : 0,
                    //       "Ordering Physician": row.OrderingPhysician ? row.OrderingPhysician.includes('Dr') ? row.OrderingPhysician : 'Dr. ' + row.OrderingPhysician : row.OrderingPhysician,
                    //       // "Ordering Physician": row.OrderingPhysician == "" &&  row.OrderingPhysician == null ? row.OrderingPhysician : row.OrderingPhysician.includes('Dr') ? row.OrderingPhysician : 'Dr. ' + row.OrderingPhysician,
                    //       "Specialisation": row.Specialisation,
                    //       // "ConductedBy": row.ConductedBy == "" &&  row.ConductedBy == null ? row.ConductedBy : row.ConductedBy.includes('Dr') ? row.ConductedBy : 'Dr. ' + row.ConductedBy,
                    //       "ConductedBy": row.ConductedBy ? row.ConductedBy.includes('Dr') ? row.ConductedBy : 'Dr. ' + row.ConductedBy : row.ConductedBy,
                    //       "User": row.User,
                    //       "Payable NonPayable": row.PayableNonPayable,
                    //       "Conducted By Final": row.ConductedByFinal ? row.ConductedByFinal.includes('Dr') ? row.ConductedByFinal : 'Dr. ' + row.ConductedByFinal : row.ConductedByFinal,
                    //       // "Conducted By Final":row.ConductedByFinal == "" &&  row.ConductedByFinal == null ?row.ConductedByFinal: row.ConductedByFinal.includes('Dr') ? row.ConductedByFinal : 'Dr. ' + row.ConductedByFinal,
                    //       "Admin Fee": row.ItemName != null && row.ItemName.includes('Consultation') ? row.consultation : row.ItemName != null && row.ItemName.includes('Follow') && row.followup != null ? row.followup : 0,
                    //       "Type": row.doctorType ? row.doctorType : '',
                    //       "Share": row.Share > 0 ? row.Share : 0,
                    //       "OP/IPD": row.OPIPD != null ? row.OPIPD : 'OPD',
                    //       "Center": row.Center,
                    //       "Region": row.region,
                    //       "Month": row.month,
                    //       "Year": row.year,

                    //     }
                    //     // console.log(object, "dataRows");
                    //     return Object.values(object);
                    //   });
                    //   // Aggregate header data and 'body' data
                    //   // Mirror default export behavior by joining data via 
                    //   // the delimiter specified within material table (by default comma delimited)
                    //   const { exportDelimiter } = tableRef.current.props.options;
                    //   const delimiter = exportDelimiter ? exportDelimiter : ",";
                    //   const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
                    //   // console.log(csvContent, "csvContent");
                    //   // This mirrors the default export behavior where the 
                    //   // exported file name is the table title.
                    //   const csvFileName = tableRef.current.props.title;

                    //   // Allow user to download file as .csv
                    //   downloadCsv(csvContent, csvFileName);
                    // },
                    pageSize: 5,
                    pageSizeOptions: [5, 10, 50, 100, 200, 500],
                    search: true,
                    exportAllData: true,
                  }}
                  onSelectionChange={(rows) => {
                    setRowSelection(true);
                    setChangeRowValue(rows);
                  }}
                  title="OP-Payout Calculation Details"
                />
                {user.user.activityAssigments.filter((elem) => elem.activityId === 11).find(item => item.RoleId === user.user.roles) ?
                  payoutDataStatus == null ?
                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                      {/* <Button
                        style={{ marginLeft: "10px" }}
                        size="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => sendToCH('OP')}
                      >
                        Send to Center Head
                      </Button> */}
                    </div>
                    : ""
                  : ""}
              </>
              :
              ""
            }
          </div>
        )}
      </Container>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Edit Doctor Details
        </DialogTitle>
        <DialogContent dividers>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-outlined-label">
              Select Doctor Name
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="doctorname"
              value={rowDetail.doctorname}
              onChange={handleInputChange}
              label="Select Role"
            >
              {doctorList &&
                doctorList.map((opt) => (
                  <MenuItem key={opt.id} value={opt.name}>
                    {opt.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <label>
            <b>Ratio</b>
          </label>
          <TextField
            id="Payout"
            label="Ratio"
            variant="outlined"
            fullWidth
            name="ratio"
            value={rowDetail.ratio}
            onChange={handleInputChange}
            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />

          <label>
            <b> Percentage</b>
          </label>
          <TextField
            id="Payout"
            label="Percentage"
            variant="outlined"
            fullWidth
            name="percentage"
            value={rowDetail.percentage}
            onChange={handleInputChange}
            //   onChange={(e) => setPayout(e.target.value)}

            style={{ marginBottom: "20px" }}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlePayoutEdit}
            color="primary"
            variant="contained"
          >
            Update
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      //splitDialog
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setSplitDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={splitDialog}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          {spitRecord.ConductedBy}
        </DialogTitle>
        <Grid container>
          <Grid item xs={12} xl={6} md={6} lg={6} sm={12}>
            <DialogContent dividers>
              <Typography>MPI: {spitRecord.MPI}</Typography>
              <Typography>BillDate: {spitRecord.BillDate}</Typography>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Doctor Name
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="doctorname"
                  value={rowDetail.doctorname}
                  onChange={handleInputChange}
                  label="  Select Doctor Name"
                >
                  {doctorList &&
                    doctorList.map((opt) => (
                      <MenuItem key={opt.id} value={opt.name}>
                        {opt.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <label>
                <b>Ratio</b>
              </label>
              <TextField
                id="Payout"
                label="Ratio"
                variant="outlined"
                fullWidth
                name="ratio"
                value={rowDetail.ratio}
                onChange={handleInputChange}
                style={{ marginBottom: "20px" }}
                autoComplete="off"
              />
            </DialogContent>
          </Grid>
          <Grid
            item
            xs={12}
            xl={6}
            md={6}
            lg={6}
            sm={12}
            style={{ height: "300px", overflowY: "auto" }}
          >
            <MaterialTable
              columns={[
                // { title: "SlNo", field: "SlNo" },
                { title: "MPI", field: "MPI" },
                { title: "Bill No", field: "BillNo" },
                { title: "Patient Name", field: "PatientName" },
                { title: "ConductedBy", field: "ConductedBy" },
                { title: "Share", field: "Share" },
                { title: "Item Group Name", field: "ItemGroupName" },
                { title: "Item Name", field: "ItemName" },
                { title: "Gross", field: "Gross" },
                { title: "Net", field: "Net" },
              ]}
              data={splitNewRec}
              title="Split Rows"
            />
          </Grid>
        </Grid>

        <Button
          color="secondary"
          onClick={() => {
            addRowData(spitRecord);
          }}
        >
          Add
        </Button>
        <DialogActions>
          {splitNewRec && splitNewRec.length > 0 ? (
            <Button
              autoFocus
              onClick={() => {
                changeSplitRecord();
              }}
              color="secondary"
              variant="contained"
            >
              Submit
            </Button>
          ) : (
            ""
          )}

          <Button
            autoFocus
            onClick={() => {
              setSplitDialog(false);
            }}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        className={backClasses.backdrop}
        open={backDropOpen}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}


