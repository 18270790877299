import React from 'react'

function NotAuthorized() {
    return (
        <div className="backgroundImg">
            <div className="Container Accessdenied">
           <h2> NOT AUTHORIZED!</h2>
            </div>
        </div>
    )
}

export default NotAuthorized
