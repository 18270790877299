import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import Button from "@material-ui/core/Button";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MaterialTable from "material-table";
import Skeleton from "@material-ui/lab/Skeleton";

import { Link } from "react-router-dom";
//ui components
import Navbar from "../Components/Navbar";
import DoctorSidebar from "../Components/DoctorSidebar";
import axios from "axios";
import { onboardById } from "../actions/onboardActions";
import { listusers } from "../actions/userActions";
import { payoutCalculation } from "../Pages/Calculations/payoutCalc";
import { payoutCalcRadiology } from "../Pages/Calculations/payoutCalcRadiology";
import { payoutCalcOtherCharges } from "../Pages/Calculations/payoutOtherCharges";
import { payoutCalcNonOt } from "../Pages/Calculations/payoutNonOt";
import { APIURL } from "../CommomMethods/Fetch";
import { numberWithCommas } from "../actions/createPositionActions";

const styleSheet = makeStyles((theme) => ({
  iconButtonStyle: {
    float: "right",
  },
  marginforMaster: {
    marginTop: "30px",
  },
}));

export default function BillingDetails() {
  const classes = styleSheet();
  const dispatch = useDispatch();
  const [preview, setPreview] = useState(true);
  const [loading, setLoading] = useState(false);

  const [intVal, setIntVal] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [progress, setProgress] = useState(false);
  const [rows, setRows] = useState([]);
  const [initCalOP, setInitCalOP] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [reload, setReload] = useState(false);

  const [opPayout, setOpPayout] = useState([]);
  const [opConNorth, setOpConNorth] = useState([]);
  const [opConSouthOne, setOpConSouthOne] = useState([]);
  const [opConSouthTwo, setOpConSouthTwo] = useState([]);
  const [opConSouthThree, setOpConSouthThree] = useState([]);
  const [opConSouthFour, setOpConSouthFour] = useState([]);
  const [opRadNorthPayout, setRadNorthOpPayout] = useState([]);
  const [opRadSouthPayout, setRadSouthOpPayout] = useState([]);
  const [opRadwestPayout, setRadWestOpPayout] = useState([]);
  const [opNonNorthPayout, setNonNorthOpPayout] = useState([]);
  const [opNonSouthPayout, setNonSouthOpPayout] = useState([]);
  const [opNonwestPayout, setNonWestOpPayout] = useState([]);
  const [opOcNorth, setOpOcNorth] = useState([]);
  const [opOcsouth, setOpOcSouth] = useState([]);
  const [opOcwest, setOpOcWest] = useState([]);
  const [opPay, setOpPay] = useState([]);
  const [opPayDisp, setOpPayDisp] = useState([]);
  const [opPayoutRowId, setOpPayoutRowId] = useState([]);
  const [opTotPayout, setOpTotPayout] = useState([]);
  const [doctorOpCommercials, setdoctorOpCommercials] = useState([]);
  const [data, setData] = useState("Select the value");
  const onboardId = useSelector((state) => state.onboardId);
  const { oboard } = onboardId;
  console.log(oboard, "@@@@@@");
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  useEffect(() => {
    setLoading(false);
    axios
      .get(APIURL() + "billings/findAllBillingDet")
      .then((res) => res.data)
      .then((rows) => {
        setRows(rows.data);
        var k = rows.data.filter((z) => z.ConductedBy === user.user.username);
        setFilteredRows(k);
        setLoading(true);
      });
  }, [reload]);

  console.log(filteredRows, "ABC");
  
    useEffect(() => {
      dispatch(onboardById(user.user.id));
    }, [reload]);


  function selectedDropdownvalue(e) {
    const { name, value } = e.target;
    setData(value);
    setInitCalOP([]);
    console.log(initCalOP, "12345");
  }

  useEffect(() => {
    setInitCalOP([]);
    setOpTotPayout([]);
  }, [data]);

  const [valRec, setValRec] = useState([]);
  const [loadVal, setLoadVal] = useState(false);
  var sumArray = [];
  var Val = [];
  var sum = 0;
  function calculatePayoutAll() {
    setLoadVal(false);
    var filterdata = filteredRows.filter(z => data.includes(z.ItemGroupName));
    Val = payoutCalculation.initiateCalOpCalc(filterdata, oboard, data);
    if (Val.length > 0) {
      Val && Val.map((ele) => {
        sumArray.push(ele.CalculatedValue);
      })
      setTimeout(() => {
        setValRec(Val);
      }, 2000);
      setLoadVal(true);
    }

    for (let i = 0; i < sumArray.length; i++) {
      sum += sumArray[i];
      setOpTotPayout(sum);
    }
    console.log(sum, "+++===");
    console.log(sumArray, "   let sumArray = [];");
  }


  useEffect(() => {
    setInitCalOP(valRec);
  }, [loadVal, valRec]);
  // console.log(initCalOP.length, "111111111pp123");

  const calculatePayoutTot = () => {
    let sum = 0;
    for (let i = 0; i < sumArray.length; i++) {
      sum += sumArray[i];
      setOpTotPayout(sum);
    }
    console.log(sum, "+++===");
  };

  const calculatePayout = (row) => {
    setReload(true);
    // var k = payoutCalculation.northRegionOpCalculation(row, oboard);
    if (row.ItemGroupName === "Radiology") {
      var a = payoutCalcRadiology.northRadiologyCalculation(row, oboard);
      var b = payoutCalcRadiology.westRadiologyOneCalculation(row, oboard);
      var c = payoutCalcRadiology.southRadiologyOneCalculation(row, oboard);
      setRadNorthOpPayout([...opRadNorthPayout, a]);
      setRadSouthOpPayout([...opRadSouthPayout, c]);
      setRadWestOpPayout([...opRadwestPayout, b]);
      setOpPay([...opPay, a]);
      setOpPayoutRowId([
        ...opPayoutRowId,
        {
          id: row.id,
          RadNorth: a,
          RadSouth: c,
          Radwest: b,
          commercialTerm: oboard,
        },
      ]);
    } else if (row.ItemGroupName === "Consultation") {
      var k = payoutCalculation.northRegionOpCalculation(row, oboard);
      var l = payoutCalculation.southRegionOneOpCalculation(row, oboard);
      var m = payoutCalculation.southRegionTowOpCalculation(row, oboard);
      var n = payoutCalculation.southRegionThreeOpCalculation(row, oboard);
      var o = payoutCalculation.southRegionFourOpCalculation(row, oboard);
      setOpConNorth([...opConNorth, k]);
      setOpConSouthOne([...opConSouthOne, l]);
      setOpConSouthTwo([...opConSouthTwo, m]);
      setOpConSouthThree([...opConSouthThree, n]);
      setOpConSouthFour([...opConSouthFour, o]);
      setOpPayoutRowId([
        ...opPayoutRowId,
        {
          id: row.id,
          north: k,
          southone: l,
          southtwo: m,
          souththree: n,
          southfour: o,
        },
      ]);
      setOpPayout([...opPayout, k]);
      setOpPay([...opPay, k]);
    } else if (row.ItemGroupName === "Non OT") {
      var p = payoutCalcNonOt.northNonOtCalculation(row, oboard);
      var q = payoutCalcNonOt.westNonOtCalculation(row, oboard);
      var r = payoutCalcNonOt.southNonOtCalculation(row, oboard);
      setNonNorthOpPayout([...opNonNorthPayout, p]);
      setNonSouthOpPayout([...opNonSouthPayout, r]);
      setNonWestOpPayout([...opNonwestPayout, q]);
      setOpPay([...opPay, a]);
      setOpPayoutRowId([
        ...opPayoutRowId,
        { id: row.id, NonOtNorth: p, NonOtSouth: q, NonOtWest: r },
      ]);
    } else if (row.ItemGroupName === "Other Charges") {
      var s = payoutCalcOtherCharges.northOtherChargesCalculation(row, oboard);
      var t = payoutCalcOtherCharges.southOtherChargesCalculation(row, oboard);
      var u = payoutCalcOtherCharges.westOtherChargesCalculation(row, oboard);
      setOpOcNorth([...opOcNorth, s]);
      setOpOcSouth([...opOcsouth, t]);
      setOpOcWest([...opOcwest, u]);
      setOpPayout([...opPayout, s]);
      setOpPay([...opPay, s]);
      setOpPayoutRowId([
        ...opPayoutRowId,
        { id: row.id, nothOc: s, southOc: t, westOc: u },
      ]);
    } else {
      alert("Incorrect data!");
    }

    const postdata = {
      doctorId: row.id,
      month: "12",
      consNorth: k,
      conssouthone: l,
      conssouthtwo: m,
      conssouththree: n,
      conssouthfour: o,
      conswestone: l,
      conswesttwo: m,
      conswestthree: o,
      radone: a,
      radtwo: c,
      radthree: b,
      nonotnorth: p,
      nonotsouth: q,
      nonotwest: r,
      ocnorth: s,
      ocsouth: t,
      ocwest: u,
      ocmisc: u,
      Status: 1,
    };
    // console.log(postdata, "%^^^");
    axios.post(APIURL() + "billings/calCreate/", postdata).then((res) => { });
    // update db
    const obj = {
      status: "P",
    };
    axios
      .put(APIURL() + "billings/centerupdate/" + row.id, obj)
      .then((res) => { });

    const calcdata = {
      calculationStatus: 1,
      calculatedValue: 630,
    };
    axios
      .put(APIURL() + "billings/centerOpBillingupdate/" + row.id, calcdata)
      .then((res) => { });
  };
  // console.log(opPayoutRowId, "lll----");
  // const calculatePayout = (row) => {
  //   setReload(true);
  //   // var k = payoutCalculation.rowLevelPayoutCalculation(row, oboard);
  //   var k = payoutCalculation.northRegionOpCalculation(row, oboard);
  //   setOpPayout([...opPayout, k]);
  //   setOpPay([...opPay,k]);
  //   setOpPayoutRowId([...opPayoutRowId, { id: row.id, value: k }]);
  // }
  // const calculateRadiology = () => {

  // }



  return (
    <>
      <DoctorSidebar />
      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />
            <Grid container>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{ margin: "15px" }}
                  >
                    <b>Filter By-</b>
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={data}
                    onChange={selectedDropdownvalue}
                    label="select the value"
                    fullWidth
                  >
                    <MenuItem value="Select the value">
                      Select the value
                    </MenuItem>
                    <MenuItem value="Radiology North">Radiology North</MenuItem>
                    <MenuItem value="Radiology South">Radiology South</MenuItem>
                    <MenuItem value="Radiology West">Radiology West</MenuItem>
                    <MenuItem value="Consultation North">
                      Consultation North{" "}
                    </MenuItem>
                    <MenuItem value="Consultation South One">
                      Consultation South One
                    </MenuItem>
                    <MenuItem value="Consultation South Two">
                      Consultation South Two
                    </MenuItem>
                    <MenuItem value="Consultation South Three">
                      Consultation South Three
                    </MenuItem>
                    <MenuItem value="Consultation South Four">
                      Consultation South Four
                    </MenuItem>
                    <MenuItem value="Consultation West One">
                      Consultation West One{" "}
                    </MenuItem>
                    <MenuItem value="Consultation West Two">
                      Consultation West Two
                    </MenuItem>
                    <MenuItem value="Consultation West Three">
                      Consultation West Three
                    </MenuItem>
                    <MenuItem value="Non OT North">Non OT North</MenuItem>
                    <MenuItem value="Non OT South">Non OT South</MenuItem>
                    <MenuItem value="Non OT West">Non OT West</MenuItem>
                    <MenuItem value="Other Charges North">
                      Other Charges North
                    </MenuItem>
                    <MenuItem value="Other Charges South">
                      Other Charges South
                    </MenuItem>
                    <MenuItem value="Other Charges West">
                      Other Charges West
                    </MenuItem>
                    <MenuItem value="Other Charges Misc">
                      Other Charges Misc
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>



              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.marginforMaster}
              >
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={
                    calculatePayoutAll
                  }
                // style={{float:"right"}}
                >
                  Initiate Calculation{" "}
                </Button>
                {!loading && !loadVal ? (
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width={950}
                    height={400}
                  />
                ) : (
                  <MaterialTable
                    columns={[
                      { title: "SlNo", field: "SlNo" },
                      { title: "MPI", field: "MPI" },
                      { title: "Patient Name", field: "PatientName" },
                      { title: "Bill Time", field: "BillTime" },
                      {
                        title: "Bill No",
                        field: "BillNo",
                        render: (row) => (
                          <div
                            style={{ cursor: "pointer", color: "red" }}
                            onClick={() => alert(row.BillNo)}
                          >
                            {row.BillNo}
                          </div>
                        ),
                      },
                      { title: "Bill Date", field: "BillDate" },
                      { title: "Admin Fee", field: "AdminFee" },
                      { title: "Item Group Name", field: "ItemGroupName" },
                      { title: "Item Name", field: "ItemName" },
                      { title: "Gross", field: "Gross" },
                      { title: "Tax", field: "Tax" },
                      { title: "Discount", field: "Discount" },
                      { title: "Net", field: "Net" },
                      // {
                      //   title: "Commercial Term",
                      //   field: "commercialterm",
                      //   filtering: false,
                      //   render: (row) => (
                      //     <div>
                      //       {JSON.stringify(row.ItemGroupName).includes(
                      //         "Consulting"
                      //       )
                      //         ? "ele.commercialTerm"
                      //         : "bye"}
                      //     </div>
                      //   ),
                      // },
                      // {
                      //   title: "Commercial Term",
                      //   field: "commercialterm",
                      //   filtering: false,
                      //   render: (row) => (
                      //     oboard && oboard.doctorOpCommercial && oboard.doctorOpCommercial.map((ele) => {
                      //       <div>
                      //       {JSON.stringify(row.ItemGroupName).includes("Consulting") ? ele.commercialterm : "bye"}
                      //     </div>
                      //     })

                      //   ),
                      // },
                      {
                        title: "Calculate",
                        field: "calculate",
                        render: (row) => (
                          <div>
                            {!opPayoutRowId.includes(row.id) ? (
                              <Button
                                size="small"
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  calculatePayout(row);
                                }}
                              >
                                Calculate{" "}
                              </Button>
                            ) : (
                              opPayout
                            )}
                          </div>
                        ),
                      },
                      { title: "Calculated Value", field: "CalculatedValue" },
                      {
                        title: "Calculated Value",
                        field: "CalculatedValue",
                        filtering: false,
                        render: (row) =>
                          opPayoutRowId &&
                          opPayoutRowId.map((ele) => {
                            return (
                              <div>
                                {ele.id === row.id && data === "Radiology North"
                                  ? ele.RadNorth
                                  : ele.id === row.id &&
                                    data === "Radiology South"
                                    ? ele.RadSouth
                                    : ele.id === row.id &&
                                      data === "Radiology West"
                                      ? ele.Radwest
                                      : ele.id === row.id &&
                                        data === "Consultation North"
                                        ? ele.north
                                        : ele.id === row.id &&
                                          data === "Consultation South One"
                                          ? ele.southone
                                          : ele.id === row.id &&
                                            data === "Consultation South Two"
                                            ? ele.southtwo
                                            : ele.id === row.id &&
                                              data === "Consultation South Three"
                                              ? ele.souththree
                                              : ele.id === row.id &&
                                                data === "Consultation South Four"
                                                ? ele.southfour
                                                : ele.id === row.id &&
                                                  data === "Consultation West One"
                                                  ? ele.southone
                                                  : ele.id === row.id &&
                                                    data === "Consultation West Two"
                                                    ? ele.southtwo
                                                    : ele.id === row.id &&
                                                      data === "Consultation West Three"
                                                      ? ele.souththree
                                                      : ele.id === row.id && data === "Non OT North"
                                                        ? ele.NonOtNorth
                                                        : ele.id === row.id && data === "Non OT South"
                                                          ? ele.NonOtSouth
                                                          : ele.id === row.id && data === "Non OT West"
                                                            ? ele.NonOtWest
                                                            : ele.id === row.id &&
                                                              data === "Other Charges North"
                                                              ? ele.nothOc
                                                              : ele.id === row.id &&
                                                                data === "Other Charges South"
                                                                ? ele.southOc
                                                                : ele.id === row.id &&
                                                                  data === "Other Charges West"
                                                                  ? ele.westOc
                                                                  : ele.id === row.id &&
                                                                    data === "Other Charges Misc"
                                                                    ? ele.westOc
                                                                    : " "}
                              </div>
                            );
                          }),
                      },
                      { title: "Share", field: "Share" },
                      // { title: "Return Amount", field: "ReturnAmount" },
                      {
                        title: "Ordering Physician",
                        field: "OrderingPhysician",
                      },
                      { title: "Specialisation", field: "Specialisation" },
                      { title: "ConductedBy", field: "ConductedBy" },
                      { title: "User", field: "User" },
                      {
                        title: "Payable NonPayable",
                        field: "PayableNonPayable",
                      },
                      {
                        title: "Conducted By Final",
                        field: "ConductedByFinal",
                      },
                      { title: "Location", field: "Location" },
                    ]}
                    data={initCalOP.length > 0 ? initCalOP.filter((z) => data.includes(z.ItemGroupName)) : filteredRows.filter((z) => data.includes(z.ItemGroupName))}

                    options={{
                      // filtering: true,
                      sorting: true,
                      exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    title="Billing Details"
                  />
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.marginforMaster}
                >
                  <label>
                    <b>Total Payout: Rs. {opTotPayout ? numberWithCommas(opTotPayout) : ""}/-</b>{" "}
                  </label>
                  {/* <Button
                    style={{ float: "right", margin: "1rem" }}
                    size="small"
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      calculatePayoutTot();
                    }}
                  >
                    Calculate All
                  </Button> */}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
