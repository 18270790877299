import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  Area,
  Line,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  ComposedChart,
  Sector,
} from "recharts";
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p
          className="label"
          style={{ color: "black", backgroundColor: "white" }}
        >{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};
export default function ComposedChart1({ datalist }) {
  var totaldata = datalist && datalist.data && datalist.data.mergeResult;
  return (
    <>
      <div>
        <ComposedChart width={350} height={270} data={totaldata}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <CartesianGrid stroke="#f5f5f5" />
          <Area
            type="monotone"
            dataKey="value"
            fill="#8884d8"
            stroke="#8884d8"
          />
          <Bar dataKey="name" barSize={20} fill="#413ea0" />
          {/* <Line type="monotone" dataKey="value" stroke="#ff7300" /> */}  
        </ComposedChart>
      </div>
    </>
  );
}
