
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Container, Row, Col, Card } from 'react-bootstrap'
import BillingPieSummary from '../../Components/BillingPieSummary'
import userImg from './../../images/user.png'
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Navbar from '../../Components/Navbar'
import { documentlist } from "../../actions/documentActions";
import SidebarRD from "../../Components/Rd-sidebar";
import Timeline from '../../Components/Timeline'
import { onboardById } from '../../actions/onboardActions'
import { useLocation } from "react-router-dom";
import { onboardState } from "../../CommomMethods/States";
import MaterialTable from "material-table";
import { APIURL } from "../../CommomMethods/Fetch";


function DoctorProfileRD({ reqTableData }) {
  const location = useLocation();
  const [newVis, setNewVis] = useState("hiddenDiv")
  const [ongVis, setOngVis] = useState("hiddenDiv")
  const [compVis, setCompVis] = useState("hiddenDiv")
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalShow, setModalShow] = React.useState(false);
  const labelStyle = { paddingBottom: "5px", color: "black" };
  const infoStyle = { padding: "5px", border: "2px solid rgb(133, 133, 133, 0.4)", fontWeight: "lighter", borderRadius: "5px" };
  // const percentage = 66;
  // const info = "Clearing Exceptions";
  const onboardId = useSelector((state) => state.onboardId);
  const { oboard } = onboardId;
  const [doctorDetail, setDoctorDetail] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;

  const listDoc = useSelector((state) => state.listDoc);
  const { list } = listDoc;


  const cardStyle = {
    background: 'transparent',
    border: '0px',
  }
  // const infoStyle = {
  //     border: '2px solid #858585',
  // }
  const containerStyle = {
    margin: '0px',
  }
  const subTitle = {
    fontSize: "13px"
  }
  const percentage = 66;
  const info = "Clearing Exceptions";

  function tabChange(stat) {
    if (stat == "new") {
      if (newVis == "hiddenDiv") {
        setNewVis("visibleDiv")
      }
      else {
        setNewVis("hiddenDiv")
      }

    }
  }
  useEffect(() => {
    dispatch(onboardById(location && location.state && location.state.id));
  }, [dispatch])

  useEffect(() => {
    dispatch(documentlist(location && location.state && location.state.id));
  }, [dispatch])

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: 'RequestId',
        field: 'requestId',
        width: 100,
      },
      {
        label: 'Request Type',
        field: 'requestType',
        width: 100,
      },
      {
        label: 'Request Raised By',
        field: 'requestRaisedBy',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Date',
        field: 'date',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'Subject',
        field: 'subject',
        sort: 'disabled',
        width: 100,
      },
    ],

    rows: reqTableData

  });
  return (
    <>

      <SidebarRD />
      <div className="main-content side-content pt-0">
        <div className="container-fluid" style={{ margin: "0 auto", backgroundColor: "white" }}>
          <div className="inner-body">

            <Navbar />

            <br />
            <hr />

            <div className="col-xl-12 col-md-12 pt-4" >
              <Row >
                <Col xs={2} md={2}>
                  {list.data && list.data.filter(z => z.docTitle === "Passport Size Photograph").map((ele) => (

                    <img class="img-responsive" src={"documents/download/" + ele.url} style={{ borderRadius: "49%", margin: "1rem", width: "500px",height:"60%" }} />
                  ))}
                  <h4 style={{ marginLeft: "3rem",marginTop:"15%"  }}>Dr. {oboard.doctorCommercial && oboard.doctorCommercial[0].fullname} </h4>

                </Col>
                <Col xs={8} md={{ span: 8, offset: 1 }}>
                  <Row>
                    <Col xs={6} md={6} style={{ padding: "10px" }}>
                      <h6 style={labelStyle}>Name</h6>
                      <h6 style={infoStyle}>Dr. {oboard.doctorCommercial && oboard.doctorCommercial[0].fullname}</h6>
                    </Col>
                    <Col xs={6} md={6} style={{ padding: "10px" }}>
                      <h6 style={labelStyle}>ID</h6>

                      <h6 style={infoStyle}>{oboard.doctorDetails && oboard.doctorDetails.LTId ? oboard.doctorDetails.LTId : "NA"}</h6>
                    </Col>
                    <Col xs={6} md={6} style={{ padding: "10px" }}>
                      <h6 style={labelStyle}>Phone Number</h6>
                      <h6 style={infoStyle}>{oboard.doctorCommercial && oboard.doctorCommercial[0].mobile}</h6>
                    </Col>
                    <Col xs={6} md={6} style={{ padding: "10px" }}>
                      <h6 style={labelStyle}>Official Email</h6>
                      <h6 style={infoStyle}>{oboard.doctorDetails && oboard.doctorDetails.email}</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} md={6} style={{ padding: "10px" }}>
                      <h6 style={{ paddingBottom: "5px" }}>Specialization</h6>
                      <h6 style={{ padding: "5px", border: "2px solid rgb(133, 133, 133, 0.4)", fontWeight: "lighter", borderRadius: "5px" }}>{oboard.doctorCommercial && oboard.doctorCommercial[0].departmentlist}</h6>
                    </Col>
                    <Col xs={6} md={6} style={{ padding: "10px" }}>
                      <h6 style={{ paddingBottom: "5px" }}>Current Designation</h6>
                      <h6 style={{ padding: "5px", border: "2px solid rgb(133, 133, 133, 0.4)", fontWeight: "lighter", borderRadius: "5px" }}>{oboard.doctorCommercial && oboard.doctorCommercial[0].designationlist}</h6>
                    </Col>
                    <Col xs={6} md={6} style={{ padding: "10px" }}>
                      <h6 style={{ paddingBottom: "5px" }}>Nature of Employment</h6>
                      <h6 style={{ padding: "5px", border: "2px solid rgb(133, 133, 133, 0.4)", fontWeight: "lighter", borderRadius: "5px" }}>{oboard.doctorCommercial && oboard.doctorCommercial[0].designationlist}</h6>
                    </Col>
                    <Col xs={6} md={6} style={{ padding: "10px" }}>
                      <h6 style={{ paddingBottom: "5px" }}>PAN Number</h6>
                      <h6 style={{ padding: "5px", border: "2px solid rgb(133, 133, 133, 0.4)", fontWeight: "lighter", borderRadius: "5px" }}>{oboard.doctorCommercial && oboard.doctorCommercial[0].pancardno}</h6>
                    </Col>
                  </Row>
                  <Row>

                    <Col xs={6} md={6} style={{ padding: "10px", marginBottom: "1rem" }}>
                      <h6 style={{ paddingBottom: "5px" }}>Bank Name</h6>
                      <h6 style={{ padding: "5px", border: "2px solid rgb(133, 133, 133, 0.4)", fontWeight: "lighter", borderRadius: "5px" }}>{oboard.doctorCommercial && oboard.doctorCommercial[0].BankName}</h6>
                    </Col>
                  </Row>
                </Col>
              </Row>


              {/* <Row>
                                <Col>
                                    <Card style={{ padding: "5px" }}>
                                        <Card.Header style={{ fontSize: "19px", fontWeight: "bold", textAlign: "center" }}>
                                            Doctor Onboard Information
							</Card.Header>
                                        <Card.Body>
                                            <Row>

                                                <Col md={6}>
                                                    <Card>
                                                        <Card.Header>
                                                            <h5 style={{margin:"1rem", textAlign:"center"}}>Document Approval Status</h5>
                                                        </Card.Header>
                                                        <Card.Body style={{ height: "180px", border: "2px solid rgb(0,0,0, 0.4)", borderRadius: "5px" }}>
                                                            <table style={{ width: "100%" }}>
                                                                <thead style={{ borderBottom: "2px solid black" }}>
                                                                    <th>Current Action</th>
                                                                    <th style={{ textAlign: "left" }}>Next Action</th>
                                                                </thead>
                                                                <tbody style={{ width: "100%", borderBottom: "2px solid black" }}>

                                                                {oboard.onBoardData && oboard.onBoardData.map((ele) => (
                                                                    <tr style={{ width: "100%", border: "1px solid grey" }}>
                                                                    <td>{ele.currentAction === onboardState.init.state ? onboardState.init.message : ele.currentAction === onboardState.upload.state ?onboardState.upload.message :  ele.currentAction === onboardState.hr.state ? onboardState.hr.message : ele.currentAction === onboardState.ch.state ?  onboardState.ch.message :ele.currentAction === onboardState.rd.state ?onboardState.ch.message :onboardState.completed.message}</td>
                                                                    <td>{ele.nextAction === onboardState.init.state ? onboardState.init.message : ele.nextAction === onboardState.upload.state ?onboardState.upload.message :  ele.nextAction === onboardState.hr.state ? onboardState.hr.message : ele.nextAction === onboardState.ch.state ?  onboardState.ch.message :ele.nextAction === onboardState.rd.state ?onboardState.ch.message :onboardState.completed.message}</td>
                                                                </tr>
                                                                ))}
                                                                                                                    
                                                                </tbody>

                                                            </table>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md={6}>
                                                    <Card>
                                                        <Card.Header>
                                                            <h5 style={{margin:"1rem", textAlign:"center"}}>Agreement Approval Status</h5>
                                                        </Card.Header>
                                                        <Card.Body style={{ height: "180px", border: "2px solid rgb(0,0,0, 0.4)", borderRadius: "5px" }}>
                                                            <table style={{ width: "100%" }}>
                                                                <thead style={{ borderBottom: "2px solid black" }}>
                                                                    <th>Approval By</th>
                                                                    <th style={{ textAlign: "left" }}>Status</th>
                                                                </thead>
                                                                <tbody style={{ width: "100%", borderBottom: "2px solid black" }}>
                                                                    <tr style={{ width: "100%", border: "1px solid grey" }}>
                                                                        <td>HR Approval</td>
                                                                        <td>Approved</td>
                                                                    </tr>
                                                                    <tr style={{ width: "100%", border: "1px solid grey" }}>
                                                                        <td>Doctor Approval</td>
                                                                        <td>Approved</td>
                                                                    </tr>
                                                                    <tr style={{ width: "100%", border: "1px solid grey" }}>
                                                                        <td>CH Approval</td>
                                                                        <td>Approved</td>
                                                                    </tr>
                                                                    <tr style={{ width: "100%", border: "1px solid grey" }}>
                                                                        <td>RD Approval</td>
                                                                        <td>Approved</td>
                                                                    </tr>
                                                                </tbody>

                                                            </table>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>

                                            </Row>
                                            <Row style={{ margin: "1rem" }}>
                                                <Col md={8}>
                                                    <label style={{ fontWeight: "bold" }}> Enter LT ID:</label>
                                                    <input type="text" className="form-control " style={{ border: "1px solid grey" }}></input>
                                                </Col>
                                            </Row>

                                            <Row style={{ margin: "1rem", float: "right" }}>
                                                <button className="btn btn-primary">Confirm</button>
                                            </Row>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row> */}

<Row>
                                <Col md={12}>
                                    <MaterialTable
                                        columns={[
                                            { title: "Item Group", field: "itemgroup" },

                                            { title: "Commercial Term", field: "commercialterm" },
                                        ]}
                                        // data={doctorDetail.doctorIPParameter}
                                        data={oboard.doctorOpCommercial}
                                        options={{
                                            // filtering: true,
                                            sorting: true,
                                            exportButton: true,
                                            pageSize: 5,
                                            pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                            search: true,
                                        }}
                                        title="Op Commercial"
                                    />
                                    <br />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <MaterialTable
                                        columns={[
                                            { title: "Item Group", field: "itemgroup" },
                                            { title: "Room Category", field: "iproom" },
                                            { title: "MOP", field: "ipmodeofpayment" },

                                            { title: "Commercial Term", field: "commercialterm" },
                                        ]}
                                        // data={doctorDetail.doctorIPParameter}
                                        data={oboard.doctorIpCommercial}
                                        options={{
                                            // filtering: true,
                                            sorting: true,
                                            exportButton: true,
                                            pageSize: 5,
                                            pageSizeOptions: [5, 10, 50, 100, 200, 500],
                                            search: true,
                                        }}
                                        title="Ip Commercial"
                                    />
                                </Col>
                            </Row>
              {/* <Row>
                <h5 style={{ margin: "1rem", width: "100%", textAlign: "center" }}>OP Commercials</h5>
                <table className="table " style={{ width: "78%" }}>
                  <thead>
                    <tr style={{
                      textAlign: "center",
                      borderBottom: "1px solid rgb(200, 200, 200)",
                      backgroundColor: "hsla(340, 97%, 26%, 0.55)",
                      color: "white",
                    }}>
                      <th className="wd-lg-20p"><span>Item Group/Procedures</span></th>
                      <th className="wd-lg-20p"><span>Commercial Term</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {oboard.doctorOPParameter && oboard.doctorOPParameter.map((ele) => (
                      <tr style={{ textAlign: "center" }}>
                        <td>{ele.itemGroup}</td>
                        <td>
                          {ele.opdiscounttype === "Yes" &&
                            ele.opadhoc !== "0"
                            ? ele.fixedpercentage !== "0"
                              ? ele.fixedpercentage +
                              "% of the Net Bill excluding Deductions"
                              : ele.fixedamount + " /- Per case"
                            : ""}
                          {ele.opdiscounttype === "No" &&
                            ele.opadhoc === "0"
                            ? ele.fixedpercentage !== "0"
                              ? ele.fixedpercentage +
                              " % of the Gross Amount"
                              : ele.fixedamount + " /- Per case"
                            : ""}
                          {ele.opdiscounttype === "Yes" &&
                            ele.opadhoc === "0"
                            ? ele.fixedpercentage !== "0"
                              ? ele.fixedpercentage +
                              " % of the Net Amount"
                              : ele.fixedamount + " /- Per case"
                            : ""}
                          {ele.opdiscounttype === "No" &&
                            ele.opadhoc !== "0"
                            ? ele.fixedpercentage !== "0"
                              ? ele.fixedpercentage +
                              "% of the Gross Bill excluding Deductions"
                              : ele.fixedamount + " /- Per case"
                            : ""}
                        </td> */}
                        {/* <td>
                      {ele.opdiscountvalue !== "0" &&
                        ele.opadhoc !== "0"
                        ? ele.fixedpercentage !== "0"
                          ? ele.fixedpercentage +
                          "% of the Net Bill excluding Deductions"
                          : ele.fixedamount + " /- Per case"
                        : ""}
                      {ele.opdiscountvalue === "0" &&
                        ele.opadhoc === "0"
                        ? ele.fixedpercentage !== "0"
                          ? ele.fixedpercentage +
                          " % of the Gross Amount"
                          : ele.fixedamount + " /- Per case"
                        : ""}
                      {ele.opdiscountvalue !== "0" &&
                        ele.opadhoc === "0"
                        ? ele.fixedpercentage !== "0"
                          ? ele.fixedpercentage +
                          " % of the Net Amount"
                          : ele.fixedamount + " /- Per case"
                        : ""}
                      {ele.opdiscountvalue === "0" &&
                        ele.opadhoc !== "0"
                        ? ele.fixedpercentage !== "0"
                          ? ele.fixedpercentage +
                          "% of the Gross Bill excluding Deductions"
                          : ele.fixedamount + " /- Per case"
                        : ""}
                    </td> */}
                        {/* <td> {
                      ele.opadhoc === null && ele.opdiscountvalue !== null ? <>  {ele.fixedpercentage !== ("0" || null) ? ele.fixedpercentage + "% of the Net Amount" : "Rs" + ele.fixedamount + "/- Per case"}  </> :
                        ele.opadhoc === null && ele.opdiscountvalue === null ? <>  {ele.fixedpercentage !== ("0" || null) ? ele.fixedpercentage + "% of the Gross Amount" : "Rs" + ele.fixedamount + "/- Per case"}  </> :
                          ele.opadhoc !== null && ele.opdiscountvalue === null ? <> {ele.fixedpercentage !== ("0" || null) ? ele.fixedpercentage + "% of the Gross Bill excluding Deductions" : "Rs" + ele.fixedamount + "/- Per case"}  </> :
                            ele.opadhoc !== null && ele.opdiscountvalue !== null ? <> {ele.fixedpercentage !== ("0" || null) ? ele.fixedpercentage + "% of the Net Bill excluding Deductions" : "Rs" + ele.fixedamount + "/- Per case"}  </> :
                              ele.fixedpercentage !== ("0" || null) ? <>  {ele.fixedpercentage}% of the consultation fee </> :
                                ele.fixedpercentage === ("0" || null) ? <> Rs.{ele.fixedamount}/- Per case </> : ""
                    }</td> */}
                      {/* </tr>
                    ))
                    }
                  </tbody>
                </table>


                <h5 style={{ margin: "1rem", width: "100%", textAlign: "center" }}>IP Commercials</h5>
                <table className="table " style={{ width: "78%" }}>
                  <thead>
                    <tr style={{
                      textAlign: "center",
                      borderBottom: "1px solid rgb(200, 200, 200)",
                      backgroundColor: "hsla(340, 97%, 26%, 0.55)",
                      color: "white",
                    }} >
                      <th className="wd-lg-20p"><span>Item Group/Procedures</span></th>
                      <th className="wd-lg-20p"><span>Room Category</span></th>
                      <th className="wd-lg-20p"><span>MOP</span></th>
                      <th className="wd-lg-20p"><span>Commercial Term</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {oboard.doctorIPParameter && oboard.doctorIPParameter.map((ele) => (
                      <tr style={{ textAlign: "center" }} >
                        <td>{ele.itemGroup}</td>
                        <td>{ele.iproom}</td>
                        <td>{ele.ipmodeofpayment}</td>
                        <td>
                        {ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "Yes" ? <> {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Amount </> :
                            ele.ipdeductaionvalue !== "0" && ele.ipdiscountType === "No" ? <>  {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Gross Bill excluding Deductions </> :
                              ele.ipdeductaionvalue !== "0" && ele.ipdiscountType === "Yes" ? <> {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Bill excluding Deductions </> :
                                ele.ippackageamountperc !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "No" ? <> {ele.ippackageamountperc}% of the Package Amount </> :
                                  ele.ipsurgeonfeeperc !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "No" ? <> {ele.ipsurgeonfeeperc}% of the  Surgeon Fee </> :
                                    ele.ipnetbillpercentage !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "No" ? <> {ele.ipnetbillpercentage}% of the Net Bill Amount </> :
                                      ele.ipgrossbillpercentage !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "No" ? <> {ele.ipgrossbillpercentage}% of the Gross Bill Amount </>
                                       : ele.ipfixedamount !== 0
                                       ? "Rs" + ele.ipfixedamount + "/- of the Fixed Amount "
                                        : ""}
                        </td> */}

                        {/* <td>
                      {ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue !== "0" ? <> {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Amount </> :
                        ele.ipdeductaionvalue !== "0" && ele.ipdiscountvalue === "0" ? <>  {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Gross Bill excluding Deductions </> :
                          ele.ipdeductaionvalue !== "0" && ele.ipdiscountvalue !== "0" ? <> {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Bill excluding Deductions </> :
                            ele.ippackageamountperc !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue === "0" ? <> {ele.ippackageamountperc}% of the Package Amount </> :
                              ele.ipsurgeonfeeperc !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue === "0" ? <> {ele.ipsurgeonfeeperc}% of the  Surgeon Fee </> :
                                ele.ipnetbillpercentage !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue === "0" ? <> {ele.ipnetbillpercentage}% of the Net Bill Amount </> :
                                  ele.ipgrossbillpercentage !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue === "0" ? <> {ele.ipgrossbillpercentage}% of the Gross Bill Amount </>
                                    : ""}
                    </td> */}

                        {/* <td> {
                      ele.ippackageamountperc !== (null || "") ? <> {ele.ippackageamountperc}% of the Package Amount </> :
                        ele.ipsurgeonfeeperc !== (null || "") ? <> {ele.ipsurgeonfeeperc}% of the  Surgeon Fee </> :
                          ele.ipnetbillpercentage !== (null || "") ? <> {ele.ipnetbillpercentage}% of the Net Bill Amount </> :
                            ele.ipgrossbillpercentage !== (null || "") ? <> {ele.ipgrossbillpercentage}% of the Gross Bill Amount </> :
                              ele.ipdeductaionvalue === null && ele.ipdiscountvalue !== null ? <> {ele.ipsurgeonfeeperc === null && ele.ippackageamountperc === null ? ele.ipgrossbillpercent : ele.ipsurgeonfeeperc === null && ele.ipgrossbillpercentage === null ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Amount </> :
                                ele.ipdeductaionvalue === null && ele.ipdiscountvalue === null ? <>  {ele.ipsurgeonfeeperc === null && ele.ippackageamountperc === null ? ele.ipgrossbillpercent : ele.ipsurgeonfeeperc === null && ele.ipgrossbillpercentage === null ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Gross Amount </> :
                                  ele.ipdeductaionvalue !== null && ele.ipdiscountvalue === null ? <>  {ele.ipsurgeonfeeperc === null && ele.ippackageamountperc === null ? ele.ipgrossbillpercent : ele.ipsurgeonfeeperc === null && ele.ipgrossbillpercentage === null ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Gross Bill excluding Deductions </> :
                                    ele.ipdeductaionvalue !== null && ele.ipdiscountvalue !== null ? <> {ele.ipsurgeonfeeperc === null && ele.ippackageamountperc === null ? ele.ipgrossbillpercent : ele.ipsurgeonfeeperc === null && ele.ipgrossbillpercentage === null ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Bill excluding Deductions </>
                                      : ""

                    }</td> */}
                      {/* </tr>
                    ))
                    }
                  </tbody>
                </table>


              </Row> */}
              <br />
              <br />
            </div>
          </div>
        </div>
        <button className="btn btn-primary" style={{ float: "right", margin: "1rem" }}>Approve</button>
      </div>

    </>
  )
}

export default DoctorProfileRD
