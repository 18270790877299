import React from "react";
// material-table component
import MaterialTable from "material-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, Paper } from "@material-ui/core";

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

var abc = "";

export default function TableDepartment({ rows, editRow, deleteDepartment }) {
  const tables = myTableStyle();
  const CustomRender = ({ value }) => {
    if (value === null || value === '') {
      return value
    } else {
      const data = value.split(",");
      return (
        <List>
          {data.map((item, i) => (
            <List key={i}>{item}</List>
          ))}
        </List>
      );
    }
  }
  return (
    <>
      <Paper className={tables.root}>
        <MaterialTable
          columns={[
            { title: "Procedure Name", field: "procedurename" },
            {
              title: "Center",
              field: "center",
              render: (row) => <CustomRender value={row.center} />
            },
            // {
            //   title: "Specialization", field: "designationlist",
            //   render: (row) => <CustomRender value={row.designationlist} />
            // },
            // { title: "Platinum", field: "platinum" },
            { title: "Payout", field: "payout" },
            // { title: "From Date", field: "fromdate" ,type:"date"},
            // { title: "To Date", field: "todate",type:"date" },
          ]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit",
              iconProps: { style: { fontSize: "24px", color: "#5f5fe7" } },
              onClick: (event, row) => editRow(row),
            },
            {
              icon: "delete",
              tooltip: "Delete",
              iconProps: { style: { fontSize: "24px", color: "#f55151" } },
              onClick: (event, row) => {
                deleteDepartment(row);
              },
            },
          ]}
          data={rows}
          options={{
            // filtering: true,
            sorting: true,
            exportButton: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 50, 100, 200, 500],
            search: true,
          }}
          title="Department"
        />
      </Paper>
    </>
  );
}
