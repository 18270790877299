import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo2 from "../images/Logos-04.png";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { listusers } from "../actions/userActions";

function SidebarRD({ userType }) {
  const [dash, setDash] = useState("bh-dashboard");
  const [item4, setItem4] = useState("visibleDiv");
  const [item5, setItem5] = useState("visibleDiv");
  const [item6, setItem6] = useState("visibleDiv");
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);

  // bringing things from the reducer
  const { loading, error, user } = userLogin;
  function CheckUser() {
    if (userType === "BH") {
      setDash("bh-dashboard");
    } else if (userType === "HR") {
      setDash("hr-dashboard");
      setItem5("hiddenDiv");
    } else if (userType === "COO") {
      setDash("rd-dashboard");
      setItem4("hiddenDiv");
      setItem6("hiddenDiv");
    }
    useEffect(() => {
      dispatch(listusers());
    }, [dispatch]);
  }

  //  checkUser();

  // useEffect(() => {
  //   checkUser();
  //  } )

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  const [activeMenu, setActiveMenu] = useState();
  return (
    <div class="sidebar main-sidebar main-sidebar-sticky side-menu ps ps--active-y">
      <div className="main-sidebar-header header-img">
        <Link to="/rd-dashboard">
          <img src={logo2} className="img-responsive" alt="logo" />
        </Link>
      </div>
      <div className="main-sidebar-body">
        <ul className="nav">
          <li
            className={
              splitLocation[1] === "rd-dashboard"
                ? "active" + "nav-item"
                : "nav-item"
            }
          >
            <Link to="/rd-dashboard" id="nav-link" className="new-nav-link" style={{
              backgroundColor: "transparent",
              height: "2.6rem",
              border: "none",
              //marginTop: "1px",
              color: "white"
            }}>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <i className="ti-home sidemenu-icon mr-2"></i>
              <span className="sidemenu-label" style={{ fontSize: "16px" }}>{'   '}Dashboard</span>
            </Link>
          </li>

          <div
            style={{
              display: "block",
              width: "100%",
              marginTop: "-.2rem",
              marginLeft: "2px",
            }}
          >
            <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent" }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}
                  >
                    <i className="fas fa-angle-down sidemenu-icon mr-4"></i>
                    Onboard Actions
                  </Accordion.Toggle>
                </Card.Header>
                {/* <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/vacancy-approval"
                      id="nav-link"
                      className="active nav-link"
                      style={{ marginTop: "-1rem" }}
                      // className={activeMenu == 'approval' ? 'active nav-link' : 'nav-link'}
                      // onClick={() => { setActiveMenu('approval') }}
                       
                    >
                      
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i class="fas fa-check-circle sidemenu-icon mr-2"></i>
                      {/* Position Approval 
                      Approval
                    </Link>
                  </Card.Body>
                </Accordion.Collapse> */}
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/rd-approvalcalc"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i class="ti-bookmark-alt sidemenu-icon mr-2"></i>
                      Estimation
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/rdStatus"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i class="fas fa-file-upload sidemenu-icon mr-2"></i>
                      Documents
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/rd-agreement"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                      Agreement Approval
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                      whiteSpace: "nowrap"
                    }}
                  >
                    <Link
                      to="/Rddiscontinue"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i class="fas fa-check-circle sidemenu-icon mr-2"></i>
                      {/* renewal */}
                      Agreement Discontinue
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>

                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/signed-agreement"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-0.7rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
                      Signed Agreement
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          {/* <div
            style={{
              display: "block",
              width: "100%",
              // marginTop: ,
              marginLeft: "2px",
            }}
          >
            <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent" }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}
                  >
                    <i className="fas fa-list sidemenu-icon mr-4"></i>
                    Reports
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/DoctorListRd"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fas fa-list sidemenu-icon mr-2"></i>
                      Doctor List
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div> */}

          {/* <div
            style={{
              display: "block",
              width: "100%",
              marginTop: "-.2rem",
              marginLeft: "2px",
            }}
          >
            <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent" }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}
                  >
                    <i className="fas fa-angle-down sidemenu-icon mr-4"></i>
                   Payout
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/CenterWisePayout"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i class="fas fa-check-circle sidemenu-icon mr-2"></i>
                      Center Wise
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/DoctorWisePayout"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i class="ti-bookmark-alt sidemenu-icon mr-2"></i>
                      Doctor Wise
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
                
              </Card>
            </Accordion>
          </div> */}

          <li
            className={
              splitLocation[1] === "rd-dashboard"
                ? "active" + "nav-item"
                : "nav-item"
            }
          >
            <Link to="/ippayout-process" id="nav-link" className="new-nav-link" style={{
              backgroundColor: "transparent",
              height: "2.6rem",
              border: "none",
              //marginTop: "1px",
              color: "white"
            }}>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <i className="fas fa-file-contract sidemenu-icon mr-2"></i>
              <span className="sidemenu-label" style={{ fontSize: "16px" }}>{'   '}Payouts</span>
            </Link>
          </li>
          <div
            style={{
              display: "block",
              width: "100%",
              // marginTop: ,
              marginLeft: "2px",
            }}
          >
            <Accordion
              defaultActiveKey="1"
              style={{ backgroundColor: "transparent" }}
            >
              <Card style={{ backgroundColor: "transparent" }}>
                <Card.Header style={{ backgroundColor: "transparent" }}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    style={{ color: "white" }}
                  >
                    <i className="fas fa-user-circle sidemenu-icon mr-4"></i>
                    My Account
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/RDProfile"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="ti-user sidemenu-icon mr-2"></i>
                      Profile
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="new-nav-link"
                    style={{
                      backgroundColor: "transparent",
                      height: "1.2rem",
                      border: "none",
                    }}
                  >
                    <Link
                      to="/ChangePassword"
                      id="nav-link"
                      className="nav-link"
                      style={{ marginTop: "-1rem" }}
                    >
                      <span className="shape1"></span>
                      <span className="shape2"></span>
                      <i className="fas fa-unlock sidemenu-icon mr-2"></i>
                      Change Password
                    </Link>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default SidebarRD;
