import axios from "axios";
import {
  AGREEMENTS_FAIL,
  AGREEMENTS_REQUEST,
  AGREEMENTS_SUCCESS,
  AGREEMENTS_VIEW_REQUEST,
  AGREEMENTS_VIEW_SUCCESS,
  AGREEMENTS_VIEW_FAIL

} from "../constants/agreementConstants";
import { APIURL } from "../CommomMethods/Fetch";

export const agreementlist = () => async (dispatch) => {
  try {
    dispatch({ type: AGREEMENTS_REQUEST });
    const { data } = await axios.get(APIURL()+"onboard/currentAgreementStatus/");
    dispatch({
      type: AGREEMENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AGREEMENTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export const agreementviewById = (id) => async (dispatch) => {
  try {
    dispatch({ type: AGREEMENTS_VIEW_REQUEST });
    const { data } = await axios.get(APIURL()+"agreements/"+id);
    dispatch({
      type: AGREEMENTS_VIEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: AGREEMENTS_VIEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};