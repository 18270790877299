export const onboardState = {
  request: { state: "REQUEST_INIT", currmessage: "Pending", message: "Position request initiated" },
  request: { state: "DISCONTINUE_INIT", currmessage: "Sent for Discontinue", message: "Sent for Discontinue" },
  request: { state: "COO_DISCONTINUE", currmessage: "Discontinued by COO", message: "Discontinued by COO" },
  request: { state: "DISCONTINUED", currmessage: "Discontinued by COO", message: "Discontinued by COO" },
  rd_grid_approval: { state: "RD_GRID_APPROVAL", message: "COO-Financial approved" },
  rd_grid_review: { state: "RD_GRID_REVIEW", message: "COO-Financial review" },
  request_edit: { state: "REQUEST_EDIT", message: "Position Request Edit" },
  init: { state: "CH_INIT", message: "Initiated", activity: "Provision to fill primary details and estimations of Doctor" },
  save_draft: { state: "SAVE_DRAFT", activity: "Saved as Draft", message: "Saved as Draft" },
  doc_revise: { state: "DOC_REVISE", message: "Sent for review by doctor" },
  dr_proposed_commercials_accepted: { state: "DR_PROPOSED_COMMERCIALS_ACCEPTED", message: "To be accepted by DR" },
  dr_info: { state: "DR_INFO", message: "Doctor info to be filled", activity: "Doctor personal details to be filled"},
  upload: { state: "DOC_UPLOAD", message: "Documents to be uploaded", activity: "Required Joining Documents to be uploaded" },
  hr: { state: "HR_VERIFY", message: "To be verified by Corporate HR", activity: "Provision to give approval on joining documents and enter execution date and stamp tag number", currmessage: "Corporate HR Verified" },
  hr_rej: { state: "HR_REVISION", message: "Revised by HR", currmessage: "HR Revise" },
  ch: { state: "CH_VERIFY", message: "To be verified by BH", activity: "Provision to give final approval on draft agreement and give commencement Date", currmessage: "BH Verified" },
  rd: { state: "RD_VERIFY", message: "To be verified by COO", activity: "Provision to give approval on the given Estimations", currmessage: "COO Verified" },
  rd_revision: { state: "RD_REVISION", activity: "Provision to send back to edit Commercials", message: "Sent back to review the commercials and re-submit" },
  rj: { state: "RD_REJECTED", message: "Rejected by COO", currmessage: "COO Rejected" },
  doc_reject: { state: "DOC_REJECT", message: "Rejected by Doctor", currmessage: "Rejected by Doctor" },
  done: { state: "RD_OK", message: "Verified by COO", currmessage: "Verified By BH and COO" },
  ch_doc: { state: "CH_DOC_OK", message: "Documents verified by BH", currmessage: "BH Verified" },
  ch_doc_rej: { state: "CH_DOC_REJ", activity: "Provision to send back to resubmit doctors document", message: "Send back to resubmit doctors document", currmessage: "Send back to resubmit doctors document" },
  completed: "COMPLETED",
  complete: { state: "COMPLETED", message: "COMPLETED", currmessage: "COMPLETED" },
  ch_renew: { state: "CH_RENEW", message: "Renewal Initiated" },
  dr: { state: "DR_TO_SIGN", activity: "", message: "Agreement to be signed by Doctor", currmessage: "Agreement to be signed by Doctor" },
  dr_sign: { state: "DR_SIGNED", activity: "Doctor Signed Agreement", message: "Doctor Signed Agreement", currmessage: "Doctor Signed Agreement" },
  manual_agreement_uploaded: { state: "MANUAL_AGREEMENT_UPLOADED", activity: "Manual Signed Agreement Uploaded", message: "Manual Signed Agreement Uploaded", currmessage: "Manual Signed Agreement Uploaded" },
  ceo: { state: "CEO_TO_SIGN", activity: "", message: "Agreement to be signed by CEO", currmessage: "Agreement to be signed by CEO" },
  ceo_sign: { state: "CEO_SIGNED", activity: "CEO Signed Agreement", message: "CEO Signed Agreement", currmessage: "CEO Signed Agreement" },
  commercial_review: { state: "COMMERCIAL_REVIEW", activity: "Review of Commercials", message: "To be review the commercials", currmessage: "Commercials Reviewed" },
  dr_info_revise: { state: "DR_INFO_REVISE", activity: "Provision to send back to edit Doctor's Personal Information", message: "Send back to edit Doctor's Personal Information", currmessage: "Send back to edit Doctor's Personal Information" },

};

export const agreementState = {
  init: { state: "RD_INIT", message: "Initiated" },
  ag_init: { state: "DOC_INIT", message: "Awaiting doctor acceptance" },
  rd_revision: { state: "RD_REVISION", message: "Revised by COO" },
  hr_revision: { state: "HR_REVISION", message: "Revised by HR" },
  dr_accepted: { state: "DR_ACCEPTED", message: "Accepted by Doctor" },
  hr_accepted: { state: "HR_ACCEPTED", message: "Accepted by HR" },
  ch_approved: { state: "CH_APPROVED", message: "Accepted by BH" },
  finance_approved: { state: "FINANCE_APPROVED", message: "Accepted by Finance" },
  finance_approval: { state: "FINANCE_APPROVAL", message: "Sent to finance for approval" },
  ceo_approved: { state: "CEO_APPROVED", message: "Accepted by CEO" },
  rd_approved: { state: "RD_APPROVED", message: "Accepted by COO" },
  dr_decline: { state: "DR_DECLINED", message: "Declined by DR" },
  ch_decline: { state: "CH_DECLINED", message: "Declined by BH" },
  rd_decline: { state: "RD_DECLINED", message: "Declined by COO" },
  hr_decline: { state: "HR_DECLINED", message: "Declined by HR" },
  finance_decline: { state: "FINANCE_DECLINED", message: "Declined by Finance" },
  completed: "COMPLETED",
};

export const payoutState = {
  upload_report: { state: "UPLOAD_REPORT", activity: "Upload Billing Reports", message: "Billing reports uploades" },
  report_process: { state: "REPORT_PROCESSED", activity: "Load Data into System", message: "Reports processed into system" },
  init: { state: "PAYOUT_INIT", message: "Payout Initiated", currmessage: "Payout Initiated" },
  ch_approval: { state: "CH_APPROVAL", activity: "Send to BH for Approval", message: "Send to BH for Approval" },
  rd_approval: { state: "COO_APPROVAL", activity: "Send to COO for Approval", message: "Send to COO for Approval" },
  co_hr_approval: { state: "CO_HR_APPROVAL", activity: "Send to Corporate HR for Approval", message: "Send to Corporate HR for Approval" },
  dp_team_approval: { state: "DP_TEAM_APPROVAL", activity: "Send to DP Team for Review", message: "Send to DP Team for Review" },
  dp_team_second_approval: { state: "DP_TEAM_SECOND_APPROVAL", activity: "Send to DP Team for Review", message: "Send to DP Team for Review" },
  dp_team_final_reviw: { state: "DP_TEAM_REVIEW", activity: "Send to DP Team for Review", message: "Send to DP Team for Review" },
  ch_add: { state: "CH_ADD", activity: "Add Items", message: "Item Added" },
  ch_edit: { state: "CH_EDIT", activity: "Edit Items", message: "Item Updated" },
  ch_delete: { state: "CH_DELETE", activity: "Delete Items", message: "Item Deleted" },
  ch_approved: { state: "CH_APPROVED", message: "To be approved by BH", currmessage: "Approved by BH" },
  dp_reviewd: { state: "DP_REVIEWED", message: "To be reviewd by DP Team", currmessage: "Reviewd by DP Team" },
  dp_rejected: { state: "DP_REJECTED", message: "Rejected by DP Head", currmessage: "Sent Back to Revise" },
  ch_rejected: { state: "CH_REJECTED", message: "Rejected by BH", currmessage: "Sent Back to Revise" },
  coo_rejected: { state: "COO_REJECTED", message: "Rejected by COO", currmessage: "Sent Back to Revise" },
  hr_approved: { state: "HR_APPROVED", message: "To be approved by HR", currmessage: "Approved by HR" },
  co_hr_approved: { state: "CO_HR_APPROVED", message: "To be approved by Corporate HR", currmessage: "Approved by Corporate HR" },
  rd_approved: { state: "RD_APPROVED", message: "To be approved by COO", currmessage: "Approved by COO" },
  d365: { state: "SENT_DYNAMICS", activity: "Sent to D365", message: "Sent to D365" },
  completed: "COMPLETED",
};