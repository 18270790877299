import React, { useState } from "react";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  FormControlLabel,
  Paper,
  RadioGroup,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const styleSheet = makeStyles((theme) => ({
  paperStyle: { marginTop: "10px" },
  textFieldstyle: {
    // marginBottom: "20px",
    marginTop: "20px",
  },
  btnStyle: {
    marginRight: "80px",
    marginLeft: "auto",
    display: "block",
    marginBottom: "20px",
  },
}));

export default function AddCenterPositionMap({
  addDepartment,
  centerRows,
  departmentRows,
}) {
  const style = styleSheet();
  const initialFormState = {
    centerid: "",
    category: "",
    department: "",
    allocated: "",
    occupied: "0",
    fg_from: "",
    fg_to: "",
    status: "",
  };
  const [centerMapPosition, setCenterMapPosition] = useState(initialFormState);
  const [value, setValue] = React.useState("1");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCenterMapPosition({ ...centerMapPosition, [name]: value });
  };

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          centerMapPosition.status = value;
          addDepartment(centerMapPosition);
        }}
      >
        <Paper className={style.paperStyle}>
          <Grid container spacing={1}>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
              {/* <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="centerid"
                label="Center Id"
                value={centerMapPosition.centerid}
                onChange={handleInputChange}
                required
                fullWidth
              /> */}
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Center
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="centerid"
                  value={centerMapPosition.centerid}
                  onChange={handleInputChange}
                  label="Select Center"
                >
                  {centerRows &&
                    centerRows.map((opt) => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl
                className={style.textFieldstyle}
                variant="outlined"
                style={{ width: "100%" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="category"
                  
                  value={centerMapPosition.category}
                  onChange={handleInputChange}
                  label="Select Category"
                >
                  <MenuItem value="Full Time Consultant">Full Time Consultant</MenuItem>
                  <MenuItem value="Visiting Consultant">Visiting Consultant</MenuItem>
                  <MenuItem value="Retainer">Retainer</MenuItem>
                  <MenuItem value="Minimum Guarentee(MG)">Minimum Guarentee(MG)</MenuItem>
                </Select>
              </FormControl>

              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="fg_from"
                label="Financial Grid (Min.)"
                value={centerMapPosition.fg_from}
                onChange={handleInputChange}
                required
                fullWidth
              />

              <FormControl component="fieldset" style={{ marginTop: "25px" }}>
                <FormLabel component="legend">Status</FormLabel>
                <RadioGroup
                  row
                  name="status"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="#00963f" />}
                    label="Active"
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="#00963f" />}
                    label="Inactive"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="allocated"
                label="No of positions"
                value={centerMapPosition.allocated}
                onChange={handleInputChange}
                required
                fullWidth
              />
              {/* <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="occupied"
                label="Occupied"
                value={centerMapPosition.occupied}
                onChange={handleInputChange}
                required
                disabled
                fullWidth
              /> */}
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Department
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="department"
                  value={centerMapPosition.department}
                  onChange={handleInputChange}
                  label="Select Department"
                >
                  {departmentRows &&
                    departmentRows.map((opt) => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                className={style.textFieldstyle}
                variant="outlined"
                name="fg_to"
                label="Financial Grid (Max.)"
                value={centerMapPosition.fg_to}
                onChange={handleInputChange}
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={style.btnStyle}
            >
              Add
            </Button>
          </Grid>
        </Paper>
      </form>
    </>
  );
}
