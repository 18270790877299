import React, { useState } from "react";
// @material-ui/core components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { MenuProps } from "../../CommonFIle/util";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { Input, OutlinedInput, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Stack from '@mui/material/Stack';
import { Autocomplete, createFilterOptions } from '@mui/material';
import {
  FormControl,
  FormControlLabel,
  Paper,
  RadioGroup,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import { CheckBox as CheckBoxIcon } from "@mui/icons-material";

const styleSheet = makeStyles((theme) => ({
  paperStyle: { marginTop: "10px" },
  textFieldstyle: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  btnStyle: {
    marginRight: "80px",
    marginLeft: "auto",
    display: "block",
    marginBottom: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    width: "100%",
  },
}));

export default function AddUserMapCenter({
  addDepartment,
  regionRows,
  centerRows,
  userRows,
}) {
  const style = styleSheet();
  const classes = useStyles();
  const initialFormState = {
    id: null,
    regionid: "",
    centerId: "",
    roleid: 2,
    userId: "",
    centerList: [],
  };

  console.log(centerRows, "centerRows centerRows");
  const [department, setDepartment] = useState(initialFormState);
  const [value, setValue] = React.useState("1");
  const [childItem, setChildItem] = useState([]);
  const [options, setOptions] = useState([]);
  const [childoptions, setChildOptions] = useState([]);
  const [optionsSubItem, setOptionsSubItem] = useState([]);
  const [selected, setSelected] = useState([]);
  const [ValueRegion, setValueRegion] = useState({});
  const [ValueCenter, setValueCenter] = useState([]);
  const [ValueUser, setValueUser] = useState({});

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDepartment({ ...department, [name]: value });
    setValueCenter([])
    setAllData([])
  };
  const [allData, setAllData] = useState([]);
  const [centerName, setCenterName] = React.useState([]);

  const handleChangeMulti = (event) => {
    const {
      target: { value },
    } = event;
    console.log(event, "1212");
    setCenterName(typeof value === "string" ? value.split(",") : value);
    setSelected(value);
  };

  console.log(centerName, "center name");

  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const parentItemChange = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === "all") {
      let centernames = [];
      let centerid = [];

      centerRows.forEach((element) => {
        centerid.push(element.id);
        centernames.push(element.name);
      });
      setSelected(selected.length === centerRows.length ? [] : centernames);
      setAllData(centerid);
      console.log(centerid, "all center");

      return;
    } else {
      setSelected(value);
      let newArralyList = [];
      newArralyList.push([...value]);
      console.log(newArralyList, "new array list");
      let indexPosition = -1;
      const centerSet = new Set();
      let arrayList = [];
      let obj = {};
      console.log(value, "selected value");
      if (value.length > 0) {
        for (let i = 0; i < value.length; i++) {
          centerRows.map((element, index) => {
            if (element.name == value[i]) {
              obj.cid = element.id;
              allData.push({ ...obj });
            }
          });
        }
      } else {
        centerRows.map((element, index) => {
          if (element.name == value) {
            obj.cid = element.id;
            allData.push(obj);
          }
        });
      }

      // console.log(Object.values(allData), "centerSet  centerSet");
      // setAllData(unique);
      const centerids = Array.from(centerSet);
      console.log(centerids, "centeridssss");
    }
  };

  const centerChange = async (event, selectedOptions, reason) => {
    console.log(reason, selectedOptions, 'childItemChange')
    if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find(option => option.name === "Select all")) {
        handleToggleSelectAll();
      } else {
        handleToggleOption && handleToggleOption(selectedOptions);
      }
    } else if (reason === "clear") {
      handleClearOptions && handleClearOptions();
    }
  };

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!isAllChildSelected);
  };

  const handleSelectAll = isSelected => {
    if (isSelected) {
      let centerMame = []
      var rows = centerRows.filter((elem) => elem.region_id === department.regionid)
      rows.forEach((element) => {
        centerMame.push(element.id);
      });
      setValueCenter(rows);
      setAllData(centerMame);
    } else {
      handleClearOptions();
    }
  };

  const handleClearOptions = () => {
    setValueCenter([])
  };

  const handleToggleOption = selectedOptions => {
    let centerMame = []
    selectedOptions.forEach((element) => {
      centerMame.push(element.id);
    });
    // setValueCenter(centerMame);
    setValueCenter(selectedOptions);
    setAllData(centerMame);
  }

  const getOptionSelected = (option, anotherOption) => {
    return option.name === anotherOption.name;
  }

  const isAllChildSelected =
    centerRows.length > 0 && ValueCenter.length === centerRows.filter((elem) => elem.region_id === department.regionid).length;


  const filter = createFilterOptions()

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          department.status = value;
          department.centerList = allData;
          // const unique = [...new Set(allData.map((item) => item.cid))];

          addDepartment(department);
        }}
      >

        <Paper className={style.paperStyle}>
          <Grid container>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <FormControl
                variant="outlined"
                style={{ width: "50%", marginTop: "70px" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Region
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="regionid"
                  value={department.regionid}
                  onChange={handleInputChange}
                  label="Select Center"
                >
                  {regionRows &&
                    regionRows.map((opt) => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name}
                      </MenuItem>
                    ))}
                </Select>

                {/* <Autocomplete
                id="combo-box-demo"
                  options={regionRows}
                  sx={{ width:890 }}
                  value={ValueRegion}
                  onChange={(event, value) => {
                    setValueRegion(value)
                    setDepartment({...department, 'regionid':value.id})
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(aaab) => <TextField {...aaab} label="Select Region"/>}
                  renderOption={(props,option,state) => <Typography {...props} style={{color:'black'}}>{option.name}</Typography>}
                /> */}
              </FormControl>
              {/* <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Center
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="centerId"
                  value={department.centerId}
                  onChange={handleInputChange}
                  label="Select Center"
                >
                  {centerRows &&
                    centerRows
                      .filter((elem) => elem.region_id === department.regionid)
                      .map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl> */}
              {/* <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "20px" }}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Center
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="centerId"
                  value={department.centerId}
                  onChange={handleInputChange}
                  label="Select Center"
                >
                  {centerRows &&
                    centerRows.map((opt) => (
                      <MenuItem key={opt.id} value={opt.id}>
                        {opt.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}

              {/* <FormControl className={classes.formControl}> */}
              {/* <InputLabel id="mutiple-select-label">Select Center</InputLabel> */}
              {/* <Select
                  labelId="mutiple-select-label"
                  multiple="true"
                  value={selected}
                  onChange={parentItemChange}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : "",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < options.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {centerRows
                    .filter((elem) => elem.region_id === department.regionid)
                    .map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        <ListItemIcon>
                          <Checkbox
                            checked={selected.indexOf(option.name) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))} 
                </Select> */}

              {/* <Autocomplete
                id="combo-box-demo"
                  options={centerRows}
                   sx={{ width:445 }}
                  value={ValueCenter}
                  onChange={(event, value) => {
                    setValueCenter(value)
                    setDepartment({...department, 'centerId':value.id})
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(aaab) => <TextField {...aaab} label="Select Center"/>}
                  renderOption={(props,option,state) => <Typography {...props} style={{color:'black'}}>{option.name}</Typography>}
                />
              </FormControl> */}

              <div className="row mt-2 pt-2">
                {/* <div className="col-4" style={{ display: 'flex', alignContent: 'left', justifyContent: 'left', alignItems: 'left' }}></div> */}
                <div className="col-6">
                  <Autocomplete
                    multiple
                    id="combo-box-demo"
                    value={ValueCenter}
                    onChange={(event, newValue, reason) => centerChange(event, newValue, reason)}
                    // onChange={(event, value) => {
                    //   setValueCenter(value)
                    //   setDepartment({ ...department, 'centerId': value.id })
                    // }}
                    options={centerRows.filter((elem) => elem.region_id === department.regionid)}
                    getOptionSelected={getOptionSelected}
                    getOptionLabel={(option) => option.name ? option.name : ''}
                    // sx={{ width: 445, height: 50 }}
                    renderInput={(params) => <TextField {...params} label="Centers" />}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      return [{ name: "Select all" }, ...filtered];
                    }}
                    renderOption={(props, option, { selected }) => {
                      const selectAllProps =
                        option.name === "Select all" // To control the state of 'select-all' checkbox
                          ? { checked: isAllChildSelected }
                          : {};
                      return (
                        <>
                          <Typography key={option.id} {...props} style={{ color: 'black' }}>
                            <Checkbox
                              color="primary"
                              icon={<CheckBoxOutlineBlank fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              {...selectAllProps}
                            />
                            {option.name}
                          </Typography>

                        </>
                      );
                    }}
                  />
                </div>
              </div>

              {/* <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-native-simple">
                  Select Center
                </InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={centerName}
                  name="first"
                  onChange={handleChangeMulti}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) =>
                    selected.map((obj) => centerRows[obj - 1].value).join(", ")
                  }
                >
                  {centerRows.map((center) => (
                    <MenuItem key={center.id} value={center.name}>
                      <Checkbox checked={selected.indexOf(center.id) > -1} />
                      <ListItemText primary={center.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}

              {/* <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: "20px" }}
              > */}
              {/* <InputLabel id="demo-simple-select-outlined-label">
                  Select User
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  name="userId"
                  value={department.userId}
                  onChange={handleInputChange}
                  label="Select Center"
                >
                  {userRows &&
                    userRows
                      .filter((z) => z.users_role_id == 2)
                      .map((opt) => (
                        <MenuItem key={opt.id} value={opt.id}>
                          {opt.name + " (" + opt.email + ")"}
                        </MenuItem>
                      ))}
                </Select> */}
              {/* <Autocomplete
                id="combo-box-demo"
                  options={userRows}
                  sx={{ width:445 }}
                  value={ValueUser}
                  onChange={(event, value) => {
                    setValueUser(value)
                    setDepartment({...department, 'userId':value.id})
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(aaab) => <TextField {...aaab} label="Select User"/>}
                  renderOption={(props,option,state) => <Typography {...props} style={{color:'black'}}>{option.name}</Typography>}
                />
              </FormControl> */}

              <div className="row mt-2 pt-2">
                {/* <div className="col-4" style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>Select Center :</div> */}
                <div className="col-6">
                  <Autocomplete
                    id=""
                    // disabled={department.PoolId && department.PoolId != '' ? false : true}
                    value={ValueUser}
                    onChange={(event, value) => {
                      setValueUser(value)
                      setDepartment({ ...department, 'userId': value.id })
                    }}
                    options={userRows}
                    getOptionLabel={(option) => option.name ? option.name : ''}
                    sx={{ width: 445, height: 20 }}

                    renderInput={(params) => <TextField {...params} label="User" />}
                    renderOption={(props, option, state) => {
                      return (
                        <Typography key={option.name} {...props} style={{ color: 'black' }}>{option.name}</Typography>
                      );
                    }}
                  />
                </div>
              </div>

            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ marginTop: "10px" }}
              className={style.btnStyle}
            >
              Add
            </Button>
          </Grid>
        </Paper>
      </form>
    </>
  );
}
