import axios from "axios";
import {
  POSITION_CREATE_FAIL,
  POSITION_CREATE_REQUEST,
  POSITION_REMOVE_SUCCESS,
  POSITION_CREATE_SUCCESS,
  POSITION_ADD_REQUEST,
  POSITION_ADD_SUCCESS,
  POSITION_ADD_FAIL,
  FORM_FAIL,
  FORM_REQUEST,
  FORM_SUCCESS,
  POSITION_ID_CREATE_REQUEST,
  POSITION_ID_CREATE_SUCCESS,
  POSITION_ID_CREATE_FAIL,
} from "../constants/createPositionConstants";
import { APIURL } from "../CommomMethods/Fetch";
// create positions
export const createPositions = (postdata) => async (dispatch, getState) => {
  console.log(postdata, "post data at vacancy time");
  try {
    dispatch({ type: POSITION_CREATE_REQUEST });
    const {
      userLogin: { user },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
   
      const { data } = await axios.post(
        APIURL() + "vacancies/ ",
        {
          nofreq:postdata.nofreq,
          manualAgreement:postdata.manualAgreement,
          id: user.user.id,
          label: user.user.username,
          code: user.user.roles,
          designationLabel: postdata.designationlist,
          category: postdata.category,
          departmentLabel: postdata.departmentlist,
          description: postdata.descriptionInfo,
          comments: postdata.comments,
          centerNames: postdata.centerlist,
          centercode: postdata.centercode,
          salaryType: postdata.salaryType,
          minsalary: postdata.minsalary,
          maxsalary: postdata.maxsalary,
          postedBy: postdata.postedBy,
          financialGrid: postdata.financialGrid,
          positionType: postdata.positionType,
          request_type: postdata.request_type,
          status: 1,
        },
        config
      );
      dispatch({
        type: POSITION_CREATE_SUCCESS,
        payload: data,
      });
    


  } catch (error) {
    dispatch({
      type: POSITION_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// get form
export const formlist = () => async (dispatch) => {
  try {
    dispatch({ type: FORM_REQUEST });
    const { data } = await axios.get(APIURL() + "vacancies/form/");
    dispatch({
      type: FORM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FORM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ADD POSITION TO TABLE
export const addpositions = () => async (dispatch) => {
  try {
    dispatch({ type: POSITION_ADD_REQUEST });
    const { data } = await axios.get(APIURL() + "vacancies/");
    dispatch({
      type: POSITION_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: POSITION_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeRequest = (id) => async (dispatch) => {
  dispatch({
    type: POSITION_REMOVE_SUCCESS,
    payload: id,
  });
};

// ADD SINGLE POSITION TO MODAL
export const findpositions = (id) => async (dispatch) => {
  try {
    dispatch({ type: POSITION_ID_CREATE_REQUEST });
    const { data } = await axios.get(APIURL() + `vacancies/${id}`);
    dispatch({
      type: POSITION_ID_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: POSITION_ID_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Formatting Date
export const dateFormatter = (dateRaw) => {

  let date = new Date(dateRaw);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) { dt = '0' + dt; }
  if (month < 10) { month = '0' + month; }

  return dt + '-' + month + '-' + year;
}

//number with commas

export const numberWithCommas = (x) => {
   return (parseFloat(x).toFixed()).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const numberWithCommasINR = (x) => {
  return parseInt(x).toLocaleString('en-IN');
}

export const numberToEnglish = (n, custom_join_character) => {

  var string = n.toString(),
      units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words;

  var and = custom_join_character || 'and';

  /* Is number zero? */
  if (parseInt(string) === 0) {
      return 'zero';
  }

  /* Array of units as words */
  units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];

  /* Array of tens as words */
  tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

  /* Array of scales as words */
  scales = ['', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion'];

  /* Split user arguemnt into 3 digit chunks from right to left */
  start = string.length;
  chunks = [];
  while (start > 0) {
      end = start;
      chunks.push(string.slice((start = Math.max(0, start - 3)), end));
  }

  /* Check if function has enough scale words to be able to stringify the user argument */
  chunksLen = chunks.length;
  if (chunksLen > scales.length) {
      return '';
  }

  /* Stringify each integer in each chunk */
  words = [];
  for (i = 0; i < chunksLen; i++) {

      chunk = parseInt(chunks[i]);

      if (chunk) {

          /* Split chunk into array of individual integers */
          ints = chunks[i].split('').reverse().map(parseFloat);

          /* If tens integer is 1, i.e. 10, then add 10 to units integer */
          if (ints[1] === 1) {
              ints[0] += 10;
          }

          /* Add scale word if chunk is not zero and array item exists */
          if ((word = scales[i])) {
              words.push(word);
          }

          /* Add unit word if array item exists */
          if ((word = units[ints[0]])) {
              words.push(word);
          }

          /* Add tens word if array item exists */
          if ((word = tens[ints[1]])) {
              words.push(word);
          }

          /* Add 'and' string after units or tens integer if: */
          if (ints[0] || ints[1]) {

              /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
              if (ints[2] || !i && chunksLen) {
                  words.push(and);
              }

          }

          /* Add hundreds word if array item exists */
          if ((word = units[ints[2]])) {
              words.push(word + ' hundred');
          }

      }

  }

  return words.reverse().join(' ');

}